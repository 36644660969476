import React from 'react';
import './Login.scss';

const AccessDenied = () => (
    <div className="login__container">
        <img src="/img/3tc-logo.svg" alt="The 3tc logo"/>
        <div>
            <h1>Access Denied</h1>
            <p>You do not have the correct access to use Water Management.</p>
            <a href="/logout" className="btn">Log out</a>
        </div>
    </div>
)

export default AccessDenied;
