import React from "react";

import ReadOnlyElement from "../../../../labelledField/components/readOnlyElement";

interface EmptyListProps { 
    readonly id: string; 
    readonly label: string; 
}
const EmptyList = ({ id, label }: EmptyListProps): JSX.Element => {
    return <ReadOnlyElement id={id} label={label} value="-" classes={{ container: 'input__group input__group_side-panel', label: 'input__label' }} />;
};

export type { EmptyListProps };
export default EmptyList;
