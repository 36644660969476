import { useState, useEffect, useCallback } from "react";

import { NodeID, encodeNodeId } from "../../lib/nodeIdentifier";
import { IInspection } from "../../store/types";
import getInspection from "../getInspection";
import { FetchStatus, FetchSingle } from "./types";
import { bindIfMounted } from "./utils";

const useInspection = (nodeId: NodeID | undefined): FetchSingle<IInspection> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [inspection, setInspection] = useState<IInspection | undefined>(undefined);

    const id = nodeId ? encodeNodeId(nodeId) : undefined;
    const fetch = useCallback(() => {
        let isMounted = true;
        const ifMounted = bindIfMounted(isMounted);

        setStatus("loading");
        if (id) {
            getInspection(id)
                .then(inspection => ifMounted(() => setInspection(inspection)))
                .catch(() => ifMounted(() => setInspection(undefined)))
                .finally(() => ifMounted(() => setStatus("complete")));
        }
        else {
            ifMounted(() => setInspection(undefined));
            ifMounted(() => setStatus("complete"));
        }

        return (): void => {
            isMounted = false;
        };
    }, [id]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [status, inspection, fetch];
};

export default useInspection;