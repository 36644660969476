import { NodeID, NodeType, encodeNodeId } from "../../lib/nodeIdentifier";
import { AddressNode, IAddress } from "../../store/types";

export interface AddressSelector {
    readonly owner: AddressOwner;
    readonly onSelect?: (address: AddressDetailsData) => void;
    readonly onUpdate?: (address: AddressDetailsData) => void;
}

export interface AddressField extends AddressSelector {
    readonly editing?: boolean;
    readonly onClear?: () => void;
}

export interface AddressDetailsData {
    readonly addressNodeId?: string;
    readonly addressId?: number;
    readonly description?: string;
    readonly streetDescription?: string;
    readonly locality?: string;
    readonly town?: string;
    readonly postCode?: string;
}

type PropTypes<T> = T[keyof T];

export type AddressDetailsKeys = keyof AddressDetailsData;
export type AddressDetailsTypes = PropTypes<AddressDetailsData>;

export type AddressOwner = "water source" | "scheme";


const isAddress = (source: AddressDetailsData | AddressNode | IAddress): source is IAddress => {
    return !("addressNodeId" in source);
};

const getAddressNodeId = (source: AddressDetailsData | AddressNode | IAddress): string | undefined => {
    const { addressId } = source;
    const addressNodeId = isAddress(source)
        ? addressId 
            ? encodeNodeId(NodeID(NodeType.ADDRESS, addressId)) 
            : undefined
        : source.addressNodeId;
    return addressNodeId;
};

export const toAddressDetails = (source: AddressDetailsData | AddressNode | IAddress): AddressDetailsData => {
    const { addressId, streetDescription, locality, town, postCode } = source;
    const addressNodeId = getAddressNodeId(source);
    if ("paon" in source && source.paon?.description) {
        return { 
            addressId,
            addressNodeId,
            description: source.paon?.description,
            streetDescription,
            locality,
            town,
            postCode
        };
    }
    if ("description" in source && source.description) {
        return { 
            addressId,
            addressNodeId,
            description: source.description,
            streetDescription,
            locality,
            town,
            postCode
        };
    }
    return { 
        addressId,
        addressNodeId,
        streetDescription,
        locality,
        town,
        postCode
    };
};

export const toAddressNode = (source: AddressDetailsData | AddressNode | IAddress): AddressNode => {
    const { addressId, streetDescription, locality, town, postCode } = source;
    const addressNodeId = getAddressNodeId(source);
    if ("paon" in source && source.paon?.description) {
        return { 
            addressId,
            addressNodeId,
            paon: {
                description: source.paon?.description
            },
            streetDescription,
            locality,
            town,
            postCode
        };
    }
    if ("description" in source && source.description) {
        return { 
            addressId,
            addressNodeId,
            paon: {
                description: source.description
            },
            streetDescription,
            locality,
            town,
            postCode
        };
    }
    return { 
        addressId,
        addressNodeId,
        streetDescription,
        locality,
        town,
        postCode
    };
};

export const isAddressEqual = (a: IAddress | undefined | null, b: IAddress | undefined | null): boolean => {
    if (a && b) {
        if (a.addressId !== b.addressId) {
            return false;
        }
        const result =
            a.paon?.description === b?.paon?.description &&
            a.streetDescription === b?.streetDescription &&
            a.locality === b?.locality &&
            a.town === b?.town &&
            a.postCode === b?.postCode;
        return result;
    }
    if (a && !b) {
        return false;
    }
    if (!a && b) {
        return false;
    }
    // both undefined/null
    return true;
};
