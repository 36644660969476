import React from 'react';

import Typography from '@material-ui/core/Typography';

import Dialog from '../../../dialog/dialog';
import DialogActions from '../../../dialog/dialogActions';
import DialogTitle from '../../../dialog/dialogTitle';
import DialogContent from '../../../dialog/dialogContent';

interface IEditNoteDialogProps {
    readonly title: string;
    readonly message: string;
    readonly isOpen: boolean
    readonly onCancel?: () => void;
    readonly onConfirm?: () => void;
}

const EditNoteDialog = (props: IEditNoteDialogProps): JSX.Element => {
    const { title, message, isOpen, onCancel, onConfirm } = props;

    const handleCancelClick = (): void => {
        onCancel?.();
    };

    const handleConfirmClick = (): void => {
        onConfirm?.();
    };

    return (
        <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={handleCancelClick}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <button id="cancelEdit-no-button" className="action-button" onClick={handleCancelClick}>No</button>
                <button id="cancelEdit-yes-button" className="action-button action-button--cancel" onClick={handleConfirmClick}>Yes</button>
            </DialogActions>
        </Dialog>
    );
};

export default EditNoteDialog;