import React from "react";

import CategoryIcon, { CategoryIconProps } from "./categoryIcon";

const Unknown = (props: CategoryIconProps): JSX.Element => (
    <CategoryIcon title="UNKNOWN" className={props.className} width={props.width ?? 24} height={props.height ?? 24}>
        <rect x="0" y="0" width="24" height="24" stroke="#434446" strokeWidth="1" strokeOpacity="1" fill="#a7a8aa" fillOpacity="1" />
        <text x="3.1229746" y="23.319578" transform="scale(1.169634,0.85496832)"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="25.00352287px"
            fontFamily="sans-serif"
            letterSpacing="0px"
            wordSpacing="0px"
            fill="#434446"
            fillOpacity="1"
            stroke="none"
            strokeWidth="0.6250881"
            style={{ lineHeight: "1.25" }}>
            <tspan x="3.1229746" y="23.319578" role="line" strokeWidth="0.6250881">?</tspan>
        </text>
    </CategoryIcon>
);

export default React.memo(Unknown);