import React, { Component } from 'react';
import { List } from '@3tc/shared-components';

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import { formatLastEvent } from '../../../../lib/Utils';
import {
    ILookupItem,
    IDefect,
    IInspection,
    IMapSettings,
    IWaterSource,
    Repair,
    ObjectType
} from '../../../../store/types';
import { WaterSourceTab } from '../../../../components/dataTabs/DataTabsContext';
import NoteList from '../../../../components/notes/components/noteList';
import TabPanel from '../../../../components/tabs/TabPanel';
import { ContentType } from '../ViewSelectedChild';
import ViewSelectedTab, { ListConfig, ConfigBuilder, ListClickHandler, ListItem } from '../ViewSelectedTab';
import WaterSourceDetailsTab from '../WaterSourceDetailsTab';

import styles from './ViewWaterSource.module.scss';

interface IViewWaterSourceProps {
    readonly selectedWaterSource: IWaterSource;
    readonly statuses: ILookupItem[];
    readonly mapSettings: IMapSettings;
    readonly inspections: IInspection[];
    readonly defects: IDefect[];
    readonly repairs: Repair[];
    readonly selectedDefect?: IDefect;
    readonly selectedInspection?: IInspection;
    readonly selectedRepair?: Repair;
    readonly clearGlobalToast: () => void;
    readonly onRefreshDefects?: () => void;
    readonly onRefreshInspections?: () => void;
    readonly onRefreshRepairs?: () => void;
    readonly setSelectedDefect: (defect?: IDefect) => void;
    readonly setSelectedInspection: (inspection?: IInspection) => void;
    readonly setSelectedRepair: (repair?: Repair) => void;
}

interface IViewWaterSourceState {
    readonly selectedChild?: ContentType;
    readonly selectedTab: WaterSourceTab;
}

const formatTitle = (waterSource: IWaterSource): string => {
    return `Water source ${waterSource.waterSourceId}`;
};

const bindDefectListConfig: ConfigBuilder<IDefect> = (clickHandler: ListClickHandler<IDefect>) => {
    const config: ListConfig<IDefect> = {
        titleKey: 'defectId',
        infoKey: 'lastEventDescription',
        titleLeftKey: 'typeDescription',
        titleAttrKeys: ['defectId', 'lastEventDescription', 'typeDescription'],
        clickHandler
    };
    return config;
};

const bindInspectionListConfig: ConfigBuilder<IInspection> = (clickHandler: ListClickHandler<IInspection>) => {
    const config: ListConfig<IInspection> = {
        titleKey: 'inspectionId',
        infoKey: 'lastEventDescription',
        titleLeftKey: 'typeDescription',
        titleAttrKeys: ['inspectionId', 'lastEventDescription', 'typeDescription'],
        clickHandler
    };
    return config;
};

const bindRepairListConfig: ConfigBuilder<Repair> = (clickHandler: ListClickHandler<Repair>) => {
    const config: ListConfig<Repair> = {
        titleKey: 'repairId',
        infoKey: 'lastEventDescription',
        titleLeftKey: 'typeDescription',
        titleAttrKeys: ['repairId', 'lastEventDescription', 'typeDescription'],
        clickHandler
    };
    return config;
};

const toObjectType = (tab: WaterSourceTab): ObjectType => {
    switch (tab) {
        case WaterSourceTab.INSPECTIONS:
            return "INSPECTION";
        case WaterSourceTab.DEFECTS:
            return "DEFECT";
        case WaterSourceTab.REPAIRS:
            return "REPAIR";
        default:
            return "WATER_SOURCE"
    }
};

class ViewWaterSource extends Component<IViewWaterSourceProps, IViewWaterSourceState> {
    constructor(props: IViewWaterSourceProps) {
        super(props);

        this.state = { selectedTab: WaterSourceTab.WATER_SOURCE };
    }

    public componentDidUpdate(): void {
        document.title = formatTitle(this.props.selectedWaterSource);
    }

    public componentWillUnmount(): void {
        document.title = '3tc - Water Management';
    }

    public render(): JSX.Element {
        const { selectedWaterSource, mapSettings } = this.props;
        if (selectedWaterSource) {
            const handleTabChange = (_: React.ChangeEvent<Record<string, unknown>>, value: any): void => {
                this.setSelectedChild(undefined);
                this.setState({ selectedTab: value as number });
            };
            const { selectedTab } = this.state;

            const { selectedDefect, onRefreshDefects, setSelectedDefect } = this.props;
            const defectListConfig = bindDefectListConfig(defect => {
                setSelectedDefect(defect);
                this.setSelectedChild("DEFECT");
            });

            const { selectedInspection, onRefreshInspections, setSelectedInspection } = this.props;
            const inspectionListConfig = bindInspectionListConfig(inspection => {
                this.props.setSelectedInspection(inspection);
                this.setSelectedChild("INSPECTION");
            });

            const { selectedRepair, onRefreshRepairs, setSelectedRepair } = this.props;
            const repairListConfig = bindRepairListConfig(repair => {
                this.props.setSelectedRepair(repair);
                this.setSelectedChild("REPAIR");
            });

            return (
                <div className={styles.root}>
                    <Typography variant="h6" className={styles.waterSourceTitle}>
                        {formatTitle(selectedWaterSource)}
                    </Typography>
                    <div className={styles.tabBar}>
                        <Tabs variant="fullWidth" scrollButtons="auto" value={selectedTab} onChange={handleTabChange}>
                            <Tab label={<Typography variant="caption">Water Source</Typography>} id="WATER_SOURCE" className={styles.tab} />
                            <Tab label={<Typography variant="caption">Inspections</Typography>} id="INSPECTIONS" className={styles.tab} />
                            <Tab label={<Typography variant="caption">Defects</Typography>} id="DEFECTS" className={styles.tab} />
                            <Tab label={<Typography variant="caption">Repairs</Typography>} id="REPAIRS" className={styles.tab} />
                        </Tabs>
                    </div>
                    <div className={styles.tabContainer}>
                        <TabPanel value={selectedTab} index={WaterSourceTab.WATER_SOURCE}>
                            <WaterSourceDetailsTab waterSource={selectedWaterSource} mapSettings={mapSettings} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={WaterSourceTab.INSPECTIONS}>
                            <ViewSelectedTab child={selectedInspection} config={inspectionListConfig} items={this.buildInspectionList()} onCloseItem={setSelectedInspection} onRefresh={onRefreshInspections} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={WaterSourceTab.DEFECTS}>
                            <ViewSelectedTab child={selectedDefect} config={defectListConfig} items={this.buildDefectList()} onCloseItem={setSelectedDefect} onRefresh={onRefreshDefects} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={WaterSourceTab.REPAIRS}>
                            <ViewSelectedTab child={selectedRepair} config={repairListConfig} items={this.buildRepairList()} onCloseItem={setSelectedRepair} onRefresh={onRefreshRepairs} />
                        </TabPanel>
                    </div>
                    <div className={styles['print-list']}>
                        <h4>Watersource Notes</h4>
                        <NoteList notes={[]} canDeleteNotes={false} canEditNotes={false} objectType={toObjectType(selectedTab)} />
                        <h4>Inspections</h4>
                        <List items={this.buildInspectionList()} config={inspectionListConfig} clickHandler={inspectionListConfig.clickHandler} />
                        <h4>Defects</h4>
                        <List items={this.buildDefectList()} config={defectListConfig} clickHandler={defectListConfig.clickHandler} />
                        <h4>Repairs</h4>
                        <List items={this.buildRepairList()} config={repairListConfig} clickHandler={repairListConfig.clickHandler} />
                    </div>
                </div>
            );
        }
        return <React.Fragment />;
    }

    private buildDefectList(): ListItem<IDefect>[] {
        const items = this.props.defects.map(defect => ({
            ...defect,
            lastEventDescription: formatLastEvent(defect.lastEvent),
            typeDescription: defect.type.displayText
        }));
        return items;
    }

    private buildInspectionList(): ListItem<IInspection>[] {
        const items = this.props.inspections.map(inspection => ({
            ...inspection,
            lastEventDescription: formatLastEvent(inspection.lastEvent),
            typeDescription: inspection.type.displayText
        }));
        return items;
    }

    private buildRepairList(): ListItem<Repair>[] {
        const items = this.props.repairs.map(repair => ({
            ...repair,
            lastEventDescription: formatLastEvent(repair.lastEvent),
            typeDescription: repair.repairType?.displayText ?? ""
        }));
        return items;
    }

    private setSelectedChild(selectedChild?: ContentType): void {
        this.setState({ selectedChild });
    }
}

export type { IViewWaterSourceProps, IViewWaterSourceState };
export { bindDefectListConfig, bindInspectionListConfig, bindRepairListConfig };
export default ViewWaterSource;
