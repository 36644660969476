import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import SearchIcon from "@material-ui/icons/Search";

import useMountedEffect from "../../api/hooks/useMountedEffect";
import { SearchInput, useMobileSearch } from "../../components/mobileSearch";
import View from "../../components/shared/view";
import useStyles from "./MobileSearch.styles";
import MobileSearchResult from "../../components/mobileSearch/MobileSearchResult";

const MobileSearch = (): JSX.Element => {
    const classes = useStyles();

    const [searchInput, setSearchInput] = useState<string>("");
    const [disableSearch, setDisableSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [status, result, search] = useMobileSearch("");

    const handleClearClick = (): void => {
        setSearchInput("");
        setShowResults(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = event.target.value;
        setSearchInput(value);
    };

    const handleResultsClose = (): void => {
        setShowResults(false);
    };

    const handleSearchClick = (): void => {
        setShowResults(false);
        setDisableSearch(true);
        setLoading(true);
        search(searchInput);
    };

    useMountedEffect(ifMounted => {
        const isEmpty = searchInput.trim() === "";
        ifMounted(() => setDisableSearch(isEmpty));
        if (isEmpty) {
            ifMounted(() => setShowResults(false));
        }
    }, [searchInput]);

    useMountedEffect(ifMounted => {
        if (status === "complete") {
            ifMounted(() => setShowResults(true));
            ifMounted(() => setLoading(false));
            ifMounted(() => setDisableSearch(false));
        }
    }, [status, result, result?.waterSourceNodeId]);

    return (
        <View title="Search" backButton disableRefresh>
            <div className={classes.root}>
                <SearchInput
                    id="search-input"
                    placeholder="Find a Water Source"
                    helperText="Enter a water source id, then tap Search."
                    value={searchInput}
                    onChange={handleInputChange}
                    onClear={handleClearClick}
                />
                <div className={classes.actionsContainer}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<SearchIcon />}
                        disabled={disableSearch}
                        onClick={handleSearchClick}
                    >
                        {loading ? <CircularProgress size={24} /> : "Search"}
                    </Button>
                </div>
                <MobileSearchResult show={showResults} searchTerm={searchInput} result={result} onClose={handleResultsClose} />
            </div>
        </View>
    )
};

export default MobileSearch;