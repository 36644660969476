import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { NodeID } from "../../../lib/nodeIdentifier";
import { useRepair } from "../../appWindowContext";
import useRepairs from "../../appWindowContext/hooks/useRepairs";
import { setDialogOpen } from "../../../store/actions/app";
import { IWaterSource } from "../../../store/types";
import RepairPanelBase, { IRepairPanelProps } from "./RepairPanel.view";

const RepairPanel = ({ waterSource }: { readonly waterSource: IWaterSource; }): JSX.Element => {
    const dispatch = useDispatch();

    const [repairs, refreshRepairs] = useRepairs(waterSource.waterSourceNodeId);
    const { instance: repair, change: changeRepair, clear: clearRepair, refresh: refreshRepair } = useRepair();

    const createSuccess = useSelector(state => state.repairs.createRepairSuccess);
    const prevCreateSuccess = useRef(createSuccess);
    useEffect(() => {
        if (prevCreateSuccess.current !== createSuccess && createSuccess) {
            refreshRepairs();
        }
    }, [createSuccess, refreshRepairs]);

    const props: IRepairPanelProps = {
        selectedRepair: repair,
        repairs,
        onClearRepair: (): void => {
            clearRepair();
        },
        onRefreshRepair: async (): Promise<void> => {
            await refreshRepair();
            refreshRepairs();
        },
        onSelectRepair: (id: NodeID): void => {
            changeRepair(id);
        },
        showCreateRepairDialog: (category): void => {
            switch (category) {
                case "external":
                    dispatch(setDialogOpen("createExternalRepair"));
                    return;
                case "internal":
                    dispatch(setDialogOpen("createInternalRepair"));
                    return;
            }
        }
    };
    return <RepairPanelBase {...props} />;
};

export default RepairPanel;