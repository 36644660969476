import React from "react";
import { OverflowYProperty } from "csstype";

import { withStyles, Theme, StyleRules, WithStyles, createStyles } from "@material-ui/core/styles";
import MuiDialogContent, { DialogContentProps as MuiDialogContentProps } from "@material-ui/core/DialogContent";

const styles = (theme: Theme): StyleRules<"root", Partial<DialogContentProps>> => createStyles({
    root: {
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        overflowY: (props): OverflowYProperty | undefined => props.overflowY
    }
});

interface DialogContentProps extends WithStyles<typeof styles> {
    readonly overflowY?: OverflowYProperty;
}

const DialogContent = withStyles(styles)((props: DialogContentProps & Omit<MuiDialogContentProps, keyof DialogContentProps>) => {
    const { classes, children, overflowY, ...other } = props;
    return (
        <MuiDialogContent classes={classes} {...other}>
            {children}
        </MuiDialogContent>
    );
});

export default DialogContent;