import React, { useState, HTMLAttributes } from "react";

import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";

import EditWaterSourceAction from "./EditWaterSourceAction";
import MoveWaterSourceAction from "./MoveWaterSourceAction";
import CreateInspectionAction from "./CreateInspection";
import { WaterSourceActionProps } from "./types";
import WaterSourceActionMenuAnchor from "./WaterSourceActionMenuAnchor";
import RemoveWaterSourceAction from "./RemoveWaterSourceAction";

const WaterSourceActionMenu = ({ schemeNodeId, waterSourceNodeId, ...props }: WaterSourceActionProps & HTMLAttributes<HTMLElement>): JSX.Element => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
        setMenuAnchor(event.currentTarget);
    };
    const handleCloseMenu = (): void => {
        setMenuAnchor(null);
    };
    const actionProps: WaterSourceActionProps = {
        schemeNodeId,
        waterSourceNodeId
    };
    return (
        <React.Fragment>
            <WaterSourceActionMenuAnchor title="Actions" onClick={handleOpenMenu} />
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClick={handleCloseMenu}
                onClose={handleCloseMenu}
                {...props}
            >
                <EditWaterSourceAction {...actionProps} />
                <MoveWaterSourceAction {...actionProps} />
                <CreateInspectionAction {...actionProps} />
                <Divider />
                <RemoveWaterSourceAction {...actionProps} />
            </Menu>
        </React.Fragment>
    );
};

export type { WaterSourceActionProps };
export default WaterSourceActionMenu;