import React, { Component } from 'react';
import { LocalDate } from '@js-joda/core';
import cx from "classnames";

import { StateValidators, valid, validateState, requiredObject, requiredString, requiredLocalDate, validationStyle } from '../../../lib/validation';
import { ILookupItem, IInspection, IWaterSource, IQuickSearchItem, IDefectInput } from '../../../store/types';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import InlineQuickSearch from '../../inlineQuickSearch';
import LabelledField, { SelectOption } from '../../labelledField';
import './createDefectDialog.scss';

interface ICreateDefectDialogProps {
    readonly isOpen: boolean;
    readonly defectTypes: ILookupItem[];
    readonly selectedWaterSource?: IWaterSource;
    readonly selectedInspection?: IInspection;
    readonly getInspections: (waterSourceNodeId: string) => Promise<IInspection[]>;
    readonly getWaterSource: (variables) => void;
    readonly onClose?: () => void;
    readonly onCreateDefect?: (data: IDefectInput) => void;
}

interface CreateDefectData {
    readonly waterSource: IQuickSearchItem | undefined;
    readonly inspection: SelectOption | undefined;
    readonly defectType: SelectOption | undefined;
    readonly defectReportedDate: LocalDate | undefined;
    readonly reportedBy: string | undefined;
}

interface ICreateDefectDialogState {
    readonly inspections: IInspection[];
    readonly data: CreateDefectData;
}

type DataKeys = keyof CreateDefectData;
type DataTypes = CreateDefectData[DataKeys];

const validators: StateValidators<CreateDefectData> = {
    waterSource: requiredObject,
    inspection: valid,
    defectType: requiredObject,
    defectReportedDate: requiredLocalDate,
    reportedBy: requiredString,
};

const validateForm = validateState(validators);

const formatInspection = ({ inspectionId, description }: IInspection): string => {
    return description
        ? `${inspectionId} - ${description}`
        : `${inspectionId}`;
};

class CreateDefectDialog extends Component<ICreateDefectDialogProps, ICreateDefectDialogState> {
    constructor(props: ICreateDefectDialogProps) {
        super(props);

        const { selectedWaterSource, selectedInspection } = props;
        this.state = {
            inspections: [],
            data: {
                waterSource: selectedWaterSource,
                inspection: selectedInspection
                    ? { value: selectedInspection.inspectionNodeId, displayText: formatInspection(selectedInspection), sortOrder: 0, enabled: true }
                    : undefined,
                defectType: undefined,
                defectReportedDate: undefined,
                reportedBy: undefined,
            }
        };
    }

    public componentDidMount(): void {
        if (this.state.data.waterSource) {
            this.props.getInspections(this.state.data.waterSource.waterSourceNodeId)
                .then(inspections => this.setState({ inspections }))
                .catch(() => this.setState({ inspections: [] }));
        }
    }

    public componentDidUpdate(_: ICreateDefectDialogProps, prevState: ICreateDefectDialogState): void {
        if (this.state.data.waterSource) {
            const { waterSourceNodeId: prevId } = prevState.data.waterSource ?? {};
            const { waterSourceNodeId: currentId } = this.state.data.waterSource;
            if (currentId !== prevId) {
                this.props.getInspections(currentId)
                    .then(inspections => this.setState({ inspections }))
                    .catch(() => this.setState({ inspections: [] }));
            }
        }
    }

    public render(): JSX.Element {
        const handleClose = (): void => this.props.onClose?.();

        const handleSearchClear = (): void => {
            this.setState(current => ({
                data: { ...current.data, waterSource: undefined, inspection: undefined },
                inspections: []
            }));
        };
        const handleSearchResult = (item: IQuickSearchItem): void => {
            this.setState(current => ({ data: { ...current.data, waterSource: { ...item } } }));
        };

        const handleChange = (key: DataKeys) => {
            return (value: DataTypes): void => {
                this.setState(current => ({ data: { ...current.data, [key]: value } }));
            };
        };
        const handleClear = (key: DataKeys) => {
            return (): void => {
                this.setState(current => ({ data: { ...current.data, [key]: undefined } }));
            };
        };

        const handleSave = (): void => {
            this.handleSave();
        };

        const { inspections, data } = this.state;
        const disableSave = !validateForm(this.state.data);
        return (
            <Dialog fullWidth maxWidth="sm" overflowY="unset" open={this.props.isOpen} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>New Defect</DialogTitle>
                <DialogContent overflowY="unset">
                    <InlineQuickSearch
                        result={data.waterSource}
                        invalid={!validators.waterSource(data.waterSource)}
                        onClear={handleSearchClear}
                        onResult={handleSearchResult}
                    />
                    <LabelledField
                        fieldType="select"
                        id="defect_inspections"
                        label="Inspection"
                        classes={{ container: 'input__group', label: 'input__label' }}
                        editing
                        value={data.inspection}
                        selectOptions={inspections.map<SelectOption>(i => ({ value: i.inspectionNodeId, displayText: formatInspection(i), sortOrder: 0, enabled: true }))}
                        onChange={handleChange("inspection")}
                        onClear={handleClear("inspection")}
                    />
                    <LabelledField
                        fieldType="select"
                        id="defect_type"
                        label="Defect Type *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.defectType)(data.defectType) }}
                        editing
                        value={data.defectType}
                        selectOptions={this.props.defectTypes}
                        onChange={handleChange('defectType')}
                        onClear={handleClear('defectType')}
                    />
                    <LabelledField
                        fieldType="date"
                        id="defect_report_date"
                        label="Defect Reported Date *"
                        classes={{ container: 'input__group', label: 'input__label', input: cx("createDefect-dateInput", validationStyle(validators.defectReportedDate)(data.defectReportedDate)) }}
                        editing
                        value={data.defectReportedDate}
                        onChange={handleChange('defectReportedDate')}
                    />
                    <LabelledField
                        fieldType="text"
                        id="inspection_reportedBy"
                        label="Reported By *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.reportedBy)(data.reportedBy) }}
                        editing
                        value={data.reportedBy}
                        onChange={handleChange('reportedBy')}
                    />
                </DialogContent>
                <DialogActions>
                    <button id="cancel_button" className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button id="save_button" className="action-button" disabled={disableSave} onClick={handleSave}>Save</button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleSave(): void {
        const { waterSource, defectType, defectReportedDate, reportedBy, inspection } = this.state.data;
        if (waterSource && defectType && defectReportedDate && reportedBy) {
            this.props.onClose?.();
            this.props.onCreateDefect?.({
                dateReported: defectReportedDate,
                reportedBy: reportedBy,
                typeId: Number(defectType.value),
                parentId: inspection
                    ? String(inspection.value)
                    : waterSource.waterSourceNodeId
            });
        }
        else {
            console.warn("CreateDefect", "Missing required field values.", this.state);
        }
    }
}

export type { ICreateDefectDialogProps };
export default CreateDefectDialog;