import React from "react";

import Box from "@material-ui/core/Box";

import { NodeID } from "../../../../../lib/nodeIdentifier";
import { IScheme } from "../../../../../store/types";
import CollapsibleBox from "./CollapsibleBox";
import SchemeLink from "./SchemeLink";
import Optional from "../../../../shared/Optional";

interface CollapsibleListProps {
    readonly schemes: IScheme[];
    readonly id?: string;
    readonly onClick?: (id: NodeID) => void;
}

const CollapsibleList = ({ schemes, id, onClick }: CollapsibleListProps): JSX.Element => {
    const [head, ...tail] = [...schemes].sort((a, b) => b.schemeId - a.schemeId);
    return (
        <Box id={id} display="flex" flexDirection="column">
            <SchemeLink id={`waterSource-schemeLink-${head.schemeId}`} scheme={head} onClick={onClick} />
            <Optional hidden={tail.length === 0}>
                <CollapsibleBox>
                        {tail.map(scheme => <SchemeLink id={`waterSource-schemeLink-${scheme.schemeId}`} key={scheme.schemeNodeId} scheme={scheme} onClick={onClick} />)}
                </CollapsibleBox>
            </Optional>
        </Box>
    );
};

export type { CollapsibleListProps };
export default CollapsibleList;