import React, { useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '../typography';

import { createPortal } from 'react-dom';

interface GlobalNoticeProps {
    readonly show?: boolean;
    readonly message?: string;
    readonly key?: string;
    readonly onKeyDown?: (event: KeyboardEvent) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        position: "absolute",
        top: "80px",
        left: "50%",
        transform: "translate(-50%)",
        padding: "10px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main
    }
}));

const GlobalNotice = ({ show, message, key = "Escape", onKeyDown }: GlobalNoticeProps): JSX.Element => {
    const styles = useStyles();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent): void => {
            if (event.key === key) {
                onKeyDown?.(event);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return (): void => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [key, onKeyDown]);

    if (show && message) {
        return createPortal(
            <div className={styles.root}>
                <Typography>{message}</Typography>
            </div>,
            document.body
        );
    }
    return <React.Fragment />;
};

export type { GlobalNoticeProps };
export default GlobalNotice;