import { LocalDate } from "@js-joda/core";

import {
    ILookupItem,
    LookupItem,
    IPoint,
    getMeasurementValue,
    IOperationalStatus,
    IWaterSource,
    WaterSourceCategory,
    IHazard,
    AddressNode
} from "../../../store/types";
import { SelectOption } from "../../labelledField";
import { HazardItem } from "./types";
import { IPrepareHazardsToSaveData, PreparedHazardsToSave } from "./types";
import { toAddressNode } from "../../address";

export interface IWaterSourceEditData {
    readonly isActive: boolean;
    // all water sources
    readonly classification: SelectOption | undefined;
    readonly operationalStatusOverride: SelectOption | undefined;
    readonly status: SelectOption | undefined;
    readonly address: AddressNode | undefined;
    readonly locationCoordinates: IPoint | undefined;
    readonly locationDescription: string | undefined;
    readonly riskSeverity: SelectOption | undefined;
    readonly nextInspectionDate: LocalDate | undefined;
    readonly inspectionFrequency: number | undefined;
    readonly station: SelectOption | undefined;
    readonly organisation: SelectOption | undefined;
    readonly additionalInfo: string | undefined;
    readonly controlMeasures: SelectOption[];
    readonly isSecondPersonRequired: boolean | undefined;
    readonly roadSpeed: SelectOption | undefined;
    readonly hazardInformation: string | undefined;
    readonly hazardFlag: SelectOption | undefined;
    readonly hazards: SelectOption[];
    readonly hazardSeverity: SelectOption | undefined;
    // hydrant
    readonly assetReference: string | undefined;
    readonly mainsSize: number | undefined;
    readonly mainsSizeUnit: SelectOption | undefined;
    readonly flowRate: number | undefined;
    readonly pressure: number | undefined;
    readonly surface: SelectOption | undefined;
    readonly hydrantLocation: SelectOption | undefined;
    readonly plateLocation: SelectOption | undefined;
    readonly plateDistance: number | undefined;
    // ews
    readonly accessDescription: string | undefined;
    readonly capacity: number | undefined;
    readonly isHardstanding: boolean | undefined;
    readonly isSeasonal: boolean | undefined;
    readonly lift: number | undefined;
    readonly pumpCarryDistance: number | undefined;
    readonly pumpCount: number | undefined;
    readonly owner: string | undefined;
}

export const formatMapRef = (coordinates?: IPoint): string =>
    coordinates
        ? `${Math.trunc(coordinates.x)}, ${Math.trunc(coordinates.y)}`
        : "-";

enum OperationalStatus { Operable = 1, Defective = 2, OperableDefective = 3 }

export const operationalStatuses: ILookupItem[] = [
    LookupItem(OperationalStatus.Operable, "OPERABLE", 1),
    LookupItem(OperationalStatus.Defective, "DEFECTIVE", 2),
    LookupItem(OperationalStatus.OperableDefective, "OPERABLE DEFECTIVE", 3)
];

const opsStatusOperable = 0;
const opsStatusDefective = 1;
const opsStatusOperableDefective = 2;

const opsStatus = (isOperable: boolean, isDefective: boolean): SelectOption => {
    if (isOperable && isDefective) {
        return operationalStatuses[opsStatusOperableDefective];
    }
    if (isDefective) {
        return operationalStatuses[opsStatusDefective];
    }
    if (isOperable) {
        return operationalStatuses[opsStatusOperable];
    }
    throw Error("Invalid Operational Status; 'isOperable' and 'isDefective' cannot both be 'false'.");
}

const getOpsStatusOverride = (isOperable: IOperationalStatus, isDefective: IOperationalStatus): SelectOption | undefined => {
    const overrideIsOperable = isOperable.isOverridden && isOperable.value;
    const overrideIsDefective = isDefective.isOverridden && isDefective.value;
    if (overrideIsOperable && overrideIsDefective) {
        return operationalStatuses[opsStatusOperableDefective];
    }
    if (overrideIsDefective) {
        return operationalStatuses[opsStatusDefective];
    }
    if (overrideIsOperable) {
        return operationalStatuses[opsStatusOperable];
    }
    return undefined;
};

export const getOverrideState = (operationalStatusOverride: SelectOption | undefined): string =>
    operationalStatusOverride
        ? "(manual)"
        : "(auto)";

export const getOperationalStatus = (isOperable: IOperationalStatus, isDefective: IOperationalStatus, operationalStatusOverride: SelectOption | undefined): SelectOption | undefined =>
    typeof operationalStatusOverride === "undefined"
        ? opsStatus(isOperable.value, isDefective.value)
        : operationalStatusOverride;

export const getClassificationId = (data: IWaterSourceEditData, categoryId: number): number | null =>
    categoryId === WaterSourceCategory.UNKNOWN
        ? null
        : data.classification
            ? Number(data.classification.value)
            : null;

export const getIsDefective = (data: IWaterSourceEditData): boolean | null => {
    const { operationalStatusOverride: operableStatusOverride } = data;
    if (operableStatusOverride) {
        switch (operableStatusOverride.value) {
            case OperationalStatus.Defective:
            case OperationalStatus.OperableDefective:
                return true;
            default:
                return false;
        }
    }
    return null; // (auto)
}

export const getIsOperable = (data: IWaterSourceEditData): boolean | null => {
    const { operationalStatusOverride: operableStatusOverride } = data;
    if (operableStatusOverride) {
        switch (operableStatusOverride.value) {
            case OperationalStatus.Operable:
            case OperationalStatus.OperableDefective:
                return true;
            default:
                return false;
        }
    }
    return null; // (auto)
}

export const getLocationCoordinates = (data: IWaterSourceEditData, currentCoordinate: IPoint, newCoordinates: [number, number] | undefined): IPoint =>
    newCoordinates
        ? {
            x: newCoordinates[0],
            y: newCoordinates[1]
        }
        : data.locationCoordinates ? data.locationCoordinates : currentCoordinate;

export const getStatus = (data: IWaterSourceEditData): number | null => {
    return data.status
        ? Number(data.status.value)
        : null;
};

export const getLocationAddressId = (data: IWaterSourceEditData): number | null => {
    return data.address?.addressId
        ? data.address.addressId
        : null;
};

export const getLocationDescription = (data: IWaterSourceEditData): string | null =>
    data.locationDescription !== undefined
        ? data.locationDescription
        : null;

export const getAdditionalInfo = (data: IWaterSourceEditData): string | null =>
    data.additionalInfo !== undefined
        ? data.additionalInfo
        : null;

export const getRiskSeverity = (data: IWaterSourceEditData): number | null =>
    data.riskSeverity !== undefined && data.riskSeverity !== null
        ? Number(data.riskSeverity.value)
        : null;

export const getHazardSeverity = (data: IWaterSourceEditData): number | null =>
    data.hazardSeverity !== undefined && data.hazardSeverity !== null
        ? Number(data.hazardSeverity.value)
        : null;

export const getNextInspectionDate = (data: IWaterSourceEditData): LocalDate | null =>
    data.nextInspectionDate !== undefined
        ? data.nextInspectionDate
        : null;

export const getInspectionFrequency = (data: IWaterSourceEditData): number | null =>
    data.inspectionFrequency !== undefined
        ? data.inspectionFrequency
        : null;

export const getStation = (data: IWaterSourceEditData): number | null =>
    data.station !== undefined && data.station !== null
        ? Number(data.station.value)
        : null;

export const getOrganisation = (data: IWaterSourceEditData): number | null =>
    data.organisation !== undefined && data.organisation !== null
        ? Number(data.organisation.value)
        : null;

export const getAssetReference = (data: IWaterSourceEditData, categoryId: number): string | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.assetReference !== undefined
            ? data.assetReference
            : null
        : undefined;

export const getMainsSize = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.mainsSize !== undefined
            ? data.mainsSize
            : null
        : undefined;

export const getMainsSizeUnit = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.mainsSizeUnit !== undefined && data.mainsSizeUnit !== null
            ? Number(data.mainsSizeUnit.value)
            : null
        : undefined;

export const getFlowRate = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.flowRate !== undefined
            ? data.flowRate
            : null
        : undefined;

export const getPressure = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.pressure !== undefined
            ? data.pressure
            : null
        : undefined;

export const getSurface = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.surface !== undefined && data.surface !== null
            ? Number(data.surface.value)
            : null
        : undefined;

export const getHydrantLocation = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.hydrantLocation && data.hydrantLocation !== null
            ? Number(data.hydrantLocation.value)
            : null
        : undefined;

export const getPlateLocation = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.HYDRANT
        ? data.plateLocation && data.plateLocation !== null
            ? Number(data.plateLocation.value)
            : null
        : undefined;

export const getPlateDistance = (data: IWaterSourceEditData, categoryId: number): number | null | undefined => 
    categoryId === WaterSourceCategory.HYDRANT
        ? data.plateDistance != undefined
            ? data.plateDistance
            : null
        : undefined;

export const getAccessDescription = (data: IWaterSourceEditData, categoryId: number): string | null | undefined =>
    categoryId === WaterSourceCategory.EWS
        ? data.accessDescription !== undefined
            ? data.accessDescription
            : null
        : undefined;

export const getIsHardstanding = (data: IWaterSourceEditData, categoryId: number): boolean | null | undefined =>
    categoryId === WaterSourceCategory.EWS
        ? data.isHardstanding !== undefined
            ? data.isHardstanding
            : null
        : undefined;

export const getLift = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.EWS
        ? data.lift !== undefined
            ? data.lift
            : null
        : undefined;

export const getCapacity = (data: IWaterSourceEditData, categoryId: number): number | null | undefined =>
    categoryId === WaterSourceCategory.EWS
        ? data.capacity !== undefined
            ? data.capacity
            : null
        : undefined;

export const getOwner = (data: IWaterSourceEditData, categoryId: number): string | null | undefined =>
    categoryId === WaterSourceCategory.EWS
        ? data.owner !== undefined
            ? data.owner
            : null
        : undefined;

const getValue = <T>(value: T | null | undefined): T | undefined =>
    value === null
        ? undefined
        : value;

export const getControlMeasureValues = (data: IWaterSourceEditData): number[] =>
    data.controlMeasures.map(controlMeasure => Number(controlMeasure.value));

export const getRoadSpeed = (data: IWaterSourceEditData): number | null | undefined =>
    data.roadSpeed !== undefined && data.roadSpeed !== null
        ? Number(data.roadSpeed.value)
        : null;

export const getHazardInformation = (data: IWaterSourceEditData): string | null =>
    data.hazardInformation !== undefined
        ? data.hazardInformation
        : null;

export const getHazardFlag = (data: IWaterSourceEditData): number | null | undefined =>
    data.hazardFlag !== undefined && data.hazardFlag !== null
        ? Number(data.hazardFlag.value)
        : null;

export const getHazardTypes = (hazards?: IHazard[]): SelectOption[] =>
    hazards?.map(hazard => hazard.hazardType) ?? [];

export const resetEditData = (waterSource: IWaterSource): IWaterSourceEditData => {
    const { location, organisation, ...other } = waterSource;
    return ({
        isActive: other.isActive,
        // all water sources
        classification: other.classification,
        operationalStatusOverride: getOpsStatusOverride(other.isOperable, other.isDefective),
        status: other.status,
        address: location.address ? toAddressNode(location.address) : undefined,
        locationCoordinates: location.coordinates,
        locationDescription: location.description,
        riskSeverity: other.riskSeverity,
        nextInspectionDate: getValue(other.nextInspectionDate),
        inspectionFrequency: getValue(other.inspectionFrequency),
        station: other.station,
        organisation: organisation
            ? { value: organisation.organisationId, displayText: organisation.name ?? organisation.shortName ?? "(unknown)", enabled: true, sortOrder: 0 }
            : undefined,
        additionalInfo: getValue(other.additionalInfo),
        controlMeasures: other.controlMeasures,
        isSecondPersonRequired: other.isSecondPersonRequired,
        roadSpeed: other.roadSpeed,
        hazardInformation: other.hazardInformation,
        hazardFlag: other.hazardFlag,
        hazards: getHazardTypes(other.hazards),
        hazardSeverity: other.hazardSeverity,
        // hydrants
        assetReference: getValue(other.assetReference),
        mainsSize: getMeasurementValue(other.mainsSize),
        mainsSizeUnit: other.mainsSize ? other.mainsSize.unit : undefined,
        flowRate: getValue(other.flowRate),
        pressure: getValue(other.pressure),
        surface: other.surface,
        hydrantLocation: other.category.value === 1 ? other.hydrantLocation : undefined,
        plateLocation: other.category.value === 1 ? other.plateLocation : undefined,
        plateDistance: getValue(other.plateDistance),
        // ews
        accessDescription: getValue(other.accessDescription),
        capacity: getValue(other.capacity),
        isHardstanding: getValue(other.isHardstanding),
        isSeasonal: getValue(other.isSeasonal),
        lift: getValue(other.lift),
        owner: getValue(other.owner),
        pumpCarryDistance: getValue(other.pumpCarryDistance),
        pumpCount: getValue(other.pumpCount)
    })
};

export const defaultHazardSeverity: SelectOption = {
    displayText: "medium",
    value: 2,
    enabled: true,
    sortOrder: 2
};

export const prepareHazardsToSave = (data: IPrepareHazardsToSaveData): PreparedHazardsToSave => {
    const {
        hazardsValues = [],
        initialHazardsValues = [],
        hazardSeverityValue,
        allHazardsTypes = [],
        allHazards
    } = data;
    const addedHazardValues: number[] = [];
    const deletedHazardValues: number[] = [];

    hazardsValues.forEach(hazardValue => {
        if (!initialHazardsValues.includes(hazardValue)) {
            addedHazardValues.push(Number(hazardValue));
        }
    });

    initialHazardsValues.forEach(initialHazardValue => {
        if (!hazardsValues.includes(initialHazardValue)) {
            deletedHazardValues.push(Number(initialHazardValue));
        }
    });

    const addedHazards: HazardItem[] = addedHazardValues.map(addedValue => ({
        checked: Number(hazardSeverityValue ?? defaultHazardSeverity.value),
        displayText: allHazardsTypes.find(hazard => hazard.value === addedValue)?.displayText ?? "",
        id: "",
        value: addedValue
    }));

    const deletedHazards: IHazard[] = deletedHazardValues.map(deletedHazardValue => {
        const deletedHazard = allHazards?.find(hazard => {
            if (hazard.hazardType.value === deletedHazardValue) {
                return hazard;
            }
        });

        return deletedHazard as IHazard;
    });

    return { addedHazards, deletedHazards };
}
