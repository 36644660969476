import { saveAs } from "file-saver";
import { fileManager } from "@3tc/shared-components-ts";

const downloadDocument = async (fileStoreToken: string, fileId: number, name: string): Promise<void> => {
    try {
        const response: fileManager.v2.api.Response<Blob> = await fileManager.v2.api.downloadFileContent(fileStoreToken, fileId);
        response.match<void>(
            blob => saveAs(blob, name),
            error => console.warn("Error downloading file.", error)
        );
    }
    catch (error) {
        console.warn("Error downloading file.", error);
    }
};

export { downloadDocument };