import React, { useMemo } from "react";

import { Coordinate as olCoordinate } from "ol/coordinate";

import View from "../../components/shared/view";
import useDeviceLocation, { DeviceLocation } from "../../api/hooks/useDeviceLocation";
import NearMeMap from "../../components/nearMeMap";
import LoadingContainer from "../../components/shared/loadingContainer";
import useNearestWaterSources from "../../api/hooks/useNearestWaterSources";

const defaultFence = 700; // 1000m = 1km
const defaultLocation: olCoordinate = [0, 0];

interface NearMeViewProps {
    readonly fence?: number;
}

const NearMeView = (props: NearMeViewProps): JSX.Element => {
    const deviceLocation: DeviceLocation | undefined = useDeviceLocation();
    const finalDeviceLocation = deviceLocation === undefined || deviceLocation instanceof Error
        ? defaultLocation
        : deviceLocation;
    const fence = props.fence ? props.fence : defaultFence;
    const [status, nearestWaterSources, refresh] = useNearestWaterSources(finalDeviceLocation, fence);
    const loading = useMemo(() => status !== "complete", [status]);

    const handleViewUpdate = (): void => {
        refresh();
    };

    return (
        <View title="Near Me" backButton onRefreshClick={handleViewUpdate}>
            <LoadingContainer isLoading={loading}>
                <NearMeMap nearestWaterSources={nearestWaterSources} deviceLocation={finalDeviceLocation} />
            </LoadingContainer>
        </View>
    );
};

export type { NearMeViewProps };
export default NearMeView;
