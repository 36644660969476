import React from 'react';
import { Icon } from "@blueprintjs/core";

import { makeStyles, createStyles } from '@material-ui/core/styles';

export interface INavButtonProps {
    readonly text: string;
    readonly chevronDir: 'left' | 'right';
    readonly onClick?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        alignItems: "center",
        background: "none",
        border: "none",
        color: "white",
        cursor: "pointer",
        display: "flex",
        fontSize: "0.8rem",
        paddingLeft: 0,
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    text: {
        "& svg": {
            fill: "currentColor"
        }
    }
}));

export const NavButton = ({ chevronDir, text, onClick }: INavButtonProps): JSX.Element => {
    const styles = useStyles();
    return (
        <button className={styles.root} onClick={onClick}>
            {chevronDir === 'left' && <Icon className={styles.icon} icon="chevron-left" iconSize={18} />}
            <span className={styles.text}>{text}</span>
            {chevronDir === 'right' && <Icon className={styles.icon} icon="chevron-right" iconSize={18} />}
        </button>
    );
};

export default NavButton;
