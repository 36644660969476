import React from 'react';

import Box from '@material-ui/core/Box';

import { NodeID, encodeNodeId, parseNodeId } from '../../../lib/nodeIdentifier';
import { parseCurrency } from '../../../lib/Utils';
import { Repair, ILookupItem, IDefect, IInspection, RepairMutationData, isExternalRepair } from '../../../store/types';
import FileUploader from '../../files/fileUploader';
import { SelectOption } from '../../labelledField';
import NotesTab from '../../notes/NotesTab';
import styles from './repairControl.module.scss';
import Divider from '../../Divider';
import BackButton from '../../shared/BackButton';
import RepairCategory from './components/RepairCategory';
import RepairType from './components/RepairType';
import { RepairControlStateData } from './types';
import RepairActions from './components/RepairActions';
import RepairDefect from './components/RepairDefect';
import RepairInspection from './components/RepairInspection';
import RepairLifecycle from './components/RepairLifecycle';
import RepairCancelReason from './components/RepairCancelReason';
import RepairWorkOrderRef from './components/RepairWorkOrderRef';
import RepairVendor from './components/RepairVendor';
import RepairExpectedCost from './components/RepairExpectedCost';
import RepairPurchaseOrderRef from './components/RepairPurchaseOrderRef';
import RepairActualCost from './components/RepairActualCost';
import RepairPaymentAuthorised from './components/RepairPaymentAuthorised';
import RepairDateWorkCompleted from './components/RepairDateWorkCompleted';
import RepairInvoiceRef from './components/RepairInvoiceRef';
import RepairDateInvoiced from './components/RepairDateInvoiced';
import RepairDatePaid from './components/RepairDatePaid';
import RepairCostSavings from './components/RepairCostSavings';
import RepairDateGuaranteedUntil from './components/RepairDateGuarantedUntil';
import Optional from '../../shared/Optional';
import CancelRepairDialog from "../cancelRepairDialog";
import CloseRepairDialog from '../closeRepairDialog';
import { formatEmail } from './utils';

interface IRepairControlProps {
    readonly repairTypes: ILookupItem[];
    readonly repairVendors: ILookupItem[];
    readonly openDefects: IDefect[];
    readonly openInspections: IInspection[];
    readonly selectedRepair: Repair;
    readonly fileStoreToken: string;
    readonly onClose?: () => void;
    readonly onUpdateRepair?: (repairId: NodeID, update: RepairMutationData) => void;
    readonly onCloseRepair?: () => void;
    readonly onCancelRepair?: () => void;
}

type PropTypes<T> = T[keyof T];
type RepairDataKeys = keyof RepairControlStateData;
type RepairDataTypes = PropTypes<RepairControlStateData>;

interface IRepairControlState {
    readonly editing: boolean;
    readonly data: RepairControlStateData;
    readonly showCloseDialog: boolean;
    readonly showCancelDialog: boolean;
}

const toDefectOption = (source: Pick<IDefect, "defectId" | "type">, index: number): SelectOption => ({
    value: source.defectId,
    displayText: `${source.defectId} (${source.type.displayText})`,
    enabled: true,
    sortOrder: index
});

const toInspectionOption = (source: Pick<IInspection, "inspectionId" | "type">, index: number): SelectOption => ({
    value: source.inspectionId,
    displayText: `${source.inspectionId} (${source.type.displayText})`,
    enabled: true,
    sortOrder: index
});

const initData = (selectedRepair: Repair | undefined, openDefects: IDefect[], openInspections: IInspection[]): RepairControlStateData => ({
    selectedDefect: selectedRepair?.defect
        ? toDefectOption(selectedRepair.defect, openDefects.findIndex(d => d.defectNodeId === selectedRepair.defect?.defectNodeId))
        : undefined,
    selectedInspection: selectedRepair?.inspection
        ? toInspectionOption(selectedRepair.inspection, openInspections.findIndex(i => i.inspectionNodeId === selectedRepair.inspection?.inspectionNodeId))
        : undefined,
    repairType: selectedRepair?.repairType,
    repairVendor: selectedRepair?.repairVendor,
    expectedCost: selectedRepair?.expectedCost,
    actualCost: selectedRepair?.actualCost,
    costSaving: selectedRepair?.costSaving,
    isPaymentAuthorised: selectedRepair?.isPaymentAuthorised ?? false,
    workOrderRef: selectedRepair?.workOrderRef,
    purchaseOrderRef: selectedRepair?.purchaseOrderRef,
    invoiceRef: selectedRepair?.invoiceRef,
    dateWorkCompleted: selectedRepair?.dateWorkCompleted,
    dateInvoiced: selectedRepair?.dateInvoiced,
    datePaid: selectedRepair?.datePaid,
    dateGuaranteeExpires: selectedRepair?.dateGuaranteeExpires
});


const isNullOrWhiteSpace = (s: string | undefined | null): boolean => {
    if (s === undefined) {
        return true;
    }
    if (s === null) {
        return true;
    }
    if (s.trim() === "") {
        return true;
    }
    return false;
};

export class RepairControl extends React.Component<IRepairControlProps, IRepairControlState> {
    constructor(props: IRepairControlProps) {
        super(props)

        const { selectedRepair, openDefects, openInspections } = this.props;
        this.state = {
            editing: false,
            data: initData(selectedRepair, openDefects, openInspections),
            showCloseDialog: false,
            showCancelDialog: false
        };

        this.updateState = this.updateState.bind(this);
        this.resetData = this.resetData.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    public render(): JSX.Element {
        const { selectedRepair } = this.props;
        const { editing, data } = this.state;

        const handleNavClick = (): void => {
            this.props.onClose?.();
        };

        const handleCancel = (): void => {
            this.setState({ editing: false });
            this.resetData();
        };
        const handleEdit = (): void => {
            this.setState({ editing: true });
        };
        const handleSave = (): void => {
            this.handleSave();
        };

        const handleValue = (key: RepairDataKeys) => {
            return (value: RepairDataTypes): void => {
                this.updateState(key, value)
            };
        };
        const handleClear = (key: RepairDataKeys) => {
            return (): void => {
                handleValue(key)(undefined);
            };
        };

        const handleShowCloseRepairDialog = (): void => {
            this.setState({
                showCloseDialog: true
            });
        };
        const handleShowCancelRepairDialog = (): void => {
            this.setState({
                showCancelDialog: true
            });
        };
        const handleHideCloseRepairDialog = (): void => {
            this.setState({
                showCloseDialog: false
            }, () => this.props.onCloseRepair?.());
        };
        const handleHideCancelRepairDialog = (): void => {
            this.setState({
                showCancelDialog: false
            }, () => this.props.onCancelRepair?.());
        };

        const handleSendEmail = (): void => {
            const emailString = formatEmail(selectedRepair);
            window.open(emailString);
        };

        const isExternal = isExternalRepair(selectedRepair);
        return (
            <div className={styles.root}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <BackButton edge="start" onClick={handleNavClick} />
                        <RepairCategory repair={selectedRepair} />
                    </Box>
                    <RepairType
                        editing={editing}
                        options={this.props.repairTypes}
                        data={data}
                        onClear={handleClear("repairType")}
                        onSelect={handleValue("repairType")}
                    />
                    <RepairActions
                        isExternal={isExternal}
                        isOpenRepair={selectedRepair.isOpen}
                        hasDefect={this.state.data.selectedDefect !== undefined}
                        hasVendor={!isNullOrWhiteSpace(this.state.data.repairVendor?.emailAddress)}
                        editing={editing}
                        onCancel={handleCancel}
                        onEdit={handleEdit}
                        onSave={handleSave}
                        onCloseRepair={handleShowCloseRepairDialog}
                        onCancelRepair={handleShowCancelRepairDialog}
                        onSendEmail={isExternal ? handleSendEmail : undefined}
                    />
                </Box>
                <Divider />
                <RepairDefect
                    editing={editing}
                    options={this.props.openDefects.map(toDefectOption)}
                    data={this.state.data}
                    onClear={handleClear("selectedDefect")}
                    onSelect={handleValue("selectedDefect")}
                />
                <RepairInspection
                    editing={editing}
                    options={this.props.openInspections.map(toInspectionOption)}
                    data={this.state.data}
                    onClear={handleClear("selectedInspection")}
                    onSelect={handleValue("selectedInspection")}
                />
                {/* external repairs only */}
                <Optional hidden={!isExternal} >
                    <RepairWorkOrderRef
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("workOrderRef")}
                    />
                    <RepairVendor
                        show={isExternal}
                        editing={editing}
                        options={this.props.repairVendors}
                        data={data}
                        onClear={handleClear("repairVendor")}
                        onSelect={handleValue("repairVendor")}
                    />
                    <RepairExpectedCost
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={(value): void => handleValue("expectedCost")(parseCurrency(value))}
                    />
                    <Divider hidden={!isExternal} />
                    {/* DateOrdered */}
                    <RepairPurchaseOrderRef
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("purchaseOrderRef")}
                    />
                    <RepairActualCost
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={(value): void => handleValue("actualCost")(parseCurrency(value))}
                    />
                    <RepairPaymentAuthorised
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("isPaymentAuthorised")}
                    />
                    <RepairDateWorkCompleted
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("dateWorkCompleted")}
                    />
                    <Divider hidden={!isExternal} />
                    <RepairInvoiceRef
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("invoiceRef")}
                    />
                    <RepairDateInvoiced
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("dateInvoiced")}
                    />
                    <RepairDatePaid
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("datePaid")}
                    />
                    <RepairDateGuaranteedUntil
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={handleValue("dateGuaranteeExpires")}
                    />
                    <RepairCostSavings
                        show={isExternal}
                        editing={editing}
                        data={data}
                        onChange={(value): void => handleValue("costSaving")(parseCurrency(value))}
                    />
                </Optional>
                <Divider />
                <RepairLifecycle events={selectedRepair.events} />
                <RepairCancelReason cancelReason={selectedRepair.cancelReason} />
                <Divider />
                <FileUploader entity='REPAIR' entityId={selectedRepair.repairId.toString()} />
                <Divider />
                <NotesTab objectType="REPAIR" objectNodeId={selectedRepair.repairNodeId} notes={selectedRepair.notes} />
                <CloseRepairDialog isOpen={this.state.showCloseDialog} onClose={handleHideCloseRepairDialog} />
                <CancelRepairDialog isOpen={this.state.showCancelDialog} onClose={handleHideCancelRepairDialog} />
            </div>
        );
    }

    private updateState(key: RepairDataKeys, value: RepairDataTypes): void {
        this.setState(state => ({
            ...state,
            data: {
                ...state.data,
                [key]: value
            }
        }));
    }

    private resetData(): void {
        this.setState((state, props) => ({
            ...state,
            data: initData(props.selectedRepair, props.openDefects, props.openInspections)
        }));
    }

    private handleSave(): void {
        function valueOrNull<T>(value: T | undefined): T | null {
            return value ? value : null;
        }

        const { selectedRepair } = this.props;
        if (selectedRepair) {
            const { data } = this.state;
            const defectId = data.selectedDefect?.value;
            const inspectionId = data.selectedInspection?.value;
            const variables: RepairMutationData = {
                waterSourceId: selectedRepair.waterSource.waterSourceNodeId,
                defectId: defectId ? encodeNodeId(NodeID("Defect", defectId)) : undefined,
                inspectionId: inspectionId ? encodeNodeId(NodeID("Inspection", inspectionId)) : undefined,
                repairTypeId: data.repairType ? Number(data.repairType.value) : null,
                expectedCost: valueOrNull(data.expectedCost),
                actualCost: valueOrNull(data.actualCost),
                costSaving: valueOrNull(data.costSaving),
                repairVendorId: data.repairVendor ? Number(data.repairVendor.value) : null,
                workOrderRef: valueOrNull(data.workOrderRef),
                isPaymentAuthorised: data.isPaymentAuthorised,
                dateWorkCompleted: valueOrNull(data.dateWorkCompleted),
                invoiceRef: valueOrNull(data.invoiceRef),
                dateInvoiced: valueOrNull(data.dateInvoiced),
                purchaseOrderRef: valueOrNull(data.purchaseOrderRef),
                datePaid: valueOrNull(data.datePaid),
                dateGuaranteeExpires: valueOrNull(data.dateGuaranteeExpires)
            };
            this.props.onUpdateRepair?.(parseNodeId(selectedRepair.repairNodeId), variables);
        }

        this.setState({ editing: false });
    }
}

export type { IRepairControlProps };
export default RepairControl;    