import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    button: {
        color: theme.palette.grey[200]
    },
    dialog: {
        marginTop: theme.spacing(1)
    },
    icon: {
        backgroundColor: theme.palette.primary.main
    }
}));

export default useStyles;