import { useState, useEffect, useCallback } from "react";
import { Coordinate as olCoordinate } from "ol/coordinate";

import { IWaterSourceSummary } from "../../components/inspectionView";
import getWaterSourcesInRadius from "../getWaterSourcesInRadius";
import { FetchCollection, FetchStatus } from "./types";
import { bindIfMounted } from "./utils";

const useNearestWaterSources = ([x, y]: olCoordinate, fence: number): FetchCollection<IWaterSourceSummary> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [waterSources, setWaterSources] = useState<IWaterSourceSummary[]>([]);

    const fetch = useCallback(() => {
        let isMounted = true;
        const ifMounted = bindIfMounted(isMounted);

        setStatus("loading");
        getWaterSourcesInRadius([x, y], fence)
            .then(nearestWaterSources => ifMounted(() => setWaterSources(nearestWaterSources)))
            .catch(() => ifMounted(() => setWaterSources([])))
            .finally(() => ifMounted(() => setStatus("complete")));

        return (): void => {
            isMounted = false;
        };
    }, [x, y, fence]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [status, waterSources, fetch];
};

export default useNearestWaterSources;