import React, { useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import { NodeID } from '../../../lib/nodeIdentifier';
import { INote, ObjectType } from '../../../store/types/notes';
import TabPanel from '../../tabs/TabPanel';
import CreateNote from '../components/createNote';
import NoteList from '../components/noteList';
import { RoleName, RoleNames } from '../../../auth/roles';

interface INotesTabProps {
    readonly notes: INote[];
    readonly objectType: ObjectType;
    readonly objectNodeId: string;
    readonly checkAccess: (role: RoleName) => boolean;
    readonly onSaveNote?: (objectType: ObjectType, objectId: NodeID, content: string) => void;
    readonly onDeleteNote?: (parentType: ObjectType, noteNodeId: string) => void;
    readonly onEditNote?: (objectType: ObjectType, ownerId: string, content: string) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {

    },
    tabBar: {

    },
    tabContainer: {

    },
    tab: {
        color: "white",
        minWidth: "unset",
        "&:focus": {
            color: theme.palette.secondary.main
        }
    },
    tabs: {

    }
}));

enum TabIndex {
    NOTES = 0,
    CREATE_NOTES = 1
}

export const NotesTab = ({ notes, objectNodeId, objectType, onSaveNote, onDeleteNote, onEditNote, checkAccess }: INotesTabProps): JSX.Element => {
    const styles = useStyles();
    const [tab, setTab] = useState(TabIndex.NOTES);
    const canDeleteNotes = checkAccess(RoleNames.NOTES_DELETE);
    const canEditNotes = checkAccess(RoleNames.NOTES_EDIT);

    const handleSaveNote = (objectType: ObjectType, objectId: NodeID, content: string): void => {
        onSaveNote?.(objectType, objectId, content);
        setTab(TabIndex.NOTES)
    };

    const handleTabChange = (_: React.ChangeEvent<unknown>, value: TabIndex): void => {
        setTab(value);
    };

    return (
        <div className={styles.root}>
            <div className={styles.tabBar}>
                <Tabs variant="fullWidth" scrollButtons="off" value={tab} onChange={handleTabChange}>
                    <Tab label={<Typography variant="caption">Notes</Typography>} id={`note-tab-${0}`} className={styles.tab} />
                    <Tab label={<Typography variant="caption">Add Note</Typography>} id={`note-tab-${1}`} className={styles.tab} />
                </Tabs>
            </div>
            <div className={styles.tabContainer}>
                <TabPanel value={tab} index={TabIndex.NOTES} disableGutters>
                    <NoteList objectType={objectType} notes={notes} canDeleteNotes={canDeleteNotes} canEditNotes={canEditNotes} onDeleteNote={onDeleteNote} onEditNote={onEditNote} />
                </TabPanel>
                <TabPanel value={tab} index={TabIndex.CREATE_NOTES} disableGutters>
                    <CreateNote objectType={objectType} objectNodeId={objectNodeId} onSaveNote={handleSaveNote} />
                </TabPanel>
            </div>
        </div>
    );
}

export type { INotesTabProps };
export default NotesTab;
