import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, DateProps } from "../types";

const RepairDateInvoiced = ({ show, editing, data, onChange }: PropsWithEditing<PropsWithShow<DateProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "date",
        id: "repair-date-invoiced",
        label: "Date Invoiced",
        classes: { container: 'input__group', label: 'input__label' },
        value: data.dateInvoiced,
        editing: editing ?? false,
        onChange: (value) => onChange?.(value),
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairDateInvoiced;