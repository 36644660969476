import moment from 'moment';
import currency from 'currency.js';
import { DateTimeFormatter } from '@js-joda/core';
import "@js-joda/timezone";
import { Locale } from "@js-joda/locale_en";
import { v4 } from "uuid";

import { ILifecycleEvent } from './Model';

export const objectHash = <T>(obj: T | undefined): string => {
    if (obj === undefined || obj === null) {
        return "";
    }
    const s = Object.entries(obj).map(([key, value]) => `${key}:${value}`).join(",");
    const h = btoa(s);
    return h;
};

export const encodeId = (typeName: string, key: string | number): string =>
    btoa(`${typeName}:${key}`);

export function formatText(s?: string, fixCase = false, defaultValue = "", addSpacing = false): string {
    if (addSpacing) {
        s = s
            ? s.replace(/([A-Z])/g, ' $1')
            : defaultValue;
    }
    if (fixCase) {
        return s && s.trim().length > 0
            ? (s.charAt(0).toUpperCase() + s.slice(1)).trim()
            : defaultValue;
    }
    return s && s.trim().length > 0
        ? s.trim()
        : defaultValue;
}

export interface IDisplayText {
    readonly displayText?: string;
}

export function formatDisplayText(o?: IDisplayText, defaultValue = ""): string {
    return o
        ? formatText(o.displayText, false, defaultValue)
        : defaultValue;
}

export { v4 as guid };

export function getBrandColor(color: string): string {
    switch (color) {
        case "fuse":
            return "#e73930"
        case "mist":
            return "#a7a8aa"
        case "petrol":
            return "#273c4e"
        case "spark":
            return "#ffcf21"
        case "white":
            return "#ffffff"
        case "black":
            return "#000000"
        default:
            return "#000000"
    }
}

function getMonthName(month: number): string {
    switch (month) {
        case 0:
            return "January";
        case 1:
            return "February";
        case 2:
            return "March";
        case 3:
            return "April";
        case 4:
            return "May";
        case 5:
            return "June";
        case 6:
            return "July";
        case 7:
            return "August";
        case 8:
            return "September";
        case 9:
            return "October";
        case 10:
            return "November";
        case 11:
            return "December";
        default:
            return "";
    }
}

function getDateDecorator(date: number): string {
    if (date === 1 || date === 21 || date === 31) {
        return "st"
    }

    if (date === 2 || date === 22) {
        return "nd"
    }

    if (date === 3 || date === 23) {
        return "rd"
    }

    return "th"
}

export function formatDate(dateString: Date): string {
    const formattedDate = new Date(dateString)

    const year = formattedDate.getFullYear();
    const month = getMonthName(formattedDate.getMonth());
    const date = formattedDate.getDate();
    const dateDecorator = getDateDecorator(date);

    return `${date}${dateDecorator} ${month} ${year}`
}

export const ShortDateFormat = "DD/MM/YYYY";
export const LongDateFormat = "Do MMMM YYYY";

export const shortDateFormatter = DateTimeFormatter.ofPattern("dd/MM/uuuu");
export const longDateFormatter = DateTimeFormatter.ofPattern("d MMMM uuuu").withLocale(Locale.UK);
export const timestampFormatter = DateTimeFormatter.ofPattern("dd/MM/uuuu HH:mm:ss");

export const formatDateWithMoment = (date: Date | undefined, format = ShortDateFormat): string => {
    return date
        ? moment(date).locale('en-GB').format(format)
        : "";
};

export const formatLastEvent = (event: ILifecycleEvent | undefined): string => {
    return event
        ? `${event.action} by ${event.actor} on ${event.timestamp.format(shortDateFormatter)}`
        : "";
};

export const formatCurrency = (input: number | null | undefined, useSymbol = true, defaultValue = "-"): string => {
    return input !== undefined && input !== null
        ? currency(input / 100, { symbol: '£' }).format(useSymbol)
        : defaultValue;
};

export const parseCurrency = (input: string | undefined): number | undefined => {
    return input
        ? Number(input.replace(/£|,|\./gm, ''))
        : undefined;
};

export const handleValidation = (shouldValidate: boolean, fieldValue: any): string => {
    return shouldValidate
        ? fieldValue ? 'valid' : 'invalid'
        : '';
};

export const formatBytes = (value: number): string => {
    const KB = 1024;
    const MB = 1024 * KB;
    const GB = 1024 * MB;
    if (value < KB) {
        return `${value} B`;
    }
    if (value < MB) {
        return `${Math.round(value / KB)} KB`;
    }
    if (value < GB) {
        return `${Math.round(value / MB)} MB`;
    }
    return `${Math.round(value / GB)} GB`;
};

export const getMin = (a: number, b: number): number => Math.min(a, b);
export const getMax = (a: number, b: number): number => Math.max(a, b);