export default `
query Get_TechnicianProfile($userName: String!) {
    technician(userName: $userName) {
        technicianNodeId: id
        technicianId
        displayName
        userName
        inspectionGroups {
            inspectionGroupNodeId: id
            inspectionGroupId
            name
        }
    }
}`;