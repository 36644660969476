export default `mutation addThoroughfaresToScheme($input: EditUpdateLinksInput!) {
    scheme {
        addThoroughfares(input: $input) {
            clientMutationId
            hasErrors
            errors {
                message
                path
            }
            scheme {
                schemeNodeId: id
                events {
                    action
                    actor
                    timestamp
                }
                thoroughfares {
                    thoroughfareNodeId: id
                    thoroughfareId
                    isActive
                    name
                    path {
                        coordinates {
                            x
                            y
                        }
                    }
                }
            }
        }
    }
}`