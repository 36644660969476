import React from "react";

import { Lookup } from "../../../../../../lib/lookups";
import { ILookupItem } from "../../../../../../store/types";
import Select from "../../../../../forms/select";
import SelectItem from "../../../../../forms/selectItem";
import { formatText } from "../../../../utils";
import Field from "../../../../../card/components/field";

interface HydrantLocationProps {
    readonly selected: ILookupItem | undefined;
    readonly items: Lookup;
    readonly editing?: boolean;
    readonly native?: boolean;
    readonly onChange?: (item: ILookupItem | undefined) => void;
}

const HydrantLocation = ({ selected, items, editing, native, onChange }: HydrantLocationProps): JSX.Element => {
    if (editing) {
        const handleSelectChange = (event: React.ChangeEvent<{ readonly value: unknown; }>): void => {
            const getItem = (): ILookupItem | undefined => {
                const { value } = event.target;
                if (typeof value === "string" && value) {
                    return items.getItem(Number(value));
                }
                if (typeof value === "number") {
                    return items.getItem(value);
                }
                return undefined;
            };
            const item = getItem();
            onChange?.(item);
        };
        return (
            <Select value={selected?.value} id="location-hydrantLocation" label="Hydrant location" placeholder="Please select..." native={native} onChange={handleSelectChange}>
                {items.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
        );
    }
    return <Field label="Hydrant location" text={formatText(selected?.displayText)} />;
};

export type { HydrantLocationProps };
export default HydrantLocation;