import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import AddIcon from "@material-ui/icons/Add";

import { parseNodeId, NodeID } from "../../../../lib/nodeIdentifier";
import { IInspection } from "../../../../store/types";
import { formatLastEvent } from "../../../../lib/Utils";

interface InspectionItemProps {
    readonly inspection: IInspection;
    readonly onCreateDefect?: (inspectionNodeId: NodeID) => void;
    readonly onSelectInspection?: (inspectionNodeId: NodeID) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        border: "3px dashed transparent",
        "&:hover": {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.light
        },
        "&:active": {
            borderColor: theme.palette.secondary.main
        }
    },
    secondaryText: {
        color: theme.palette.common.white
    },
    action: {
        color: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
}));

const InspectionItem = ({ inspection, onCreateDefect, onSelectInspection }: InspectionItemProps): JSX.Element => {
    const styles = useStyles();
    const nodeId = parseNodeId(inspection.inspectionNodeId);
    const handleItemClick = (): void => {
        onSelectInspection?.(nodeId);
    };
    const handleCreateDefectClick = (): void => {
        onCreateDefect?.(nodeId);
    };
    return (
        <ListItem className={styles.root} button onClick={handleItemClick}>
            <ListItemText
                primary={inspection.type.displayText}
                primaryTypographyProps={{ variant: "body1" }}
                secondary={formatLastEvent(inspection.lastEvent)}
                secondaryTypographyProps={{ variant: "caption", align: "right", className: styles.secondaryText }}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" className={styles.action} onClick={handleCreateDefectClick}>
                    <AddIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export type { InspectionItemProps };
export default InspectionItem;