import { ILookups } from "../../../store/types";
import {
    buildLookupFilterItems,
    buildYesNoFilterItems,
    formatFilterCurrency,
    formatFilterDate,
    formatFilterText,
    resolveBoolean,
    resolveRange,
    resolveMatchString
} from "../utils";
import { FilterOption } from "../types";
import { RepairSearchFilters, RepairSelectedFilters } from "./types";
import { buildListFilterObject } from "../filter/utils";

const generateCategories = (): FilterOption[] => [
    { text: "External", value: 1, enabled: true },
    { text: "Internal", value: 2, enabled: true }
];

export const buildRepairFilters = (lookups: ILookups): RepairSearchFilters => ({
    id: "TEXTRANGE",
    category: generateCategories(),
    type: buildLookupFilterItems(lookups.repairCategories, lookups.repairTypes, true),
    waterSource: "TEXT",
    defect: "TEXTRANGE",
    inspection: "TEXTRANGE",
    expectedCost: "TEXTRANGE",
    actualCost: "TEXTRANGE",
    vendor: buildLookupFilterItems(lookups.repairVendors, undefined, true),
    workOrderRef: "TEXT",
    purchaseOrderRef: "TEXT",
    invoiceRef: "TEXT",
    dateCreated: "DATERANGE",
    dateWorkCompleted: "DATERANGE",
    dateInvoiced: "DATERANGE",
    datePaid: "DATERANGE",
    dateClosed: "DATERANGE",
    isOpen: buildYesNoFilterItems(),
    isWorkCompleted: buildYesNoFilterItems(),
    isInvoiced: buildYesNoFilterItems(),
    isPaymentAuthorised: buildYesNoFilterItems(),
    isPaid: buildYesNoFilterItems(),
    isCancelled: buildYesNoFilterItems(),
    cancelReason: buildLookupFilterItems(lookups.repairCancelReasons)
});

export const buildQueryFilter = (filters: RepairSelectedFilters): Record<string, unknown> => ({
    "id": resolveRange(filters.id, formatFilterText),
    "$all": [
        buildListFilterObject(filters.category, "repairCategory"),
        buildListFilterObject(filters.type, "repairType"),
        buildListFilterObject(filters.vendor, "repairVendor"),
        buildListFilterObject(filters.cancelReason, "cancelReason"),
    ].filter(item => Object.keys(item).length),
    "waterSource": {
        "$like": resolveMatchString(filters.waterSource)
    },
    "defect": resolveRange(filters.defect, formatFilterText),
    "inspection": resolveRange(filters.inspection, formatFilterText),
    "expectedCost": resolveRange(filters.expectedCost, formatFilterCurrency),
    "actualCost": resolveRange(filters.actualCost, formatFilterCurrency),
    "workOrderRef": {
        "$like": resolveMatchString(filters.workOrderRef)
    },
    "purchaseOrderRef": {
        "$like": resolveMatchString(filters.purchaseOrderRef)
    },
    "invoiceRef": {
        "$like": resolveMatchString(filters.invoiceRef)
    },
    "dateCreated": resolveRange(filters.dateCreated, formatFilterDate),
    "dateWorkCompleted": resolveRange(filters.dateWorkCompleted, formatFilterDate),
    "dateInvoiced": resolveRange(filters.dateInvoiced, formatFilterDate),
    "datePaid": resolveRange(filters.datePaid, formatFilterDate),
    "dateClosed": resolveRange(filters.dateClosed, formatFilterDate),
    "isOpen": {
        "$eq": resolveBoolean(filters.isOpen)
    },
    "isWorkCompleted": {
        "$eq": resolveBoolean(filters.isWorkCompleted)
    },
    "isInvoiced": {
        "$eq": resolveBoolean(filters.isInvoiced)
    },
    "isPaymentAuthorised": {
        "$eq": resolveBoolean(filters.isPaymentAuthorised)
    },
    "isPaid": {
        "$eq": resolveBoolean(filters.isPaid)
    },
    "isCancelled": {
        "$eq": resolveBoolean(filters.isCancelled)
    }
});