import React from "react";
import cls from "classnames";

import { AccessControl } from "../../../../../auth/components";
import { RoleNames } from "../../../../../auth/roles";
import styles from "./waterSourceButtons.module.scss";

enum ButtonType { None, CreateWaterSource = 1 << 1, CreateInspections = 1 << 2 }

interface IWaterSourceButtonsProps {
    readonly buttons: ButtonType;
    readonly onCreateWaterSourceClick: () => void;
    readonly onCreateInspectionsClick: () => void;
}

const Button = (props: { label: string; onClick: () => void }): JSX.Element => (
    <button type="button" className={cls("action-button", styles.button)} onClick={props.onClick}>{props.label}</button>
);

const WaterSourceButtons = (props: IWaterSourceButtonsProps): JSX.Element => {
    const showCreateWaterSource = (props.buttons & ButtonType.CreateWaterSource) === ButtonType.CreateWaterSource;
    const showCreateInspections = (props.buttons & ButtonType.CreateInspections) === ButtonType.CreateInspections;
    return (
        <AccessControl role={RoleNames.ROUTES_ALL}>
            <div className={styles.root}>
                {showCreateWaterSource && <Button label="Create Water Source" onClick={props.onCreateWaterSourceClick} />}
                {showCreateInspections && <Button label="Create Inspections" onClick={props.onCreateInspectionsClick} />}
            </div>
        </AccessControl>
    );
};

export type { IWaterSourceButtonsProps };
export { ButtonType as WaterSourceButton };
export default WaterSourceButtons;