import executeQuery from "../../../lib/executeQuery";
import getInspectionsByFiltersQuery from "../../../store/actions/graphQL/getInspectionsBasicsByFilter";
import { IConnection, IConnectionQuery, IQueryControl } from "../../../store/types";
import { InspectionSearchItem } from "./types";

export interface IInspectionQueryResponse {
    readonly inspections: IConnection<InspectionSearchItem>;
}

export const queryInspectionsByFilter = async (filter: Record<string, unknown>, paging: IQueryControl): Promise<IConnection<InspectionSearchItem>> => {
    const input: IConnectionQuery<Record<string, unknown>> = {
        filter,
        paging
    };
    const response = await executeQuery<IInspectionQueryResponse>(getInspectionsByFiltersQuery, input);
    return response
        ? response.inspections
        : Promise.reject(new Error("Missing query response."));
};
