export default `
    mutation editSavedSearch($input: EditSavedSearchInput!) {
        searches {
            saveExisting(input: $input) {
                clientMutationId
                errors {
                    message
                    path
                }
                hasErrors
                result {
                    nodeId
                    searches {
                        id
                        searchPath
                        displayText
                        queryControl {
                            pageIndex
                            pageSize
                            sortBy
                            sortDirection
                        }
                        queryFilter
                        searchId
                        sortOrder
                    }
                }
            }
        }
    }
`;