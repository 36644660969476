import { IMapAction, IMapState } from '../types/map';

const defaultAction: IMapAction = {
    type: '@@INIT'
}

const defaultState: IMapState = {
    mapSettings: undefined,
    renderPoints: false,
    renderAnimateTo: undefined,
    selectWaterSourceNodeId: undefined,
    createFromMapLocation: undefined,
    movingWaterSource: false,
    movingWaterSourceLocation: undefined,
    mapClickAction: undefined,
    mapCenterCoords: undefined,
    streetView: {
        apiKey: undefined,
        params: {
            position: {
                lat: undefined,
                lng: undefined
            },
            pov: {
                heading: 100,
                pitch: 0
            },
            zoom: 1
        }
    },
    filters: {},
};

export default (state: IMapState = defaultState, action: IMapAction = defaultAction): IMapState => {
    switch (action.type) {
        case 'SET_MAP_CENTER_COORDS':
            return {
                ...state,
                mapCenterCoords: action.mapCenterCoords ?? state.mapCenterCoords
            }
        case 'SET_RENDER_POINTS': {
            return {
                ...state,
                renderPoints: action.renderPoints ?? false
            }
        }
        case 'SET_SETTINGS': {
            const { mapSettings } = action;
            return {
                ...state,
                mapSettings: mapSettings,
                mapCenterCoords: mapSettings
                    ? [mapSettings.view.centre.x, mapSettings.view.centre.y]
                    : undefined,
                fallbackWarning: typeof mapSettings?.tileServer.fallback === "boolean"
                    ? mapSettings?.tileServer.fallback
                    : false
            }
        }
        case 'SET_STREET_VIEW_SETTINGS':
            return {
                ...state,
                streetView: {
                    ...state.streetView,
                    ...action.streetViewSettings
                }
            }
        case 'SET_CREATE_FROM_MAP_LOCATION':
            return {
                ...state,
                createFromMapLocation: action.createFromMapLocation
            }
        case 'SET_MOVING_WATER_SOURCE':
            return {
                ...state,
                movingWaterSource: action.movingWaterSource ?? false,
                movingWaterSourceLocation: action.movingWaterSourceLocation
            }
        case 'SET_RENDER_ANIMATE_TO':
            return {
                ...state,
                renderAnimateTo: action.renderAnimateTo,
                selectWaterSourceNodeId: action.selectWaterSourceNodeId
            }
        case 'SET_MAP_CLICK_ACTION':
            return {
                ...state,
                mapClickAction: action.mapClickAction
            }
        case 'SET_MAP_FILTERS':
            return {
                ...state,
                filters: action.filters
            }
        case 'SET_MAP_FALLBACK_WARNING':
            return {
                ...state,
                fallbackWarning: action.fallbackWarning
            }
        default:
            return state;
    }
}
