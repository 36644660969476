import React from 'react';
import { LocalDate, DateTimeFormatter } from '@js-joda/core';

import { formatText } from '../../../lib/Utils';
import LabelledField from '../../labelledField';
import { FilterOption } from '../types';
import { SelectedFilters } from '../types';
import FilterContainer from './components/FilterContainer';
import ListFilter from './components/ListFilter';
import FilterLabel from './components/FilterLabel';
import styles from './filter.module.scss';

interface IFilterProps {
    readonly name: string;
    readonly options: FilterOption[];
    readonly selected: SelectedFilters;
    readonly onChangeSelection?: (selected: SelectedFilters) => void;
}

const dateFormatter = DateTimeFormatter.ofPattern("uuuu-MM-dd");

const Filter = ({ name, options, selected, onChangeSelection }: IFilterProps): JSX.Element => {
    const filterLabel = formatText(name, true, undefined, true);

    if (Array.isArray(options)) {
        const handleItemChange = (value: string): void => {
            const currentSelection = selected[name] ?? [];

            const index = currentSelection.indexOf(value);
            if (index > -1) {
                const update = [...currentSelection];
                update.splice(index, 1);
                return onChangeSelection?.({ ...selected, [name]: update });
            }

            onChangeSelection?.({ ...selected, [name]: [...currentSelection, value] })
        };

        return (
            <ListFilter
                id={name}
                label={filterLabel}
                options={options}
                selected={selected[name]}
                onChange={handleItemChange}
            />
        );
    }

    if (options === "DATERANGE") {
        const parseLocalDate = (value: string | LocalDate | undefined): LocalDate | undefined => {
            if (typeof value === "string") {
                return LocalDate.parse(value, dateFormatter);
            }
            if (value instanceof LocalDate) {
                return value;
            }
            return undefined;
        };
        const item = selected[name] ?? ["", ""];
        const from = parseLocalDate(item[0]);
        const to = parseLocalDate(item[1]);
        return (
            <FilterContainer>
                <FilterLabel htmlFor={name}>{filterLabel}</FilterLabel>
                <div id={name}>
                    <LabelledField
                        fieldType="date"
                        id={`${name}-from`}
                        label="From"
                        classes={{ container: styles.labelledFieldContainerOverride, label: styles.labelledFieldLabelOverride }}
                        editing={true}
                        value={from}
                        onChange={(value): void => onChangeSelection?.({ ...selected, [name]: [value?.toJSON(), to?.toJSON()] })}
                    />
                    <LabelledField
                        fieldType="date"
                        id={`${name}-to`}
                        label="To"
                        classes={{ container: styles.labelledFieldContainerOverride, label: styles.labelledFieldLabelOverride }}
                        editing={true}
                        value={to}
                        onChange={(value): void => onChangeSelection?.({ ...selected, [name]: [from?.toJSON(), value?.toJSON()] })}
                    />
                </div>
            </FilterContainer>
        )
    }

    if (options === "TEXTRANGE") {
        const item = selected[name] ?? ["", ""];
        return (
            <FilterContainer>
                <FilterLabel htmlFor={name}>{filterLabel}</FilterLabel>
                <div id={name}>
                    <LabelledField
                        fieldType="text"
                        id={`${name}-from`}
                        label="From"
                        classes={{ container: styles.labelledFieldContainerOverride, label: styles.labelledFieldLabelOverride }}
                        editing={true}
                        value={item[0] ?? ""}
                        onChange={(value): void => onChangeSelection?.({ ...selected, [name]: [value, item[1]] })}
                    />
                    <LabelledField
                        fieldType="text"
                        id={`${name}-to`}
                        label="To"
                        classes={{ container: styles.labelledFieldContainerOverride, label: styles.labelledFieldLabelOverride }}
                        editing={true}
                        value={item[1] ?? ""}
                        onChange={(value): void => onChangeSelection?.({ ...selected, [name]: [item[0], value] })}
                    />
                </div>
            </FilterContainer>
        );
    }

    if (options === "TEXT") {
        const item = selected[name] ?? ["", ""];
        return (
            <FilterContainer>
                <FilterLabel htmlFor={name}>{filterLabel}</FilterLabel>
                <LabelledField
                    fieldType="text"
                    id={`${name}`}
                    label=""
                    classes={{ container: styles.labelledFieldContainerOverride, label: styles.labelledFieldLabelOverride }}
                    editing={true}
                    value={item[0] ?? ""}
                    onChange={(value): void => onChangeSelection?.({ ...selected, [name]: [value] })}
                />
            </FilterContainer>
        );
    }

    return <React.Fragment />;
}

export type { IFilterProps };
export default Filter;