import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            "Montserrat",
            "Calibri",
            "Helvetica",
            "Arial",
            "sans-serif"
        ].join(",")
    },
    palette: {
        type: "light",
        primary: {
            main: "#273c4f",
            light: "#52667b",
            dark: "#001627",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#ffcf21",
            light: "#ffff5d",
            dark: "#c79e00",
            contrastText: "#000000"
        }
    }
});

export { theme as mobile };
export { theme as desktop };