import React from "react";

import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useNavigation from "../../../router/MobileRouter/hooks/useNavigation";

const NavBackButton = (): JSX.Element => {
    const { goBack } = useNavigation();

    const handleNavClick = (): void => goBack();

    return (
        <IconButton edge="start" color="inherit" onClick={handleNavClick}>
            <ArrowBackIcon />
        </IconButton>
    );
};

export default NavBackButton;