import { connect } from "react-redux";
import * as proj from "ol/proj";

import { IPoint, IReduxState } from "../../../../store/types";
import StreetViewMobile, { StreetViewMobileProps } from "./StreetView.view";

const defaultMapSettings = {
    projection: { code: "", definition: "" },
    tileServer: { apiKey: "", type: "", url: "", params: undefined },
    view: {
        centre: { x: 0, y: 0 },
        extent: { max: { x: 0, y: 0 }, min: { x: 0, y: 0 } }
    }
};

const mapProps = (state: IReduxState, props: { readonly waterSourceCoordinates: IPoint }): StreetViewMobileProps => {
    const streetView = state.map.streetView;
    const mapSettings = state.map.mapSettings ?? defaultMapSettings;
    const { waterSourceCoordinates } = props;
    const [lng, lat] = proj.toLonLat([waterSourceCoordinates.x, waterSourceCoordinates.y], mapSettings.projection.code);

    return ({
        config: {
            ...streetView,
            params: {
                ...streetView.params,
                position: {
                    lng,
                    lat
                }
            }
        }
    })
};

export default connect(mapProps)(StreetViewMobile);