import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, SelectProps } from "../types";

const RepairVendor = ({ show, editing, options, data, onClear, onSelect }: PropsWithEditing<PropsWithShow<SelectProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "repair-vendor-id",
        label: "Repair Vendor",
        classes: { container: 'input__group', label: 'input__label' },
        value: data.repairVendor,
        selectOptions: options,
        editing: editing ?? false,
        onChange: value => onSelect?.(value),
        onClear
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairVendor;