import axios, { AxiosRequestConfig } from "axios";
import { FilteredObject, IQueryControl } from "../../../../store/types";
import { SearchEntity } from "../../searchSelector";
import { guid } from "../../../../lib/Utils";

type HeaderKeys = "content-disposition" | "content-length" | "content-type";

type FileHeaders = {
    readonly [K in HeaderKeys]?: string;
};

interface ExportFile {
    readonly fileName: string;
    readonly data: Blob;
}

type ExportPath = SearchEntity;

interface ExportVariables<T> {
    readonly filter?: FilteredObject<T> | Record<string, unknown>;
    readonly paging: IQueryControl;
}

const fileNameRegex = /filename=(.+);/i;
const parseFileName = (source: string | undefined): string | undefined => {
    if (source === undefined || source === null || source.length === 0) {
        return undefined;
    }

    const fileName = source.split(';').find(field => {
        const matches = fileNameRegex.exec(field);
        if (matches && matches?.length > 0) {
            return matches[0];
        }
        return undefined;
    });

    return fileName;
};

const requestConfig: AxiosRequestConfig = {
    responseType: "arraybuffer"
};
const downloadCsv = async <T>(path: ExportPath, filter?: FilteredObject<T> | Record<string, unknown>): Promise<void> => {
    const input: ExportVariables<T> = {
        filter: filter ?? {},
        paging: {
            pageIndex: 1,
            pageSize: 9999999,
            sortBy: "id",
            sortDirection: "ASCENDING"
        }
    };
    return axios.post<Iterable<number>>(`/api/export/${path}`, input, requestConfig)
        .then<[FileHeaders, Iterable<number>]>(response => {
            const headers: Record<HeaderKeys, string> = response.headers;
            const data = response.data;
            return [headers, data]
        })
        .then<ExportFile>(([headers, data]) => {
            const fileName = parseFileName(headers["content-disposition"]);
            return {
                data: new Blob([new Uint8Array(data)]),
                fileName: fileName ?? `export-${path}-${guid().replaceAll("-", "")}.csv`
            }
        })
        .then((file) => saveAs(file.data, file.fileName))
        .catch(error => console.warn("Error downloading file.", error));
};

export default downloadCsv;