import React from "react";
import cx from "classnames";

import MuiMenuItem from "@material-ui/core/MenuItem";

interface SelectItemProps {
    readonly className?: string;
    readonly native?: boolean;
    readonly value?: string | number | string[];
}

const SelectItem = (props: React.PropsWithChildren<SelectItemProps>): JSX.Element => {
    const { children, className, native, value } = props;
    return native
        ? <option value={value} className={className}>{children}</option>
        : <MuiMenuItem value={value} className={className}>{children}</MuiMenuItem>;
};

export type { SelectItemProps };
export default SelectItem;