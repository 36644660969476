import React from "react";
import { useDispatch } from "react-redux";

import { AddressDetailsData, toAddressDetails } from "../../../../../address";
import { startCreateAddress } from "../../../../../../api/createAddress";
import AddAddressBase, { AddAddressProps } from "./AddAddress.view";

const AddAddress = ({ onConfirm, ...other }: AddAddressProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleCreate = async (data: AddressDetailsData): Promise<void> => {
        const action = startCreateAddress(data);
        const address = await action(dispatch);
        if (address) {
            onConfirm?.(toAddressDetails(address));
        }
    };

    return <AddAddressBase {...other} onConfirm={handleCreate} />;
};

export default AddAddress;