import reportError from '../../lib/reportError';
import { IAppState, IAppAction, ILookups, IConfigurableLookups } from '../types/app';

export const defaultLookups: ILookups = {
    contactTypes: [],
    controlMeasures: [],
    crews: [],
    defectCancelReasons: [],
    defectCloseReasons: [],
    defectTypes: [],
    hazardFlags: [],
    hazardTypes: [],
    hydrantLocations: [],
    inspectionCancelReasons: [],
    inspectionGroups: [],
    inspectionResults: [],
    inspectionTests: [],
    inspectionTypes: [],
    mainsSizeUnits: [],
    measurementUnitCategories: [],
    measurementUnitSystems: [],
    measurementUnits: [],
    organisations: [],
    organisationTypes: [],
    plateLocations: [],
    pumpTypes: [],
    repairCancelReasons: [],
    repairCategories: [],
    repairTypes: [],
    repairVendors: [],
    riskSeverity: [],
    roadSpeed: [],
    routes: [],
    schemeStages: [],
    schemeTypes: [],
    stations: [],
    surfaces: [],
    tags: [],
    technicians: [],
    waterSourceCategories: [],
    waterSourceClasses: [],
    waterSourceStatuses: []
};

export const defaultConfigurableLookups: IConfigurableLookups = {
    controlMeasures: [],
    defectCancelReasons: [],
    defectCloseReasons: [],
    defectTypes: [],
    hazardFlags: [],
    hazardTypes: [],
    hydrantLocations: [],
    inspectionCancelReasons: [],
    inspectionTests: [],
    inspectionTypes: [],
    organisations: [],
    organisationTypes: [],
    plateLocations: [],
    pumpTypes: [],
    repairCancelReasons: [],
    repairTypes: [],
    repairVendors: [],
    roadSpeed: [],
    schemeStages: [],
    schemeTypes: [],
    stations: [],
    surfaces: [],
    tags: [],
    waterSourceClasses: []
};

export const defaultState: IAppState = {
    dialog: undefined,
    isLoading: false,
    lookups: defaultLookups,
    configurableLookups: defaultConfigurableLookups,
    showViewAll: false,
    globalToast: {
        showToast: false
    },
    dataTabsSelectedTab: "WATER_SOURCE",
    user: undefined,
    shouldReloadWaterSources: false,
    savedSearches: [],
    version: {
        apiVersion: undefined,
        uiVersion: undefined
    },
    tokenExpiry: 1200000
};

export const defaultAction: IAppAction = {
    type: '@@INIT'
};

export default (state: IAppState = defaultState, action: IAppAction = defaultAction): IAppState => {
    switch (action.type) {
        case 'SET_DIALOG_OPEN':
            return {
                ...state,
                dialog: action.dialog
            }

        case "SET_IS_LOADING":
            return {
                ...state,
                isLoading: action.isLoading ?? defaultState.isLoading
            }

        case 'SET_LOOKUPS':
            return {
                ...state,
                lookups: action.lookups
            }

        case 'SET_CONFIGURABLE_LOOKUPS':
            return {
                ...state,
                configurableLookups: action.configurableLookups
            }
        case 'SET_GLOBAL_TOAST': {
            const { globalToast } = action;
            if (globalToast?.type === "ERROR") {
                void reportError({
                    errorMessage: globalToast.message,
                    errorObject: globalToast.error,
                    errorTime: new Date().toISOString()
                })
            }
            return {
                ...state,
                globalToast: {
                    ...(globalToast ?? defaultState.globalToast)
                }
            };
        }
        case 'SET_DATA_TAB_SELECTED_TAB':
            return {
                ...state,
                dataTabsSelectedTab: action.dataTabsSelectedTab
            }

        case 'SET_ACCOUNT':
            return {
                ...state,
                user: {
                    ...state.user,
                    account: action.account
                        ? { ...action.account }
                        : undefined
                }
            }

        case "SET_VERSION":
            return {
                ...state,
                version: action.version
                    ? { ...action.version }
                    : { apiVersion: undefined, uiVersion: undefined }
            }

        case "SET_PROFILE":
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: action.profile
                        ? { ...action.profile }
                        : undefined
                }
            }

        case 'SHOULD_RELOAD_WATER_SOURCES':
            return {
                ...state,
                shouldReloadWaterSources: action.shouldReload ?? false
            }
        case 'SET_SAVED_SEARCHES':
            return {
                ...state,
                savedSearches: action.savedSearches ?? []
            };
        case 'UPDATE_LOOKUPS': {
            if (state.lookups && state.configurableLookups && action.lookup && action.updatedLookups) {
                return {
                    ...state,
                    lookups: {
                        ...state.lookups,
                        [action.lookup]: action.updatedLookups
                    },
                    configurableLookups: {
                        ...state.configurableLookups,
                        [action.lookup]: action.updatedLookups
                    }
                }
            }
            return state;
        }
        case 'SET_TOKEN_EXPIRY': {
            if (action.tokenExpiry) {
                return {
                    ...state,
                    tokenExpiry: action.tokenExpiry
                };
            }
            return state;
        }
        default:
            return state;
    }
};
