import React, { DragEvent, useState } from "react";
import cx from 'classnames';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { RouteWaterSource } from "../../RoutePanel.utils";
import CloseIcon from '@material-ui/icons/Close';
import css from './InspectionList.module.scss';
import { formatNextInspectionValue, formatNextInspectionTag } from "../../../../../lib/formatters";

interface InspectionListItemProps {
    readonly routerWaterSource: RouteWaterSource;
    readonly isEditing: boolean;
    readonly handleListItemClick: (waterSorceNodeId: string) => void;
    readonly handleRemoveWaterSource: (ws: RouteWaterSource) => void;
    readonly handleMoveWaterSource: (waterSourceid: string, idToMoveAbove: string) => void;
}

const InspectionListItem = ({ routerWaterSource, isEditing, handleListItemClick, handleRemoveWaterSource, handleMoveWaterSource }: InspectionListItemProps): JSX.Element => {
    const [isBeingDraggedOver, setIsBeingDraggedOver] = useState(false);

    const handleClick = (): void => handleListItemClick(routerWaterSource.waterSourceNodeId);
    const handleIconClick = (e: React.MouseEvent<SVGSVGElement>): void => {
        e.stopPropagation();
        handleRemoveWaterSource(routerWaterSource)
    };
    const handleOnDragOver = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setIsBeingDraggedOver(true);
    }
    const handleOnDragLeave = (): void => setIsBeingDraggedOver(false);
    const handleOnDragStart = (e: DragEvent<HTMLDivElement>): void => {
        if (e?.dataTransfer) {
            e.dataTransfer.setData("text/plain", routerWaterSource.waterSourceNodeId);
        }
    }
    const handleOnDrop = (e: DragEvent<HTMLInputElement>): void => {
        setIsBeingDraggedOver(false);
        handleMoveWaterSource(e.dataTransfer.getData("text/plain"), routerWaterSource.waterSourceNodeId)
    }
    const listItemStyles = cx([css.listItem, { [css.listItemDraggedOver]: isBeingDraggedOver }]);

    const { waterSourceId, nextInspectionDate, recommendedInspectionDate, routeSortOrder, riskSeverity } = routerWaterSource;
    const nextInspection = `${formatNextInspectionValue(nextInspectionDate, recommendedInspectionDate)} ${formatNextInspectionTag(nextInspectionDate, recommendedInspectionDate, "", "*")}`;
    return (
        <div
            draggable={isEditing}
            onDragStart={handleOnDragStart}
            onDragOver={handleOnDragOver}
            onDragLeave={handleOnDragLeave}
            onDrop={handleOnDrop}
            className={listItemStyles}
        >
            <ListItem
                button
                disableGutters
                onClick={handleClick}
            >
                {isEditing && <div className={css.listItemOrder}>{routeSortOrder}</div>}
                <ListItemText
                    primary={<div className={css.listItemPrimary}>
                        <span>{waterSourceId}</span>
                        <div>
                            <span>{`Next Inspection: - ${nextInspection}`}</span>
                            {isEditing && <CloseIcon onClick={handleIconClick} />}
                        </div>
                    </div>}
                    secondary={<span className={css.listItemSecondary}>{`Risk Severity: - ${riskSeverity?.displayText ?? ''}`}</span>}
                    primaryTypographyProps={{ variant: "body1", }}
                    secondaryTypographyProps={{ variant: "body2" }}
                />
            </ListItem>
        </div>
    );
};

export type { InspectionListItemProps };
export default InspectionListItem;