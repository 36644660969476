import React, { useState } from "react";

import Tabs from "@material-ui/core/Tabs";

import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import { IScheme, IWaterSource, AddressNode } from '../../../store/types';
import DrawerToolbar from "../../drawer/DrawerToolbar";
import Tab from "../../tabs/Tab";
import TabPanel from "../../tabs/TabPanel";
import TabPanelContainer from "../../tabs/TabPanelContainer";
import AssignWaterSourceToSchemeDialog from "../assignWaterSourceToSchemeDialog";
import SchemeDetailsTab from './components/schemeDetailsTab';
import { ISchemeDetailsData } from "./components/schemeDetailsTab/components/schemeDetails";
import { SchemeWorkflowData } from "./components/schemeDetailsTab/components/schemeWorkflow";
import SchemeMenu from "./components/schemeMenu";
import WaterSourceListTab from "./components/waterSourceListTab";
import "./schemePanel.scss";

interface ISchemePanelProps {
    readonly scheme: IScheme;
    readonly onAssignWaterSource?: (scheme: NodeID, waterSource: NodeID) => void;
    readonly onCancelEdit?: () => void;
    readonly onAddBoundary?: () => void;
    readonly onRemoveBoundary?: (scheme: NodeID) => void;
    readonly onClose?: () => void;
    readonly onEditBoundary?: () => void;
    //readonly onMapAction?: (action: "DRAW_POLYGON" | "EDIT_POLYGON") => void;
    readonly onUpdateScheme?: (schemeNodeId: string, details: ISchemeDetailsData | undefined, address: AddressNode | undefined, workflow: SchemeWorkflowData | undefined) => Promise<void>;
    readonly onCreateInspectionsClick: (waterSourceIds: string[]) => void;
}

const formatWaterSourcesLabel = (waterSources: IWaterSource[]): string => {
    const { length } = waterSources;
    return length
        ? `Water Sources (${length})`
        : `Water Sources`;
};

const hasBoundary = (scheme: IScheme): boolean =>
    scheme.boundary !== undefined && scheme.boundary.exteriorRing.coordinates.length > 0;

type DialogKind = "ASSIGN_WATERSOURCE" | "CREATE_WATERSOURCE" | false;

const SchemePanel = (props: ISchemePanelProps): JSX.Element => {
    const [tab, setTab] = useState(0);
    const [editing, setEditing] = useState(false);
    const [dialogKind, setDialogKind] = useState<DialogKind>(false);

    const closeDialog = (): void => setDialogKind(false);

    const { scheme } = props;

    const handleAssignWaterSourceOpen = (): void => {
        setDialogKind("ASSIGN_WATERSOURCE");
    };
    const handleCloseDialog = (): void => {
        closeDialog();
    };

    const handleSelectWaterSource = (waterSource: NodeID): void => {
        props.onAssignWaterSource?.(parseNodeId(scheme.schemeNodeId), waterSource);
        closeDialog();
    };

    const handleCloseClick = (): void => {
        props.onClose?.();
    };

    const handleChange = (_: React.ChangeEvent<unknown>, value: any): void => {
        setTab(value);
    };

    const handleAddBoundary = (): void => {
        props.onAddBoundary?.();
    };
    const handleRemoveBoundary = (): void => {
        props.onRemoveBoundary?.(parseNodeId(scheme.schemeNodeId));
    };
    const handleEditBoundary = (): void => {
        props.onEditBoundary?.();
    };

    const handleEditing = (editing: boolean): void => {
        setEditing(editing);
        if (!editing) {
            props.onCancelEdit?.();
        }
    };

    const handleCreateInspectionsClick = (): void => {
        const ids = scheme.waterSources.map(ws => ws.waterSourceNodeId);
        props.onCreateInspectionsClick(ids);
    };

    return (
        <React.Fragment>
            <DrawerToolbar
                menu={<SchemeMenu
                    hasBoundary={hasBoundary(scheme)}
                    onAssignWaterSource={handleAssignWaterSourceOpen}
                    onAddBoundary={handleAddBoundary}
                    onRemoveBoundary={handleRemoveBoundary}
                    onEditBoundary={handleEditBoundary}
                    onCreateInspectionsClick={handleCreateInspectionsClick}
                />}
                title={`${scheme.schemeId} - ${scheme.title}`}
                onClose={handleCloseClick}
            />
            <Tabs variant="fullWidth" scrollButtons="off" value={tab} onChange={handleChange} >
                <Tab label="Details" id={`tab-${0}`} />
                <Tab label={formatWaterSourcesLabel(scheme.waterSources)} id={`tab-${1}`} />
            </Tabs>
            <TabPanelContainer>
                <TabPanel value={tab} index={0}>
                    <SchemeDetailsTab scheme={scheme} editing={editing} onEditing={handleEditing} onUpdateScheme={props.onUpdateScheme} hasBoundary={hasBoundary(scheme)} />
                </TabPanel>
                <TabPanel value={tab} index={1} disableGutters>
                    <WaterSourceListTab scheme={scheme} />
                </TabPanel>
            </TabPanelContainer>
            <AssignWaterSourceToSchemeDialog isOpen={dialogKind === "ASSIGN_WATERSOURCE"} onClose={handleCloseDialog} onSelect={handleSelectWaterSource} />
        </React.Fragment>
    );
};

export type { ISchemePanelProps };
export default SchemePanel;