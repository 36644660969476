import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

interface MobileSearchNoMatchResultProps {
    readonly searchTerm: string;
}

const useStyles = makeStyles(theme => createStyles({
    noMatch: {
        color: theme.palette.grey[300]
    }
}));

const MobileSearchNoMatchResult = ({ searchTerm }: MobileSearchNoMatchResultProps): JSX.Element => {
    const styles = useStyles();
    return (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={3}>
            <Typography variant="body1" className={styles.noMatch}>{`Water source '${searchTerm}' not found.`}</Typography>
        </Box>
    );
};

export type { MobileSearchNoMatchResultProps };
export default MobileSearchNoMatchResult;