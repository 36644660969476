import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { NodeID, encodeNodeId, parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { ISavedSearch } from "../../../store/types";
import * as api from "../api";
import DeleteSearchDialog from "../RemoveSavedFilterDialog";
import SaveSearchDialog from "../SaveFilterDialog";
import { SelectedFilters, FilterIterator } from "../types";
import SearchSelector, { SearchEntity } from "./searchSelector.view";
import useNavigation from "../../../router/DesktopRouter/hooks/useNavigation";

interface SearchActionState {
    readonly disabled: boolean;
    readonly execute: () => void;
}

interface SearchSelectorContextState {
    readonly clearSelection?: SearchActionState;
    readonly remove?: SearchActionState;
    readonly save?: SearchActionState;
    readonly saveAs?: SearchActionState;
}

const Context = React.createContext<SearchSelectorContextState>({});

interface SearchSelectorContextProps {
    readonly entity: SearchEntity;
    readonly selectedFilters: FilterIterator<unknown>;
    readonly onChangeSelection?: (selected: ISavedSearch | undefined) => void;
}

const searchesHash = (searches: ISavedSearch[]): string => {
    const s = searches.map(search => search.id).join(",");
    const h = btoa(s);
    return h;
};

const SearchSelectorContext = (props: React.PropsWithChildren<SearchSelectorContextProps>): JSX.Element => {
    const { children, ...other } = props;

    const dispatch = useDispatch();
    const { gotoSearch } = useNavigation();
    const params = useParams<{ savedSearchId?: string }>();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [savedSearches, setSavedSearches] = useState<ISavedSearch[]>([]);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();

    const closeDeleteDialog = (): void => setShowDeleteDialog(false);
    const closeSaveDialog = (): void => setShowSaveDialog(false);

    const showError = (message: string) =>
        (error?: Error | undefined): void => {
            if (error) {
                dispatch(setError(message, error));
            }
            else {
                dispatch(setGlobalToast({
                    showToast: true,
                    type: "ERROR",
                    message
                }))
            }
        };

    const showLoading = (message: string): void => {
        dispatch(setGlobalToast({
            showToast: true,
            type: "LOADING",
            message
        }));
    };

    const showSuccess = (message: string): void => {
        dispatch(setGlobalToast({
            showToast: true,
            type: "SUCCESS",
            message,
            timeout: 5000
        }))
    };

    const handleChange = (searchId: NodeID | undefined): void => {
        gotoSearch(other.entity, searchId);
    };
    const handleClearSelection = (): void => {
        gotoSearch(other.entity);
    };

    const setMutationResult = (result: api.SearchMutationResult): api.SearchMutationResult => {
        const { searches, nodeId } = result;
        setSavedSearches(searches);
        const selected = searches.find(s => s.id === nodeId);
        setSelectedSearch(selected);
        return result;
    };

    const handleEditSearch = (nodeId: string, name: string | undefined, filters: SelectedFilters): void => {
        showLoading(`Updating '${name}'...`);
        api.saveExistingSearch(other.entity, nodeId, name ?? "", filters)
            .then(result => setMutationResult(result))
            .then(() => showSuccess("Search updated."))
            .catch(showError("Error updating search."));
    };
    const handleRemoveSearch = (nodeId: string): void => {
        showLoading(`Deleting search...`);
        api.deleteSearches(other.entity, [nodeId])
            .then(result => setMutationResult(result))
            .then(() => showSuccess("Search deleted."))
            .then(() => handleClearSelection())
            .catch(showError("Error deleting search."));
    };
    const handleSaveNewSearch = (name: string, filters: SelectedFilters): void => {
        showLoading("Saving search...");
        api.saveNewSearch(other.entity, name, filters)
            .then(result => setMutationResult(result))
            .then(result => result.nodeId
                ? gotoSearch(other.entity, parseNodeId(result.nodeId))
                : gotoSearch(other.entity))
            .then(() => showSuccess("Search saved."))
            .catch(showError("Error saving search"));
    };

    const handleDeleteConfirm = (): void => {
        closeDeleteDialog();
        if (selectedSearch) {
            handleRemoveSearch(selectedSearch.id);
        }
    };

    const handleSave = (): void => {
        if (selectedSearch) {
            handleEditSearch(selectedSearch.id, selectedSearch.displayText, other.selectedFilters);
        }
    };

    const handleSaveAs = (searchName: string): void => {
        closeSaveDialog();
        handleSaveNewSearch(searchName, other.selectedFilters);
    };

    const handleSaveError = (message: string): void => {
        showError(message)();
    };

    useEffect(() => {
        const showLoading = (message: string): void => {
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message
            }));
        };
        const showError = (message: string): void => {
            dispatch(setGlobalToast({
                showToast: true,
                type: "ERROR",
                message
            }))
        };
        const hideToast = (): void => {
            dispatch(setGlobalToast());
        };

        showLoading("Loading saved searches...");
        api.getSavedSearches(other.entity)
            .then(searches => setSavedSearches(searches))
            .catch(() => showError("Error loading saved searches."))
            .finally(() => hideToast());
    }, []);

    const { savedSearchId } = params;
    const hash = searchesHash(savedSearches);
    useEffect(() => {
        const selected = savedSearches.find(s => s.id === savedSearchId);
        setSelectedSearch(selected);
    }, [savedSearchId, savedSearches, hash, setSelectedSearch]);

    useEffect(() => {
        other.onChangeSelection?.(selectedSearch);
    }, [selectedSearch?.id]);

    const context: SearchSelectorContextState = {
        clearSelection: {
            disabled: false,
            execute: (): void => handleClearSelection()
        },
        remove: {
            disabled: selectedSearch === undefined,
            execute: (): void => setShowDeleteDialog(true)
        },
        save: {
            disabled: Object.keys(other.selectedFilters).length === 0 || selectedSearch === undefined,
            execute: (): void => handleSave()
        },
        saveAs: {
            disabled: Object.keys(other.selectedFilters).length === 0 || selectedSearch !== undefined,
            execute: (): void => setShowSaveDialog(true)
        }
    };

    return (
        <Context.Provider value={context}>
            <DeleteSearchDialog
                isOpen={showDeleteDialog}
                onCancel={closeDeleteDialog}
                onConfirm={handleDeleteConfirm}
            />
            <SaveSearchDialog
                isOpen={showSaveDialog}
                existingSearchNames={[]}
                onCancel={closeSaveDialog}
                onError={handleSaveError}
                onSave={handleSaveAs}
            />
            <SearchSelector entity={other.entity} savedSearches={savedSearches} selected={selectedSearch} onChange={handleChange} onClear={handleClearSelection} />
            {children}
        </Context.Provider>
    );
};

const useSearchContext = (): SearchSelectorContextState => {
    return React.useContext(Context);
};

export type { SearchSelectorContextState, SearchSelectorContextProps, SearchActionState };
export { useSearchContext };
export default SearchSelectorContext;