import React from "react";

import useInspectionsSummary, { InspectionSummary, empty } from "../../api/hooks/useInspectionsSummary";
import getInspections from "../../api/getRouteInspections";
import useTechnician from "../../auth/hooks/useTechnician";
import LoadingContainer from "../../components/shared/loadingContainer";
import UnregisteredTechnicianItem from "../../components/shared/unregisteredTechnicianItem";
import View from '../../components/shared/view';
import RoutesList, { RouteItem } from '../../components/routesList';
import useStationNodeIdParam from "../../router/MobileRouter/hooks/useStationNodeIdParam";

const reducer = (routes: Record<number, RouteItem>, inspection: InspectionSummary): Record<number, RouteItem> => {
    const { route } = inspection;
    const update = (count: number): Record<number, RouteItem> => ({
        ...routes,
        [route.nodeId.id]: { ...route, inspectionCount: count + 1 }
    });
    return routes[route.nodeId.id] === undefined
        ? update(0)
        : update(routes[route.nodeId.id].inspectionCount);
};

const lessThan = -1;
const equalTo = 0;
const greaterThan = 1;

const compare = (a: RouteItem, b: RouteItem): number => {
    if (isNaN(a.nodeId.id)) {
        return greaterThan;
    }
    if (isNaN(b.nodeId.id)) {
        return lessThan;
    }
    if (a.nodeId.id === b.nodeId.id) {
        return equalTo;
    }
    return a.name.localeCompare(b.name);
};

const RoutesListView = (): JSX.Element => {
    const stationId = useStationNodeIdParam();
    const technicianId = useTechnician();
    const [status, inspections = empty, refresh] = useInspectionsSummary(getInspections, technicianId, stationId);
    const isLoading = status !== "complete";
    const collation = inspections.edges
        .map(edge => edge.node)
        .reduce(reducer, {});
    const routes = Object
        .values(collation)
        .sort(compare);

    const handleViewRefresh = (): void => {
        refresh();
    };

    return (
        <View backButton onRefreshClick={handleViewRefresh}>
            {technicianId
                ? <LoadingContainer isLoading={isLoading} component={<RoutesList routes={routes} stationId={stationId} />} />
                : <UnregisteredTechnicianItem />
            }
        </View>
    );
};

export default RoutesListView;