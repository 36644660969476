import React, { PropsWithChildren } from "react";
import { LoadingBar } from "@3tc/shared-components";

interface LoadingContainerProps {
    readonly isLoading?: boolean;
    readonly component?: React.ReactNode;
}

const LoadingContainer = (props: PropsWithChildren<LoadingContainerProps>): JSX.Element => {
    const { children, component, isLoading } = props;
    return (
        <React.Fragment>
            {isLoading
                ? <LoadingBar loading />
                : component ?? children
            }
        </React.Fragment>
    );
};

export default LoadingContainer;