import { shallowEqual } from "react-redux";

import useSelector from "../../lib/hooks/useSelector";
import { IUser } from "../../store/types";

type SecurityPrincipal = IUser | undefined;

const useSecurityPrincipal = (): SecurityPrincipal => {
    const principal = useSelector<IUser | undefined>(state => state.app.user, shallowEqual);
    return principal;
};

export type { SecurityPrincipal };
export default useSecurityPrincipal;
