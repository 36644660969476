import React from "react";
import classnames from "classnames";
import { LocalDate } from "@js-joda/core";

import DateInput from "../../../utils/DateInput";

import { EditElementProps } from "../../types";
import { inputClass } from "../../labelledField.utils";

import ReadOnlyElement from "../readOnlyElement";
import LabelledContainer from "../LabelledContainer";

type DateElementProps = EditElementProps<LocalDate>;

const DateElement = ({ editing, value, onChange, ...others }: DateElementProps): JSX.Element => {
    if (editing) {
        const handleChange = (value: LocalDate | undefined): void => {
            onChange(value);
        };
        return (
            <LabelledContainer {...others}>
                <DateInput value={value} {...others} onChange={handleChange} classes={classnames(inputClass(others))} />
            </LabelledContainer>
        );
    }
    return <ReadOnlyElement {...others} value={value} />;
};

export type { DateElementProps };
export default DateElement;