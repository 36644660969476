import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, BooleanProps } from "../types";

const RepairPaymentAuthorised = ({ show, editing, data, onChange }: PropsWithEditing<PropsWithShow<BooleanProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "checkbox",
        id: "repair-payment-authorised",
        label: "Payment Authorised?",
        classes: { container: 'input__group', label: 'input__label' },
        value: data.isPaymentAuthorised,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairPaymentAuthorised;