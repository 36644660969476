import React, { useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import SearchIcon from '@material-ui/icons/Search';

import { AddressNode } from "../../../../../store/types";
import AddressSearch from "../../../../address/AddressSearch";
import { MobileSearchInput, SearchInputProps } from "../../../../address/AddressSearchInput";
import { RenderAction } from "../../../../address/AddressSearchItem";
import { FindAddressProps as BaseProps } from "../../../../address/FindAddress";
import { AddressDetailsData, toAddressDetails, toAddressNode } from "../../../../address/types";
import { WaterSourceLocationChange } from "../WaterSourceLocation.view";
import AppDialog from "../../../../shared/appDialog";
import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";
import { AddressChanged, AddressSelected } from "./types";

const useStyles = makeStyles(createStyles({
    button: {
        marginLeft: "-5px"
    }
}));

const renderInput = (inputProps: SearchInputProps): JSX.Element => {
    return <MobileSearchInput {...inputProps} />;
};

interface FindAddressProps {
    readonly onChange?: AddressChanged;
    readonly onSelect?: AddressSelected;
}

const FindAddress = ({ onChange, onSelect }: FindAddressProps): JSX.Element => {
    const styles = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };
    const handleEditSelect = (address: AddressDetailsData): void => {
        setDialogOpen(false);
        if (address) {
            onSelect?.(toAddressNode(address));
        }
    };
    const handleSearchSelect = (address: AddressNode): void => {
        setDialogOpen(false);
        if (address) {
            onSelect?.(address);
        }
    };

    const renderEditAction = (refresh?: () => void): RenderAction => {
        const handleUpdate = (value: AddressDetailsData): void => {
            refresh?.();
            onChange?.(toAddressNode(value));
        };
        const EditAction = (address: AddressNode): JSX.Element => {
            return <EditAddress context="search" variant="icon" initialAddress={toAddressDetails(address)} onSelect={handleEditSelect} onUpdate={handleUpdate} />
        };
        return EditAction;
    };
    
    return (
        <React.Fragment>
            <Button size="small" color="secondary" startIcon={<SearchIcon />} className={styles.button} onClick={handleActionClick}>Find Address</Button>
            <AppDialog open={dialogOpen} appBar={{ title: "Find Address" }} onClose={handleCloseDialog}>
                <Box display="flex" flexDirection="column" width="100%" mb={5}>
                    <AddressSearch mobile pageSize={25} theme="light" renderInput={renderInput} renderItemEdit={renderEditAction} onSelect={handleSearchSelect} />
                </Box>
                <AddAddress active={dialogOpen} onConfirm={handleEditSelect} />
            </AppDialog>
        </React.Fragment>
    );
};

export type { FindAddressProps };
export default FindAddress;