import React from 'react';
import { Checkbox, FormGroup, FormControlLabel, makeStyles, createStyles } from "@material-ui/core";
import { ILookupItem, LookupOwnerType } from '../../../store/types';
import { formatCamelOrPascalCase } from '../../../lib/formatters';

interface ITagTypeProps {
    readonly tag?: ILookupItem;
    readonly handleChangeTagType: (tag: ILookupItem | undefined, tagType: LookupOwnerType, shouldAddTagType: boolean) => void;
}

const LookupOwners: LookupOwnerType[] = ['Defect', 'EmergencyWaterSupply', 'Hydrant', 'Riser', 'UnknownWaterSource'];
const useStyles = makeStyles(theme => createStyles({
    checkBoxLabel: {
        marginLeft: "0px"
    },
    checkbox: {
        color: theme.palette.secondary.main
    }
}));

const TagType = ({ tag, handleChangeTagType }: ITagTypeProps): JSX.Element => {
    const styles = useStyles();
    const isChecked = (lookupOwner: LookupOwnerType): boolean => (tag?.filters.indexOf(lookupOwner) ?? -1) > -1 

    return (
        <FormGroup>
            <span>Please select the type of Object(s) the Tag can be added to:</span>
            {LookupOwners.map((lookupOwner) => <FormControlLabel key={lookupOwner} className={styles.checkBoxLabel} label={formatCamelOrPascalCase(lookupOwner)} control={
                <Checkbox
                    checked={isChecked(lookupOwner)}
                    onChange={(_event, checked): void => handleChangeTagType(tag, lookupOwner, checked)}
                    className={styles.checkbox}
                />
            } />)}
        </FormGroup>
    )
};

export default TagType;