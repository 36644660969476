import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { FetchStatus } from "../../../api/hooks";
import { getInspectionsForWaterSource } from "../../../store/actions/inspections";
import { IInspection } from "../../../store/types";

const useInspections = (waterSourceNodeId: string | undefined): [IInspection[], () => void, FetchStatus] => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [inspections, setInspections] = useState<IInspection[]>([]);

    const dispatch = useDispatch();

    const fetch = useCallback((): void => {
        if (waterSourceNodeId) {
            setStatus("loading");
            const action = getInspectionsForWaterSource({ id: waterSourceNodeId });
            action(dispatch)
                .then(response => setInspections(response))
                .catch(error => console.warn("Error getting inspections for water source", error))
                .finally(() => setStatus("complete"));
        }
        else {
            setInspections([]);
            setStatus("complete");
        }
    }, [waterSourceNodeId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [inspections, fetch, status]
};

export default useInspections;