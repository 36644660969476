import React, { useEffect, useState } from "react";

const postCodePattern = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

interface IPostCodeSearchProps {
    readonly input: string;
    readonly projection?: string;
    readonly onSearchInvalid: () => void;
    readonly showOnMap: (postCode: string, projectionCode: string | undefined) => void;
}

const PostCodeSearch = (props: IPostCodeSearchProps): JSX.Element => {
    const [isInputValid, setIsInputValid] = useState<boolean>(true);

    const { input, projection, showOnMap } = props;
    useEffect(() => {
        if (postCodePattern.test(input)) {
            setIsInputValid(true);
            showOnMap(input, projection);
        }
        else {
            setIsInputValid(false);
        }
    }, [input, projection, showOnMap]);

    useEffect(() => {
        if (!isInputValid) {
            props.onSearchInvalid?.();
        }
    }, [isInputValid, props.onSearchInvalid]);

    return <React.Fragment />;
};

export type { IPostCodeSearchProps };
export default PostCodeSearch;