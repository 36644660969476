import React from "react";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

import { GroupItem } from "../../../../lib/collections";
import { IInspection } from "../../../../store/types";
import InspectionItem from "../inspectionItem";
import useStyles from "./InspectionItemGroup.styles";

interface InspectionItemGroupProps {
    readonly groupItem: GroupItem<IInspection>;
}

const ItemGroup = (props: InspectionItemGroupProps): JSX.Element => {
    const styles = useStyles();
    const { groupItem } = props;
    const { label, items } = groupItem;
    return (
        <li key={label}>
            <List disablePadding>
                <ListSubheader className={styles.subheader}>
                    {label}
                </ListSubheader>
                {items.map(inspection => {
                    const { inspectionNodeId, inspectionId, inspectionDate, waterSource } = inspection;
                    return <InspectionItem key={inspection.inspectionId} inspectionNodeId={inspectionNodeId} inspectionId={inspectionId} inspectionDate={inspectionDate} waterSource={waterSource} />
                })}
            </List>
        </li>
    );
};

export type { InspectionItemGroupProps };
export default ItemGroup;