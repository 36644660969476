import { RouteState } from "../appWindowContext";
import { LineNodeFeature, MapDrawGeometry, PolygonInfo } from "../map/desktop";
import { compareWaterSources } from "../routes/routePanel/RoutePanel.utils";
import { IScheme, IThoroughfare, IWaterPipe, MapClickAction } from "../../store/types";

export const generateJoiningLines = (selectedRoute: RouteState): LineNodeFeature[] => {
    const { route: { routeNodeId = "", waterSources = [] } = {}, showOnMap } = selectedRoute;
    if (showOnMap) {
        const path = waterSources
            .sort(compareWaterSources)
            .map(w => w.location.coordinates);
        return [{ nodeId: routeNodeId, path }];
    }
    return [];
};

export const generatePolygons = ({ title, boundary }: IScheme): PolygonInfo[] | undefined => {
    if (boundary) {
        return [{
            data: {
                reference: title
            },
            coords: boundary.exteriorRing.coordinates.map((point) => [point.x, point.y])
        }];
    }
    return undefined
};

export const generateThoroughfares = ({ thoroughfares }: IScheme): LineNodeFeature[] => {
    const resolveCoordinates = ({ thoroughfareNodeId, path }: IThoroughfare): LineNodeFeature => {
        return {
            nodeId: thoroughfareNodeId,
            path: path?.coordinates ?? []
        };
    }
    return thoroughfares.map(resolveCoordinates);
};

export const generateWaterPipes = ({ waterPipes }: IScheme): LineNodeFeature[] => {
    const resolveCoordinates = ({ waterPipeNodeId, path }: IWaterPipe): LineNodeFeature => {
        return {
            nodeId: waterPipeNodeId,
            path: path?.coordinates ?? []
        };
    };
    return waterPipes.map(resolveCoordinates);
};

export const getCustomCursor = (action: MapClickAction | undefined): string | undefined => {
    switch (action) {
        case 'CREATE_WATER_SOURCE':
            return `url('data:image/svg+xml;utf8,<svg width="16px" height="16px" fill="rgb(0, 0, 0)" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.99,6.99h-2v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2c-0.55,0-1,0.45-1,1s0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2h2c0.55,0,1-0.45,1-1S11.54,6.99,10.99,6.99z M7.99-0.01c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S12.41-0.01,7.99-0.01z M7.99,13.99c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S11.31,13.99,7.99,13.99z"/></svg>'), cell`
        case 'STREET_VIEW':
            return `url('data:image/svg+xml;utf8,<svg width="16px" height="16px" fill="rgb(0, 0, 0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15,14.62l-4-2.4V9.77c-0.32,0.09-0.66,0.15-1,0.18v2.27l-4,2.4V8.71C5.62,8.4,5.28,8.05,5,7.65v6.97l-4-2.4V8c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1V1.38l3.15,1.89C4.23,2.93,4.33,2.61,4.47,2.3L0.76,0.07l0,0.01C0.68,0.03,0.59,0,0.5,0C0.22,0,0,0.22,0,0.5v12c0,0.18,0.1,0.33,0.25,0.42l0,0.01l5,3l0-0.01C5.32,15.97,5.41,16,5.5,16s0.18-0.03,0.25-0.08l0,0.01l4.74-2.85l4.74,2.85l0-0.01C15.32,15.97,15.41,16,15.5,16c0.28,0,0.5-0.22,0.5-0.5v-3.78C15.7,11.89,15.37,12,15,12V14.62z M2,5c0,0.55,0.45,1,1,1s1-0.45,1-1c0-0.55-0.45-1-1-1S2,4.45,2,5z M8,4C7.45,4,7,4.45,7,5c0,0.55,0.45,1,1,1s1-0.45,1-1C9,4.45,8.55,4,8,4z M15.75,3.08L15.75,3.08l-1.19-0.72c0.18,0.43,0.29,0.9,0.36,1.38L15,3.78v3.39l1,1V3.5C16,3.32,15.9,3.17,15.75,3.08z M10,2C9.45,2,9,2.45,9,3c0,0.55,0.45,1,1,1s1-0.45,1-1C11,2.45,10.55,2,10,2z M13.3,6.89C13.74,6.19,14,5.38,14,4.5C14,2.01,11.99,0,9.5,0S5,2.01,5,4.5S7.01,9,9.5,9c0.88,0,1.69-0.26,2.39-0.7l2.41,2.41C14.47,10.89,14.72,11,15,11c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L13.3,6.89zM9.5,8C7.57,8,6,6.43,6,4.5S7.57,1,9.5,1S13,2.57,13,4.5S11.43,8,9.5,8z"/></svg>'), zoom-in`
        default:
            return undefined;
    }
};

export const getDeletionType =  (action: MapClickAction | undefined): false | MapDrawGeometry => {
    switch (action) {
        case 'DELETE_WATER_PIPE':
            return 'WaterPipe'
        case 'DELETE_THOROUGHFARE':
            return 'Thoroughfare'
        default:
            return false;
    }
};
