import React from "react";

import { CategoryIconProps } from "./categoryIcon";

const Abandoned = (props: CategoryIconProps & { show?: boolean }): JSX.Element => props.show
    ? (
        <svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 24 24">
            <g stroke="#e73930" strokeWidth="2px">
                <line x1="0" y1="0" x2="24" y2="24" />
                <line x1="0" y1="24" x2="24" y2="0" />
            </g>
        </svg>
    )
    : <React.Fragment />;

export default React.memo(Abandoned);