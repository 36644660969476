import React, { useState, useEffect, useRef } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from '../../../dialog/dialog';
import DialogActions from '../../../dialog/dialogActions';
import DialogTitle from '../../../dialog/dialogTitle';
import DialogContent from '../../../dialog/dialogContent';
import { SelectOption, OptionValue } from "../../../labelledField";
import { makeHash } from "../../../../lib/collections";

interface EwsPumpTypesDialogProps {
    readonly open: boolean;
    readonly options: SelectOption[];
    readonly selected: SelectOption[];
    readonly onClose?: () => void;
    readonly onChange?: (values: OptionValue[]) => void;
}

const initState = (options: SelectOption[], selected: SelectOption[]): OptionValue[] => {
    return options
        .filter(option => option.enabled)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(option => option.value)
        .filter(value => selected.some(item => item.value === value));
};

const calculateHash = makeHash<OptionValue>(value => value.toString());

const useStyles = makeStyles(theme => createStyles({
    checkbox: {
        color: theme.palette.primary.light
    }
}));

const EwsPumpTypesDialog = ({ open, options, selected, onClose, onChange }: EwsPumpTypesDialogProps): JSX.Element => {
    const styles = useStyles();
    const [checked, setChecked] = useState<OptionValue[]>([]);

    const handleClose = (): void => {
        onClose?.();
    };
    const handleSave = (): void => {
        onChange?.(checked);
    };

    const handleToggle = (changed: OptionValue) => (): void => {
        const currentIndex = checked.indexOf(changed);
        const newState = [...checked];

        if (currentIndex < 0) {
            newState.push(changed);
        }
        else {
            newState.splice(currentIndex, 1);
        }

        setChecked(newState);
    };

    const prevOpen = useRef(false);
    const baseHash = useRef("");
    useEffect(() => {
        if (open && prevOpen.current !== open) {
            const state = initState(options, selected);
            setChecked(state);
            prevOpen.current = open;
            baseHash.current = calculateHash(state);
        }
    }, [open, options, selected]);

    const disableSave = baseHash.current === calculateHash(checked);
    return (
        <Dialog maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Pump Types</DialogTitle>
            <DialogContent>
                <List>
                    {options.map(({ value, displayText }) => {
                        const id = `pumpTypes-item-${value}`;
                        return (
                            <ListItem key={value} dense button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.includes(value)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": id }}
                                        className={styles.checkbox}
                                        color="secondary"
                                    />
                                </ListItemIcon>
                                <ListItemText id={id} primary={displayText} />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <button className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                <button className="action-button" disabled={disableSave} onClick={handleSave}>Save</button>
            </DialogActions>
        </Dialog>
    );
};

export type { EwsPumpTypesDialogProps };
export default EwsPumpTypesDialog;