import { IDeviceAction, IDeviceSettings } from "../types/device";

export const setDeviceIsDesktop = (): IDeviceAction => ({
    type: "SET_DEVICE_ISDESKTOP"
});

export const setDeviceIsMobile = (): IDeviceAction => ({
    type: "SET_DEVICE_ISMOBILE"
});

export const setDevice = (settings: IDeviceSettings): IDeviceAction => settings.isMobile === "true"
    ? setDeviceIsMobile()
    : setDeviceIsDesktop();