import executeQuery from "../../../lib/executeQuery";
import { IScheme, IRoute, ILookupItem, WaterSourceCategory, isScheme } from "../../../store/types";
import { SelectOption } from "../../labelledField";

export type CreateWaterSourceContext = IScheme | IRoute;

export const getCreateWaterSourceContext = async (id: string): Promise<CreateWaterSourceContext | undefined> => {
    const gql = `
        query getCreateWaterSourceContext($id: ID!) {
            node(id: $id) {
                ... on Route {
                    routeNodeId: id
                    name
                    waterSources {
                        waterSourceNodeId: id
                    }
                }        
                ... on Scheme {
                    schemeNodeId: id
                    title
                }
            }
        }`;
    return executeQuery<{ node: CreateWaterSourceContext | undefined }>(gql, { id })
        .then(response => response?.node);
};

export const matchCategory = (category: SelectOption | undefined) =>
    (item: ILookupItem): boolean => {
        return typeof category !== "undefined" && (item.groupingId === category.value);
    };

export const defaultStatus = (statuses: ILookupItem[], category: ILookupItem | undefined, required = "PROPOSED"): ILookupItem | undefined => {
    const status = statuses
        .filter(matchCategory(category))
        .find(s => s.displayText.toUpperCase() === required.toUpperCase());
    return status;
};
