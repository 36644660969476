import React, { Component } from 'react';

import { validationStyle, requiredString, requiredObject, validateState, StateValidators } from '../../../lib/validation';
import { ILookupItem, INewRoute } from '../../../store/types';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import LabelledField, { asLookupItem, SelectOption } from '../../labelledField';

interface ICreateRouteProps {
    readonly isOpen: boolean;
    readonly stations: ILookupItem[];
    readonly onClose?: () => void;
    readonly onCreateRoute?: (route: INewRoute) => void;
}

interface ICreateRouteState {
    readonly name: string | undefined;
    readonly station: ILookupItem | undefined;
}

const validators: StateValidators<ICreateRouteState> = {
    name: requiredString,
    station: requiredObject
};

const validateForm = validateState(validators);

class CreateRouteDialog extends Component<ICreateRouteProps, ICreateRouteState> {
    constructor(props: ICreateRouteProps) {
        super(props);

        this.state = {
            name: undefined,
            station: undefined
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private handleSubmit(): void {
        this.props.onClose?.();
        this.props.onCreateRoute?.({
            name: this.state.name ?? "",
            stationId: this.state.station
                ? this.state.station.value
                : undefined
        });
    }

    public render(): JSX.Element {
        const handleClose = (): void => {
            this.props.onClose?.();
        };
        const handleSaveClick = (): void => {
            this.handleSubmit();
        }
        const handleNameChange = (value: string | undefined): void => {
            this.setState({ name: value });
        };
        const handleStationChange = (value: SelectOption | undefined): void => {
            this.setState({ station: asLookupItem(value) });
        };
        const handleStationClear = (): void => {
            this.setState({ station: undefined });
        };

        const disableSave = !validateForm(this.state);
        return (
            <Dialog fullWidth maxWidth="sm" open={this.props.isOpen} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>New Route</DialogTitle>
                <DialogContent>
                    <LabelledField
                        id="new-route-name"
                        label="Route Name *"
                        fieldType="text"
                        editing
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.name)(this.state.name) }}
                        value={this.state.name}
                        onChange={handleNameChange}
                    />
                    <LabelledField
                        fieldType="select"
                        id="station"
                        label="Station *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.station)(this.state.station) }}
                        value={this.state.station}
                        selectOptions={this.props.stations}
                        editing
                        onChange={handleStationChange}
                        onClear={handleStationClear}
                    />
                </DialogContent>
                <DialogActions>
                    <button className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button className="action-button" disabled={disableSave} onClick={handleSaveClick}>Save</button>
                </DialogActions>
            </Dialog>
        );
    }
}

export type { ICreateRouteProps };
export default CreateRouteDialog;
