import React from "react";
import List from "@material-ui/core/List";
import { RouteWaterSource } from "../../RoutePanel.utils";
import InspectionListItem from './InspectionListItem.view';

interface InspectionListTabProps {
    readonly routerWaterSources: RouteWaterSource[];
    readonly isEditing: boolean;
    readonly handleListItemClick: (waterSorceNodeId: string) => void;
    readonly handleRemoveWaterSource: (ws: RouteWaterSource) => void;
    readonly handleMoveWaterSource: (waterSourceid: string, idToMoveAbove: string) => void;
}

const InspectionsList = ({ routerWaterSources, isEditing, handleListItemClick, handleRemoveWaterSource, handleMoveWaterSource }: InspectionListTabProps): JSX.Element => {
    return (
        <List disablePadding>
            {routerWaterSources.map(ws => 
                <InspectionListItem
                    key={ws.waterSourceNodeId}
                    isEditing={isEditing}
                    routerWaterSource={ws}
                    handleListItemClick={handleListItemClick}
                    handleRemoveWaterSource={handleRemoveWaterSource}
                    handleMoveWaterSource={handleMoveWaterSource}
                />
            )}
        </List>
    );
};

export type { InspectionListTabProps };
export default InspectionsList;