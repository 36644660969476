import React from "react";
import classnames from "classnames";

import styles from "./elementContainer.module.scss";

export interface IElementContainerProps {
    readonly classes?: string;
    readonly children?: React.ReactNode;
    readonly noMargin?: boolean;
}

const ElementContainer = (props: IElementContainerProps): JSX.Element => {
    const css = classnames(styles.labelledField, props.noMargin ? styles.noMargin : "", props.classes);
    return (
        <div className={css}>
            {props.children}
        </div>
    );
};

export default ElementContainer;