import { IDefect, IInspection } from "../../../store/types";
import { SelectOption } from "../../labelledField";

export const toDefectOption = (source: Pick<IDefect, "defectId" | "type">, index: number): SelectOption => ({
    value: source.defectId,
    displayText: `${source.defectId} (${source.type.displayText})`,
    enabled: true,
    sortOrder: index
});

export const toInspectionOption = (source: Pick<IInspection, "inspectionId" | "type">, index: number): SelectOption => ({
    value: source.inspectionId,
    displayText: `${source.inspectionId} (${source.type.displayText})`,
    enabled: true,
    sortOrder: index
});
