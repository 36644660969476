import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

interface ActionsProps {
    readonly editing?: boolean;
    readonly onCancel?: () => void;
    readonly onEdit?: () => void;
    readonly onSave?: () => void;
}

const Actions = (props: ActionsProps): JSX.Element => {
    const { editing, onCancel, onEdit, onSave } = props;

    if (editing) {
        const handleCancelClick = (): void => {
            onCancel?.();
        };
        const handleSaveClick = (): void => {
            onSave?.();
        };
        return (
            <Box>
                <Button onClick={handleCancelClick}>Cancel</Button>
                <Button onClick={handleSaveClick}>Save</Button>
            </Box>
        );
    }

    const handleEditClick = (): void => {
        onEdit?.();
    };
    return (
        <Button onClick={handleEditClick}>Edit</Button>
    );
};

export type { ActionsProps };
export default Actions;
