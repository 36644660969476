import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AddressDetailsData } from "../components/address";
import { setError } from "../store/actions/errors";
import { AddressNode } from "../store/types";
import { setLoadingToast, setSuccessToast } from "../store/actions/app";
import executeQuery from "../lib/executeQuery";
import { guid } from "../lib/Utils";
import updateAddress from "./graphql/updateAddress";
import { EditAddressRequest, UpdateAddressResponse } from "./types";

export const startUpdateAddress = (addressNodeId: string, data: AddressDetailsData) => {
    return async (dispatch: ThunkDispatch<any, unknown, AnyAction>): Promise<AddressNode | undefined> => {
        try {
            dispatch(setLoadingToast("Updating address record..."));
            const variables: EditAddressRequest = {
                input: {
                    clientMutationId: guid(),
                    nodeId: addressNodeId,
                    data: {
                        paon: {
                            description: data.description ?? null
                        },
                        streetDescription: data.streetDescription ?? null,
                        locality: data.locality ?? null,
                        town: data.town ?? null,
                        postCode: data.postCode ?? null
                    }
                }
            };
            const response = await executeQuery<UpdateAddressResponse>(updateAddress, variables);
            const { address } = response?.gis.address?.update ?? {};
            if (address) {
                dispatch(setSuccessToast("Address record updated."))
                return address;
            }
            const errorMessages = response?.gis.address?.update.errors.map(e => e.message).join(" ")
            dispatch(setError("Error updating address record.", new Error(errorMessages)));
        } catch (error) {
            dispatch(setError("Error updating address record.", error));
        }
        return undefined;
    };
};
