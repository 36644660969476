import React from 'react';
import { Dialog } from '@blueprintjs/core';

interface IRemoveSavedFilterDialogProps {
    readonly isOpen: boolean
    readonly onCancel?: () => void;
    readonly onConfirm?: () => void;
}

const RemoveSavedFilterDialog = (props: IRemoveSavedFilterDialogProps): JSX.Element => {
    const { isOpen, onCancel, onConfirm } = props;

    const handleCancelClick = (): void => {
        onCancel?.();
    };

    const handleConfirmClick = (): void => {
        onConfirm?.();
    };

    return (
        <Dialog title="Remove Filter" portalClassName="wm-dialog-portal" isOpen={isOpen} onClose={handleCancelClick}>
            <div className="wm-dialog-body">
                <p>Are you sure you want to remove the current saved search?</p>
            </div>
            <div className="wm-dialog-buttonContainer">
                <button className="action-button" onClick={handleCancelClick}>No</button>
                <button className="action-button action-button--cancel" onClick={handleConfirmClick}>Yes</button>
            </div>
        </Dialog>
    );
};

export default RemoveSavedFilterDialog;