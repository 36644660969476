import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        marginRight: theme.spacing(0.5),
        maxHeight: "200px",
        overflowX: "hidden",
        overflowY: "auto"
    }
}));

const FilterContainer = (props: React.PropsWithChildren<unknown>): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            {props.children}
        </div>
    );
};

export default FilterContainer;