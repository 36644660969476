import React from "react";

import { IWaterSource } from "../../store/types";
import WaterSourceTitle, { createTitleProps } from "../waterSource/waterSourceTitle";

interface SearchResultTitleProps {
    readonly waterSource?: IWaterSource;
}

const SearchResultTitle = (props: SearchResultTitleProps): JSX.Element => {
    const { waterSource } = props;
    return waterSource
        ? <WaterSourceTitle {...createTitleProps(waterSource)} dark />
        : <React.Fragment />;
};

export type { SearchResultTitleProps };
export default SearchResultTitle;