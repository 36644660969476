import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { defaultLookups } from "../../../store/reducers/app";
import { useWaterSource } from "../../appWindowContext";
import { WaterSourceTab } from "../../dataTabs/DataTabsContext";
import RepairSearchBase, { RepairSearchProps } from "./repairSearch.view";

const RepairSearch = (): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showWaterSource } = useWaterSource({ newWindow: true });
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const props: RepairSearchProps = {
        lookups,
        hideToast: () =>
            dispatch(setGlobalToast()),
        showError: (message, error) => error
            ? dispatch(setError(message, error))
            : dispatch(setGlobalToast({
                showToast: true,
                type: "ERROR",
                message
            })),
        showLoading: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message
            })),
        showRepairOnMap: (repair): void => {
            const { repairNodeId, waterSource } = repair;
            showWaterSource(parseNodeId(waterSource.waterSourceNodeId), {
                tab: WaterSourceTab.REPAIRS,
                repair: parseNodeId(repairNodeId),
                focusMap: true
            });
        },
        showSuccess: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message,
                timeout: 5000
            }))
    };
    return <RepairSearchBase {...props} />;
};

export default RepairSearch;