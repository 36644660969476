import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { WaterSourceTitleProps } from "./types";

const useStyles = makeStyles(theme => createStyles({
    subTitle: {
        color: theme.palette.primary.contrastText
    },
}));

const WaterSourceSubtitle = ({ subtitle }: Pick<WaterSourceTitleProps, "subtitle">): JSX.Element => {
    const styles = useStyles();
    if (typeof subtitle === "function") {
        return subtitle(styles.subTitle);
    }
    if (typeof subtitle === "string" || typeof subtitle === "number") {
        return <Typography variant="caption" classes={{ root: styles.subTitle }}>{subtitle}</Typography>;
    }
    return <React.Fragment />;
};

export default WaterSourceSubtitle;