import { useState, useCallback, useEffect } from "react";

import { IFileList, getFileList } from "../../components/shared/fileManager";
import useSelector from "../../lib/hooks/useSelector";
import { FetchStatus, FetchMethod } from "./types";
import { bindIfMounted } from "./utils";

const useAttachments = (inspectionId: number | undefined): [FetchStatus, IFileList, FetchMethod] => {
    const fileStoreToken = useSelector<string>(state => state.fileStore.token);
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [attachments, setAttachments] = useState<IFileList>({});

    const fetch = useCallback(() => {
        let isMounted = true;
        const ifMounted = bindIfMounted(isMounted);

        setStatus("loading");
        if (inspectionId) {
            getFileList(fileStoreToken, "INSPECTION", inspectionId)
                .then(files => ifMounted(() => setAttachments(files)))
                .catch(() => ifMounted(() => setAttachments({})))
                .finally(() => ifMounted(() => setStatus("complete")));
        }
        else {
            ifMounted(() => setAttachments({}));
            ifMounted(() => setStatus("complete"));
        }

        return (): void => {
            isMounted = false;
        };
    }, [inspectionId, fileStoreToken]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [status, attachments, fetch];
};

export default useAttachments;