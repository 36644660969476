export default `
fragment Inspection on Inspection {
    inspectionNodeId: id
    inspectionId
    type {
        displayText
    }
    inspectionDate
    isOpen
    isAssignedToGroup
    isAssignedToInspector
    technician {
        displayName
    }
    crew {
        callSign
    }
    inspectionGroup {
        name
    }
    waterSource {
        waterSourceNodeId: id
        waterSourceId: externalId
    }
}

query getInspectionsBasicsByFilter($filter:QueryFilter, $paging: QueryControlInput) {
  inspections(queryFilter: $filter, queryControl: $paging) {
    totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        isFirstPage
        isLastPage
        pageCount
        pageNumber
        pageSize
        sortBy
        sortDirection
      }
      items {
        ... Inspection
      }
  }
}
`