import { withStyles, Theme } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        paddingRight: theme.spacing(1)
    }
}))(MuiDialogActions);

export default DialogActions;