import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setRenderPoints } from "../../../store/actions/map";
import { IScheme, IRoute } from "../../../store/types";

const useRefreshMap = (context: IScheme | IRoute | undefined): void => {
    const dispatch = useDispatch();
    const nodeId = (context as IScheme)?.schemeNodeId ?? (context as IRoute)?.routeNodeId;
    const prevNodeId = useRef(nodeId);
    useEffect(() => {
        if (prevNodeId.current !== nodeId) {
            dispatch(setRenderPoints(true));
        }
        prevNodeId.current = nodeId;
    }, [nodeId]);
};

export default useRefreshMap;
