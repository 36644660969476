import React, { useState, useRef, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { IconButton, ClickAwayListener, Grow, MenuList, MenuItem, Paper, Popper, Divider } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { AccessControl } from "../../../../../auth/components";
import { RoleNames } from "../../../../../auth/roles";

const useStyles = makeStyles(theme => createStyles({
    root: {

    },
    button: {
        color: theme.palette.primary.contrastText
    },
    menu: {
        minWidth: theme.spacing(25),
        zIndex: 10
    }
}));

interface RoutesMenuProps {
    readonly showOnMap?: boolean;
    readonly onEditClick?: () => void;
    readonly onPrintClick?: () => void;
    readonly onToggleOnMap?: () => void;
}

const RoutesMenu = (props: RoutesMenuProps): JSX.Element => {
    const styles = useStyles();
    const { showOnMap, onEditClick, onPrintClick, onToggleOnMap } = props;
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement>(null);

    const closeMenu = (): void => setOpen(false);

    const handleToggle = (): void => {
        setOpen(previous => !previous);
    };

    const handleClose = (): void => {
        if (anchor.current?.contains(event?.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleEditClick = (): void => {
        closeMenu();
        onEditClick?.();
    };
    const handlePrintClick = (): void => {
        closeMenu();
        onPrintClick?.();
    };
    const handleToggleOnMap = (): void => {
        closeMenu();
        onToggleOnMap?.();
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchor.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div className={styles.root}>
            <IconButton ref={anchor} className={styles.button} aria-controls={open ? "routes-menu" : undefined} aria-haspopup="true" onClick={handleToggle}>
                <MenuIcon />
            </IconButton>
            <Popper className={styles.menu} open={open} anchorEl={anchor.current} role={undefined} transition disablePortal placement="bottom-start">
                {({ TransitionProps }): JSX.Element => (
                    <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="routes-menu" onKeyDown={handleListKeyDown}>
                                    <AccessControl role={RoleNames.ROUTES_ALL}>
                                        <MenuItem onClick={handleEditClick}>Edit Route</MenuItem>
                                    </AccessControl>
                                    <Divider />
                                    <MenuItem onClick={handleToggleOnMap}>
                                        {`${showOnMap ? "Hide" : "Show"} on Map`}
                                    </MenuItem>
                                    <MenuItem onClick={handlePrintClick}>Print</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default RoutesMenu;