import { IFeaturesState, IFeaturesAction } from "../types/features";

const defaultAction: IFeaturesAction = {
    type: "@@INIT"
};

const defaultState: IFeaturesState = {
    features: {
        dev: false,
        mobile: false
    }
};

export default (state: IFeaturesState = defaultState, action: IFeaturesAction = defaultAction): IFeaturesState => {
    switch (action.type) {
        case "SET_FEATURES":
            return {
                ...state,
                features: {
                    ...(action.features || defaultState.features)
                }
            };
        default:
            return state;
    }
}