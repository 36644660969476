import React from 'react';

import MuiDrawer from "@material-ui/core/Drawer";

import styles from './Drawer.module.scss';

interface DrawerProps {
    readonly anchor?: "left" | "right";
    readonly isOpen?: boolean;
}

export const Drawer = (props: React.PropsWithChildren<DrawerProps>): JSX.Element => {
    const { anchor, children, isOpen } = props;
    return (
        <MuiDrawer open={isOpen} variant={"persistent"} anchor={anchor} className={styles.drawer} classes={{ paper: styles.paper }}>
            {children}
        </MuiDrawer>
    );
};

export default Drawer;
