export default `
mutation assignInspectionGroup_Mutation($input:EditString!) {
    inspection {
        assignInspectionGroup(input:$input) {
        clientMutationId
        inspection {
            inspectionNodeId: id
            inspectionId
            waterSource {
                waterSourceNodeId: id
            }
            type {
                displayText
                value
            }
            inspectionDate
            description
            isOpen
            isCancelled
            isCompleted
            isAssignedToGroup
            isAssignedToInspector
            technician {
                technicianNodeId: id
                technicianId
                displayName
                userName
            }
            crew {
                crewNodeId: id
                crewId
                callSign
            }
            inspectionGroup {
                inspectionGroupNodeId: id
                inspectionGroupId
                name
            }
            result {
                displayText
                value
            }
            repairs {
                repairType {
                    displayText
                    value
                }
                repairCategory {
                    displayText
                    value
                }
            }
            cancelReason {
                displayText
                value
            }
            events {
                timestamp
                action
                actor
            }
            lastEvent {
                timestamp
                action
                actor
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
        }
        hasErrors
        errors {
            message
            path
        }
      }
    }
}`;