import React from "react";

import { AccessControl } from "../../../auth/components";
import AppBarMenuItem, { AppBarMenuItemProps } from "./AppBarMenuItem";
import { AccessControlProps, AccessControlRule } from "../../../auth/components/AccessControl";

const SecureAppBarMenuItem = (props: AccessControlProps & AccessControlRule & AppBarMenuItemProps): JSX.Element => {
    const { id, label, onClick, ...accessProps } = props;
    const itemProps: AppBarMenuItemProps = { id, label, onClick };
    return (
        <AccessControl {...accessProps}>
            <AppBarMenuItem {...itemProps} />
        </AccessControl>
    );
};

export type { AccessControlProps, AccessControlRule, AppBarMenuItemProps };
export default SecureAppBarMenuItem;