import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { guid } from "../../../lib/Utils";
import { startCreateScheme } from "../../../store/actions/schemes";
import { IAddSchemeVariables } from "../../../store/types";
import { useScheme } from "../../appWindowContext";
import CreateSchemeDialogBase, { ICreateSchemeDialogProps } from "./createSchemeDialog";
import { parseNodeId } from "../../../lib/nodeIdentifier";

const CreateSchemeDialog = (props: Pick<ICreateSchemeDialogProps, "isOpen" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showScheme } = useScheme();
    const stations = useSelector(state => state.app.lookups?.stations ?? []);
    const stages = useSelector(state => state.app.lookups?.schemeStages ?? []);
    const types = useSelector(state => state.app.lookups?.schemeTypes ?? []);
    const combined: ICreateSchemeDialogProps = {
        ...props,
        stations,
        stages,
        types,
        onCreateScheme: async (data) => {
            const variables: IAddSchemeVariables = {
                input: {
                    clientMutationId: guid(),
                    data
                }
            };
            const action = startCreateScheme(variables);
            const scheme = await action(dispatch);
            if (scheme) {
                showScheme(parseNodeId(scheme.schemeNodeId));
            }
        }
    };
    return <CreateSchemeDialogBase {...combined} />;
};

export default CreateSchemeDialog;