import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import useDefects from "../../../../components/appWindowContext/hooks/useDefects";
import useInspections from "../../../../components/appWindowContext/hooks/useInspections";
import useSelector from "../../../../lib/hooks/useSelector";
import { encodeNodeId } from "../../../../lib/nodeIdentifier";
import { guid } from "../../../../lib/Utils";
import { startEditRepair } from "../../../../store/actions/repairs";
import { IRepairInput } from "../../../../store/types";
import RepairControlBase, { IRepairControlProps } from "./RepairControl";

const RepairControl = (props: Pick<IRepairControlProps, "selectedRepair" | "hideNavButton" | "onClose"> & { readonly onRefresh?: () => void; }): JSX.Element => {
    const dispatch = useDispatch();
    const { selectedRepair, ...otherProps } = props;
    const repairTypes = useSelector(state => state.app.lookups?.repairTypes ?? []);
    const repairVendors = useSelector(state => state.app.lookups?.repairVendors ?? []);
    const fileStoreToken = useSelector(state => state.fileStore.token);
    const [defects] = useDefects(selectedRepair?.waterSource?.waterSourceNodeId);
    const openDefects = useMemo(() => defects.filter(defect => defect.isOpen), [defects]);
    const [inspections] = useInspections(selectedRepair?.waterSource?.waterSourceNodeId);
    const openInspections = useMemo(() => inspections.filter(inspection => inspection.isOpen), [inspections]);

    const handleRefresh = (): void => {
        props.onRefresh?.();
    }

    const combined: IRepairControlProps = {
        ...otherProps,
        selectedRepair,
        repairTypes,
        repairVendors,
        fileStoreToken,
        openDefects,
        openInspections,
        onUpdateRepair: async (repairId, data): Promise<void> => {
            const input: IRepairInput = {
                repair: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(repairId),
                    data
                }
            };
            const action = startEditRepair(input);
            await action(dispatch);
            handleRefresh();
        },
    };

    return <RepairControlBase {...combined} />;
};

export default RepairControl;