import { useCallback, useState } from "react";

import DesktopSearchInput from "./DesktopSearchInput";
import MobileSearchInput from "./MobileSearchInput";
import { SearchInputProps } from "./types";

interface RenderAddressSearchInput {
    (props: SearchInputProps): JSX.Element;
}

interface AddressSearchInputProps {
    readonly initSearch?: string;
    readonly enabled?: boolean;
    readonly renderInput: RenderAddressSearchInput;
    readonly onSearch?: (searchText: string) => void;
    readonly onClear?: () => void;
}

const placeholder = "Building name/number, street, locality, town, post code";

const AddressSearchInput = (props: AddressSearchInputProps): JSX.Element => {
    const { enabled, renderInput, onClear, onSearch } = props;
    const [searchText, setSearchText] = useState("");

    const search = useCallback(() => {
        if (searchText.trim()) {
            onSearch?.(searchText.trim());
        }
    }, [searchText, onSearch]);

    const handleTextChange = (value: string): void => {
        setSearchText(value);
    };
    const handleClearClick = (): void => {
        setSearchText("");
        onClear?.();
    };
    const handleSearchClick = (): void => {
        search();
    };

    const inputProps: SearchInputProps = {
        searchText,
        placeholder,
        enabled,
        onClear: handleClearClick,
        onSearch: handleSearchClick,
        onTextChange: handleTextChange
    };

    return renderInput(inputProps);
};

export type { AddressSearchInputProps, RenderAddressSearchInput, SearchInputProps };
export { DesktopSearchInput, MobileSearchInput };
export default AddressSearchInput;