import React, { useState, useEffect, useReducer, useRef } from "react";
import { LoadingBar } from "@3tc/shared-components";
import SwipeableViews from "react-swipeable-views";
import { LocalDate } from "@js-joda/core";

import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import AttachmentIcon from "@material-ui/icons/Attachment";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import FactCheckIcon from "../../components/icons/FactCheck";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveIcon from "@material-ui/icons/Save";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from '@material-ui/icons/Error';

import { AccessControl } from "../../auth/components";
import { RoleNames } from "../../auth/roles";
import { Field } from "../../components/card";
import ActionMenu from "../../components/inspectionView/components/actionMenu";
import AddDefect from "../../components/inspectionView/components/addDefect";
import CompleteInspection from "../../components/inspectionView/components/completeInspection";
import ShowLocation from "../../components/inspectionView/components/showMap";
import WaterSourceDetails, { DetailsKeys, DetailsFields } from "../../components/inspectionView/components/waterSourceDetails";
import HazardsDetails from "../../components/inspectionView/components/hazardsDetails";
import WaterSourceLocation from "../../components/inspectionView/components/waterSourceLocation";
import { InspectionOutcome, IDefectSummary } from "../../components/inspectionView/types";
import { formatStatus, formatOutcome, formatInspector } from "../../components/inspectionView/utils";
import { IFileList, FileUploader } from "../../components/shared/fileManager";
import Optional from "../../components/shared/Optional";
import View, { NavBackButton } from "../../components/shared/view";
import TabPanel from "../../components/tabs/TabPanel";
import Hydrant from "../../components/waterSource/waterSourceCategory/hydrant";
import { parseNodeId, NodeID } from "../../lib/nodeIdentifier";
import { longDateFormatter } from "../../lib/Utils";
import { IInspection, ILookupItem, IAddress, IPoint } from "../../store/types";
import AttachmentList from "./components/AttachmentList";
import DefectList from "./components/DefectList";
import InspectionTitle from "./components/InspectionTitle";
import useStyles from "./InspectionView.styles";
import ViewNotes from "../../components/inspectionView/components/viewNotes";
import StreetView from "../../components/inspectionView/components/streetView";
import NotesDetails from "./components/NotesDetails";
import { LocationChangeKeys, LocationChangeValues } from "../../components/inspectionView/components/waterSourceLocation/WaterSourceLocation.view";
import useAddressUpdate from "./hooks/useAddressUpdate";

interface EditState {
    readonly dirty: boolean;
    readonly address: IAddress | undefined;
    readonly coordinates: IPoint | undefined;
    readonly description: string | undefined;
    readonly hydrantLocation: ILookupItem | undefined;
    readonly waterSource: DetailsFields;
}

interface IInspectionViewProps {
    readonly inspection: {
        readonly item: IInspection | undefined;
        readonly loading: boolean;
    };
    readonly defects: {
        readonly items: IDefectSummary[];
        readonly loading: boolean;
    };
    readonly attachments: {
        readonly files: IFileList;
        readonly loading: boolean;
    };
    readonly actionsEnabled?: boolean;
    readonly onAddDefect?: (defectType: ILookupItem, dateReported: LocalDate, reportedBy: string, note: string) => void;
    readonly onCloseDefect?: (defectId: NodeID, closeReason: ILookupItem) => void;
    readonly onCompleteInspection: (
        outcome: InspectionOutcome,
        note: string,
        unresolvedDefects: IDefectSummary[],
        selectedTest: ILookupItem,
        selectedRepairs: ILookupItem[],
        attachments: IFileList,
        wallPlateFitted?: boolean,
        serviceRecordsInDate?: boolean) => Promise<void>;
    readonly onRefresh?: () => void;
    readonly onSaveLocation?: (nodeId: NodeID, update: EditState, category: ILookupItem) => void;
    readonly onSaveWaterSource?: (nodeId: NodeID, update: EditState, category: ILookupItem) => void;
    readonly onUploadComplete?: () => void;
    readonly onSaveHazards?: (nodeId: NodeID, update: EditState) => void;
    readonly tabIndex?: number;
}

type EditActionType =
    "SET_ADDRESS" |
    "SET_LOCATION_DESCRIPTION" |
    "SET_HYDRANT_LOCATION" |
    "SET_WATER_SOURCE";

type EditAction = {
    readonly type: EditActionType;
    readonly value: EditState[keyof EditState];
} | {
    readonly type: "RESET";
    readonly value: IInspection | undefined;
};

const defaultWaterSource = { controlMeasures: [] };

const initState = (inspection: IInspection | undefined, address?: IAddress): EditState => {
    const initAddress = (): IAddress | undefined => {
        const value = address ?? inspection?.waterSource?.location?.address;
        return value
            ? { ...value }
            : undefined;
    };
    return {
        dirty: false,
        address: initAddress(),
        coordinates: inspection?.waterSource?.location?.coordinates,
        description: inspection?.waterSource?.location?.description,
        hydrantLocation: inspection?.waterSource.hydrantLocation,
        waterSource: {
            ...inspection?.waterSource ?? defaultWaterSource,
            hazardsValues: inspection?.waterSource?.hazards?.map(hazard => hazard.hazardType.value)
        }
    };
};

const reducer = (state: EditState, action: EditAction): EditState => {
    switch (action.type) {
        case "SET_ADDRESS":
            return {
                ...state,
                dirty: true,
                address: action.value as IAddress
            };
        case "SET_HYDRANT_LOCATION":
            return {
                ...state,
                dirty: true,
                hydrantLocation:
                    action.value as ILookupItem
            };
        case "SET_LOCATION_DESCRIPTION":
            return {
                ...state,
                dirty: true,
                description: action.value as string
            };
        case "SET_WATER_SOURCE":
            return {
                ...state,
                dirty: true,
                waterSource: {
                    ...state.waterSource,
                    ...(action.value as DetailsFields)
                }
            };
        case "RESET": 
            return initState(action.value, state.address);
        default:
            return state;
    }
};

enum TabIndex {
    LOCATION = 0,
    WATER_SOURCE = 1,
    HAZARDS = 2,
    INSPECTION = 3,
    DEFECTS = 4,
    ATTACHMENTS = 5
}

const InspectionView = (props: IInspectionViewProps): JSX.Element => {
    const { inspection, defects, attachments, actionsEnabled, tabIndex } = props;
    const { onCompleteInspection, onRefresh, onSaveLocation, onSaveWaterSource, onAddDefect, onCloseDefect, onUploadComplete, onSaveHazards } = props;

    const styles = useStyles();
    const [tab, setTab] = useState(tabIndex ?? TabIndex.LOCATION);
    const [editing, setEditing] = useState(false);
    const [editState, editDispatch] = useReducer(reducer, inspection.item, initState);

    const [showNotesDetails, setShowNotesDetails] = useState<boolean>(false);

    const enableEdit = [TabIndex.LOCATION, TabIndex.WATER_SOURCE, TabIndex.HAZARDS].includes(tab);
    const disableSave = !editState.dirty;

    const resetInspection = (inspection: IInspection | undefined): void => {
        editDispatch({ type: "RESET", value: inspection });
        editDispatch({ type: "SET_ADDRESS", value: inspection?.waterSource.location.address });
    };

    useEffect(() => {
        if (!inspection.loading) {
            resetInspection(inspection.item);
        }
    }, [inspection.loading, inspection.item?.inspectionId]);

    useAddressUpdate(inspection.item?.waterSource.location.address, editState, a => editDispatch({ type: "SET_ADDRESS", value: a }));

    if (inspection.item === undefined && inspection.loading) {
        return <View><LoadingBar loading /></View>;
    }
    
    if (inspection.item === undefined) {
        return <View />;
    }

    const { inspectionId, waterSource } = inspection.item;
    const attachmentCount = Object.keys(attachments.files).length;
    const defectCount = defects.items.length;
    const outcome = defectCount > 0
        ? InspectionOutcome.Fail
        : InspectionOutcome.Pass;

    const handleChange = (_: React.ChangeEvent<unknown>, value: any): void => {
        setTab(value);
    };

    const handleSwipeChange = (index: number): void => {
        setTab(index);
    };

    const handleEditClick = (): void => {
        setEditing(true);
    };

    const handleEditCancel = (): void => {
        setEditing(false);
        resetInspection(inspection.item);
    };

    const handleEditSave = (): void => {
        setEditing(false);
        const { waterSourceNodeId, category } = waterSource;
        switch (tab) {
            case TabIndex.LOCATION:
                onSaveLocation?.(parseNodeId(waterSourceNodeId), editState, category);
                return;
            case TabIndex.WATER_SOURCE:
                onSaveWaterSource?.(parseNodeId(waterSourceNodeId), editState, category);
                return;
            case TabIndex.HAZARDS:
                onSaveHazards?.(parseNodeId(waterSourceNodeId), editState);
                return;
            default:
                return;
        }
    };

    const handleAddressUpdated = (): void => {
        onRefresh?.();
    };

    const handleLocationChange = (key: LocationChangeKeys, value: LocationChangeValues): void => {
        switch (key) {
            case "address":
                editDispatch({ type: "SET_ADDRESS", value });
                break;
            case "description":
                editDispatch({ type: "SET_LOCATION_DESCRIPTION", value });
                break;
            case "hydrantLocation":
                editDispatch({ type: "SET_HYDRANT_LOCATION", value });
                return;
        }
    };

    const handleWaterSourceChange = (key: DetailsKeys, value: DetailsFields[DetailsKeys]): void => {
        editDispatch({ type: "SET_WATER_SOURCE", value: { [key]: value } });
    };

    const handleCompleteInspection = async (selectedTest: ILookupItem, note: string, selectedRepairs: ILookupItem[], wallPlateFitted?: boolean, serviceRecordsInDate?: boolean): Promise<void> => {
        await onCompleteInspection(
            outcome,
            note,
            defects.items,
            selectedTest,
            selectedRepairs,
            attachments.files,
            wallPlateFitted,
            serviceRecordsInDate
        );
    };

    const handleMapRefresh = (): void => {
        onRefresh?.();
    };

    const handleViewRefresh = (): void => {
        onRefresh?.();
    };

    const openNotesDetails = (): void => setShowNotesDetails(true);
    const closeNotesDetails = (): void => setShowNotesDetails(false);

    const disableTab = (index: number): boolean => {
        return editing
            ? tab !== index
            : false;
    };

    const actions = editing
        ? <IconButton key={0} className={styles.appBarButton} edge="end" disabled={disableSave} onClick={handleEditSave}>
                <SaveIcon />
        </IconButton>
        : [
            <AccessControl key={0} any={[RoleNames.WS_EDIT_DETAILS, RoleNames.WS_EDIT_HAZARDS, RoleNames.WS_EDIT_LOCATION]}>
                <Optional hidden={!enableEdit}>
                    <IconButton className={styles.appBarButton} onClick={handleEditClick}>
                        <EditIcon />
                    </IconButton>
                </Optional>
            </AccessControl>,
            <ActionMenu key={1}>
                <AccessControl role={RoleNames.DEFECTS_CREATE}>
                    <AddDefect waterSourceId={waterSource.waterSourceId} disabled={!actionsEnabled} onCommit={onAddDefect} />
                </AccessControl>
                <AccessControl any={[RoleNames.FILES_UPLOAD, RoleNames.SECURE_FILES_UPLOAD]}>
                    <FileUploader entity="INSPECTION" entityId={inspectionId ?? Number.NaN} disabled={!actionsEnabled} onUploadComplete={onUploadComplete} />
                </AccessControl>
                <AccessControl any={[RoleNames.DEFECTS_CREATE, RoleNames.FILES_UPLOAD, RoleNames.SECURE_FILES_UPLOAD]}>
                    <Optional hidden={!actionsEnabled}>
                        <Divider />
                    </Optional>
                </AccessControl>
                <ShowLocation focus={waterSource} onRefresh={handleMapRefresh} />
                <StreetView waterSourceCoordinates={waterSource.location.coordinates} />
                <Divider />
                <ViewNotes openNotesDetails={openNotesDetails} />
            </ActionMenu>
        ];

    const navButton = editing
        ? <IconButton className={styles.appBarButton} edge="start" onClick={handleEditCancel}>
            <CloseIcon />
        </IconButton>
        : <NavBackButton />;

    return (
        <View title={<InspectionTitle inspection={inspection.item} disabled={editing} />} extendedAppBar primary={navButton} secondary={actions} onRefreshClick={handleViewRefresh}>
            <div className={styles.root}>
                <div className={styles.tabBar}>
                    <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange}>
                        <Tab id={`tab-${TabIndex.LOCATION}`} disabled={disableTab(TabIndex.LOCATION)} label={<Typography variant="caption">Location</Typography>} icon={<LocationOnIcon />} />
                        <Tab id={`tab-${TabIndex.WATER_SOURCE}`} disabled={disableTab(TabIndex.WATER_SOURCE)} label={<Typography variant="caption">Water Source</Typography>} icon={<Hydrant className={styles.waterSourceIcon} />} />
                        <Tab id={`tab-${TabIndex.HAZARDS}`} disabled={disableTab(TabIndex.HAZARDS)} label={<Typography variant="caption">Hazards</Typography>} icon={<WarningIcon />} />
                        <Tab id={`tab-${TabIndex.INSPECTION}`} disabled={disableTab(TabIndex.INSPECTION)} label={<Typography variant="caption">Inspection</Typography>} icon={<FactCheckIcon />} />
                        <Tab id={`tab-${TabIndex.DEFECTS}`} disabled={disableTab(TabIndex.DEFECTS)} label={<Typography variant="caption">Defects</Typography>} icon={<Badge badgeContent={defectCount} color="secondary" max={9} showZero><ErrorIcon /></Badge>} />
                        <Tab id={`tab-${TabIndex.ATTACHMENTS}`} disabled={disableTab(TabIndex.ATTACHMENTS)} label={<Typography variant="caption">Attachments</Typography>} icon={<Badge badgeContent={attachmentCount} color="secondary" max={9} showZero><AttachmentIcon /></Badge>} />
                    </Tabs>
                </div>
                <div className={styles.tabContainer}>
                    <SwipeableViews disabled={editing} index={tab} onChangeIndex={handleSwipeChange} style={{ height: "100%", overflow: "hidden" }} containerStyle={{ height: "100%" }} slideStyle={{ height: "100%", overflow: "hidden" }}>
                        <TabPanel value={tab} index={TabIndex.LOCATION} noMargins>
                            <WaterSourceLocation 
                                category={waterSource.category} 
                                coordinates={editState.coordinates} 
                                address={editState.address} 
                                description={editState.description}
                                hydrantLocation={editState.hydrantLocation}
                                editing={editing} 
                                onAddressUpdated={handleAddressUpdated}
                                onChange={handleLocationChange} 
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.WATER_SOURCE} noMargins>
                            <WaterSourceDetails category={waterSource.category} {...editState.waterSource} editing={editing} onChange={handleWaterSourceChange} />
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.HAZARDS} noMargins>
                            <HazardsDetails {...editState.waterSource} editing={editing} onChange={handleWaterSourceChange} />
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.INSPECTION} noMargins>
                            <Box display="flex" flexDirection="column" height="100%" paddingBottom={3} overflow="auto">
                                <Box p={2}>
                                    <Field label="Type" text={inspection.item?.type.displayText ?? "-"} />
                                    <Field label="Due date" text={inspection.item?.inspectionDate?.format(longDateFormatter) ?? "-"} />
                                    <Field label="Status" text={formatStatus(inspection.item.isOpen, inspection.item.isCompleted, inspection.item.isCancelled)} />
                                    <Field label="Test" text={inspection.item?.test?.displayText ?? "-"} />
                                    <Field label="Outcome" text={formatOutcome(outcome)} />
                                    <Field label="Inspector" text={formatInspector(inspection.item?.technician) ?? "-"} />
                                    <Field label="Inspection group" text={inspection.item.inspectionGroup?.name ?? "-"} />
                                    <Field label="Description" text={(inspection.item.description?.trim() !== "") ? inspection.item.description : "-"} />
                                </Box>
                                <Optional hidden={!actionsEnabled}>
                                    <Box px={2} mt="auto" zIndex={1}>
                                        <AccessControl role={RoleNames.INSPECTIONS_COMPLETE}>
                                            <CompleteInspection
                                                inspection={inspection.item}
                                                waterSourceCategory={waterSource.category}
                                                outcome={outcome}
                                                onCommit={handleCompleteInspection}
                                            />
                                        </AccessControl>
                                    </Box>
                                </Optional>
                            </Box>
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.DEFECTS} noMargins>
                            <DefectList
                                defects={defects.items}
                                loading={defects.loading}
                                enableActions={actionsEnabled}
                                onCloseDefect={onCloseDefect}
                            />
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.ATTACHMENTS} noMargins>
                            <AttachmentList
                                attachments={attachments.files}
                                loading={attachments.loading}
                            />
                        </TabPanel>
                    </SwipeableViews>
                </div>
                {inspection.loading && <LoadingBar loading />}
            </div>
            <NotesDetails
                withAddNote
                objectType="WATER_SOURCE"
                showDialogue={showNotesDetails}
                closeNotesDetails={closeNotesDetails}
                notes={waterSource.notes ?? []}
                objectNodeId={waterSource.waterSourceNodeId}
                onRefresh={onRefresh}
            />
        </View>
    );
};

export type { IInspectionViewProps, EditState }
export default InspectionView;