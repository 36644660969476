import checkRoles, { RoleChecks } from "../checkRoles";
import useAccessRoles from "./useAccessRoles";

const useRoleChecks = (): RoleChecks => {
    const roles = useAccessRoles();
    const checks = checkRoles(roles);
    return checks;
};

export type { RoleChecks };
export default useRoleChecks;