import { useEffect, useState } from "react";
import { IBasicRouteDetails, LookupItem } from "../../store/types";
import getRoutes from "../getRoutes";
import { FetchStatus } from "./types";
import { NodeID, encodeNodeId } from "../../lib/nodeIdentifier";

const nullRoute: IBasicRouteDetails = {
    routeNodeId: encodeNodeId(NodeID("Route", Number.NaN)),
    routeId: Number.NaN,
    name: "(No Route)",
    station: LookupItem(0, "Unknown", 9999999)
};

const useRoutes = (stationNodeId: NodeID | undefined): [FetchStatus, IBasicRouteDetails[]] => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [routes, setRoutes] = useState<IBasicRouteDetails[]>([]);
    const { id } = stationNodeId ?? {};
    useEffect(() => {
        setStatus("loading");
        getRoutes(id)
            .then(values => setRoutes([...values, nullRoute]))
            .catch(() => setRoutes([nullRoute]))
            .finally(() => setStatus("complete"));
    }, [id]);

    return [status, routes];
};

export type { FetchStatus };
export default useRoutes;