import React, { useMemo } from "react";
import cx from "classnames";

import Abandoned from "./abandoned";
import CategoryIcon, { CategoryIconProps } from "./categoryIcon";
import useStyles from "./hydrant.styles";

type HydrantIconType = "standard" | "private" | "washout";
type HydrantIconStatus = "inactive" | "proposed" | "awaitingAdoption" | "operableDefective" | "defective" | "operable";

interface HydrantIconProps extends CategoryIconProps {
    readonly type?: HydrantIconType;
    readonly status?: HydrantIconStatus;
    readonly abandoned?: boolean;
}

const renderText = (type: HydrantIconType | undefined, statusClass: string, styles: Record<"text", string>): JSX.Element => {
    switch (type) {
        case "private":
            return <text className={cx(styles.text, type, statusClass)} x="5" y="19">P</text>;
        case "washout":
            return <text className={cx(styles.text, type, statusClass)} x="4" y="18">W</text>;
        default:
            return <text className={cx(styles.text, statusClass)} x="5" y="19">H</text>;
    }
};

const Hydrant = (props: HydrantIconProps): JSX.Element => {
    const styles = useStyles();
    const { status, type, className, height, width } = props;
    const statusClass = status ?? "operable";
    const text = useMemo(() => renderText(type, statusClass, styles), [type, statusClass, styles])
    return (
        <CategoryIcon title="HYDRANT" className={className} width={width} height={height}>
            <rect className={cx(styles.hydrant, type, statusClass)} x="0" y="0" width={width ?? 24} height={height ?? 24} />
            {text}
            <Abandoned show={props.abandoned} width={width} height={height} />
        </CategoryIcon>
    );
};

export type { HydrantIconProps, HydrantIconType, HydrantIconStatus };
export default React.memo(Hydrant);