import React from 'react'

import { IPageInfo, SortDirection } from '../../../store/types/connections';
import { ITableHeader } from '../types';
import NoResults from './components/noResults';
import Results from './components/results';

export interface IFilterResultsTableProps {
    readonly headers: ITableHeader[];
    readonly items: string[][];
    readonly pageInfo: IPageInfo;
    readonly totalCount: number;
    readonly lastFieldAsObjectNodeId?: boolean;
    readonly onFirstClick: () => void;
    readonly onPreviousClick: () => void;
    readonly onNextClick: () => void;
    readonly onLastClick: () => void;
    readonly onTableRowClick: (id: string) => void;
    readonly onTableHeaderClick: (sortBy: string, sortDirection: SortDirection) => void;
}

const FilterResultsTable = (props: IFilterResultsTableProps): JSX.Element => {
    const { items, pageInfo } = props;
    return (
        <div>
            {items.length > 0 && pageInfo
                ? <Results {...props} />
                : <NoResults message="There were no results matching this search." />
            }
        </div>
    );
};

export default FilterResultsTable;
