import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '../store';
import { jsJodaDeserializer } from './json';

interface GraphResponse<T> {
    readonly data?: T;
}

class TokenTimeout {
    private _timeout: NodeJS.Timeout | undefined;

    public constructor(private readonly _callback: (...args: any[]) => void) {
        this._timeout = undefined;
    }

    public clear(): void {
        if (this._timeout) {
            console.log("TokenTimeout.clear", this._timeout);
            clearTimeout(this._timeout);
            this._timeout = undefined;
        }
    }

    public renew(timeout: number): void {
        if (timeout) {
            this._timeout = setTimeout(this._callback, timeout);
            console.log("TokenTimeout.renew", this._timeout, timeout);
        }
    }
}

const tokenTimeout = new TokenTimeout(() => {
    window.location.href = "/";
});

const extendTokenLease = <R>(response: AxiosResponse<R>): AxiosResponse<R> => {
    const { tokenExpiry } = store.getState().app;

    tokenTimeout.clear();
    tokenTimeout.renew(tokenExpiry);

    return response;
};

const handleError = (reason: any, query: string, variables: unknown, url: string): undefined => {
    console.error("Error executing graphql query", reason, "\r\n\r\n", query, "\r\n\r\n", variables, "\r\n\r\n", url);
    return undefined;
}

const requestConfig: AxiosRequestConfig = {
    transformResponse: (data: any): any => {
        const result = JSON.parse(data, jsJodaDeserializer) as unknown;
        return result;
    }
};

const executeQuery = async <R extends any>(query: string, variables: unknown, url = "/api/graphql"): Promise<R | undefined> => {
    const input = { query, variables };
    return axios.post<GraphResponse<R>>(url, input, requestConfig)
        .then(response => extendTokenLease(response))
        .then(response => response.data.data)
        .catch(reason => handleError(reason, query, variables, url));
};

export default executeQuery;
