import React from "react";

import MenuItem from "@material-ui/core/MenuItem";

import { Lookup } from "../../../lib/lookups";
import { ILookupItem } from "../../../store/types";

interface MultiSelectOptionsProps {
    readonly items: Lookup;
    readonly isMobile: boolean | undefined;
}

const MutliSelectOptions = ({ items, isMobile }: MultiSelectOptionsProps): JSX.Element => {
    const renderOption = (item: ILookupItem): JSX.Element => {
        const { value, displayText } = item;
        return isMobile
            ? <option key={value} value={value}>{displayText}</option>
            : <MenuItem key={value} value={value}>{displayText}</MenuItem>;
    };
    return (
        <React.Fragment>
            {items.map(renderOption)}
        </React.Fragment>
    );
};

export type { MultiSelectOptionsProps };
export default MutliSelectOptions;