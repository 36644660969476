import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { FilterOption } from "../../types";
import FilterContainer from "./FilterContainer";
import FilterGroupItem from "./FilterGroupItem";
import FilterLabel from "./FilterLabel";
import FilterOptionItem from "./FilterOptionItem";

interface ListFilterProps {
    readonly id: string;
    readonly label: string;
    readonly options: FilterOption[];
    readonly selected: (string | undefined)[] | undefined;
    readonly onChange?: (value: string) => void;
}

const useStyles = makeStyles(theme => createStyles({
    input: {
        display: "block",
        background: "none",
        backgroundColor: theme.palette.primary.main,
        border: "3px dashed transparent",
        color: "inherit",
        cursor: "pointer",
        overflowX: "hidden",
        overflowY: "auto",
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: 0,
        maxWidth: "200px",
        minWidth: "240px",
        transition: "background-color 300ms ease-in-out, padding 300ms ease-in-out",
        width: "100%",
        "&:focus": {
            outline: "none",
            borderColor: theme.palette.secondary.main
        }
    },
    item: {
        textTransform: "capitalize"
    }
}));

const maxLength = (options: FilterOption[]): number => {
    const result = options.reduce((length: number, current: FilterOption): number => {
        return Math.max(length, current.children?.length ?? 0);
    }, 0);
    return result > 0 ? result : options.length;
};

const renderItem = (option: FilterOption): JSX.Element => {
    const { text, value, children } = option;
    return children
        ? <FilterGroupItem key={`${value}`} label={text} options={children} />
        : <FilterOptionItem key={`${value}`} option={option} />;
};

const ListFilter = (props: ListFilterProps): JSX.Element => {
    const { id, label, options, selected, onChange } = props;
    const styles = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLSelectElement, MouseEvent>): void => {
        const target = event.target as HTMLOptionElement;
        if (target) {
            onChange?.(target.value);
        }
    };

    const value = selected?.reduce((accumulator: string[], current: string | undefined): string[] => {
        if (current || current === '') {
            accumulator.push(current);
        }
        return accumulator;
    }, []);

    return (
        <FilterContainer>
            <FilterLabel htmlFor={id}>{label}</FilterLabel>
            <select
                id={id}
                value={value}
                size={maxLength(options)}
                className={styles.input}
                onClick={handleClick}
                multiple
            >
                {options.map(renderItem)}
            </select>
        </FilterContainer>
    );
};

export type { ListFilterProps };
export default ListFilter;