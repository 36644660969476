import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import RoutesListPage from "../../../views/routesListView";
import StationListPage from "../../../views/stationListView";

const StationsRouter = (): JSX.Element => {
    const { path } = useRouteMatch() ?? {};
    return (
        <Switch>
            <Route path={`${path}/:stationId`}>
                <RoutesListPage />
            </Route>
            <Route path={`${path}`} exact>
                <StationListPage />
            </Route>
        </Switch>
    );
};

export default StationsRouter;