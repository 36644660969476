import React from "react";
import { LoadingBar } from "@3tc/shared-components";

import { IFileList } from "../../../components/shared/fileManager";
import AttachmentItem from "./AttachmentItem";
import { toAttachment } from "./Attachment.types";
import TabList from "./TabList";

interface AttachmentListProps {
    readonly attachments: IFileList;
    readonly loading?: boolean;
}

const AttachmentList = ({ attachments, loading }: AttachmentListProps): JSX.Element => {
    const items = Object
        .entries(attachments)
        .map(([key, file]) =>
            <AttachmentItem key={key} attachment={toAttachment(file)} />
        );
    return (
        <TabList disablePadding>
            {loading && <LoadingBar loading />}
            {items}
        </TabList>
    );
};

export type { AttachmentListProps };
export default AttachmentList;