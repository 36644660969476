import { NodeID, parseNodeId } from "../../lib/nodeIdentifier";
import { ILookupItem } from "./app";
import { IConnection } from "./connections";
import { IDefect } from "./defects";
import { Inspector, ITechnician, ICrew, IInspection } from "./inspections";
import { Repair, ExternalRepair, InternalRepair } from "./repairs";
import { IRoute } from "./routes";
import { IScheme } from "./schemes";
import { IWaterSource, EmergencyWaterSupply, Hydrant, Riser, UnknownWaterSource } from "./waterSources";

export const isConnection = <TNode>(o: TNode[] | IConnection<TNode> | undefined): o is IConnection<TNode> =>
    (o as IConnection<TNode>).pageInfo !== undefined;

export const isCrew = (inspector: Inspector): inspector is ICrew =>
    (inspector as ICrew).crewNodeId !== undefined;

export const isLookupItem = (o: unknown): o is ILookupItem =>
    (o as ILookupItem)?.value !== undefined && (o as ILookupItem)?.displayText !== undefined;

export const isTechnician = (inspector: Inspector): inspector is ITechnician =>
    (inspector as ITechnician).technicianNodeId !== undefined;


export const getInspectorDisplayName = (inspector: Inspector | undefined): string => {
    if (inspector === undefined) {
        throw new Error("`inspector` must be defined.");
    }
    if (isTechnician(inspector)) {
        return inspector.displayName;
    }
    if (isCrew(inspector)) {
        return inspector.callSign;
    }
    throw new Error("`inspector` must be an instance of `ITechnician` or `ICrew`.");
};

export const getInspectorNodeId = (inspector: Inspector | undefined): NodeID => {
    if (inspector === undefined) {
        throw new Error("`inspector` must be defined.");
    }
    if (isTechnician(inspector)) {
        return parseNodeId(inspector.technicianNodeId);
    }
    if (isCrew(inspector)) {
        return parseNodeId(inspector.crewNodeId);
    }
    throw new Error("`inspector` must be an instance of `ITechnician` or `ICrew`.");
};

export const isWaterSource = (obj: unknown): obj is IWaterSource => {
    return (obj as IWaterSource)?.waterSourceNodeId !== undefined;
};

export const isEmergencyWaterSupply = (obj: unknown): obj is EmergencyWaterSupply => {
    return (isWaterSource(obj) && obj.category.value === 2);
};

export const isHydrant = (obj: unknown): obj is Hydrant => {
    return (isWaterSource(obj) && obj.category.value === 1);
};

export const isRiser = (obj: unknown): obj is Riser => {
    return (isWaterSource(obj) && obj.category.value === 3);
};

export const isUnknownWaterSource = (obj: unknown): obj is UnknownWaterSource => {
    return (isWaterSource(obj) && obj.category.value === 0);
};

export const isInspection = (obj: unknown): obj is IInspection => {
    return (obj as IInspection)?.inspectionNodeId !== undefined;
};

export const isDefect = (obj: unknown): obj is IDefect => {
    return (obj as IDefect)?.defectNodeId !== undefined;
};

export const isRepair = (obj: unknown): obj is Repair => {
    return (obj as Repair)?.repairNodeId !== undefined;
};

export const isExternalRepair = (obj: unknown): obj is ExternalRepair => {
    return (isRepair(obj) && obj.repairCategory.value === 1);
};

export const isInternalRepair = (obj: unknown): obj is InternalRepair => {
    return (isRepair(obj) && obj.repairCategory.value === 2);
};

export const isScheme = (obj: unknown): obj is IScheme => {
    return (obj as IScheme)?.schemeNodeId !== undefined;
};

export const isRoute = (obj: unknown): obj is IRoute => {
    return (obj as IRoute)?.routeNodeId !== undefined;
};