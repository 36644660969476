import React, { PropsWithChildren, useEffect } from "react";
import moment from "moment";

import "moment/locale/en-gb";

const LocaleProvider = <P extends unknown>(props: PropsWithChildren<P>): JSX.Element => {
    useEffect(() => {
        moment.locale("en-gb");
        console.log("locale:", moment.locale());
    }, []);

    return <React.Fragment>{props.children}</React.Fragment>
};

export default LocaleProvider;