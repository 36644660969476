import React from "react";

import FileUploader, { FileUploaderProps } from "./fileUploader.view";
import useSelector from "../../../../../lib/hooks/useSelector";

type ConnectedProps = Omit<FileUploaderProps, "accessRoles" | "fileStoreToken">;

const ConnectedFileUploader = (props: ConnectedProps): JSX.Element => {
    const connectedProps: FileUploaderProps = {
        ...props,
        accessRoles: useSelector(state => state.app.user?.account?.access ?? []),
        fileStoreToken: useSelector(state => state.fileStore.token)
    };
    return <FileUploader {...connectedProps} />;
};

export default ConnectedFileUploader;
