import { connect } from 'react-redux';

import { IReduxState } from '../../store/types';
import NearMeView, { NearMeViewProps } from './NearMeView.view';

type ConnectedProps = Pick<NearMeViewProps, "fence">;

const mapState = (state: IReduxState): ConnectedProps => {
    const defaultRadius = state.map.mapSettings?.search?.defaultRadius;
    const props: ConnectedProps = {
        fence: Number(defaultRadius)
    };
    return props;
};

export default connect(mapState)(NearMeView);