import executeQuery from "../../../../lib/executeQuery";
import { IConnection, IConnectionQuery, Repair, emptyPage } from "../../../../store/types";
import { RepairQueryResponse } from "../../../filters/repairSearch/query";

const gql = `
query findRepairs($filter: QueryFilter, $paging: QueryControlInput) {
    repairs(queryFilter: $filter, queryControl: $paging) {
        items {
            repairType {
                displayText
                value
            }
        }
    }
}`;

type RepairType = Pick<Repair, "repairNodeId" | "repairId" | "repairType">;

const emptyResponse: IConnection<RepairType> = {
    edges: [],
    items: [],
    pageInfo: emptyPage,
    totalCount: 0
};

const queryRepairsByIds = async (repairIds: number[]): Promise<IConnection<RepairType>> => {
    if (repairIds.length === 0) {
        return emptyResponse;
    }

    const filter: Record<string, unknown> = {
        "id": {
            "$in_list": repairIds
        }
    };
    const input: IConnectionQuery<Record<string, unknown>> = {
        filter,
        paging: {
            pageIndex: 1,
            pageSize: 999,
            sortBy: "dateCreated",
            sortDirection: "DESCENDING"
        }
    };
    const response = await executeQuery<RepairQueryResponse>(gql, input);
    return response
        ? response.repairs
        : Promise.reject(new Error("Missing query response."));
};

export type { RepairType };
export { queryRepairsByIds };