import React, { Component } from 'react';
import { Radio, RadioGroup } from "@blueprintjs/core";
import classNames from 'classnames';
import { LocalDate } from "@js-joda/core";

import { AccessControl } from "../../../auth/components";
import { RoleNames } from "../../../auth/roles";
import { ILifecycleEvent } from '../../../lib/Model';
import { NodeID } from '../../../lib/nodeIdentifier';
import { formatText, handleValidation, timestampFormatter } from '../../../lib/Utils';
import { LookupItem } from '../../../store/types';
import { ILookupItem } from '../../../store/types/app';
import { IInspection } from '../../../store/types/inspections';
import ControlHeader from '../../controlHeader/controlHeader';
import LabelledField, { LabelledFieldProps, SelectOption, asLookupItem } from '../../labelledField';
import FileUploader from '../../files/fileUploader';
import NavButton from '../../navButton/navButton';
import NotesTab from '../../notes/NotesTab';
import AssignInspectorsDialog from '../assignInspectorsDialog';
import AssignInspectionGroupDialog from '../assignInspectionGroupDialog';
import styles from './inspectionControl.module.scss';
import SelectDefectsDialog from '../../defect/selectDefectsDialog';

interface IInspectionControlProps {
    readonly inspectionCancelReasons: ILookupItem[];
    readonly inspectionResults: ILookupItem[];
    readonly inspectionTests: ILookupItem[];
    readonly inspectionTypes: ILookupItem[];
    readonly selectedInspection: IInspection | undefined;
    readonly fileStoreToken: string;
    readonly hideNavButton?: boolean;
    readonly waterSourceCategory: ILookupItem;
    readonly onAssignInspectionGroup?: (inspectionNodeId: NodeID, groupNodeId: NodeID) => void;
    readonly onAssignInspector?: (inspectionNodeId: NodeID, inspectorNodeId: NodeID) => void;
    readonly onCancelInspection?: (inspectionNodeId: string, cancelReason: SelectOption) => void;
    readonly onCompleteInspection?: (inspectionNodeId: string, test: SelectOption | undefined, result: SelectOption, resolvedDefects: string[], unresolvedDefects: string[]) => void;
    readonly onClearError?: () => void;
    readonly onClose?: () => void;
    readonly onRemoveInspectionGroup?: (inspectionNodeId: string) => void;
    readonly onRemoveInspector?: (inspectionNodeId: string) => void;
    readonly onShowError?: (message: string) => void;
    readonly onUpdateInspection?: (inspectionNodeId: string, test: SelectOption | undefined, type: SelectOption, inspectionDate: LocalDate, description: string | undefined, isWallPlateFitted: boolean | undefined, isServiceRecordInDate: boolean | undefined) => void;
}

interface InspectionControlStateData {
    readonly description: string | undefined;
    readonly inspectionDate: LocalDate | undefined;
    readonly isWallPlateFitted: boolean | undefined;
    readonly isServiceRecordInDate: boolean | undefined;
    readonly type: SelectOption | undefined;
    readonly inspectionGroup: SelectOption | undefined;
    readonly crew: SelectOption | undefined;
    readonly technician: SelectOption | undefined;
    readonly test: SelectOption | undefined;
    readonly result: SelectOption | undefined;
    readonly cancel: SelectOption | undefined;
}

interface IInspectionControlState {
    readonly editing: boolean;
    readonly shouldValidate: boolean;
    readonly closeType: number;
    readonly assignGroupOpen: boolean;
    readonly assignInspectorOpen: boolean;
    readonly selectDefectsOpen: boolean;
    readonly defects: {
        readonly reviewed: string[];
        readonly notReviewed: string[];
    };
    readonly data: InspectionControlStateData;
}

const initState = (selectedInspection: IInspection | undefined): Readonly<IInspectionControlState> => {
    if (selectedInspection) {
        const { inspectionGroup, crew, technician, ...other } = selectedInspection;
        return {
            editing: false,
            shouldValidate: false,
            closeType: other.cancelReason
                ? 3
                : other.result
                    ? 2
                    : 1,
            assignGroupOpen: false,
            assignInspectorOpen: false,
            selectDefectsOpen: false,
            defects: {
                reviewed: [],
                notReviewed: []
            },
            data: {
                description: other.description,
                inspectionDate: other.inspectionDate,
                isWallPlateFitted: other.isWallPlateFitted,
                isServiceRecordInDate: other.isServiceRecordInDate,
                type: other.type,
                inspectionGroup: inspectionGroup
                    ? LookupItem(inspectionGroup.inspectionGroupId, inspectionGroup.name, inspectionGroup.sortOrder ?? 0)
                    : undefined,
                crew: crew
                    ? LookupItem(crew.crewId, crew.callSign, crew.sortOrder ?? 0)
                    : undefined,
                technician: technician
                    ? LookupItem(technician.technicianId, technician.displayName, technician.sortOrder ?? 0)
                    : undefined,
                test: other.test,
                result: other.result,
                cancel: other.cancelReason
            }
        };
    }
    return {
        editing: false,
        shouldValidate: false,
        closeType: 1,
        assignGroupOpen: false,
        assignInspectorOpen: false,
        selectDefectsOpen: false,
        defects: {
            reviewed: [],
            notReviewed: []
        },
        data: {
            description: undefined,
            inspectionDate: undefined,
            isWallPlateFitted: undefined,
            isServiceRecordInDate: undefined,
            type: undefined,
            inspectionGroup: undefined,
            crew: undefined,
            technician: undefined,
            test: undefined,
            result: undefined,
            cancel: undefined
        }
    };
};
class InspectionControl extends Component<IInspectionControlProps, IInspectionControlState> {
    constructor(props: IInspectionControlProps) {
        super(props);

        this.state = initState(props.selectedInspection);
    }

    public componentWillUnmount(): void {
        this.props.onClearError?.();
    }

    public render(): JSX.Element {
        const handleNavClick = (): void => {
            this.props.onClose?.();
        };
        const handleAssignInspectionGroup = (inspectionId: NodeID, groupId: NodeID): void => {
            this.props.onAssignInspectionGroup?.(inspectionId, groupId);
        };
        const handleAssignInspectionGroupClose = (): void => {
            this.setState({ assignGroupOpen: false });
        };
        const handleAssignInspector = (inspectionId: NodeID, inspectorId: NodeID): void => {
            this.props.onAssignInspector?.(inspectionId, inspectorId);
        };
        const handleAssignInspectorClose = (): void => {
            this.setState({ assignInspectorOpen: false });
        };
        const handleSelectDefectsClose = (): void => {
            this.setState({ selectDefectsOpen: false });
        };
        const handleSelectDefectsSave = (reviewed: string[], notReviewed: string[]): void => {
            this.setState({ defects: { reviewed, notReviewed } });
        };
        const { waterSource } = this.props.selectedInspection ?? {};
        return (
            <div className={styles.inspectionControlContainer}>
                {!this.props.hideNavButton && <NavButton onClick={handleNavClick} text="Back: Inspections" chevronDir="left" />}
                <div className={styles.inspectionControl}>
                    <div className={styles['action-buttons-container']}>
                        {this.renderActions()}
                    </div>
                    {this.renderInspectionData()}
                </div>
                {this.renderNotes()}
                <AssignInspectorsDialog isOpen={this.state.assignInspectorOpen} onAssignInspector={handleAssignInspector} onClose={handleAssignInspectorClose} />
                <AssignInspectionGroupDialog isOpen={this.state.assignGroupOpen} onAssignInspectionGroup={handleAssignInspectionGroup} onClose={handleAssignInspectionGroupClose} />
                <SelectDefectsDialog
                    isOpen={this.state.selectDefectsOpen}
                    onSave={handleSelectDefectsSave}
                    onClose={handleSelectDefectsClose}
                    waterSourceNodeId={waterSource?.waterSourceNodeId}
                />
            </div>
        );
    }

    private renderNotes(): JSX.Element {
        const { selectedInspection } = this.props
        return selectedInspection
            ? <NotesTab objectType="INSPECTION" objectNodeId={selectedInspection.inspectionNodeId} notes={selectedInspection.notes} />
            : <React.Fragment />;
    }

    private renderActions(): JSX.Element {
        const { selectedInspection } = this.props
        if (this.state.editing) {
            const handleCancel = (): void => this.setState({
                editing: false,
                shouldValidate: false,
                closeType: 0
            });
            return (
                <React.Fragment>
                    <button id="save-button" className="action-button" onClick={this.handleSave}>
                        Save
                    </button>
                    <button id="cancel-button" className="action-button action-button--cancel" onClick={handleCancel}>
                        Cancel
                    </button>
                </React.Fragment>
            );
        }

        const handleEdit = (): void => {
            this.setState({ editing: true });
        };
        const isOpen = selectedInspection?.isOpen ?? false;
        return (
            <AccessControl role={RoleNames.INSPECTIONS_ALL}>
                <button id="edit-button" className="action-button" disabled={!isOpen} onClick={handleEdit}>
                    Edit
                </button>
            </AccessControl>
        );
    }

    private renderDescription(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "text",
            id: "inspection-description",
            label: "Description",
            classes: { container: 'input__group', label: 'input__label' },
            value: this.state.data.description,
            editing: this.state.editing,
            onChange: (value) => this.updateState("description", value)
        };
        return <LabelledField {...props} />;
    }

    private renderInspectionTests(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "select",
            id: "inspection-testType",
            label: "Inspection Test",
            classes: { container: 'input__group', label: 'input__label' },
            selectOptions: this.props.inspectionTests,
            editing: this.state.editing,
            value: this.state.data.test,
            onChange: value => this.setState(current => ({
                ...current,
                data: {
                    ...current.data,
                    test: value
                }
            }))
        };
        return <LabelledField {...props} />;
    }

    private renderInspectionCloseResult(inspection: IInspection): JSX.Element {
        const { editing } = this.state;
        const showControls = (!editing && !inspection.isOpen) || (editing && inspection.isOpen);
        return showControls
            ? <React.Fragment>
                {this.renderInspectionCloseType()}
                {this.renderInspectionResult()}
                {this.renderInspectionCancelReason()}
            </React.Fragment>
            : <React.Fragment />;
    }

    private renderInspectionCancelReason(): JSX.Element {
        const { selectedInspection } = this.props;
        if (selectedInspection && this.state.closeType === 3) {
            const props: LabelledFieldProps = {
                fieldType: "select",
                id: "inspection-close-reason",
                label: "Inspection Close Reason",
                classes: { container: 'input__group', label: 'input__label' },
                selectOptions: this.props.inspectionCancelReasons,
                editing: this.state.editing,
                value: this.state.data.cancel,
                onChange: (value) => {
                    const update: IInspectionControlState = {
                        ...this.state,
                        data: {
                            ...this.state.data,
                            cancel: value,
                            result: undefined
                        }
                    };
                    this.setState(update);
                }
            }
            return <LabelledField {...props} />;
        }
        return <React.Fragment />;
    }

    private renderInspectionCloseType(): JSX.Element {
        if (this.state.editing) {
            const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
                this.setState({
                    closeType: parseInt(e.currentTarget.value)
                });
                if (e.currentTarget.value === "2") {
                    this.setState({ selectDefectsOpen: true });
                }
            };
            return (
                <RadioGroup inline onChange={onChange} selectedValue={this.state.closeType}>
                    <Radio label="Deferred" value={1} />
                    <Radio label="Completed" value={2} />
                    <Radio label="Cancelled" value={3} />
                </RadioGroup>
            );
        }
        return <React.Fragment />;
    }

    private renderInspectionData(): JSX.Element {
        const { selectedInspection } = this.props;
        if (selectedInspection) {
            return (
                <div className={styles.inspectionData}>
                    <ControlHeader
                        editing={this.state.editing}
                        primaryKey="inspectionId"
                        primary={selectedInspection.inspectionId.toString()}
                        editPrimary={false}
                        secondaryKey="status"
                        secondaryReadOnlyValue={selectedInspection.isOpen ? LookupItem(1, "Open", 1) : LookupItem(2, "Closed", 2)}
                        tertiaryKey="type"
                        tertiaryValue={asLookupItem(this.state.data.type)}
                        tertiaryReadOnlyValue={selectedInspection.type}
                        tertiaryOptions={this.props.inspectionTypes}
                        tertiaryClass={handleValidation(this.state.shouldValidate, this.state.data.type)}
                        onTertiaryChange={(key, value): void => this.updateState(key as keyof InspectionControlStateData, value)}
                    />
                    {this.renderDescription()}
                    {this.renderInspectionDate()}
                    {this.renderIsWallPlateFitted()}
                    {this.renderIsServiceRecordInDate()}
                    {this.renderInspectors(selectedInspection)}
                    {this.renderInspectionLifecycleEvents(selectedInspection)}
                    {this.renderInspectionTests()}
                    {this.renderInspectionCloseResult(selectedInspection)}
                    {this.renderInternalTasksCompleted()}
                    <FileUploader entity='INSPECTION' entityId={selectedInspection?.inspectionId.toString()} />
                </div>
            );
        }
        return <React.Fragment />;
    }

    private renderInspectionDate(): JSX.Element {
        const inspectionDateProps: LabelledFieldProps = {
            fieldType: "date",
            id: "inspection-date",
            label: "Inspection Date *",
            value: this.state.data.inspectionDate,
            editing: this.state.editing,
            classes: { container: 'input__group', label: 'input__label', input: handleValidation(this.state.shouldValidate, this.state.data.inspectionDate) },
            onChange: (value) => this.updateState("inspectionDate", value)
        }
        return <LabelledField {...inspectionDateProps} />;
    }

    private renderInspectionLifecycleEvent(inspectionId: number, event: ILifecycleEvent, index: number): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "readonly",
            id: `${inspectionId}-${event.timestamp}`,
            label: formatText(event.action, true),
            value: event.timestamp.format(timestampFormatter)
        };
        return <LabelledField key={index} {...props} />
    }

    private renderInspectionLifecycleEvents(inspection: IInspection): JSX.Element[] | JSX.Element {
        return inspection.events
            ? inspection.events.map((event, index) => this.renderInspectionLifecycleEvent(inspection.inspectionId, event, index))
            : <React.Fragment />;
    }

    private renderInspectionResult(): JSX.Element {
        const { selectedInspection } = this.props;
        if (selectedInspection && this.state.closeType === 2) {
            const props: LabelledFieldProps = {
                fieldType: "select",
                id: "inspection-result",
                label: "Inspection Result",
                classes: { container: 'input__group', label: 'input__label' },
                selectOptions: this.props.inspectionResults,
                editing: this.state.editing,
                value: this.state.data.result,
                onChange: (value) => {
                    const update: IInspectionControlState = {
                        ...this.state,
                        data: {
                            ...this.state.data,
                            result: value,
                            cancel: undefined
                        }
                    };
                    this.setState(update);
                },
                onClear: () => this.updateState("result", undefined)
            };
            return <LabelledField {...props} />;
        }
        return <React.Fragment />;
    }

    private renderInternalTasksCompleted(): JSX.Element {
        const { selectedInspection } = this.props;
        if (selectedInspection) {
            const { repairs, inspectionId } = selectedInspection;

            const internalRepairs = repairs
                .filter(repair => repair.repairCategory.value === 2)
                .map(repair => repair.repairType?.displayText ?? "");

            const value = internalRepairs.length === 0
                ? "-"
                : internalRepairs.length > 4
                    ? internalRepairs.slice(0, 4).join(", ").concat("...")
                    : internalRepairs.join(", ");

            const props: LabelledFieldProps = {
                fieldType: "readonly",
                id: `${inspectionId}-${repairs.length}`,
                label: `Internal Tasks Completed (${internalRepairs.length})`,
                classes: { container: 'input__group', label: 'input__label' },
                value: value
            };
            return <LabelledField key={`${inspectionId}`} {...props} />
        }
        return <React.Fragment />;
    }

    private renderInspectors(inspection: IInspection): JSX.Element {
        return (
            <div className={styles["inspectors-container"]}>
                <div className={styles["inspectors-list"]}>
                    {this.renderInspectionGroups(inspection)}
                    {this.renderTechnicians(inspection)}
                    {this.renderCrews(inspection)}
                </div>
                <AccessControl role={RoleNames.INSPECTIONS_ALL}>
                    <div className={styles["inspectors-button-container"]}>
                        {this.renderAssignInspectorButton()}
                        {this.renderRemoveInspectorButton(inspection)}
                    </div>
                    <div className={styles["inspectors-button-container"]}>
                        {this.renderAssignGroupButton()}
                        {this.renderRemoveGroupButton(inspection)}
                    </div>
                </AccessControl>
            </div>
        );
    }

    private renderInspectionGroups(inspection: IInspection): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "readonly",
            id: "inspection-inspectionGroup",
            label: "Inspection Group",
            classes: { container: 'input__group', label: 'input__label' },
            value: inspection.inspectionGroup?.name
        };
        return <LabelledField {...props} />;
    }

    private renderTechnicians(inspection: IInspection): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "readonly",
            id: "inspection-technicians",
            label: "Technician",
            classes: { container: 'input__group', label: 'input__label' },
            value: inspection.technician?.displayName
        };
        return <LabelledField {...props} />;
    }

    private renderCrews(inspection: IInspection): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "readonly",
            id: "inspection-crews",
            label: "Crew",
            classes: { container: 'input__group', label: 'input__label' },
            value: inspection.crew?.callSign
        };
        return <LabelledField {...props} />;
    }

    private renderIsWallPlateFitted(): JSX.Element {
        if (this.props.waterSourceCategory.value === 3) {
            const props: LabelledFieldProps = {
                fieldType: "switch",
                id: "inspection-isWallPlateFitted",
                label: "Wall Plate Fitted",
                classes: { container: 'input__group', label: 'input__label' },
                value: this.state.data.isWallPlateFitted,
                options: { checked: "Yes", unchecked: "No" },
                editing: this.state.editing,
                onChange: (value) => this.updateState("isWallPlateFitted", value)
            };
            return <LabelledField {...props} />;
        }

        return <React.Fragment />;
    }

    private renderIsServiceRecordInDate(): JSX.Element {
        if (this.props.waterSourceCategory.value === 3) {
            const props: LabelledFieldProps = {
                fieldType: "switch",
                id: "inspection-isServiceRecordInDate",
                label: "Service Record In Date",
                classes: { container: 'input__group', label: 'input__label' },
                value: this.state.data.isServiceRecordInDate,
                options: { checked: "Yes", unchecked: "No" },
                editing: this.state.editing,
                onChange: (value) => this.updateState("isServiceRecordInDate", value)
            };
            return <LabelledField {...props} />;
        }

        return <React.Fragment />;
    }

    private renderAssignInspectorButton(): JSX.Element {
        const className = classNames("action-button", styles["action-button--inspectors"]);
        const onClick = (): void => this.setState({ assignInspectorOpen: true });
        return <button type="button" id="inspectors-modal-button" className={className} onClick={onClick}>Assign Inspector</button>;
    }

    private renderAssignGroupButton(): JSX.Element {
        const className = classNames("action-button", styles["action-button--inspectors"]);
        const onClick = (): void => this.setState({ assignGroupOpen: true });
        return <button type="button" id="inspectionGroup-modal-button" className={className} onClick={onClick}>Assign Group</button>;
    }

    private renderRemoveInspectorButton(inspection: IInspection): JSX.Element {
        const className = classNames("action-button", styles["action-button--inspectors"]);
        const onClick = (): void => this.props.onRemoveInspector?.(inspection.inspectionNodeId);
        const buttonDisabled: boolean = !inspection.technician && !inspection.crew;
        return <button type="button" disabled={buttonDisabled} id="inspector-remove-button" className={className} onClick={onClick}>Remove Inspector</button>;
    }

    private renderRemoveGroupButton(inspection: IInspection): JSX.Element {
        const className = classNames("action-button", styles["action-button--inspectors"]);
        const onClick = (): void => this.props.onRemoveInspectionGroup?.(inspection.inspectionNodeId);
        const buttonDisabled = !inspection.inspectionGroup
        return <button type="button" disabled={buttonDisabled} id="inspectionGroup-remove-button" className={className} onClick={onClick}>Remove Group</button>;
    }

    private updateState = (key: keyof InspectionControlStateData, value: string | LocalDate | SelectOption | boolean | undefined): void => {
        this.setState(current => ({
            ...current,
            data: {
                ...current.data,
                [key]: value
            }
        }));
    }

    private handleSave = (): void => {
        const { data: { inspectionDate, test, type, result, cancel, description, isWallPlateFitted, isServiceRecordInDate }, closeType } = this.state;
        if (inspectionDate && type) {
            const {
                selectedInspection,
                onClearError,
                onUpdateInspection,
                onCompleteInspection,
                onCancelInspection
            } = this.props;
            const {
                defects: {
                    reviewed: reviewedDefects,
                    notReviewed: notReviewedDefects
                }
            } = this.state;

            onClearError?.();

            if (selectedInspection) {
                const { inspectionNodeId } = selectedInspection;

                this.setState({ shouldValidate: true })

                onUpdateInspection?.(inspectionNodeId, test, type, inspectionDate, description, isWallPlateFitted, isServiceRecordInDate);

                if (closeType === 2 && result) {
                    onCompleteInspection?.(inspectionNodeId, test, result, reviewedDefects, notReviewedDefects);
                }

                if (closeType === 3 && cancel) {
                    onCancelInspection?.(inspectionNodeId, cancel);
                }

                this.setState({ editing: false });
            }
        } else {
            this.props.onShowError?.("Please complete all required fields.");
        }
    }
}

export type { IInspectionControlProps };
export default InspectionControl;