import React from "react";
import { useDispatch } from "react-redux";

import { AddressDetailsData, toAddressDetails } from "../types";
import EditAddressBase, { EditAddressProps } from "./EditAddress.view";
import { startUpdateAddress } from "../../../api/updateAddress";

const EditAddress = ({ onSelect, onUpdate, ...props }: EditAddressProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleSelect = async (data: AddressDetailsData): Promise<void> => {
        if (data.addressNodeId) {
            const action = startUpdateAddress(data.addressNodeId, data);
            const address = await action(dispatch);
            if (address) {
                onSelect?.(toAddressDetails(address));
            }
        }
    };
    const handleUpdate = async (data: AddressDetailsData): Promise<void> => {
        if (data.addressNodeId) {
            const action = startUpdateAddress(data.addressNodeId, data);
            const address = await action(dispatch);
            if (address) {
                onUpdate?.(toAddressDetails(address));
            }
        }
    };

    return <EditAddressBase {...props} onSelect={handleSelect} onUpdate={handleUpdate} />;
};

export default EditAddress;