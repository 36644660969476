import React from "react";

import { renderWaterSources } from "../../../lib/map";
import Map from "../../../components/map/desktop";
import NotesTab from "../../../components/notes/NotesTab";
import { IWaterSource, IMapSettings, tileServerUrl } from "../../../store/types";
import Panel from "./Panel";
import PanelContainer from "./PanelContainer";
import WaterSourceControl from "./WaterSourceControl";

import styles from "./ViewWaterSource/ViewWaterSource.module.scss";

interface WaterSourceDetailsTabProps {
    readonly waterSource: IWaterSource;
    readonly mapSettings: IMapSettings;
}

const renderMapFeatures = (waterSource: IWaterSource) => {
    return (context: Map): void => {
        renderWaterSources(context, waterSource);
        const x = waterSource.location.coordinates?.x;
        const y = waterSource.location.coordinates?.y;
        context?.gotoPoint([x, y]);
    };
};

const WaterSourceDetailsTab = (props: WaterSourceDetailsTabProps): JSX.Element => {
    const { waterSource, mapSettings: { tileServer, view } } = props;
    return (
        <PanelContainer>
            <Panel edge="left">
                <WaterSourceControl selectedWaterSource={waterSource} fullPageLayout={true} />
            </Panel>
            <Panel edge="right">
                <div className={styles.map}>
                    <Map
                        mapCentre={view.centre}
                        mapTileServer={tileServer}
                        data={{ waterSources: [waterSource] }}
                        pointRenderHandler={renderMapFeatures(waterSource)}
                    />
                </div>
                <NotesTab objectType="WATER_SOURCE" objectNodeId={waterSource.waterSourceNodeId} notes={waterSource.notes ?? []} />
            </Panel>
        </PanelContainer>
    );
};

export type { WaterSourceDetailsTabProps };
export default WaterSourceDetailsTab;