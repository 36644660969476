import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import DownloadIcon from "@material-ui/icons/GetApp";

import { RoleNames } from "../../../auth/roles";
import { AccessControl } from "../../../auth/components";
import { downloadDocument } from "../../../components/shared/fileManager";
import useSelector from "../../../lib/hooks/useSelector";
import { Attachment } from "./Attachment.types";

const useStyles = makeStyles(theme => createStyles({
    button: {
        color: theme.palette.grey[200]
    }
}));

interface AttachmentDownloaderProps {
    readonly attachment: Attachment;
}

const AttachmentDownloader = ({ attachment }: AttachmentDownloaderProps): JSX.Element => {
    const fileStoreToken = useSelector<string>(state => state.fileStore.token);
    const styles = useStyles();
    const handleClick = async (): Promise<void> => {
        const { attachmentId, name } = attachment;
        if (attachmentId) {
            await downloadDocument(fileStoreToken, attachmentId, name);
        }
    };
    return (
        <AccessControl any={[RoleNames.FILES_DOWNLOAD, RoleNames.SECURE_FILES_DOWNLOAD]}>
            <IconButton className={styles.button} onClick={handleClick}>
                <DownloadIcon />
            </IconButton>
        </AccessControl>
    );
};

export type { AttachmentDownloaderProps };
export default AttachmentDownloader;