import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

interface LineProps {
    readonly children?: string;
    readonly lineBreak?: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    line: {
        display: "block",
        marginBottom: (props: LineProps): number => 
            props.lineBreak 
            ? theme.spacing(0.5) 
            : theme.spacing(0)
    },
}));

const Line = ({ children, lineBreak }: LineProps): JSX.Element => {
    const styles = useStyles({ lineBreak });
    return children 
        ? <span className={styles.line}>{children}</span>
        : <React.Fragment />
};

export type { LineProps };
export default Line;