import React from "react";

import useSelector from '../../lib/hooks/useSelector';
import { IStreetViewParams } from "../../store/types";
import StreetViewBase, { IStreetViewProps } from './streetView';

const StreetView = (props: Pick<IStreetViewProps, "isOpen" | "onClose"> & { readonly params?: IStreetViewParams }): JSX.Element => {
    const config = useSelector(state => ({
        ...state.map.streetView,
        params: {
            ...state.map.streetView.params,
            position: {
                lat: props.params?.lat,
                lng: props.params?.lng
            }
        }
    }));
    const combined: IStreetViewProps = {
        ...props,
        config,
    };
    return <StreetViewBase {...combined} />;
};

export default StreetView;