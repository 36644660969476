import React from "react";
import { useDispatch } from "react-redux";

import { AccessControl } from "../../../auth/components";
import { RoleNames, RoleName } from "../../../auth/roles";
import { DialogVariant } from "../../../components/utils/globalDialog";
import { setDialogOpen } from "../../../store/actions/app";
import AppBarMenu from "./AppBarMenu";
import SecureAppBarMenuItem from "./SecureAppBarMenuItem";

const CREATE_ANY: RoleName[] = [
    RoleNames.WS_ALL,
    RoleNames.INSPECTIONS_ALL,
    RoleNames.DEFECTS_ALL,
    RoleNames.REPAIRS_ALL,
    RoleNames.ROUTES_ALL,
    RoleNames.SCHEMES_ALL
];

const AppBarCreateMenu = (): JSX.Element => {
    const dispatch = useDispatch();

    const handleClick = (dialog: DialogVariant) => {
        return (): void => {
            dispatch(setDialogOpen(dialog));
        };
    };

    return (
        <AccessControl any={CREATE_ANY}>
            <AppBarMenu id="create" title="Create">
                <SecureAppBarMenuItem role={RoleNames.SCHEMES_ALL} id="createScheme" label="Scheme" onClick={handleClick("createScheme")} />
                <SecureAppBarMenuItem role={RoleNames.WS_ALL} id="createWaterSource" label="Water Source" onClick={handleClick("createWaterSource")} />
                <SecureAppBarMenuItem role={RoleNames.INSPECTIONS_ALL} id="createInspection" label="Inspection" onClick={handleClick("createInspection")} />
                <SecureAppBarMenuItem role={RoleNames.DEFECTS_ALL} id="createDefect" label="Defect" onClick={handleClick("createDefect")} />
                <SecureAppBarMenuItem role={RoleNames.ROUTES_ALL} id="createRoute" label="Route" onClick={handleClick("createRoute")} />
                <SecureAppBarMenuItem role={RoleNames.REPAIRS_ALL} id="createExternalRepair" label="External Repair" onClick={handleClick("createExternalRepair")} />
                <SecureAppBarMenuItem role={RoleNames.REPAIRS_ALL} id="createInternalRepair" label="Internal Repair" onClick={handleClick("createInternalRepair")} />
            </AppBarMenu>
        </AccessControl>
    );
};

export default AppBarCreateMenu;