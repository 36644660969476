import { isTechnician, ITechnician } from "../../store/types";
import useProfile from "./useProfile";

const useTechnician = (): ITechnician | undefined => {
    const inspector = useProfile();
    return inspector
        ? isTechnician(inspector)
            ? inspector
            : undefined
        : undefined;
};

export default useTechnician;