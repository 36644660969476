import { LocalDate, LocalTime, LocalDateTime, ZonedDateTime, Period, Duration } from "@js-joda/core";

// 2020-07-24
const localDate = /^(\d{4}-\d{2}-\d{2})$/;
// 09:42
// 09:42:42
// 09:42:42
// 09:42:42.123
// 09:42:42.123456789
const localTime = /^(\d{2}:\d{2}(?::\d{2}(?:\.\d+)?)?)$/;
// 2020-07-24T09:42
// 2020-07-24T09:42:42
// 2020-07-24T09:42:42.123
// 2020-07-24T09:42:42.123456789
const localDateTime = /^((\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}(?::\d{2}(?:\.\d+)?)?))$/;
// 2016-03-18T12:38:23.561+01:00[SYSTEM]
// 2016-03-18T12:38:23.561+01:00
// 2016-03-18T11:38:23.561Z
// 2016-03-18T06:38:23.561-05:00[UTC-05:00]
// 2017-02-04T17:01:15.846+01:00[Europe/Paris]
const zonedDateTime = /^((\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}(?::\d{2}(?:\.\d+)?)?))(Z|[+-]\d{2}:\d{2}(?:\[(?:SYSTEM|\w+\/\w+|UTC-\d{2}:\d{2})?\])?)$/;
// P1Y10M -> 1 year, 10 months
// P10Y5M30D -> 10 years, 5 months and 30 days
// P10Y -> 10 years
// P10Y10M-42D -> 10 years and 10 months, subtract 42 days
// P10M -> 10 months
// P42D -> 42 days
const period = /^(P(?:(-?\d+Y)|(?:(-?\d+Y)?(-?\d+M))|(?:(-?\d+Y)?(-?\d+M)?(-?\d+D))))$/;
// PT10H -> 10 hours
// PT34S -> 34 seconds
// PT6M -> 6 minutes
// PT58H6M34S -> 2 days, 10 hours, 6 minutes, 34 seconds
// PT58H-34M -> 57 hours, 26 minutes
// PT0.356S -> 356 milliseconds
// PT0.000000356S -> 356 nanoseconds
// PT58H6M34.356000356S -> 58 hours, 6 minutes, 34 seconds, 356 milliseconds, 356 nanoseconds
const duration = /^(PT(?:(-?\d+H)|(?:(-?\d+H)?(-?\d+M))|(?:(?:(-?\d+H)?(-?\d+M))?(-?\d+(\.\d+)?S))))$/;

const jsJodaDeserializer = (key: string, value: unknown): any => {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    if (typeof value === "string") {
        if (localDate.test(value)) {
            return LocalDate.parse(value);
        }
        if (localTime.test(value)) {
            return LocalTime.parse(value);
        }
        if (localDateTime.test(value)) {
            const result = LocalDateTime.parse(value);
            return result;
        }
        if (zonedDateTime.test(value)) {
            return ZonedDateTime.parse(value);
        }
        if (period.test(value)) {
            return Period.parse(value);
        }
        if (duration.test(value)) {
            return Duration.parse(value);
        }
    }
    return value;
};

export { jsJodaDeserializer };
