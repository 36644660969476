import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { formatText } from '../../lib/Utils';
import { ILookupItem } from '../../store/types/app';

import LabelledField, { SelectOption } from '../labelledField';

import './controlHeader.scss';

interface IControlHeaderProps {
    readonly primaryKey: string;
    readonly primary: string;
    readonly editPrimary?: boolean;
    readonly hidePrimary?: boolean;

    readonly secondaryKey: string;
    readonly secondaryValue?: ILookupItem;
    readonly secondaryReadOnlyValue?: ILookupItem;
    readonly secondaryOptions?: ILookupItem[];
    readonly secondaryClass?: string;
    readonly secondaryFooter?: string;

    readonly tertiaryKey: string;
    readonly tertiaryValue?: ILookupItem;
    readonly tertiaryReadOnlyValue?: ILookupItem;
    readonly tertiaryOptions?: ILookupItem[];
    readonly tertiaryClass?: string;

    readonly editing?: boolean;
    readonly renderPrimaryAsLink?: boolean;
    readonly waterSourceNodeId?: string;
    readonly onPrimaryChange?: (key: string, value: string) => void;
    readonly onSecondaryChange?: (key: string, option: SelectOption | undefined) => void;
    readonly onTertiaryChange?: (key: string, option: SelectOption | undefined) => void;
}

export default class ControlHeader extends Component<IControlHeaderProps> {
    public render(): JSX.Element {
        const showPrimary = !this.props.hidePrimary;
        const showTertiary = showPrimary;
        return (
            <div className="controlHeader">
                <div className="controlHeaderMain">
                    {showPrimary ? this.renderPrimaryField() : this.renderTertiaryField()}
                    {this.renderSecondaryField()}
                </div>
                {showTertiary && this.renderTertiaryField()}
            </div>
        );
    }

    private renderPrimaryField(): JSX.Element {
        const { primary, primaryKey, editing, editPrimary, onPrimaryChange } = this.props;
        if (editing && editPrimary) {
            const handleChange = (key: string, value: string): void => {
                onPrimaryChange?.(key, value);
            };
            return (
                <input
                    type="text"
                    placeholder="Water Source Reference"
                    id="waterSource_reference"
                    name="waterSource_reference"
                    className="input"
                    value={formatText(primary)}
                    onChange={(e): void => handleChange(primaryKey, e.currentTarget.value)}
                />
            );
        }
        return this.props.renderPrimaryAsLink
            ? (<Link className="controlHeader-primary" to={`/water-source/${this.props.waterSourceNodeId}`} target={this.props.waterSourceNodeId} >{formatText(primary)}</Link>)
            : (<div className="controlHeader-primary">{formatText(primary)}</div>);
    }

    private renderSecondaryField(): JSX.Element {
        const { secondaryValue, secondaryReadOnlyValue, secondaryOptions, secondaryKey, secondaryFooter, editing, onSecondaryChange } = this.props;
        if (secondaryKey) {
            if (editing && secondaryOptions) {
                const handleChange = (key: string, option: SelectOption | undefined): void => {
                    onSecondaryChange?.(key, option);
                };
                return (
                    <LabelledField
                        fieldType="select"
                        id="operableStatus"
                        label=""
                        classes={{ container: 'input__group', label: 'input__label' }}
                        editing
                        value={secondaryValue}
                        selectOptions={secondaryOptions}
                        onChange={(value: SelectOption | undefined): void => handleChange(secondaryKey, value)}
                        onClear={(): void => handleChange(secondaryKey, undefined)}
                    />
                );
            }
            const displayText = secondaryReadOnlyValue
                ? secondaryReadOnlyValue.displayText
                : undefined;
            return (
                <div className="secondaryHeaderContainer">
                    <p className="controlHeader-secondary">{formatText(displayText, true, "-")}</p>
                    {secondaryFooter && <p className="controlHeader-secondary-footer">{secondaryFooter}</p>}
                </div>
            );
        }
        return <Fragment />;
    }

    private renderTertiaryField(): JSX.Element {
        const { tertiaryValue, tertiaryReadOnlyValue, tertiaryOptions, tertiaryKey, editing, onTertiaryChange } = this.props;
        if (tertiaryKey) {
            if (editing && tertiaryOptions) {
                const handleChange = (key: string, option: SelectOption | undefined): void => {
                    onTertiaryChange?.(key, option);
                };
                return (
                    <LabelledField
                        fieldType="select"
                        id="tertiaryField"
                        label=""
                        classes={{ input: this.props.tertiaryClass }}
                        editing
                        value={tertiaryValue}
                        selectOptions={tertiaryOptions}
                        onChange={(value: SelectOption | undefined): void => handleChange(tertiaryKey, value)}
                        onClear={(): void => handleChange(tertiaryKey, undefined)}
                    />
                );
            }
            const displayText = tertiaryReadOnlyValue
                ? tertiaryReadOnlyValue.displayText
                : undefined;
            return <div className="controlHeader-tertiary">{formatText(displayText, true, "-")}</div>;
        }
        return <Fragment />;
    }
}
