import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { defaultLookups } from "../../../store/reducers/app";
import { useScheme } from "../../appWindowContext";
import SchemeSearchBase, { SchemeSearchProps } from "./schemeSearch.view";

const SchemeSearch = (): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showScheme } = useScheme({ newWindow: true });
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const props: SchemeSearchProps = {
        lookups,
        hideToast: () =>
            dispatch(setGlobalToast()),
        showError: (message, error) => error
            ? dispatch(setError(message, error))
            : dispatch(setGlobalToast({
                showToast: true,
                type: "ERROR",
                message
            })),
        showLoading: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message
            })),
        showSchemeOnMap: scheme =>
            showScheme(parseNodeId(scheme.schemeNodeId)),
        showSuccess: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message,
                timeout: 5000
            }))
    };
    return <SchemeSearchBase {...props} />;
};

export default SchemeSearch;