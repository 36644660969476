import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { defaultLookups } from "../../../store/reducers/app";
import { useWaterSource } from "../../appWindowContext";
import { WaterSourceTab } from "../../dataTabs/DataTabsContext";
import DefectSearchBase, { IDefectSearchProps } from "./defectSearch.view";

const DefectSearch = (): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showWaterSource } = useWaterSource({ newWindow: true });
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const props: IDefectSearchProps = {
        lookups,
        hideToast: () =>
            dispatch(setGlobalToast()),
        showError: (message, error) => error
            ? dispatch(setError(message, error))
            : dispatch(setGlobalToast({
                showToast: true,
                type: 'ERROR',
                message
            })),
        showDefectOnMap: (defect): void => {
            const { defectNodeId, waterSource } = defect;
            showWaterSource(parseNodeId(waterSource.waterSourceNodeId), {
                tab: WaterSourceTab.DEFECTS,
                defect: parseNodeId(defectNodeId),
                focusMap: true
            });
        },
        showLoading: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message,
            })),
        showSuccess: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message,
                timeout: 5000
            }))
    };
    return <DefectSearchBase {...props} />;
};

export default DefectSearch;