import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setCreateFromMapLocation } from '../../store/actions/map';
import { IReduxState } from '../../store/types';
import LocationMap, { ILocationMapProps } from './locationMap';

type ConnectedProps = Pick<ILocationMapProps, "mapServer" | "mapCentre">;

const mapStateToProps = (state: IReduxState): ConnectedProps => {
    const { mapSettings } = state.map;
    const { view, tileServer } = mapSettings ?? {};

    const mapCentre: [number, number] | undefined = view?.centre
        ? [view.centre.x, view.centre.y]
        : [0, 0];
    return ({
        mapServer: tileServer ?? { apiKey: "", type: "", url: "", params: undefined },
        mapCentre: mapCentre
    })
};

type ConnectedActions = Pick<ILocationMapProps, "clearCreateFromMapLocation">;

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): ConnectedActions => ({
    clearCreateFromMapLocation: (): void => {
        dispatch(setCreateFromMapLocation());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationMap);
