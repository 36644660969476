import { v4 as uuid } from "uuid";

import { EntityName } from "../../../../../store/types";
import { fileManager } from "@3tc/shared-components-ts";
import { IFileList } from "../../fileManager.d";
import { GetDocumentMetadataParams } from "@3tc/shared-components-ts/build/file-manager/v2/api";

const validFileTypes = ["audio/", "video/", "image/", "text/", "/pdf", "/msword", "/vnd.openxmlformats", "/vnd.ms-excel"];

const isValidFileType = (file: File): boolean => {
    return validFileTypes.some(type => file.type.includes(type));
};

const fromFile = (
    entity: EntityName,
    entityId: number,
    status: fileManager.v2.FileUploadStatus,
    file: File,
    errorCode?: fileManager.v2.ErrorReasonCode, errorDescripton?: string): fileManager.v2.ITrackedFile => ({
        file: file,
        status: status,
        errorReasonCode: errorCode,
        errorReasonDescription: errorDescripton,
        metaData: {
            tempId: uuid(),
            Local: true,
            Entity: entity,
            EntityId: entityId,
            Name: file.name,
            Size: file.size,
            Type: file.type,
            Sensitive: 0,
            Path: "",
            Description: "",
        },
        progressPercent: undefined
    });

const fromMetaData = (
    entity: EntityName,
    entityId: number,
    status: fileManager.v2.FileUploadStatus,
    fileInfo: fileManager.v2.IFileMetadataRecord): fileManager.v2.ITrackedFile => ({
        file: undefined,
        status: status,
        errorReasonCode: undefined,
        errorReasonDescription: undefined,
        metaData: {
            ...fileInfo
        },
        progressPercent: undefined
    });

const getUploadedFiles = async (fileStoreToken: string, entity: EntityName, entityId: number): Promise<fileManager.v2.ITrackedFile[]> => {
    const onError = (error: any): fileManager.v2.ITrackedFile[] => {
        console.warn("Error getting file meta-data: ", error);
        return [];
    };

    const params: GetDocumentMetadataParams = {
        entity,
        entityId
    };
    try {
        const response = await fileManager.v2.api.getDocumentMetadata(fileStoreToken, params);
        const files = response.match(
            metadata => metadata.map(data => fromMetaData(entity, entityId, "UPLOADED", data)),
            fault => onError(fault));
        return files;
    } catch (error) {
        return onError(error);
    }
};

const toTrackedFiles = (source: FileList | null, entity: EntityName, entityId: number): fileManager.v2.ITrackedFile[] => {
    return source
        ? Array.from(source).map((f: File) => {
            const isValid = isValidFileType(f);
            const status: fileManager.v2.FileUploadStatus = isValid ? "WAITING" : "ERROR";
            const errorCode: fileManager.v2.ErrorReasonCode | undefined = isValid ? undefined : "INVALID_FILE_TYPE";
            const errorDescription: string | undefined = isValid ? undefined : "Unsupported file type";
            return fromFile(entity, entityId, status, f, errorCode, errorDescription)
        })
        : [];
};

const toFileList = (source: FileList | null, entity: EntityName, entityId: number): IFileList => {
    return toTrackedFiles(source, entity, entityId)
        .filter(file => file.metaData.tempId)
        .reduce<IFileList>((acc, current) => ({
            ...acc,
            [current.metaData.tempId ?? ""]: current
        }), {});
}

const uploadFiles = async (fileStoreToken: string, files: IFileList): Promise<number> => {
    const uploaded: number[] = [];
    const uploads = Object.entries(files).map(async ([, file]) => {
        try {
            const response = await fileManager.v2.api.uploadDocument(fileStoreToken, file, () => undefined);
            const id = response.match(fileId => fileId, fault => {
                console.warn("Error uploading file.", fault);
                return undefined;
            });
            if (id) {
                uploaded.push(id);
            }
        } catch (error) {
            console.warn("Error uploading file.", error);
        }
    });
    return Promise.allSettled(uploads)
        .then(() => uploaded.length)
        .catch(() => 0);
};

export { getUploadedFiles, uploadFiles, toFileList };