import React, { useState } from 'react';
import Dialog from '../../../dialog/dialog';
import DialogTitle from '../../../dialog/dialogTitle';
import DialogContent from '../../../dialog/dialogContent';
import DialogActions from '../../../dialog/dialogActions';
import LabelledField from '../../../labelledField';
import executeQuery from '../../../../lib/executeQuery';
import styles from './editWaterSourceDialog.module.scss';
import { IConnection, IWaterSource } from '../../../../store/types';
import { NodeID } from '../../../../lib/nodeIdentifier';
import updateWaterSourceDetails from '../../../../api/waterSources/updateDetails';
import { IWaterSourceSummary } from '../../../inspectionView';

interface IEditWaterSourceIdDialogProps {
    readonly waterSourceNodeId: NodeID;
    readonly waterSourceId: string;
    readonly isOpen: boolean;
    readonly onClose?: () => void;
    readonly onSave?: (waterSource: IWaterSourceSummary) => void;
    readonly onCancel?: () => void;
}

const query = `query QuickSearch_Query($filter: QueryFilter) {
    waterSources(queryFilter: $filter) {
        totalCount
    }
}`;

const isWaterSourceExternalIdValid = async (externalId: string): Promise<boolean> => {
    const variables = {
        filter: {
            externalId
        }
    };
    const res = await executeQuery<{ waterSources: IConnection<IWaterSource> }>(query, variables);
    const totalCount = res?.waterSources.totalCount;
    return !totalCount;
};

const EditWaterSourceIdDialog = (props: IEditWaterSourceIdDialogProps): JSX.Element => {
    const { waterSourceNodeId, waterSourceId } = props;
    const [externalId, setExternalId] = useState<string>();
    const [isInvalid, setIsInvalid] = useState(false);

    const handleCancel = (): void => props.onCancel?.();
    const handleSave = async (): Promise<void> => {
        if (externalId && waterSourceNodeId) {
            const isNewIdValid = await isWaterSourceExternalIdValid(externalId);

            if (!isNewIdValid) {
                setIsInvalid(true);
                return;
            }

            try {
                const ws = await updateWaterSourceDetails(waterSourceNodeId, { externalId });
                if (ws) {
                    props.onSave?.(ws);
                }
            }
            catch (error) {
                console.error("Error updating water source external id.", error);
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" overflowY="unset" open={props.isOpen} onClose={handleCancel}>
            <DialogTitle onClose={handleCancel}>Change Water Source ID</DialogTitle>
            <DialogContent>
                <div className={styles.existingId}>{`Current Id: ${waterSourceId}`}</div>
                <LabelledField
                    id="waterSource_externalId"
                    label="New ID"
                    fieldType="text"
                    editing
                    classes={{ container: 'input__group', label: 'input__label', input: isInvalid ? "invalid" : '' }}
                    value={externalId}
                    onChange={setExternalId}
                    onFocus={(): void => setIsInvalid(false)}
                />
                {isInvalid && <div className={styles.invalid}>Cannot change the Water Source ID as it is already in use. Please choose another ID.</div>}
            </DialogContent>
            <DialogActions>
                <button className="action-button" disabled={!externalId} onClick={handleSave}>Save</button>
                <button id="cancel_button" className="action-button action-button--cancel" onClick={handleCancel}>Cancel</button>
            </DialogActions>
        </Dialog>
    );
};

export type { IEditWaterSourceIdDialogProps };
export default EditWaterSourceIdDialog;