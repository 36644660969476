import React from "react";
import { Switch, Route } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { mobile } from "../../themes";
import Search from '../../views/mobileSearch';
import LandingPage from '../../views/mobileStartView';
import RouterContext from "./components/RouterContext";
import AdminRouter from "./components/AdminRouter";
import InspectionsRouter from "./components/InspectionsRouter";
import StationsRouter from "./components/StationsRouter";
import NearMeRouter from "./components/NearMeRouter";

const MobileRouter = (): JSX.Element => {
    return (
        <RouterContext disallowedPaths={["/inspections/:inspectionId"]}>
            <CssBaseline />
            <ThemeProvider theme={mobile}>
                <Switch>
                    <Route path="/inspections">
                        <InspectionsRouter />
                    </Route>
                    <Route path="/stations">
                        <StationsRouter />
                    </Route>
                    <Route path="/search">
                        <Search />
                    </Route>
                    <Route path="/admin">
                        <AdminRouter />
                    </Route>
                    <Route path="/near-me">
                        <NearMeRouter />
                    </Route>
                    <Route>
                        <LandingPage />
                    </Route>
                </Switch>
            </ThemeProvider>
        </RouterContext>
    );
};

export default MobileRouter;