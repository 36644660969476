import React, { useEffect, useState, useCallback } from 'react';
import { LocalDate } from '@js-joda/core';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import LabelledField from '../../components/labelledField';
import executeQuery from '../../lib/executeQuery';
import { requiredLocalDate, StateValidators, validationStyle, validateState } from '../../lib/validation';
import { FilteredObject } from '../../store/types';
import DashboardCard from './components/DashboardCard';
import { defectsQuery, inspectionsQuery, repairsQuery, schemesQuery } from "./graphql";
import { DashboardData, DashboardResponse, DateRange, DashboardFilter, DateFilters, DashboardDataKey, FilterName } from './types';

const validators: StateValidators<DateRange> = {
    start: requiredLocalDate,
    end: requiredLocalDate
};

const validateDateRange = validateState(validators);
const startDateStyle = validationStyle(validators.start);
const endDateStyle = validationStyle(validators.end);

const useStyles = makeStyles(theme => createStyles({
    root: {
        padding: theme.spacing(3)
    },
    filterContainer: {
        marginBottom: theme.spacing(3)
    },
    cardContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "center"
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    inputLabel: {
        display: "inline-block",
        minWidth: "128px",
        maxWidth: "128px"
    }
}));

const bindQuery = (setData: (value: React.SetStateAction<DashboardData>) => void) => <F extends FilterName>(query: string, cardName: keyof DashboardDataKey<F>, dateField: keyof DateFilters<F>, filter: FilteredObject<DashboardFilter<F>>) => {
    return async (range: DateRange): Promise<void> => {
        const response = await executeQuery<DashboardResponse>(query, {
            filter: {
                [dateField]: {
                    $gte: range.start,
                    $lt: range.end?.plusDays(1)
                },
                ...filter
            }
        });
        if (response) {
            setData(current => ({
                ...current,
                [cardName]: response.items.totalCount
            }));
        }
    };
};

const DashboardPage = (): JSX.Element => {
    const styles = useStyles();

    const [dashboardData, setDashboardData] = useState<DashboardData>({});
    const [dateRange, setDateRange] = useState<DateRange>(() => {
        const date = LocalDate.now();
        return {
            start: date.minusDays(7),
            end: date
        }
    });

    const query = useCallback(bindQuery(setDashboardData), [setDashboardData]);
    const getInspectionsCompleted = useCallback(query<"inspection">(inspectionsQuery, "inspectionsCompleted", "dateClosed", {
        isOpen: false,
        isCompleted: true,
        isCancelled: false
    }), [query]);
    const getInspectionsDue = useCallback(query<"inspection">(inspectionsQuery, "inspectionsDue", "inspectionDate", {
        isOpen: true
    }), [query]);
    const getSchemesCreated = useCallback(query<"scheme">(schemesQuery, "schemesCreated", "dateCreated", {}), [query]);
    const getSchemesReturned = useCallback(query<"scheme">(schemesQuery, "schemesReturned", "dateReturned", {}), [query]);
    const getDefectsReported = useCallback(query<"defect">(defectsQuery, "defectsReported", "dateReported", {}), [query]);
    const getDefectsClosed = useCallback(query<"defect">(defectsQuery, "defectsClosed", "dateClosed", {
        isOpen: false
    }), [query]);
    const getInternalRepairsCompleted = useCallback(query<"repair">(repairsQuery, "internalRepairsCompleted", "dateClosed", {
        repairCategory: 2,
        isOpen: false,
        isCancelled: false
    }), [query]);
    const getExternalRepairsCreated = useCallback(query<"repair">(repairsQuery, "externalRepairsRaised", "dateCreated", {
        repairCategory: 1
    }), [query]);
    const getExternalRepairsClosed = useCallback(query<"repair">(repairsQuery, "externalRepairsClosed", "dateClosed", {
        repairCategory: 1,
        isOpen: false,
        isCancelled: false
    }), [query]);

    const getData = async (dateRange: DateRange): Promise<void> => {
        if (validateDateRange(dateRange)) {
            await Promise.allSettled([
                getInspectionsCompleted(dateRange),
                getInspectionsDue(dateRange),
                getSchemesCreated(dateRange),
                getSchemesReturned(dateRange),
                getDefectsReported(dateRange),
                getDefectsClosed(dateRange),
                getInternalRepairsCompleted(dateRange),
                getExternalRepairsCreated(dateRange),
                getExternalRepairsClosed(dateRange)
            ]);
        }
    };

    useEffect(() => {
        getData(dateRange).catch(error => console.error("Cannot get dashboard data.", error));
    }, []);

    const handleStartDateChange = (value: LocalDate | undefined): void => {
        setDateRange(current => ({
            ...current,
            start: value
        }));
    };
    const handleEndDateChange = (value: LocalDate | undefined): void => {
        setDateRange(current => ({
            ...current,
            end: value
        }));
    };

    const isValid = validateDateRange(dateRange);

    const handleRefreshButtonClick = async (): Promise<void> => {
        if (isValid) {
            setDashboardData({});
            await getData(dateRange);
        }
    };

    return (
        <div className={styles.root}>
            <Typography variant="h4" className={styles.title}>Dashboard</Typography>
            <div className={styles.filterContainer}>
                <LabelledField
                    fieldType="date"
                    id="dashboard_from_date"
                    label="Date From"
                    classes={{ label: styles.inputLabel, input: startDateStyle(dateRange.start) }}
                    editing
                    value={dateRange.start}
                    onChange={handleStartDateChange}
                />
                <LabelledField
                    fieldType="date"
                    id="dashboard_to_date"
                    label="Date To"
                    classes={{ label: styles.inputLabel, input: endDateStyle(dateRange.end) }}
                    editing
                    value={dateRange.end}
                    onChange={handleEndDateChange}
                />
                <button className='action-button' disabled={!isValid} onClick={handleRefreshButtonClick}>Refresh</button>
            </div>
            <div className={styles.cardContainer}>
                <DashboardCard
                    color='FUSE'
                    title="Inspections Completed"
                    description='The number of Inspections completed.'
                    number={dashboardData.inspectionsCompleted}
                />
                <DashboardCard
                    color='FUSE'
                    title='Inspections Due'
                    description='The number of open Inspections due.'
                    number={dashboardData.inspectionsDue}
                />
                <DashboardCard
                    color='MIST'
                    title='Schemes Created'
                    description='The number of Schemes created.'
                    number={dashboardData.schemesCreated}
                />
                <DashboardCard
                    color='MIST'
                    title="Schemes Returned"
                    description='The number of Schemes returned.'
                    number={dashboardData.schemesReturned}
                />
                <DashboardCard
                    color='PETROL'
                    title='Defects Reported'
                    description='The number of Defects reported.'
                    number={dashboardData.defectsReported}
                />
                <DashboardCard
                    color='PETROL'
                    title='Defects Closed'
                    description='The number of Defects closed.'
                    number={dashboardData.defectsClosed}
                />
                <DashboardCard
                    color='SPARK'
                    title="Internal Repairs Completed"
                    description='The number of Internal Repairs completed.'
                    number={dashboardData.internalRepairsCompleted}
                />
                <DashboardCard
                    color='SPARK'
                    title='External Repairs Created'
                    description='The number of External Repairs created.'
                    number={dashboardData.externalRepairsRaised}
                />
                <DashboardCard
                    color='SPARK'
                    title='External Repairs Closed'
                    description='The number of External Repairs closed.'
                    number={dashboardData.externalRepairsClosed}
                />
            </div>
        </div>
    );
};

export default DashboardPage;
