import React from "react";

import Box from "@material-ui/core/Box";

interface ViewSecondaryActionProps {
    readonly action?: React.ReactNode;
    readonly extended?: boolean;
}

const ViewSecondaryAction = ({ action, extended }: ViewSecondaryActionProps): JSX.Element => {
    return (
        <Box display="flex" flexDirection="row" ml={extended ? "auto" : "initial"}>
            {action}
        </Box>
    );
};

export type { ViewSecondaryActionProps };
export default ViewSecondaryAction;