import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import { AccessControl } from '../../../auth/components';
import { RoleNames } from '../../../auth/roles';
import { NodeID } from '../../../lib/nodeIdentifier';
import { IInspection, ILookupItem } from '../../../store/types';
import InspectionControl from '../inspectionControl';
import InspectionItem from './components/InspectionItem';

interface IInspectionPanelProps {
    readonly inspections: IInspection[];
    readonly selectedInspection: IInspection | undefined;
    readonly hideNavButton?: boolean;
    readonly waterSourceCategory: ILookupItem;
    readonly onClearInspection?: () => void;
    readonly onRefreshInspection?: () => void;
    readonly onSelectInspection?: (id: NodeID) => void;
    readonly showCreateDefectDialog: () => void;
    readonly showCreateInspectionDialog: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        padding: theme.spacing(2, 0),
        color: "white"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    }
}));

const InspectionList = (props: Pick<IInspectionPanelProps, "inspections" | "onSelectInspection" | "showCreateDefectDialog" | "showCreateInspectionDialog">): JSX.Element => {
    const { inspections, onSelectInspection, showCreateDefectDialog, showCreateInspectionDialog } = props;
    const styles = useStyles();

    const handleCreateDefectClick = (inspectionNodeId: NodeID): void => {
        onSelectInspection?.(inspectionNodeId);
        showCreateDefectDialog();
    };

    const handleInspectionClick = (inspectionNodeId: NodeID): void => {
        onSelectInspection?.(inspectionNodeId);
    };

    return (
        <div className={styles.root}>
            <AccessControl role={RoleNames.INSPECTIONS_ALL}>
                <div className={styles.buttonContainer}>
                    <button className="action-button" onClick={showCreateInspectionDialog}>
                        Add New Inspection
                    </button>
                </div>
            </AccessControl>
            <List disablePadding>
                {inspections.map(inspection =>
                    <InspectionItem key={inspection.inspectionNodeId} inspection={inspection} onCreateDefect={handleCreateDefectClick} onSelectInspection={handleInspectionClick} />
                )}
            </List>
        </div>
    );
};

const InspectionPanel = (props: IInspectionPanelProps): JSX.Element => {
    const { inspections, selectedInspection, hideNavButton, waterSourceCategory } = props;
    const { onClearInspection, onRefreshInspection, onSelectInspection, showCreateDefectDialog, showCreateInspectionDialog } = props;
    return (
        <div>
            {selectedInspection
                ? <InspectionControl selectedInspection={selectedInspection} waterSourceCategory={waterSourceCategory} hideNavButton={hideNavButton} onClose={onClearInspection} onRefresh={onRefreshInspection} />
                : <InspectionList
                    inspections={inspections}
                    onSelectInspection={onSelectInspection}
                    showCreateDefectDialog={showCreateDefectDialog}
                    showCreateInspectionDialog={showCreateInspectionDialog}
                />
            }
        </div>
    );
};

export type { IInspectionPanelProps };
export default InspectionPanel;