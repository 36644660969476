import React, { useState } from 'react';
import cx from "classnames";

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { NodeID, parseNodeId } from '../../../../lib/nodeIdentifier';
import { ObjectType } from '../../../../store/types';

interface ICreateNoteProps {
    readonly objectType: ObjectType;
    readonly objectNodeId: string;
    readonly onSaveNote?: (objectType: ObjectType, objectId: NodeID, content: string) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        paddingTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },
    form: {

    },
    label: {
        color: theme.palette.primary.contrastText,
        ...theme.typography.subtitle2
    },
    text: {
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textTransform: "none"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: theme.spacing(2)
    }
}));

const CreateNote = ({ objectType, objectNodeId, onSaveNote }: ICreateNoteProps): JSX.Element => {
    const styles = useStyles();

    const [content, setContent] = useState("");

    const clearInput = (): void => setContent("");

    const handleClearClick = (): void => {
        clearInput();
    };

    const handleSaveClick = (): void => {
        onSaveNote?.(objectType, parseNodeId(objectNodeId), content);
        clearInput();
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setContent(e.target.value);
    };

    return (
        <div className={styles.root}>
            <div className={styles.form}>
                <label htmlFor="note-content" className={cx("input__label", styles.label)}>New Note</label>
                <textarea
                    name="note-content"
                    id="note-content"
                    cols={1}
                    rows={5}
                    className={cx("input", styles.text)}
                    onChange={handleTextChange}
                    value={content}
                />
            </div>
            <div className={styles.buttonContainer}>
                <button id="save-note-button" className="action-button" disabled={!content} onClick={handleSaveClick}>Save Note</button>
                <button id="clear-note-button" className="action-button action-button--clear" onClick={handleClearClick}>Clear</button>
            </div>
        </div>
    )
}

export type { ICreateNoteProps };
export default CreateNote;
