import { Severity, Severities } from ".";

const fromValue = (i: number): Severity => {
    switch (i) {
        case 1: return Severities.HIGH
        case 2: return Severities.MEDIUM;
        case 3: return Severities.LOW;
        default: return Severities.UNKNOWN;
    }
}

export const mapSeverity = (value: number | undefined): Severity => value
    ? fromValue(value)
    : Severities.UNKNOWN;