import React from "react";
import { useDispatch } from "react-redux";
import { LocalDate } from "@js-joda/core";

import useSelector from "../../../../lib/hooks/useSelector";
import { NodeID, encodeNodeId } from "../../../../lib/nodeIdentifier";
import { guid } from "../../../../lib/Utils";
import { setDialogOpen, setGlobalToast } from "../../../../store/actions/app";
import {
    startCancelDefect,
    startCompleteDefect,
    startEditDefect
} from "../../../../store/actions/defects";
import { ICloseDefectVariables, ICancelDefectVariables, IUpdateDefectVariables } from "../../../../store/types";
import { SelectOption } from "../../../../components/labelledField";
import DefectControlBase, { IDefectControlProps } from "./defectControl.view";

const DefectControl = (props: Pick<IDefectControlProps, "selectedDefect" | "hideNavButton" | "onClose"> & { readonly onRefresh?: () => void }): JSX.Element => {
    const dispatch = useDispatch();
    const defectCancelReasons = useSelector(state => state.app.lookups?.defectCancelReasons ?? []);
    const defectCloseReasons = useSelector(state => state.app.lookups?.defectCloseReasons ?? []);
    const defectTypes = useSelector(state => state.app.lookups?.defectTypes ?? []);
    const fileStoreToken = useSelector(state => state.fileStore.token);

    const handleRefresh = (): void => {
        props.onRefresh?.();
    };

    const combined: IDefectControlProps = {
        ...props,
        defectCancelReasons,
        defectCloseReasons,
        defectTypes,
        fileStoreToken,
        onCancelDefect: async (defectId: NodeID, cancelReason: SelectOption): Promise<void> => {
            const input: ICancelDefectVariables = {
                defect: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(defectId),
                    data: {
                        cancelReasonId: Number(cancelReason.value)
                    }
                }
            };
            const action = startCancelDefect(input);
            await action(dispatch);
            handleRefresh();
        },
        onClearError: (): void => {
            dispatch(setGlobalToast());
        },
        onCompleteDefect: async (defectId: NodeID, result: SelectOption, inspectionId?: NodeID, isResolved?: boolean): Promise<void> => {
            const input: ICloseDefectVariables = {
                defect: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(defectId),
                    data: {
                        closeReasonId: Number(result.value),
                        inspectionId: inspectionId
                            ? encodeNodeId(inspectionId)
                            : undefined,
                        isResolved: isResolved
                    }
                }
            };
            const action = startCompleteDefect(input);
            await action(dispatch);
            handleRefresh();
        },
        onCreateRepair: (): void => {
            dispatch(setDialogOpen("createExternalRepair"));
        },
        onShowError: (message): void => {
            dispatch(setGlobalToast({
                type: "ERROR",
                message: message,
                showToast: true
            }));
        },
        onUpdateDefect: async (defectId: NodeID, dateReported: LocalDate, reportedBy: string, typeId: SelectOption, waterSourceId: NodeID, parentId?: SelectOption): Promise<void> => {
            const input: IUpdateDefectVariables = {
                defect: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(defectId),
                    data: {
                        dateReported,
                        reportedBy,
                        typeId: Number(typeId.value),
                        parentId: parentId
                            ? String(parentId.value)
                            : encodeNodeId(waterSourceId)
                    }
                }
            };
            const action = startEditDefect(input);
            await action(dispatch);
            handleRefresh();
        }
    };
    return <DefectControlBase {...combined} />;
};

export default DefectControl;