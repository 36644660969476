import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

type NavDirection = "prev" | "next";

interface InspectionNavButtonProps {
    readonly direction: NavDirection;
    readonly disabled?: boolean;
    readonly hide?: boolean;
    readonly onClick?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.grey[200],
        "&.Mui-disabled": {
            color: theme.palette.primary.main
        }
    },
}));

const getEdge = (direction: NavDirection): "start" | "end" => {
    switch (direction) {
        case "next": return "end";
        case "prev": return "start";
    }
};

const renderIcon = (direction: NavDirection): JSX.Element => {
    switch (direction) {
        case "next": return <NavigateNextIcon />;
        case "prev": return <NavigateBeforeIcon />;
    }
};

const InspectionNavButton = ({ direction, disabled, hide, onClick }: InspectionNavButtonProps): JSX.Element => {
    const styles = useStyles();
    if (hide) {
        return <React.Fragment />;
    }
    const edge = getEdge(direction);
    return (
        <IconButton className={styles.root} disabled={disabled} onClick={onClick} edge={edge}>
            {renderIcon(direction)}
        </IconButton>
    );
};

export type { InspectionNavButtonProps };
export default InspectionNavButton;