import React from "react";
import { useDispatch } from 'react-redux';

import { addTag, removeTag } from '../../store/actions/tags';
import TagsBase, { ITagInputProps } from './Tags';
import useSelector from '../../lib/hooks/useSelector';

const Tags = ({ ownerNodeId, ...rest }: Omit<ITagInputProps, "options" | "addTag" | "removeTag">): JSX.Element => {
    const dispatch = useDispatch();
    const options = useSelector(state => state.app.lookups?.tags ?? []);

    const props: ITagInputProps = {
        options: options.filter(tag => tag.filters.some(filter => filter.toUpperCase() === ownerNodeId.typeName.toUpperCase())) ?? [],
        ownerNodeId,
        ...rest,
        addTag: async (nodeId, tagId): Promise<void> => {
            const action = addTag(nodeId, [tagId]);
            await action(dispatch);
        },
        removeTag: async (nodeId, tagId): Promise<void> => {
            const action = removeTag(nodeId, [tagId]);
            await action(dispatch);
        },
    };

    return <TagsBase {...props} />;
};

export default Tags;
