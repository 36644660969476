import { Theme } from "@material-ui/core/styles";

export const $fuse = "#e73930";
export const $petrol = "#273c4e";
export const $spark = "#ffcf21";
export const $mist = "#a7a8aa";

export type ColourOption = "SPARK" | "FUSE" | "MIST" | "PETROL" | "DARKPETROL";

export const getRgb = (theme: Theme) =>
    (colour: ColourOption): string => {
        switch (colour) {
            case "FUSE":
                return $fuse;
            case "MIST":
                return $mist;
            case "PETROL":
                return theme.palette.primary.main;
            case "DARKPETROL":
                return theme.palette.primary.dark;
            case "SPARK":
                return theme.palette.secondary.main
        }
    };
