import React from 'react';
import { Icon } from '@blueprintjs/core';
import cx from "classnames";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import * as coords from "../../../lib/coordinates";
import { ILookupItem, isRoute, isScheme } from '../../../store/types';
import LabelledField from '../../labelledField';
import LocationMap from '../../locationMap';
import { CreateWaterSourceContext } from './utils';
import { ICreateWaterSourceDialogStateData, CreateWaterSourceStateDataKeys, CreateWaterSourcePropsTypes } from './createWaterSourceDialog';
import { StateValidators, validationStyle } from '../../../lib/validation';
import styles from './createWaterSourceDetails.module.scss';

interface ICreateWaterSourceDetailsProps {
    readonly categories: ILookupItem[]
    readonly classifications: ILookupItem[];
    readonly statuses: ILookupItem[];
    readonly stations: ILookupItem[];
    readonly context: CreateWaterSourceContext | undefined;
    readonly data: ICreateWaterSourceDialogStateData;
    readonly validators: StateValidators<ICreateWaterSourceDialogStateData>;
    readonly onChange: (key: CreateWaterSourceStateDataKeys, data: CreateWaterSourcePropsTypes) => void;
    readonly externalIdCustomValidation: string | undefined;
    readonly resetExternalIdValidation: () => void;
}

const formatEastingNorthing = (coordinates: [number, number] | undefined): string => {
    return coordinates
        ? coords.formatEastingsAndNorthings({ x: coordinates[0], y: coordinates[1] })
        : "";
};

const formatGridRef = (coordinates: [number, number] | undefined): string => {
    return coordinates
        ? coords.formatGridReference({ x: coordinates[0], y: coordinates[1] })
        : "";
};

const CreateWaterSourceDetails = (props: ICreateWaterSourceDetailsProps): JSX.Element => {
    const { externalIdCustomValidation } = props;
    // Remove decimals from location coordinates
    const location = formatEastingNorthing(props.data.location);
    const gridRef = formatGridRef(props.data.location);

    const scheme = isScheme(props.context) ? props.context : undefined;
    const route = isRoute(props.context) ? props.context : undefined;

    const handleChange = (key: keyof ICreateWaterSourceDialogStateData) => {
        return (value: ICreateWaterSourceDialogStateData[keyof ICreateWaterSourceDialogStateData]): void => {
            props.onChange(key, value);
        };
    };
    const handleClear = (key: keyof ICreateWaterSourceDialogStateData) => {
        return (): void => {
            props.onChange(key, undefined);
        };
    };

    const externalIdInfoTextClasses = cx([
        styles.externalIdInfoText,
        { [styles.externalIdInfoTextError]: externalIdCustomValidation }
    ])

    return (
        <React.Fragment>
            {scheme?.schemeNodeId && (
                <div className="input__group">
                    <label htmlFor="waterSource_SchemeTitle" className="input__label">Scheme</label>
                    <input
                        type="text"
                        placeholder="Scheme"
                        id="waterSource_SchemeTitle"
                        name="waterSource_SchemeTitle"
                        className="input"
                        value={scheme.title}
                        disabled={true}
                    />
                </div>
            )}
            {route?.routeNodeId && (
                <div className="input__group">
                    <label htmlFor="waterSource_RouteTitle" className="input__label">Route</label>
                    <input
                        type="text"
                        placeholder="Route"
                        id="waterSource_RouteTitle"
                        name="waterSource_RouteTitle"
                        className="input"
                        value={route.name}
                        disabled={true}
                    />
                </div>
            )}
            <LabelledField
                id="waterSource_externalId"
                label="Id"
                fieldType="text"
                editing
                classes={{ container: 'input__group', label: 'input__label', input: externalIdCustomValidation ? "invalid" : validationStyle(props.validators.externalId)(props.data.externalId) }}
                value={props.data.externalId}
                onChange={handleChange('externalId')}
                onFocus={props.resetExternalIdValidation}
            />
            <div className={externalIdInfoTextClasses}>
                <Icon icon={externalIdCustomValidation ? "error" : "info-sign"} />
                {externalIdCustomValidation ?? 'Enter an id for the water source, or leave empty for the system to assign one.'}
            </div>
            <div className={styles.locationContainer}>
                <div className={cx("input__group", styles.eastingNorthing)}>
                    <label htmlFor="waterSource_location" className="input__label">Location *</label>
                    <input
                        type="text"
                        disabled
                        placeholder="Location..."
                        id="waterSource_location"
                        name="waterSource_location"
                        className={`input ${validationStyle(props.validators.location)(props.data.location)}`}
                        value={location}
                    />
                </div>
                <Box display="flex" flexDirection="row">
                    <Typography variant="caption" className={styles.gridRef}>{gridRef}</Typography>
                </Box>
            </div>
            {!location && (
                <div className={styles['location-info-text']}>
                    <Icon icon="info-sign" />Click on the map below to set a location.
                </div>
            )}
            <LocationMap
                waterSourceStatus={props.data.status}
                selectedLocation={props.data.location}
                onChange={handleChange("location")}
            />
            <LabelledField
                id="waterSource_category"
                label="Category *"
                fieldType="select"
                editing
                selectOptions={props.categories}
                classes={{ container: 'input__group', label: 'input__label', input: validationStyle(props.validators.category)(props.data.category) }}
                value={props.data.category}
                onChange={handleChange('category')}
                onClear={handleClear('category')}
            />
            <LabelledField
                id="waterSource_class"
                label="Classification *"
                fieldType="select"
                editing
                selectOptions={props.classifications}
                classes={{ container: 'input__group', label: 'input__label', input: validationStyle(props.validators.classification)(props.data.classification) }}
                value={props.data.classification}
                onChange={handleChange('classification')}
                onClear={handleClear('classification')}
            />
            <LabelledField
                id="waterSource_status"
                label="Status *"
                fieldType="select"
                editing
                selectOptions={props.statuses}
                classes={{ container: 'input__group', label: 'input__label', input: validationStyle(props.validators.status)(props.data.status) }}
                value={props.data.status}
                onChange={handleChange('status')}
                onClear={handleClear('status')}
            />
            <LabelledField
                id="waterSource_stationId"
                label="Station *"
                fieldType="select"
                editing
                selectOptions={props.stations}
                classes={{ container: 'input__group', label: 'input__label', input: validationStyle(props.validators.station)(props.data.station) }}
                value={props.data.station}
                onChange={handleChange('station')}
                onClear={handleClear('station')}
            />
        </React.Fragment>
    );
};

export type { ICreateWaterSourceDetailsProps };
export default CreateWaterSourceDetails;
