import { v4 as guid } from "uuid";

import executeQuery from "../../../lib/executeQuery";
import editSearchMutation from '../../../store/actions/graphQL/editSavedSearch'
import { IEditMutation } from "../../../store/types";
import { SearchEntity } from "../searchSelector";
import { SelectedFilters } from "../types";
import { SearchMutationResult, SaveSearchInput, SearchMutations } from "./types";
import { makeResolveMutation } from "./utils";

const resolveMutation = makeResolveMutation("saveExisting");

const saveExistingSearch = async (searchPath: SearchEntity, nodeId: string, name: string, filters: SelectedFilters): Promise<SearchMutationResult> => {
    const variables: IEditMutation<SaveSearchInput> = {
        input: {
            clientMutationId: guid(),
            nodeId,
            data: {
                searchPath: searchPath,
                displayText: name,
                queryFilter: filters
            }
        }
    };
    return executeQuery<SearchMutations>(editSearchMutation, variables)
        .then(resolveMutation);
};

export default saveExistingSearch;