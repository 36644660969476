import React from "react";
import { List } from '@3tc/shared-components';

import { IDefect, IInspection, Repair } from "../../../store/types";
import Panel from "./Panel";
import PanelContainer from "./PanelContainer";
import ViewSelectedChild from "./ViewSelectedChild";

import styles from "./ViewWaterSource/ViewWaterSource.module.scss";

type ListItem<T> = Partial<T> & {
    readonly lastEventDescription: string;
    readonly typeDescription: string;
};

interface ListClickHandler<T> {
    (item: T): void;
}

interface ListConfig<T> {
    readonly titleKey: keyof ListItem<T>;
    readonly infoKey: keyof ListItem<T>;
    readonly titleLeftKey: keyof ListItem<T>;
    readonly titleAttrKeys: (keyof ListItem<T>)[];
    readonly clickHandler: (item: T) => void;
}

interface ConfigBuilder<T> {
    (onClick: (item: T) => void): ListConfig<T>;
}

interface ViewSelectedTabProps<T> {
    readonly config: ListConfig<T>;
    readonly items: ListItem<T>[];
    readonly child?: IDefect | IInspection | Repair;
    readonly onCloseItem?: () => void;
    readonly onRefresh?: () => void;
}

const ViewSelectedTab = <T extends {}>(props: ViewSelectedTabProps<T>): JSX.Element => {
    const { config, items, child, onCloseItem, onRefresh } = props;

    const handleClearClick = (): void => {
        onCloseItem?.()
    };
    const handleRefresh = (): void => {
        onRefresh?.();
    };

    return (
        <PanelContainer>
            <Panel edge="left">
                <List items={items} config={config} clickHandler={config.clickHandler} />
            </Panel>
            <Panel edge="right">
                {child && <button onClick={handleClearClick} className={`action-button action-button--cancel ${styles['clear-selected-button']}`}>Clear selected item</button>}
                <ViewSelectedChild child={child} onRefresh={handleRefresh} />
            </Panel>
        </PanelContainer>
    );
};


export type { ViewSelectedTabProps, ListItem, ListConfig, ListClickHandler, ConfigBuilder };
export default ViewSelectedTab;