import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

interface InspectionNavPositionProps {
    readonly itemCount: number;
    readonly itemIndex: number;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.primary.contrastText,
    }
}));

const InspectionNavPosition = ({ itemCount, itemIndex }: InspectionNavPositionProps): JSX.Element => {
    const styles = useStyles();
    if (itemCount) {
        return (
            <Typography variant="caption" classes={styles}>
                {`${itemIndex + 1} of ${itemCount}`}
            </Typography>
        )
    }
    return <React.Fragment />;
};

export type { InspectionNavPositionProps };
export default InspectionNavPosition;