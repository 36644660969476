import { encodeNodeId, NodeID, parseNodeId } from "../../lib/nodeIdentifier";
import { IAddress, AddressNode } from "./app";
import { IPoint } from "./geometry";

const getAonRangeNumber = (range: { number: number } | undefined): number | undefined =>
    range
        ? range.number
        : undefined;

const getAonRangeSuffix = (range: { suffix?: string } | undefined): string | undefined =>
    range
        ? range.suffix
        : undefined;

export const getAddressNodeId = (address: AddressNode | undefined): NodeID | undefined =>
    address?.addressNodeId
        ? parseNodeId(address.addressNodeId)
        : undefined;

export const getAddressId = (address: IAddress | undefined): number | undefined =>
    address
        ? address.addressId
        : undefined;

export const getSaonDescription = (address: IAddress | undefined): string | undefined =>
    address
        ? address.saon?.description
        : undefined;

export const getSaonStartRangeNumber = (address: IAddress | undefined): number | undefined =>
    address
        ? getAonRangeNumber(address.saon?.startRange)
        : undefined;

export const getSaonStartRangeSuffix = (address: IAddress | undefined): string | undefined =>
    address
        ? getAonRangeSuffix(address.saon?.startRange)
        : undefined;

export const getSaonEndRangeNumber = (address: IAddress | undefined): number | undefined =>
    address
        ? getAonRangeNumber(address.saon?.endRange)
        : undefined;

export const getSaonEndRangeSuffix = (address: IAddress | undefined): string | undefined =>
    address
        ? getAonRangeSuffix(address.saon?.endRange)
        : undefined;

export const getPaonDescription = (address: IAddress | undefined): string | undefined =>
    address
        ? address.paon?.description
        : undefined;

export const getPaonStartRangeNumber = (address: IAddress | undefined): number | undefined =>
    address
        ? getAonRangeNumber(address.paon?.startRange)
        : undefined;

export const getPaonStartRangeSuffix = (address: IAddress | undefined): string | undefined =>
    address
        ? getAonRangeSuffix(address.paon?.startRange)
        : undefined;

export const getPaonEndRangeNumber = (address: IAddress | undefined): number | undefined =>
    address
        ? getAonRangeNumber(address.paon?.endRange)
        : undefined;

export const getPaonEndRangeSuffix = (address: IAddress | undefined): string | undefined =>
    address
        ? getAonRangeSuffix(address.paon?.endRange)
        : undefined;

export const getStreetDescription = (address: IAddress | undefined): string | undefined =>
    address
        ? address.streetDescription
        : undefined;

export const getLocality = (address: IAddress | undefined): string | undefined =>
    address
        ? address.locality
        : undefined;

export const getTown = (address: IAddress | undefined): string | undefined =>
    address
        ? address.town
        : undefined;

export const getPostCode = (address: IAddress | undefined): string | undefined =>
    address
        ? address.postCode
        : undefined;

export const getUprn = (address: IAddress | undefined): number | undefined =>
    address
        ? address.uprn
        : undefined;

export const getCoordinates = (address: IAddress | undefined): IPoint | undefined =>
    address
        ? address.coordinates
        : undefined;

export const getAddressNode = (source: IAddress | undefined): AddressNode | undefined => 
    source?.addressId
            ? {
                ...source,
                addressNodeId: encodeNodeId(NodeID("Address", source.addressId))
            }
            : undefined;
