import React from "react";

import { withStyles } from "@material-ui/core/styles";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

import MoreIcon from '@material-ui/icons/MoreVert';

const WaterSourceActionMenuAnchor = withStyles(theme => ({
    root: {
        color: theme.palette.grey[200]
    }
}))((props: IconButtonProps) => (
    <IconButton edge="end" {...props}>
        <MoreIcon />
    </IconButton>
));

export default WaterSourceActionMenuAnchor;