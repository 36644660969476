import React, { useMemo, useState } from "react";
import { LoadingBar, Pagination } from "@3tc/shared-components";

import { useWaterSource, WaterSourceOptions } from "../../components/appWindowContext/AppWindowContext";
import useNotifications from "../../components/notifications/hooks/useNotifications";
import Notification from '../../components/notifications/components/notificationItem';
import Typography from "../../components/shared/typography";
import { parseNodeId } from "../../lib/nodeIdentifier";
import { WaterSourceTab } from "../../router/DesktopRouter/hooks/useAppWindowParams";
import styles from './NotificationsPage.module.scss';

const defaultPageIndex = 1;
const defaultPageSize = 50;

const parseOptions = (options?: { child: string, tab: "DEFECTS" | "INSPECTIONS" }): WaterSourceOptions | undefined => {
    const defaultOptions: WaterSourceOptions = { focusMap: true };
    if (options) {
        const { child, tab } = options;
        switch (tab) {
            case "DEFECTS":
                return { ...defaultOptions, tab: WaterSourceTab.DEFECTS };
            case "INSPECTIONS":
                return { ...defaultOptions, tab: WaterSourceTab.INSPECTIONS, inspection: parseNodeId(child) };
        }
    }
    return defaultOptions;
};

const NotificationsPage = (): JSX.Element => {
    const { change: showWaterSource } = useWaterSource({ newWindow: true });
    const [pageIndex, setPageIndex] = useState(defaultPageIndex);
    const [status, notifications] = useNotifications(pageIndex, defaultPageSize);

    const { items, totalCount, pageInfo: { pageCount, pageNumber } } = notifications;

    const handleFirstClick = (): void => {
        setPageIndex(defaultPageIndex);
    };

    const handleLastClick = (): void => {
        setPageIndex(pageCount);
    };

    const handleNextClick = (): void => {
        setPageIndex(pageNumber + 1);
    };

    const handlePreviousClick = (): void => {
        setPageIndex(pageNumber - 1);
    };

    const handleShowOnMap = (waterSourceId: string, options?: { child: string, tab: "DEFECTS" | "INSPECTIONS" }): void => {
        showWaterSource(parseNodeId(waterSourceId), parseOptions(options));
    };

    const isLoading = useMemo(() => status !== "complete", [status]);

    const PageControl = (): JSX.Element => (
        <Pagination
            tableContent={items}
            totalResults={totalCount}
            pageNumber={pageNumber}
            pageCount={pageCount}
            firstPageClickHandler={handleFirstClick}
            lastPageClickHandler={handleLastClick}
            nextPageClickHandler={handleNextClick}
            previousPageClickHandler={handlePreviousClick}
        />
    );

    return (
        <div className={styles.root}>
            <Typography scale="h4">Notifications</Typography>
            <div className={styles.container}>
                {isLoading
                    ? <LoadingBar loading={true} />
                    : <React.Fragment>
                        <div className={styles.topPageControlContainer}>
                            <PageControl />
                        </div>
                        <ul className={styles.list}>
                            {items.map(notification => <Notification key={notification.id} notification={notification} onShowOnMapClick={handleShowOnMap} />)}
                        </ul>
                        <PageControl />
                    </React.Fragment>

                }
            </div>
        </div>
    );
};

export default NotificationsPage;