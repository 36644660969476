import React from "react";
import { Link } from "react-router-dom";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { NodeID, parseNodeId } from "../../../../../lib/nodeIdentifier";
import { IScheme } from "../../../../../store/types";

interface SchemeLinkProps {
    readonly scheme: IScheme;
    readonly id?: string;
    readonly onClick?: (nodeId: NodeID) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        border: "none",
        color: theme.palette.common.white,
        fontSize: "14px",
        fontWeight: "bold",
        marginBottom: theme.spacing(0.5),
        textDecoration: "unset",
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
}));

const SchemeLink = ({ scheme, id, onClick }: SchemeLinkProps): JSX.Element => {
    const styles = useStyles();
    const { schemeId, schemeNodeId, title, stage } = scheme;
    const text = `${schemeId} - ${title} (${stage.isOpen ? "Open" : "Closed"})`;
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        onClick?.(parseNodeId(schemeNodeId));
    };
    return <Link id={id} className={styles.root} to={"#"} onClick={handleClick}>{text}</Link>;
};

export type { SchemeLinkProps };
export default SchemeLink;