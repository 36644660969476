import React from "react";

import Box from "@material-ui/core/Box";

interface SchemeButtonsProps {
    readonly editing?: boolean;
    readonly onEditing?: () => void;
    readonly onSave?: () => void;
    readonly onCancel?: () => void;
}

const SchemeButtons = (props: SchemeButtonsProps): JSX.Element => {
    const handleSaveClick = (): void => {
        props.onSave?.();
    };

    const handleCancelClick = (): void => {
        props.onCancel?.();
    };

    const handleEditClick = (): void => {
        props.onEditing?.();
    };

    return (
        <Box display="inline-flex" flexDirection="row">
            {props.editing
                ? (
                    <React.Fragment>
                        <button id="save-button" className="action-button" onClick={handleSaveClick}>Save</button>
                        <button id="cancel-button" className="action-button action-button--cancel" onClick={handleCancelClick}>Cancel</button>
                    </React.Fragment>
                )
                : <button id="edit-button" className="action-button" onClick={handleEditClick}>Edit</button>
            }
        </Box>
    );
};

export type { SchemeButtonsProps };
export default SchemeButtons;