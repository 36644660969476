import { LocalDateTime } from '@js-joda/core';
import { ILookupItem } from '../store/types/app';

export interface IMovedWaterSource {
    waterSourceNodeId: string;
    location: [number, number];
}

export interface ILifecycleEvent {
    readonly action: string;
    readonly actor: string;
    readonly timestamp: LocalDateTime;
}

export const getEvent = (events: ILifecycleEvent[], action: string): ILifecycleEvent => {
    const created = events.find(e => e.action === action);
    if (created) {
        return created;
    }
    throw new Error("Cannot find `created` lifecycle event.");
};

export const getLookupItemText = (item?: ILookupItem): string | undefined => {
    return item
        ? item.displayText
        : undefined;
}

export const getLookupItemValue = (item?: ILookupItem): number | undefined => {
    return item
        ? item.value
        : undefined;
}

export const isLookupItemEqual = (a?: ILookupItem, b?: ILookupItem): boolean => {
    return a && b
        ? a.value === b.value
        : false;
}
