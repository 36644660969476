import React, { useState, useEffect } from "react";
import { Input, LoadingBar } from "@3tc/shared-components";

import { jsonToCsv, downloadCsvFile } from "../../../lib/csv";
import { formatText, longDateFormatter } from "../../../lib/Utils";
import { ILookups, IConnection, ISavedSearch, IQueryControl } from "../../../store/types";
import ActionButtons from "../actionButtons";
import Filter from "../filter";
import FilterResultsTable from "../filterResultsTable";
import SearchSelectorContext from "../searchSelector/searchSelectorContext";
import { querySchemesByFilter } from "./query";
import { SchemeSearchItem, SchemeSelectedFilters, SchemeSearchFilters } from "./types";
import { buildQueryFilter, buildSchemeFilters } from "./utils";
import styles from "./schemeSearch.module.scss";
import { ITableHeader } from "../types";
import { useTablePaging } from "../hooks";
import { SortDirections } from "../filterResultsTable/components/results";

interface SchemeSearchProps {
    readonly lookups: ILookups;
    readonly hideToast: () => void;
    readonly showError: (message: string, error?: Error) => void;
    readonly showLoading: (message: string) => void;
    readonly showSchemeOnMap: (scheme: SchemeSearchItem) => void;
    readonly showSuccess: (message: string) => void;
}

const tableHeaders: ITableHeader[] = [
    {
        displayText: "Scheme Id",
        sortBy: "id"
    },
    {
        displayText: "Type",
        sortBy: "type"
    },
    {
        displayText: "Reference",
        sortBy: "reference"
    },
    {
        displayText: "Title",
        sortBy: "title"
    },
    {
        displayText: "Station",
        sortBy: "station"
    },
    {
        displayText: "Water Sources",
        sortBy: "waterSourceCount"
    },
    {
        displayText: "Stage",
        sortBy: "stage"
    },
    {
        displayText: "Date Received",
        sortBy: "dateReceived"
    },
    {
        displayText: "Date Returned",
        sortBy: "dateReturned"
    },
    {
        displayText: "Date Ordered",
        sortBy: "dateOrdered"
    },
    {
        displayText: "Invoice Date Due",
        sortBy: "invoiceDueDate"
    },
    {
        displayText: "Date Closed",
        sortBy: "dateClosed"
    }
];
const tableRows = (schemes: SchemeSearchItem[]): string[][] => {
    return schemes.map(scheme => {
        return [
            scheme.schemeId.toString(),
            scheme.type?.displayText ?? "",
            scheme.reference,
            scheme.title,
            scheme.station?.displayText ?? "",
            scheme.waterSourceCount?.toString() ?? "",
            scheme.stage?.displayText ?? "",
            scheme.dateReceived?.format(longDateFormatter) ?? "",
            scheme.dateReturned?.format(longDateFormatter) ?? "",
            scheme.dateOrdered?.format(longDateFormatter) ?? "",
            scheme.invoiceDueDate?.format(longDateFormatter) ?? "",
            scheme.dateClosed?.format(longDateFormatter) ?? ""
        ];
    });
};

const empty: IConnection<SchemeSearchItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "id",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "id",
    sortDirection: "ASCENDING"
};

const querySchemes = async (selectedFilters: SchemeSelectedFilters, paging: IQueryControl): Promise<IConnection<SchemeSearchItem>> => {
    const filter = buildQueryFilter(selectedFilters);
    return querySchemesByFilter(filter, paging);
};

const SchemeSearch = (props: SchemeSearchProps): JSX.Element => {
    const { hideToast, showError, showLoading, showSchemeOnMap } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<SchemeSelectedFilters>({});
    const [page, setPage] = useState<IConnection<SchemeSearchItem>>(empty);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            showLoading("Getting schemes...");
            querySchemes(selectedFilters, paging)
                .then(schemes => setPage(schemes))
                .catch(() => showError("Error searching for schemes"))
                .finally(() => {
                    setIsLoading(false);
                    hideToast();
                });
        }
    }, [paging]);

    const handleFilterChange = (value: string): void => {
        if (Object.keys(selectedFilters).indexOf(value) > -1) {
            const update = { ...selectedFilters };
            delete update[value];
            setSelectedFilters(update);
            return;
        }
        setSelectedFilters({ ...selectedFilters, [value]: [] });
    };

    const handleClearClick = (): void => {
        setIsLoading(true);
        setSelectedFilters({});
        setPage(empty);
        setIsLoading(false);
    };

    const handleExportClick = (): void => {
        querySchemes(selectedFilters, { pageIndex: 1, pageSize: totalCount, sortBy: "id", sortDirection: SortDirections.ASCENDING })
            .then(schemes => {
                if (schemes) {
                    const dataToExport = schemes.items.map(scheme => ({
                        schemeId: scheme.schemeId,
                        type: scheme.type,
                        reference: scheme.reference,
                        title: scheme.title,
                        station: scheme.station,
                        waterSourceCount: scheme.waterSourceCount,
                        stage: scheme.stage,
                        dateReceived: scheme.dateReceived,
                        dateReturned: scheme.dateReturned,
                        dateOrdered: scheme.dateOrdered,
                        invoiceDateDue: scheme.invoiceDueDate,
                        dateClosed: scheme.dateClosed
                    }))
                    const csvData = jsonToCsv(dataToExport);
                    downloadCsvFile(csvData);
                } else {
                    showError("Error exporting water sources to CSV.")
                }
            })
            .catch(error => showError("Error exporting water sources to CSV.", error))
            .finally(hideToast);
    };

    const handleRowClick = (id: string): void => {
        const scheme = items.find(item => item.schemeId.toString() === id);
        if (scheme) {
            showSchemeOnMap(scheme);
        }
    };

    const handleChangeSelection = (selected: ISavedSearch | undefined): void => {
        setSelectedSearch(selected);
    };

    useEffect(() => {
        if (selectedSearch) {
            setSelectedFilters(selectedSearch.queryFilter);
        }
    }, [selectedSearch, setSelectedFilters]);

    const schemeFilters: SchemeSearchFilters = buildSchemeFilters(props.lookups);

    return (
        <SearchSelectorContext entity="schemes" selectedFilters={selectedFilters} onChangeSelection={handleChangeSelection}>
            <div className={styles.allInputs}>
                <div className={styles.inputContainer}>
                    <Input
                        type="multilist"
                        id="selected-filters-list"
                        labelText="Search On"
                        isEditing
                        value={Object.keys(selectedFilters)}
                        responseOptions={Object.keys(schemeFilters).map(item => ({
                            value: item,
                            text: formatText(item, true, undefined, true)
                        }))}
                        onChangeHandler={handleFilterChange}
                    />
                </div>
                {Object.keys(selectedFilters).map(filter => (
                    <Filter
                        key={filter}
                        name={filter}
                        options={schemeFilters[filter]}
                        selected={selectedFilters}
                        onChangeSelection={setSelectedFilters}
                    />
                ))}
            </div>
            <ActionButtons
                totalCount={totalCount}
                onClear={handleClearClick}
                onExport={handleExportClick}
                onSearch={handleSearchClick}
            />
            {isLoading
                ? <LoadingBar loading />
                : <FilterResultsTable
                    headers={tableHeaders}
                    items={tableRows(items)}
                    pageInfo={pageInfo}
                    totalCount={totalCount}
                    onFirstClick={handleFirstClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onLastClick={handleLastClick}
                    onTableHeaderClick={handleTableHeaderClick}
                    onTableRowClick={handleRowClick}
                />
            }
        </SearchSelectorContext>
    );
};

export type { SchemeSearchProps };
export default SchemeSearch;