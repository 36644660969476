import React, { useState } from "react";

import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";

import AppDialog, { AppDialogInput } from "../../../components/shared/appDialog";
import { INote, ObjectType } from "../../../store/types";
import NoteList from "../../../components/notes/components/noteList";
import { createNote } from "../../../store/actions/notes";
import { bindComparers } from "../../../lib/comparers";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { useDispatch } from "react-redux";

interface NotesDetails {
    readonly showDialogue: boolean;
    readonly objectType: ObjectType;
    readonly notes: INote[];
    readonly objectNodeId?: string;
    readonly withAddNote?: boolean;
    readonly closeNotesDetails: () => void;
    readonly onRefresh?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    noNotes: {
        color: theme.palette.grey[900],
        margin: "15px"
    }
}));

const orderBy = bindComparers<INote>([
    (a, b): number => b.timestamp.toLocalDate().compareTo(a.timestamp.toLocalDate()),
    (a, b): number => b.noteId - a.noteId
]);

const NotesDetails = (props: NotesDetails): JSX.Element => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const { showDialogue, objectType, notes, objectNodeId, withAddNote, closeNotesDetails, onRefresh } = props;
    const [showAddNote, setShowAddNote] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");

    const handleOpenAddNote = (): void => {
        setNote("");
        setShowAddNote(true);
    };
    const handleCloseAddNote = (): void => setShowAddNote(false);

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;
        setNote(value);
    };

    const handleSaveNote = async (): Promise<void> => {
        if (objectNodeId) {
            const action = createNote(parseNodeId(objectNodeId), note);
            await action(dispatch);
            onRefresh?.();
        }
        handleCloseAddNote();
    };

    const addNoteButton = withAddNote && objectNodeId ? <Button onClick={handleOpenAddNote}>Add</Button> : <React.Fragment />;
    const saveNoteButton = <Button onClick={handleSaveNote}>Save</Button>;

    const sortedNotesByTimestamp = notes.sort(orderBy);

    return showAddNote
        ? (
            <AppDialog open={showAddNote} appBar={{ title: "Add Note" }} onClose={handleCloseAddNote} commit={saveNoteButton}>
                <AppDialogInput value={note} id="addDefect-note" label="Notes" multiline rows={3} onChange={handleNoteChange} />
            </AppDialog>
        )
        : (
            <AppDialog open={showDialogue} onClose={closeNotesDetails} appBar={{ title: "Notes" }} commit={addNoteButton}>
                {sortedNotesByTimestamp.length
                    ? <NoteList objectType={objectType} notes={sortedNotesByTimestamp} hideActions isDark canDeleteNotes={false} canEditNotes={false} />
                    : <Typography className={styles.noNotes}>No Notes to View</Typography>
                }
            </AppDialog>
        );
};

export default NotesDetails;