import React, { useMemo, useEffect } from "react";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import ListIcon from "@material-ui/icons/List";
import MapIcon from "@material-ui/icons/Map";

import { useInspectionsForTechnician } from "../../api/hooks";
import { useTechnician } from "../../auth/hooks";
import InspectionList from "../../components/inspectionList";
import InspectionMap from "../../components/inspectionMap";
import UnregisteredTechnicianItem from "../../components/shared/unregisteredTechnicianItem";
import View, { NavBackButton } from "../../components/shared/view";
import LoadingContainer from "../../components/shared/loadingContainer";
import TabPanel from "../../components/tabs/TabPanel";
import { ViewOptions, IEdge, IInspection, emptyConnection } from "../../store/types";
import useStyles from "./InspectionListView.styles";
import { group, toGroupItems } from "../../lib/collections";
import { buildComparer } from "../../lib/comparers/inspections";
import useInspectionsParams from "../../router/MobileRouter/hooks/useInspectionsParams";

interface InspectionListViewProps {
    readonly view: ViewOptions;
    readonly onInspectionsLoaded?: (inspections: IInspection[]) => void;
    readonly onSelectView?: (value: ViewOptions | null) => void;
}

const groupInspections = (node: IInspection): string => node.type.displayText;

const nodesHash = (edges: IEdge<IInspection>[]): string => {
    const s = edges.map(edge => edge.node.inspectionId).join(",");
    const h = btoa(s);
    return h;
};

const empty = emptyConnection<IInspection>();

const InspectionListView = (props: InspectionListViewProps): JSX.Element => {
    const { view, onInspectionsLoaded, onSelectView } = props;

    const styles = useStyles();
    const technician = useTechnician();
    const params = useInspectionsParams();

    const [status, inspections, refresh] = useInspectionsForTechnician(technician, params);

    const { edges, totalCount } = inspections ?? empty;
    const hash = nodesHash(edges)

    const handleViewChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: ViewOptions | null): void => {
        onSelectView?.(value);
    };

    const handleViewUpdate = (): void => {
        refresh();
    };

    const actions = (
        <ToggleButtonGroup key={0} value={view} exclusive onChange={handleViewChange} >
            <ToggleButton value={ViewOptions.LIST} classes={{ root: styles.viewButton }}>
                <ListIcon />
            </ToggleButton>
            <ToggleButton value={ViewOptions.MAP} classes={{ root: styles.viewButton }}>
                <MapIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );

    const loading = useMemo(() => status !== "complete", [status]);
    const category = useMemo(() => params?.category ?? "PLANNED", [params?.category]);
    const groupItems = useMemo(() => {
        const comparer = buildComparer(category);
        const nodes = edges.map(edge => edge.node);
        const groups = group(nodes, groupInspections, comparer);
        return toGroupItems(groups).sort((a, b) => a.label.localeCompare(b.label));
    }, [edges, hash, category]);

    useEffect(() => {
        onInspectionsLoaded?.(groupItems.flatMap(group => group.items));
    }, [groupItems, onInspectionsLoaded])

    return (
        <View backButton secondary={actions} onRefreshClick={handleViewUpdate}>
            {technician
                ? (
                    <LoadingContainer isLoading={loading}>
                        <TabPanel index={ViewOptions.LIST} value={view} noMargins>
                            <InspectionList groups={groupItems} totalCount={totalCount} />
                        </TabPanel>
                        <TabPanel index={ViewOptions.MAP} value={view} noMargins>
                            <InspectionMap groups={groupItems} totalCount={totalCount} />
                        </TabPanel>
                    </LoadingContainer>
                )
                : <UnregisteredTechnicianItem />
            }
        </View>
    );
};

export default InspectionListView;