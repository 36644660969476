import React from "react";

import { styled } from "@material-ui/core/styles";
import Typography, { TypographyTypeMap } from "@material-ui/core/Typography";

import { formatText } from "../../../../lib/Utils";
import { PropsWithRepair } from "../types";

const RepairCategory = styled(({ repair, ...rest }: PropsWithRepair<Omit<TypographyTypeMap["props"], "variant">>): JSX.Element => {
    return (
        <Typography variant="body2" {...rest}>
            {`${repair.repairId}: ${formatText(repair.repairCategory.displayText, true, "-")}`}
        </Typography>
    );
})(({ theme }) => ({
    color: theme.palette.secondary.main
}));

export default RepairCategory;