import { IMeasurement } from "./app";
import { getLookupItemText, getLookupItemValue } from "../../lib/Model";

export const getMeasurementValue = (measurement: IMeasurement | undefined): number | undefined =>
    measurement
        ? measurement.value
        : undefined;

export const getMeasurementUnitDescription = (measurement: IMeasurement | undefined): string | undefined =>
    measurement
        ? getLookupItemText(measurement.unit)
        : undefined;

export const getMeasurementUnitValue = (measurement: IMeasurement | undefined): number | undefined =>
    measurement
        ? getLookupItemValue(measurement.unit)
        : undefined;