import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import Login from "./components/utils/Login";
import AccessDenied from "./components/utils/AccessDenied";

import getUserSubscriptions from './lib/getUserSubscriptions';
import UserSubscriptionsPage from './components/pages/UserSubscriptionsPage';
import { getUser } from './lib/getUser'

const root = document.getElementById("root");

getUserSubscriptions().then(async data => {
    if (data) {
        ReactDOM.render(<UserSubscriptionsPage userSubscriptions={data} />, root);
    } else {
        getUser();
    }
}).catch((err) => {
    console.error(err);
    if (window.location.pathname === "/access-denied") {
        ReactDOM.render(<AccessDenied />, root);
        return;
    }

    // assume error is due to user not being logged in, so render login page
    ReactDOM.render(<Login />, root);
    return;
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
