import React, { useState, useEffect } from "react";
import { Input, LoadingBar } from "@3tc/shared-components";

import { ILookups, IConnection, ISavedSearch, IQueryControl } from "../../../store/types";
import { jsonToCsv, downloadCsvFile } from "../../../lib/csv";
import { formatText, longDateFormatter } from "../../../lib/Utils";
import ActionButtons from "../actionButtons";
import Filter from "../filter";
import FilterResultsTable from "../filterResultsTable";
import SearchSelectorContext from "../searchSelector/searchSelectorContext";
import { formatYesNo } from "../utils";
import { queryDefectsByFilter } from "./query";
import { IDefectSelectedFilters, IDefectSearchFilters, DefectSearchItem } from "./types";
import { buildQueryFilter, buildDefectFilters } from "./utils";
import styles from "./defectSearch.module.scss";
import { ITableHeader } from "../types";
import { useTablePaging } from "../hooks";
import { SortDirections } from "../filterResultsTable/components/results";

interface IDefectSearchProps {
    readonly lookups: ILookups;
    readonly hideToast: () => void;
    readonly showDefectOnMap: (defect: DefectSearchItem) => void;
    readonly showError: (message: string, error?: Error) => void;
    readonly showLoading: (message: string) => void;
    readonly showSuccess: (message: string) => void;
}

const tableHeaders: ITableHeader[] = [
    {
        displayText: "Defect",
        sortBy: "id"
    },
    {
        displayText: "Water Source",
        sortBy: "waterSource"
    },
    {
        displayText: "Type",
        sortBy: "type"
    },
    {
        displayText: "Reported On",
        sortBy: "dateReported"
    },
    {
        displayText: "Reported By",
        sortBy: "reportedBy"
    },
    {
        displayText: "Open",
        sortBy: "isOpen"
    },
    {
        displayText: "Date Closed",
        sortBy: "dateClosed"
    },
    {
        displayText: "Closed By",
        sortBy: "closedBy"
    },
    {
        displayText: "Operable",
        sortBy: "isOperable"
    },
];
const tableRows = (defects: DefectSearchItem[]): string[][] => {
    return defects.map(defect => {
        return [
            defect.defectId.toString(),
            defect.waterSource.waterSourceId.toString(),
            defect.type.displayText,
            defect.dateReported.format(longDateFormatter),
            defect.reportedBy,
            formatYesNo(defect.isOpen),
            defect.dateClosed?.format(longDateFormatter) ?? "",
            defect.closedBy ?? "",
            formatYesNo(defect.isOperable),
        ];
    });
}

const empty: IConnection<DefectSearchItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "id",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "id",
    sortDirection: "ASCENDING"
};

const queryDefects = async (selectedFilters: IDefectSelectedFilters, paging: IQueryControl): Promise<IConnection<DefectSearchItem>> => {
    const filter = buildQueryFilter(selectedFilters);
    return queryDefectsByFilter(filter, paging);
};

const DefectSearch = (props: IDefectSearchProps): JSX.Element => {
    const { hideToast, showError, showDefectOnMap, showLoading } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<IDefectSelectedFilters>({});
    const [page, setPage] = useState<IConnection<DefectSearchItem>>(empty);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            showLoading("Getting defects...");
            queryDefects(selectedFilters, paging)
                .then(defects => setPage(defects))
                .catch(() => showError("Error searching for defects."))
                .finally(() => {
                    setIsLoading(false);
                    hideToast();
                });
        }
    }, [paging]);

    const handleFilterChange = (value: string): void => {
        if (Object.keys(selectedFilters).indexOf(value) > -1) {
            const update = { ...selectedFilters };
            delete update[value];
            setSelectedFilters(update);
            return;
        }
        setSelectedFilters({ ...selectedFilters, [value]: [] });
    };

    const handleClearClick = (): void => {
        setIsLoading(true);
        setSelectedFilters({});
        setPage(empty);
        setIsLoading(false);
    };

    const handleExportClick = (): void => {
        showLoading("Downloading CSV.");
        queryDefects(selectedFilters, { pageIndex: 1, pageSize: totalCount, sortBy: "defect", sortDirection: SortDirections.ASCENDING })
            .then(waterSources => {
                if (waterSources) {
                    const csvData = jsonToCsv(waterSources.items.map(item => ({
                        defectId: item.defectId,
                        waterSource: item.waterSource,
                        type: item.type,
                        dateReported: item.dateReported,
                        reportedBy: item.reportedBy,
                        isOpen: item.isOpen,
                        dateClosed: item.dateClosed,
                        closedBy: item.closedBy,
                        isOperable: item.isOperable,
                    })));
                    downloadCsvFile(csvData);
                } else {
                    showError("Error exporting water sources to CSV.")
                }
            })
            .catch(error => showError("Error exporting water sources to CSV.", error))
            .finally(hideToast);
    };

    const handleRowClick = (id: string): void => {
        const defect = items.find(item => item.defectId.toString() === id);
        if (defect) {
            showDefectOnMap(defect);
        }
    };

    const handleChangeSelection = (selected: ISavedSearch | undefined): void => {
        setSelectedSearch(selected);
    };

    useEffect(() => {
        if (selectedSearch) {
            setSelectedFilters(selectedSearch.queryFilter);
        }
    }, [selectedSearch, setSelectedFilters]);

    const defectFilters: IDefectSearchFilters = buildDefectFilters(props.lookups);

    return (
        <SearchSelectorContext entity="defects" selectedFilters={selectedFilters} onChangeSelection={handleChangeSelection}>
            <div className={styles.allInputs}>
                <div className={styles.inputContainer}>
                    <Input
                        type="multilist"
                        id="selected-filters-list"
                        labelText="Search On"
                        isEditing
                        value={Object.keys(selectedFilters)}
                        responseOptions={Object.keys(defectFilters).map((item: string) => ({
                            value: item,
                            text: formatText(item, true, undefined, true)
                        }))}
                        onChangeHandler={handleFilterChange}
                    />
                </div>
                {Object.keys(selectedFilters).map(filter => (
                    <Filter
                        key={filter}
                        name={filter}
                        options={defectFilters[filter]}
                        selected={selectedFilters}
                        onChangeSelection={setSelectedFilters}
                    />
                ))}
            </div>
            <ActionButtons
                totalCount={totalCount}
                onClear={handleClearClick}
                onExport={handleExportClick}
                onSearch={handleSearchClick}
            />
            {isLoading
                ? <LoadingBar loading={isLoading} />
                : <FilterResultsTable
                    headers={tableHeaders}
                    items={tableRows(items)}
                    pageInfo={pageInfo}
                    totalCount={totalCount}
                    onFirstClick={handleFirstClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onLastClick={handleLastClick}
                    onTableHeaderClick={handleTableHeaderClick}
                    onTableRowClick={handleRowClick}
                />
            }
        </SearchSelectorContext>
    );
};

export type { IDefectSearchProps };
export default DefectSearch;