import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
    IGlobalToast,
    IDefectAction,
    IDefect,
    IAddDefectVariables,
    ICloseDefectVariables,
    IDefectState,
    INote,
    IDefectSearchItem,
    IConnection,
    ICancelDefectVariables,
    IUpdateDefectVariables
} from '../types';
import { setGlobalToast, setLoadingToast } from './app';
import { setError } from './errors';
import { setRefreshInspection } from './inspections';
import executeQuery from '../../lib/executeQuery';

import getDefectQuery from './graphQL/getDefect';
import getDefectsForWaterSourceQuery from './graphQL/getDefectsForWaterSource';
import cancelDefectMutation from './graphQL/cancelDefect';
import closeDefectMutation from './graphQL/closeDefect';
import createDefectMutation from './graphQL/createDefect';
import editDefectMutation from './graphQL/editDefect';
import getDefectsByFilterQuery from "./graphQL/getDefectsByFilterQuery";
import { updateWaterSourceOperability } from './waterSources';

export const addDefect = (newDefect: IDefect): IDefectAction => ({
    type: 'ADD_DEFECT',
    newDefect
});

export const addPaginatedDefects = (page: IConnection<IDefectSearchItem>): IDefectAction => ({
    type: "SET_PAGINATED_DEFECTS",
    defectPage: page
});

export const clearPaginatedDefects = (): IDefectAction => ({
    type: "CLEAR_PAGINATED_DEFECTS"
});

export const clearDefects = (): IDefectAction => ({
    type: 'CLEAR_DEFECTS'
});

export const editDefect = (editedDefect: IDefect): IDefectAction => ({
    type: 'EDIT_DEFECT',
    editedDefect
});

export const setCreateDefectSuccess = (createDefectSuccess?: boolean): IDefectAction => ({
    type: 'SET_CREATE_DEFECT_SUCCESS',
    createDefectSuccess
});

export const setDefects = (defects: IDefect[]): IDefectAction => ({
    type: 'SET_DEFECTS',
    defects
});

export const setEditDefectSuccess = (editDefectSuccess?: boolean): IDefectAction => ({
    type: 'SET_EDIT_DEFECT_SUCCESS',
    editDefectSuccess
});

export const setReviewedDefects = (reviewed: string[], notReviewed: string[]): IDefectAction => ({
    type: 'SET_REVIEWED_DEFECTS',
    reviewedDefects: [reviewed, notReviewed]
});

export const setSelectedDefect = (selectedDefect?: IDefect): IDefectAction => ({
    type: 'SET_SELECTED_DEFECT',
    selectedDefect
});

export const getDefect = (variables: { id: string }, setLoading = true) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast('Loading defect data...'));
            }

            const data = await executeQuery<{ node: IDefect | undefined }>(getDefectQuery, variables);
            dispatch(setSelectedDefect(data?.node));

            if (setLoading) {
                dispatch(setGlobalToast());
            }
        } catch (err) {
            dispatch(setError('Error getting defect', err));
        }
    }
}

interface IDefectSearchQuery {
    readonly defects: IConnection<IDefectSearchItem>;
}

const queryDefectsByFilter = async (query: string, filter: Record<string, unknown>, pageIndex: number, pageSize: number): Promise<IDefectSearchQuery | undefined> => {
    const input = {
        filter: filter,
        control: {
            pageIndex: pageIndex,
            pageSize: pageSize
        }
    };
    const response = await executeQuery<IDefectSearchQuery>(query, input);
    return response;
};

export const getDefectsByFilter = (filter: Record<string, unknown>, pageIndex: number, pageSize: number) =>
    async (dispatch: ThunkDispatch<IDefectState, unknown, AnyAction>): Promise<void> => {
        try {
            const response = await queryDefectsByFilter(getDefectsByFilterQuery, filter, pageIndex, pageSize);
            if (response) {
                const page = response.defects;
                dispatch(addPaginatedDefects(page));
            }
            else {
                dispatch(setError("Error getting defects.", new Error("API response missing.")))
            }
        }
        catch (err) {
            dispatch(setError("Error getting defects", err));
        }
    };

export const getDefectsForWaterSource = (variables: { id: string }, setLoading = true) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<IDefect[]> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast("Getting defects for water source..."));
            }

            const res = await executeQuery<{ node: { defects: IDefect[] } }>(getDefectsForWaterSourceQuery, variables);
            const defects = res?.node.defects ?? [];

            dispatch(setDefects(defects));

            if (setLoading) {
                dispatch(setGlobalToast());
            }

            return defects;
        } catch (err) {
            dispatch(setError("Error getting defects for water source", err));
            return [];
        }
    };
};

export const startAddDefect = (variables: IAddDefectVariables) => {
    return async (dispatch: Function): Promise<IDefect | undefined> => {
        try {
            dispatch(setLoadingToast('Creating defect...'));
            const res = await executeQuery<{ addDefect: { defect: IDefect } }>(createDefectMutation, variables);
            const newDefect = res?.addDefect.defect;
            if (newDefect) {
                const { waterSourceNodeId, isOperable, isDefective } = newDefect.waterSource;
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Defect ${newDefect.defectId} created`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(updateWaterSourceOperability(waterSourceNodeId, isDefective, isOperable));
                dispatch(addDefect(newDefect));
                dispatch(setCreateDefectSuccess(true));
                dispatch(setGlobalToast(globalToast));
                dispatch(setRefreshInspection(true));
            }
            return newDefect;
        } catch (err) {
            dispatch(setError('Error creating defect', err));
        }
    }
}

export const startCancelDefect = (variables: ICancelDefectVariables) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Cancelling defect...'));
            const res = await executeQuery<{ cancelDefect: { defect: IDefect } }>(cancelDefectMutation, variables);
            const editedDefect = res?.cancelDefect.defect;
            if (editedDefect) {
                const { waterSourceNodeId, isOperable, isDefective } = editedDefect.waterSource;
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Defect ${editedDefect.defectId} cancelled`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(updateWaterSourceOperability(waterSourceNodeId, isDefective, isOperable));
                dispatch(editDefect(editedDefect));
                dispatch(setSelectedDefect(editedDefect));
                dispatch(setEditDefectSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        } catch (err) {
            dispatch(setError('Error cancelling defect', err));
        }
    };
};

export const startCompleteDefect = (variables: ICloseDefectVariables) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to defect...'));
            const res = await executeQuery<{ closeDefect: { defect: IDefect } }>(closeDefectMutation, variables);
            const editedDefect = res?.closeDefect.defect;
            if (editedDefect) {
                const { waterSourceNodeId, isOperable, isDefective } = editedDefect.waterSource;
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Changes saved to defect ${editedDefect.defectId}`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(updateWaterSourceOperability(waterSourceNodeId, isDefective, isOperable));
                dispatch(editDefect(editedDefect));
                dispatch(setEditDefectSuccess(true));
                dispatch(setSelectedDefect(editedDefect));
                dispatch(setGlobalToast(globalToast));
                dispatch(setRefreshInspection(true));
            }
        } catch (err) {
            dispatch(setError('Error completing defect', err))
        }
    }
}

export const startEditDefect = (variables: IUpdateDefectVariables) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to defect...'));
            const res = await executeQuery<{ updateDefect: { defect: IDefect } }>(editDefectMutation, variables);
            const editedDefect = res?.updateDefect.defect;
            if (editedDefect) {
                const { waterSourceNodeId, isOperable, isDefective } = editedDefect.waterSource;
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Changes saved to defect ${editedDefect.defectId}`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(updateWaterSourceOperability(waterSourceNodeId, isDefective, isOperable));
                dispatch(editDefect(editedDefect));
                dispatch(setSelectedDefect(editedDefect));
                dispatch(setEditDefectSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        } catch (err) {
            dispatch(setError('Error editing defect', err));
        }
    }
}
