import { ISavedSearch } from "../../../store/types";
import { SearchQueryResponse, SearchMutations, SearchMutationResult, SaveExistingSearch, SaveNewSearch, DeleteSearch } from "./types";

const resolveQuery = (response: SearchQueryResponse | undefined): ISavedSearch[] => {
    return response?.searches ?? [];
};

type Keys = keyof (SaveExistingSearch & SaveNewSearch & DeleteSearch);

const makeResolveMutation = (key: Keys) => {
    return (mutation: SearchMutations | undefined): SearchMutationResult => {
        return mutation?.searches?.[key]?.result ?? { searches: [] };
    };
};

export { resolveQuery, makeResolveMutation };