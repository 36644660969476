import React, { useCallback, useEffect, useState } from "react";

import useMountedEffect from "../../api/hooks/useMountedEffect";
import { emptyPage, AddressNode } from "../../store/types";
import useAddressSearch from "./hooks/useAddressSearch";
import AddressSearchInput, { RenderAddressSearchInput } from "./AddressSearchInput";
import AddressSearchResults from "./AddressSearchResults";
import { RenderAction } from "./AddressSearchItem";

interface AddressSearchProps {
    readonly pageSize: number;
    readonly search?: string;
    readonly mobile?: boolean;
    readonly paging?: boolean;
    readonly theme?: "dark" | "light";
    readonly renderInput: RenderAddressSearchInput;
    readonly renderItemEdit?: (refresh?: () => void) => RenderAction;
    readonly onClear?: () => void;
    readonly onSearchComplete?: (itemCount: number) => void;
    readonly onSelect?: (address: AddressNode) => void;
}

const AddressSearch = ({ pageSize, paging, theme, renderInput, renderItemEdit, onClear, onSearchComplete, onSelect }: AddressSearchProps): JSX.Element => {
    const [enabled, setEnabled] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [status, result, search, clear] = useAddressSearch({ searchTerm, pageIndex, pageSize });

    const executeSearch = useCallback((): void => {
        setEnabled(false);
        setLoading(true);
        search({ searchTerm, pageIndex, pageSize });
    }, [searchTerm, pageIndex, pageSize]);

    const handleClear = (): void => {
        clear?.();
        setPageIndex(1);
        setSearchTerm("");
        onClear?.();
    };
    const handleSearch = (searchTerm: string): void => {
        setPageIndex(1);
        setSearchTerm(searchTerm);
        executeSearch();
    };

    const handleGoToFirst = (): void => {
        setPageIndex(1);
    };
    const handleGoToLast = (): void => {
        setPageIndex(result?.pageInfo.pageCount ?? 1);
    };
    const handleGoToNext = (): void => {
        setPageIndex(current => current + 1);
    };
    const handleGoToPrevious = (): void => {
        setPageIndex(current => current - 1);
    };

    useMountedEffect(ifMounted => { 
        ifMounted(executeSearch);
    }, [searchTerm, pageIndex]);

    useMountedEffect(ifMounted => {
        ifMounted(() => {
            if (status === "complete") {
                setLoading(false);
                setEnabled(true);
            }
        });
    }, [status, result]);

    const { addresses = [], pageInfo = emptyPage, totalCount = 0 } = result ?? {};

    const itemCount = addresses.length;
    useEffect(() => {
        if (status === "complete") {
            onSearchComplete?.(itemCount);
        }
    }, [status, itemCount]);

    return (
        <React.Fragment>
            <AddressSearchInput initSearch={searchTerm} enabled={enabled} renderInput={renderInput} onClear={handleClear} onSearch={handleSearch} />
            {status !== "ready" &&
                <AddressSearchResults
                    loading={loading}
                    addresses={addresses}
                    totalCount={totalCount}
                    pageCount={pageInfo.pageCount}
                    pageNumber={pageInfo.pageNumber}
                    paging={paging}
                    theme={theme}
                    renderItemEdit={renderItemEdit?.(executeSearch)}
                    onGoToFirst={handleGoToFirst}
                    onGoToLast={handleGoToLast}
                    onGoToNext={handleGoToNext}
                    onGoToPrevious={handleGoToPrevious}
                    onSelect={onSelect}
                />
            }
        </React.Fragment>
    );
};

export type { AddressSearchProps };
export default AddressSearch;