import { ElementProps } from "./types";

export type ClassesProps = Pick<ElementProps, "classes">;

export const containerClass = (props: ClassesProps): string | undefined =>
    props.classes
        ? props.classes.container
        : undefined;

export const inputClass = (props: ClassesProps): string | undefined =>
    props.classes
        ? props.classes.input
        : undefined;

export const labelClass = (props: ClassesProps): string | undefined =>
    props.classes
        ? props.classes.label
        : undefined;