import React from "react";

import { ILookupItem } from "../../../../store/types";
import LabelledField, { LabelledFieldProps } from "../../../labelledField";

interface RepairCancelReasonProps {
    readonly cancelReason?: ILookupItem;
}

const RepairCancelReason = ({ cancelReason }: RepairCancelReasonProps): JSX.Element => {
    if (cancelReason) {
        const props: LabelledFieldProps = {
            fieldType: "readonly",
            id: `repair-cancel-reason`,
            label: "Cancel Reason",
            classes: { container: 'input__group', label: 'input__label' },
            value: cancelReason.displayText
        };
        return <LabelledField {...props} />;
    }
    return <React.Fragment />;
};

export default RepairCancelReason;