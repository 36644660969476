import React, { useState, useRef } from 'react';

import { LocalDate, convert, nativeJs } from '@js-joda/core';
import "@js-joda/timezone";

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import InputBase from '@material-ui/core/InputBase';
import Popper from '@material-ui/core/Popper';

import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { shortDateFormatter } from '../../lib/Utils';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

export interface IDateInputProps {
    readonly id?: string;
    readonly placeholder?: string;
    readonly value?: LocalDate;
    readonly classes?: string;
    readonly onChange: (selectedDate: LocalDate | undefined) => void;
}

const defaultPlaceholder = "DD/MM/YYYY";

const useStyles = makeStyles(theme => createStyles({
    root: {
        "& div": {
            flexGrow: "unset"
        },
        "& div + div": {
            marginLeft: "unset"
        }
    },
    button: {
        "&:first-child": {
            marginRight: "auto"
        }
    },
    inputRoot: {
        backgroundColor: theme.palette.primary.main,
        border: "3px dashed transparent",
        color: theme.palette.common.white,
        display: "flex",
        minHeight: theme.spacing(6),
    },
    inputFocused: {
        border: `3px dashed ${theme.palette.secondary.main}`,
    },
    inputInput: {
        padding: theme.spacing(1.5)
    },
    popper: {
        boxShadow: theme.shadows[9],
        zIndex: theme.zIndex.modal
    },
}));

const DateInput = (props: IDateInputProps): JSX.Element => {
    const styles = useStyles();

    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLInputElement>(null);

    const [date, setDate] = useState(props.value);

    const changeDate = (value: LocalDate | undefined): void => {
        setDate(value);
        props.onChange?.(value);
    };
    const closeCalendar = (): void => setOpen(false);
    const openCalendar = (): void => setOpen(true);

    const handleClearClick = (): void => {
        changeDate(undefined);
        closeCalendar();
    };
    const handleClickAway = (event: React.MouseEvent<EventTarget>): void => {
        if (anchor.current?.contains(event.target as HTMLElement)) {
            return;
        }
        closeCalendar();
    };
    const handleDateChange = (value: MaterialUiPickersDate): void => {
        changeDate(value ? LocalDate.from(nativeJs(value)) : undefined);
    };
    const handleTodayClick = (): void => {
        changeDate(LocalDate.now());
        closeCalendar();
    };

    const renderDay = (day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element): JSX.Element => {
        return React.cloneElement(dayComponent, {
            ...dayComponent.props,
            onClick: () => {
                handleDateChange(day?.clone() ?? null);
                closeCalendar();
            }
        });
    };

    return (
        <Box className={styles.root}>
            <InputBase
                ref={anchor}
                id={props.id}
                name={props.id}
                placeholder={props.placeholder ?? defaultPlaceholder}
                className={props.classes}
                classes={{ root: styles.inputRoot, input: styles.inputInput, focused: styles.inputFocused }}
                value={date?.format(shortDateFormatter) ?? ""}
                onClick={openCalendar}
            />
            <Popper className={styles.popper} open={open} anchorEl={anchor.current} role={undefined} transition placement="bottom-start">
                {({ TransitionProps }): JSX.Element => (
                    <Grow {...TransitionProps}>
                        <Paper square>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <div>
                                    <DatePicker
                                        variant="static"
                                        autoOk
                                        animateYearScrolling
                                        openTo="date"
                                        orientation="landscape"
                                        views={["year", "month", "date"]}
                                        value={date ? convert(date).toDate() : undefined}
                                        initialFocusedDate={undefined}
                                        onChange={handleDateChange}
                                        renderDay={renderDay}
                                    />
                                    <Box display="flex" flexDirection="row">
                                        <Button id="picker-today-button" className={styles.button} onClick={handleTodayClick}>Today</Button>
                                        <Button id="picker-clear-button" className={styles.button} onClick={handleClearClick}>Clear</Button>
                                    </Box>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default DateInput;
