import React from "react";

import Box from "@material-ui/core/Box";

import { AccessControl } from "../../../../auth/components";
import { RoleNames } from "../../../../auth/roles";
import { PropsWithEditing } from "../types";
import RepairMenu from "./RepairMenu";

interface RepairActionsProps {
    readonly isExternal: boolean;
    readonly isOpenRepair: boolean;
    readonly hasDefect: boolean;
    readonly hasVendor: boolean;
    readonly onCancel?: () => void;
    readonly onEdit?: () => void;
    readonly onCancelRepair?: () => void;
    readonly onCloseRepair?: () => void;
    readonly onSave?: () => void;
    readonly onSendEmail?: () => void;
}

const RepairActions = ({ editing, isExternal, isOpenRepair, hasDefect, hasVendor, onCancel, onEdit, onSave, onCloseRepair, onCancelRepair, onSendEmail }: PropsWithEditing<RepairActionsProps>): JSX.Element => {
    const handleCancelClick = (): void => {
        onCancel?.();
    };
    const handleSaveClick = (): void => {
        onSave?.();
    };
    return (
        <Box display="inline-flex" flexDirection="row">
            {editing
                ? (
                    <React.Fragment>
                        <button id="save-button" className="action-button" onClick={handleSaveClick}>Save</button>
                        <button id="cancel-button" className="action-button action-button--cancel" onClick={handleCancelClick}>Cancel</button>
                    </React.Fragment>
                )
                : (
                    <AccessControl role={RoleNames.WS_ALL} >
                        <RepairMenu
                            isExternalRepair={isExternal}
                            isOpenRepair={isOpenRepair}
                            hasDefect={hasDefect}
                            hasVendor={hasVendor}
                            onEdit={onEdit}
                            onCloseRepair={onCloseRepair}
                            onCancelRepair={onCancelRepair}
                            onSendEmail={onSendEmail}
                        />
                    </AccessControl>
                )
            }
        </Box>
    );
};

export type { RepairActionsProps };
export default RepairActions;