import { useState, useEffect, useMemo, useCallback } from "react";

import { IInspection, IConnectionQuery, IConnection, FilteredObject, emptyConnection } from "../../store/types";
import { IQueryFilter, InspectionCategory, ITechnician } from "../../store/types/inspections";
import getInspections from "../getInspections";
import { FetchStatus, FetchSingle } from "./types";
import { InspectionsParams } from "../../router/MobileRouter/hooks/useInspectionsParams";

const empty = emptyConnection<IInspection>();

const isPlanned = (category: InspectionCategory | undefined): boolean | undefined => {
    switch (category) {
        case "PLANNED":
            return true;
        case "UNPLANNED":
            return false;
        default:
            return undefined;
    }
};

/**
 * @param {boolean} isPlanned true to only included planned/scheduled inspections, false to only include unplanned/ad-hoc inspections
 * @param {number} pageIndex the number of the query results page to return
 * @param {number} pageSize the number of query results in a page
 */
const useInspectionsForTechnician = (technician: ITechnician | undefined, params: InspectionsParams | undefined, pageIndex = 1, pageSize = 99999999): FetchSingle<IConnection<IInspection>> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [page, setPage] = useState<IConnection<IInspection>>(empty);

    const { technicianId, inspectionGroups } = technician ?? {};
    const groupIds = useMemo(() => inspectionGroups?.map(group => group.inspectionGroupId) ?? [], [inspectionGroups]);

    const { category, station, route } = params ?? {};
    const { id: stationId } = station ?? {};
    const { id: routeId } = route ?? {};
    const fetch = useCallback(() => {
        if (technicianId) {
            const filter: FilteredObject<IQueryFilter> = {
                isOpen: true,
                stationId,
                routeId,
                isPlanned: isPlanned(category),
                "$any": {
                    technicianId,
                    "$all": {
                        technicianId: null,
                        inspectionGroupId: {
                            "$in_list": groupIds
                        }
                    }
                }
            };
            setStatus("loading");
            const filters: IConnectionQuery<FilteredObject<IQueryFilter>> = {
                filter: filter ?? {},
                paging: {
                    pageIndex: pageIndex ?? 1,
                    pageSize: pageSize ?? 9999999,
                    sortBy: "inspectionDate",
                    sortDirection: "ASCENDING"
                }
            };
            getInspections(filters)
                .then(page => setPage(page))
                .catch(() => setPage(empty))
                .finally(() => setStatus("complete"));
        }
    }, [technicianId, groupIds, JSON.stringify(groupIds), category, routeId, stationId, pageIndex, pageSize]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [status, page, fetch];
};

export default useInspectionsForTechnician;