import React, { Component } from 'react';

import { encodeNodeId, NodeID } from '../../../lib/nodeIdentifier';
import { validateState, requiredObject, validationStyle, StateValidators, valid } from '../../../lib/validation';
import { ILookupItem, IQuickSearchItem, CreateRepairData } from '../../../store/types';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import LabelledField, { SelectOption } from '../../labelledField';
import InlineQuickSearch from '../../inlineQuickSearch';

type RepairCategory = "external" | "internal";

interface CreateRepairDialogProps {
    readonly repairCategory: RepairCategory;
    readonly repairTypes: ILookupItem[];
    readonly repairVendors: ILookupItem[];
    readonly isOpen: boolean;
    readonly selectedWaterSource?: IQuickSearchItem;
    readonly getDefectsAndInspections: (waterSourceId: string | undefined) => Promise<[SelectOption[], SelectOption[]]>
    readonly onClose?: () => void;
    readonly onCreateRepair?: (category: RepairCategory, data: CreateRepairData) => void;
    readonly onError?: (message: string) => void;
}

interface CreateRepairDialogState {
    readonly selectedDefect: SelectOption | undefined;
    readonly selectedInspection: SelectOption | undefined;
    readonly repairType: SelectOption | undefined;
    readonly repairVendor: SelectOption | undefined;
    readonly workOrderRef: string | undefined;
    readonly formValid: boolean | undefined;
    readonly shouldValidate: boolean;
    readonly waterSource: IQuickSearchItem | undefined;
    readonly openDefects: SelectOption[];
    readonly openInspections: SelectOption[];
}

const formatTitle = (category: RepairCategory): string => {
    switch (category) {
        case "external":
            return "New External Repair";
        case "internal":
            return "New Internal Repair";
    }
};

type StateKeys = keyof CreateRepairDialogState;

const validators: StateValidators<CreateRepairDialogState> = {
    waterSource: requiredObject,
    repairType: requiredObject,
    openDefects: valid,
    selectedDefect: valid,
    openInspections: valid,
    selectedInspection: valid,
    repairVendor: valid,
    workOrderRef: valid,
    formValid: valid,
    shouldValidate: valid
};

const validateForm = validateState(validators);

class CreateRepairDialog extends Component<CreateRepairDialogProps, CreateRepairDialogState> {
    constructor(props: CreateRepairDialogProps) {
        super(props);

        this.state = {
            shouldValidate: false,
            selectedDefect: undefined,
            selectedInspection: undefined,
            repairType: undefined,
            repairVendor: undefined,
            workOrderRef: undefined,
            formValid: false,
            waterSource: undefined,
            openDefects: [],
            openInspections: []
        };
    }

    public async componentDidMount(): Promise<void> {
        const { repairCategory, selectedWaterSource, getDefectsAndInspections } = this.props;
        const [openDefects, openInspections] = repairCategory === "external"
            ? await getDefectsAndInspections(selectedWaterSource?.waterSourceNodeId)
            : [[], []];
        this.setState({
            waterSource: selectedWaterSource,
            openDefects,
            openInspections
        });
    }

    public render(): JSX.Element {
        const isExternal = this.props.repairCategory === "external";

        const handleClose = (): void => {
            this.props.onClose?.();
        };
        const handleSave = (): void => {
            this.props.onClose?.();
            this.handleFormSubmit();
        };

        const handleSearchClear = (): void => {
            this.setState({
                waterSource: undefined,
                openDefects: [],
                openInspections: [],
                selectedDefect: undefined,
                selectedInspection: undefined
            });
        };
        const handleSearchResult = (item: IQuickSearchItem): void => {
            if (this.state.waterSource?.waterSourceNodeId === item.waterSourceNodeId) {
                return;
            }

            if (isExternal) {
                this.props.getDefectsAndInspections(item.waterSourceNodeId)
                    .then(([openDefects, openInspections]) => this.setState({
                        waterSource: item,
                        openDefects,
                        openInspections
                    }))
                    .catch(error => {
                        console.warn("Error getting defects and inspections for water source.", error);
                        this.setState({ waterSource: item });
                    })
            }

            this.setState({
                waterSource: item
            });
        };

        const handleChange = (key: StateKeys) => {
            return (value: CreateRepairDialogState[StateKeys]): void => {
                this.setState(current => ({ ...current, [key]: value }));
            };
        };
        const handleClear = (key: StateKeys) => {
            return (): void => {
                this.setState(current => ({ ...current, [key]: undefined }));
            };
        };

        const disableSave = !validateForm(this.state);

        return (
            <Dialog fullWidth maxWidth="sm" overflowY="unset" open={this.props.isOpen} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>{formatTitle(this.props.repairCategory)}</DialogTitle>
                <DialogContent overflowY="unset">
                    <InlineQuickSearch
                        id="repairs-selected-watersource"
                        result={this.state.waterSource}
                        onClear={handleSearchClear}
                        onResult={handleSearchResult}
                        invalid={!validators.waterSource(this.state.waterSource)}
                    />
                    {isExternal &&
                        <LabelledField
                            fieldType="select"
                            id="repair-defect-id"
                            label="Defect"
                            classes={{ container: 'input__group', label: 'input__label' }}
                            value={this.state.selectedDefect}
                            selectOptions={this.state.openDefects}
                            editing
                            onChange={handleChange("selectedDefect")}
                            onClear={handleClear("selectedDefect")}
                        />
                    }
                    {isExternal &&
                        <LabelledField
                            fieldType="select"
                            id="repair-inspection-id"
                            label="Inspection"
                            classes={{ container: 'input__group', label: 'input__label' }}
                            value={this.state.selectedInspection}
                            selectOptions={this.state.openInspections}
                            editing
                            onChange={handleChange("selectedInspection")}
                            onClear={handleClear("selectedInspection")}
                        />
                    }
                    <LabelledField
                        fieldType="select"
                        id="repair-repair-type"
                        label="Repair Type *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.repairType)(this.state.repairType) }}
                        value={this.state.repairType}
                        selectOptions={this.props.repairTypes}
                        editing
                        onChange={handleChange("repairType")}
                        onClear={handleClear("repairType")}
                    />
                    {isExternal &&
                        <LabelledField
                            fieldType="select"
                            id="repair_vendorId"
                            label="Repair Vendor"
                            classes={{ container: 'input__group', label: 'input__label' }}
                            value={this.state.repairVendor}
                            selectOptions={this.props.repairVendors}
                            editing
                            onChange={handleChange("repairVendor")}
                            onClear={handleClear("repairVendor")}
                        />
                    }
                    {isExternal &&
                        <LabelledField
                            fieldType="text"
                            id="repair-workOrderRef"
                            label="Work Order Reference"
                            classes={{ container: 'input__group', label: 'input__label' }}
                            value={this.state.workOrderRef}
                            editing
                            onChange={handleChange("workOrderRef")}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <button id="createRepair-cancel-button" className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button id="createRepair-save-button" className="action-button" disabled={disableSave} onClick={handleSave}>Save</button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleFormSubmit(): void {
        const getValueAsNumber = (option: SelectOption | undefined): number | undefined => option ? Number(option.value) : undefined;

        const { waterSource } = this.state;
        if (waterSource) {
            const defectId = this.state.selectedDefect?.value;
            const inspectionId = this.state.selectedInspection?.value;
            this.props.onCreateRepair?.(this.props.repairCategory, {
                waterSourceId: waterSource.waterSourceNodeId,
                defectId: defectId ? encodeNodeId(NodeID("Defect", defectId)) : undefined,
                inspectionId: inspectionId ? encodeNodeId(NodeID("Inspection", inspectionId)) : undefined,
                repairTypeId: getValueAsNumber(this.state.repairType),
                repairVendorId: getValueAsNumber(this.state.repairVendor),
                workOrderRef: this.state.workOrderRef,
                isPaymentAuthorised: false
            });
        }
        else {
            this.props.onError?.("Please select a water source.");
        }
    }
}

export type { CreateRepairDialogProps, RepairCategory };
export default CreateRepairDialog;
