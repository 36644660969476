import React from "react";

import useSelector from "../../../../lib/hooks/useSelector";
import WaterSourceDetails, { WaterSourceDetailsProps } from "./WaterSourceDetails.view";

const Connected = (props: Omit<WaterSourceDetailsProps, "isMobile">): JSX.Element => {
    const connectedProps: WaterSourceDetailsProps = {
        ...props,
        isMobile: useSelector(state => state.device.isMobile)
    };
    return <WaterSourceDetails {...connectedProps} />;
};

export default Connected;