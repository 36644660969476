import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, NumberProps, PropsWithShow } from "../types";
import Optional from "../../../shared/Optional";

const WaterSourcePlateDistance = ({ editing, show, value, onChange }: PropsWithEditing<PropsWithShow<NumberProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "number",
        id: "water-source-plateDistance",
        label: "Plate Distance",
        classes: { container: "input__group", label: "input__label" },
        value,
        minValue: 0,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default WaterSourcePlateDistance;