import { styled } from "@material-ui/core/styles";

const PanelContainer = styled("div")({
    minHeight: "50vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 16
});

export default PanelContainer;
