import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import MyLocationIcon from '@material-ui/icons/MyLocation';

import * as coords from "../../../../../lib/coordinates";
import { IPoint } from "../../../../../store/types";
import useStyles from "../InspectionItem.styles";

interface CoordinatesProps {
    readonly coordinates: IPoint | undefined;
}

const formatCoordinates = (coordinates: IPoint | undefined): string => {
    return coordinates
        ? coords.formatEastingsAndNorthings(coordinates)
        : "-";
};

const formatGridRef = (coordinates: IPoint | undefined): string => {
    return coordinates
        ? coords.formatGridReference(coordinates)
        : "-";
};

const Coordinates = (props: CoordinatesProps): JSX.Element => {
    const styles = useStyles();
    const { coordinates } = props;
    return (
        <React.Fragment>
            <Box mr={1}>
                <MyLocationIcon className={styles.icon} />
            </Box>
            <Box display="flex">
                <Typography variant="caption">{formatCoordinates(coordinates)}</Typography>
                <Typography variant="caption" className={styles.gridRef}>{formatGridRef(coordinates)}</Typography>
            </Box>
        </React.Fragment>
    );
};

export type { CoordinatesProps };
export default React.memo(Coordinates, (prev, next): boolean => {
    const { coordinates: prevCoordinates } = prev;
    const { coordinates: nextCoordinates } = next;
    if (prevCoordinates === undefined && nextCoordinates === undefined) {
        return true;
    }
    if (prevCoordinates && nextCoordinates) {
        return prevCoordinates.x === nextCoordinates.x && prevCoordinates.y === nextCoordinates.y;
    }
    return false;
});