import React, { useState, useMemo } from "react";
import cx from "classnames";
import { Color, ColorProperty } from "csstype";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { timestampFormatter, shortDateFormatter } from "../../../../lib/Utils";
import { INote, ObjectType } from "../../../../store/types";
import TextElement from "../../../labelledField/components/textElement";
import EditNoteDialog from "../editNoteDialogue/EditNoteDialogue";
import { NoteStylesProps } from "../types";

interface NoteItem extends NoteStylesProps {
    readonly note: INote;
    readonly hideActions?: boolean;
}

interface NoteListItemProps extends NoteItem {
    readonly canDeleteNotes: boolean;
    readonly canEditNotes: boolean;
    readonly objectType: ObjectType;
    readonly onDeleteNote?: (parentType: ObjectType, noteNodeId: string) => void;
    readonly onEditNote?: (objectType: ObjectType, objectId: string, content: string) => void;
}

interface ReadOnlyProps extends Pick<NoteListItemProps, "canDeleteNotes" | "canEditNotes" | "isDark" | "note" | "hideActions"> {
    readonly onDelete?: () => void;
    readonly onEdit?: () => void;
}

interface EditNoteProps extends NoteItem {
    readonly onCancel?: () => void;
    readonly onSave?: (content: string) => void;
}

const useStyles = makeStyles<Theme, NoteStylesProps>(theme => {
    const getColour = (isDark: boolean | undefined, lightGrey = 400): ColorProperty => {
        const colour: Color = isDark ? theme.palette.grey[900] : theme.palette.grey[lightGrey];
        return colour;
    }
    return createStyles({
        divider: ({ isDark }) => ({
            borderBottom: `1px solid ${isDark ? theme.palette.grey[400] : theme.palette.primary.light}`,
        }),
        secondaryAction: {
            paddingRight: theme.spacing(9)
        },
        content: ({ isDark }) => ({
            color: getColour(isDark, 200),
            hyphens: "auto",
            overflowWrap: "break-word"
        }),
        metaData: ({ isDark }) => ({
            color: getColour(isDark),
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontStyle: "italic"
        }),
        action: ({ isDark }) => ({
            color: getColour(isDark),
            fontSize: "1rem",
            "&:hover": {
                color: theme.palette.secondary.main
            }
        }),
        editor: {
            display: "flex"
        },
        editorTextContainer: {
            flexGrow: 1
        },
        editorActionContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }
    })
});

const ReadOnlyNote = (props: ReadOnlyProps): JSX.Element => {
    const { note: { content, author, timestamp }, hideActions, canEditNotes: canEdit, canDeleteNotes: canDelete, isDark, onDelete, onEdit } = props;
    const [showDialog, setShowDialog] = useState(false);
    const styles = useStyles({ isDark });

    const handleCancelDelete = (): void => {
        setShowDialog(false);
    };
    const handleConfirmDelete = (): void => {
        setShowDialog(false);
        onDelete?.();
    };
    const handleEditClick = (): void => {
        onEdit?.();
    };
    const handleDeleteClick = (): void => {
        setShowDialog(true);
    };

    const longTimestamp = timestamp.format(timestampFormatter);
    const shortTimestamp = timestamp.format(shortDateFormatter);

    return (
        <ListItem disableGutters divider classes={{ root: styles.root, secondaryAction: styles.secondaryAction, divider: styles.divider }}>
            <ListItemText
                primary={content}
                primaryTypographyProps={{ variant: "body2", className: cx(styles.text, styles.content), gutterBottom: true }}
                secondary={
                    <Typography variant="caption" classes={{ root: cx(styles.metaData, styles.text) }}>
                        <span>{author}</span>
                        <span title={longTimestamp}>{shortTimestamp}</span>
                    </Typography>
                }
            />
            <EditNoteDialog
                title="Delete Note?"
                message="Are you sure you want to delete this note? This is a permanent action and cannot be undone."
                isOpen={showDialog}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
            {!hideActions &&
                <ListItemSecondaryAction>
                    {canEdit &&
                        <IconButton title="Edit" size="small" onClick={handleEditClick}>
                            <EditIcon className={styles.action} />
                        </IconButton>
                    }
                    {canDelete &&
                        <IconButton title="Delete" size="small" edge="end" onClick={handleDeleteClick}>
                            <DeleteForeverIcon className={styles.action} />
                        </IconButton>
                    }
                </ListItemSecondaryAction>
            }
        </ListItem>
    );
};

const EditingNote = (props: EditNoteProps): JSX.Element => {
    const { note, isDark, onCancel, onSave } = props;

    const [content, setContent] = useState(note.content);
    const [showDialog, setShowDialog] = useState(false);
    const styles = useStyles({ isDark });
    const isDirty = useMemo(() => note.content !== content, [note.content, content]);

    const handleCancelEdit = (): void => {
        if (isDirty) {
            setShowDialog(true);
            return;
        }
        onCancel?.();
    };
    const handleSave = (): void => {
        if (isDirty) {
            onSave?.(content);
            return;
        }
        onCancel?.();
    };
    const handleConfirmCancel = (): void => {
        setShowDialog(false);
        onCancel?.();
    };
    const handleRejectCancel = (): void => {
        setShowDialog(false);
    };

    return (
        <ListItem disableGutters divider classes={{ root: styles.root, secondaryAction: styles.secondaryAction, divider: styles.divider }}>
            <ListItemText disableTypography>
                <TextElement
                    editing
                    multiline
                    classes={{ container: styles.editorTextContainer }}
                    value={content}
                    onChange={(value): void => setContent(value ?? '')}
                    id={`note-${note.noteId}-edit`}
                    label=''
                />
            </ListItemText>
            <EditNoteDialog
                title="Cancel Edit?"
                message="Are you sure you want to cancel the edit? This will discard any changes you have made."
                isOpen={showDialog}
                onCancel={handleRejectCancel}
                onConfirm={handleConfirmCancel}
            />
            <ListItemSecondaryAction>
                <IconButton title="Save" size="small" onClick={handleSave}>
                    <SaveIcon className={styles.action} />
                </IconButton>
                <IconButton title="Cancel" size="small" edge="end" onClick={handleCancelEdit}>
                    <CancelIcon className={styles.action} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const NoteListItem = (props: NoteListItemProps): JSX.Element => {
    const [editing, setEditing] = useState(false);
    const { objectType, canDeleteNotes, canEditNotes, onDeleteNote, onEditNote, ...rest } = props;

    const handleCancelEdit = (): void => {
        setEditing(false);
    };
    const handleDelete = (): void => {
        onDeleteNote?.(objectType, rest.note.noteNodeId);
    };
    const handleSave = (content: string): void => {
        onEditNote?.(objectType, rest.note.noteNodeId, content);
        setEditing(false);
    };
    const handleStartEdit = (): void => {
        setEditing(true);
    };

    return editing && onEditNote
        ? <EditingNote {...rest} onCancel={handleCancelEdit} onSave={handleSave} />
        : <ReadOnlyNote {...rest} canDeleteNotes={canDeleteNotes} canEditNotes={canEditNotes} onDelete={handleDelete} onEdit={handleStartEdit} />;
};

export type { NoteListItemProps };
export default NoteListItem;