import React, { useState, useEffect, useRef } from "react";
import { List } from "@3tc/shared-components";

import { formatLastEvent } from "../../../lib/Utils";
import { IDefect } from "../../../store/types";
import Dialog from "../../dialog/dialog";
import DialogActions from "../../dialog/dialogActions";
import DialogTitle from "../../dialog/dialogTitle";
import DialogContent from "../../dialog/dialogContent";

interface ISelectDefectsDialogProps {
    readonly defects: IDefect[];
    readonly isOpen: boolean;
    readonly onClose?: () => void;
    readonly onSave?: (reviewed: string[], notReviewed: string[]) => void;
}

interface DefectListItem extends IDefect {
    readonly checked: boolean;
    readonly lastEventDescription: string;
    readonly typeDescription: string;
}

const listConfig = {
    titleKey: "defectId",
    titleLeftKey: "typeDescription",
    infoKey: "lastEventDescription",
    titleAttrKeys: ["defectId", "typeDescription"],
    listType: "checkbox",
    isCheckboxList: true
};

const serialize = (defects: IDefect[]): string => {
    return btoa(defects.map(d => d.defectNodeId).join(','));
};

const SelectDefectsDialog2 = (props: ISelectDefectsDialogProps): JSX.Element => {
    const { defects, isOpen, onClose, onSave } = props;
    const [items, setItems] = useState<DefectListItem[]>([]);

    const handleClose = (): void => {
        onClose?.();
    };
    const handleSave = (): void => {
        const reviewed = items
            .filter(item => item.checked)
            .map(item => item.defectNodeId);
        const notReviewed: string[] = items
            .filter(item => !item.checked)
            .map(item => item.defectNodeId);

        onSave?.(reviewed, notReviewed);
        onClose?.();
    };

    const updateDefect = (updatedDefect: DefectListItem): void => {
        setItems(current => current.map(item => item.defectNodeId === updatedDefect.defectNodeId
            ? { ...item, checked: !item.checked }
            : item
        ));
    };

    const hash = serialize(defects);
    const prevHash = useRef<string>();
    useEffect(() => {
        if (prevHash.current !== hash) {
            const items = defects
                .map<DefectListItem>(defect => ({
                    ...defect,
                    checked: true,
                    lastEventDescription: formatLastEvent(defect.lastEvent),
                    typeDescription: defect.type.displayText
                }));
            setItems(items);
        }
        prevHash.current = hash;
    }, [defects, hash]);

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Select Defects Reviewed During Inspection</DialogTitle>
            <DialogContent>
                <List items={items} config={listConfig} clickHandler={updateDefect} />
            </DialogContent>
            <DialogActions>
                <button id="selectDefects-cancel" className="action-button" onClick={handleClose}>Cancel</button>
                <button id="selectDefects-accept" className="action-button" onClick={handleSave}>Accept</button>
            </DialogActions>
        </Dialog>
    );
};

export type { ISelectDefectsDialogProps };
export default SelectDefectsDialog2;