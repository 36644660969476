import React from "react";

import Box from "@material-ui/core/Box";

import { AddressDetailsData, toAddressNode } from "../../../../address/types";
import EditAddress from "./EditAddress";
import { AddressChanged } from "./types";

interface EditorProps {
    readonly initialAddress: AddressDetailsData;
    readonly addressSaved: boolean;
    readonly show?: boolean;
    readonly onChange?: AddressChanged;
}

const Editor = ({ initialAddress, addressSaved, show, onChange }: EditorProps): JSX.Element | null => {
    if (!(show && addressSaved)) {
        return null;
    }

    const handleUpdate = (address: AddressDetailsData): void => {
        onChange?.(toAddressNode(address));
    };
    return (
        <Box display="flex" flexDirection="row" justifyContent="start" mt={0.5}>
            <EditAddress context="editor" variant="button" initialAddress={initialAddress} onUpdate={handleUpdate} />
        </Box>
    );
};

export type { EditorProps };
export default Editor;