import executeQuery from "../../../lib/executeQuery";
import { IConnection, IConnectionQuery, IQueryControl } from "../../../store/types";
import { SchemeSearchItem } from "./types";

const gql = `
fragment LookupItem on LookupItem {
    displayText
    value
}

fragment Scheme on Scheme {
    schemeNodeId: id
    schemeId
    type {
        ...LookupItem
    }
    reference
    title
    station {
        ...LookupItem
    }
    waterSourceCount
    stage {
        displayText
        value
        isOpen
    }
    dateReceived
    dateReturned
    dateOrdered
    invoiceDueDate
    cost
    dateClosed
}

query getSchemes($filter: QueryFilter, $paging: QueryControlInput) {
    schemes(queryFilter: $filter, queryControl: $paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        items {
            ...Scheme
        }
    }
}`;

export interface SchemeQueryResponse {
    readonly schemes: IConnection<SchemeSearchItem>;
}

export const querySchemesByFilter = async (filter: Record<string, unknown>, paging: IQueryControl): Promise<IConnection<SchemeSearchItem>> => {
    const input: IConnectionQuery<Record<string, unknown>> = {
        filter,
        paging
    };
    const response = await executeQuery<SchemeQueryResponse>(gql, input);
    return response
        ? response.schemes
        : Promise.reject(new Error("Missing query response."));
};