import React from 'react';
import { LocalDate } from '@js-joda/core';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ILifecycleEvent } from '../../../../../../lib/Model';
import { formatText, timestampFormatter, formatCurrency, parseCurrency } from '../../../../../../lib/Utils';
import { ILookupItem } from '../../../../../../store/types';
import Divider from '../../../../../Divider';
import LabelledField, { SelectOption } from '../../../../../labelledField'
import SectionHeader from '../../../../../SectionHeader';


interface SchemeWorkflowData {
    readonly stage?: ILookupItem;
    readonly dateReceived?: LocalDate;
    readonly dateReturned?: LocalDate;
    readonly dateOrdered?: LocalDate;
    readonly orderReference?: string;
    readonly invoiceReference?: string;
    readonly invoiceDueDate?: LocalDate;
    readonly cost?: number;
    readonly dateClosedExternally?: LocalDate;
    readonly dateClosed?: LocalDate;
}

interface SchemeWorkflowProps {
    readonly data: SchemeWorkflowData;
    readonly events: ILifecycleEvent[];
    readonly schemeStages: ILookupItem[];
    readonly editing?: boolean;
    readonly onChange?: (workflow: SchemeWorkflowData) => void;
}

const SchemeWorfkflow = (props: SchemeWorkflowProps): JSX.Element => {
    const { data, schemeStages, editing, onChange } = props;

    const handleChange = (key: keyof SchemeWorkflowData, value: string | number | LocalDate | SelectOption | undefined): void => {
        onChange?.({
            ...data,
            [key]: value
        });
    };

    const isEditing = editing ?? false;
    return (
        <Box>
            <SectionHeader>Workflow</SectionHeader>
            <LabelledField
                fieldType="select"
                id="scheme-workflowStage"
                label="Stage"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.stage}
                selectOptions={schemeStages}
                editing={isEditing}
                onChange={(value: SelectOption | undefined): void => handleChange("stage", value)}
                onClear={(): void => handleChange("stage", undefined)}
            />
            <LabelledField
                fieldType="date"
                id="scheme-dateReceived"
                label="Date Received"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.dateReceived}
                editing={isEditing}
                onChange={(value): void => handleChange("dateReceived", value)}
            />
            <LabelledField
                fieldType="date"
                id="scheme-dateReturned"
                label="Date Returned"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.dateReturned}
                editing={isEditing}
                onChange={(value): void => handleChange("dateReturned", value)}
            />
            <LabelledField
                fieldType="date"
                id="scheme-dateOrdered"
                label="Date Ordered"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.dateOrdered}
                editing={isEditing}
                onChange={(value): void => handleChange("dateOrdered", value)}
            />
            <LabelledField
                fieldType="text"
                id="scheme-order-reference"
                label="Order Reference"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.orderReference}
                editing={isEditing}
                onChange={(value: string | undefined): void => handleChange("orderReference", value)}
            />
            <LabelledField
                fieldType="text"
                id="scheme-invoice-reference"
                label="Invoice Reference"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.invoiceReference}
                editing={isEditing}
                onChange={(value: string | undefined): void => handleChange("invoiceReference", value)}
            />
            <LabelledField
                fieldType="date"
                id="scheme-invoice-dueDate"
                label="Invoice Due Date"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.invoiceDueDate}
                editing={isEditing}
                onChange={(value): void => handleChange("invoiceDueDate", value)}
            />
            <LabelledField
                fieldType="text"
                id="scheme-cost"
                label="Cost"
                classes={{ container: "input__group", label: "input__label" }}
                value={formatCurrency(data.cost, true, isEditing ? "" : "-")}
                editing={isEditing}
                onChange={(value): void => handleChange("cost", parseCurrency(value))}
            />
            <LabelledField
                fieldType="date"
                id="scheme-dateClosedExternally"
                label="Date Closed Externally"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.dateClosedExternally}
                editing={isEditing}
                onChange={(value): void => handleChange("dateClosedExternally", value)}
            />
            <LabelledField
                fieldType="date"
                id="scheme-dateClosed"
                label="Date Closed"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.dateClosed}
                editing={isEditing}
                onChange={(value): void => handleChange("dateClosed", value)}
            />
            <Divider />
            <SectionHeader>Lifecycle</SectionHeader>
            {props.events.map((event, index) => {
                return <LabelledField
                    key={index}
                    fieldType="readonly"
                    id={`scheme-event-${event.action}`}
                    label={formatText(event.action, true)}
                    classes={{ container: 'input__group', label: 'input__label' }}
                    value={event.timestamp.format(timestampFormatter)}
                />
            })}
        </Box>
    );
};

export type { SchemeWorkflowProps, SchemeWorkflowData };
export default SchemeWorfkflow;