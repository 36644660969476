import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { DateProps } from "../types";

const WaterSourceLastInspectionDate = ({ value }: Pick<DateProps, "value">): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "readonly",
        id: "water-source-last-inspection-date",
        label: "Last Inspection Date",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value,
    };
    return <LabelledField {...props} />;
};

export default WaterSourceLastInspectionDate;