import React from "react";
import css from './filterResultsTable.module.scss';

interface ResultRowProps {
    readonly index: number;
    readonly fields: string[];
    readonly lastFieldAsObjectNodeId?: boolean;
    readonly onClick?: (id: string) => void;
}

const ResultRow = (props: ResultRowProps): JSX.Element => {
    const { index, fields, lastFieldAsObjectNodeId, onClick } = props;
    const fieldsToRender = lastFieldAsObjectNodeId ? fields.slice(0, -1) : fields;

    const handleRowClick = (): void => {
        if (lastFieldAsObjectNodeId) {
            return onClick?.(fields[fields.length - 1]);
        }
        onClick?.(fields[0]);
    }

    const renderField = (field: string, fieldIndex: number): JSX.Element => (
        <td className={css['skinny-td']} key={`row-${index}-field-${fieldIndex}`}>{field}</td>
    );

    return (
        <tr onClick={handleRowClick}>
            {fieldsToRender.map(renderField)}
        </tr>
    );
};

export type { ResultRowProps };
export default ResultRow;