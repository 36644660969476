export default `mutation Remove_Tags($input:EditInt32CollectionInput!) {
    tags {
        remove(input:$input) {
            clientMutationId
            hasErrors
            object {
                id
              __typename
                ... on ITagged {
                    tags {
                        value
                        displayText
                        enabled
                    }
                }
            }
        }
    }
}`