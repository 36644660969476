import React, { useState, useReducer, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { LoadingBar } from "@3tc/shared-components";

import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import AttachmentIcon from "@material-ui/icons/Attachment";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import FactCheckIcon from "../../components/icons/FactCheck";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveIcon from "@material-ui/icons/Save";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from '@material-ui/icons/Error';

import { AccessControl } from "../../auth/components";
import { RoleNames } from "../../auth/roles";
import ActionMenu from "../../components/inspectionView/components/actionMenu";
import ShowLocation from "../../components/inspectionView/components/showMap";
import WaterSourceDetails, { DetailsKeys, DetailsFields } from "../../components/inspectionView/components/waterSourceDetails";
import HazardsDetails from "../../components/inspectionView/components/hazardsDetails";
import WaterSourceLocation from "../../components/inspectionView/components/waterSourceLocation";
import Optional from "../../components/shared/Optional";
import View from "../../components/shared/view";
import TabPanel from "../../components/tabs/TabPanel";
import Hydrant from "../../components/waterSource/waterSourceCategory/hydrant";
import { parseNodeId, NodeID } from "../../lib/nodeIdentifier";
import { ILookupItem, IWaterSource, CreateInspectionData, IAddress, IPoint } from "../../store/types";
import useStyles from "./NearMeWaterSourceView.styles";
import CreateInspection from "../../components/inspectionView/components/createInspection";
import { Box, Divider } from "@material-ui/core";
import WaterSourceTitle, { createTitleProps } from "../../components/waterSource/waterSourceTitle";
import StreetView from "../../components/inspectionView/components/streetView";
import ViewNotes from "../../components/inspectionView/components/viewNotes";
import NotesDetails from "../inspectionView/components/NotesDetails";
import { LocationChangeKeys, LocationChangeValues } from "../../components/inspectionView/components/waterSourceLocation/WaterSourceLocation.view";
import useAddressUpdate from "../inspectionView/hooks/useAddressUpdate";

interface EditState {
    readonly dirty: boolean;
    readonly address: IAddress | undefined;
    readonly coordinates: IPoint | undefined;
    readonly description: string | undefined;
    readonly hydrantLocation: ILookupItem | undefined;
    readonly waterSource: DetailsFields;
}

interface INearMeWaterSourceViewProps {
    readonly waterSource: {
        readonly item: IWaterSource | undefined;
        readonly loading: boolean;
    };
    readonly actionsEnabled?: boolean;
    readonly onRefresh?: () => void;
    readonly onSaveLocation?: (nodeId: NodeID, update: EditState, category: ILookupItem) => void;
    readonly onSaveWaterSource?: (nodeId: NodeID, update: EditState, category: ILookupItem) => void;
    readonly onSaveHazards?: (nodeId: NodeID, update: EditState) => void;
    readonly onCreateInspection: (data: CreateInspectionData) => Promise<void>;
}

type EditActionType =
    "SET_ADDRESS" |
    "SET_LOCATION_DESCRIPTION" |
    "SET_HYDRANT_LOCATION" |
    "SET_WATER_SOURCE";

type EditAction = {
    readonly type: EditActionType;
    readonly value: EditState[keyof EditState];
} | {
    readonly type: "RESET";
    readonly value: IWaterSource | undefined;
};

const defaultWaterSource = { controlMeasures: [] };
const zeroCount = 0;

const initState = (waterSource: IWaterSource | undefined, address?: IAddress): EditState => {
    const initAddress = (): IAddress | undefined => {
        const value = address ?? waterSource?.location?.address;
        return value
            ? { ...value }
            : undefined;
    };
    return {
        dirty: false,
        address: initAddress(),
        coordinates: waterSource?.location?.coordinates,
        description: waterSource?.location?.description,
        hydrantLocation: waterSource?.hydrantLocation,
        waterSource: {
            ...waterSource ?? defaultWaterSource,
            hazardsValues: waterSource?.hazards?.map(hazard => hazard.hazardType.value)
        }
    };
};

const reducer = (state: EditState, action: EditAction): EditState => {
    switch (action.type) {
        case "SET_ADDRESS":
            return {
                ...state,
                dirty: true,
                address: action.value as IAddress
            };
        case "SET_HYDRANT_LOCATION":
            return {
                ...state,
                dirty: true,
                hydrantLocation:
                    action.value as ILookupItem
            };
        case "SET_LOCATION_DESCRIPTION":
            return {
                ...state,
                dirty: true,
                description: action.value as string
            };
        case "SET_WATER_SOURCE":
            return {
                ...state,
                dirty: true,
                waterSource: {
                    ...state.waterSource,
                    ...(action.value as DetailsFields)
                }
            };
        case "RESET":
            return initState(action.value, state.address);
        default:
            return state;
    }
};

enum TabIndex {
    LOCATION = 0,
    WATER_SOURCE = 1,
    HAZARDS = 2,
    INSPECTION = 3,
    DEFECTS = 4,
    ATTACHMENTS = 5
}

const NearMeWaterSourceView = (props: INearMeWaterSourceViewProps): JSX.Element => {
    const {
        waterSource,
        onRefresh,
        onSaveLocation,
        onSaveWaterSource,
        onSaveHazards,
        onCreateInspection
    } = props;

    const styles = useStyles();
    const [tab, setTab] = useState(TabIndex.LOCATION);
    const [editing, setEditing] = useState(false);
    const [editState, editDispatch] = useReducer(reducer, waterSource.item, initState);

    const [showNotesDetails, setShowNotesDetails] = useState<boolean>(false);

    const enableEdit = [TabIndex.LOCATION, TabIndex.WATER_SOURCE, TabIndex.HAZARDS].includes(tab);
    const disableSave = !editState.dirty;

    const resetWaterSource = (waterSource: IWaterSource | undefined): void => {
        editDispatch({ type: "RESET", value: waterSource });
        editDispatch({ type: "SET_ADDRESS", value: waterSource?.location.address });
    };

    useEffect(() => {
        if (!waterSource.loading) {
            resetWaterSource(waterSource.item);
        }
    }, [waterSource.loading, waterSource.item?.waterSourceId]);

    useAddressUpdate(waterSource.item?.location.address, editState, a => editDispatch({ type: "SET_ADDRESS", value: a }));

    if (waterSource.item === undefined && waterSource.loading) {
        return <View><LoadingBar loading /></View>;
    }

    if (waterSource.item === undefined) {
        return <View />;
    }

    const handleChange = (_: React.ChangeEvent<unknown>, value: any): void => {
        setTab(value);
    };

    const handleSwipeChange = (index: number): void => {
        setTab(index);
    };

    const handleEditClick = (): void => {
        setEditing(true);
    };

    const handleEditCancel = (): void => {
        setEditing(false);
        resetWaterSource(waterSource.item);
    };

    const handleEditSave = (): void => {
        if (waterSource.item) {
            setEditing(false);
            const { waterSourceNodeId, category } = waterSource.item;
            switch (tab) {
                case TabIndex.LOCATION:
                    onSaveLocation?.(parseNodeId(waterSourceNodeId), editState, category);
                    return;
                case TabIndex.WATER_SOURCE:
                    onSaveWaterSource?.(parseNodeId(waterSourceNodeId), editState, category);
                    return;
                case TabIndex.HAZARDS:
                    onSaveHazards?.(parseNodeId(waterSourceNodeId), editState);
                    return;
                default:
                    return;
            }
        }
    };

    const handleAddressUpdated = (): void => {
        onRefresh?.();
    };

    const handleLocationChange = (key: LocationChangeKeys, value: LocationChangeValues): void => {
        switch (key) {
            case "address":
                editDispatch({ type: "SET_ADDRESS", value });
                break;
            case "description":
                editDispatch({ type: "SET_LOCATION_DESCRIPTION", value });
                break;
            case "hydrantLocation":
                editDispatch({ type: "SET_HYDRANT_LOCATION", value });
                return;
        }
    };

    const handleWaterSourceChange = (key: DetailsKeys, value: DetailsFields[DetailsKeys]): void => {
        editDispatch({ type: "SET_WATER_SOURCE", value: { [key]: value } });
    };

    const handleMapRefresh = (): void => {
        onRefresh?.();
    };

    const handleViewRefresh = (): void => {
        onRefresh?.();
    };

    const openNotesDetails = (): void => setShowNotesDetails(true);
    const closeNotesDetails = (): void => setShowNotesDetails(false);

    const disableTab = (index: number): boolean => {
        return editing
            ? tab !== index
            : false;
    };

    const handleCreateInspection = async (data: CreateInspectionData): Promise<void> => {
        await onCreateInspection(data);
    }

    const actions = editing
        ? <IconButton key={0} className={styles.appBarButton} edge="end" disabled={disableSave} onClick={handleEditSave}>
            <SaveIcon />
        </IconButton>
        : [
            <AccessControl key={0} any={[RoleNames.WS_EDIT_DETAILS, RoleNames.WS_EDIT_LOCATION]}>
                <Optional hidden={!enableEdit}>
                    <IconButton className={styles.appBarButton} onClick={handleEditClick}>
                        <EditIcon />
                    </IconButton>
                </Optional>
            </AccessControl>,
            <ActionMenu key={1}>
                <ShowLocation focus={waterSource.item} onRefresh={handleMapRefresh} />
                <StreetView waterSourceCoordinates={waterSource.item.location.coordinates} />
                <Divider />
                <ViewNotes openNotesDetails={openNotesDetails} />
            </ActionMenu>
        ];

    const navButton = editing
        ? <IconButton className={styles.appBarButton} edge="start" onClick={handleEditCancel}>
            <CloseIcon />
        </IconButton>
        : true;

    return (
        <View title={<WaterSourceTitle {...createTitleProps(waterSource.item)} />} extendedAppBar primary={navButton} secondary={actions} onRefreshClick={handleViewRefresh}>
            <div className={styles.root}>
                <div className={styles.tabBar}>
                    <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange}>
                        <Tab id={`tab-${TabIndex.LOCATION}`} disabled={disableTab(TabIndex.LOCATION)} label={<Typography variant="caption">Location</Typography>} icon={<LocationOnIcon />} />
                        <Tab id={`tab-${TabIndex.WATER_SOURCE}`} disabled={disableTab(TabIndex.WATER_SOURCE)} label={<Typography variant="caption">Water Source</Typography>} icon={<Hydrant className={styles.waterSourceIcon} />} />
                        <Tab id={`tab-${TabIndex.HAZARDS}`} disabled={disableTab(TabIndex.HAZARDS)} label={<Typography variant="caption">Hazards</Typography>} icon={<WarningIcon />} />
                        <Tab id={`tab-${TabIndex.INSPECTION}`} disabled={disableTab(TabIndex.INSPECTION)} label={<Typography variant="caption">Inspection</Typography>} icon={<FactCheckIcon />} />
                        <Tab id={`tab-${TabIndex.DEFECTS}`} disabled={disableTab(TabIndex.DEFECTS)} label={<Typography variant="caption">Defects</Typography>} icon={<Badge badgeContent={zeroCount} color="secondary" max={9} showZero><ErrorIcon /></Badge>} />
                        <Tab id={`tab-${TabIndex.ATTACHMENTS}`} disabled={disableTab(TabIndex.ATTACHMENTS)} label={<Typography variant="caption">Attachments</Typography>} icon={<Badge badgeContent={zeroCount} color="secondary" max={9} showZero><AttachmentIcon /></Badge>} />
                    </Tabs>
                </div>
                <div className={styles.tabContainer}>
                    <SwipeableViews disabled={editing} index={tab} onChangeIndex={handleSwipeChange} style={{ height: "100%", overflow: "hidden" }} containerStyle={{ height: "100%" }} slideStyle={{ height: "100%", overflow: "hidden" }}>
                        <TabPanel value={tab} index={TabIndex.LOCATION} noMargins>
                            {waterSource.item && 
                                <WaterSourceLocation 
                                    category={waterSource.item.category} 
                                    address={editState.address} 
                                    coordinates={editState.coordinates} 
                                    description={editState.description}
                                    hydrantLocation={editState.hydrantLocation}
                                    editing={editing} 
                                    onAddressUpdated={handleAddressUpdated}
                                    onChange={handleLocationChange} 
                                />
                            }
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.WATER_SOURCE} noMargins>
                            {waterSource && <WaterSourceDetails category={waterSource.item.category} {...editState.waterSource} editing={editing} onChange={handleWaterSourceChange} />}
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.HAZARDS} noMargins>
                            <HazardsDetails {...editState.waterSource} editing={editing} onChange={handleWaterSourceChange} />
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.INSPECTION} noMargins>
                            <Box display="flex" flexDirection="column" height="100%" paddingBottom={3} overflow="auto">
                                <Typography className={styles.noItemsText}>No Open Inspections</Typography>
                                <Box px={2} mt="auto" zIndex={1}>
                                    <CreateInspection selectedWaterSource={waterSource.item} onCreateInspection={handleCreateInspection} />
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.DEFECTS} noMargins>
                            <Typography className={styles.noItemsText}>No Defects</Typography>
                        </TabPanel>
                        <TabPanel value={tab} index={TabIndex.ATTACHMENTS} noMargins>
                            <Typography className={styles.noItemsText}>No Attachments</Typography>
                        </TabPanel>
                    </SwipeableViews>
                </div>
                {waterSource.loading && <LoadingBar loading />}
            </div>
            <NotesDetails
                withAddNote
                objectType="WATER_SOURCE"
                showDialogue={showNotesDetails}
                closeNotesDetails={closeNotesDetails}
                notes={waterSource.item.notes ?? []}
                objectNodeId={waterSource.item.waterSourceNodeId}
                onRefresh={onRefresh}
            />
        </View>
    );
};

export type { EditState }
export default NearMeWaterSourceView;