import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { startUpdateAddress } from "../../../../../../api/updateAddress";
import { AddressDetailsData, toAddressDetails } from "../../../../../address";
import EditAddressBase, { EditAddressProps } from "./EditAddress.view";

const EditAddress = ({ onSelect, onUpdate, ...rest }: EditAddressProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleSelect = useCallback(async (data: AddressDetailsData): Promise<void> => {
        if (data.addressNodeId) {
            const action = startUpdateAddress(data.addressNodeId, data);
            const address = await action(dispatch);
            if (address) {
                onSelect?.(toAddressDetails(address));
            }
        }
    }, [onSelect]);
    const handleUpdate = useCallback(async (data: AddressDetailsData): Promise<void> => {
        if (data.addressNodeId) {
            const action = startUpdateAddress(data.addressNodeId, data);
            const address = await action(dispatch);
            if (address) {
                onUpdate?.(toAddressDetails(address));
            }
        }
    }, [onUpdate]);

    return <EditAddressBase onSelect={handleSelect} onUpdate={handleUpdate} {...rest} />;
};

export default EditAddress;