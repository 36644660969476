import React from 'react';
import { List } from '@3tc/shared-components';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import AccessControl from '../../../auth/components/AccessControl';
import { RoleNames } from '../../../auth/roles';
import { Comparer, compareTimestamps, applyComparers } from '../../../lib/comparers';
import { NodeID, parseNodeId } from '../../../lib/nodeIdentifier';
import { formatLastEvent } from '../../../lib/Utils';
import { IDefect, IInspection, isDefect } from '../../../store/types';
import DefectControl from '../defectControl';

interface IDefectPanelProps {
    readonly defects: IDefect[];
    readonly selectedDefect?: IDefect;
    readonly selectedInspection: IInspection | undefined;
    readonly hideNavButton?: boolean;
    readonly onClearDefect?: () => void;
    readonly onRefreshDefect?: () => void;
    readonly onSelectDefect?: (id: NodeID) => void;
    readonly showCreateDialog: () => void;
    readonly onUpdateDefect: () => void;
}

type ListItem<T> = T & {
    readonly lastEventDescription: string;
    readonly typeDescription: string;
};

type DefectItem = ListItem<IDefect>;

interface ListConfiguration<T, K = keyof ListItem<T>> {
    readonly titleKey: K;
    readonly infoKey: K;
    readonly titleLeftKey: K;
    readonly titleAttrKeys: K[];
    readonly moreMenu?: {
        readonly title: string;
        readonly items: {
            readonly key: string;
            readonly title: string;
            readonly onClick?: (arg: any) => void;
        }[];
    };
}

const configureList = (): ListConfiguration<DefectItem> => ({
    titleKey: "defectId",
    infoKey: "lastEventDescription",
    titleLeftKey: "typeDescription",
    titleAttrKeys: ["defectId", "lastEventDescription", "typeDescription"]
});

export const buildListItems = (defects: IDefect[]): (IDefect & { lastEventDescription: string; typeDescription: string })[] => {
    return defects
        ?.map((defect) => {
            return {
                ...defect,
                lastEventDescription: formatLastEvent(defect.lastEvent),
                typeDescription: defect.type.displayText
            }
        })
        .sort((a, b) => {
            const comparers: Comparer<IDefect>[] = [
                (a, b): number => compareTimestamps(a, b, "created"),
                (a, b): number => a.defectId - b.defectId
            ];
            const result = applyComparers(comparers, a, b);
            return result;
        })
        .reverse();
};

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: "white"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1)
    }
}));

const DefectList = (props: Pick<IDefectPanelProps, "defects" | "onSelectDefect" | "showCreateDialog">): JSX.Element => {
    const { defects, onSelectDefect, showCreateDialog } = props;
    const styles = useStyles();

    const handleDefectClick = (item: Record<string, unknown>): void => {
        if (isDefect(item)) {
            onSelectDefect?.(parseNodeId(item.defectNodeId));
        }
    };

    const listConfig = configureList();
    return (
        <div className={styles.root}>
            <AccessControl role={RoleNames.DEFECTS_ALL}>
                <div className={styles.buttonContainer}>
                    <button className="action-button" onClick={showCreateDialog}>
                        Add New Defect
                    </button>
                </div>
            </AccessControl>
            <List items={buildListItems(defects)} config={listConfig} clickHandler={handleDefectClick} />
        </div>
    );
};
const DefectPanel = (props: IDefectPanelProps): JSX.Element => {
    const { defects, selectedDefect, hideNavButton, onClearDefect, onRefreshDefect, onSelectDefect, showCreateDialog, onUpdateDefect } = props;
    return (
        <div>
            {selectedDefect
                ? <DefectControl selectedDefect={selectedDefect} hideNavButton={hideNavButton} onClose={onClearDefect} onRefresh={onRefreshDefect} onDefectUpdate={onUpdateDefect} />
                : <DefectList defects={defects} onSelectDefect={onSelectDefect} showCreateDialog={showCreateDialog} />
            }
        </div>
    );
};

export type { IDefectPanelProps };
export default DefectPanel;