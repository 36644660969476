export default `
mutation completeInspection_Mutation($inspection: EditCompleteInspectionInput!) {
    inspection {
        complete(input:$inspection) {
            clientMutationId
            hasErrors
            errors {
                message
                path
            }
            inspection {
                inspectionNodeId: id
                inspectionId
                waterSource {
                    waterSourceNodeId: id
                }
                type {
                    displayText
                    value
                }
                inspectionDate
                description
                isOpen
                isCancelled
                isCompleted
                isAssignedToGroup
                isAssignedToInspector
                technician {
                    technicianNodeId: id
                    technicianId
                    displayName
                    userName
                }
                repairs {
                    repairType {
                        displayText
                        value
                    }
                    repairCategory {
                        displayText
                        value
                    }
                }
                crew {
                    crewNodeId: id
                    crewId
                    callSign
                }
                inspectionGroup {
                    inspectionGroupNodeId: id
                    inspectionGroupId
                    name
                }
                test {
                    displayText
                    value
                }
                result {
                    displayText
                    value
                }
                cancelReason {
                    displayText
                    value
                }
                events {
                    timestamp
                    action
                    actor
                }
                lastEvent {
                    timestamp
                    action
                    actor
                }
                notes {
                    noteNodeId: id
                    noteId
                    timestamp
                    author
                    content
                }
            }
        }
    }
}`
