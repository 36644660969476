import React from "react";

import Box from "@material-ui/core/Box";

import WaterSourceTitle, { createTitleProps } from "../../../components/waterSource/waterSourceTitle";
import { IInspection } from "../../../store/types";
import { useListNavigation, useListContext } from "../../inspectionListView/listNavigationContext";
import InspectionNavButton from "./InspectionNavButton";
import InspectionNavPosition from "./InspectionNavPosition";

interface InspectionTitleProps {
    readonly disabled?: boolean
    readonly inspection?: IInspection;
}

const InspectionTitle = (props: InspectionTitleProps): JSX.Element => {
    const { disabled, inspection } = props;

    const { count, index, isFirst, isLast } = useListContext(inspection?.inspectionNodeId);
    const { gotoNextItem, gotoPrevItem } = useListNavigation(inspection?.inspectionNodeId);

    if (inspection) {
        const handlePrevClick = (): void => {
            gotoPrevItem();
        };
        const handleNextClick = (): void => {
            gotoNextItem();
        };

        const { inspectionId, waterSource } = inspection;

        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                <InspectionNavButton direction="prev" hide={isFirst} disabled={disabled} onClick={handlePrevClick} />
                <WaterSourceTitle {...createTitleProps(waterSource)} subtitle={inspectionId} />
                <Box flexGrow={1} />
                <InspectionNavPosition itemCount={count} itemIndex={index} />
                <InspectionNavButton direction="next" hide={isLast} disabled={disabled} onClick={handleNextClick} />
            </Box>
        );
    }
    return <React.Fragment />
};

export type { InspectionTitleProps };
export default InspectionTitle;