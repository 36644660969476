import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { LocalDate } from "@js-joda/core";

import { IWaterSourceAction, IWaterSource, IMapWaterSource, ICreateWaterSourceVariables, IEditHydrantInput, IEditEmergencyWaterSupplyInput, IEditRiserOrUnknownInput, IUpdateWaterSourceActivationInput, ICreateWaterSourceInput } from "../types/waterSources";
import { setRenderPoints, navigateToLocationOnMap } from './map';
import { setError } from './errors';
import { setGlobalToast, shouldReloadWaterSources, setLoadingToast, setDataTabSelectedTab, setSuccessToast } from './app';
import { startAddWaterSourceToScheme } from './schemes';
import { beginUpdateRouteWaterSources } from './routes';
import { IGlobalToast, ILookupItem } from "../types/app";
import getWaterSourceQuery from './graphQL/getWaterSource';
import getMapWaterSourcesQuery from './graphQL/getMapWaterSources';
import getFilteredWaterSources from './graphQL/getWaterSourcesByFilter';
import getBasicWaterSourceDetailsWithFilterQuery from './graphQL/getBasicWaterSourceDetailsWithFilter'

import createHydrantMutation from './graphQL/createHydrant';
import createEWSMutation from './graphQL/createEWS';
import createRiserMutation from './graphQL/createRiser';
import editHydrantQuery from './graphQL/editHydrant';
import editEWSQuery from './graphQL/editEWS';
import editRiserQuery from './graphQL/editRiser';
import editUnknownWaterSourceQuery from './graphQL/editUnknownWaterSource';

import updateWaterSourceActivationQuery from './graphQL/updateWaterSourceActivation';
import executeQuery from '../../lib/executeQuery';
import { IRoute } from '../types/routes';
import { guid, formatText } from "../../lib/Utils";

import { clearDefects, setSelectedDefect } from "../actions/defects";
import { clearInspections, setSelectedInspection } from "../actions/inspections";
import { clearRepairs, setSelectedRepair } from "../actions/repairs";
import { IHazard } from "../types/hazards";
import { IPageInfo, IConnection, emptyPage, IConnectionQuery } from "../types/connections";

import { IWaterSourceSelectedFilters } from "../../components/filters/waterSourceSearch/types";
import { compareWaterSources } from "../../components/routes/routePanel/RoutePanel.utils";

import { buildQueryFilter } from "../../components/filters/waterSourceSearch/utils";
import { OptionValue } from "../../components/labelledField";
import store from '../index';
import { parseNodeId } from "../../lib/nodeIdentifier";
import { IOperationalStatus, IPoint } from "../types";
import { IWaterSourceSummary } from "../../components/inspectionView";

export const setWaterSources = (requestId: string, waterSources: IMapWaterSource[], totalCount: number): IWaterSourceAction => ({
    type: 'SET_WATER_SOURCES',
    requestId,
    waterSources,
    waterSourcesTotalCount: totalCount
});

export const updateWaterSource = (waterSource: IWaterSourceSummary): IWaterSourceAction => ({
    type: "UPDATE_WATER_SOURCE",
    updatedWaterSource: waterSource
});

export const setPaginatedWaterSources = (requestId: string, waterSources: IMapWaterSource[], waterSourcesPageInfo: IPageInfo, totalCount: number): IWaterSourceAction => ({
    type: 'SET_WATER_SOURCES',
    requestId,
    waterSources,
    waterSourcesPageInfo,
    waterSourcesTotalCount: totalCount
});

export const addWaterSource = (newWaterSource: IWaterSource): IWaterSourceAction => ({
    type: 'ADD_WATER_SOURCE',
    newWaterSource
});

export const setEditWaterSourceSuccess = (editWaterSourceSuccess?: boolean): IWaterSourceAction => ({
    type: 'SET_EDIT_WATER_SOURCE_SUCCESS',
    editWaterSourceSuccess
});

export const editWaterSource = (editedWaterSource: IMapWaterSource, tags?: ILookupItem[]): IWaterSourceAction => ({
    type: 'EDIT_WATER_SOURCE',
    editedWaterSource,
    tags
});

export const addHazardsToSelectedWaterSource = (waterSourceHazards: IHazard[]): IWaterSourceAction => ({
    type: 'ADD_WATER_SOURCE_HAZARDS',
    waterSourceHazards
});

export const updateHazardsOnSelectedWaterSource = (waterSourceHazards: IHazard[]): IWaterSourceAction => ({
    type: 'UPDATE_WATER_SOURCE_HAZARDS',
    waterSourceHazards
});

export const removeHazardsFromSelectedWaterSource = (waterSourceHazards: IHazard[]): IWaterSourceAction => ({
    type: 'REMOVE_WATER_SOURCE_HAZARDS',
    waterSourceHazards
});

export const addTagsToSelectedWaterSource = (waterSourceTags: ILookupItem[]): IWaterSourceAction => ({
    type: 'ADD_WATER_SOURCE_TAGS',
    waterSourceTags
});

export const removeTagsFromSelectedWaterSource = (waterSourceTags: ILookupItem[]): IWaterSourceAction => ({
    type: 'REMOVE_WATER_SOURCE_TAGS',
    waterSourceTags
});

export const setSelectedWaterSourceLastInspectionDate = (lastInspectionDate: LocalDate | undefined): IWaterSourceAction => ({
    type: 'SET_SELECTED_WATER_SOURCE_LAST_INSPECTION_DATE',
    lastInspectionDate
});

export const setEditWaterSource = (editWaterSource: boolean): IWaterSourceAction => ({
    type: 'SET_EDIT_WATER_SOURCE',
    editWaterSource
});

export const setWaterSourceRequestId = (requestId: string): IWaterSourceAction => ({
    type: 'SET_WATER_SOURCE_REQUEST_ID',
    requestId
});

export const setIsEditingWaterSourceAborted = (isEditingWaterSourceAborted: boolean): IWaterSourceAction => ({
    type: 'SET_IS_EDITING_WATER_SOURCE_ABORTED',
    isEditingWaterSourceAborted
})

export const getWaterSources = (variables: Record<string, unknown>, toastMessage = 'Fetching all water sources...') => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            const requestId: string = guid();
            dispatch(setWaterSourceRequestId(requestId));
            dispatch(shouldReloadWaterSources(false));
            dispatch(setLoadingToast(toastMessage));
            const data = await executeQuery<{ waterSources: IMapWaterSource[] }>(getMapWaterSourcesQuery, variables);
            const newData = data?.waterSources.map((waterSource) => {
                return {
                    ...waterSource,
                    show: true
                }
            }) ?? [];
            dispatch(setWaterSources(requestId, newData, newData.length));
            dispatch(setRenderPoints(true));
            dispatch(setGlobalToast());
        } catch (err) {
            dispatch(setError('Error getting water sources', err));
        }
    }
}

export const getWaterSourcesByFilter = (filters: IWaterSourceSelectedFilters, pageIndex: number, forMap: boolean, basicDataOnly = false) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            const requestId = guid();

            dispatch(setWaterSourceRequestId(requestId));
            dispatch(shouldReloadWaterSources(false));
            if (forMap) {
                dispatch(setLoadingToast('Loading water sources...'));
            }

            const filterQueryInput = buildQueryFilter(filters);
            const input: IConnectionQuery<Record<string, unknown>> = {
                filter: filterQueryInput,
                paging: {
                    pageIndex,
                    pageSize: forMap ? 999999 : 50,
                    sortBy: "inspectionDate",
                    sortDirection: "DESCENDING"
                }
            };
            const query: string = basicDataOnly ? getBasicWaterSourceDetailsWithFilterQuery : getFilteredWaterSources
            const data = await executeQuery<{ waterSources: IConnection<IMapWaterSource> }>(query, input);

            const { items, pageInfo, totalCount } = data?.waterSources ?? {};

            dispatch(setPaginatedWaterSources(requestId, items ?? [], pageInfo ?? emptyPage, totalCount ?? 0));
            if (forMap) {
                dispatch(setRenderPoints(true));
                const type = (totalCount ?? 0) > 0 ? 'SUCCESS' : 'ERROR';
                const message = (totalCount ?? 0) > 0 ? `Showing ${totalCount} water sources` : 'No water sources were found';
                dispatch(setGlobalToast({
                    type,
                    message,
                    showToast: true,
                    timeout: 4000
                }));
            }
        }
        catch (err) {
            dispatch(setError('Error loading water sources', err));
        }
    }
};

export const getWaterSource = (variables: { id: string }, setLoading = true) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast('Getting water source data...'));
            }

            const data = await executeQuery<{ node: IWaterSource | undefined }>(getWaterSourceQuery, variables);
            dispatch(setSelectedWaterSource(data?.node));

            if (setLoading) {
                dispatch(setGlobalToast());
            }

        } catch (err) {
            dispatch(setError('Error getting water source', err));
        }
    }
}

export const navigateToWaterSource = (waterSource: string, position: IPoint) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        try {
            dispatch(setLoadingToast('Navigating to water source...'));
            const coordinates = [position.x, position.y];
            dispatch(navigateToLocationOnMap(coordinates, false, waterSource));

        } catch (error) {
            dispatch(setError('Error navigating to water source', error));
        }
    };
};

export const navigateToAndGetWaterSource = (variables: { id: string }) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Navigating to water source...'));

            const data = await executeQuery<{ node: IWaterSource | undefined }>(getWaterSourceQuery, variables);
            if (data?.node) {
                const { waterSourceId, location } = data.node;
                const coordinates = [location.coordinates.x, location.coordinates.y];
                dispatch(navigateToLocationOnMap(coordinates, false, variables.id));
                dispatch(setSelectedWaterSource(data.node));
                dispatch(setDataTabSelectedTab('WATER_SOURCE'));
                dispatch(setSelectedInspection(undefined));
                dispatch(setSelectedDefect(undefined));
                dispatch(setSelectedRepair(undefined));
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Water source ${waterSourceId} selected`,
                    showToast: true,
                    timeout: 5000
                }

                dispatch(setGlobalToast(globalToast));
            }

        } catch (err) {
            dispatch(setError('Error navigating to water source', err));
        }
    }
}

interface CreateRequest<T extends "hydrant" | "emergencyWaterSupply" | "riser"> {
    readonly waterSource: {
        readonly create: {
            readonly [P in T]: {
                readonly waterSource: IWaterSource
            }
        }
    }
}

const createWaterSource = (category: "hydrant" | "emergencyWaterSupply" | "riser", gql: string) => {
    return (data: ICreateWaterSourceInput, schemeNodeId: string | undefined, route: IRoute | undefined, addToMap: boolean) => {
        return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<IWaterSource | undefined> => {
            try {
                dispatch(setLoadingToast(`Creating new ${category}...`));

                const request: ICreateWaterSourceVariables = {
                    input: {
                        clientMutationId: guid(),
                        data
                    }
                };
                const response = await executeQuery<CreateRequest<typeof category>>(gql, request);

                const { waterSource } = response?.waterSource.create[category] ?? {};
                if (waterSource) {
                    console.log(category, formatText(category, true, "", true))
                    dispatch(setSuccessToast(`${formatText(category, true, "", true)} ${waterSource.waterSourceId} created`, 5000));

                    if (schemeNodeId) {
                        await dispatch(startAddWaterSourceToScheme(parseNodeId(schemeNodeId), parseNodeId(waterSource.waterSourceNodeId)));
                    }

                    if (route) {
                        const routeWaterSources = route.waterSources
                            .sort(compareWaterSources)
                            .map((ws) => ws.waterSourceNodeId);
                        await dispatch(beginUpdateRouteWaterSources(route.routeNodeId, [...routeWaterSources, waterSource.waterSourceNodeId], { refreshMap: true }));
                    }

                    if (addToMap) {
                        dispatch(addWaterSource(waterSource));
                        dispatch(setRenderPoints(true));
                    }
                }
                return waterSource;
            }
            catch (error) {
                dispatch(setError(`Error creating ${formatText(category, false, "", true).toLocaleLowerCase()}`, error));
            }
        };
    };
};

export const createHydrant = createWaterSource("hydrant", createHydrantMutation);
export const createEmergencyWaterSupply = createWaterSource("emergencyWaterSupply", createEWSMutation);
export const createRiser = createWaterSource("riser", createRiserMutation);

interface UpdateRequest<T extends "hydrant" | "emergencyWaterSupply" | "riser" | "unknown"> {
    readonly waterSource: {
        readonly update: {
            readonly [P in T]: {
                readonly waterSource: IWaterSource
            }
        }
    }
}

export const editHydrant = (variables: IEditHydrantInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to hydrant...'));

            const response = await executeQuery<UpdateRequest<"hydrant">>(editHydrantQuery, variables);

            const { waterSource } = response?.waterSource.update.hydrant ?? {};
            if (waterSource) {
                dispatch(editWaterSource(waterSource, tags));
                dispatch(setEditWaterSourceSuccess(true));
                // triggers reload of edited data, assumes the edited water source is selected waterSource
                dispatch(setSelectedWaterSource(waterSource));

                dispatch(setGlobalToast({
                    type: 'SUCCESS',
                    message: `Changes saved to hydrant ${waterSource.waterSourceId}`,
                    showToast: true,
                    timeout: 5000
                }));

                if (triggerMapReRender) {
                    dispatch(setRenderPoints(true));
                }
            }
        }
        catch (err) {
            dispatch(setError('Error saving changes to hydrant', err));
        }
    }
}

export const editEmergencyWaterSupply = (variables: IEditEmergencyWaterSupplyInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to emergency water supply...'));

            const response = await executeQuery<UpdateRequest<"emergencyWaterSupply">>(editEWSQuery, variables);

            const { waterSource } = response?.waterSource.update.emergencyWaterSupply ?? {};
            if (waterSource) {
                dispatch(editWaterSource(waterSource, tags));
                dispatch(setEditWaterSourceSuccess(true));
                // triggers reload of edited data, assumes the edited water source is selected waterSource
                dispatch(setSelectedWaterSource(waterSource));

                dispatch(setGlobalToast({
                    type: 'SUCCESS',
                    message: `Changes saved to emergency water supply ${waterSource.waterSourceId}`,
                    showToast: true,
                    timeout: 5000
                }));

                if (triggerMapReRender) {
                    dispatch(setRenderPoints(true));
                }
            }
        }
        catch (err) {
            dispatch(setError('Error saving changes to emergency water supply', err));
        }
    }
}

export const editRiser = (variables: IEditRiserOrUnknownInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to riser...'));

            const riserData = await executeQuery<UpdateRequest<"riser">>(editRiserQuery, variables);

            const { waterSource } = riserData?.waterSource.update.riser ?? {};
            if (waterSource) {
                dispatch(editWaterSource(waterSource, tags));
                dispatch(setEditWaterSourceSuccess(true));
                // triggers reload of edited data, assumes the edited water source is selected waterSource
                dispatch(setSelectedWaterSource(waterSource));

                dispatch(setGlobalToast({
                    type: 'SUCCESS',
                    message: `Changes saved to riser ${waterSource.waterSourceId}`,
                    showToast: true,
                    timeout: 5000
                }));

                if (triggerMapReRender) {
                    dispatch(setRenderPoints(true));
                }
            }
        }
        catch (err) {
            dispatch(setError('Error saving changes to riser', err));
        }
    }
}

export const editUnknownWaterSource = (variables: IEditRiserOrUnknownInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to water source...'));

            const waterSourceData = await executeQuery<UpdateRequest<"unknown">>(editUnknownWaterSourceQuery, variables);

            const { waterSource } = waterSourceData?.waterSource.update.unknown ?? {};
            if (waterSource) {
                dispatch(editWaterSource(waterSource, tags));
                dispatch(setEditWaterSourceSuccess(true));
                // triggers reload of edited data, assumes the edited water source is selected waterSource
                dispatch(setSelectedWaterSource(waterSource));

                dispatch(setGlobalToast({
                    type: 'SUCCESS',
                    message: `Changes saved to water source ${waterSource.waterSourceId}`,
                    showToast: true,
                    timeout: 5000
                }));

                if (triggerMapReRender) {
                    dispatch(setRenderPoints(true));
                }
            }
        }
        catch (err) {
            dispatch(setError('Error saving changes to water source', err));
        }
    }
}

export const startUpdateWaterSourceActivation = (variables: IUpdateWaterSourceActivationInput) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Updating water source activity...'));
            const res = await executeQuery<{ changeWaterSourceActivation: { waterSource: IWaterSource } }>(updateWaterSourceActivationQuery, variables);
            const { waterSource } = res?.changeWaterSourceActivation ?? {};
            if (waterSource) {
                const resultStatus = variables.waterSource.data.toLowerCase();

                const waterSources = store.getState().waterSources.waterSources?.map(waterSource => {
                    if (waterSource.waterSourceNodeId === variables.waterSource.nodeId) {
                        return {
                            ...waterSource,
                            isActive: variables.waterSource.data.toUpperCase() === 'ACTIVE'
                        }
                    }

                    return waterSource;
                });

                if (waterSources) {
                    const requestId = guid();
                    dispatch(setWaterSourceRequestId(requestId));
                    dispatch(setWaterSources(requestId, waterSources, waterSources?.length))
                    dispatch(setRenderPoints(true));
                }

                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Water source ${waterSource.waterSourceId} is now ${resultStatus}`,
                    showToast: true,
                    timeout: 5000
                };

                dispatch(setGlobalToast(globalToast));
            }
        } catch (err) {
            dispatch(setError('Error updating activation status for Water Source', err));
        }
    }
}

export const savePumpTypes = (waterSourceNodeId: string, values: OptionValue[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast("Updating pump types..."));

            const variables: IEditEmergencyWaterSupplyInput = {
                input: {
                    clientMutationId: guid(),
                    nodeId: waterSourceNodeId,
                    data: {
                        pumpTypes: values.map(value => Number(value))
                    }
                }
            };
            await executeQuery<UpdateRequest<"emergencyWaterSupply">>(editEWSQuery, variables);

            dispatch(setSuccessToast("Updated pump types", 5000));
        }
        catch (error) {
            dispatch(setError("Error updating pump types.", error));
        }
    };
}

export const setSelectedWaterSource = (waterSource?: IWaterSource) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        if (waterSource === undefined) {
            dispatch(clearDefects());
            dispatch(clearInspections());
            dispatch(clearRepairs());
        }
        dispatch({
            type: 'SET_SELECTED_WATER_SOURCE',
            selectedWaterSource: waterSource
        });
    }
}

export const updateWaterSourceOperability = (waterSourceNodeId: string, isDefective: IOperationalStatus, isOperable: IOperationalStatus) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        dispatch({
            type: 'UPDATE_WATER_SOURCE_OPERABILITY',
            updatedOperability: {
                isDefective,
                isOperable,
                waterSourceNodeId
            }
        });
        dispatch(setRenderPoints(true));
    }
}