import { styled } from "@material-ui/core/styles";
import List from "@material-ui/core/List";

const TabList = styled(List)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    height: "100%",
    overflowY: "auto",
    position: "unset",
}));

export default TabList;