import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import { longDateFormatter } from "../../../lib/Utils";
import { IInspection } from "../../../store/types";
import { useNavigation } from "../../../router/MobileRouter/hooks";
import { EmptyItem } from "../../inspectionList/components";

interface InspectionSelectorListProps {
    readonly inspections: IInspection[];
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        height: "100%",
        flexGrow: 1,
        overflowY: "hidden"
    },
    list: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
        height: "100%",
        overflowY: "auto"
    },
    subheader: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
    },
    item: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
    }
}));

const bindReducer = (groupBy?: (edge: IInspection) => string) =>
    (groups: Record<string, IInspection[]>, current: IInspection): Record<string, IInspection[]> => {
        const label = groupBy?.(current) ?? "";
        const edges = groups[label] ?? [];
        return {
            ...groups,
            [label]: [...edges, current]
        };
    };

const renderGroup = (group: string, nodes: IInspection[], styles: Record<"subheader" | "item", string>, gotoInspection: (nodeId: NodeID) => void): JSX.Element => {
    const handleClick = (inspection: IInspection): void => {
        gotoInspection(parseNodeId(inspection.inspectionNodeId));
    };
    return (
        <li key={group}>
            <List disablePadding>
                <ListSubheader className={styles.subheader}>
                    {group}
                </ListSubheader>
                {nodes.map(node => (
                    <ListItem button key={node.inspectionId} className={styles.item} onClick={(): void => handleClick(node)} >
                        <ListItemText primary={node.inspectionId} />
                        <ListItemSecondaryAction className={styles.item}>
                            <ListItemText primary={node.inspectionDate?.format(longDateFormatter)} primaryTypographyProps={{ variant: "caption" }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </li>
    );
};

const renderGroups = (groups: Record<string, IInspection[]>, styles: Record<"subheader" | "item", string>, gotoInspection: (nodeId: NodeID) => void): JSX.Element => {
    const pairs = Object.entries(groups);
    return (
        <React.Fragment>{
            pairs.length > 0
                ? pairs
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([key, value]) => renderGroup(key, value, styles, gotoInspection))
                : <EmptyItem message="No open inspections." />
        }</React.Fragment>
    );
};

const InspectionSelectorList = (props: InspectionSelectorListProps): JSX.Element => {
    const styles = useStyles();
    const { gotoInspection } = useNavigation();
    const { inspections: edges } = props;
    const makeGroups = bindReducer(node => node.type.displayText);
    const groups = edges.reduce(makeGroups, {});
    return (
        <div className={styles.root} >
            <List className={styles.list} disablePadding>
                {renderGroups(groups, styles, gotoInspection)}
            </List>
        </div>
    );
}

export type { InspectionSelectorListProps };
export default InspectionSelectorList;