import React, { useState, useRef, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from "@material-ui/core/Typography";

import Optional from "../../../shared/Optional";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => createStyles({
    root: {

    },
    button: {
        color: theme.palette.primary.contrastText
    },
    menu: {
        minWidth: theme.spacing(25),
        zIndex: 10
    },
    warning: {
        fontStyle: "italic",
        color: theme.palette.error.main
    }
}));

interface RepairMenuProps {
    readonly isOpenRepair: boolean;
    readonly isExternalRepair: boolean;
    readonly hasDefect: boolean;
    readonly hasVendor: boolean;
    readonly onCancelRepair?: () => void;
    readonly onCloseRepair?: () => void;
    readonly onEdit?: () => void;
    readonly onSendEmail?: () => void;
}

const RepairMenu = (props: RepairMenuProps): JSX.Element => {
    const { isOpenRepair, isExternalRepair, hasDefect, hasVendor } = props;

    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement>(null);

    const handleToggle = (): void => {
        setOpen(previous => !previous);
    };

    const handleClose = (): void => {
        if (anchor.current?.contains(event?.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchor.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleEditClick = (): void => {
        handleClose();
        props.onEdit?.();
    };
    const handleSendEmailClick = (): void => {
        handleClose();
        props.onSendEmail?.();
    }
    const handleCancelRepairClick = (): void => {
        handleClose();
        props.onCancelRepair?.();
    };
    const handleCloseRepairClick = (): void => {
        handleClose();
        props.onCloseRepair?.();
    };

    const emailEnabled = hasDefect && hasVendor;
    return (
        <div className={styles.root}>
            <IconButton ref={anchor} className={styles.button} aria-controls={open ? "repair-menu" : undefined} aria-haspopup="true" onClick={handleToggle}>
                <MenuIcon />
            </IconButton>
            <Popper className={styles.menu} open={open} anchorEl={anchor.current} role={undefined} transition disablePortal placement="bottom-start">
                {({ TransitionProps }): JSX.Element => (
                    <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="repair-menu" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleEditClick}>Edit Repair</MenuItem>
                                    <Optional hidden={!isOpenRepair}>
                                        <Optional hidden={!isExternalRepair}>
                                            <Divider />
                                            <MenuItem onClick={handleSendEmailClick} disabled={!emailEnabled}>Email Repair to Vendor</MenuItem>
                                            <Box display="flex" flexDirection="column" paddingLeft={2} paddingRight={2}>
                                                <Optional hidden={hasDefect}>
                                                    <Typography variant="caption" className={styles.warning}>A defect is required.</Typography>
                                                </Optional>
                                                <Optional hidden={hasVendor}>
                                                    <Typography variant="caption" className={styles.warning}>A vendor with a valid e-mail address is required.</Typography>
                                                </Optional>
                                            </Box>
                                        </Optional>
                                        <Divider />
                                        <MenuItem onClick={handleCloseRepairClick}>Close Repair</MenuItem>
                                        <MenuItem onClick={handleCancelRepairClick}>Cancel Repair</MenuItem>
                                    </Optional>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};



export type { RepairMenuProps };
export default RepairMenu;