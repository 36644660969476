import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { MultiSelectProps } from "../types";
import EwsManagePumpTypes from "./EwsManagePumpTypes";

const EwsPumpTypes = ({ selected, ...rest }: MultiSelectProps): JSX.Element => {
    const text = selected.length === 0
        ? "-"
        : selected.map(item => item.displayText).join(", ");
    const props: LabelledFieldProps = {
        fieldType: "readonly",
        id: "water-source-pumpTypes",
        label: "Pump Types",
        classes: { container: 'input__group', label: 'input__label' },
        value: text
    };
    return (
        <React.Fragment>
            <LabelledField {...props} />
            <EwsManagePumpTypes selected={selected} {...rest} />
        </React.Fragment>
    );
};

export default EwsPumpTypes;