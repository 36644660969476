import React from "react";
import { Link } from 'react-router-dom';
import * as H from "history";

import { ReactComponent as Logo } from "../../../images/3tc-logo.svg";

import { makeStyles, createStyles } from '@material-ui/core/styles';
import SvgIcon from "@material-ui/core/SvgIcon";


const home: ((location: H.Location) => H.LocationDescriptor) = (): H.LocationDescriptor => ({
    pathname: "/",
    state: { clearMapFilters: true }
});

const useStyles = makeStyles(theme => createStyles({
    root: {
        alignItems: "center",
        border: "none",
        borderRadius: "4px",
        display: "flex",
        height: "64px",
        justifyContent: "center",
        lineHeight: 1,
        margin: 0,
        outline: 0,
        padding: 0,
        width: "96px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.main
        }
    },
    logo: {
        fontSize: 64
    }
}));

const AppBarHome = (): JSX.Element => {
    const styles = useStyles();
    return (
        <Link id="appBar-home" to={home} className={styles.root}>
            <SvgIcon component={Logo} viewBox="0 0 311.8 170.1" className={styles.logo} />
        </Link>
    );
};

export default AppBarHome;