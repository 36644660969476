import { NodeID, NodeType, parseNodeId } from "../../../../../lib/nodeIdentifier";
import { WaterSourceTab } from "../../../../dataTabs/DataTabsContext";
import { WaterSourceHistoryItem } from "./types";

export const getObjectCategory = (nodeId: NodeID): string => {
    switch (nodeId.typeName) {
        case NodeType.INSPECTION:
            return "inspection";
        case NodeType.DEFECT:
            return "defect";
        case NodeType.REPAIR:
            return "repair";
        case NodeType.EWS:
        case NodeType.HYDRANT:
        case NodeType.RISER:
        case NodeType.UNKNOWN_WATERSOURCE:
            return "waterSource";
        default:
            throw new Error(`Unknown node type '${nodeId.typeName}'.`);
    }
};

export const getTabIndex = (nodeId: NodeID): number => {
    switch (nodeId.typeName) {
        case NodeType.INSPECTION:
            return WaterSourceTab.INSPECTIONS;
        case NodeType.DEFECT:
            return WaterSourceTab.DEFECTS;
        case NodeType.REPAIR:
            return WaterSourceTab.REPAIRS;
        case NodeType.EWS:
        case NodeType.HYDRANT:
        case NodeType.RISER:
        case NodeType.UNKNOWN_WATERSOURCE:
            return WaterSourceTab.WATER_SOURCE;
        default:
            throw new Error(`Unknown node type '${nodeId.typeName}'.`);
    }
};

export const formatOutcome = (history: WaterSourceHistoryItem): string => {
    if (getObjectCategory(parseNodeId(history.objectNodeId)) === "inspection") {
        const cancelReason = history.cancelReason
            ? ` (${history.cancelReason})`
            : null;
        const outcome = [history.outcome, cancelReason].filter(s => s).join(" ");
        const result = [history.activity, outcome].filter(s => s).join(", ");
        return result;
    }
    return "";
};
