import React from "react";
import { Pagination } from "@3tc/shared-components";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { AddressNode } from "../../store/types";
import LoadingContainer from "../shared/loadingContainer";
import AddressSearchItem, { RenderAction } from "./AddressSearchItem";

interface AddressSearchClasses {
    readonly header?: string;
}

interface AddressSearchResultsProps {
    readonly loading?: boolean;
    readonly totalCount: number;
    readonly pageNumber: number;
    readonly pageCount: number;
    readonly addresses: AddressNode[];
    readonly paging?: boolean;
    readonly classes?: AddressSearchClasses;
    readonly theme?: "dark" | "light";
    readonly renderItemEdit?: RenderAction;
    readonly onGoToFirst?: () => void;
    readonly onGoToLast?: () => void;
    readonly onGoToPrevious?: () => void;
    readonly onGoToNext?: () => void;
    readonly onSelect?: (address: AddressNode) => void;
}

const useHeaderStyles = makeStyles(theme => createStyles<"root", HeaderProps>({
    root: {
        color: ({ theme: themeType }): string => {
            switch (themeType) {
                case "dark":
                    return theme.palette.secondary.main;
                case "light":
                    return theme.palette.primary.main;
                default:
                    return theme.palette.primary.contrastText
            }
        }
    }
}));

const usePagingStyles = makeStyles(theme => createStyles({
    paging: {
        marginBottom: theme.spacing(2),
        "& > div": {
            display: "flex",
            flexDirection: "column",
            "& > div": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "unset",
                "& > p": {
                    fontSize: "11px"
                }
            }
        },
        "& button": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            height: "32px",
            width: "32px"
        },
        "& svg": {
            width: "18px",
            height: "18px"
        }
    }
}));

type HeaderProps = Pick<AddressSearchResultsProps, "totalCount" | "theme"> & {
    readonly itemCount: number;
};
const Header = (props: HeaderProps): JSX.Element => {
    const { itemCount, totalCount } = props;
    const styles = useHeaderStyles(props);
    return <Typography variant="body2" className={styles.root}>{`Showing ${itemCount} of ${totalCount} addresses`}</Typography>;
};

const AddressSearchResults = (props: AddressSearchResultsProps): JSX.Element => {
    const { loading, totalCount, pageNumber, pageCount, addresses, paging, theme, renderItemEdit, onGoToFirst, onGoToLast, onGoToNext, onGoToPrevious, onSelect } = props;
    const styles = usePagingStyles();
    if (loading) {
        return (
            <Box marginTop={2}>
                <LoadingContainer isLoading={loading} />
            </Box>
        );
    }
    const gutterSize = 0;
    return (
        <Box marginTop={2}>
            <Header itemCount={addresses.length} totalCount={totalCount} theme={theme} />
            <Box marginTop={2} marginLeft={gutterSize} marginRight={gutterSize}>
                {addresses.map(address => <AddressSearchItem key={address.addressNodeId} address={address} renderEdit={renderItemEdit} onSelect={onSelect} />)}
            </Box>
            {paging
                ? <Box className={styles.paging}>
                    <Pagination
                        tableContent={addresses}
                        totalResults={totalCount}
                        pageNumber={pageNumber}
                        pageCount={pageCount}
                        firstPageClickHandler={onGoToFirst}
                        lastPageClickHandler={onGoToLast}
                        nextPageClickHandler={onGoToNext}
                        previousPageClickHandler={onGoToPrevious}
                    />
                </Box>
                : <Box display="flex" flexDirection="row" alignItems="center" height={56} minHeight={56} mb={2} mt={1}>
                    <Header itemCount={addresses.length} totalCount={totalCount} theme={theme} />
                </Box>
            }
        </Box>
    );
};

export type { AddressSearchResultsProps };
export default AddressSearchResults;