import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";

import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => createStyles({
    button: {
        color: theme.palette.grey[200],
        paddingLeft: 0,
        paddingRight: "8px"
    },
    paper: {
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
    },
    container: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
    }
}));

interface ActionMenuItemProps {
    readonly onClose?: () => void;
}

interface ActionMenuProps {
    readonly children?: React.ReactNode;
}

const renderMenuItems = (children: React.ReactNode, props: ActionMenuItemProps): JSX.Element => {
    return (
        <React.Fragment>
            {React.Children.map(children, child =>
                React.isValidElement(child)
                    ? React.cloneElement(child, props)
                    : child)
            }
        </React.Fragment>
    );
};

const ActionMenu = (props: React.PropsWithChildren<unknown>): JSX.Element => {
    const styles = useStyles();

    const [showMenu, setShowMenu] = useState(false);

    const closeMenu = (): void => setShowMenu(false);
    const openMenu = (): void => setShowMenu(true);

    return (
        <React.Fragment>
            <IconButton className={styles.button} edge="end" onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Drawer anchor="bottom" open={showMenu} onClose={closeMenu} PaperProps={{ square: false, classes: { rounded: styles.paper } }}>
                <div className={styles.container}>
                    <List>
                        {renderMenuItems(props.children, { onClose: closeMenu })}
                    </List>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export type { ActionMenuProps, ActionMenuItemProps };
export default ActionMenu;