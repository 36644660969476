import { ISchemeAction, ISchemeState } from '../types/schemes';

const defaultAction: ISchemeAction = {
    type: '@@INIT'
}

const defaultState: ISchemeState = {
    createSchemeSuccess: undefined
}

export default (state: ISchemeState = defaultState, action: ISchemeAction = defaultAction): ISchemeState => {
    switch (action.type) {
        case 'SET_CREATE_SCHEME_SUCCESS':
            return {
                ...state,
                createSchemeSuccess: action.createSchemeSuccess
            };
        default:
            return state;
    }
};
