import React, { useState } from "react";

import Box from "@material-ui/core/Box";

import { AccessControl } from "../../../../auth/components";
import { RoleNames } from "../../../../auth/roles";
import { PropsWithEditing } from "../types";
import WaterSourceMenu from "./WaterSourceMenu";
import WaterSourceHistoryDialog from "./WaterSourceHistoryDialog";
import EditWaterSourceIdDialog from "./EditWaterSourceIdDialog";
import { NodeID } from "../../../../lib/nodeIdentifier";
import { IWaterSourceSummary } from "../../../inspectionView";

interface WaterSourceActionsProps {
    readonly waterSourceNodeId: NodeID;
    readonly waterSourceId: string;
    readonly onCancel?: () => void;
    readonly onEdit?: () => void;
    readonly onRefresh?: (waterSource?: IWaterSourceSummary) => void;
    readonly onSave?: () => void;
}

const WaterSourceActions = (props: PropsWithEditing<WaterSourceActionsProps>): JSX.Element => {
    const { editing, onCancel, onEdit, onRefresh, onSave } = props;
    const [waterSourceHistoryDialogOpen, setWaterSourceHistoryDialogOpen] = useState(false);
    const [changeWaterSourceIdDialogOpen, setChangeWaterSourceDialogOpen] = useState(false);

    const handleCancelClick = (): void => {
        onCancel?.();
    };
    const handleEditClick = (): void => {
        onEdit?.();
    };
    const handleSaveClick = (): void => {
        onSave?.();
    };
    const handleEditWaterSourceIdCancel = (): void => {
        setChangeWaterSourceDialogOpen(false);
    }
    const handleEditWaterSourceIdSave = (waterSource: IWaterSourceSummary): void => {
        setChangeWaterSourceDialogOpen(false);
        onSave?.();
        onRefresh?.(waterSource);
    };
    const handleHistoryClose = (): void => {
        setWaterSourceHistoryDialogOpen(false);
    };

    return (
        <Box display="inline-flex" flexDirection="row">
            {editing
                ? (
                    <React.Fragment>
                        <button id="save-button" className="action-button" onClick={handleSaveClick}>Save</button>
                        <button id="cancel-button" className="action-button action-button--cancel" onClick={handleCancelClick}>Cancel</button>
                    </React.Fragment>
                )
                : (
                    <AccessControl role={RoleNames.WS_ALL}>
                        <WaterSourceMenu
                            onEditWaterSource={handleEditClick}
                            onViewHistory={(): void => setWaterSourceHistoryDialogOpen(true)}
                            onChangeWaterSourceId={(): void => setChangeWaterSourceDialogOpen(true)}
                        />
                        <WaterSourceHistoryDialog isOpen={waterSourceHistoryDialogOpen} onClose={handleHistoryClose} waterSourceNodeId={props.waterSourceNodeId} />
                        <EditWaterSourceIdDialog isOpen={changeWaterSourceIdDialogOpen} onCancel={handleEditWaterSourceIdCancel} onSave={handleEditWaterSourceIdSave} waterSourceNodeId={props.waterSourceNodeId} waterSourceId={props.waterSourceId} />
                    </AccessControl>
                )
            }
        </Box>
    );
};

export type { WaterSourceActionsProps };
export default WaterSourceActions;