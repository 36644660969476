import { ILookups, FilteredObject } from "../../../store/types";
import { buildListFilterObject } from "../filter/utils";
import {
    buildLookupFilterItems,
    buildYesNoFilterItems,
    formatFilterText,
    resolveBoolean,
    resolveRange,
    resolveMatchString
} from "../utils";
import { RouteSearchFilters, RouteSelectedFilters } from "./types";

export const buildRouteFilters = (lookups: ILookups): Omit<RouteSearchFilters, "isActive"> => ({
    id: "TEXTRANGE",
    name: "TEXT",
    station: buildLookupFilterItems(lookups.stations),
    waterSource: "TEXT",
    waterSourceCount: "TEXTRANGE",
    hasOpenInspections: buildYesNoFilterItems(),
    technician: buildLookupFilterItems(lookups.technicians, undefined, true),
    crew: buildLookupFilterItems(lookups.crews, undefined, true),
    group: buildLookupFilterItems(lookups.inspectionGroups, undefined, true)
});

export const buildQueryFilter = (filters: RouteSelectedFilters): FilteredObject<RouteSearchFilters> => {
    return ({
        "id": resolveRange(filters.id, formatFilterText),
        "$all": [
            buildListFilterObject(filters.station, 'station'),
            buildListFilterObject(filters.technician, 'technician'),
            buildListFilterObject(filters.crew, 'crew'),
            buildListFilterObject(filters.group, 'group')
        ].filter(item => Object.keys(item).length),
        "name": {
            "$like": resolveMatchString(filters.name)
        },
        "isActive": {
            "$eq": resolveBoolean(filters.isActive)
        },
        "waterSource": {
            "$like": resolveMatchString(filters.waterSource)
        },
        "waterSourceCount": resolveRange(filters.waterSourceCount, formatFilterText),
        "hasOpenInspections": {
            "$eq": resolveBoolean(filters.hasOpenInspections)
        }
    });
};
