import React from "react";
import cx from "classnames";

import Abandoned from "./abandoned";
import CategoryIcon, { CategoryIconProps } from "./categoryIcon";
import { HydrantIconStatus } from "./hydrant";
import useStyles from "./hydrant.styles";

interface RiserIconProps extends CategoryIconProps {
    readonly status?: HydrantIconStatus;
    readonly abandoned?: boolean;
}

const Riser = (props: RiserIconProps): JSX.Element => {
    const styles = useStyles();
    const { status, className, height, width } = props;
    const statusClass = status ?? "operable";
    return (
        <CategoryIcon title="RISER" className={className} width={width} height={height}>
            <React.Fragment>
                <rect className={cx(styles.hydrant, statusClass)} x="0" y="0" width={width ?? 24} height={height ?? 24} />
                <text className={cx(styles.text, statusClass)} x="5" y="19">R</text>
                <Abandoned show={props.abandoned} width={width} height={height} />
            </React.Fragment>
        </CategoryIcon>
    );
};

export default React.memo(Riser);