import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';

import * as coords from "../../../../lib/coordinates";
import { IPoint } from "../../../../store/types";
import LabelledField from "../../../labelledField";
import ElementContainer from "../../../labelledField/components/elementContainer";
import LabelElement from "../../../labelledField/components/labelElement";
import { containerClass } from "../../../labelledField/labelledField.utils";

interface WaterSourceLocationCoordinatesProps {
    readonly value: IPoint;
    readonly editing?: boolean;
    readonly hideSearch?: boolean;
    readonly onChange?: (coordinates: IPoint) => void;
    readonly onSearchClick?: () => void;
    readonly navigateToWaterSource: () => void;
}

const formatEastingNorthing = (coordinates: IPoint | undefined): string => {
    return coordinates?.x && coordinates?.y
        ? coords.formatEastingsAndNorthings(coordinates)
        : "-";
};

const formatGridReference = (coordinates: IPoint | undefined): string => {
    return coordinates?.x && coordinates?.y
        ? coords.formatGridReference(coordinates)
        : "-";
};

const WaterSourceGridReference = (props: { id: string; coordinates: IPoint | undefined }): JSX.Element => {
    return <LabelledField
        fieldType="readonly"
        id={`${props.id}-grid-reference`}
        label="Grid Reference"
        classes={{ container: 'input__group', label: 'input__label' }}
        value={formatGridReference(props.coordinates)}
    />
};

const useStyles = makeStyles(theme => createStyles({
    label: {

    },
    input: {
        width: "96px"
    },
    searchButton: {
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main
        },
        paddingTop: 0,
        marginLeft: theme.spacing(2)
    }
}));

const WaterSourceLocationCoordinates = (props: WaterSourceLocationCoordinatesProps): JSX.Element => {
    const id = "water-source-coordinates";
    const { value: coordinates, editing, hideSearch, onChange, onSearchClick, navigateToWaterSource } = props;
    const styles = useStyles();
    if (editing) {
        const containerId = `${id}-edit-container`;
        const x = Math.floor(coordinates?.x);
        const y = Math.floor(coordinates?.y);
        const handleXChange = (value: number | undefined): void => {
            if (value !== undefined) {
                onChange?.({ x: value, y });
            }
        };
        const handleYChange = (value: number | undefined): void => {
            if (value !== undefined) {
                onChange?.({ x, y: value });
            }
        };
        return (
            <Box>
                <ElementContainer noMargin classes={containerClass({ classes: { container: 'input__group', label: 'input__label' } })}>
                    <LabelElement ownerId={containerId} label="Easting, Northing" classes={styles.label} />
                    <Box id={containerId} display="flex" flexDirection="row">
                        <LabelledField
                            fieldType="number"
                            id={`${id}-coords-x`}
                            label="X"
                            editing
                            minValue={undefined}
                            classes={{ input: styles.input }}
                            value={x}
                            onChange={handleXChange} />
                        <Box ml={1} />
                        <LabelledField
                            fieldType="number"
                            id={`${id}-coords-y`}
                            label="Y"
                            editing
                            minValue={undefined}
                            classes={{ input: styles.input }}
                            value={y}
                            onChange={handleYChange}
                        />
                        {!hideSearch &&
                            <IconButton className={styles.searchButton} onClick={onSearchClick} >
                                <LocationSearchingIcon />
                            </IconButton>
                        }
                    </Box>
                </ElementContainer>
                <WaterSourceGridReference id={id} coordinates={coordinates} />
            </Box>
        );
    }
    return (
        <Box display="flex" flexDirection="row">
            <Box>
                <LabelledField
                    fieldType="readonly"
                    id={`${id}-easting-northing`}
                    label="Easting, Northing"
                    classes={{ container: 'input__group', label: 'input__label' }}
                    value={formatEastingNorthing(coordinates)}
                />
                <WaterSourceGridReference id={id} coordinates={coordinates} />
            </Box>
            <IconButton className={styles.searchButton} onClick={onSearchClick} title="Move Water Source">
                <LocationSearchingIcon />
            </IconButton>
            <IconButton className={styles.searchButton} onClick={navigateToWaterSource} title="Center on Water Source">
                <FontAwesomeIcon icon={faMapMarked} />
            </IconButton>
        </Box>
    );
};

export type { WaterSourceLocationCoordinatesProps };
export default WaterSourceLocationCoordinates;
