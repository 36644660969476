import { useDispatch } from "react-redux";

import { NodeID } from "../../../../../../lib/nodeIdentifier";
import { setEditWaterSource } from "../../../../../../store/actions/waterSources";
import { useWaterSource } from "../../../../../appWindowContext";

const useEditWaterSource = (): (waterSourceNodeId: NodeID) => void => {
    const dispatch = useDispatch();
    const { change: selectWaterSource } = useWaterSource();
    return (waterSourceNodeId): void => {
        selectWaterSource(waterSourceNodeId)
        dispatch(setEditWaterSource(true));
    };
};

export default useEditWaterSource;