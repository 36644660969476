import executeQuery from "../../../lib/executeQuery";
import { emptyPage, FilteredObject, IConnection, IConnectionQuery } from "../../../store/types";
import { Notification, INotificationQueryFilter } from "../types";

const gql = `
    fragment Notification on Notification {
        id
        notificationId
        sourceUserName
        sourceSubscription
        eventType
        eventData
        createdUtc
        createdLocalDate
    }
    query getNotifications($filter: QueryFilter, $paging: QueryControlInput) {
        notifications(queryFilter: $filter, queryControl: $paging) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                isFirstPage
                isLastPage
                pageCount
                pageNumber
                pageSize
                sortBy
                sortDirection
            }
            items {
                ...Notification
            }
        }
    }
`;

const getNotifications = async (variables: IConnectionQuery<FilteredObject<INotificationQueryFilter>>): Promise<IConnection<Notification>> => {
    const response = await executeQuery<{ notifications: IConnection<Notification> }>(gql, variables);
    return response?.notifications ?? {
        edges: [],
        items: [],
        pageInfo: emptyPage,
        totalCount: 0
    };
};

export default getNotifications;