import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Owner, IWaterSource } from "../../../store/types";
import RiskSeverity, { mapSeverity } from "../../riskSeverity";
import WaterSourceCategory, { mapCategory } from "../waterSourceCategory";
import { WaterSourceFeatureData } from "../../../lib/map";
import { WaterSourceTitleProps } from "./types";
import WaterSourceSubtitle from "./WaterSourceSubtitle";

const createTitleProps = (waterSource: IWaterSource | Owner | WaterSourceFeatureData): WaterSourceTitleProps => ({
    category: waterSource.category,
    isActive: waterSource.isActive,
    isDefective: waterSource.isDefective,
    isOperable: waterSource.isOperable,
    waterSourceId: waterSource.waterSourceId,
    riskSeverity: waterSource.riskSeverity,
    status: waterSource.status,
    tags: waterSource.tags
});

type StyleProps = Pick<WaterSourceTitleProps, "dark">;

const useStyles = makeStyles(theme => createStyles({
    title: {
        color: ({ dark }: StyleProps): string => {
            return dark 
                ? theme.palette.secondary.contrastText
                : theme.palette.secondary.main
        },
        fontWeight: theme.typography.fontWeightBold,
        marginRight: theme.spacing(1)
    }
}));

const WaterSourceTitle = (props: WaterSourceTitleProps): JSX.Element => {
    const { waterSourceId, category, riskSeverity, dark, subtitle, ...rest } = props;
    const styles = useStyles({ dark });
    const categoryValue = mapCategory(category?.value);
    const severityValue = mapSeverity(riskSeverity?.value);

    return (
        <Box display="inline-flex" flexDirection="row" alignItems="center">
            <RiskSeverity severity={severityValue} mr={1} />
            <WaterSourceCategory {...rest} category={categoryValue} mr={1} />
            <Box component="span" display="inline-flex" flexDirection="row" alignItems="baseline">
                <Typography variant="h5" classes={{ root: styles.title }}>
                    {waterSourceId}
                </Typography>
                <WaterSourceSubtitle subtitle={subtitle} />
            </Box>
        </Box>
    );
};

export { createTitleProps };
export default React.memo(WaterSourceTitle, (prev, next): boolean => {
    return prev.category.value === next.category.value &&
        prev.dark === next.dark &&
        prev.isActive === next.isActive &&
        prev.isDefective.value === next.isDefective.value &&
        prev.isOperable.value === next.isOperable.value &&
        prev.riskSeverity?.value === next.riskSeverity?.value &&
        prev.status?.value === next.status?.value &&
        prev.subtitle === next.subtitle &&
        prev.tags?.map(t => t.value).join(",") === next.tags?.map(t => t.value).join(",") &&
        prev.waterSourceId === next.waterSourceId;
});
