import React from "react";

import { SearchEntity } from "../../../components/filters/searchSelector";
import useNavigation from "../hooks/useNavigation";
import AppBarMenu from "./AppBarMenu";
import AppBarMenuItem from "./AppBarMenuItem";

const AppBarSearchMenu = (): JSX.Element => {
    const { gotoSearch } = useNavigation();

    const handleClick = (path: SearchEntity) => {
        return (): void => {
            gotoSearch(path);
        };
    };

    return (
        <AppBarMenu id="search" title="Search">
            <AppBarMenuItem id="searchWaterSources" label="Water Sources" onClick={handleClick("water-sources")} />
            <AppBarMenuItem id="searchInspections" label="Inspections" onClick={handleClick("inspections")} />
            <AppBarMenuItem id="searchDefects" label="Defects" onClick={handleClick("defects")} />
            <AppBarMenuItem id="searchRepairs" label="Repairs" onClick={handleClick("repairs")} />
            <AppBarMenuItem id="searchSchemes" label="Schemes" onClick={handleClick("schemes")} />
            <AppBarMenuItem id="searchRoutes" label="Routes" onClick={handleClick("routes")} />
        </AppBarMenu>
    );
};

export default AppBarSearchMenu;