import React from "react";

import { IEdge, IInspection, IWaterSource } from "../../store/types";
import InspectionSelectorList from "../inspectionMap/components/InspectionSelectorList";
import AppDialog from "../shared/appDialog";
import SearchResultTitle from "./SearchResultTitle";

interface MobileSearchMatchResultProps {
    readonly show: boolean;
    readonly result: IWaterSource | undefined;
    readonly onClose?: () => void;
}

const getInspections = (waterSource?: IWaterSource): IEdge<IInspection>[] => {
    const inspections = waterSource
        ? waterSource.inspections?.map<IEdge<IInspection>>((node, index) => ({ index, node })) ?? []
        : [];
    return inspections;
};

const MobileSearchMatchResult = ({ show, result, onClose }: MobileSearchMatchResultProps): JSX.Element => {
    const inspections = getInspections(result);
    return (
        <AppDialog disableGutters open={show} appBar={{ title: <SearchResultTitle waterSource={result} /> }} onClose={onClose} >
            <InspectionSelectorList inspections={inspections.map(edge => edge.node)} />
        </AppDialog>
    )
};

export type { MobileSearchMatchResultProps };
export default MobileSearchMatchResult;