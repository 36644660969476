import olFeature from "ol/Feature";

import { IWaterSourceSummary } from "../../components/inspectionView";
import StyleCache from "../../components/map/StyleCache";
import createFeature from "./createFeatureFromWaterSource";
import { FeatureFactory } from "./types";

const bindFeatureFactory = (styleCache: StyleCache, selectedNodeId?: string | number | undefined): FeatureFactory => {
    return (waterSource: IWaterSourceSummary): olFeature => {
        return createFeature(styleCache, waterSource, selectedNodeId);
    };
};

export default bindFeatureFactory;
