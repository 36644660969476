import React, { useMemo } from "react";
import { Route } from "react-router";

import useSelector from "../../lib/hooks/useSelector";
import DesktopRouter from "../DesktopRouter";
import MobileRouter from '../MobileRouter';

const useAppRoutes = (): JSX.Element[] => {
    // const features = useSelector(state => state.features.features);
    const isMobile = useSelector(state => state.device.isMobile);
    const result = useMemo(() => {
        return isMobile
            ? [<Route key={0} path="/"><MobileRouter /></Route>]
            : [<Route key={0} path="/"><DesktopRouter /></Route>];
    }, [isMobile]);
    return result;
};

export default useAppRoutes;