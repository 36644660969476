import React, { useState, useEffect } from "react";

import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import { handleValidation } from "../../../lib/Utils";
import { ILookupItem } from "../../../store/types";
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import LabelledField, { LabelledFieldProps, SelectOption, asLookupItem } from "../../labelledField";

export interface IAssignInspectionGroupDialogProps {
    readonly assignInspectionGroupSuccess?: boolean;
    readonly groups: ILookupItem[];
    readonly inspectionNodeId: string;
    readonly selectedGroup?: ILookupItem;
    readonly isOpen: boolean;
    readonly setAssignInspectionGroupSuccess: () => void;
    readonly onAssignInspectionGroup?: (inspectionId: NodeID, groupId: NodeID) => void;
    readonly onClearError?: () => void;
    readonly onClose?: () => void;
    readonly onShowError?: (message: string) => void;
}

const saveData = (props: IAssignInspectionGroupDialogProps, state: ILookupItem | undefined, setShouldValidate: React.Dispatch<React.SetStateAction<boolean>>): void => {
    setShouldValidate(true);
    if (typeof state !== "undefined") {
        props.onClearError?.();
        props.onAssignInspectionGroup?.(parseNodeId(props.inspectionNodeId), NodeID("InspectionGroup", state.value));
    }
    else {
        props.onShowError?.("Please select an Inspection Group.");
    }
};

const AssignInspectionGroupDialog = (props: IAssignInspectionGroupDialogProps): JSX.Element => {
    const [selectedGroup, setSelectedGroup] = useState<ILookupItem | undefined>(undefined);
    const [shouldValidate, setShouldValidate] = useState(false);

    const { assignInspectionGroupSuccess, onClose, setAssignInspectionGroupSuccess } = props;
    useEffect(() => {
        if (assignInspectionGroupSuccess) {
            onClose?.();
            setAssignInspectionGroupSuccess();
        }
    }, [assignInspectionGroupSuccess, onClose, setAssignInspectionGroupSuccess]);

    const handleClose = (): void => onClose?.();
    const handleSave = (): void => saveData(props, selectedGroup, setShouldValidate);

    const crewProps: LabelledFieldProps = {
        id: "groups",
        fieldType: "select",
        label: "Select an Inspection Group",
        value: selectedGroup,
        selectOptions: props.groups,
        editing: true,
        classes: { container: 'input__group', label: 'input__label', input: handleValidation(shouldValidate, selectedGroup) },
        onClear: () => setSelectedGroup(undefined),
        onChange: (value: SelectOption | undefined) => setSelectedGroup(asLookupItem(value))
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={props.isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Assign Inspection Group</DialogTitle>
            <DialogContent>
                <form>
                    <LabelledField {...crewProps} />
                </form>
            </DialogContent>
            <DialogActions>
                <button id="assignGroup-cancel" className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                <button id="assignGroup-save" className="action-button" onClick={handleSave}>Save</button>
            </DialogActions>
        </Dialog>
    );
}

export default AssignInspectionGroupDialog;