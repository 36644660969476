import { IMobileState, IMobileAction, ViewOptions } from "../types/mobile";

const defaultState: IMobileState = {
    viewOption: ViewOptions.LIST
};

const defaultAction: IMobileAction = {
    type: "@@INIT"
}

const reducer = (state: IMobileState = defaultState, action: IMobileAction = defaultAction): IMobileState => {
    switch (action.type) {
        case "SET_VIEW_LIST":
            return { viewOption: ViewOptions.LIST };
        case "SET_VIEW_MAP":
            return { viewOption: ViewOptions.MAP };
        default:
            return state;
    }
}

export default reducer;