import React, { useState, useEffect, useCallback, useReducer, useRef } from "react";

import Box from "@material-ui/core/Box";

import { AddressDetailsData, AddressDetailsKeys } from "../../../../address";
import AppDialog, { AppDialogInput } from "../../../../shared/appDialog";

interface AddressDialogProps {
    readonly title: "Add" | "Edit";
    readonly open: boolean;
    readonly initialAddress: AddressDetailsData;
    readonly renderActions?: (enabled?: boolean, getAddress?: () => AddressDetailsData) => JSX.Element;
    readonly onCancel?: () => void;
}

interface ResetAction {
    readonly type: "reset";
    readonly value: AddressDetailsData;
}
interface UpdateAction {
    readonly type: AddressDetailsKeys;
    readonly value: string | undefined;
}
type AddressAction = ResetAction | UpdateAction;

const validateAddress = (address: AddressDetailsData): boolean => {
    const { streetDescription, locality, town, postCode } = address;
    return Boolean(streetDescription)
        || Boolean(locality)
        || Boolean(town)
        || Boolean(postCode);
};

const reducer = (state: AddressDetailsData, action: AddressAction): AddressDetailsData => {
    switch (action.type) {
        case "description":
            return { ...state, description: action.value };
        case "streetDescription":
            return { ...state, streetDescription: action.value };
        case "locality":
            return { ...state, locality: action.value };
        case "town":
            return { ...state, town: action.value };
        case "postCode":
            return { ...state, postCode: action.value };
        case "reset":
            return { ...action.value };
        default:
            return state;
    }
};

const initialise = (source: AddressDetailsData | undefined): AddressDetailsData => {
    return source ?? {};
};

const AddressDialog = ({ title, open, initialAddress, renderActions, onCancel }: AddressDialogProps): JSX.Element => {
    const [address, dispatch] = useReducer(reducer, initialAddress, initialise);
    const [valid, setValid] = useState(false);

    const handleCloseDialog = (): void => {
        onCancel?.();
    };
    const handleChange = (key: AddressDetailsKeys): ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) => {
        return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            const { value } = event.target;
            dispatch({ type: key, value: value ?? undefined });
        };
    };
    const getAddress = useCallback(() => address, [address]);

    const openRef = useRef(open);
    useEffect(() => {
        if (openRef.current !== open && open) {
            dispatch({ type: "reset", value: initialAddress });
        }
        openRef.current = open;
    }, [open]);

    useEffect(() => {
        setValid(validateAddress(address));
    }, [address]);

    return (
        <AppDialog open={open} appBar={{ title: `${title} Address` }} onClose={handleCloseDialog}>
            <Box display="flex" flexDirection="column" width="100%">
                <AppDialogInput value={address.description ?? ""} label="Building name/number" onChange={handleChange("description")} />
                <AppDialogInput value={address.streetDescription ?? ""} label="Street" onChange={handleChange("streetDescription")} />
                <AppDialogInput value={address.locality ?? ""} label="Locality" onChange={handleChange("locality")} />
                <AppDialogInput value={address.town ?? ""} label="Town" onChange={handleChange("town")} />
                <AppDialogInput value={address.postCode ?? ""} label="Post code" onChange={handleChange("postCode")} />
                <Box display="flex" flexDirection="column" alignItems="flex-end" mt={1}>
                    {renderActions?.(valid, getAddress)}
                </Box>
            </Box>
        </AppDialog>
    );
};

export type { AddressDialogProps };
export default AddressDialog;