import React from "react";

import { library, IconName } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown, faArrowUp, faEquals } from "@fortawesome/free-solid-svg-icons";

import { ILookupItem } from "../../../../store/types";
import HazardItem from "./components/HazardItem";
import useStyles from "./hazards.styles";

library.add(faArrowDown, faArrowUp, faEquals);

interface HazardsProps {
    readonly hazardSeverity?: ILookupItem;
}

const Hazards = (props: HazardsProps): JSX.Element => {
    const styles = useStyles();
    const { hazardSeverity } = props;

    return (
        <div className={styles.root}>
            <HazardItem symbolName={hazardSeverity?.symbolName as IconName} title={`Hazard Severity ${hazardSeverity?.displayText}`} />
        </div>
    );
};

export type { HazardsProps };
export default Hazards;