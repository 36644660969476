import React, { useState } from "react";
import { LocalDate } from "@js-joda/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Fab, Box, MenuItem, Button } from "@material-ui/core";

import AppDialog, { AppDialogInput, AppDialogSelect } from "../../../shared/appDialog";
import { Lookup } from "../../../../lib/lookups";
import { CreateInspectionData, ILookupItem, ITechnician, IWaterSource } from "../../../../store/types";
import { isNumber } from "../../../../lib/validation";
import { encodeNodeId, NodeID } from "../../../../lib/nodeIdentifier";

interface CreateInspectionProps {
    readonly isMobile: boolean;
    readonly inspectionTypes: Lookup;
    readonly technicians: Lookup;
    readonly userProfile?: ITechnician;
    readonly inspectionGroups: Lookup;
    readonly onCreateInspection: (data: CreateInspectionData) => void;
    readonly selectedWaterSource: IWaterSource;
}

const renderSelectItems = (tests: Lookup, isMobile: boolean | undefined): JSX.Element[] => {
    return tests
        .map(({ value, displayText }) => isMobile
            ? <option key={value} value={value}>{displayText}</option>
            : <MenuItem key={value} value={value} >{displayText}</MenuItem>);
};

const useStyles = makeStyles(createStyles({
    addNewInspectionButton: {
        width: "100%"
    }
}));

const defaultInspectionDate = LocalDate.now();

const CreateInspection = (props: CreateInspectionProps): JSX.Element => {
    const { isMobile, inspectionTypes, technicians, userProfile, inspectionGroups, onCreateInspection, selectedWaterSource } = props;
    const styles = useStyles();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");
    const [inspectionType, setInspectionType] = useState<ILookupItem>();
    const [inspectionDate, setInspectionDate] = useState<LocalDate>(defaultInspectionDate);
    const loggedTechnician = userProfile?.technicianId ? technicians.getItem(userProfile?.technicianId) : undefined;
    const [technician, setTechnician] = useState<ILookupItem | undefined>(loggedTechnician);
    const [inspectionGroup, setInspectionGroup] = useState<ILookupItem>();

    const closeDialog = (): void => {
        setShowDialog(false);
    };
    const openDialog = (): void => setShowDialog(true);

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;
        setDescription(value);
    };

    const handleInspectionTypeChange = (event: React.ChangeEvent<{ value: unknown; }>): void => {
        const { value } = event.target;
        const item = isNumber(value)
            ? inspectionTypes.getItem(Number(value))
            : undefined;
        setInspectionType(item);
    };

    const handleInspectionDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;
        const dateValue = LocalDate.parse(value);
        setInspectionDate(dateValue);
    };

    const handleTechnicianChange = (event: React.ChangeEvent<{ value: unknown; }>): void => {
        const { value } = event.target;
        const item = isNumber(value)
            ? technicians.getItem(Number(value))
            : undefined;
        setTechnician(item);
    };

    const handleInspectionGroupChange = (event: React.ChangeEvent<{ value: unknown; }>): void => {
        const { value } = event.target;
        const item = isNumber(value)
            ? inspectionGroups.getItem(Number(value))
            : undefined;
        setInspectionGroup(item);
    };

    const handleSave = (): void => {
        if (inspectionType) {
            onCreateInspection({
                waterSourceIds: [selectedWaterSource.waterSourceNodeId],
                typeId: Number(inspectionType.value),
                inspectionDate: inspectionDate,
                description: description,
                inspectionGroupId: inspectionGroup
                    ? encodeNodeId(NodeID("InspectionGroup", inspectionGroup.value))
                    : undefined,
                inspectorId: technician
                    ? encodeNodeId(NodeID("Technician", technician.value))
                    : undefined
            });
            closeDialog();
        }
    };

    const saveButton = <Button onClick={handleSave} disabled={!inspectionType}>Save</Button>;

    return (
        <React.Fragment>
            <Fab variant="extended" color="secondary" className={styles.addNewInspectionButton} onClick={openDialog}>
                Add New Inspection
            </Fab>
            <AppDialog open={showDialog} appBar={{ title: "Inspection" }} onClose={closeDialog} commit={saveButton}>
                <Box mt={1}>
                    <AppDialogInput
                        value={description}
                        id="inspection-description"
                        label="Description"
                        onChange={handleDescriptionChange}
                    />
                    <AppDialogSelect
                        id="inspection-type"
                        label="Inspection Type"
                        native={isMobile}
                        placeholder="Please select..."
                        value={inspectionType?.value}
                        required
                        onChange={handleInspectionTypeChange}
                    >
                        {renderSelectItems(inspectionTypes, isMobile)}
                    </AppDialogSelect>
                    <AppDialogInput
                        value={inspectionDate}
                        id="inspection-date"
                        label="Inspection Date"
                        placeholder="DD/MM/YYYY"
                        type="date"
                        required
                        onChange={handleInspectionDateChange}
                    />
                    <AppDialogSelect
                        id="assign-technician"
                        label="Assign Technician"
                        native={isMobile}
                        placeholder="Please select..."
                        value={technician?.value}
                        onChange={handleTechnicianChange}
                    >
                        {renderSelectItems(technicians, isMobile)}
                    </AppDialogSelect>
                    <AppDialogSelect
                        id="inspection-groups"
                        label="Assign Group"
                        native={isMobile}
                        placeholder="Please select..."
                        value={inspectionGroup?.value}
                        onChange={handleInspectionGroupChange}
                    >
                        {renderSelectItems(inspectionGroups, isMobile)}
                    </AppDialogSelect>
                </Box>
            </AppDialog>
        </React.Fragment>
    );
};

export type { CreateInspectionProps };
export default CreateInspection;