import React from "react";
import { LoadingBar } from "@3tc/shared-components";

import { IDefectSummary } from "../../../components/inspectionView";
import { NodeID } from "../../../lib/nodeIdentifier";
import { ILookupItem } from "../../../store/types";
import DefectItem from "./DefectItem";
import TabList from "./TabList";

interface DefectListProps {
    readonly defects: IDefectSummary[];
    readonly loading?: boolean;
    readonly enableActions?: boolean;
    readonly onCloseDefect?: (defectId: NodeID, closeReason: ILookupItem) => void;
}

const DefectList = ({ defects, enableActions, loading, onCloseDefect }: DefectListProps): JSX.Element => {
    const items = defects.map(defect =>
        <DefectItem key={defect.defectNodeId} defect={defect} disabled={!enableActions} onClose={onCloseDefect} />
    );
    return (
        <TabList disablePadding>
            {loading && <LoadingBar loading />}
            {items}
        </TabList>
    );
};

export type { DefectListProps };
export default DefectList;