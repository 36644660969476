import React from "react";

import { styled } from "@material-ui/core/styles";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

const BackButton = styled((props: IconButtonProps) => (
    <IconButton {...props}>
        <NavigateBeforeIcon />
    </IconButton>
))(({ theme }) => ({
    color: theme.palette.secondary.main,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
        color: theme.palette.secondary.light
    },
    "&:active": {
        color: theme.palette.secondary.dark
    }
}));

export default BackButton;