import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Coordinate } from "ol/coordinate";
import * as proj from "ol/proj";

import { IWaterSourceSearchFilters } from "../../components/filters/waterSourceSearch/types";
import executeQuery from '../../lib/executeQuery';
import { IMapAction, MapClickAction, IMapSettings, IStreetViewSettings } from '../types';
import { setGlobalToast, setInfoToast, setLoadingToast } from './app';
import { setError } from './errors';

export const setMapCenterCoords = (mapCenterCoords: Coordinate | undefined): IMapAction => ({
    type: 'SET_MAP_CENTER_COORDS',
    mapCenterCoords
});

export const setRenderAnimateTo = (renderAnimateTo?: Coordinate, selectWaterSourceNodeId?: string): IMapAction => ({
    type: 'SET_RENDER_ANIMATE_TO',
    renderAnimateTo,
    selectWaterSourceNodeId
});

export const setMapClickAction = (mapClickAction: MapClickAction): IMapAction => ({
    type: 'SET_MAP_CLICK_ACTION',
    mapClickAction
});

export const setMapSettings = (mapSettings?: IMapSettings): IMapAction => ({
    type: 'SET_SETTINGS',
    mapSettings
});

export const setStreetViewSettings = (streetViewSettings: IStreetViewSettings): IMapAction => ({
    type: 'SET_STREET_VIEW_SETTINGS',
    streetViewSettings
});

export const setRenderPoints = (renderPoints: boolean): IMapAction => ({
    type: 'SET_RENDER_POINTS',
    renderPoints
});

export const setCreateFromMapLocation = (createFromMapLocation?: number[]): IMapAction => ({
    type: 'SET_CREATE_FROM_MAP_LOCATION',
    createFromMapLocation
});

export const setMapFilters = (filters?: IWaterSourceSearchFilters): IMapAction => ({
    type: 'SET_MAP_FILTERS',
    filters
});

export const setMapFallbackWarning = (fallbackWarning: boolean): IMapAction => ({
    type: "SET_MAP_FALLBACK_WARNING",
    fallbackWarning
});

export const getPostCodeCoordinates = (postCode: string, projection?: string) => {
    return async (dispatch: ThunkDispatch<any, never, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Navigating to postcode'));
            const gazetteer = await executeQuery<{ position: { lat: number, lon: number } }>(postCode, {}, '/api/gazetteer')
            if (gazetteer) {
                const { lat, lon } = gazetteer.position
                dispatch(setRenderAnimateTo(proj.fromLonLat([lon, lat], projection)))
                dispatch(setRenderPoints(true))
                dispatch(setGlobalToast());
                setTimeout(() => {
                    // clear this once it's been set as it will animate to a point on every internal map render
                    dispatch(setRenderAnimateTo());
                }, 3000);
            }
            else {
                dispatch(setGlobalToast());
                const errorMessage = `No match found for post code '${postCode}'.`;
                dispatch(setError(errorMessage, new Error(errorMessage)));
            }
        }
        catch (error) {
            dispatch(setError('Error navigating to postcode', error));
            console.error(error);
        }
    };
};

export const navigateToLocationOnMap = (input: Coordinate, showToast = true, selectWaterSourceNodeId?: string) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        if (showToast) {
            dispatch(setLoadingToast("Navigating to Grid Reference", 5000));
        }

        dispatch(setRenderAnimateTo(input, selectWaterSourceNodeId));

        setTimeout(() => {
            // clear this once it's been set as it will animate to a point on every internal map render
            dispatch(setRenderAnimateTo());
        }, 3000);
    };
};

export const setMovingWaterSource = (movingWaterSource?: boolean, movingWaterSourceLocation?: [number, number]) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        try {
            if (movingWaterSource) {
                dispatch(setInfoToast('Select a new location from the map'));
            }

            dispatch({
                type: 'SET_MOVING_WATER_SOURCE',
                movingWaterSource,
                movingWaterSourceLocation
            });
        }
        catch (error) {
            dispatch(setError('Error moving water source', error));
        }
    };
};

export const startSetMapClickAction = (mapClickAction?: MapClickAction, showMessage?: boolean) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        const selectionBtn = document.getElementById('select-action-button');
        const createWaterSourceBtn = document.getElementById('create-water-source-action-button');
        const measureDistanceBtn = document.getElementById('measure-distance-action-button');
        const streetViewBtn = document.getElementById('street-view-action-button');

        selectionBtn?.classList.remove('active-action');
        createWaterSourceBtn?.classList.remove('active-action');
        measureDistanceBtn?.classList.remove('active-action');
        streetViewBtn?.classList.remove('active-action');

        let message = '';
        switch (mapClickAction) {
            case 'CREATE_WATER_SOURCE':
                createWaterSourceBtn?.classList.add('active-action');
                message = 'Click on the map to create a water source at that location';
                break;
            case 'MEASURE_DISTANCE':
                measureDistanceBtn?.classList.add('active-action');
                message = 'Click on the map to begin drawing a line to measure distance, double click to finish';
                break;
            case 'STREET_VIEW':
                streetViewBtn?.classList.add('active-action');
                message = 'Click on the map to see street view';
                break;
            case 'DRAW_POLYGON':
                message = 'Click on the map to start drawing a boundary, double click to finish';
                break;
            case 'EDIT_POLYGON':
                message = 'Click and drag the existing boundary to update';
                break;
            case 'DRAW_WATER_PIPE':
                message = 'Click on the map to begin creating a water pipe, double click to finish';
                break;
            case 'DELETE_WATER_PIPE':
                message = 'Click on a water pipe to delete it and remove it from the scheme'
                break;
            case 'DRAW_THOROUGHFARE':
                message = 'Click on the map to begin creating a thoroughfare, double click to finish';
                break;
            case 'DELETE_THOROUGHFARE':
                message = 'Click on a thoroughfare to delete it and remove it from the scheme'
                break;
            default:
                selectionBtn?.classList.add('active-action');
                message = 'Click on the map to perform no action or select a water source (default)';
                break;
        }

        dispatch(setMapClickAction(mapClickAction));
        if (showMessage) {
            dispatch(setInfoToast(message, 5000));
        }
    };
};
