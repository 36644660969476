export const buildListFilterObject = (values: string[] | undefined, key: string) => {
    if (!values) {
        return {};
    }

    const filters: Record<string, unknown>[] = [];

    if (values?.indexOf('') > -1) {
        filters.push({[key]: null});
        values = values.filter(val => val !== '');
    }
    
    if (values.length) {
        filters.push({[key]: {"$in_list": values}});
    }

    return {
        "$any": filters
    };
}
