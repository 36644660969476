import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { setDialogOpen } from '../../../store/actions/app';
import { IReduxState, IAppAction } from '../../../store/types';
import GlobalDialog, { IGlobalDialogProps } from './GlobalDialog';

type ConnectedProps = Pick<IGlobalDialogProps, "variant" | "createInspectionProps">;

const mapState = (state: IReduxState): ConnectedProps => ({
    variant: state.app.dialog?.variant,
    createInspectionProps: state.app.dialog?.createInspectionProps
})
type ConnectedActions = Pick<IGlobalDialogProps, "onClose">;

const mapDispatch = (dispatch: ThunkDispatch<IReduxState, never, IAppAction>): ConnectedActions => ({
    onClose: (): void => {
        dispatch(setDialogOpen());
    }
});

export type { IGlobalDialogProps };
export default connect(mapState, mapDispatch)(GlobalDialog);
