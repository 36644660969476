import React from "react";
import classes from "classnames";

import css from "./choiceChip.module.scss";

interface IChip {
    readonly label: string;
    readonly value: string;
    readonly checked?: boolean;
    readonly disabled?: boolean;
}

interface IChipStyles {
    readonly inputGroup?: string;
    readonly radio?: string;
    readonly label?: string;
}

interface IChoiceChipProps extends IChip {
    readonly name: string;
    readonly index: number;
    readonly styles?: IChipStyles,
    readonly onChange: (value: string) => void;
}

const ChoiceChip = (props: IChoiceChipProps): JSX.Element => {
    const { label, value, checked, disabled, name, index, styles, onChange } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(event.target.value);
    };
    const id = `${name}-${index}`;
    return (
        <div className={classes(css.inputGroup, styles?.inputGroup)}>
            <input type="radio" id={id} name={name} value={value} className={classes(css.radio, styles?.radio)} checked={checked} disabled={disabled} onChange={handleChange} />
            <label htmlFor={id} className={classes(css.label, styles?.radio)} >{label}</label>
        </div>
    );
};

interface IChoiceChipsProps {
    readonly name: string;
    readonly chips: IChip[];
    readonly selected?: string;
    readonly styles?: {
        readonly root?: string;
    } & IChipStyles,
    readonly onChange?: (value: string) => void;
}

const ChoiceChips = (props: IChoiceChipsProps): JSX.Element => {
    const { name, chips, selected, styles, onChange } = props;
    const handleChange = (value: string): void => {
        onChange?.(value);
    };
    return (
        <div className={classes(css.root, styles?.root)}>
            {chips.map((option, index) => (
                <ChoiceChip key={index} index={index} name={name} {...option} checked={option.value === selected} onChange={handleChange} />
            ))}
        </div>
    );
};

export type { IChip, IChoiceChipsProps };
export default ChoiceChips;