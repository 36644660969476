import React, { Component } from 'react';

import { StateValidators, requiredString, requiredObject, valid, validateState, validationStyle } from '../../../lib/validation';
import { ILookupItem, ISchemeInput } from '../../../store/types';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import LabelledField, { SelectOption } from '../../labelledField';

interface ICreateSchemeDialogProps {
    readonly isOpen: boolean;
    readonly stations: ILookupItem[];
    readonly stages: ILookupItem[];
    readonly types: ILookupItem[];
    readonly onClose?: () => void;
    readonly onCreateScheme?: (scheme: ISchemeInput) => void;
}

interface ICreateSchemeDialogState {
    readonly reference: string | undefined;
    readonly title: string | undefined;
    readonly description: string | undefined;
    readonly type: SelectOption | undefined;
    readonly station: SelectOption | undefined;
    readonly stage: SelectOption | undefined;
}

const validators: StateValidators<ICreateSchemeDialogState> = {
    reference: requiredString,
    type: requiredObject,
    title: requiredString,
    description: valid,
    stage: requiredObject,
    station: valid
};

const validateForm = validateState(validators);

class CreateSchemeDialog extends Component<ICreateSchemeDialogProps, ICreateSchemeDialogState> {
    constructor(props: ICreateSchemeDialogProps) {
        super(props)

        this.state = {
            reference: undefined,
            type: undefined,
            title: undefined,
            description: undefined,
            station: undefined,
            stage: undefined
        }
    }

    public render(): JSX.Element {
        const handleClose = (): void => this.props.onClose?.();
        const handleSave = (): void => {
            this.handleSave();
        }

        const handleClearState = (key: keyof ICreateSchemeDialogState) => {
            return (): void => {
                this.setState(current => ({ ...current, [key]: undefined }));
            }
        };
        const handleSetState = (key: keyof ICreateSchemeDialogState) => {
            return (data: string | number | SelectOption | undefined): void => {
                this.setState(current => ({ ...current, [key]: data }));
            };
        };

        const disableSave = !validateForm(this.state);
        return (
            <Dialog fullWidth maxWidth="sm" open={this.props.isOpen} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>New Scheme</DialogTitle>
                <DialogContent id="create-scheme-form">
                    <LabelledField
                        fieldType="text"
                        id="scheme_reference"
                        label="Reference *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.reference)(this.state.reference) }}
                        value={this.state.reference}
                        editing
                        onChange={handleSetState('reference')}
                    />
                    <LabelledField
                        fieldType="select"
                        id="scheme_type"
                        label="Type *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.type)(this.state.type) }}
                        value={this.state.type}
                        selectOptions={this.props.types}
                        editing
                        onChange={handleSetState('type')}
                        onClear={handleClearState("type")}
                    />
                    <LabelledField
                        fieldType="text"
                        id="scheme_title"
                        label="Title *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.title)(this.state.title) }}
                        value={this.state.title}
                        editing
                        onChange={handleSetState('title')}
                    />
                    <LabelledField
                        fieldType="text"
                        id="scheme_description"
                        label="Description"
                        classes={{ container: 'input__group', label: 'input__label' }}
                        value={this.state.description}
                        editing
                        onChange={handleSetState('description')}
                    />
                    <LabelledField
                        fieldType="select"
                        id="scheme_stage"
                        label="Stage *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(validators.stage)(this.state.stage) }}
                        value={this.state.stage}
                        selectOptions={this.props.stages}
                        editing
                        onChange={handleSetState('stage')}
                        onClear={handleClearState("stage")}
                    />
                    <LabelledField
                        fieldType="select"
                        id="scheme_station"
                        label="Station"
                        classes={{ container: 'input__group', label: 'input__label' }}
                        value={this.state.station}
                        selectOptions={this.props.stations}
                        editing
                        onChange={handleSetState('station')}
                        onClear={handleClearState("station")}
                    />
                </DialogContent>
                <DialogActions>
                    <button className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button className="action-button" disabled={disableSave} onClick={handleSave}>Save</button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleSave(): void {
        const { reference, title, description, stage, station, type } = this.state;
        this.props.onClose?.();
        const data: ISchemeInput = {
            reference,
            title,
            description,
            typeId: type
                ? type.value === 0
                    ? undefined
                    : Number(type.value)
                : undefined,
            stationId: station
                ? station.value === 0
                    ? undefined
                    : Number(station.value)
                : undefined,
            stageId: stage
                ? Number(stage.value)
                : undefined
        };
        this.props.onCreateScheme?.(data);
    }
}

export type { ICreateSchemeDialogProps };
export default CreateSchemeDialog;