import React from 'react';
import cx from "classnames";

import { timestampFormatter } from '../../../../lib/Utils';
import Typography from '../../../shared/typography';
import { Notification } from '../../types';
import { DefectsItem, FilesItem, NotesItem, Field, WaterSourceLink, InternalTasks } from '../notificationDetails';
import styles from './NotificationItem.module.scss';

interface INotificationProps {
    readonly notification: Notification;
    readonly onShowOnMapClick: (waterSourceId: string, props?: { child: string, tab: "DEFECTS" | "INSPECTIONS" }) => void;
}

const parseOutcome = (source: string): string => {
    switch (source.toLocaleLowerCase()) {
        case "pass":
            return "Pass";
        case "fail":
            return "Fail";
        default:
            return "Unknown"
    }
};

const NotificationItem = ({ notification, onShowOnMapClick }: INotificationProps): JSX.Element => {
    const { eventData } = notification;

    const { WaterSourceNodeId, WaterSourceId, InspectionNodeId, InspectionId, Type, Result, DateClosed, ClosedBy, HasAttachments, HasNote, HasOpenDefects, InternalRepairs } = eventData;
    const outcome = parseOutcome(Result);

    const handleWaterSourceClick = (): void => onShowOnMapClick(WaterSourceNodeId);
    const handleDefectsClick = (): void => onShowOnMapClick(WaterSourceNodeId, { child: "", tab: "DEFECTS" });
    const handleFilesClick = (): void => onShowOnMapClick(WaterSourceNodeId, { child: InspectionNodeId, tab: "INSPECTIONS" });
    const failStyle = { [styles.fail]: outcome === "Fail" };

    return (
        <li className={cx(styles.root, failStyle)}>
            <div className={cx(styles.row, styles.header)}>
                <div className={cx(styles.inspection, failStyle)}>
                    <Typography className={styles.id} scale="subtitle1">Inspection {InspectionId}</Typography>
                    <Typography className={styles.outcome} scale="subtitle1">{outcome.toLocaleLowerCase()}</Typography>
                </div>
                <div className={cx(styles.row, styles.waterSource)}>
                    <WaterSourceLink id={WaterSourceId} onClick={handleWaterSourceClick} />
                </div>
                <div className={cx(styles.row, styles.notification)}>
                    <Field label="Type:" value={Type} />
                    <Field label="Closed by:" value={ClosedBy} />
                    <Field label="Date closed: " value={DateClosed.format(timestampFormatter)} />
                </div>
            </div>
            <div className={cx(styles.row)}>
                <div className={styles.actions}>
                    <div className={styles.action}>
                        <DefectsItem hasOpenDefects={HasOpenDefects} onClick={handleDefectsClick} />
                    </div>
                    <div className={styles.action}>
                        <FilesItem hasAttachments={HasAttachments} onClick={handleFilesClick} />
                    </div>
                </div>
                <div className={styles.internalTasks}>
                    <InternalTasks repairIds={InternalRepairs ?? []} />
                </div>
                <div className={styles.info}>
                    <NotesItem show={HasNote} />
                </div>
            </div>
        </li>
    );
}
export type { INotificationProps };
export default NotificationItem;