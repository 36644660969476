import React, { useEffect, useState } from "react";

const coordinatesPattern = /^(\d{5,7})(\.\d+)?(,)(\d{5,7})(\.\d+)?$/;

const parseCoordinates = (input: string): [number, number] => {
    const fields = input.split(",");
    const x = parseFloat(fields[0]);
    const y = parseFloat(fields[1]);
    const coords: [number, number] = [x, y];
    return coords;
};

interface ICoordinatesSearchProps {
    readonly input: string;
    readonly onSearchInvalid?: () => void;
    readonly showOnMap: (coordinates: [number, number]) => void;
}

const CoordinatesSearch = (props: ICoordinatesSearchProps): JSX.Element => {
    const [isInputValid, setIsInputValid] = useState<boolean>(true);

    const { input, showOnMap } = props;
    useEffect(() => {
        if (coordinatesPattern.test(input)) {
            setIsInputValid(true);
            const coordinates = parseCoordinates(input);
            showOnMap(coordinates);
        }
        else {
            setIsInputValid(false);
        }
    }, [input, showOnMap]);

    const { onSearchInvalid } = props;
    useEffect(() => {
        if (!isInputValid) {
            onSearchInvalid?.();
        }
    }, [isInputValid, onSearchInvalid]);

    return <React.Fragment />;
};

export type { ICoordinatesSearchProps };
export default CoordinatesSearch;