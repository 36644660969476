import { ILookups } from "../../../store/types";
import { buildListFilterObject } from "../filter/utils";
import {
    buildLookupFilterItems,
    buildYesNoFilterItems,
    formatFilterDate,
    formatFilterText,
    resolveBoolean,
    resolveRange,
    resolveMatchString
} from "../utils";
import { InspectionSearchFilters, InspectionSelectedFilters } from "./types";

export const buildInspectionFilters = (lookups: ILookups): InspectionSearchFilters => ({
    id: "TEXTRANGE",
    inspectionDate: "DATERANGE",
    type: buildLookupFilterItems(lookups.inspectionTypes),
    waterSource: "TEXT",
    isOpen: buildYesNoFilterItems(),
    isCompleted: buildYesNoFilterItems(),
    isCancelled: buildYesNoFilterItems(),
    dateClosed: "DATERANGE",
    result: buildLookupFilterItems(lookups.inspectionResults, undefined, true),
    cancelReason: buildLookupFilterItems(lookups.inspectionCancelReasons, undefined, true),
    isAssignedToInspector: buildYesNoFilterItems(),
    isAssignedToGroup: buildYesNoFilterItems(),
    technician: buildLookupFilterItems(lookups.technicians, undefined, true),
    crew: buildLookupFilterItems(lookups.crews, undefined, true),
    inspectionGroup: buildLookupFilterItems(lookups.inspectionGroups, undefined, true),
    isWallPlateFitted: buildYesNoFilterItems(),
    isServiceRecordInDate: buildYesNoFilterItems()
});

export const buildQueryFilter = (filters: InspectionSelectedFilters): Record<string, unknown> => ({
    "id": resolveRange(filters.id, formatFilterText),
    "$all": [
        buildListFilterObject(filters.type, 'typeId'),
        buildListFilterObject(filters.result, 'resultId'),
        buildListFilterObject(filters.cancelReason, 'cancelReasonId'),
        buildListFilterObject(filters.technician, 'technicianId'),
        buildListFilterObject(filters.crew, 'crewId'),
        buildListFilterObject(filters.inspectionGroup, 'inspectionGroupId')
    ].filter(item => Object.keys(item).length),
    "inspectionDate": resolveRange(filters.inspectionDate, formatFilterDate),
    "waterSource": {
        "$like": resolveMatchString(filters.waterSource)
    },
    "isOpen": {
        "$eq": resolveBoolean(filters.isOpen)
    },
    "iSCompleted": {
        "$eq": resolveBoolean(filters.isCompleted)
    },
    "isCancelled": {
        "$eq": resolveBoolean(filters.isCancelled)
    },
    "dateClosed": resolveRange(filters.dateClosed, formatFilterDate),
    "isAssignedToInspector": {
        "$eq": resolveBoolean(filters.isAssignedToInspector)
    },
    "isAssignedToGroup": {
        "$eq": resolveBoolean(filters.isAssignedToGroup)
    },
    "isWallPlateFitted": {
        "$eq": resolveBoolean(filters.isWallPlateFitted)
    },
    "isServiceRecordInDate": {
        "$eq": resolveBoolean(filters.isServiceRecordInDate)
    }
});
