import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    appBar: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText
    },
    toolbar: {
        flexDirection: "row",
        paddingRight: theme.spacing(1.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        "&.extended": {
            flexWrap: "wrap",
            minHeight: "128px"
        }
    },
    header: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "&.extended": {
            flexDirection: "column"
        }
    },
    closeButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        "&.extended": {
            order: 3,
            width: "100%"
        }
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        "&.extended": {
            marginLeft: "auto"
        }
    },
    container: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: "hidden",
    },
    content: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.secondary.contrastText,
        flexGrow: 1,
        overflowY: "auto",
        display: "flex",
        flexDirection: "row"
    }
}));

export default useStyles;
