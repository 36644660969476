import React from "react";

import { AddressNode, IAddress } from "../../../../store/types";
import Address, { toAddressDetails } from "../../../address";
import { NodeID, NodeType, encodeNodeId } from "../../../../lib/nodeIdentifier";
import { AddressField } from "../../../address/types";

interface WaterSourceAddressProps extends Pick<AddressField, "editing" | "onClear" | "onSelect" | "onUpdate"> {
    readonly address: IAddress | undefined;
}

const WaterSourceAddress = (props: WaterSourceAddressProps): JSX.Element => {
    const { editing, address, onClear, onSelect, onUpdate } = props;

    const handleAddressClear = (): void => {
        onClear?.();
    };
    const handleAddressSelect = (address: AddressNode): void => {
        onSelect?.(address);
    };
    const handleAddressUpdate = (address: AddressNode): void => {
        onUpdate?.(address);
    };

    const details = toAddressDetails({
        ...address,
        addressNodeId: address?.addressId 
            ? encodeNodeId(NodeID(NodeType.ADDRESS, address.addressId))
            : undefined
    });

    return (
        <React.Fragment>
            <Address
                id="water-source"
                address={details}
                owner="water source"
                editing={editing}
                onClear={handleAddressClear}
                onSelect={handleAddressSelect}
                onUpdate={handleAddressUpdate}
            />
        </React.Fragment>
    )
};

export default WaterSourceAddress;