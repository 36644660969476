export default `
query getInspection_Query($id: ID!) {
    node(id:$id) {
        ... on Inspection {
            inspectionNodeId: id
            inspectionId
            waterSource {
                waterSourceNodeId: id
            }
            type {
                displayText
                value
            }
            inspectionDate
            description
            isOpen
            isCancelled
            isCompleted
            isWallPlateFitted
            isServiceRecordInDate
            isAssignedToGroup
            isAssignedToInspector
            technician {
                technicianNodeId: id
                technicianId
                displayName
                userName
            }
            crew {
                crewNodeId: id
                crewId
                callSign
            }
            inspectionGroup {
                inspectionGroupNodeId: id
                inspectionGroupId
                name
            }
            test {
                displayText
                value
            }
            result {
                displayText
                value
            }
            cancelReason {
                displayText
                value
            }
            repairs {
                repairNodeId: id
                repairId
                repairType {
                    displayText
                    value
                }
                repairCategory {
                    displayText
                    value
                }
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
            events {
                timestamp
                action
                actor
            }
            lastEvent {
                timestamp
                action
                actor
            }
        }
    }
}`;