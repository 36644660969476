import { Radio, RadioGroup } from '@blueprintjs/core';
import React from 'react';
import { ILookupItem } from '../../../store/types';

interface IRepairTypeCategoryProps {
    readonly repairCategories: ILookupItem[];
    readonly repairType: ILookupItem;
    readonly onChange?: (lookupValue: number) => void;
}

const RepairTypeCategory = ({ repairCategories, repairType, onChange }: IRepairTypeCategoryProps): JSX.Element => {
    const handleRadioChange = (e: React.FormEvent<HTMLInputElement>): void => onChange?.(parseInt(e.currentTarget.value))
    return (
        <RadioGroup inline onChange={handleRadioChange} selectedValue={repairType.groupingId}>
            {repairCategories.filter(rtc => rtc.enabled).map(rtc => <Radio key={rtc.value} label={rtc.displayText} value={rtc.value} />)}
        </RadioGroup>
    );
};

export default RepairTypeCategory;
