export default `
fragment Item on LookupItem {
    value
    displayText
    sortOrder
    enabled
}
query AppWindow_ConfigurableLookupsQuery {
    lookups {
        controlMeasures {
            ...Item
        }
        defectCancelReasons {
            ...Item
        }
        defectCloseReasons {
            ...Item
        }
        defectTypes {
            ...Item
            isOperable
        }
        hazardFlags {
            ...Item
        }
        hazardTypes {
            ...Item
            symbolName
        }
        hydrantLocations {
            ...Item
        }
        inspectionCancelReasons {
            ...Item
        }
        inspectionTests {
            ...Item
        }
        inspectionTypes {
            ...Item
            isPlanned
        }
        organisations {
            ...Item
            groupingId
            symbolName
        }
        organisationTypes {
            ...Item
        }
        plateLocations {
            ...Item
        }
        pumpTypes {
            ...Item
        }
        repairCancelReasons {
            ...Item
        }
        repairTypes {
            ...Item
            groupingId
        }
        repairVendors {
            ...Item
            emailAddress
        }
        roadSpeed {
            ...Item
        }
        schemeStages {
            ...Item
            isOpen
        }
        schemeTypes {
            ...Item
        }
        stations {
            ...Item
            mapX
            mapY
        }
        surfaces {
            ...Item
        }
        tags {
            ...Item
            filters
        }
        waterSourceClasses {
            ...Item
            groupingId
        }
    }
}`;