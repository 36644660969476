import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { defaultLookups } from "../../../store/reducers/app";
import { useWaterSource } from "../../appWindowContext";
import { WaterSourceTab } from "../../dataTabs/DataTabsContext";
import InspectionSearchBase, { IInspectionSearchProps } from "./inspectionSearch.view";

const InspectionSearch = (): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showWaterSource } = useWaterSource({ newWindow: true });
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const props: IInspectionSearchProps = {
        lookups,
        hideToast: () =>
            dispatch(setGlobalToast()),
        showError: (message, error) => error
            ? dispatch(setError(message, error))
            : dispatch(setGlobalToast({
                showToast: true,
                type: 'ERROR',
                message
            })),
        showInspectionOnMap: (inspection): void => {
            const { inspectionNodeId, waterSource } = inspection;
            showWaterSource(parseNodeId(waterSource.waterSourceNodeId), {
                tab: WaterSourceTab.INSPECTIONS,
                inspection: parseNodeId(inspectionNodeId),
                focusMap: true
            });
        },
        showLoading: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message,
            })),
        showSuccess: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message,
                timeout: 5000
            }))
    };
    return <InspectionSearchBase {...props} />;
};

export default InspectionSearch;