import moment from "moment";
import { LocalDate } from "@js-joda/core";
import { longDateFormatter } from "./Utils";

export const formatDate = (d: Date): string => {
    const m = moment(d);
    const s = m.format("DD/MM/YYYY");
    return s;
};

export const formatCamelOrPascalCase = (camelCaseText: string): string => {
    const splitString = camelCaseText.split(/(?=[A-Z])/);
    const firstWord = splitString[0];
    splitString[0] = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    return splitString.join(' ');
};


export const formatNextInspectionTag = (actual: LocalDate | undefined, recommended: LocalDate | undefined, isRecommended = "(recommended)", isOverridden = "(overridden)"): string => {
    if (recommended) {
        if (actual) {
            return recommended.isEqual(actual) ? isRecommended : isOverridden;
        }
        return isRecommended;
    }
    return "";
};

export const formatNextInspectionValue = (actual: LocalDate | undefined, recommended: LocalDate | undefined): string => {
    if (actual) {
        return actual.format(longDateFormatter);
    }
    if (recommended) {
        return recommended.format(longDateFormatter);
    }
    return "-";
};
