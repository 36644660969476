import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { encodeNodeId, parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast, setDialogOpen } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { defaultLookups } from "../../../store/reducers/app";
import { useFilters, useWaterSource } from "../../appWindowContext";
import WaterSourceSearchBase, { WaterSourceSearchProps } from "./waterSourceSearch.view";

const WaterSourceSearch = (): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showWaterSource } = useWaterSource({ newWindow: true });
    const [, setFilters] = useFilters(true);
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const props: WaterSourceSearchProps = {
        lookups,
        hideToast: () =>
            dispatch(setGlobalToast()),
        showCreateInspectionsDialog: waterSourceNodeIds => {
            const ids = waterSourceNodeIds.map(encodeNodeId);
            dispatch(setDialogOpen("createInspection", { waterSourcesToAssign: ids }));
        },
        showError: (message, error) => error
            ? dispatch(setError(message, error))
            : dispatch(setGlobalToast({
                showToast: true,
                type: "ERROR",
                message
            })),
        showLoading: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message
            })),
        showSuccess: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message,
                timeout: 5000
            })),
        showWaterSourceOnMap: waterSource => {
            showWaterSource(parseNodeId(waterSource.waterSourceNodeId), { focusMap: true });
        },
        showWaterSourcesOnMap: filters => {
            setFilters(filters);
        }
    };
    return <WaterSourceSearchBase {...props} />;
}

export default WaterSourceSearch;