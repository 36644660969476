import executeQuery from "../../../lib/executeQuery";
import getDefectsByFilterQuery from "../../../store/actions/graphQL/getDefectsByFilterQuery";
import { IConnection, IConnectionQuery, IQueryControl } from "../../../store/types";
import { DefectSearchItem } from "./types";

export interface DefectQueryResponse {
    readonly defects: IConnection<DefectSearchItem>;
}

export const queryDefectsByFilter = async (filter: Record<string, unknown>, paging: IQueryControl): Promise<IConnection<DefectSearchItem>> => {
    const input: IConnectionQuery<Record<string, unknown>> = {
        filter,
        paging
    };
    const response = await executeQuery<DefectQueryResponse>(getDefectsByFilterQuery, input);
    return response
        ? response.defects
        : Promise.reject(new Error("Missing query response."));
};