import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "@3tc/shared-components";
import styles from './ConfigurationPage.module.scss';
import { IConfigurableLookups, ILookupItem, ILookups, LookupOwnerType } from "../../store/types";
import LabelledField from "../../components/labelledField";
import { Icon } from '@blueprintjs/core';
import { Treeview, LookupTypes } from '@3tc/shared-components-ts';
import TagType from './components/tagType.view';
import { formatCamelOrPascalCase } from "../../lib/formatters";
import { getMax, getMin, guid } from "../../lib/Utils";
import { convertLookupKeyToLookupType, convertLookupTypeToLookupKey, findAndFormatChangedLookups, findNewlyAddedLookup, GraphQlLookupType, moveLookup } from "./utils";
import executeQuery from "../../lib/executeQuery";
import addNewLookupMutation from '../../store/actions/graphQL/addNewLookup';
import editLookupMutation from '../../store/actions/graphQL/editLookup';
import { useDispatch } from "react-redux";
import { updateLookups } from "../../store/actions/app";
import ArchiveLookupDialogue from './components/ArchiveLookupModal.view';
import { useHistory } from "react-router-dom";
import AreYouSureModal from './components/AreYouSureModal.view';
import { Checkbox, createStyles, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import RepairTypeCategory from './components/RepairTypeCategory.view';
import OrganisationInfo from './components/OrganisationInfo.view';
import RepairVendorEmailAddress from './components/RepairVendorEmailAddress.view';
import StationInfo from "./components/StationInfo.view";
import WaterSourceCategorySelector from "./components/WaterSourceCategorySelector.view";

type AddLookupData = Pick<EditedLookup,  "displayText" | "sortOrder" | "isOperable" | "isPlanned" | "groupingId">;
interface IAddLookupMutationInput {
    readonly clientMutationId: string;
    readonly lookup: GraphQlLookupType;
    readonly data: AddLookupData[];
}

interface IAddLookupMutationResponse {
    readonly addItems: {
        readonly clientMutationId: string;
        readonly errors: string[];
        readonly hasErrors: boolean;
        readonly lookup: {
            readonly name: GraphQlLookupType;
            readonly items: ILookupItem[];
            readonly isOperable: boolean;
            readonly isPlanned: boolean;
            readonly groupingId: number;
        }
    }
}

export type EditedLookup = Omit<ILookupItem, "enabled">;

interface IEditLookupMutationInput {
    readonly clientMutationId: string;
    readonly lookup: GraphQlLookupType;
    readonly data: EditedLookup[];
}

interface IEditLookupMutationResponse {
    readonly updateItems: {
        readonly clientMutationId: string;
        readonly errors: string[];
        readonly hasErrors: boolean;
        readonly lookup: {
            readonly items: ILookupItem[];
        }
    }
}
export interface IConfigurationPageProps {
    readonly configurableLookups: IConfigurableLookups;
    readonly lookups: ILookups;
}

const useStyles = makeStyles(theme => createStyles({
    checkbox: {
        color: theme.palette.secondary.main
    }
}));

const ConfigurationPage = (props: IConfigurationPageProps): JSX.Element => {
    const checkboxStyles = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const lookupDescriptionRef = useRef<HTMLInputElement>(null);
    const [localLookups, setLocalLookups] = useState<IConfigurableLookups>(props.configurableLookups)
    const lookupKeys = useMemo(() => Object.keys(localLookups).sort(), [localLookups]);
    const lookupResponseOptions = useMemo(() => lookupKeys.map(lookupKey => ({ value: lookupKey, text: formatCamelOrPascalCase(lookupKey) })), [lookupKeys]);
    const [isWaitingForApi, setIsWaitingForApi] = useState(false);
    const [selectedLookup, setSelectedLookup] = useState<keyof IConfigurableLookups>();
    const [lookupItemsFilterText, setLookupItemsFilterText] = useState<string>();
    const [isArchiveLookupDialogOpen, setIsArchiveLookupDialogOpen] = useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const navigateTo = useRef<string>();
    const enabledLookupItems = useMemo(() => {
        if (selectedLookup) {
            return localLookups[selectedLookup].filter(lookupItem => lookupItem.enabled)
        }

        return [];
    }, [localLookups, selectedLookup]);
    const lookupItemsResponseOptions: LookupTypes[] = useMemo(() => {
        return enabledLookupItems
            .filter(lookupItem => lookupItem.displayText.toUpperCase().includes(lookupItemsFilterText?.toUpperCase() ?? ''))
            .map(lookupItem => {
                if (selectedLookup === 'tags') {
                    return { key: lookupItem.value.toString(), description: lookupItem.displayText, parentKey: undefined, sortOrder: lookupItem.sortOrder, groupingId: lookupItem.groupingId ?? 0 }
                }
                if (selectedLookup === 'repairTypes') {
                    const repairTypeCategory = props.lookups.repairCategories.find(c => c.value === lookupItem.groupingId);
                    return { key: lookupItem.value.toString(), description: `${repairTypeCategory?.displayText} - ${lookupItem.displayText}`, parentKey: undefined, sortOrder: lookupItem.sortOrder, groupingId: lookupItem.groupingId ?? 0 }
                }
                if (selectedLookup === "waterSourceClasses") {
                    const category = props.lookups.waterSourceCategories.find(c => c.value === lookupItem.groupingId);
                    return { key: lookupItem.value.toString(), description: `${category?.displayText} - ${lookupItem.displayText}`, parentKey: undefined, sortOrder: lookupItem.sortOrder, groupingId: lookupItem.groupingId ?? 0 }
                }
                return { key: lookupItem.value.toString(), description: lookupItem.displayText, groupingId: lookupItem.groupingId ?? 0, sortOrder: lookupItem.sortOrder }
            })
            .sort((a, b) => {
                const x = a.groupingId - b.groupingId;
                if (x === 0) {
                    return a.sortOrder - b.sortOrder
                }
                return x;
            });
    }, [enabledLookupItems, lookupItemsFilterText]);
    const minLookupSortNumber = useMemo(() => enabledLookupItems.map(lookup => lookup.sortOrder).reduce(getMin, 1), [enabledLookupItems]);
    const maxLookupSortNumber = useMemo(() => enabledLookupItems.map(lookup => lookup.sortOrder).reduce(getMax, 1), [enabledLookupItems]);

    useEffect(() => {
        if (!lookupItemsResponseOptions.length) {
            setSelectedLookupItem(undefined);
        }
    }, [lookupItemsResponseOptions])

    const [selectedLookupItem, setSelectedLookupItem] = useState<string>();
    const handleSetSelectedLookup = (value: keyof IConfigurableLookups): void => {
        setSelectedLookup(value);
        setLookupItemsFilterText(undefined);
        setSelectedLookupItem(undefined);
    }
    const selectedlocalLookupItem = useMemo(() => {
        if (localLookups && selectedLookup && !!selectedLookupItem) {
            return localLookups[selectedLookup].find(lookup => lookup.value.toString() === selectedLookupItem);
        }

        return undefined;
    }, [localLookups, selectedLookup, selectedLookupItem]);
    const handleChangeLookupItemDescription = (value: string | undefined): void => {
        setLocalLookups(oldState => {
            if (selectedLookup) {
                const newLocalLookups = {...oldState};
                newLocalLookups[selectedLookup] = newLocalLookups[selectedLookup].map(lookup => {
                    if (lookup.value.toString() === selectedLookupItem) {
                        return {
                            ...lookup,
                            displayText: value ?? ''
                        }
                    }
                    return lookup;
                });
                
                return newLocalLookups;
            }

            return oldState;
        });
    };
    const haveLookupsChanged = useMemo(() => {
        const originalLookupsJSON = JSON.stringify(props.configurableLookups);
        const localLookupsJSON = JSON.stringify(localLookups);
        return originalLookupsJSON.toUpperCase() !== localLookupsJSON.toUpperCase();
    }, [props.configurableLookups, localLookups]);
    const handleTagTypeChange = (tag: ILookupItem | undefined, tagType: LookupOwnerType, shouldAddTagType: boolean): void => {
        if (tag) {
            setLocalLookups(oldState => ({
                ...oldState,
                tags: oldState.tags.map(oldStateTag => {
                    if (tag.value === oldStateTag.value) {
                        return {
                            ...oldStateTag,
                            filters: shouldAddTagType
                                ? [...tag.filters, tagType]
                                : [...tag.filters.filter(currentTagType => currentTagType !== tagType)]
                        }
                    }

                    return oldStateTag;
                })
            }));
        }
    }
    const handleCheckBooleanProp = (selectedLookup: keyof IConfigurableLookups, selectedLookupItem: string | undefined, value: boolean, propName: keyof EditedLookup): void => {
        if (selectedLookupItem) {
            setLocalLookups(oldState => ({
                ...oldState,
                [selectedLookup]: oldState[selectedLookup].map(oldStateLookup => {
                    if (oldStateLookup.value === parseInt(selectedLookupItem)) {
                        return {
                            ...oldStateLookup,
                            [propName]: value
                        }
                    }
                    return oldStateLookup;
                })
            }));
        }
    };
    const handleRepairCategoryChange = (repairCategoryId: number): void => {
        if (selectedLookupItem) {
            setLocalLookups(oldState => ({
                ...oldState,
                repairTypes: oldState.repairTypes.map(rt => {
                    if (rt.value === parseInt(selectedLookupItem)) {
                        return {
                            ...rt,
                            groupingId: repairCategoryId
                        }
                    }
                    return rt;
                })
            }))
        }
    }
    const handleWaterSourceClassCategoryChange = (categoryId: number): void => {
        if (selectedLookupItem) {
            setLocalLookups(prev => ({
                ...prev,
                waterSourceClasses: prev.waterSourceClasses.map(cls => {
                    if (cls.value === parseInt(selectedLookupItem)) {
                        return { ...cls, groupingId: categoryId }
                    }
                    return cls;
                })
            }));
        }
    };
    const handleOrganisationShortNameChange = (value: string):void => {
        if (selectedLookupItem) {
            setLocalLookups(oldState => ({
                ...oldState,
                organisations: oldState.organisations.map(org => {
                    if (org.value === parseInt(selectedLookupItem)) {
                        return {
                            ...org,
                            symbolName: value
                        }
                    }
    
                    return org;
                })
            }))
        }
    }
    const handleOrganisationTypeChange = (organisationTypeId: number): void => {
        if (selectedLookupItem) {
            setLocalLookups(oldState => ({
                ...oldState,
                organisations: oldState.organisations.map(org => {
                    if (org.value === parseInt(selectedLookupItem)) {
                        return {
                            ...org,
                            groupingId: organisationTypeId
                        }
                    }
                    return org;
                })
            }))
        }
    }
    const handleStationCoordChange = (value: number | null, propName: "mapX" | "mapY"): void => {
        if (selectedLookupItem) {
            setLocalLookups(prev => ({
                ...prev,
                stations: prev.stations.map(stn => {
                    if (stn.value === parseInt(selectedLookupItem)) {
                        return { ...stn, [propName]: value }
                    }
                    return stn;
                })
            }));
        }
    };
    const handleStationMapXChange = (value: number | null): void => {
        handleStationCoordChange(value, "mapX");
    };
    const handleStationMapYChange = (value: number | null): void => {
        handleStationCoordChange(value, "mapY");
    };
    const handleRepairVendorEmailAddressChange = (value: string):void => {
        if (selectedLookupItem) {
            setLocalLookups(oldState => ({
                ...oldState,
                repairVendors: oldState.repairVendors.map(vendor => {
                    if (vendor.value === parseInt(selectedLookupItem)) {
                        return {
                            ...vendor,
                            emailAddress: value
                        }
                    }
    
                    return vendor;
                })
            }))
        }
    }
    const handleAddNewLookup = async (): Promise<void> => {
        const getGroupingId = (lookupName: keyof ILookups): number | undefined => {
            const getGroupingKey = (): (keyof ILookups) | undefined => {
                switch (lookupName) {
                    case "organisations": return "organisationTypes";
                    case "repairTypes": return "repairCategories";
                    case "waterSourceClasses": return "waterSourceCategories";
                    default: return undefined;
                }
            };
            const groupingKey = getGroupingKey();
            if (groupingKey === undefined) {
                return undefined;
            }
            const { lookups } = props;
            const grouping = lookups[groupingKey];
            const groupingId = grouping.filter(item => item.enabled)[0].value ?? grouping[0].value;
            return groupingId;
        };
        
        if (selectedLookup) {
            const lookup = convertLookupKeyToLookupType(selectedLookup);
            const input: IAddLookupMutationInput = {
                clientMutationId: guid(),
                lookup,
                data: [{
                    displayText: 'New Item',
                    sortOrder: maxLookupSortNumber + 1,
                    isOperable: selectedLookup === 'defectTypes' ? true : undefined,
                    isPlanned: selectedLookup === 'inspectionTypes' ? true : undefined,
                    groupingId: getGroupingId(selectedLookup)
                }]
            };

            setIsWaitingForApi(true);
            const res = await executeQuery<{ lookups: IAddLookupMutationResponse }>(addNewLookupMutation, { input });
            const { name, items } = res?.lookups.addItems.lookup ?? {};
            if (name && items) {
                const newLookup = findNewlyAddedLookup(localLookups[selectedLookup], items);
                setLocalLookups(oldState => ({
                    ...oldState,
                    [selectedLookup]: [...oldState[selectedLookup], newLookup]
                }));
                const lookupName = convertLookupTypeToLookupKey(name);
                if (lookupName) {
                    dispatch(updateLookups(lookupName, items));
                }
                if (newLookup) {
                    setSelectedLookupItem(newLookup.value.toString());
                    lookupDescriptionRef.current?.focus();
                }
            }
            setIsWaitingForApi(false);
        }
    };
    const handleArchiveLookup = (updatedLookups?: ILookupItem[], updatedLookupType?: keyof IConfigurableLookups, updatedLookupId?: number): void => {
        setIsArchiveLookupDialogOpen(false);
        if (updatedLookups && updatedLookupType && updatedLookupId) {
            const updatedLookup = updatedLookups.find(lookup => lookup.value === updatedLookupId);
            if (updatedLookup) {
                setLocalLookups(oldState => {
                    return {
                        ...oldState,
                        [updatedLookupType]: [
                            ...oldState[updatedLookupType].filter(lookup => lookup.value !== updatedLookupId),
                            updatedLookup
                        ]
                    }
                });
                setSelectedLookupItem(undefined);
            }

            dispatch(updateLookups(updatedLookupType, updatedLookups));
        }
    }
    const handleSaveButtonClick = async (): Promise<void> => {
        setIsWaitingForApi(true);
        const lookupKeys = Object.keys(props.configurableLookups) as (keyof ILookups)[];
        for (let x = 0; x < lookupKeys.length; x++) {
            const lookupKey = lookupKeys[x];
            const originalLookups = props.configurableLookups[lookupKey];
            const newLookups = localLookups[lookupKey];
            const updatedLookups = findAndFormatChangedLookups(originalLookups, newLookups);
            const lookup = convertLookupKeyToLookupType(lookupKey);
            if (updatedLookups.length && lookup) {
                const input: IEditLookupMutationInput = {
                    clientMutationId: guid(),
                    lookup,
                    data: updatedLookups
                }
                const res = await executeQuery<{ lookups: IEditLookupMutationResponse }>(editLookupMutation, { input });
                const { items } = res?.lookups.updateItems.lookup ?? {};

                if (items) {
                    dispatch(updateLookups(lookupKey, items));
                }
            }
        }
        setIsWaitingForApi(false);
    }
    const handleMoveLookup = (direction: 'UP' | 'DOWN'): void => {
        if (selectedLookup && selectedLookupItem) {
            const updatedLookups = moveLookup(localLookups[selectedLookup], parseInt(selectedLookupItem), direction);
            setLocalLookups(oldState => {
                return {
                    ...oldState,
                    [selectedLookup]: updatedLookups
                }
            })
        }
    }
    const handleAreYouSureModalClose = (navigateAway: boolean): void => {
        if (navigateAway && navigateTo.current) {
            history.push(navigateTo.current);
        } else {
            navigateTo.current = undefined;
            setShowAreYouSureModal(false);
        }
    }

    useEffect(() => {
        const unblock = history.block((location) => {
            if (haveLookupsChanged && navigateTo.current === undefined) {
                navigateTo.current = location.pathname;
                setShowAreYouSureModal(true);
                return false;
            }
        });

        return (): void => {
            unblock();
        }
    }, [haveLookupsChanged]);

    return (
        <div className={styles.root}>
            <Typography variant="h4">Lookups Configuration</Typography>
            <div className={styles.container}>
                <div>
                    <Input
                        type="multilist"
                        id="lookups"
                        isEditing
                        value={[selectedLookup]}
                        responseOptions={lookupResponseOptions}
                        onChangeHandler={handleSetSelectedLookup}
                    />
                    {!!enabledLookupItems.length && <React.Fragment>
                        <Typography>Lookup Items</Typography>
                        <div className={styles.lookupItemsContainer}>
                            <div className={styles.lookupItems}>
                                <Treeview
                                    id="lookupItemsTreeview"
                                    values={[selectedLookupItem ?? '']}
                                    labelText=""
                                    isEditing
                                    responseOptions={lookupItemsResponseOptions}
                                    onClickHandler={(value: string[]): void => setSelectedLookupItem(value[value.length - 1])}
                                />
                            </div>
                            {!!selectedLookupItem && <div className={styles.lookupOrderButtons}>
                                <button
                                    id="moveLookupUpButton"
                                    title="Move item up"
                                    className='action-button'
                                    disabled={selectedlocalLookupItem?.sortOrder === minLookupSortNumber || isWaitingForApi}
                                    onClick={(): void => handleMoveLookup('UP')}
                                >
                                    <Icon icon='arrow-up' />
                                </button>
                                <button
                                    id="moveLookupDownButton"
                                    title="Move item down"
                                    className='action-button'
                                    disabled={selectedlocalLookupItem?.sortOrder === maxLookupSortNumber || isWaitingForApi}
                                    onClick={(): void => handleMoveLookup('DOWN')}
                                >
                                    <Icon icon='arrow-down' />
                                </button>
                            </div>}
                        </div>
                    </React.Fragment>}
                    {!!selectedLookup && <React.Fragment>
                        <button id="addLookupButton" className='action-button' disabled={isWaitingForApi} onClick={handleAddNewLookup}>Add</button>
                        {!!selectedLookupItem && <button id="removelookupButton" className='action-button' disabled={isWaitingForApi} onClick={(): void => setIsArchiveLookupDialogOpen(true)}>Archive</button>}
                    </React.Fragment>}
                </div>
                {!!selectedLookup && !!selectedLookupItem && <div className={styles.selectedLookupItem}>
                    <div>
                        <Typography>Lookup Item Description</Typography>
                        <LabelledField
                            editing
                            fieldType="text"
                            inputRef={lookupDescriptionRef}
                            value={selectedlocalLookupItem?.displayText}
                            id="lookupItem"
                            label="Lookup Item Description"
                            labelPosition="HIDDEN"
                            onChange={handleChangeLookupItemDescription}
                        />
                        {selectedLookup === 'tags' && <TagType tag={selectedlocalLookupItem} handleChangeTagType={handleTagTypeChange} />}
                        {selectedLookup === 'defectTypes' && <FormControlLabel
                            label="Is Operable"
                            control={
                                <Checkbox
                                    checked={selectedlocalLookupItem?.isOperable}
                                    onChange={(_e, value): void => handleCheckBooleanProp(selectedLookup, selectedLookupItem, value, "isOperable")}
                                    className={checkboxStyles.checkbox}
                                    color="secondary"
                                />
                            }       
                        />}
                        {selectedLookup === 'inspectionTypes' && <FormControlLabel
                            label="Is Planned"
                            control={
                                <Checkbox
                                    checked={selectedlocalLookupItem?.isPlanned}
                                    onChange={(_e, value): void => handleCheckBooleanProp(selectedLookup, selectedLookupItem, value, "isPlanned")}
                                    className={checkboxStyles.checkbox}
                                    color="secondary"
                                />
                            }
                        />}
                        {selectedLookup === 'repairTypes' && selectedlocalLookupItem && <RepairTypeCategory repairCategories={props.lookups.repairCategories} repairType={selectedlocalLookupItem} onChange={handleRepairCategoryChange} />}
                        {selectedLookup === 'organisations' && selectedlocalLookupItem && <OrganisationInfo organisation={selectedlocalLookupItem} organisationTypes={props.lookups.organisationTypes} onShortNameChange={handleOrganisationShortNameChange} onTypeChange={handleOrganisationTypeChange} />}
                        {selectedLookup === 'repairVendors' && selectedlocalLookupItem && <RepairVendorEmailAddress repairVendor={selectedlocalLookupItem} onChange={handleRepairVendorEmailAddressChange} />}
                        {selectedLookup === "schemeStages" && <FormControlLabel
                            label="Is Open"
                            control={
                                <Checkbox
                                    checked={selectedlocalLookupItem?.isOpen}
                                    onChange={(_e, value): void => handleCheckBooleanProp(selectedLookup, selectedLookupItem, value, "isOpen")}
                                    className={checkboxStyles.checkbox}
                                    color="secondary"
                                />
                            }
                        />}
                        {selectedLookup === "stations" && selectedlocalLookupItem && <StationInfo station={selectedlocalLookupItem} onMapXChange={handleStationMapXChange} onMapYChange={handleStationMapYChange} />}
                        {selectedLookup === "waterSourceClasses" && selectedlocalLookupItem && <WaterSourceCategorySelector categories={props.lookups.waterSourceCategories} item={selectedlocalLookupItem} onChange={handleWaterSourceClassCategoryChange} />}
                    </div>
                    <div className={styles.actionButtonContainer}>
                        <button id="saveLookups" disabled={!haveLookupsChanged || isWaitingForApi} className="action-button" onClick={handleSaveButtonClick}>Save</button>
                        <button id="resetLookups" disabled={!haveLookupsChanged || isWaitingForApi} className="action-button" onClick={(): void => setLocalLookups(props.configurableLookups)}>Reset</button>
                    </div>
                    <ArchiveLookupDialogue lookupType={selectedLookup} lookupItem={selectedlocalLookupItem} isOpen={isArchiveLookupDialogOpen} handleClose={handleArchiveLookup} />
                    <AreYouSureModal isOpen={showAreYouSureModal} handleClose={handleAreYouSureModalClose} />
                </div>}
            </div>
        </div>
    );
};

export default ConfigurationPage;
