import React from "react";

import { WaterSourceFeatureData } from "../../../lib/map";
import { IInspection } from "../../../store/types";
import AppDialog from "../../shared/appDialog";
import WaterSourceTitle, { createTitleProps } from "../../waterSource/waterSourceTitle";
import InspectionSelectorList from "./InspectionSelectorList";

interface SelectorDialogProps {
    readonly inspections: IInspection[];
    readonly open: boolean;
    readonly waterSource?: WaterSourceFeatureData;
    readonly onClose?: () => void;
}

const SelectorDialog = (props: SelectorDialogProps): JSX.Element => {
    const { inspections, open, waterSource, onClose } = props;

    const title = waterSource
        ? <WaterSourceTitle {...createTitleProps(waterSource)} dark />
        : <React.Fragment />;
    return (
        <AppDialog disableGutters appBar={{ title }} open={open} onClose={onClose}>
            <InspectionSelectorList inspections={inspections} />
        </AppDialog>
    );
};

export type { SelectorDialogProps };
export default SelectorDialog;
