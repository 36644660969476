import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

interface NoResultsProps {
    readonly message: string;
}

const useStyles = makeStyles(createStyles({
    root: {
        display: "flex",
        justifyContent: "center"
    },
    message: {
        fontStyle: "italic"
    }
}));

const NoResults = (props: NoResultsProps): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <Typography variant="body2" className={styles.message}>
                {props.message}
            </Typography>
        </div>
    );
};

export default NoResults;