import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Coordinate } from "ol/coordinate";

import { navigateToLocationOnMap } from "../../store/actions/map";
import { IRoute, IScheme, IWaterSource, isRoute, isScheme, isWaterSource } from "../../store/types";
import useAppWindowParams from "../../router/DesktopRouter/hooks/useAppWindowParams";

type OptionalCoordinate = [number | undefined, number | undefined];

const getCoordinates = (entity: unknown): OptionalCoordinate => {
    const getWaterSourceCoordinates = (waterSource: IWaterSource,): OptionalCoordinate => {
        const x = waterSource?.location?.coordinates?.x;
        const y = waterSource?.location?.coordinates?.y;
        return [x, y];
    };

    const getSchemeCoordinates = (scheme: IScheme): OptionalCoordinate => {
        // prioritise navigation to water source over boundary

        // coordinates of the first water source in scheme
        const [waterSource] = scheme.waterSources;
        if (waterSource) {
            return getWaterSourceCoordinates(waterSource);
        }
        // coordinates of first point of the scheme boundary
        const [point] = scheme.boundary?.exteriorRing.coordinates ?? [];
        const { x = undefined, y = undefined } = point ?? {};
        return [x, y];
    };

    const getRouteCoordinates = (route: IRoute): OptionalCoordinate => {
        const [waterSource] = route.waterSources;
        return waterSource
            ? getWaterSourceCoordinates(waterSource)
            : [undefined, undefined];
    };

    if (isScheme(entity)) {
        return getSchemeCoordinates(entity);
    }
    if (isRoute(entity)) {
        return getRouteCoordinates(entity);
    }
    if (isWaterSource(entity)) {
        return getWaterSourceCoordinates(entity);
    }
    return [undefined, undefined];
};

const getWaterSourceNodeId = (source: unknown): string | undefined => {
    return isWaterSource(source)
        ? source.waterSourceNodeId
        : undefined;
};

const useFocusMap = (target: IRoute | IScheme | IWaterSource | undefined): void => {
    const dispatch = useDispatch();
    const { focusMap } = useAppWindowParams();
    const [x, y] = getCoordinates(target);
    const waterSourceNodeId = getWaterSourceNodeId(target);
    useEffect(() => {
        if (focusMap && x !== undefined && y !== undefined && x !== null && y !== null) {
            dispatch(navigateToLocationOnMap([x, y], false, waterSourceNodeId));
        }
    }, [focusMap, waterSourceNodeId, x, y]);
};

export default useFocusMap;