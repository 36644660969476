import React from "react";

import { useWaterSource } from "../../../../components/appWindowContext";
import useSelector from "../../../../lib/hooks/useSelector";
import { defaultProjection } from "../../../../lib/map/Map";
import { NodeID } from "../../../../lib/nodeIdentifier";
import AppBarSearch from "./AppBarSearch.view";

const ConnectedAppBarSearch = (): JSX.Element => {
    const project = useSelector(state => state.map.mapSettings?.projection ?? defaultProjection);
    const { change: showWaterSource } = useWaterSource();

    const handleSelect = (nodeId: NodeID): void => {
        showWaterSource(nodeId, { focusMap: true, defect: undefined, inspection: undefined, repair: undefined });
    };

    return <AppBarSearch projection={project} onSelect={handleSelect} />;
}

export default ConnectedAppBarSearch;