import { ILookupItem, IOperationalStatus, IWaterSource, ILocation, IInspection, IDefect } from "../../store/types";

export enum InspectionOutcome { Pass, Fail }

export type LookupItem = ILookupItem;
export type OperationalStatus = IOperationalStatus;

export interface Coordinate {
    readonly x: number;
    readonly y: number;
}

export interface AONRange {
    readonly number: number;
    readonly suffix?: string;
}

export interface AON {
    readonly description?: string;
    readonly startRange?: AONRange,
    readonly endRange?: AONRange
}

export interface Address {
    readonly saon?: AON;
    readonly paon?: AON;
    readonly streetDescription?: string;
    readonly locality?: string;
    readonly town?: string;
    readonly administrativeArea?: string;
    readonly postCode?: string;
    readonly uprn?: number;
}

export interface Location {
    readonly address?: Address;
    readonly coordinates?: Coordinate,
    readonly description?: string;
}

export type IInspectionSummary = Pick<IInspection,
    "inspectionNodeId" |
    "inspectionId" |
    "type" |
    "inspectionDate" |
    "technician" |
    "isOpen" |
    "isCompleted" |
    "isCancelled" |
    "result"
>;

export type IWaterSourceSummary = Pick<IWaterSource,
    "waterSourceNodeId" |
    "waterSourceId" |
    "category" |
    "riskSeverity" |
    "isActive" |
    "isOperable" |
    "isDefective" |
    "status" |
    "lastInspectionDate" |
    "inspectionCount" |
    "routeSortOrder" |
    "tags" |
    "additionalInfo"
> & {
    readonly location: Pick<ILocation, "coordinates">;
};

export type IDefectSummary = Pick<IDefect,
    "defectNodeId" |
    "defectId" |
    "type" |
    "dateReported" |
    "reportedBy" |
    "isOpen" |
    "isCancelled" |
    "isResolved" |
    "notes"
>;
