import React from "react";

import { SelectOption } from "../types";
import { compareSelectOptions } from "../utils";
import SelectOptions, { SelectOptionsProps } from "./SelectOptions";

interface SelectOptionGroupProps extends SelectOptionsProps {
    readonly selectOptionGroups: SelectOption[];
}

const SelectOptionGroups = (props: SelectOptionGroupProps): JSX.Element => {
    const { selectOptionGroups, selectOptions } = props;
    return (
        <React.Fragment>
            {selectOptionGroups.sort(compareSelectOptions).map(group => (
                <optgroup label={group.displayText} key={`select-option-group-${group.value}`}>
                    <SelectOptions selectOptions={selectOptions.filter(option => option.groupingId === group.value)} />
                </optgroup>
            ))}
        </React.Fragment>
    );
};

export type { SelectOptionGroupProps };
export default SelectOptionGroups;
