import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles(theme => {
    const rowStyle = (marginBottom: number): CSSProperties => ({
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        marginBottom: theme.spacing(marginBottom),
    });
    return createStyles({
        root: {
            borderBottom: `1px solid ${theme.palette.primary.dark}`,
            "&:last-child": {
                borderBottom: "none",
            }
        },
        row: rowStyle(0.5),
        header: rowStyle(1),
        icon: {
            fontSize: "16px"
        },
        gridRef: {
            fontStyle: "italic",
            marginLeft: theme.spacing(2)
        },
        title: {
            color: theme.palette.secondary.main
        },
        subtitle: {
            color: theme.palette.primary.contrastText
        },
        meta: {
            color: theme.palette.grey[200]
        }
    })
}
);

export default useStyles;
