import React from "react";

import { AccessControl } from "../../../../../auth/components";
import { RoleNames } from "../../../../../auth/roles";

interface IEditButtonsProps {
    readonly onSaveClick: () => void;
    readonly onCancelClick: () => void;
}

const EditButtons = (props: IEditButtonsProps): JSX.Element => (
    <AccessControl role={RoleNames.ROUTES_ALL}>
        <button type="button" id="save-btn" className="action-button" onClick={props.onSaveClick}>Save</button>
        <button type="button" id="cancel-btn" className="action-button action-button--cancel" onClick={props.onCancelClick}>Cancel</button>
    </AccessControl>
);

export type { IEditButtonsProps };
export default EditButtons;