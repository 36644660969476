import React, { createContext, useContext, useState } from "react";
import { matchPath } from "react-router-dom";
import { Location } from "history";

interface RouterContextState {
    readonly disallowedPaths: string[];
    readonly clear: () => void;
    readonly peek: () => Location | undefined;
    readonly pop: () => void;
    readonly push: (location: Location) => void;
}

const Context = createContext<RouterContextState>({
    disallowedPaths: [],
    clear: () => undefined,
    peek: () => undefined,
    pop: () => undefined,
    push: () => undefined
});

interface RouterContextProps {
    readonly disallowedPaths: string[];
}

const RouterContext = (props: React.PropsWithChildren<RouterContextProps>): JSX.Element => {
    const [stack, setStack] = useState<Location[]>([]);

    const { disallowedPaths } = props;
    const state: RouterContextState = {
        disallowedPaths,
        clear: () => setStack([]),
        peek: () => stack.length > 0 ? stack[0] : undefined,
        pop: () => setStack(current => {
            const [, ...tail] = current;
            return tail;
        }),
        push: location => {
            const matches = disallowedPaths.map(path => matchPath(location.pathname, { path }));
            const disallowed = matches.some(match => match !== null);
            if (!disallowed) {
                setStack(current => [location, ...current]);
            }
        }
    };

    return (
        <Context.Provider value={state}>
            {props.children}
        </Context.Provider>
    );
};

const useRouterContext = (): RouterContextState => {
    return useContext(Context);
};

export type { RouterContextProps, RouterContextState };
export { useRouterContext };
export default RouterContext;