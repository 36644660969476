import { IWaterSourceSummary } from "../../components/inspectionView";
import executeQuery from "../../lib/executeQuery";
import { NodeID, encodeNodeId } from "../../lib/nodeIdentifier";
import { guid } from "../../lib/Utils";
import { IEditMutationVariables, IWaterSourceInput, IHydrantInput } from "../../store/types";
import updateEwsLocationQuery from "../graphql/updateEwsLocation";
import updateHydrantLocationQuery from "../graphql/updateHydrantLocation";
import updateRiserLocationQuery from "../graphql/updateRiserLocation";
import updateUwsLocationQuery from "../graphql/updateUwsLocation";
import { UpdateResponse, UpdateHydrantResponse, UpdateEmergencyWaterSupplyResponse, UpdateRiserResponse, UpdateUnknownWaterSupplyResponse } from "./types";

type DetailsData = Pick<IWaterSourceInput,
    "externalId" |
    "classificationId" |
    "stationId" |
    "controlMeasures" |
    "hazardInformation" |
    "roadSpeedId" |
    "hazardSeverityId" |
    "hazardFlagId" |
    "isSecondPersonRequired" |
    "riskSeverityId"
> & Pick<IHydrantInput,
    "surfaceId" |
    "flowRate" |
    "pressure" |
    "mainsSize" |
    "mainsSizeUnitId" |
    "plateLocationId" |
    "plateDistance" |
    "additionalInfo"
>;

interface UpdateWaterSourceDetailsRequest {
    readonly input: IEditMutationVariables<DetailsData>;
}

interface UpdateDetailsResponse<T = UpdateResponse> {
    readonly waterSource: {
        readonly update: T;
    }
}

const updateHydrantLocation = async (request: UpdateWaterSourceDetailsRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateDetailsResponse<UpdateHydrantResponse>>(updateHydrantLocationQuery, request)
    return response?.waterSource.update.hydrant.waterSource;
};

const updateEmergencyWaterSupplyLocation = async (request: UpdateWaterSourceDetailsRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateDetailsResponse<UpdateEmergencyWaterSupplyResponse>>(updateEwsLocationQuery, request);
    return response?.waterSource.update.emergencyWaterSupply.waterSource;
};

const updateRiserLocation = async (request: UpdateWaterSourceDetailsRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateDetailsResponse<UpdateRiserResponse>>(updateRiserLocationQuery, request);
    return response?.waterSource.update.riser.waterSource;
};

const updateUnknownWaterSourceLocation = async (request: UpdateWaterSourceDetailsRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateDetailsResponse<UpdateUnknownWaterSupplyResponse>>(updateUwsLocationQuery, request);
    return response?.waterSource.update.unknown.waterSource;
};

const updateWaterSourceDetails = async (nodeId: NodeID, data: DetailsData): Promise<IWaterSourceSummary | undefined> => {
    const request: UpdateWaterSourceDetailsRequest = {
        input: {
            clientMutationId: guid(),
            nodeId: encodeNodeId(nodeId),
            data
        }
    };
    const executeQuery = async (): Promise<IWaterSourceSummary | undefined> => {
        switch (nodeId.typeName) {
            case "Hydrant":
                return updateHydrantLocation(request);
            case "EmergencyWaterSupply":
                return updateEmergencyWaterSupplyLocation(request);
            case "Riser":
                return updateRiserLocation(request);
            case "UnknownWaterSource":
                return updateUnknownWaterSourceLocation(request);
            default:
                throw new Error(`Unknown node type '${nodeId.typeName}'.`)
        }
    };

    const waterSource = executeQuery();
    return waterSource;
};

export type { DetailsData };
export default updateWaterSourceDetails;