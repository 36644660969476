import React, { useCallback, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import EditIcon from "@material-ui/icons/Edit";

import { AddressDetailsData, toAddressNode } from "../../../../../address";
import { EditAddressProps as BaseProps, EditContextProps } from "../../../../../address/EditAddress";
import AddressDialog from "../AddressDialog";

const useStyles = makeStyles(createStyles({
    button: {
        marginLeft: "-5px"
    }
}));

type EditAddressProps = Omit<BaseProps, "owner"> & {
    readonly variant: "icon" | "button";
};

interface ActionProps extends EditContextProps {
    readonly enabled?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const useButtonStyles = makeStyles(theme => createStyles({
    button: {
        marginBottom: theme.spacing(0.5)
    }
}));

const SelectButton = ({ enabled, context, onClick }: ActionProps): JSX.Element => {
    const styles = useButtonStyles();
    if (context === "search") {
        return (
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Button size="small" variant="contained" color="secondary" className={styles.button} disabled={!enabled} onClick={onClick}>Update & Select</Button>
                <Typography variant="caption" color="textSecondary">Update the address record, and then select it as the address for the water source.</Typography>
            </Box>
        );
    }
    return <React.Fragment />;
};

const UpdateButton = ({ enabled, context, onClick }: ActionProps): JSX.Element => {
    const styles = useButtonStyles();
    const caption = context === "search" 
        ? "Update the address record, and refresh the search results."
        : "Update the address record.";
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Button size="small" variant="contained" color="secondary" className={styles.button} disabled={!enabled} onClick={onClick}>Update</Button>
            <Typography variant="caption" color="textSecondary">{caption}</Typography>
        </Box>
    )
};

const EditAddress = ({ initialAddress, hidden, context, variant, onSelect, onUpdate }: EditAddressProps): JSX.Element => {
    const styles = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    if (hidden) {
        return <React.Fragment />;
    }

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const renderActions = useCallback((enabled?: boolean, getAddress?: () => AddressDetailsData) => {
        const handleSelect = (): void => {
            setDialogOpen(false);
            const address = getAddress?.();
            if (address) {
                onSelect?.(address);
            }
        };
        const handleUpdate = (): void => {
            setDialogOpen(false);
            const address = getAddress?.();
            if (address) {
                onUpdate?.(address);
            }
        };
        return (
            <React.Fragment>
                <SelectButton context={context} enabled={enabled} onClick={handleSelect} />
                <UpdateButton context={context} enabled={enabled} onClick={handleUpdate} />
            </React.Fragment>
        );
    }, [onSelect, onUpdate]);

    return (
        <React.Fragment>
            {variant === "icon"
                ? <IconButton size="small" edge="end" onClick={handleActionClick}><EditIcon /></IconButton>
                : <Button size="small" color="secondary" startIcon={<EditIcon />} className={styles.button} onClick={handleActionClick}>Edit Address</Button>
            }
            <AddressDialog
                title="Edit"
                open={dialogOpen}
                initialAddress={initialAddress}
                renderActions={renderActions}
                onCancel={handleCloseDialog}
            />
        </React.Fragment>
    );
};

export type { EditAddressProps };
export default EditAddress;