import React, { useState, useEffect, useMemo } from 'react';

import { ILookupItem, IMapSettings, IQuickSearchItem, IRoute, IWaterSource } from '../../../store/types';
import ControlHeader from '../../controlHeader/controlHeader';
import { asLookupItem, SelectOption } from '../../labelledField';
import NotesTab from '../../notes/NotesTab';
import QuickSearch, { waterSourceIdSearch } from '../../quickSearch';
import Typography from '../../shared/typography';
import RouteDetails from '../routeDetails';
import EditButtons from './components/editButtons';
import RoutesMenu from './components/routesMenu';
import DrawerToolbar from '../../drawer/DrawerToolbar';
import WaterSourceButtons, { WaterSourceButton } from './components/waterSourceButtons';
import InspectionsList from './components/inspectionList/InspectionList.view';
import styles from './RoutesPanel.module.scss';
import { RouteWaterSource, getWaterSource, getCenterPointFromWaterSources, compareWaterSources } from "./RoutePanel.utils";
import Map from '../../map/desktop'
import { renderWaterSources } from '../../../lib/map';

interface IRoutesPanelProps {
    readonly stations: ILookupItem[];
    readonly route: IRoute;
    readonly mapSettings: IMapSettings;
    readonly showOnMap: boolean;
    readonly onClose: () => void;
    readonly onCreateInspectionsClick: (waterSourceIds: string[]) => void;
    readonly onCreateWaterSourceClick: () => void;
    readonly onError: (errorMessage: string, errorObject: Error) => void;
    readonly onInfo: (message: string) => void;
    readonly onPrint?: (route: IRoute, imageUrl: string) => void;
    readonly onRefresh?: () => void;
    readonly onUpdateRoute: (routeNodeId: string, name: string, station: ILookupItem) => Promise<void>;
    readonly onUpdateRouteWaterSources: (routeNodeId: string, waterSources: RouteWaterSource[]) => Promise<void>;
    readonly onWaterSourceSelect: (waterSourceNodeId: string) => void;
    readonly setShowOnMap: (showOnMap: boolean) => void;
}

const formatCount = (count: number): string => {
    return count
        ? ` (${count})`
        : "";
};

const RoutesPanel = (props: IRoutesPanelProps): JSX.Element => {
    const { route: selectedRoute, stations, mapSettings, showOnMap, setShowOnMap } = props;
    const { name, station, waterSources, routeNodeId, routeId, lastEvent, notes } = selectedRoute;

    const [editing, setEditing] = useState(false);
    const [routeName, setRouteName] = useState(name);
    const [routeStation, setRouteStation] = useState(station);
    const [preventMapRerender, setPreventMapRerender] = useState(true);

    const routeWaterSources = [...waterSources].sort(compareWaterSources);
    const [editWaterSources, setEditWaterSources] = useState<RouteWaterSource[]>([]);

    useEffect(() => {
        setTimeout(() => setPreventMapRerender(false), 2000);
    }, []);
    const printMapCenter = useMemo(() => getCenterPointFromWaterSources(routeWaterSources), [routeWaterSources]);
    const waterSourceCount = routeWaterSources.length;
    const waterSourceButtons = useMemo((): WaterSourceButton => {
        const showCreateInspections = !editing && waterSourceCount > 0
            ? WaterSourceButton.CreateInspections
            : WaterSourceButton.None;
        return WaterSourceButton.CreateWaterSource | showCreateInspections;
    }, [editing, waterSourceCount]);

    const endEdit = (): void => {
        setEditWaterSources([]);
        setEditing(false);
    };

    const handleEditClick = (): void => {
        setEditWaterSources(routeWaterSources);
        setEditing(true);
    };
    const handleCancelClick = (): void => {
        setRouteStation(station);
        setRouteName(name);
        endEdit();
    };
    const handleSaveClick = async (): Promise<void> => {
        await props.onUpdateRouteWaterSources(routeNodeId, editWaterSources);
        await props.onUpdateRoute(routeNodeId, routeName, routeStation);
        endEdit();
    };

    const handleCloseClick = (): void => {
        props.onClose();
    };

    const handleNameChange = (value: string | undefined): void => {
        if (value) {
            setRouteName(value);
        }
    };
    const handleStationChange = (value: SelectOption | undefined): void => {
        const station = asLookupItem(value);
        if (station) {
            setRouteStation(station);
        }
    };

    const handleCreateWaterSourceClick = (): void => {
        props.onCreateWaterSourceClick();
    };
    const handleCreateInspectionsClick = (): void => {
        const ids = routeWaterSources.map(ws => ws.waterSourceNodeId);
        props.onCreateInspectionsClick(ids)
    };

    const handleSearchResultClick = (result: IQuickSearchItem): void => {
        if (routeWaterSources.some(ws => ws.waterSourceNodeId === result.waterSourceNodeId)) {
            return props.onInfo(`Water source ${result.waterSourceId} is already assigned to route ${routeId}.`);
        }

        const warnExistingRoute = (ws: IWaterSource | undefined): IWaterSource | undefined => {
            if (ws?.route) {
                props.onInfo(`Water source ${ws.waterSourceId} removed from route ${ws.route.routeId} (${ws.route.name}).`);
            }
            return ws;
        };

        const addWaterSource = (ws: IWaterSource | undefined): void => {
            if (ws) {
                setEditWaterSources(current => {
                    return [...current, ws].map((w, i) => ({ ...w, routeSortOrder: i + 1 }))
                });
            }
        };

        const handleError = (reason: any): void => {
            const message = `Error adding water source ${result.waterSourceId} to route ${routeId}.`;
            props.onError(message, new Error(message));
            console.error(message, reason);
        };

        getWaterSource(result.waterSourceNodeId)
            .then(warnExistingRoute)
            .then(addWaterSource)
            .catch(handleError);
    };

    const handleRemoveWaterSource = (item: RouteWaterSource): void => {
        setEditWaterSources(current => current.filter(ws => ws.waterSourceNodeId !== item.waterSourceNodeId))
    };

    const handleMoveWaterSource = (itemNodeId: string, targetNodeId: string): void => {
        setEditWaterSources(current => {
            const next = [...current];
            const movedItemIndex = next.findIndex(ws => ws.waterSourceNodeId === itemNodeId);
            const targetItemIndex = next.findIndex(ws => ws.waterSourceNodeId === targetNodeId);
            next.splice(targetItemIndex, 0, next.splice(movedItemIndex, 1)[0])
            return next.map((ws, index) => ({ ...ws, routeSortOrder: index + 1 }));
        });
    };

    const handlePointRender = (context: Map): void => {
        if (context.props.data) {
            renderWaterSources(context, undefined, selectedRoute.waterSources, true);
        }
    };

    const handlePrintClick = (): void => {
        const canvas = document.getElementsByTagName('canvas')[0];
        const imageUrl = canvas.toDataURL('image/jpeg');
        props.onPrint?.(selectedRoute, imageUrl);
    };

    const handleToggleOnMap = (): void => {
        setShowOnMap(!showOnMap);
    };

    return (
        <div className={styles.root}>
            {editing
                ? <div className={styles.actionButtonsContainer}>
                    <EditButtons onSaveClick={handleSaveClick} onCancelClick={handleCancelClick} />
                </div>
                : <DrawerToolbar
                    menu={<RoutesMenu showOnMap={showOnMap} onEditClick={handleEditClick} onPrintClick={handlePrintClick} onToggleOnMap={handleToggleOnMap} />}
                    title={`${routeId} - ${station.displayText}`}
                    onClose={handleCloseClick}
                />
            }
            <div style={{ width: 1000, position: 'absolute', left: -1000 }}>
                <Map
                    mapTileServer={mapSettings.tileServer}
                    mapCentre={printMapCenter}
                    data={{ waterSources: selectedRoute.waterSources }}
                    pointRenderHandler={handlePointRender}
                    preventReRender={preventMapRerender}
                />
            </div>
            <ControlHeader
                editing={false}
                primary={`Route ${routeId}`}
                primaryKey="routeId"
                editPrimary={false}
                secondaryKey=""
                tertiaryKey=""
            />
            <RouteDetails
                routeData={{ name: routeName, station: routeStation, lastEvent: lastEvent }}
                stations={stations}
                onNameChange={handleNameChange}
                onStationChange={handleStationChange}
                editing={editing}
            />
            <Typography scale="subtitle1" className={styles.waterSourceTitle}>{`Water Sources${formatCount(routeWaterSources.length)}`}</Typography>
            <div className={styles.waterSourceActionsContainer}>
                <WaterSourceButtons buttons={waterSourceButtons} onCreateWaterSourceClick={handleCreateWaterSourceClick} onCreateInspectionsClick={handleCreateInspectionsClick} />
                {editing && <QuickSearch searchTypes={[waterSourceIdSearch]} isInline onSearchResultClick={handleSearchResultClick} />}
            </div>
            <InspectionsList
                routerWaterSources={editing ? editWaterSources : routeWaterSources}
                isEditing={editing}
                handleListItemClick={props.onWaterSourceSelect}
                handleRemoveWaterSource={handleRemoveWaterSource}
                handleMoveWaterSource={handleMoveWaterSource}
            />
            <NotesTab objectType="ROUTE" objectNodeId={routeNodeId} notes={notes} />
        </div>
    );
};

export type { IRoutesPanelProps };
export default RoutesPanel;
