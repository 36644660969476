import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';

interface ViewNotesProps {
    readonly openNotesDetails: () => void;
}

const ViewNotes = (props: ViewNotesProps): JSX.Element => {
    const { openNotesDetails  } = props;

    return (
        <React.Fragment>
            <ListItem button onClick={openNotesDetails}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faStickyNote} size="lg" style={{ marginLeft: "4px" }} />
                </ListItemIcon>
                <ListItemText primary="View notes" />
            </ListItem>
        </React.Fragment>
    );
};

export type { ViewNotesProps };
export default ViewNotes;