import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, NumberProps } from "../types";

const EwsLift = ({ editing, value, onChange }: PropsWithEditing<NumberProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "number",
        id: "water-source-lift",
        label: "Lift",
        classes: { container: 'input__group', label: 'input__label' },
        value,
        minValue: undefined,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return <LabelledField {...props} />;
};

export default EwsLift;