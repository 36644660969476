import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { SelectProps, PropsWithEditing, PropsWithShow } from "../types";

const WaterSourceClassification = ({ editing, show, options, discriminator, value, onClear, onSelect }: PropsWithEditing<PropsWithShow<SelectProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "water-source-classification",
        label: "Classification",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value,
        selectOptions: options.filter(classification => classification.groupingId === discriminator?.value),
        editing: editing ?? false,
        onChange: value => onSelect?.(value),
        onClear
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default WaterSourceClassification;