import React, { PropsWithChildren, useEffect, useState } from "react";

import proj4 from "proj4";
import { register } from 'ol/proj/proj4';

import useSelector from "../../lib/hooks/useSelector";
import { IMapProjection } from "../../store/types";

const defaultProjection: IMapProjection = {
    code: "EPSG:27700",
    definition: "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs"
};

const setProjection = (code: string, definition: string): void => {
    proj4.defs(code, definition);
    console.log("configured proj4", code, definition);
    register(proj4);
    console.log("registered proj4 with OpenLayers");
};

const Projection = <P extends Record<string, unknown> = Record<string, unknown>>(props: PropsWithChildren<P>): JSX.Element => {
    const { code, definition } = useSelector(state => state.map.mapSettings?.projection ?? defaultProjection);

    const [render, setRender] = useState(false);

    // assume that the projection does not change during the application lifetime
    useEffect(() => {
        setProjection(code, definition);
        setRender(true)
    }, [code, definition]);

    return render
        ? <React.Fragment>{props.children}</React.Fragment>
        : <React.Fragment />;
};

export default Projection;