import React, { useState } from "react";

import EditIcon from '@material-ui/icons/Edit';

import AddressDetailsDialog from "../AddressDetailsDialog";
import { AddressDetailsData, AddressOwner, AddressSelector } from "../types";
import AddressActionButton from "../AddressActionButton";

interface EditContextProps {
    readonly context: "editor" | "search";
}

interface EditAddressProps extends AddressSelector, EditContextProps {
    readonly initialAddress: AddressDetailsData;
    readonly hidden?: boolean;
}

interface ActionProps extends EditContextProps {
    readonly owner: AddressOwner;
    readonly enabled?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const SelectButton = ({ owner, enabled, context, onClick }: ActionProps): JSX.Element => {
    if (context === "search") {
        const caption = `Update the address record, and then select it as the address for the ${owner}.`;
        return <button type="button" id="addressSearch-select-button" className="action-button" disabled={!enabled} onClick={onClick} title={caption}>Update & Select Address</button>;
    }
    return <React.Fragment />;
};

const UpdateButton = ({ enabled, context, onClick }: Pick<ActionProps, "enabled" | "context" | "onClick">): JSX.Element => {
    const caption = context === "search" 
        ? "Update the address record, and refresh the search results."
        : "Update the address record.";
    return <button type="button" id="addressSearch-add-button" className="action-button" disabled={!enabled} onClick={onClick} title={caption}>Update Address</button>;
}

const EditAddress = ({ owner, initialAddress, hidden, context, onSelect, onUpdate }: EditAddressProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);

    if (hidden) {
        return <React.Fragment />;
    }

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const handleSelect = (address: AddressDetailsData): void => {
        setDialogOpen(false);
        onSelect?.(address);
    };
    const handleUpdate = (address: AddressDetailsData): void => {
        setDialogOpen(false);
        onUpdate?.(address);
    };

    const renderAction = (enabled: boolean, getAddress?: () => AddressDetailsData): JSX.Element => {
        const handleSelectClick = (): void => {
            const address = getAddress?.();
            if (address) {
                handleSelect(address);
            }
        };
        const handleUpdateClick = (): void => {
            const address = getAddress?.();
            if (address) {
                handleUpdate(address);
            }
        };
        return (
            <React.Fragment>
                <SelectButton context={context} enabled={enabled} onClick={handleSelectClick} owner={owner} />
                <UpdateButton context={context} enabled={enabled} onClick={handleUpdateClick} />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <AddressActionButton title="Edit Address" size="small" onClick={handleActionClick} >
                <EditIcon />
            </AddressActionButton>
            <AddressDetailsDialog
                title="Edit Address"
                open={dialogOpen}
                initialAddress={initialAddress}
                renderAction={renderAction}
                onCancel={handleCloseDialog}
            />
        </React.Fragment>
    );
};

export type { EditAddressProps, EditContextProps };
export default EditAddress;