import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';

import changePassword, { ChangePasswordResult } from "../../../lib/changePassword";
import useStyles from "./changePassword.styles";

const ChangePassword = (): JSX.Element => {
    const styles = useStyles();
    const [requestSent, setRequestSent] = useState(false);
    const [response, setResponse] = useState<ChangePasswordResult>();

    const handleClick = async (): Promise<void> => {
        setRequestSent(true);
        const response = await changePassword();
        setResponse(response);
    };

    const showSpinner = requestSent && response === undefined;
    return (
        <div className={styles.root}>
            <div className={styles.request}>
                <Button variant="contained" color="primary" disabled={requestSent} className={styles.button} startIcon={<RefreshIcon />} onClick={handleClick}>
                    Change Password
                </Button>
                {showSpinner && < CircularProgress size={24} className={styles.spinner} />}
            </div>
            {response &&
                <div className={styles.response}>
                    {response.error && <WarningIcon className={styles.warning} />}
                    <Typography className={styles.text} variant="body2">{response.message}</Typography>
                </div>
            }
        </div>
    );
};

export default ChangePassword;