import axios from 'axios';

interface ErrorReport {
    readonly errorTime?: string;
    readonly errorMessage?: string;
    readonly errorObject?: Error | string;
}

const reportError = async (error: ErrorReport): Promise<void> => {
    await axios.post('/api/report-error', error);
    console.error("Error reported:", error.errorMessage);
};

export type { ErrorReport };
export default reportError;