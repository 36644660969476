export default `mutation editDefect_Mutation($defect: EditDefectInput!) {
    updateDefect(input:$defect) {
      clientMutationId
      defect {
        defectNodeId: id
        defectId
        type {
            displayText
            value
        }
        cancelReason {
            displayText
            value
        }
        closeReason {
            displayText
            value
        }
        dateReported
        reportedBy
        isOpen
        isResolved
        lastEvent {
            timestamp
            action
            actor
        }
        parentInspection {
            id
        }
        notes {
            noteNodeId: id
            noteId
            timestamp
            author
            content
        }
        waterSource {
            waterSourceNodeId: id
            isDefective {
                value
                isOverridden
            }
            isOperable {
                value
                isOverridden
            }
        }
        events {
            timestamp
            action
            actor
        }
      }
      hasErrors
      errors {
          message
          path
      }
    }
  }`;