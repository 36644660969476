import { connect } from "react-redux";

import bindLookup from "../../../../../../lib/lookups";
import { IReduxState } from "../../../../../../store/types";
import Base, { HydrantLocationProps } from "./HydrantLocation.view";

type ConnectedProps = Pick<HydrantLocationProps, "items">;

const mapProps = (state: IReduxState): ConnectedProps => ({
    items: bindLookup(state.app.lookups?.hydrantLocations ?? [])
});

export default connect(mapProps)(Base);