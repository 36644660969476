import React, { useRef, useState } from "react";
import cx from "classnames";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import SearchIcon from '@material-ui/icons/Search';

import useMountedEffect from "../../../api/hooks/useMountedEffect";
import { AddressNode } from "../../../store/types";
import Dialog from "../../dialog/dialog";
import DialogTitle from "../../dialog/dialogTitle";
import DialogContent from "../../dialog/dialogContent";
import DialogActions from "../../dialog/dialogActions";
import AddressSearch from "../AddressSearch";
import FindAddressButton from "./FindAddressButton";
import { DesktopSearchInput } from "../AddressSearchInput";
import { RenderAction } from "../AddressSearchItem";
import { AddressDetailsData, AddressSelector, toAddressDetails, toAddressNode } from "../types";
import EditAddress from "../EditAddress";
import AddAddress from "../AddAddress";


interface FindAddressProps extends AddressSelector {}

const useStyles = makeStyles(createStyles({
    closeButton: {
        marginLeft: "auto"
    }
}));

const FindAddress = ({ owner, onSelect, onUpdate }: FindAddressProps): JSX.Element => {
    const styles = useStyles();
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const handleClear = (): void => {
        setShowAddAddress(false);
    };
    const handleSearchComplete = (): void => {
        setShowAddAddress(true);
    };
    const handleSelect = (address: AddressNode): void => {
        setDialogOpen(false);
        onSelect?.(toAddressDetails(address));
    };

    const handleAddAddress = (address: AddressDetailsData): void => {
        handleSelect(toAddressNode(address));
    };

    const renderEditAction = (refresh?: () => void): RenderAction => {
        const handleEditSelect = (address: AddressDetailsData): void => {
            handleSelect(toAddressNode(address));
        }
        const handleUpdate = (address: AddressDetailsData): void => {
            refresh?.();
            onUpdate?.(address);
        };
        const EditAction = (address: AddressNode): JSX.Element => {
            return <EditAddress owner={owner} context="search" initialAddress={toAddressDetails(address)} onSelect={handleEditSelect} onUpdate={handleUpdate} />;
        };
        return EditAction;
    };

    const dialogOpenRef = useRef(dialogOpen);
    useMountedEffect(ifMounted => {
        ifMounted(() => {
            if (dialogOpenRef.current !== dialogOpen && dialogOpen) {
                setShowAddAddress(false);
            }
            dialogOpenRef.current = dialogOpen;
        });
    }, [dialogOpen]);

    return (
        <React.Fragment>
            <FindAddressButton onClick={handleActionClick} />
            <Dialog fullWidth maxWidth="sm" open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle onClose={handleCloseDialog}>
                    <Box component="span" display="inline-flex" flexDirection="row" alignItems="center">
                        <SearchIcon />
                        <Box component="span" ml={1}>Find Address</Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <AddressSearch 
                        paging 
                        pageSize={10} 
                        theme="dark" 
                        renderInput={DesktopSearchInput} 
                        renderItemEdit={renderEditAction} 
                        onClear={handleClear} 
                        onSearchComplete={handleSearchComplete}
                        onSelect={handleSelect} 
                    />
                </DialogContent>
                <DialogActions>
                    <Box display="flex" flexDirection="row" width="100%">
                        <AddAddress show={showAddAddress} onConfirm={handleAddAddress} />
                        <button 
                            type="button" 
                            id="addressSearch-close-button" 
                            className={cx("action-button action-button--cancel", styles.closeButton)} 
                            onClick={handleCloseDialog}
                        >Close</button>
                    </Box>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export type { FindAddressProps };
export default FindAddress;