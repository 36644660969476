import { EntityName } from "../../../store/types";
import { getUploadedFiles } from "./components/fileUploader";
import { IFileList } from "./fileManager.d";


const getFileList = async (fileStoreToken: string, entity: EntityName, entityId: number | undefined): Promise<IFileList> => {
    if (entityId) {
        return getUploadedFiles(fileStoreToken, entity, entityId)
            .then(files => {
                console.log("Refreshed file list; file count: ", files.length)
                return files;
            })
            .then(value => value
                .filter(file => file.metaData.Id)
                .reduce<IFileList>((acc, current) => ({
                    ...acc,
                    [current.metaData.Id ?? ""]: current
                }), {}))
            .then(files => files)
            .catch(reason => {
                console.warn("Error refreshing file list:", reason);
                return {};
            });
    }
    return Promise.resolve({});
};

export { getFileList };
