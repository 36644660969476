import React from "react";

import EditIcon from "@material-ui/icons/Edit";

import useEditWaterSource from "../hooks/useEditWaterSource";
import { WaterSourceActionProps } from "./types";
import WaterSourceActionItem from "./WaterSourceActionItem";

const EditWaterSourceAction = React.forwardRef(function EditWaterSourceAction({ waterSourceNodeId }: WaterSourceActionProps, ref: React.Ref<HTMLLIElement>): JSX.Element {
    const editWaterSource = useEditWaterSource();
    const handleClick = (): void => {
        editWaterSource(waterSourceNodeId);
    };
    return <WaterSourceActionItem icon={<EditIcon />} primary="Edit" onClick={handleClick} ref={ref} />;
});

export type { WaterSourceActionProps };
export default EditWaterSourceAction;