import React from "react";
import cx from "classnames";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.primary.contrastText,
        textTransform: "unset",
        "&:hover": {
            color: theme.palette.secondary.main
        },
        "&:active": {
            color: theme.palette.secondary.main
        }
    },
    open: {
        color: theme.palette.secondary.main
    },
    endIcon: {
        marginLeft: 0
    },
    arrow: {
        fontSize: 32,
        "&:first-child": {
            fontSize: 32
        }
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
}));

interface AppBarMenuArrowProps {
    readonly show?: boolean;
    readonly open?: boolean
}

const AppBarMenuArrow = (props: AppBarMenuArrowProps): JSX.Element => {
    const { open, show } = props;
    const styles = useStyles();
    return show
        ? <ArrowDropDownIcon className={cx(styles.arrow, styles.expand, { [styles.expandOpen]: open })} />
        : <React.Fragment />;
};

interface AppBarMenuLabelProps {
    readonly id?: string;
    readonly label?: string;
    readonly icon?: React.ReactNode;
    readonly showArrow?: boolean;
    readonly open?: boolean;
    readonly onClick?: () => void;
}

const AppBarMenuLabel = React.forwardRef(function AppBarMenuLabel(props: AppBarMenuLabelProps, ref: React.Ref<HTMLButtonElement>): JSX.Element {
    const { icon, id, label, open, showArrow, onClick } = props;
    const styles = useStyles();
    const handleClick = (): void => {
        onClick?.();
    };
    return (
        <Button
            id={`appBar-menuLabel-${id}`}
            ref={ref}
            className={cx(styles.root, { [styles.open]: open })}
            startIcon={icon}
            endIcon={<AppBarMenuArrow show={showArrow} open={open} />}
            classes={{ endIcon: styles.endIcon }}
            onClick={handleClick} >
            <Typography variant="body2">
                {label}
            </Typography>
        </Button>
    );
});

export type { AppBarMenuLabelProps };
export default AppBarMenuLabel;
