import { useEffect } from "react";

import useIsMounted from "./useIsMounted";

type MountedEffectCallback = (ifMounted: (f: () => void) => void) => (void | (() => void | undefined));

const useMountedEffect = (effect: MountedEffectCallback, deps?: React.DependencyList): void => {
    const [, ifMounted] = useIsMounted();
    useEffect(() => {
        const cleanUp = effect(ifMounted);
        return (): void => {
            if (typeof cleanUp === "function") {
                cleanUp();
            }
        }
    }, [...(deps ?? [])]);
};

export type { MountedEffectCallback };
export default useMountedEffect;