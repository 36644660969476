import { IOrganisationAction, IOrganisationState } from '../types/organisations';


const defaultAction: IOrganisationAction = {
    type: '@@INIT',
    organisations: undefined
}

const defaultState = {
    organisations: undefined
}

export default (state: IOrganisationState = defaultState, action: IOrganisationAction = defaultAction): IOrganisationState => {
    switch (action.type) {
        case 'SET_ORGANISATIONS':
            return {
                ...state,
                organisations: action.organisations
            }
            
        default:
            return state;
    }
}

