import React from "react";

import { NodeID, equals, parseNodeId } from "../../lib/nodeIdentifier";
import useNavigation from "../../router/MobileRouter/hooks/useNavigation";

interface InspectionListContextItem {
    readonly inspection: NodeID;
    readonly prev?: NodeID;
    readonly next?: NodeID;
}

interface InspectionListContextProps {
    readonly list: InspectionListContextItem[];
}

const Context = React.createContext<InspectionListContextProps>({ list: [] });

const InspectionListContext = (props: React.PropsWithChildren<InspectionListContextProps>): JSX.Element => {
    return (
        <Context.Provider value={props}>
            {props.children}
        </Context.Provider>
    );
};

interface ListNavigation {
    readonly gotoNextItem: () => void;
    readonly gotoPrevItem: () => void;
}

interface ListContext {
    readonly count: number;
    readonly index: number;
    readonly isFirst: boolean;
    readonly isLast: boolean;
}

const useListContext = (nodeId: string | undefined): ListContext => {
    const { list } = React.useContext(Context);

    if (nodeId === undefined) {
        return {
            count: 0,
            index: 0,
            isFirst: false,
            isLast: false
        };
    }

    const reference = parseNodeId(nodeId);
    return {
        count: list.length,
        index: list.findIndex(item => equals(item.inspection, reference)),
        isFirst: ((): boolean => {
            if (list.length > 0) {
                const first = list[0];
                return equals(reference, first.inspection);
            }
            return true;
        })(),
        isLast: ((): boolean => {
            if (list.length > 0) {
                const last = list[list.length - 1];
                return equals(reference, last.inspection);
            }
            return true;
        })()
    };
};

const useListNavigation = (nodeId: string | undefined): ListNavigation => {
    const { list } = React.useContext(Context);
    const { gotoInspection } = useNavigation();

    if (nodeId === undefined) {
        return {
            gotoNextItem: (): void => undefined,
            gotoPrevItem: (): void => undefined
        };
    }

    const reference = parseNodeId(nodeId);
    const match = list.find(item => equals(reference, item.inspection));
    return {
        gotoNextItem: (): void => {
            const { next } = match ?? {};
            if (next) {
                gotoInspection(next);
            }
        },
        gotoPrevItem: (): void => {
            const { prev } = match ?? {};
            if (prev) {
                gotoInspection(prev);
            }
        }
    };
};

export type { InspectionListContextItem, InspectionListContextProps, ListNavigation };
export { useListContext, useListNavigation };
export default InspectionListContext;