import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import { InspectionCategory } from "../../../store/types";
import useQueryString from "../../hooks/useQueryString";

interface InspectionsParams {
    readonly category?: InspectionCategory;
    readonly station?: NodeID;
    readonly route?: NodeID;
}

type QueryString = Record<string, string | string[]>;

const getCategory = (qs: QueryString): InspectionCategory | undefined => {
    const { category } = qs;
    if (typeof category === "string") {
        const value = category?.toUpperCase();
        switch (value) {
            case "PLANNED":
                return "PLANNED";
            case "UNPLANNED":
                return "UNPLANNED";
        }
    }
    return undefined;
};

const getStation = (qs: QueryString): NodeID | undefined => {
    const { station } = qs;
    return typeof station === "string"
        ? parseNodeId(station)
        : undefined;
};

const getRoute = (qs: QueryString): NodeID | undefined => {
    const { route } = qs;
    return typeof route === "string"
        ? parseNodeId(route)
        : undefined;
};

const useInspectionsParams = (): InspectionsParams | undefined => {
    const qs = useQueryString();
    const category = getCategory(qs);
    const station = getStation(qs);
    const route = getRoute(qs);
    return {
        category,
        station,
        route
    };
};

export type { InspectionsParams };
export default useInspectionsParams;