import React from "react";

import useSelector from "../../../../lib/hooks/useSelector";
import HazardsDetails, { HazardsDetailsProps } from "./HazardsDetails.view";

const Connected = (props: Omit<HazardsDetailsProps, "isMobile">): JSX.Element => {
    const connectedProps: HazardsDetailsProps = {
        ...props,
        isMobile: useSelector(state => state.device.isMobile)
    };
    return <HazardsDetails {...connectedProps} />;
};

export default Connected;