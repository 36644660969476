import React from "react";

import { makeStyles, createStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { Owner, IMeasurement, ILookupItem } from "../../../../../store/types";
import Field from "../../../../card/components/field";
import Optional from "../../../../shared/Optional";
import { formatMultiLineText, formatText } from "../../../utils";
import { isHydrant } from "../../utils";

type HydrantDetails = Pick<Owner,
    "classification" |
    "station" |
    "surface" |
    "flowRate" |
    "pressure" |
    "mainsSize" |
    "plateLocation" |
    "plateDistance" |
    "additionalInfo" |
    "controlMeasures" |
    "hazardInformation" |
    "roadSpeed" |
    "hazardFlag" |
    "isSecondPersonRequired" |
    "hazardSeverity" |
    "hazards" |
    "riskSeverity" |
    "tags"
> & {
    hazardsValues?: number[]
};

type ReadOnlyProps = HydrantDetails & Pick<Owner, "category">;

const formatTags = (tags: ILookupItem[] | undefined): string => {
    if (tags?.length) {
        return tags
            .sort((a, b) => {
                if (a.enabled && !b.enabled) {
                    return -1;
                }
                if (!a.enabled && b.enabled) {
                    return 1;
                }
                return a.sortOrder - b.sortOrder;
            })
            .map(tag => tag.enabled ? tag.displayText : tag.displayText.toLowerCase())
            .join(", ");
    }
    return "-";
};

const formatMainsSize = (mainsSize: IMeasurement | undefined): string => {
    const { value } = mainsSize ?? {};
    if (value) {
        const { symbolName: unit } = mainsSize?.unit ?? {};
        return `${value} ${unit ?? ""}`;
    }
    return "-"
};

const useStyles = makeStyles(theme => createStyles({
    flowRateField: {
        marginRight: theme.spacing(5),
    },
}));

const ReadOnly = (props: ReadOnlyProps): JSX.Element => {
    const styles = useStyles();
    const { category, classification, station, surface, flowRate, pressure, mainsSize, plateLocation, plateDistance, additionalInfo, riskSeverity, tags } = props;
    const showField = isHydrant(category);
    return (
        <React.Fragment>
            <Field label="Tags" text={formatTags(tags)} />
            <Field label="Classification" text={formatText(classification?.displayText)} />
            <Field label="Station" text={formatText(station?.displayText)} />
            <Field label="Risk Severity" text={formatText(riskSeverity?.displayText)} />
            <Optional hidden={!showField}>
                <Field label="Surface" text={formatText(surface?.displayText)} />
            </Optional>
            <Box display="flex">
                <Optional hidden={!showField}>
                    <Field label="Flow rate" text={formatText(flowRate?.toString())} className={styles.flowRateField} />
                </Optional>
                <Optional hidden={!showField}>
                    <Field label="Pressure" text={formatText(pressure?.toString())} />
                </Optional>
            </Box>
            <Optional hidden={!showField}>
                <Field label="Mains size" text={formatMainsSize(mainsSize)} />
                <Field label="Plate Location" text={formatText(plateLocation?.displayText)} />
                <Field label="Plate Distance" text={formatText(plateDistance?.toString())} />
            </Optional>
            <Field label="Additional Information" text={formatMultiLineText(additionalInfo)} />
        </React.Fragment>
    );
};

export type { HydrantDetails, ReadOnlyProps };
export default ReadOnly;
