import React from "react";
import { useDispatch } from "react-redux";

import { useRoute, useScheme } from "../../../../components/appWindowContext";
import { DialogVariant } from "../../../../components/utils/globalDialog";
import useSelector from "../../../../lib/hooks/useSelector";
import { setGlobalToast, setDialogOpen } from "../../../../store/actions/app";
import { addHazards, removeHazards } from "../../../../store/actions/hazards";
import { setMovingWaterSource } from "../../../../store/actions/map";
import {
    editHydrant,
    editEmergencyWaterSupply,
    editRiser,
    editUnknownWaterSource,
    setEditWaterSourceSuccess,
    startUpdateWaterSourceActivation,
    setEditWaterSource
} from "../../../../store/actions/waterSources"
import { defaultLookups } from "../../../../store/reducers/app";
import { IGlobalToast, ILookupItem } from "../../../../store/types"
import WaterSourceControlBase, { IWaterSourceControlProps } from "./waterSourceControl.view";

const resolveCoordinates = (source: number[] | undefined): [number, number] | undefined => {
    return source && source.length === 2
        ? [source[0], source[1]]
        : undefined;
};

const WaterSourceControl = ({ selectedWaterSource, fullPageLayout, onRefresh }: Partial<Pick<IWaterSourceControlProps, "selectedWaterSource" | "fullPageLayout" | "onRefresh">>): JSX.Element => {
    const dispatch = useDispatch();

    const { change: showRoute } = useRoute({ newWindow: fullPageLayout });
    const { change: showScheme } = useScheme({ newWindow: fullPageLayout });

    const editing = useSelector(state => state.waterSources.editingSelectedWaterSource);
    const editWaterSourceSuccess = useSelector(state => state.waterSources.editWaterSourceSuccess ?? false);
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const movingWaterSource = useSelector(state => state.map.movingWaterSource);
    const movingWaterSourceLocation = useSelector(state => resolveCoordinates(state.map.movingWaterSourceLocation));
    const fileStoreToken = useSelector(state => state.fileStore.token);

    if (selectedWaterSource) {
        const props: IWaterSourceControlProps = {
            selectedWaterSource,
            fullPageLayout,
            editing,
            editWaterSourceSuccess,
            lookups,
            movingWaterSource,
            movingWaterSourceLocation,
            fileStoreToken,
            editEmergencyWaterSupply: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                const action = editEmergencyWaterSupply(variables, triggerMapReRender, tags);
                await action(dispatch);
            },
            editHydrant: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                const action = editHydrant(variables, triggerMapReRender, tags);
                await action(dispatch);
            },
            editRiser: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                const action = editRiser(variables, triggerMapReRender, tags);
                await action(dispatch);
            },
            editUnknownWaterSource: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                const action = editUnknownWaterSource(variables, triggerMapReRender, tags);
                await action(dispatch);
            },
            startUpdateWaterSourceActivation: async (variables): Promise<void> => {
                const action = startUpdateWaterSourceActivation(variables);
                await action(dispatch);
            },
            setDialogOpen: (dialog: DialogVariant): void => {
                dispatch(setDialogOpen(dialog))
            },
            setEditWaterSource: (editing): void => {
                dispatch(setEditWaterSource(editing))
            },
            setEditWaterSourceSuccess: (): void => {
                dispatch(setEditWaterSourceSuccess())
            },
            setGlobalToast: (globalToast?: IGlobalToast): void => {
                dispatch(setGlobalToast(globalToast))
            },
            setMovingWaterSource: (movingWaterSource: boolean): void => {
                dispatch(setMovingWaterSource(movingWaterSource))
            },
            onShowRoute: id => {
                showRoute(id);
            },
            onShowScheme: id => {
                showScheme(id);
            },
            onSaveHazards: async (added, removed): Promise<void> => {
                const { waterSourceNodeId } = selectedWaterSource;
                let refresh = false;
                if (added && added.length > 0) {
                    const data = added.map(h => ({
                        hazardTypeId: h.value
                    }));
                    const action = addHazards(data, waterSourceNodeId);
                    await action(dispatch);
                    refresh = true;
                }
                if (removed && removed.length > 0) {
                    const action = removeHazards(removed.map(h => h.hazardNodeId), waterSourceNodeId);
                    await action(dispatch);
                    refresh = true;
                }
                if (refresh) {
                    onRefresh?.();
                }
            },
            onRefresh
        };

        return <WaterSourceControlBase {...props} />;
    }

    return <React.Fragment />;
};

export default WaterSourceControl;