import { IInspection, InspectionCategory } from "../../store/types";
import { Comparer, bindComparers } from "./comparers";

const compareRouteSortOrder = (a: IInspection, b: IInspection): number => {
    const routeOrderA = a.waterSource.routeSortOrder ?? 0;
    const routeOrderB = b.waterSource.routeSortOrder ?? 0;
    const order = routeOrderA - routeOrderB;
    return order;
};

const compareInspectionDate = (a: IInspection, b: IInspection): number => {
    const dateA = a.inspectionDate;
    const dateB = b.inspectionDate;

    if (dateA && dateB) {
        return dateA.compareTo(dateB);
    }
    if (dateA && dateB === undefined) {
        return -1;
    }
    if (dateB) {
        return 1;
    }
    return 0;
};

const compareInspectionId = (a: IInspection, b: IInspection): number => {
    return a.inspectionId - b.inspectionId;
};

export const buildComparer = (category: InspectionCategory): Comparer<IInspection> => {
    const comparers: Comparer<IInspection>[] = [];
    if (category === "PLANNED") {
        comparers.push(compareRouteSortOrder);
    }
    comparers.push(compareInspectionDate);
    comparers.push(compareInspectionId);
    return bindComparers(comparers);
};