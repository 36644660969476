import React from "react";

import { NodeID, parseNodeId } from "../../../../lib/nodeIdentifier";
import { IQuickSearchItem, IMapProjection } from "../../../../store/types";
import QuickSearch, { waterSourceIdSearch, streetSearch, postCodeSearch, gridRefSearch } from "../../../../components/quickSearch";
import useNavigation from "../../hooks/useNavigation";

interface AppBarSearchProps {
    readonly projection: IMapProjection;
    readonly onSelect?: (nodeId: NodeID) => void;
}

const searchTypes = [
    waterSourceIdSearch,
    streetSearch,
    postCodeSearch,
    gridRefSearch
];

const AppBarSearch = (props: AppBarSearchProps): JSX.Element => {
    const { projection, onSelect } = props;
    const { isHome, goHome } = useNavigation();
    const handleSearchResultClick = (result: IQuickSearchItem): void => {
        if (!isHome) {
            goHome();
        }
        onSelect?.(parseNodeId(result.waterSourceNodeId));
    };
    return <QuickSearch searchTypes={searchTypes} projectionCode={projection?.code} onSearchResultClick={handleSearchResultClick} />;
};

export type { AppBarSearchProps };
export default AppBarSearch;