import React from "react";
import { GlobalsNumber, MarginLeftProperty, MarginRightProperty, WidthProperty } from "csstype";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

interface ViewTitleProps {
    readonly disableGutters?: boolean;
    readonly extended?: boolean;
    readonly title?: React.ReactNode;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        flexGrow: 1,
        marginLeft: ({ disableGutters, extended }: ViewTitleProps): MarginLeftProperty<number> => {
            return disableGutters || extended ? "initial" : theme.spacing(2);
        },
        marginRight: ({ disableGutters, extended }: ViewTitleProps): MarginRightProperty<number> => {
            return disableGutters || extended ? "initial" : theme.spacing(2);
        },
        order: ({ extended }: ViewTitleProps): GlobalsNumber => {
            return extended ? 3 : "initial"
        },
        width: ({ extended }: ViewTitleProps): WidthProperty<string | 0> => {
            return extended ? "100%" : "initial"
        }
    },
}));

const ViewTitle = (props: ViewTitleProps): JSX.Element => {
    const styles = useStyles(props);
    return <Typography variant="h6" classes={{ root: styles.root }}>{props.title}</Typography>;
};

export type { ViewTitleProps };
export default ViewTitle;