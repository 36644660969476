import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { FetchStatus } from "../../../api/hooks";
import { getDefectsForWaterSource } from "../../../store/actions/defects";
import { IDefect } from "../../../store/types";

const useDefects = (waterSourceNodeId: string | undefined): [IDefect[], () => void, FetchStatus] => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [defects, setDefects] = useState<IDefect[]>([]);

    const dispatch = useDispatch();

    const fetch = useCallback((): void => {
        if (waterSourceNodeId) {
            setStatus("loading");
            const action = getDefectsForWaterSource({ id: waterSourceNodeId });
            action(dispatch)
                .then(response => setDefects(response))
                .catch(error => console.warn("Error getting defects for water source.", error))
                .finally(() => setStatus("complete"));
        }
        else {
            setDefects([]);
            setStatus("complete");
        }
    }, [waterSourceNodeId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [defects, fetch, status];
};

export default useDefects;