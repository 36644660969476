import React from "react";
import { useDispatch } from "react-redux";

import EditLocationIcon from '@material-ui/icons/EditLocation';

import { startSetMapClickAction, setMovingWaterSource } from "../../../../../../store/actions/map";
import useEditWaterSource from "../hooks/useEditWaterSource";
import { WaterSourceActionProps } from "./types";
import WaterSourceActionItem from "./WaterSourceActionItem";

const MoveWaterSourceAction = ({ waterSourceNodeId }: WaterSourceActionProps): JSX.Element => {
    const dispatch = useDispatch();
    const editWaterSource = useEditWaterSource();
    const handleClick = (): void => {
        dispatch(startSetMapClickAction());
        editWaterSource(waterSourceNodeId);
        dispatch(setMovingWaterSource(true));
    };
    return <WaterSourceActionItem icon={<EditLocationIcon />} primary="Move" onClick={handleClick} />;
};

export type { WaterSourceActionProps };
export default MoveWaterSourceAction;