import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";
import { defaultLookups } from "../../../store/reducers/app";
import { useRoute } from "../../appWindowContext";
import RouteSearchBase, { RouteSearchProps } from "./routeSearch.view";

const RouteSearch = (): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showRoute } = useRoute({ newWindow: true });
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const props: RouteSearchProps = {
        lookups,
        hideToast: () =>
            dispatch(setGlobalToast()),
        showError: (message, error) => error
            ? dispatch(setError(message, error))
            : dispatch(setGlobalToast({
                showToast: true,
                type: "ERROR",
                message
            })),
        showLoading: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message
            })),
        showRouteOnMap: route =>
            showRoute(parseNodeId(route.routeNodeId)),
        showSuccess: message =>
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message,
                timeout: 5000
            }))
    };
    return <RouteSearchBase {...props} />;
};

export default RouteSearch;