import { ILookupItem, ILookups } from "../../../store/types";
import { buildLookupFilterItems, buildYesNoFilterItems, formatFilterText } from "../utils";
import { IWaterSourceSearchFilters, IWaterSourceSelectedFilters } from "./types";
import { resolveMatchString, resolveBoolean, resolveRange, formatFilterDate } from "../utils";
import { buildListFilterObject } from "../filter/utils";

const isWaterSourceTag = (tag: ILookupItem): boolean => {
    return tag.filters.some(t => t === "Hydrant" || t === "EmergencyWaterSupply" || t === "Riser" || t === "UnknownWaterSource");
};

export const buildWaterSourceFilters = (lookups: ILookups): Omit<IWaterSourceSearchFilters, "isActive"> => ({
    id: "TEXT",
    tags: buildLookupFilterItems(lookups.tags.filter(isWaterSourceTag), undefined, true, (a, b) => a.displayText.localeCompare(b.displayText)),
    assetReference: "TEXT",
    category: buildLookupFilterItems(lookups.waterSourceCategories),
    classification: buildLookupFilterItems(lookups.waterSourceCategories, lookups.waterSourceClasses, true),
    status: buildLookupFilterItems(lookups.waterSourceCategories, lookups.waterSourceStatuses, true),
    route: buildLookupFilterItems(lookups.stations, lookups.routes, true),
    station: buildLookupFilterItems(lookups.stations, undefined, true),
    isDefective: buildYesNoFilterItems(),
    isOperable: buildYesNoFilterItems(),
    lastInspectionDate: "DATERANGE",
    nextInspectionDate: "DATERANGE",
    hydrantLocation: buildLookupFilterItems(lookups.hydrantLocations, undefined, true),
    surface: buildLookupFilterItems(lookups.surfaces, undefined, true),
    hazards: buildLookupFilterItems(lookups.hazardTypes, undefined, true),
    controlMeasures: buildLookupFilterItems(lookups.controlMeasures, undefined, true),
    flowRate: "TEXTRANGE",
    pressure: "TEXTRANGE",
    mainsSize: "TEXTRANGE",
    organisation: buildLookupFilterItems(lookups.organisationTypes, lookups.organisations, true),
});

export const buildQueryFilter = (filters: IWaterSourceSelectedFilters): Record<string, unknown> => {
    return {
        "$all": [
            buildListFilterObject(filters.tags, 'tags'),
            buildListFilterObject(filters.category, 'category'),
            buildListFilterObject(filters.classification, 'classification'),
            buildListFilterObject(filters.organisation, 'organisation'),
            buildListFilterObject(filters.status, 'status'),
            buildListFilterObject(filters.hazards, 'hazardtype'),
            buildListFilterObject(filters.hydrantLocation, 'hydrantLocation'),
            buildListFilterObject(filters.route, 'route'),
            buildListFilterObject(filters.station, 'station'),
            buildListFilterObject(filters.surface, 'surface'),
            buildListFilterObject(filters.controlMeasures, 'controlMeasures')
        ].filter(item => Object.keys(item).length),
        "externalId": {
            "$like": resolveMatchString(filters.id)
        },
        "assetReference": {
            "$like": resolveMatchString(filters.assetReference)
        },
        "isActive": {
            "$eq": resolveBoolean(filters.isActive)
        },
        "isDefective": {
            "$eq": resolveBoolean(filters.isDefective)
        },
        "isOperable": {
            "$eq": resolveBoolean(filters.isOperable)
        },
        "flowRate": resolveRange(filters.flowRate, formatFilterText),
        "pressure": resolveRange(filters.pressure, formatFilterText),
        "mainsSize": resolveRange(filters.mainsSize, formatFilterText),
        "lastInspectionDate": resolveRange(filters.lastInspectionDate, formatFilterDate),
        "nextInspectionDate": resolveRange(filters.nextInspectionDate, formatFilterDate)
    }
};
