import React, { Component } from 'react';
import { LocalDate } from "@js-joda/core";
import cx from "classnames";

import { encodeNodeId, NodeID } from '../../../lib/nodeIdentifier';
import { StateValidators, valid, validateState, requiredObject, requiredLocalDate, validationStyle } from '../../../lib/validation';
import { ILookupItem, IWaterSource, IQuickSearchItem, CreateInspectionData } from '../../../store/types';
import LabelledField, { SelectOption } from '../../labelledField';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import InlineQuickSearch from '../../inlineQuickSearch';
import './createInspectionDialog.scss';

interface ICreateInspectionDialogProps {
    readonly isOpen: boolean;
    readonly selectedWaterSource?: IWaterSource;
    readonly waterSourcesToAssign?: string[];
    readonly inspectionTypes: ILookupItem[];
    readonly inspectionGroups: ILookupItem[];
    readonly technicians: ILookupItem[];
    readonly getWaterSource: (variables: { id: string }) => void;
    readonly onClose?: () => void;
    readonly onCreateInspection?: (data: CreateInspectionData) => void;
}

interface ICreateInspectionDialogState {
    readonly inspectionType: SelectOption | undefined;
    readonly inspectionGroup: SelectOption | undefined;
    readonly technician: SelectOption | undefined;
    readonly inspectionDate: LocalDate | undefined;
    readonly description: string | undefined;
    readonly waterSource: IQuickSearchItem | undefined;
}

type StateKeys = keyof ICreateInspectionDialogState;
type StateTypes = ICreateInspectionDialogState[StateKeys];

interface IWaterSourceViewProps {
    readonly renderFunction: () => JSX.Element;
    readonly waterSourcesToAssign: number;
}

const WaterSourceView = ({ renderFunction, waterSourcesToAssign }: IWaterSourceViewProps): JSX.Element => {
    if (waterSourcesToAssign) {
        return (
            <LabelledField
                fieldType="readonly"
                id="number_of_waterSources"
                label="Number of Water Sources"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={waterSourcesToAssign}
            />
        );
    }
    return renderFunction();
}

export class CreateInspectionDialog extends Component<ICreateInspectionDialogProps, ICreateInspectionDialogState> {
    private _validators: StateValidators<ICreateInspectionDialogState>;

    constructor(props: ICreateInspectionDialogProps) {
        super(props);

        this.state = {
            description: undefined,
            waterSource: props.selectedWaterSource,
            inspectionType: undefined,
            inspectionDate: undefined,
            inspectionGroup: undefined,
            technician: undefined,
        };

        this._validators = {
            description: valid,
            waterSource: props.waterSourcesToAssign ? valid : requiredObject,
            inspectionType: requiredObject,
            inspectionDate: requiredLocalDate,
            inspectionGroup: valid,
            technician: valid,
        }
    }

    public render(): JSX.Element {
        const { waterSourcesToAssign } = this.props;

        const handleClose = (): void => this.props.onClose?.();

        const handleChange = (key: StateKeys) => {
            return (value: StateTypes): void => {
                this.setState(current => ({ ...current, [key]: value }));
            };
        };

        const handleClear = (key: StateKeys) => {
            return (): void => {
                this.setState(current => ({ ...current, [key]: undefined }));
            };
        };

        const disableSave = !this.validateForm(this.state);
        return (
            <Dialog fullWidth maxWidth="sm" overflowY="unset" open={this.props.isOpen} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>New Inspection</DialogTitle>
                <DialogContent overflowY="unset">
                    <LabelledField
                        fieldType="text"
                        id="inspection_description"
                        label="Description"
                        classes={{ container: 'input__group', label: 'input__label' }}
                        value={this.state.description}
                        editing
                        onChange={handleChange('description')}
                    />
                    <WaterSourceView waterSourcesToAssign={waterSourcesToAssign ? waterSourcesToAssign.length : 0} renderFunction={(): JSX.Element => this.renderSelectedWaterSource(this.state.waterSource)} />
                    <LabelledField
                        fieldType="select"
                        id="inspection_type"
                        label="Inspection Type *"
                        classes={{ container: 'input__group', label: 'input__label', input: validationStyle(this._validators.inspectionType)(this.state.inspectionType) }}
                        value={this.state.inspectionType}
                        selectOptions={this.props.inspectionTypes}
                        editing
                        onChange={handleChange('inspectionType')}
                        onClear={handleClear('inspectionType')}
                    />
                    <LabelledField
                        fieldType="date"
                        id="inspection_date"
                        label="Inspection Date *"
                        classes={{ container: 'input__group', label: 'input__label', input: cx("createInspection-dateInput", validationStyle(this._validators.inspectionDate)(this.state.inspectionDate)) }}
                        value={this.state.inspectionDate}
                        editing
                        onChange={handleChange('inspectionDate')}
                    />
                    <LabelledField
                        fieldType="select"
                        id="inspection_group"
                        label="Assigned Inspection Group"
                        classes={{ container: 'input__group', label: 'input__label' }}
                        value={this.state.inspectionGroup}
                        selectOptions={this.props.inspectionGroups}
                        editing
                        onChange={handleChange('inspectionGroup')}
                        onClear={handleClear('inspectionGroup')}
                    />
                    <LabelledField
                        fieldType="select"
                        id="inspection_technician"
                        label="Assigned Technician"
                        classes={{ container: 'input__group', label: 'input__label' }}
                        value={this.state.technician}
                        selectOptions={this.props.technicians}
                        editing
                        onChange={handleChange('technician')}
                        onClear={handleClear('technician')}
                    />
                </DialogContent>
                <DialogActions>
                    <button id="cancel_button" className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button id="save_button" className="action-button" disabled={disableSave} onClick={(): void => this.handleSave()}>Save</button>
                </DialogActions>
            </Dialog>
        );
    }

    private validateForm(state: ICreateInspectionDialogState): boolean {
        const validate = validateState(this._validators);
        const isValid = validate(state);
        return isValid;
    }

    private renderSelectedWaterSource(waterSource: IQuickSearchItem | undefined): JSX.Element {
        const handleSearchClear = (): void => {
            this.setState({ waterSource: undefined });
        };
        const handleSearchResult = (item: IQuickSearchItem): void => {
            this.props.getWaterSource({ id: item.waterSourceNodeId });
            this.setState({ waterSource: { ...item } });
        };

        return <InlineQuickSearch result={waterSource} invalid={!this._validators.waterSource(this.state.waterSource)} onClear={handleSearchClear} onResult={handleSearchResult} />
    }

    private handleSave(): void {
        const { waterSource, description, inspectionType, inspectionDate, inspectionGroup, technician } = this.state;
        const { waterSourcesToAssign } = this.props;

        if (inspectionDate && inspectionType && ((waterSource?.waterSourceNodeId) || waterSourcesToAssign)) {
            const waterSourceNodeIds: string[] = waterSourcesToAssign
                ? waterSourcesToAssign
                : waterSource?.waterSourceNodeId
                    ? [waterSource?.waterSourceNodeId]
                    : []

            this.props.onClose?.();
            this.props.onCreateInspection?.({
                waterSourceIds: waterSourceNodeIds,
                typeId: Number(inspectionType.value),
                inspectionDate: inspectionDate,
                description: description,
                inspectionGroupId: inspectionGroup
                    ? encodeNodeId(NodeID("InspectionGroup", inspectionGroup.value))
                    : undefined,
                inspectorId: technician
                    ? encodeNodeId(NodeID("Technician", technician.value))
                    : undefined
            });
        }
        else {
            console.warn("CreateInspection", "Missing required field values.", this.state);
        }
    }
}

export type { ICreateInspectionDialogProps };
export default CreateInspectionDialog;