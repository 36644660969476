import React from "react";
import cx from "classnames";

import { createStyles, WithStyles, Theme, makeStyles, StyleRules } from "@material-ui/core/styles";
import Optional from "../shared/Optional";

interface TabPanelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    readonly index: number | string;
    readonly value: number | string;
    readonly disableGutters?: boolean;
    readonly noMargins?: boolean;
}

const defaultStyles = (theme: Theme): StyleRules => createStyles<"root" | "container", Pick<TabPanelProps, "disableGutters" | "noMargins">>({
    root: {
        height: "100%",
        overflow: "hidden"
    },
    container: {
        height: "100%",
        overflowY: "auto",
        padding: ({ disableGutters, noMargins }): string | number =>
            noMargins
                ? theme.spacing(0)
                : disableGutters
                    ? theme.spacing(2, 0)
                    : theme.spacing(2)
    }
});
const useStyles = makeStyles(defaultStyles);

const TabPanel = (props: Partial<WithStyles<typeof defaultStyles>> & TabPanelProps): JSX.Element => {
    const { classes, children, value, index, disableGutters, noMargins, ...rest } = props;
    const styles = useStyles({ disableGutters, noMargins });
    const show = value === index;
    return (
        <div className={cx(styles.root, classes?.root)} role="tabpanel" hidden={!show} id={`tabpanel-${index}`} {...rest}>
            <Optional hidden={!show} >
                <div className={cx(styles.container, classes?.container)}>
                    {children}
                </div>
            </Optional>
        </div>
    )
};

export type { TabPanelProps };
export default TabPanel;
