import { ILookups } from "../../../store/types";
import { buildListFilterObject } from "../filter/utils";
import {
    buildLookupFilterItems,
    formatFilterCurrency,
    formatFilterDate,
    formatFilterText,
    resolveRange,
    resolveMatchString
} from "../utils";
import { SchemeSearchFilters, SchemeSelectedFilters } from "./types";

export const buildSchemeFilters = (lookups: ILookups): SchemeSearchFilters => ({
    id: "TEXTRANGE",
    type: buildLookupFilterItems(lookups.schemeTypes, undefined, true),
    reference: "TEXT",
    title: "TEXT",
    description: "TEXT",
    station: buildLookupFilterItems(lookups.stations, undefined, true),
    waterSource: "TEXT",
    waterSourceCount: "TEXTRANGE",
    stages: buildLookupFilterItems(lookups.schemeStages),
    orderReference: "TEXT",
    invoiceReference: "TEXT",
    invoiceDueDate: "DATERANGE",
    cost: "TEXTRANGE",
    dateReceived: "DATERANGE",
    dateReturned: "DATERANGE",
    dateOrdered: "DATERANGE",
    dateClosedExternally: "DATERANGE",
    dateClosed: "DATERANGE"
});

export const buildQueryFilter = (filters: SchemeSelectedFilters): Record<string, unknown> => ({
    "id": resolveRange(filters.id, formatFilterText),
    "$all": [
        buildListFilterObject(filters.type, 'type'),
        buildListFilterObject(filters.station, 'station'),
        buildListFilterObject(filters.stages, 'stage')
    ].filter(item => Object.keys(item).length),
    "reference": {
        "$like": resolveMatchString(filters.reference)
    },
    "title": {
        "$like": resolveMatchString(filters.title)
    },
    "description": {
        "$like": resolveMatchString(filters.description)
    },
    "waterSource": {
        "$like": resolveMatchString(filters.waterSource)
    },
    "waterSourceCount": resolveRange(filters.waterSourceCount, formatFilterText),
    "orderReference": {
        "$like": resolveMatchString(filters.orderReference)
    },
    "invoiceReference": {
        "$like": resolveMatchString(filters.invoiceReference)
    },
    "invoiceDueDate": resolveRange(filters.invoiceDueDate, formatFilterDate),
    "cost": resolveRange(filters.cost, formatFilterCurrency),
    "dateReceived": resolveRange(filters.dateReceived, formatFilterDate),
    "dateReturned": resolveRange(filters.dateReturned, formatFilterDate),
    "dateOrdered": resolveRange(filters.dateOrdered, formatFilterDate),
    "dateClosedExternally": resolveRange(filters.dateClosedExternally, formatFilterDate),
    "dateClosed": resolveRange(filters.dateClosed, formatFilterDate)
});