import React from "react";

import useSelector from "../../../../lib/hooks/useSelector";
import WaterSourceLocation, { WaterSourceLocationProps } from "./WaterSourceLocation.view";

const Connected = (props: Omit<WaterSourceLocationProps, "isMobile">): JSX.Element => {
    const connectedProps: WaterSourceLocationProps = {
        ...props,
        isMobile: useSelector(state => state.device.isMobile)
    };
    return <WaterSourceLocation {...connectedProps} />;
};

export default Connected;