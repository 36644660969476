import React, { useCallback } from "react";
import { LocalDate } from "@js-joda/core";

import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import { parseNodeId } from "../../../../lib/nodeIdentifier";
import { useNavigation } from "../../../../router/MobileRouter/hooks";
import { Owner } from "../../../../store/types";
import WaterSourceTitle, { createTitleProps } from "../../../waterSource/waterSourceTitle";
import Hazards from "../hazards";
import RoadSpeed from "./components/RoadSpeed";
import HazardFlag from "./components/HazardFlag";
import Address from "./components/Address";
import Coordinates from "./components/Coordinates";
import InspectionDate from "./components/InspectionDate";
import useStyles from "./InspectionItem.styles";

interface IInspectionItemProps {
    readonly inspectionNodeId: string;
    readonly inspectionId: number;
    readonly inspectionDate: LocalDate | undefined;
    readonly waterSource: Owner;
}

const InspectionItem = (props: IInspectionItemProps): JSX.Element => {
    const classes = useStyles();
    const { gotoInspection } = useNavigation();
    const { inspectionNodeId, inspectionId, inspectionDate, waterSource } = props;

    const handleClick = useCallback((): void => {
        gotoInspection(parseNodeId(inspectionNodeId));
    }, [inspectionNodeId, gotoInspection]);

    const { address, coordinates } = waterSource.location;

    return (
        <ListItem className={classes.root} button divider onClick={handleClick}>
            <ListItemText disableTypography>
                <div className={classes.header}>
                    <Box mr={1}>
                        <WaterSourceTitle {...createTitleProps(waterSource)} subtitle={inspectionId} />
                    </Box>
                    <Box ml="auto">
                        <InspectionDate inspectionDate={inspectionDate} />
                    </Box>
                </div>
                <div className={classes.row}>
                    <Address address={address} />
                </div>
                <div className={classes.row}>
                    <Box flexGrow={1} display="flex" flexDirection="row">
                        <Coordinates coordinates={coordinates} />
                    </Box>
                    <Box ml="auto" display="flex" flexDirection="row" justifyContent="flex-end">
                        <HazardFlag hazardFlag={waterSource.hazardFlag} />
                        <RoadSpeed roadSpeed={waterSource.roadSpeed} />
                        {waterSource.isSecondPersonRequired &&
                            <Box mr={1}>
                                <FontAwesomeIcon icon={faUsers} />
                            </Box>
                        }
                        <Hazards {...waterSource} />
                    </Box>
                </div>
            </ListItemText>
        </ListItem>
    );
};

export type { IInspectionItemProps };
export default InspectionItem;