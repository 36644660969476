import getNotifications from './getNotifications';
import { useState, useEffect, } from "react";
import { emptyPage, FilteredObject, IConnection, IConnectionQuery, IQueryFilter } from '../../../store/types';
import { FetchResult, FetchStatus } from '../../../api/hooks/types';
import { Notification } from "../types";


const empty: IConnection<Notification> = {
    edges: [],
    items: [],
    pageInfo: emptyPage,
    totalCount: 0
};

const useNotifications = (pageIndex = 1, pageSize = 99999): FetchResult<Notification> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [notifications, setNotifications] = useState<IConnection<Notification>>(empty);

    useEffect(() => {
        setStatus("loading");
        const filter: FilteredObject<IQueryFilter> = {};
        const variables: IConnectionQuery<FilteredObject<IQueryFilter>> = {
            filter: filter ?? {},
            paging: {
                pageIndex: pageIndex,
                pageSize: pageSize,
                sortBy: "id",
                sortDirection: "DESCENDING"
            }
        };
        getNotifications(variables)
            .then(response => setNotifications(response))
            .catch(() => setNotifications(empty))
            .finally(() => setStatus("complete"))
    }, [pageIndex]);

    return [status, notifications];
};

export default useNotifications;

