import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from 'axios';

import store from "../store";
import { setMapSettings, setStreetViewSettings } from "../store/actions/map";
import { setAccount, getLookups, getProfile, setVersion, setTokenExpiry, getConfigurableLookups } from "../store/actions/app";
import { setDevice } from "../store/actions/device";
import { IDeviceSettings } from "../store/types/device";
import { setFeatures } from "../store/actions/features";
import { IAccount } from '../store/types/app'
import { IFeatures } from "../store/types/features";
import { IFileStore } from "../store/types/fileStore";
import { IMapSettings } from '../store/types/map';

import AppRouter from '../router/AppRouter';
import Login from "../components/utils/Login";
import AccessDenied from "../components/utils/AccessDenied";
import { setFileStore } from "../store/actions/fileStore";
import { ViewOptions } from "../store/types";
import { setViewOption } from "../store/actions/mobile";

interface IResponse {
    readonly data: ISettings;
}

interface ISettings {
    readonly map: IMapSettings;
    readonly relay: {
        readonly endpoints: {
            readonly data: string;
        };
    };
    readonly gazetteer: {
        readonly type: string;
        readonly url: string;
        readonly apiKey: string;
        readonly structure: any[];
    };
    readonly streetView: {
        readonly apiKey: string;
    };
    readonly features: IFeatures;
    readonly device: IDeviceSettings;
    readonly fileStore: IFileStore;
    readonly user: IAccount;
    readonly mobile: {
        readonly viewOption?: ViewOptions;
    }
    readonly auth: {
        readonly tokenExpiry: number;
    }
}

const root = document.getElementById("root");

const getApiVersion = async (headers?: Record<string, any>): Promise<string> => {
    const { data } = await axios.get<{ version: string }>("/api/version", headers);
    return data.version;
};

export const getUser = async (headers?: Record<string, any>): Promise<void> => {
    try {
        const response: IResponse = await axios.get("/api/settings", headers);
        const settings: ISettings = response.data;

        const apiVersion = await getApiVersion(headers);

        store.dispatch(setMapSettings(settings.map));
        store.dispatch(setStreetViewSettings(settings.streetView));
        store.dispatch(setFeatures(settings.features));
        store.dispatch(setDevice(settings.device));
        store.dispatch(setFileStore(settings.fileStore));
        store.dispatch(setAccount(settings.user));
        store.dispatch(setViewOption(settings.mobile.viewOption));
        store.dispatch(setVersion(settings.user.versionNumber, apiVersion));
        store.dispatch(setTokenExpiry(settings.auth.tokenExpiry));
        await getLookups()(store.dispatch);
        await getConfigurableLookups()(store.dispatch);
        await getProfile({ userName: settings.user.name })(store.dispatch);
        ReactDOM.render(<Provider store={store}><AppRouter /></Provider>, root);
    } catch (err) {
        if (window.location.pathname === "/access-denied") {
            ReactDOM.render(<AccessDenied />, root);
            return;
        }

        // assume error is due to user not being logged in, so render login page
        ReactDOM.render(<Login />, root);
        return;
    }
}