import React from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { setGlobalToast } from "../../../store/actions/app";
import { setAssignInspectorSuccess } from "../../../store/actions/inspections";
import { useInspection } from "../../appWindowContext";
import AssignInspectorsDialogBase, { IAssignInspectorsDialogProps } from './assignInspectorsDialog';

const AssignInspectorsDialog = (props: Pick<IAssignInspectorsDialogProps, "isOpen" | "onAssignInspector" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const assignInspectorSuccess = useSelector(state => state.inspections.assignInspectorSuccess);
    const crews = useSelector(state => state.app.lookups?.crews ?? []);
    const technicians = useSelector(state => state.app.lookups?.technicians ?? []);
    const { instance: inspection } = useInspection();
    const combined: IAssignInspectorsDialogProps = {
        ...props,
        assignInspectorSuccess,
        crews,
        inspectionNodeId: inspection?.inspectionNodeId ?? "",
        technicians,
        onClearError: (): void => {
            dispatch(setGlobalToast());
        },
        onShowError: (message): void => {
            dispatch(setGlobalToast({
                type: "ERROR",
                message,
                showToast: true
            }));
        },
        setAssignInspectorSuccess: (): void => {
            dispatch(setAssignInspectorSuccess());
        }
    };
    return <AssignInspectorsDialogBase {...combined} />;
};

export default AssignInspectorsDialog;