import React from "react";
import { PropsWithEditing, SelectProps } from "../types";
import LabelledField, { LabelledFieldProps } from "../../../labelledField";

const WaterSourceStatus = ({ editing, options, discriminator, value, onClear, onSelect }: PropsWithEditing<SelectProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "water-source-status",
        label: "Status",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value,
        selectOptions: options.filter(status => status.groupingId === discriminator?.value),
        editing: editing ?? false,
        onChange: value => onSelect?.(value),
        onClear
    };
    return <LabelledField {...props} />;
};

export default WaterSourceStatus;