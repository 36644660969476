import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import executeQuery from "../../lib/executeQuery";
import { IGlobalToast, Repair, IRepairsAction, IRepairInput, ICreateRepairVariables, INote, IRepairsState, ICompleteRepairInput, ICancelRepairVariables } from "../types";
import { setGlobalToast, setLoadingToast } from "./app";
import { setError } from "./errors";
import createExternalRepairQuery from "./graphQL/createExternalRepair";
import createInternalRepairQuery from "./graphQL/createInternalRepair";
import completeRepairQuery from "./graphQL/completeRepair";
import getRepairsForwaterSourceQuery from "./graphQL/getRepairsForWaterSource";
import getRepairQuery from "./graphQL/getRepair";
import updateRepairQuery from "./graphQL/updateRepair";
import cancelRepairMutation from './graphQL/cancelRepair';

export const addRepair = (newRepair: Repair): IRepairsAction => ({
    type: "ADD_REPAIR",
    newRepair
});

export const clearRepairs = (): IRepairsAction => ({
    type: "CLEAR_REPAIRS"
});

export const setCreateRepairSuccess = (success?: boolean): IRepairsAction => ({
    type: "SET_CREATE_REPAIR_SUCCESS",
    createRepairSuccess: success
});

export const editRepair = (editedRepair: Repair): IRepairsAction => ({
    type: "EDIT_REPAIR",
    editedRepair: editedRepair
});

export const setEditRepairSuccess = (success?: boolean): IRepairsAction => ({
    type: "SET_EDIT_REPAIR_SUCCESS",
    editRepairSuccess: success
});

export const setCompleteRepairSuccess = (success?: boolean): IRepairsAction => ({
    type: "SET_COMPLETE_REPAIR_SUCCESS",
    completeRepairSuccess: success
})

export const setRepairs = (repairs: Repair[]): IRepairsAction => ({
    type: "SET_REPAIRS",
    repairs
});

export const setSelectedRepair = (selectedRepair?: Repair): IRepairsAction => ({
    type: "SET_SELECTED_REPAIR",
    selectedRepair
});

export const getRepair = (variables: { id: string }, setLoading = true) => {
    return async (dispatch: ThunkDispatch<IRepairsState, unknown, AnyAction>): Promise<void> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast("Loading repair data..."));
            }

            const data = await executeQuery<{ node: Repair | undefined }>(getRepairQuery, variables);
            dispatch(setSelectedRepair(data?.node));

            if (setLoading) {
                dispatch(setGlobalToast());
            }
        } catch (error) {
            dispatch(setError("Error loading repair", error));
        }
    };
};

export const getRepairsForWaterSource = (variables: { id: string }, setLoading = true) => {
    return async (dispatch: ThunkDispatch<IRepairsState, unknown, AnyAction>): Promise<Repair[]> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast("Getting repairs for water source..."));
            }

            const res = await executeQuery<{ node: { repairs: Repair[] } }>(getRepairsForwaterSourceQuery, variables);
            const repairs = res?.node.repairs ?? [];

            dispatch(setRepairs(repairs));

            if (setLoading) {
                dispatch(setGlobalToast());
            }

            return repairs;
        } catch (error) {
            dispatch(setError("Error loading repairs", error));
            console.error(error);
            return [];
        }
    };
};

export const startAddExternalRepair = (variables: ICreateRepairVariables) => {
    return async (dispatch: ThunkDispatch<IRepairsState, unknown, AnyAction>): Promise<Repair | undefined> => {
        try {
            dispatch(setLoadingToast("Creating external repair..."));
            const res = await executeQuery<{ addExternalRepair: { repair: Repair } }>(createExternalRepairQuery, variables);
            const { repair } = res?.addExternalRepair ?? {};
            if (repair) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Repair ${repair.repairId} created`,
                    showToast: true,
                    timeout: 5000
                };
                dispatch(addRepair(repair));
                dispatch(setCreateRepairSuccess(true));
                dispatch(setGlobalToast(globalToast));
                return repair;
            }
        } catch (err) {
            dispatch(setError("Error creating external repair", err));
            console.error(err);
        }
    };
};

export const startAddInternalRepair = (variables: ICreateRepairVariables) => {
    return async (dispatch: ThunkDispatch<IRepairsState, unknown, AnyAction>): Promise<Repair | undefined> => {
        try {
            dispatch(setLoadingToast("Creating internal repair..."));
            const res = await executeQuery<{ addInternalRepair: { repair: Repair } }>(createInternalRepairQuery, variables);
            const { repair } = res?.addInternalRepair ?? {};
            if (repair) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Repair ${repair.repairId} created`,
                    showToast: true,
                    timeout: 5000
                };
                dispatch(addRepair(repair));
                dispatch(setCreateRepairSuccess(true));
                dispatch(setGlobalToast(globalToast));
                return repair;
            }
        } catch (err) {
            dispatch(setError("Error creating internal repair", err));
            console.error(err);
        }
    };
};

export const startCompleteRepair = (variables: ICompleteRepairInput) => {
    return async (dispatch: ThunkDispatch<IRepairsState, unknown, AnyAction>): Promise<Repair | undefined> => {
        try {
            dispatch(setLoadingToast("Completing Repair..."));
            const res = await executeQuery<{ completeRepair: { repair: Repair } }>(completeRepairQuery, variables);
            const { repair } = res?.completeRepair ?? {};
            if (repair) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Changes saved to repair ${repair.repairId}`,
                    showToast: true,
                    timeout: 5000
                };

                dispatch(editRepair(repair));
                dispatch(setCompleteRepairSuccess(true));
                dispatch(setGlobalToast(globalToast));
                return repair;
            }
        } catch (error) {
            dispatch(setError("Error completing repair", error));
            console.error(error);
        }
    };
};

export const startEditRepair = (variables: IRepairInput) => {
    return async (dispatch: ThunkDispatch<IRepairsState, unknown, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast("Saving changes to repair..."));
            const res = await executeQuery<{ updateRepair: { repair: Repair } }>(updateRepairQuery, variables);
            const { repair } = res?.updateRepair ?? {};
            if (repair) {
                dispatch(editRepair(repair));
                dispatch(setEditRepairSuccess(true))
                dispatch(setSelectedRepair(repair));
                dispatch(setGlobalToast({
                    type: "SUCCESS",
                    message: `Changes saved to repair ${repair.repairId}`,
                    showToast: true,
                    timeout: 5000
                }));
            }
        } catch (error) {
            dispatch(setError("Error updating repair", error));
            console.error(error);
        }
    };
};

export const startCancelRepair = (variables: ICancelRepairVariables) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast('Cancelling repair...'));
            const res = await executeQuery<{ cancelRepair: { repair: Repair } }>(cancelRepairMutation, variables);
            const { repair } = res?.cancelRepair ?? {};
            if (repair) {
                dispatch(editRepair(repair));
                dispatch(setSelectedRepair(repair));
                dispatch(setEditRepairSuccess(true));
                dispatch(setGlobalToast({
                    type: 'SUCCESS',
                    message: `Changes saved to repair ${repair.repairId}`,
                    showToast: true,
                    timeout: 5000
                }));
            }
        } catch (err) {
            dispatch(setError('Error cancelling repair', err))
        }
    }
}
    
