import React, { useState, useCallback, useMemo } from "react";

import { Coordinate as olCoordinate } from "ol/coordinate";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PlaceIcon from "@material-ui/icons/Place";

import { getWaterSourcesInRadius } from "../../../../api";
import { useDeviceLocation, DeviceLocation } from "../../../../api/hooks";
import { AccessControl } from "../../../../auth/components";
import { RoleNames } from "../../../../auth/roles";
import { bindFeatureFactory, toLocationFeature } from "../../../../lib/map";
import { IPoint } from "../../../../store/types";
import Map, { MapDataAsync, FeatureArray } from "../../../map/mobile";
import StyleCache from "../../../map/StyleCache";
import AppDialog from "../../../shared/appDialog";
import { IWaterSourceSummary } from "../../types";
import Actions from "./components/actions";
import { NodeID, parseNodeId } from "../../../../lib/nodeIdentifier";

interface ShowMapProps {
    readonly focus: IWaterSourceSummary | undefined;
    readonly fence?: number;
    readonly onClose?: () => void;
    readonly onMoveWaterSource?: (nodeId: NodeID, newLocation: IPoint) => Promise<IWaterSourceSummary | undefined>;
    readonly onRefresh?: () => void;
}

const defaultFence = 700; // 1000m = 1km

const ShowMap = (props: ShowMapProps): JSX.Element => {
    const [showDialog, setShowDialog] = useState(false);
    const [editing, setEditing] = useState(false);
    const [newLocation, setNewLocation] = useState<olCoordinate>();
    const [refresh, setRefresh] = useState(false);

    const [focus, setFocus] = useState(props.focus);

    const fence = props.fence ? props.fence : defaultFence;
    const { location, waterSourceNodeId } = focus ?? {};
    const { x, y } = location?.coordinates ?? {};
    const centre: olCoordinate = useMemo(() => [x ?? 0, y ?? 0], [x, y]);

    const closeDialog = (): void => setShowDialog(false);
    const openDialog = (): void => setShowDialog(true);

    const startEdit = (): void => setEditing(true);
    const endEdit = (): void => setEditing(false);

    const clearNewLocation = (): void => setNewLocation(undefined);

    const saveNewLocation = (): void => {
        if (waterSourceNodeId && newLocation && x && y) {
            const oldX = Math.round(x);
            const oldY = Math.round(y);

            const newX = newLocation[0];
            const newY = newLocation[1];

            const locationHasChanged = !(newX === oldX && newY === oldY);
            if (locationHasChanged) {
                const updateFocus = (update: IWaterSourceSummary | undefined): void => {
                    if (update) {
                        setFocus(current => current
                            ? { ...current, location: { ...update.location } }
                            : undefined
                        );
                    }
                };

                const nodeId = parseNodeId(waterSourceNodeId);
                props.onMoveWaterSource?.(nodeId, { x: newX, y: newY })
                    .then(updateFocus)
                    .then(() => setRefresh(true))
                    .catch(reason => console.warn("Error moving location:", reason));
            }
        }
    };

    const handleClose = (): void => {
        props.onClose?.();
        if (refresh) {
            props.onRefresh?.();
        }
        closeDialog();
    };

    const handleBeginEdit = (): void => {
        startEdit();
        clearNewLocation();
    };
    const handleCancelEdit = (): void => {
        endEdit();
        clearNewLocation();
        setRefresh(false);
    };
    const handleSaveEdit = (): void => {
        endEdit();
        saveNewLocation();
        clearNewLocation();
    };

    const handleMoveLocation = (coordinates: olCoordinate): void => {
        setNewLocation(coordinates);
    };

    const makeData = useCallback(async (): MapDataAsync => {
        const styleCache = new StyleCache();
        const createFeature = bindFeatureFactory(styleCache, waterSourceNodeId);
        const result: FeatureArray = await getWaterSourcesInRadius(centre, fence ?? defaultFence)
            .then(result => result.map(createFeature))
            .then(fs => fs)
            .catch(() => []);
        return [centre, result];
    }, [fence, centre, waterSourceNodeId, refresh]);

    const deviceLocation: DeviceLocation | undefined = useDeviceLocation();
    const locationFeature = toLocationFeature(deviceLocation);

    const actions = (
        <AccessControl role={RoleNames.WS_EDIT_LOCATION_COORDS}>
            <Actions editing={editing} onCancel={handleCancelEdit} onEdit={handleBeginEdit} onSave={handleSaveEdit} />
        </AccessControl>
    );

    return (
        <React.Fragment>
            <ListItem button onClick={openDialog}>
                <ListItemIcon>
                    <PlaceIcon />
                </ListItemIcon>
                <ListItemText primary="Show on map" />
            </ListItem>
            <AppDialog appBar={{ title: "Location" }} open={showDialog} disableGutters commit={actions} onClose={handleClose}>
                <Map data={makeData} deviceLocation={locationFeature} editing={editing} onMoveLocation={handleMoveLocation} />
            </AppDialog>
        </React.Fragment>
    );
};

export type { ShowMapProps };
export default ShowMap;