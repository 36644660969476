import { formatText } from '../../lib/Utils'
import { ILookupItem } from "../../store/types";

export type FilterIterator<T extends unknown> = {
    readonly [K in keyof T]?: string[];
}

export type SelectedFilters = Partial<{
    readonly [key: string]: (string | undefined)[];
}>;

export type ViewOptions = "all" | "active" | "archived";

export type FilterKind = "TEXT" | "TEXTRANGE" | "DATERANGE";

export interface FilterOption {
    readonly value: number | boolean | string;
    readonly text: string;
    readonly enabled: boolean;
    readonly children?: FilterOption[];
}

export const FilterOption = (item: ILookupItem): FilterOption => {
    return ({
        value: item.value,
        text: formatText(item.displayText.toLowerCase()),
        enabled: item.enabled
    });
};

export interface ITableHeader {
    displayText: string;
    sortBy?: string;
}
