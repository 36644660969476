import React from "react";

import Line from "./Line";

interface LinesProps {
    readonly children?: string | string[];
}

const isEmpty = (s: string | null | undefined): boolean => {
    if (s === undefined || s === null) {
        return true;
    }
    if (s.trim() === "") {
        return true;
    }
    return false;
};

const Lines = ({ children }: LinesProps): JSX.Element => {
    if (children) {
        const hasBreak = (index: number): boolean => {
            return index < children.length - 1;
        };
        if (Array.isArray(children)) {
            return (
                <React.Fragment>
                    {children.filter(line => !isEmpty(line)).map((line, index) => <Line key={index} lineBreak={hasBreak(index)}>{line}</Line>)}
                </React.Fragment>
            );
        }
        return <Line>{children}</Line>;
    }
    return <React.Fragment />
};

export type { LinesProps };
export default Lines;