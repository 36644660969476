import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import UpdateIcon from '@material-ui/icons/Update';

import MainMenu from "./MainMenu";

interface NavigationBarProps {
    readonly disableRefresh?: boolean;
    readonly onRefreshClick?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main
    }
}));

const NavigationBar = ({ disableRefresh, onRefreshClick }: NavigationBarProps): JSX.Element => {
    const styles = useStyles();
    return (
        <AppBar component="footer" position="static" classes={{ root: styles.root }}>
            <Toolbar>
                <MainMenu />
                <Box flexGrow={1} />
                {!disableRefresh &&
                    <IconButton edge="end" color="inherit" onClick={onRefreshClick}>
                        <UpdateIcon />
                    </IconButton>
                }
            </Toolbar>
        </AppBar>
    );
};

export type { NavigationBarProps };
export default NavigationBar;