import React from 'react';
import { TagDropDownInput } from '@3tc/shared-components';

import { NodeID, encodeNodeId } from '../../lib/nodeIdentifier';
import { ILookupItem } from '../../store/types/app';
import ElementContainer from '../labelledField/components/elementContainer';
import LabelElement from '../labelledField/components/labelElement';
import { containerClass, labelClass } from '../labelledField/labelledField.utils';
import { ElementProps } from '../labelledField/types';
import styles from "./Tags.module.scss";
import './Tags.scss';

interface TagItem extends Pick<ILookupItem, "displayText" | "enabled" | "sortOrder"> {
    readonly id: number;
}

interface ITagInputProps extends ElementProps {
    readonly options: ILookupItem[];
    readonly tags: ILookupItem[];
    readonly ownerNodeId: NodeID;
    readonly editing?: boolean;
    readonly onChange?: () => void;
    readonly addTag: (nodeId: NodeID, tagId: number) => void;
    readonly removeTag: (nodeId: NodeID, tagId: number) => void;
}

const Tags = (props: ITagInputProps): JSX.Element => {
    const { id, label, editing, tags, options, ownerNodeId, addTag, removeTag, onChange } = props;

    const handleAddTag = (tag: TagItem): void => {
        addTag(ownerNodeId, tag.id);
        onChange?.();
    };

    const handleDeleteTag = (tag: TagItem): void => {
        removeTag(ownerNodeId, tag.id);
        onChange?.();
    };

    const compareArrays = (currentItem: ILookupItem): boolean => {
        const filtered = tags.filter(otherItem => otherItem.value === currentItem.value);
        return filtered.length === 0;
    };

    const compareTags = (a: ILookupItem, b: ILookupItem): number => {
        if (a.enabled && !b.enabled) {
            return -1;
        }
        if (!a.enabled && b.enabled) {
            return 1;
        }
        return a.displayText.localeCompare(b.displayText);
    };

    const formatTag = (item: ILookupItem): TagItem => {
        const formatText = (item: ILookupItem): string => {
            return item.enabled
                ? item.displayText
                : `${item.displayText.toLowerCase()} (archived)`;
        };
        return {
            ...item,
            id: item.value,
            displayText: formatText(item)
        };
    };

    const labelPosition = props.labelPosition ?? "TOP";
    const hasSelection = (tags.length ?? 0) > 0;
    const selectedTags = tags
        .sort(compareTags)
        .map(formatTag);
    const tagOptions = options
        .filter(compareArrays)
        .filter(tag => tag.enabled)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(formatTag);

    return (
        <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
            {labelPosition === "TOP" && <LabelElement ownerId={id} label={label} classes={labelClass(props)} />}
            {hasSelection || editing
                ? (
                    <TagDropDownInput
                        id={`${id}-${encodeNodeId(ownerNodeId)}`}
                        tags={selectedTags}
                        options={tagOptions}
                        readOnly={!editing}
                        addHandler={handleAddTag}
                        deleteHandler={handleDeleteTag}
                    />)
                : <span className={styles.noSelection}>-</span>
            }
            {labelPosition === "BOTTOM" && <LabelElement ownerId={id} label={label} classes={labelClass(props)} />}
        </ElementContainer>
    );
};

export type { ITagInputProps };
export default Tags;
