import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { NodeID } from "../../../lib/nodeIdentifier";
import { setDialogOpen } from "../../../store/actions/app";
import { IWaterSource, IInspection } from "../../../store/types";
import { useInspection } from "../../appWindowContext";
import useInspections from "../../appWindowContext/hooks/useInspections";
import InspectionPanelBase, { IInspectionPanelProps } from "./InspectionPanel.view";
import { Comparer, compareTimestamps, applyComparers } from "../../../lib/comparers";
import { IWaterSourceSummary } from "../../inspectionView";

const sortInspections = (inspections: IInspection[]): IInspection[] => {
    return inspections
        .sort((a, b) => {
            if (a.isOpen && !b.isOpen || !a.isOpen && b.isOpen) {
                return -1;
            }
            const comparers: Comparer<IInspection>[] = [
                (a, b): number => compareTimestamps(a, b, "created"),
                (a, b): number => a.inspectionId - b.inspectionId
            ];
            const result = applyComparers(comparers, a, b);
            return result;
        })
        .reverse();
};

const InspectionPanel = (props: Pick<IInspectionPanelProps, "hideNavButton"> & { readonly waterSource: IWaterSource; readonly onRefresh?: (waterSource?: IWaterSourceSummary) => void }): JSX.Element => {
    const { waterSource, onRefresh, ...otherProps } = props;
    const dispatch = useDispatch();
    const [inspections, refreshInspections] = useInspections(waterSource.waterSourceNodeId);
    const { instance: inspection, change: changeInspection, clear: clearInspection, refresh: refreshInspection } = useInspection();
    const createSuccess = useSelector(state => state.inspections.createInspectionSuccess);
    const prevCreateSuccess = useRef(createSuccess);

    const handleRefreshWaterSource = (): void => {
        onRefresh?.(waterSource);
    };

    useEffect(() => {
        if (prevCreateSuccess.current !== createSuccess && createSuccess) {
            refreshInspections();
        }
    }, [createSuccess, refreshInspections]);

    const combined: IInspectionPanelProps = {
        ...otherProps,
        selectedInspection: inspection,
        inspections: sortInspections(inspections),
        waterSourceCategory: waterSource.category,
        onClearInspection: (): void => {
            clearInspection();
        },
        onRefreshInspection: async (): Promise<void> => {
            await refreshInspection();
            refreshInspections();
            handleRefreshWaterSource();
        },
        onSelectInspection: (id: NodeID): void => {
            changeInspection(id);
        },
        showCreateDefectDialog: (): void => {
            dispatch(setDialogOpen("createDefect"));
        },
        showCreateInspectionDialog: (): void => {
            dispatch(setDialogOpen("createInspection"));
        }
    };
    return <InspectionPanelBase {...combined} />;
};

export default InspectionPanel;