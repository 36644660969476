import React from "react";
import { LocalDate } from "@js-joda/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, DateProps } from "../types";
import { formatNextInspectionValue, formatNextInspectionTag } from "../../../../lib/formatters";

interface NextInspectionDateProps extends DateProps {
    readonly recommended?: LocalDate | undefined
}

const id = "water-source-next-inspection-date";

const useStyles = makeStyles(theme => createStyles({
    tag: {
        color: theme.palette.common.white,
        marginLeft: theme.spacing(1)
    }
}));

const Editor = ({ value, onChange }: Pick<NextInspectionDateProps, "value" | "onChange">): JSX.Element => {
    const dateProps: LabelledFieldProps = {
        fieldType: "date",
        id: id,
        label: "Next Inspection Date",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value,
        editing: true,
        onChange: value => onChange?.(value),
    };
    return <LabelledField {...dateProps} />;
};

const ReadOnly = ({ value, recommended }: Pick<NextInspectionDateProps, "value" | "recommended">): JSX.Element => {
    const styles = useStyles();
    return (
        <Box display="flex" flexDirection="row" alignItems="center" marginBottom="1rem">
            <LabelledField
                fieldType="readonly"
                id={id}
                label="Next Inspection Date"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={formatNextInspectionValue(value, recommended)}
                noMargin
            />
            <Typography variant="caption" className={styles.tag}>{formatNextInspectionTag(value, recommended)}</Typography>
        </Box>
    );
};

const WaterSourceNextInspectionDate = (props: PropsWithEditing<NextInspectionDateProps>): JSX.Element => {
    const { editing, value, ...rest } = props;
    return editing
        ? <Editor value={value} {...rest} />
        : <ReadOnly value={value} {...rest} />
};

export type { NextInspectionDateProps };
export default WaterSourceNextInspectionDate;