import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";

interface InputProps {
    readonly value: any;
    readonly type?: "text" | "date" | "number";
    readonly id?: string;
    readonly label?: string;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly multiline?: boolean;
    readonly rows?: number;
    readonly onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginBottom: theme.spacing(1.5),
    },
    inputRoot: {
        color: theme.palette.text.primary,
        padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
        ["&.Mui-disabled"]: {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
        }
    },
    input: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    label: {
        position: "unset",
        top: "unset",
        bottom: "unset",
        marginBottom: theme.spacing(2),
        "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
            transform: "translate(0, 10px) scale(0.75)",
        }
    },
}));

const Input = (props: InputProps): JSX.Element => {
    const styles = useStyles();
    const { value, id, label, placeholder, required, disabled, type, multiline, rows, onChange } = props;
    const labelId = `${id}-label`;
    const inputProps = {
        id,
        type,
        value,
        placeholder,
        required,
        disabled,
        multiline,
        rows,
        onChange
    };
    return (
        <FormControl className={styles.root} fullWidth variant="filled">
            <InputLabel id={labelId} htmlFor={id} classes={{ formControl: styles.label }} variant="filled" shrink required={required}>{label}</InputLabel>
            <FilledInput {...inputProps} disableUnderline={disabled} classes={{ root: styles.inputRoot, input: styles.input }} />
        </FormControl>
    );
}

export type { InputProps };
export default Input;