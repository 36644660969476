import * as Categories from "./categories.d";
import { Category } from "./category.d";

const fromValue = (i: number): Category => {
    switch (i) {
        case 1:
            return Categories.HYDRANT;
        case 2:
            return Categories.EWS;
        case 3:
            return Categories.RISER;
        default:
            return Categories.UNKNOWN;
    }
}

export const mapCategory = (value: number | undefined): Category => value
    ? fromValue(value)
    : Categories.UNKNOWN;