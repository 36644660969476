import React, { useCallback, useMemo, useState } from "react";

import { Coordinate as olCoordinate } from "ol/coordinate";
import olSimpleGeometry from "ol/geom/SimpleGeometry";

import { DeviceLocation, useDeviceLocation } from "../../api/hooks";
import { GroupItem } from "../../lib/collections";
import { bindFeatureFactory, toLocationFeature, WaterSourceFeatureData } from "../../lib/map";
import { parseNodeId } from "../../lib/nodeIdentifier";
import { useNavigation } from "../../router/MobileRouter/hooks";
import { IInspection, Owner } from "../../store/types";
import Map, { FeatureArray, MapDataAsync } from "../map/mobile";
import StyleCache from "../map/StyleCache";
import SelectorDialog from "./components/SelectorDialog";
import useStyles from "./InspectionMap.styles";

const ensureDistinct = (accumulator: Owner[], current: Owner): Owner[] => {
    const isMatch = (owner: Owner): boolean => {
        return owner.waterSourceNodeId === current.waterSourceNodeId;
    }
    if (accumulator.find(isMatch) === undefined) {
        accumulator.push(current);
    }
    return accumulator;
};

const findCentre = (features: FeatureArray, mapCentre: olCoordinate): olCoordinate => {
    if (features.length > 0) {
        const feature = features[0];
        const geometry = feature.getGeometry() as olSimpleGeometry;
        if (geometry) {
            return geometry.getFirstCoordinate();
        }
    }
    return mapCentre;
};

export const isFeatureData = (o: unknown): o is WaterSourceFeatureData => {
    return (o as WaterSourceFeatureData)?.waterSourceNodeId !== undefined;
};

interface InspectionMapProps {
    readonly groups: GroupItem<IInspection>[];
    readonly totalCount: number;
    readonly mapCentre: olCoordinate;
}

const InspectionMap = (props: InspectionMapProps): JSX.Element => {
    const styles = useStyles();
    const { gotoInspection } = useNavigation();
    const [waterSource, setWaterSource] = useState<WaterSourceFeatureData>();
    const [inspections, setInspections] = useState<IInspection[]>([]);
    const [showSelector, setShowSelector] = useState(false);

    const closeSelector = useCallback((): void => setShowSelector(false), []);
    const openSelector = useCallback((): void => setShowSelector(true), []);

    const { groups, totalCount, mapCentre } = props;

    const handleMapSelect = useCallback((data: unknown): void => {
        if (isFeatureData(data)) {
            const filtered = groups
                .flatMap(group => group.items)
                .filter(node => node.waterSource.waterSourceNodeId === data.waterSourceNodeId);
            const count = filtered.length;
            if (count > 1) {
                setWaterSource(data);
                setInspections(filtered);
                openSelector();
            }
            if (count === 1) {
                gotoInspection(parseNodeId(filtered[0].inspectionNodeId));
            }
            // do nothing; or show message?
        }
    }, [groups, openSelector, gotoInspection]);

    const waterSources = useMemo(() => {
        if (totalCount === 0) {
            return [];
        }
        const ws = groups
            .flatMap(group => group.items)
            .map(node => node.waterSource)
            .reduce(ensureDistinct, []);
        return ws;
    }, [groups]);

    const selectedNodeId = useMemo(() => {
        if (totalCount === 0) {
            return undefined;
        }
        const first = waterSources[0];
        return first.waterSourceNodeId;
    }, [waterSources]);

    const makeData = useCallback(async (): MapDataAsync => {
        const styleCache = new StyleCache();
        const createFeature = bindFeatureFactory(styleCache, selectedNodeId);
        const features: FeatureArray = waterSources.map(createFeature);
        const centre = findCentre(features, mapCentre);
        return Promise.resolve([centre, features]);
    }, [waterSources, selectedNodeId, mapCentre]);

    const deviceLocation: DeviceLocation | undefined = useDeviceLocation();
    const locationFeature = toLocationFeature(deviceLocation);

    return (
        <div className={styles.root}>
            <Map data={makeData} deviceLocation={locationFeature} onSelect={handleMapSelect} />
            <SelectorDialog open={showSelector} waterSource={waterSource} inspections={inspections} onClose={closeSelector} />
        </div>
    );
};

export type { InspectionMapProps };
export default InspectionMap;