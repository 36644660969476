import React from "react";

import LabelledField, { LabelledFieldProps } from "../labelledField";
import Optional from "../shared/Optional";
import { AddressDetailsData, AddressDetailsKeys, AddressDetailsTypes } from "./types";

type AddressDetailsOptions = {
    [K in AddressDetailsKeys]?: boolean;
}

interface AddressDetailsProps {
    readonly id?: string;
    readonly address: AddressDetailsData | undefined;
    readonly editing?: boolean;
    readonly hidden?: AddressDetailsOptions;
    readonly onChange?: (key: AddressDetailsKeys, value: AddressDetailsTypes) => void;
}

const AddressDetails = (props: AddressDetailsProps): JSX.Element => {
    const { id, address, editing, hidden, onChange } = props;

    const handleChange = (key: AddressDetailsKeys) =>
        (value: AddressDetailsTypes): void => {
            onChange?.(key, value);
        };

    const addressDescriptionProps: LabelledFieldProps = {
        fieldType: "text",
        id: `${id}-address-description`,
        label: "Name/Number",
        classes: { container: 'input__group', label: 'input__label' },
        value: address?.description,
        editing: editing ?? false,
        onChange: handleChange("description")
    };
    const addressStreetProps: LabelledFieldProps = {
        fieldType: "text",
        id: `${id}-address-street`,
        label: "Street",
        classes: { container: 'input__group', label: 'input__label' },
        value: address?.streetDescription,
        editing: editing ?? false,
        onChange: handleChange("streetDescription")
    };
    const addressLocalityProps: LabelledFieldProps = {
        fieldType: "text",
        id: `${id}-address-locality`,
        label: "Locality",
        classes: { container: 'input__group', label: 'input__label' },
        value: address?.locality,
        editing: editing ?? false,
        onChange: handleChange("locality")
    };
    const addressTownProps: LabelledFieldProps = {
        fieldType: "text",
        id: `${id}-address-town`,
        label: "Town",
        classes: { container: 'input__group', label: 'input__label' },
        value: address?.town,
        editing: editing ?? false,
        onChange: handleChange("town")
    };
    const addressPostCodeProps: LabelledFieldProps = {
        fieldType: "text",
        id: `${id}-address-postCode`,
        label: "Post Code",
        classes: { container: 'input__group', label: 'input__label' },
        value: address?.postCode,
        editing: editing ?? false,
        onChange: handleChange("postCode")
    };
    return (
        <div>
            <Optional hidden={hidden?.description}>
                <LabelledField {...addressDescriptionProps} />
            </Optional>
            <Optional hidden={hidden?.streetDescription}>
                <LabelledField {...addressStreetProps} />
            </Optional>
            <Optional hidden={hidden?.locality}>
                <LabelledField {...addressLocalityProps} />
            </Optional>
            <Optional hidden={hidden?.town}>
                <LabelledField {...addressTownProps} />
            </Optional>
            <Optional hidden={hidden?.postCode}>
                <LabelledField {...addressPostCodeProps} />
            </Optional>
        </div>
    );
};

export type { AddressDetailsProps, AddressDetailsOptions };
export default AddressDetails;