import React, { useContext, useState, useEffect, useRef } from "react";

import { FetchStatus } from "../../api/hooks";
import { NodeID } from "../../lib/nodeIdentifier";
import useAppWindowFeatures from "../../router/DesktopRouter/hooks/useAppWindowFeatures";
import useAppWindowParams, { WaterSourceTab, AppWindowFilters, AppWindowParams } from "../../router/DesktopRouter/hooks/useAppWindowParams";
import useNavigation from "../../router/DesktopRouter/hooks/useNavigation";
import { IScheme, IRoute, IWaterSource, IDefect, IInspection, Repair, ICoordinate } from "../../store/types";
import useGetDefect from "./hooks/useGetDefect";
import useGetInspection from "./hooks/useGetInspection";
import useGetRepair from "./hooks/useGetRepair";
import useGetRoute from "./hooks/useGetRoute";
import useGetScheme from "./hooks/useGetScheme";
import useGetWaterSource from "./hooks/useGetWaterSource";
import useMountedEffect from "../../api/hooks/useMountedEffect";

type RefreshTarget = "scheme" | "route" | "waterSource" | "defect" | "inspection" | "repair";

type EntityState<T> = [FetchStatus, T | undefined];

interface RouteState {
    readonly route: IRoute | undefined;
    readonly showOnMap: boolean;
}

interface AppWindowContextState {
    readonly scheme?: EntityState<IScheme>;
    readonly route?: EntityState<RouteState>;
    readonly waterSource?: EntityState<IWaterSource>;
    readonly defect?: EntityState<IDefect>;
    readonly inspection?: EntityState<IInspection>;
    readonly repair?: EntityState<Repair>;
    readonly refresh: (...targets: RefreshTarget[]) => () => Promise<void>;
}

const initState: AppWindowContextState = {
    refresh: (): (() => Promise<void>) => async (): Promise<void> => Promise.resolve()
};

const Context = React.createContext<[AppWindowContextState, React.Dispatch<React.SetStateAction<AppWindowContextState>>]>([initState, (): void => { return; }]);

const useAppWindowContext = (): AppWindowContextState => {
    const [state] = useContext(Context);
    return state;
};

const AppWindowContext = (props: React.PropsWithChildren<unknown>): JSX.Element => {
    const params = useAppWindowParams();

    const [schemeStatus, scheme, refetchScheme] = useGetScheme(params.scheme);
    const [routeStatus, route, refetchRoute] = useGetRoute(params.route);
    const [waterSourceStatus, waterSource, refetchWaterSource] = useGetWaterSource(params.waterSource);
    const [inspectionStatus, inspection, refetchInspection] = useGetInspection(params.inspection);
    const [defectStatus, defect, refetchDefect] = useGetDefect(params.defect);
    const [repairStatus, repair, refetchRepair] = useGetRepair(params.repair);

    const [state, setState] = useState(initState);

    const refresh = async (target: RefreshTarget): Promise<void> => {
        switch (target) {
            case "scheme":
                return refetchScheme();
            case "route":
                return refetchRoute();
            case "waterSource":
                return refetchWaterSource();
            case "defect":
                return refetchDefect();
            case "repair":
                return refetchRepair();
            case "inspection":
                return refetchInspection();
        }
    };

    useMountedEffect(ifMounted => ifMounted(() => {
        setState({
            scheme: [schemeStatus, scheme],
            route: [routeStatus, { route, showOnMap: false }],
            waterSource: [waterSourceStatus, waterSource],
            inspection: [inspectionStatus, inspection],
            defect: [defectStatus, defect],
            repair: [repairStatus, repair],
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        });
    }), []);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState(state => ({
            ...state,
            scheme: [schemeStatus, scheme],
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        }));
    }), [scheme]);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState((state): AppWindowContextState => {
            const [, routeState] = state.route ?? [];
            return ({
                ...state,
                route: [routeStatus, { route, showOnMap:  routeState?.showOnMap ?? false }],
                refresh: (...targets: RefreshTarget[]) =>
                    async (): Promise<void> => {
                        await Promise.allSettled(targets?.map(refresh));
                    }
            });
    });
    }), [route]);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState(state => ({
            ...state,
            waterSource: [waterSourceStatus, waterSource],
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        }));
    }), [waterSource]);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState(state => ({
            ...state,
            inspection: [inspectionStatus, inspection],
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        }));
    }), [inspection]);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState(state => ({
            ...state,
            defect: [defectStatus, defect],
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        }));
    }), [defect]);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState(state => ({
            ...state,
            repair: [repairStatus, repair],
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        }));
    }), [repair]);

    useMountedEffect(ifMounted => ifMounted(() => {
        setState(state => ({
            ...state,
            refresh: (...targets: RefreshTarget[]) =>
                async (): Promise<void> => {
                    await Promise.allSettled(targets?.map(refresh));
                }
        }));
    }), [params.filters]);

    return (
        <Context.Provider value={[state, setState]}>
            {props.children}
        </Context.Provider>
    );
};

interface EntityContext<T, O = unknown> {
    readonly status: FetchStatus;
    readonly instance: T | undefined;
    readonly change: (id: NodeID, options?: O) => void;
    readonly clear: () => void;
    readonly refresh: () => Promise<void>;
}

const CreateEntityContext = <T extends unknown, O = unknown>(
    state: EntityState<T> | undefined,
    change: (id: NodeID, options?: O) => void,
    clear: () => void,
    refresh: () => Promise<void>): EntityContext<T, O> => {
    const [status, instance] = state ?? [];
    return {
        status: status ?? "ready",
        instance,
        change: (id, options): void => change(id, options),
        clear,
        refresh
    };
};

type Flatten<T> = T extends EntityState<infer E> ? E : never;
type ContextEntityState = Omit<AppWindowContextState, "filters" | "refresh">;
type ContextStateKeys = keyof ContextEntityState;
type ContextStateTypes = ContextEntityState[ContextStateKeys];
type EntityTypes = Flatten<ContextStateTypes>;
interface IdAccessor<T extends EntityTypes> {
    (instance: T | undefined): string | undefined;
}
const makeOnChanged = <T extends EntityTypes>(key: ContextStateKeys, getId: IdAccessor<T>) => {
    return (onChanged: ((entity: T | undefined) => void) | undefined): void => {
        const context = useAppWindowContext();
        const state = context[key] as (EntityState<T> | undefined);
        const [, instance] = state ?? [];
        const id = getId(instance);
        const prevId = useRef<string | undefined>(undefined);
        useEffect(() => {
            if (prevId.current !== id) {
                prevId.current = id;
                onChanged?.(instance);
            }
        }, [instance, id]);
    };
};

interface WithNewWindowOption {
    readonly newWindow?: boolean;
}

interface RouteContext extends EntityContext<RouteState> {
    readonly setShowOnMap: (showOnMap: boolean) => void;
}

const useRoute = ({ newWindow }: WithNewWindowOption = {}): RouteContext => {
    const [, setState] = useContext(Context);
    const { route, refresh } = useAppWindowContext();
    const { clearRoute } = useAppWindowFeatures();
    const { gotoMap } = useNavigation(newWindow);
    const showRoute = (id: NodeID): void => {
        gotoMap({ route: id, focusMap: newWindow });
    };
    const setShowOnMap = (showOnMap: boolean): void => {
        setState(current => {
            const [routeStatus = "complete", state] = current.route ?? [];
            if (state?.route) {
                return {
                    ...current,
                    route: [routeStatus, { route: state.route, showOnMap }]
                };
            }
            return current;
        });
    };
    return { ...CreateEntityContext<RouteState>(route, showRoute, clearRoute, refresh("route")), setShowOnMap };
};

interface SchemeContext extends EntityContext<IScheme> {
    readonly setBoundary: (boundary: ICoordinate[]) => void;
}

const useScheme = ({ newWindow }: WithNewWindowOption = {}): SchemeContext => {
    const [, setState] = useContext(Context);
    const { scheme, refresh } = useAppWindowContext();
    const { clearScheme } = useAppWindowFeatures();
    const { gotoMap } = useNavigation(newWindow);
    const showScheme = (id: NodeID): void => {
        gotoMap({ scheme: id, focusMap: newWindow });
    };
    const setBoundary = (boundary: ICoordinate[]): void => {
        setState(current => {
            const [schemeStatus = "complete", scheme] = current.scheme ?? [];
            if (scheme) {
                return {
                    ...current,
                    scheme: [schemeStatus, {
                        ...scheme,
                        boundary: {
                            exteriorRing: {
                                coordinates: boundary
                            },
                            interiorRings: []
                        }
                    }]
                };
            }
            return current;
        });
    };
    return { ...CreateEntityContext<IScheme>(scheme, showScheme, clearScheme, refresh("scheme")), setBoundary };
};

type WaterSourceOptions = Pick<AppWindowParams, "focusMap" | "tab" | "defect" | "inspection" | "repair">;
interface WaterSourceContext extends EntityContext<IWaterSource, WaterSourceOptions | undefined> {
    readonly changeCoordinates: (coordinates: [number, number]) => void;
}

const useWaterSource = ({ newWindow }: WithNewWindowOption = {}): WaterSourceContext => {
    const [, setState] = useContext(Context);
    const { clearWaterSource } = useAppWindowFeatures();
    const { waterSource, refresh } = useAppWindowContext();
    const { gotoMap } = useNavigation(newWindow);
    const showWaterSource = (id: NodeID, options?: WaterSourceOptions): void => {
        const { tab, focusMap } = options ?? {};
        gotoMap({
            waterSource: id,
            tab: tab ?? WaterSourceTab.WATER_SOURCE,
            focusMap,
            defect: undefined,
            inspection: undefined,
            repair: undefined
        });
    };
    const changeCoordinates = (coordinates: [number, number]): void => {
        const [x, y] = coordinates;
        setState(current => {
            const [waterSourceStatus = "complete", waterSource] = current.waterSource ?? [];
            if (waterSource) {
                return {
                    ...current,
                    waterSource: [waterSourceStatus, {
                        ...waterSource,
                        location: {
                            ...waterSource.location,
                            coordinates: { x, y }
                        }
                    }]
                };
            }
            return current;
        });
    };
    return { ...CreateEntityContext<IWaterSource, WaterSourceOptions | undefined>(waterSource, showWaterSource, clearWaterSource, refresh("waterSource")), changeCoordinates }
};
const useOnWaterSourceChanged = makeOnChanged<IWaterSource>("waterSource", instance => instance?.waterSourceNodeId);

const useInspection = (): EntityContext<IInspection> => {
    const { clearInspection } = useAppWindowFeatures();
    const { inspection, refresh } = useAppWindowContext();
    const { gotoMap } = useNavigation();
    const changeInspection = (id: NodeID): void => {
        gotoMap({ inspection: id })
    };
    return CreateEntityContext<IInspection>(inspection, changeInspection, clearInspection, refresh("inspection"));
};

const useDefect = (): EntityContext<IDefect> => {
    const { clearDefect } = useAppWindowFeatures();
    const { defect, refresh } = useAppWindowContext();
    const { gotoMap } = useNavigation();
    const changeDefect = (id: NodeID): void => {
        gotoMap({ defect: id });
    }
    return CreateEntityContext<IDefect>(defect, changeDefect, clearDefect, refresh("defect"));
};

const useRepair = (): EntityContext<Repair> => {
    const { clearRepair } = useAppWindowFeatures();
    const { repair, refresh } = useAppWindowContext();
    const { gotoMap } = useNavigation();
    const changeRepair = (id: NodeID): void => {
        gotoMap({ repair: id });
    }
    return CreateEntityContext<Repair>(repair, changeRepair, clearRepair, refresh("repair"));
};

const useFilters = (newWindow?: boolean): [AppWindowFilters, (filters: AppWindowFilters) => void, () => void] => {
    const { clearFilters } = useAppWindowFeatures();
    const { filters } = useAppWindowParams();
    const { gotoMap } = useNavigation(newWindow);
    const setFilters = (filters: AppWindowFilters): void => {
        gotoMap({ filters });
    };
    return [filters ?? {}, setFilters, clearFilters];
};

const useRefresh = (): ((...targets: RefreshTarget[]) => () => Promise<void>) => {
    const { refresh } = useAppWindowContext();
    return refresh;
};

export type { RefreshTarget, RouteState, WaterSourceOptions };
export {
    useAppWindowContext,
    useRoute,
    useScheme,
    useWaterSource,
    useOnWaterSourceChanged,
    useInspection,
    useDefect,
    useRepair,
    useFilters,
    useRefresh
};
export default AppWindowContext;
