import React from "react";
import { IconName, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import cls from "classnames";

import { IQuickSearchItem, IAddress } from "../../../../store/types";
import Typography from "../../../shared/typography";
import { IQuickSearchSelectHandler } from "../../QuickSearch.d";
import styles from "./QuickSearchResultItem.module.scss";

interface IQuickSearchResultItemProps {
    readonly item: IQuickSearchItem;
    readonly isInline?: boolean;
    readonly onSelect: IQuickSearchSelectHandler;
}

const formatTown = (address: IAddress | undefined): string => {
    if (address) {
        const locality = address.locality?.trim();
        const town = address.town?.trim();
        if (locality && town) {
            return `${locality}, ${town}`;
        }
        if (locality) {
            return locality;
        }
        if (town) {
            return town;
        }
    }
    return "-";
};

const formatStreet = (address: IAddress | undefined): string => {
    return address?.streetDescription?.trim() || "-";
};

const QuickSearchResultItem = (props: IQuickSearchResultItemProps): JSX.Element => {
    const handleClick = (): void => {
        props.onSelect(props.item);
    };

    const { item } = props;
    const icon: IconName = item.location.coordinates ? IconNames.MAP_MARKER : IconNames.BLANK;
    const street = formatStreet(item.location.address);
    const town = formatTown(item.location.address);
    return (
        <li className={cls(styles.root, { [styles.inline]: props.isInline })}>
            <button type="button" className={styles.link} onClick={handleClick}>
                <Icon icon={icon} className={styles.icon} iconSize={24} />
                <div className={styles.itemText}>
                    <Typography scale="subtitle2" className={styles.id}>{item.waterSourceId}</Typography>
                    <Typography scale="caption" className={styles.address}>{street}</Typography>
                    <Typography scale="caption" className={styles.address}>{town}</Typography>
                    <Typography scale="caption" className={styles.location}>{item.location.description}</Typography>
                </div>
            </button>
        </li>
    );
};

export type { IQuickSearchResultItemProps, IQuickSearchSelectHandler };
export default QuickSearchResultItem;
