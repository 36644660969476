import { fileManager } from "@3tc/shared-components-ts";

export interface Attachment {
    readonly attachmentId: number | undefined;
    readonly status: fileManager.v2.FileUploadStatus,
    readonly name: string;
    readonly size: number;
    readonly tempId?: string;
    readonly type: string;
    readonly description?: string;
    readonly errorDescription?: string;
    readonly sensitive: boolean;
}

export type ThemedProps<T = unknown> = T & {
    readonly theme?: "light" | "dark"
};

export const isDark = (theme: "light" | "dark" | undefined): boolean => {
    return theme === undefined || theme === "dark";
};

export const toAttachment = (file: fileManager.v2.ITrackedFile): Attachment => ({
    attachmentId: file.metaData.Id,
    name: file.metaData.Name,
    size: file.metaData.Size,
    status: file.status,
    description: file.metaData.Description,
    sensitive: file.metaData.Sensitive > 0 ? true : false,
    type: file.metaData.Type
});
