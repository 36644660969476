import { getEvent, ILifecycleEvent } from "../Model";

export interface Comparer<T> {
    (a: T, b: T): number;
}

export const applyComparers = <T>(comparers: Comparer<T>[], a: T, b: T): number => {
    return comparers.reduce((state, comparer) => {
        return state === 0
            ? comparer(a, b)
            : state;
    }, 0);
};

export const bindComparers = <T>(comparers: Comparer<T>[]) => {
    return (a: T, b: T): number => {
        return applyComparers(comparers, a, b);
    };
}

export const compareTimestamps = (
    a: { events: ILifecycleEvent[] },
    b: { events: ILifecycleEvent[] },
    event: "created" | "closed"): number => {
    const { timestamp: t0 } = getEvent(a.events, event);
    const { timestamp: t1 } = getEvent(b.events, event);
    return t0.compareTo(t1);
};
