import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, DateProps } from "../types";

const RepairDateGuaranteedUntil = ({ show, editing, data, onChange }: PropsWithEditing<PropsWithShow<DateProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "date",
        id: "repair-date-guaranteedUntil",
        label: "Date Guaranteed Until",
        classes: { container: 'input__group', label: 'input__label' },
        value: data.dateGuaranteeExpires,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairDateGuaranteedUntil;