import executeQuery from "../../../lib/executeQuery";
import { IConnection, IConnectionQuery, FilteredObject, IQueryControl } from "../../../store/types";
import { RouteSearchItem, RouteSearchFilters } from "./types";

const gql = `
fragment Route on Route {
    routeNodeId: id
    routeId
    name
    isActive
    station {
        displayText
    }
    waterSources {
        inspections(includeClosed: false) {
            technician {
                displayName
            }
            crew {
                callSign
            }
            inspectionGroup {
                name
            }
        }
    }
}

query getRoutes($filter: QueryFilter, $paging: QueryControlInput) {
    routes(queryFilter: $filter, queryControl: $paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        items {
            ...Route
        }
    }
}`;

export interface RouteQueryResponse {
    readonly routes: IConnection<RouteSearchItem>;
}

export const queryRoutesByFilter = async (filter: FilteredObject<RouteSearchFilters>, paging: IQueryControl): Promise<IConnection<RouteSearchItem>> => {
    const input: IConnectionQuery<FilteredObject<RouteSearchFilters>> = {
        filter,
        paging
    };
    const response = await executeQuery<RouteQueryResponse>(gql, input);
    return response
        ? response.routes
        : Promise.reject(new Error("Missing query response."));
};