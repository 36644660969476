import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import SearchIcon from "@material-ui/icons/Search";

import AppDialogInput from "../../shared/appDialog/AppDialogInput";
import { SearchInputProps } from "./types";

const useStyles = makeStyles(theme => createStyles({
    button: {
        "&:first-child": {
            marginRight: theme.spacing(1)
        },
        "&:last-child": {
            marginLeft: theme.spacing(1)
        }
    },
    primary: {
        flexGrow: 1,
    },
    secondary: {
        justifyContent: "end"
    }
}));

const MobileSearchInput = ({ searchText, placeholder, enabled, onClear, onSearch, onTextChange }: SearchInputProps): JSX.Element => {
    const styles = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onTextChange?.(event.target.value);
    };

    return (
        <Box display="flex" flexDirection="column">
            <AppDialogInput value={searchText} disabled={!enabled} placeholder={placeholder} onChange={handleChange} />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Button
                    variant="contained"
                    color="secondary"
                    className={cx(styles.button, styles.primary)}
                    startIcon={<SearchIcon />}
                    disabled={!enabled}
                    onClick={onSearch}
                >Search</Button>
                <Button 
                    variant="text" 
                    color="primary"
                    className={cx(styles.button, styles.secondary)}
                    classes={{ label: styles.secondary }}
                    disabled={!enabled} 
                    onClick={onClear}
                >Clear</Button>
            </Box>
        </Box>
    );
};

export type { SearchInputProps };
export default MobileSearchInput;