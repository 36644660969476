import { Comparer } from "../../lib/comparers";
import { ILookupItem } from "../../store/types/app";
import { FilterOption } from "./types";

export const buildLookupFilterItems = <T extends ILookupItem = ILookupItem>(items: T[], children?: T[], includeNoneOption?: boolean, comparer?: Comparer<T>): FilterOption[] => {
    const compareItems = (a: T, b: T): number => {
        if (a.enabled && !b.enabled) {
            return -1;
        }
        if (!a.enabled && b.enabled) {
            return 1;
        }
        if (comparer) {
            return comparer(a, b);
        }
        return a.sortOrder - b.sortOrder;
    };

    const sortedItems = items.sort(compareItems);
    const enabledItems = sortedItems.filter(item => item.enabled).map(FilterOption);
    const disabledItems = sortedItems.filter(item => !item.enabled).map(FilterOption);

    if (includeNoneOption) {
        enabledItems.push({ value: '', text: children?.length ? 'Other' : 'None', enabled: true });
    }

    if (children) {
        return enabledItems.map(item => {
            console.log(item, includeNoneOption); //eslint-disable-line
            const filterOptionChildren = children
                .filter(child => child.groupingId === item.value)
                .sort(compareItems)
                .map(FilterOption)
            
            if (includeNoneOption && item.value === '') {
                filterOptionChildren.push({ value: '', text: 'None', enabled: true })
            }

            return {
                ...item,
                children: filterOptionChildren
            }
        });
    }

    return [...enabledItems, ...disabledItems];
};

export const buildYesNoFilterItems = (): FilterOption[] => [
    { text: 'Yes', value: true, enabled: true },
    { text: 'No', value: false, enabled: true }
];

export const formatFilterCurrency = (value: string[], index: number): number | undefined => {
    if (value?.length > index) {
        const n = Number(value[index]);
        return isNaN(n)
            ? undefined
            : n * 100;
    }
    return undefined;
};

export const formatFilterDate = (value: string[], index: number, last?: boolean): string | undefined => {
    return value?.length > index ? value[index]?.concat(last ? "T23:59:59" : "T00:00:00") : undefined;
};

export const formatFilterText = (value: string[], index: number): string | undefined => {
    return value?.length > index ? value[index]?.toString() : undefined;
};

export const formatYesNo = (input: boolean | undefined): string => {
    return input ? "Yes" : "No";
};

export const resolveBoolean = (value: string[] | undefined): boolean | undefined => {
    if (value?.length === 1) {
        const x = value[0]?.trim().toLocaleLowerCase() ?? "";
        if (x) {
            if (x === "true" || x === "yes") {
                return true;
            }
            if (x === "false" || x === "false") {
                return false;
            }
            const n = Number(x);
            if (isNaN(n)) {
                return false
            }
            return n > 0;
        }
    }
    return undefined;
};

export const resolveRange = <T>(value: string[] | undefined, format: (value: string[], index: number, last?: boolean) => T): Record<string, unknown> => {
    if (value === undefined) {
        return {};
    }
    const first = format(value, 0);
    const last = format(value, 1, true);
    const range: Record<string, unknown> = {};
    if (first !== undefined) {
        range.$first = first;
    }
    if (last !== undefined) {
        range.$last = last;
    }
    return {
        $between: range
    };
};

export const resolveMatchString = (value: string[] | undefined): string | undefined => {
    return value?.length === 1 ? `%${value[0]}%` : undefined
};
