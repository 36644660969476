import React, { useState } from "react";
import { fileManager } from "@3tc/shared-components-ts";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PublishIcon from '@material-ui/icons/Publish';

import AttachmentItem from "../../../../../views/inspectionView/components/AttachmentItem";
import { IAccess, EntityName } from "../../../../../store/types";
import AppDialog from "../../../appDialog";
import Optional from "../../../Optional";
import { IFileList } from "../../fileManager.d";
import { toFileList, uploadFiles } from "./fileUploader.utils";

interface FileUploaderProps {
    readonly fileStoreToken: string;
    readonly entity: EntityName;
    readonly entityId: number;
    readonly accessRoles: IAccess[];
    readonly className?: string;
    readonly disabled?: boolean;
    readonly onClose?: () => void;
    readonly onUploadComplete?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    label: {
        alignItems: "center",
        display: "flex",
    },
    input: {
        height: "0.1px",
        width: "0.1px",
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1
    },
    list: {
        height: "100%",
        overflowY: "auto",
        position: "unset",
        flexGrow: 1
    }
}));

const FileUploader = (props: FileUploaderProps): JSX.Element => {
    const styles = useStyles();
    const [selectedFiles, setSelectedFiles] = useState<IFileList>({});
    const [showDialog, setShowDialog] = useState(false);

    const closeDialog = (): void => {
        setShowDialog(false);
        setSelectedFiles({});
        props.onClose?.();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const files = toFileList(event.target.files, props.entity, props.entityId);
        setSelectedFiles(files);
        setShowDialog(true);
        event.target.value = "";
    };

    const handleDescriptionChange = (tempId: string | undefined, description: string): void => {
        console.log(tempId, description);
        if (tempId) {
            const file = selectedFiles[tempId];
            const update: fileManager.v2.ITrackedFile = {
                ...file,
                metaData: {
                    ...file.metaData,
                    Description: description
                }
            }
            setSelectedFiles({
                ...selectedFiles,
                [tempId]: update
            });
        }
    }

    const handleCommit = async (): Promise<void> => {
        await uploadFiles(props.fileStoreToken, selectedFiles);
        closeDialog();
        props.onUploadComplete?.();
    };

    const commit = (
        <IconButton edge="end" onClick={handleCommit}>
            <PublishIcon />
        </IconButton>
    );

    return (
        <Optional hidden={props.disabled}>
            <ListItem button>
                <InputLabel htmlFor="fileInput" disabled={props.disabled} classes={{ root: styles.label }}>
                    <ListItemIcon>
                        <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload files" primaryTypographyProps={{ color: "textPrimary" }} />
                </InputLabel>
                <input type="file" id="fileInput" name="fileInput" className={styles.input} multiple disabled={props.disabled} onChange={handleFileChange} />
            </ListItem>
            <AppDialog appBar={{ title: "Selected Files" }} disableGutters open={showDialog} commit={commit} onClose={closeDialog}>
                <List className={styles.list} disablePadding>
                    {Object.entries(selectedFiles)
                        .map(([key, file]) =>
                            <AttachmentItem key={key}
                                editing
                                theme="light"
                                attachment={{
                                    attachmentId: undefined,
                                    tempId: file.metaData.tempId,
                                    name: file.metaData.Name,
                                    size: file.metaData.Size,
                                    status: file.status,
                                    description: file.metaData.Description,
                                    sensitive: file.metaData.Sensitive > 0 ? true : false,
                                    type: file.metaData.Type
                                }} onDescriptionChange={handleDescriptionChange}
                            />
                        )}
                </List>
            </AppDialog>
        </Optional>
    );
};

export type { FileUploaderProps };
export default FileUploader;