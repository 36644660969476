import React from "react";
import { useDispatch } from "react-redux";

import { AddressDetailsData } from "../types";
import AddAddressBase, { AddAddressProps } from "./AddAddress.view";
import { startCreateAddress } from "../../../api/createAddress";

const AddAddress = ({ onConfirm, ...rest }: AddAddressProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleCreate = async (data: AddressDetailsData): Promise<void> => {
        const action = startCreateAddress(data);
        const address = await action(dispatch);
        if (address) {
            onConfirm?.(address);
        }
    };

    return <AddAddressBase onConfirm={handleCreate} {...rest} />;
};

export default AddAddress;