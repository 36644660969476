import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

interface AppDialogText {
    readonly text: string;
    readonly label?: string;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(1.5)
    },
    input: {

    },
    label: {
        marginBottom: theme.spacing(0.5)
    }
}));

const AppDialogText = ({ label, text }: AppDialogText): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            {label && <Typography className={styles.label} color="textSecondary" variant="caption">{label}</Typography>}
            <Typography className={styles.input} variant="body2">{text}</Typography>
        </div>
    )
};

export type { AppDialogText };
export default AppDialogText;