import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SecurityIcon from '@material-ui/icons/Security';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import { AccessControl } from "../../../../auth/components";
import { useVersion } from "../../../../auth/hooks";
import { RoleNames } from "../../../../auth/roles";
import { useNavigation } from "../../../../router/MobileRouter/hooks";
import { AppDialogText } from "../../appDialog";
import ChangePassword from "../../changePassword";

const useStyles = makeStyles(theme => createStyles({
    softwareVersion: {
        flexGrow: 1,
        marginTop: theme.spacing(2)
    }
}));

interface AdminMenuProps {
    readonly className?: string;
    readonly divider?: "top" | "bottom";
    readonly onOpenDialog?: (title: string, children?: React.ReactNode) => void;
}

const SoftwareVersion = (): JSX.Element => {
    const version = useVersion();
    const styles = useStyles();
    return (
        <div className={styles.softwareVersion}>
            <AppDialogText label="UI Software Version" text={version.uiVersion ?? "Unknown"} />
            <AppDialogText label="API Software Version" text={version.apiVersion ?? "Unknown"} />
        </div>
    );
};

const AdminMenu = (props: AdminMenuProps): JSX.Element => {
    const { gotoAdminRoles, gotoAdminUsers, signOut } = useNavigation();

    const openAccountDialog = (): void => props.onOpenDialog?.("Account", <ChangePassword />);
    const openSystemDialog = (): void => props.onOpenDialog?.("System", <SoftwareVersion />);

    return (
        <div className={props.className}>
            {props.divider === "top" && <Divider />}
            <List>
                <AccessControl role={RoleNames.ADMIN_USERS}>
                    <ListItem button onClick={gotoAdminUsers}>
                        <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                </AccessControl>
                <AccessControl role={RoleNames.ADMIN_ROLES}>
                    <ListItem button onClick={gotoAdminRoles}>
                        <ListItemIcon><SecurityIcon /></ListItemIcon>
                        <ListItemText primary="Roles &amp; Permissions" />
                    </ListItem>
                </AccessControl>
                <AccessControl any={[RoleNames.ADMIN_USERS, RoleNames.ADMIN_ROLES]}>
                    <Divider />
                </AccessControl>
                <ListItem button onClick={openSystemDialog}>
                    <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                    <ListItemText primary="System" />
                </ListItem>
                <Divider />
                <ListItem button onClick={openAccountDialog}>
                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                    <ListItemText primary="Account" />
                </ListItem>
                <ListItem button onClick={signOut}>
                    <ListItemText primary="Sign Out" inset />
                </ListItem>
            </List>
            {props.divider === "bottom" && <Divider />}
        </div>
    );
};

export type { AdminMenuProps };
export default AdminMenu;