import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';

import { AccessControl } from "../../../auth/components";
import { RoleNames } from "../../../auth/roles";
import { CloseDefect } from "../../../components/inspectionView/components";
import Optional from "../../../components/shared/Optional";
import { NodeID } from "../../../lib/nodeIdentifier";
import { IDefect, ILookupItem } from "../../../store/types";
import { longDateFormatter } from "../../../lib/Utils";
import NotesDetails from "./NotesDetails";

interface DefectItemProps {
    readonly defect: Pick<IDefect, "defectNodeId" | "defectId" | "type" | "dateReported" | "reportedBy" | "notes">;
    readonly disabled?: boolean;
    readonly onClose?: (defectId: NodeID, closeReason: ILookupItem) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        borderBottom: `1px solid ${theme.palette.primary.dark}`
    },
    primary: {
        alignItems: "baseline",
        display: "flex",
        flexDirection: "row",
    },
    id: {
        display: "inline",
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1),
    },
    type: {
        color: theme.palette.grey[200],
    },
    secondary: {
        color: theme.palette.grey[400]
    },
    infoContainer: {
        display: "flex",
        alignItems: "flex-end"
    },
    noteIcon: {
        margin: "0 0 10px 10px"
    }
}));

const DefectItem = (props: DefectItemProps): JSX.Element => {
    const styles = useStyles();
    const { defect, disabled, onClose } = props;
    const { defectId, type, dateReported, reportedBy, notes } = defect;
    const areNotesAvailable = Boolean(notes.length);
    const [showNoteDetails, setShowNoteDetails] = useState(false);

    const openNotesDetails = (): void => setShowNoteDetails(true);

    const closeNotesDetails = (): void => setShowNoteDetails(false);

    return (
        <ListItem button divider className={styles.root}>
            <div className={styles.infoContainer}>
                <ListItemText
                    primary={
                        <div className={styles.primary}>
                            <Typography variant="body2" className={styles.id}>{defectId}</Typography>
                            <Typography variant="caption" className={styles.type}>{type.displayText}</Typography>
                        </div>
                    }
                    secondary={
                        <Typography variant="caption" className={styles.secondary}>
                            {`${dateReported.format(longDateFormatter)}, ${reportedBy}`}
                        </Typography>
                    }
                />
                {areNotesAvailable && <FontAwesomeIcon className={styles.noteIcon} icon={faStickyNote} onClick={openNotesDetails} />}
                <NotesDetails objectType="DEFECT" showDialogue={showNoteDetails} closeNotesDetails={closeNotesDetails} notes={notes} />
            </div>
            <Optional hidden={disabled}>
                <ListItemSecondaryAction>
                    <AccessControl role={RoleNames.DEFECTS_CLOSE}>
                        <CloseDefect defect={defect} onCommit={onClose} />
                    </AccessControl>
                </ListItemSecondaryAction>
            </Optional>
        </ListItem>
    );
};

export type { DefectItemProps };
export default DefectItem;