export default `mutation createInspections_Mutation($input: AddCreateInspectionsInput!) {
    waterSource {
        createInspections(input:$input) {
            clientMutationId
            inspections {
                inspectionNodeId: id
                inspectionId
                waterSource {
                    waterSourceNodeId: id
                }
                type {
                    displayText
                    value
                }
                inspectionDate
                description
                isOpen
                isCancelled
                isCompleted
                isAssignedToGroup
                isAssignedToInspector
                technician {
                    technicianNodeId: id
                    technicianId
                    displayName
                    userName
                }
                crew {
                    crewNodeId: id
                    crewId
                    callSign
                }
                inspectionGroup {
                    inspectionGroupNodeId: id
                    inspectionGroupId
                    name
                }
                result {
                    displayText
                    value
                }
                cancelReason {
                    displayText
                    value
                }
                events {
                    timestamp
                    action
                    actor
                }
                lastEvent {
                    timestamp
                    action
                    actor
                }
                notes {
                    noteNodeId: id
                    noteId
                    timestamp
                    author
                    content
                }
            }
            hasErrors
            errors {
                message
                path
            }
        }
    }
}`;