import { isNumber } from "lodash";

import { ILookupItem, LookupItem } from "../../../../store/types";
import { SelectOption } from "./types";

export const asLookupItem = (source: SelectOption | undefined): ILookupItem | undefined => {
    if (source) {
        const { value, ...other } = source;
        if (isNumber(value)) {
            const { displayText, enabled, sortOrder, filters, groupingId, symbolName } = other;
            return LookupItem(value, displayText, sortOrder, {
                enabled,
                filters: filters ?? [],
                groupingId: isNumber(groupingId) ? groupingId : undefined,
                symbolName
            });
        }
    }
    return undefined;
};

export const asOption = (source: ILookupItem): SelectOption => {
    return source;
};

export const compareSelectOptions = (a: SelectOption, b: SelectOption): number => {
    return a.sortOrder - b.sortOrder;
};