import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    subheader: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.grey[200],
    },
}));

export default useStyles;
