import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";

type ActionButtonProps = Omit<ButtonProps, "variant">;

const useStyles = makeStyles(theme => createStyles({
    root: {
        borderColor: theme.palette.grey[500],
        borderStyle: "solid",
        borderWidth: "3px",
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        "&:active": {
            borderColor: theme.palette.secondary.main
        },
        "&:focus": {
            borderColor: theme.palette.secondary.main,
            borderStyle: "dashed"
        }
    },
    label: {
        fontWeight: "bold",
        textTransform: "capitalize"
    }
}));

const ActionButton = (props: ActionButtonProps): JSX.Element => {
    const { children, className, ...rest } = props;
    const styles = useStyles();
    return (
        <Button className={cx(styles.root, className)} variant="outlined" classes={{ label: styles.label }} {...rest} >
            {children}
        </Button>
    );
};

export type { ActionButtonProps };
export default ActionButton;