export default `mutation changeWaterSourceActivation_Mutation($waterSource: EditActivationState!) {
    changeWaterSourceActivation(input:$waterSource) {
        clientMutationId
        hasErrors
        errors {
            message
            path
        }
        waterSource {
            waterSourceId: externalId
        }
    }
}`;