import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";

import Dialog from "../dialog/dialog";
import DialogTitle from "../dialog/dialogTitle";
import DialogContent from "../dialog/dialogContent";
import DialogActions from "../dialog/dialogActions";
import AddressDetails from "./AddressDetails";
import { AddressDetailsKeys, AddressDetailsTypes, AddressDetailsData } from "./types";


interface RenderDialogAction {
    (enabled: boolean, getAddress?: () => AddressDetailsData): JSX.Element;
}

interface AddressDetailsProps {
    readonly title: string;
    readonly open: boolean;
    readonly initialAddress: AddressDetailsData;
    readonly renderAction?: RenderDialogAction;
    readonly onCancel?: () => void;
}

const validateAddress = (address: AddressDetailsData): boolean => {
    const { streetDescription, locality, town, postCode } = address;
    return Boolean(streetDescription)
        || Boolean(locality)
        || Boolean(town)
        || Boolean(postCode);
};

const AddressDetailsDialog = ({ title, open, initialAddress, renderAction, onCancel }: AddressDetailsProps): JSX.Element => {
    const [address, setAddress] = useState<AddressDetailsData>(initialAddress ?? {});
    const valid = useMemo(() => validateAddress(address), [address]);

    const handleCloseDialog = (): void => {
        onCancel?.();
        setAddress(initialAddress);
    };
    const handleChange = (key: AddressDetailsKeys, value: AddressDetailsTypes): void => {
        setAddress(current => ({
            ...current,
            [key]: value
        }));
    };

    // on open, reset address to initialAddress
    const openRef = useRef(open);
    useEffect(() => {
        if (!openRef.current && open) {
            setAddress(initialAddress);
        }
        openRef.current = open;
    }, [open]);

    const getAddress = useCallback(() => address, [address]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCloseDialog}>
            <DialogTitle onClose={handleCloseDialog}>
                {title}
            </DialogTitle>
            <DialogContent>
                <AddressDetails address={address} editing onChange={handleChange} />
            </DialogContent>
            <DialogActions>
                {renderAction?.(valid, getAddress)}
                <button id="addressSearch-cancel-button" className="action-button action-button--cancel" onClick={handleCloseDialog}>Cancel</button>
            </DialogActions>
        </Dialog>
    );
};

export type { AddressDetailsProps };
export default AddressDetailsDialog;