import React from "react";
import Dialog from "../../../components/dialog/dialog";
import DialogActions from "../../../components/dialog/dialogActions";
import DialogContent from "../../../components/dialog/dialogContent";
import DialogTitle from "../../../components/dialog/dialogTitle";

export interface IAreYouSureModalProps {
    readonly isOpen: boolean;
    readonly handleClose: (navigateAway: boolean) => void;
}

const AssignInspectorsDialog = (props: IAreYouSureModalProps): JSX.Element => {
    return (
        <Dialog fullWidth maxWidth="sm" open={props.isOpen} onClose={(): void => props.handleClose(false)}>
            <DialogTitle>You have unsaved changes.</DialogTitle>
            <DialogContent>Are you sure you want to leave this page without saving?</DialogContent>
            <DialogActions>
                <button id="archiveLookup-yes" className="action-button" onClick={(): void => props.handleClose(true)}>Yes</button>
                <button id="archiveLookup-no" className="action-button action-button--cancel" onClick={(): void => props.handleClose(false)}>No</button>
            </DialogActions>
        </Dialog>
    );
}

export default AssignInspectorsDialog;