import { LookupItem, ILookupItem, IWaterSourceAction, IWaterSourceState, IMapWaterSource } from '../types';
import { parseNodeId, equals } from '../../lib/nodeIdentifier';


const defaultAction: IWaterSourceAction = {
    type: '@@INIT',
    waterSources: [],
    waterSourcesPageInfo: undefined,
    requestId: undefined
};

const defaultState: IWaterSourceState = {
    waterSources: [],
    waterSourcesPageInfo: undefined,
    selectedWaterSource: undefined,
    editWaterSourceSuccess: undefined,
    editingSelectedWaterSource: false,
    requestId: undefined,
    isEditingWaterSourceAborted: false
};

export const waterSourceToMapWaterSource = (inputWaterSource: IMapWaterSource, tags?: ILookupItem[]): IMapWaterSource => {
    return {
        ...inputWaterSource,
        status: inputWaterSource.status ?? LookupItem(0, "UNKNOWN", 0),
        tags: tags ?? []
    };
};

export default (state: IWaterSourceState = defaultState, action: IWaterSourceAction = defaultAction): IWaterSourceState => {
    switch (action.type) {
        case 'SET_WATER_SOURCES':
            if (action.requestId === state.requestId) {
                return {
                    ...state,
                    waterSources: action.waterSources,
                    waterSourcesPageInfo: action.waterSourcesPageInfo,
                    waterSourcesTotalCount: action.waterSourcesTotalCount
                };
            }

            return state;

        case 'ADD_WATER_SOURCE':
            if (action.newWaterSource && state.waterSources) {
                const updatedWaterSources = [
                    ...state.waterSources,
                    waterSourceToMapWaterSource(action.newWaterSource)
                ];

                return {
                    ...state,
                    waterSources: updatedWaterSources
                }
            }

            return state;

        case 'EDIT_WATER_SOURCE':
            if (action.editedWaterSource && state.waterSources) {
                const existingWaterSourceNodeId = action.editedWaterSource.waterSourceNodeId
                const updatedWaterSources: IMapWaterSource[] = state.waterSources.map((waterSource) => {
                    if (waterSource.waterSourceNodeId === existingWaterSourceNodeId && action.editedWaterSource) {
                        return waterSourceToMapWaterSource(action.editedWaterSource, action.tags);
                    }
                    return waterSource;
                });

                return {
                    ...state,
                    waterSources: updatedWaterSources
                };
            }

            return state;

        case 'SET_SELECTED_WATER_SOURCE':
            return {
                ...state,
                selectedWaterSource: action.selectedWaterSource
            }

        case 'SET_EDIT_WATER_SOURCE_SUCCESS':
            return {
                ...state,
                editWaterSourceSuccess: action.editWaterSourceSuccess
            }

        case 'ADD_WATER_SOURCE_HAZARDS':
            return {
                ...state,
                selectedWaterSource: Object.assign({}, state.selectedWaterSource, { hazards: action.waterSourceHazards })
            }

        case 'UPDATE_WATER_SOURCE_HAZARDS':
            return {
                ...state,
                selectedWaterSource: Object.assign({}, state.selectedWaterSource, { hazards: action.waterSourceHazards })
            }

        case 'REMOVE_WATER_SOURCE_HAZARDS':
            return {
                ...state,
                selectedWaterSource: Object.assign({}, state.selectedWaterSource, { hazards: action.waterSourceHazards })
            }

        case 'ADD_WATER_SOURCE_TAGS':
            return {
                ...state,
                selectedWaterSource: Object.assign({}, state.selectedWaterSource, { tags: action.waterSourceTags })
            }

        case 'REMOVE_WATER_SOURCE_TAGS':
            return {
                ...state,
                selectedWaterSource: Object.assign({}, state.selectedWaterSource, { tags: action.waterSourceTags })
            }


        case 'SET_SELECTED_WATER_SOURCE_LAST_INSPECTION_DATE':
            if (state.selectedWaterSource && action.lastInspectionDate) {
                return {
                    ...state,
                    selectedWaterSource: {
                        ...state.selectedWaterSource,
                        lastInspectionDate: action.lastInspectionDate
                    }
                }
            }

            return state

        case 'SET_EDIT_WATER_SOURCE':
            return {
                ...state,
                editingSelectedWaterSource: action.editWaterSource ?? false
            }

        case 'SET_WATER_SOURCE_REQUEST_ID':
            return {
                ...state,
                requestId: action.requestId
            }

        case 'SET_IS_EDITING_WATER_SOURCE_ABORTED':
            return {
                ...state,
                isEditingWaterSourceAborted: action.isEditingWaterSourceAborted
            }

        case "UPDATE_WATER_SOURCE":
            if (action.updatedWaterSource && state.waterSources) {
                const updatedWaterSources = [
                    ...state.waterSources,
                    waterSourceToMapWaterSource({ ...action.updatedWaterSource, show: true })
                ];

                return {
                    ...state,
                    waterSources: updatedWaterSources
                }
            }

            return state;
        case "UPDATE_WATER_SOURCE_OPERABILITY":
            if (action.updatedOperability) {
                return {
                    ...state,
                    waterSources: state.waterSources?.map(ws => {
                        if (ws.waterSourceNodeId === action.updatedOperability?.waterSourceNodeId) {
                            return {
                                ...ws,
                                isOperable: action.updatedOperability.isOperable,
                                isDefective: action.updatedOperability.isDefective
                            }
                        }

                        return ws;
                    })
                }
            }
            return state;
        default:
            return state;
    }
}
