import React from "react";

import MuiInputLabel from "@material-ui/core/InputLabel";
import { makeStyles, createStyles } from "@material-ui/core/styles";

interface InputLabelProps {
    readonly htmlFor?: string;
    readonly required?: boolean
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.grey[400],
        position: "unset",
        top: "unset",
        bottom: "unset",
        "&.Mui-focused": {
            color: theme.palette.grey[400]
        },
        marginBottom: theme.spacing(2),
        "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
            transform: "translate(0, 10px) scale(0.75)",
        }
    }
}));

const InputLabel = (props: React.PropsWithChildren<InputLabelProps>): JSX.Element => {
    const styles = useStyles();
    const { children, htmlFor, required } = props;
    const labelId = `${htmlFor}-label`;
    return (
        <MuiInputLabel id={labelId} htmlFor={htmlFor} classes={{ formControl: styles.root }} variant="filled" shrink required={required}>
            {children}
        </MuiInputLabel>
    );
};

export type { InputLabelProps };
export default InputLabel;