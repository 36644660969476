import React from "react";
import { LocalDate } from "@js-joda/core";

import Typography from "@material-ui/core/Typography";

import { longDateFormatter } from "../../../../../lib/Utils";
import useStyles from "../InspectionItem.styles";

interface InspectionDateProps {
    readonly inspectionDate: LocalDate | undefined;
}

const InspectionDate = (props: InspectionDateProps): JSX.Element => {
    const styles = useStyles();
    const { inspectionDate } = props;
    return <Typography variant="caption" className={styles.meta}>{inspectionDate?.format(longDateFormatter)}</Typography>;
};

export type { InspectionDateProps };
export default React.memo(InspectionDate, (prev, next): boolean => {
    const { inspectionDate: prevDate } = prev;
    const { inspectionDate: nextDate } = next;
    if (prevDate === undefined && nextDate === undefined) {
        return true;
    }
    if (prevDate && nextDate) {
        return prevDate.equals(nextDate);
    }
    return false;
});