import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";

interface CollapsibleBoxProps {
    readonly children?: React.ReactNode;
}

const useStyles = makeStyles(theme => createStyles({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    action: {
        color: theme.palette.secondary.main,
        cursor: "pointer",
        fontSize: "0.65rem",
        marginLeft: "auto",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));

const CollapsibleBox = (props: CollapsibleBoxProps): JSX.Element => {
    const styles = useStyles();
    const [expanded, setExpanded] = useState(false);
    const handleClick = (): void => {
        setExpanded(current => !current);
    };
    return (
        <div className={styles.container}>
            <Collapse in={expanded}>
                <div className={styles.container}>
                    {props.children}
                </div>
            </Collapse>
            <span className={styles.action} onClick={handleClick}>{expanded ? "less" : "more"}</span>
        </div>
    );
};

export type { CollapsibleBoxProps };
export default CollapsibleBox;