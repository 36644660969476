import React from "react";
import { LocalDate } from "@js-joda/core";

import useSelector from "../../../../lib/hooks/useSelector";
import { ILookupItem, getInspectorDisplayName } from "../../../../store/types";
import AddDefectBase, { AddDefectProps } from "./AddDefect.view";

type ConnectedProps = Pick<AddDefectProps, "waterSourceId" | "disabled" | "onClose" | "onCommit">;

const AddDefect = (props: ConnectedProps): JSX.Element => {
    const defectTypes = useSelector<ILookupItem[]>(state => state.app.lookups?.defectTypes ?? []);
    const defaultReportDate = LocalDate.now();
    const defaultReporter = useSelector(state => state.app.user?.profile ? getInspectorDisplayName(state.app.user.profile) : undefined);
    const isMobile = useSelector(state => state.device.isMobile);

    const connectedProps: AddDefectProps = {
        ...props,
        defectTypes,
        defaultReportDate,
        defaultReporter: defaultReporter,
        isMobile: isMobile
    };
    return <AddDefectBase {...connectedProps} />;
};

export default AddDefect;