import React from "react";
import { useDispatch } from 'react-redux';
import { LocalDate } from '@js-joda/core';

import useSelector from "../../../../lib/hooks/useSelector";
import { NodeID, encodeNodeId } from "../../../../lib/nodeIdentifier";
import { guid } from '../../../../lib/Utils';
import { setGlobalToast } from '../../../../store/actions/app';
import {
    startCancelInspection,
    startCompleteInspection,
    startEditInspection,
    startRemoveInspectionGroup,
    startRemoveInspector,
    startAssignInspector,
    startAssignInspectionGroup,
} from '../../../../store/actions/inspections';
import { IAssignInspectorInput, IAssignInspectionGroupInput } from "../../../../store/types";
import { SelectOption } from '../../../../components/labelledField';
import InspectionControlBase, { IInspectionControlProps } from './inspectionControl';

const InspectionControl = (props: Pick<IInspectionControlProps, "selectedInspection" | "hideNavButton" | "onClose"> & { readonly onRefresh?: () => void; }): JSX.Element => {
    const dispatch = useDispatch();
    const inspectionCancelReasons = useSelector(state => state.app.lookups?.inspectionCancelReasons ?? []);
    const inspectionResults = useSelector(state => state.app.lookups?.inspectionResults ?? []);
    const inspectionTests = useSelector(state => state.app.lookups?.inspectionTests ?? []);
    const inspectionTypes = useSelector(state => state.app.lookups?.inspectionTypes ?? []);
    const fileStoreToken = useSelector(state => state.fileStore.token);

    const handleRefresh = (): void => {
        props.onRefresh?.();
    };

    const combined: IInspectionControlProps = {
        ...props,
        inspectionCancelReasons,
        inspectionResults,
        inspectionTests,
        inspectionTypes,
        fileStoreToken,
        onAssignInspectionGroup: async (inspectionId, groupId): Promise<void> => {
            const input: IAssignInspectionGroupInput = {
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(inspectionId),
                    data: encodeNodeId(groupId)
                }
            };
            const action = startAssignInspectionGroup(input);
            await action(dispatch);
            handleRefresh();
        },
        onAssignInspector: async (inspectionNodeId: NodeID, inspectorNodeId: NodeID): Promise<void> => {
            const input: IAssignInspectorInput = {
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(inspectionNodeId),
                    data: encodeNodeId(inspectorNodeId)
                }
            };
            const action = startAssignInspector(input);
            await action(dispatch);
            handleRefresh();
        },
        onCancelInspection: async (inspectionNodeId: string, cancelReason: SelectOption): Promise<void> => {
            const action = startCancelInspection({
                inspection: {
                    clientMutationId: guid(),
                    nodeId: inspectionNodeId,
                    data: {
                        cancelReasonId: Number(cancelReason.value)
                    }
                }
            });
            await action(dispatch);
            handleRefresh();
        },
        onClearError: (): void => {
            dispatch(setGlobalToast());
        },
        onCompleteInspection: async (inspectionNodeId: string, test: SelectOption | undefined, result: SelectOption, resolvedDefects: string[], unresolvedDefects: string[]): Promise<void> => {
            const action = startCompleteInspection({
                inspection: {
                    clientMutationId: guid(),
                    nodeId: inspectionNodeId,
                    data: {
                        testId: test ? Number(test.value) : null,
                        resultId: Number(result.value),
                        resolvedDefects,
                        unresolvedDefects,
                        internalRepairs: [],
                        attachments: []
                    }
                }
            });
            await action(dispatch);
            handleRefresh();
        },
        onRemoveInspectionGroup: async (inspectionNodeId: string): Promise<void> => {
            const action = startRemoveInspectionGroup({
                input: {
                    clientMutationId: guid(),
                    nodeId: inspectionNodeId
                }
            });
            await action(dispatch);
            handleRefresh();
        },
        onRemoveInspector: async (inspectionNodeId: string): Promise<void> => {
            const action = startRemoveInspector({
                input: {
                    clientMutationId: guid(),
                    nodeId: inspectionNodeId
                }
            });
            await action(dispatch);
            handleRefresh();
        },
        onShowError: (message): void => {
            dispatch(setGlobalToast({
                type: 'ERROR',
                message: message,
                showToast: true
            }));
        },
        onUpdateInspection: async (inspectionNodeId: string, test: SelectOption | undefined, type: SelectOption, inspectionDate: LocalDate, description: string | undefined): Promise<void> => {
            const action = startEditInspection({
                inspection: {
                    clientMutationId: guid(),
                    nodeId: inspectionNodeId,
                    data: {
                        testId: test ? Number(test.value) : null,
                        typeId: Number(type.value),
                        inspectionDate: inspectionDate,
                        description: description
                    }
                }
            });
            await action(dispatch);
            handleRefresh();
        }
    };
    return <InspectionControlBase {...combined} />;
};

export default InspectionControl;
