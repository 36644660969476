export default `mutation archiveWaterPipeMutation($input: EmptyEditInput!) {
    gis {
      waterPipe {
        archive(input: $input) {
          hasErrors
          errors {
            message
            path
          }
          clientMutationId
          waterPipe {
            waterPipeNodeId: id
            waterPipeId
            isActive
            scheme {
              schemeId
            }
            path {
              coordinates {
                x
                y
              }
            }
          }
        }
      }
    }
  }`