import React, { useEffect, useRef } from "react";

import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { IWaterSource } from "../../store/types";
import DefectControlPanel from "../defect/defectPanel";
import Drawer from "../drawer/Drawer";
import InspectionControlPanel from "../inspection/InspectionPanel";
import RepairControlPanel from "../repairs/repairPanel";
import Tab from "../tabs/Tab";
import TabPanel from "../tabs/TabPanel";
import TabPanelContainer from "../tabs/TabPanelContainer";
import WaterSourceControl from "../waterSource/waterSourceControl";
import { useTabs, WaterSourceTab } from "./DataTabsContext";
import DrawerToolbar from "../drawer/DrawerToolbar";
import LabelledField from "../labelledField";

import styles from "./dataTabs.module.scss";
import { IWaterSourceSummary } from "../inspectionView";

interface IDataTabsProps {
    readonly open: boolean;
    readonly selectedWaterSource: IWaterSource;
    readonly editingSelectedWaterSource: boolean;
    readonly getDefectsForWaterSource: (waterSourceNodeId, setLoading) => void;
    readonly getInspectionsForWaterSource: (waterSourceNodeId, setLoading) => void;
    readonly getRepairsForWaterSource: (waterSourceNodeId, setLoading) => void;
    readonly onCloseWaterSource?: () => void;
    readonly onOpenWaterSource?: () => void;
    readonly onRefreshWaterSource?: (waterSource?: IWaterSourceSummary) => void;
    readonly changeWaterSourceCoordinates: (coordinates: [number, number]) => void;
    readonly setEditingWaterSourceAborted: (isEditingWaterSourceAborted: boolean) => void;
}

const DataTabs = (props: IDataTabsProps): JSX.Element => {
    const { selectedWaterSource } = props;

    const [tab, changeTab] = useTabs();

    const { waterSourceNodeId } = selectedWaterSource ?? {};
    const prevTab = useRef(tab);
    useEffect(() => {
        if (waterSourceNodeId && prevTab.current !== tab) {
            const variables = { id: waterSourceNodeId };
            switch (tab) {
                case WaterSourceTab.INSPECTIONS:
                    props.getInspectionsForWaterSource(variables, true);
                    return;
                case WaterSourceTab.DEFECTS:
                    props.getDefectsForWaterSource(variables, true);
                    return;
                case WaterSourceTab.REPAIRS:
                    props.getRepairsForWaterSource(variables, true);
                    return;
                default:
                    return;
            }
        }
        prevTab.current = tab;
    }, [waterSourceNodeId, tab])

    const handleChangeTab = (_: React.ChangeEvent<unknown>, value: WaterSourceTab): void => {
        changeTab(value);
    };

    const handleCloseClick = (): void => {
        if (props.editingSelectedWaterSource) {
            props.setEditingWaterSourceAborted(true);
        }
        props.onCloseWaterSource?.();
    };

    const handleTitleClick = (): void => {
        props.onOpenWaterSource?.();
    };

    const handleRefreshWaterSource = (waterSource?: IWaterSourceSummary): void => {
        props.onRefreshWaterSource?.(waterSource);
    };

    const { waterSourceId } = selectedWaterSource;
    const title = (
        <Box display="flex" flexDirection="row" alignItems="center" className={styles.title}>
            <LabelledField
                fieldType="link"
                id="waterSource-id"
                label=""
                linkText={waterSourceId}
                preventDefault
                classes={{ container: styles.idContainer }}
                onClick={handleTitleClick}
            />
            <Box marginLeft={0.5}>
                <OpenInNewIcon style={{ fontSize: "1rem" }} />
            </Box>
        </Box>
    );

    return (
        <Drawer isOpen={props.open} anchor="right">
            <DrawerToolbar title={title} onClose={handleCloseClick} />
            <Tabs variant="fullWidth" scrollButtons="auto" value={tab} onChange={handleChangeTab}>
                <Tab label="Water Source" id="WATER_SOURCE" />
                <Tab label="Inspections" id="INSPECTIONS" />
                <Tab label="Defects" id="DEFECTS" />
                <Tab label="Repairs" id="REPAIRS" />
            </Tabs>
            <TabPanelContainer>
                <TabPanel value={tab} index={WaterSourceTab.WATER_SOURCE}>
                    <WaterSourceControl selectedWaterSource={selectedWaterSource} onRefresh={handleRefreshWaterSource} changeWaterSourceCoordinates={props.changeWaterSourceCoordinates} />
                </TabPanel>
                <TabPanel value={tab} index={WaterSourceTab.INSPECTIONS} disableGutters>
                    <InspectionControlPanel waterSource={selectedWaterSource} onRefresh={handleRefreshWaterSource} />
                </TabPanel>
                <TabPanel value={tab} index={WaterSourceTab.DEFECTS}>
                    <DefectControlPanel waterSource={selectedWaterSource} />
                </TabPanel>
                <TabPanel value={tab} index={WaterSourceTab.REPAIRS}>
                    <RepairControlPanel waterSource={selectedWaterSource} />
                </TabPanel>
            </TabPanelContainer>
        </Drawer>
    );
};

export type { IDataTabsProps };
export default DataTabs;