import React from "react";

import Tags from "../../../tags";
import { WaterSourceEditor } from "../types";
import { parseNodeId } from "../../../../lib/nodeIdentifier";

interface TagsProps {
    readonly onChange?: () => void;
}

const WaterSourceTags = ({ editing, waterSource, onChange }: WaterSourceEditor<TagsProps>): JSX.Element => (
    <Tags
        id="waterSource-tags"
        label="Tags"
        tags={waterSource.tags ?? []}
        editing={editing}
        classes={{ container: "input__group input__group_side-panel", label: "input__label" }}
        ownerNodeId={parseNodeId(waterSource.waterSourceNodeId)}
        onChange={onChange}
    />
);

export default WaterSourceTags;