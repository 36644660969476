import React from "react";
import classnames from "classnames";

import styles from "./labelElement.module.scss"

export interface ILabelElementProps {
    readonly label: string;
    readonly ownerId: string;
    readonly classes?: string;
}

const LabelElement = (props: ILabelElementProps): JSX.Element => {
    const css = classnames(styles.label, props.classes);
    return props.label
        ? <label className={css} htmlFor={props.ownerId}>{props.label}</label>
        : <React.Fragment />;
};

export default LabelElement;