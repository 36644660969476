import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    sectionHeader: {
        color: theme.palette.grey[400],
        marginBottom: theme.spacing(1)
    },
    status: {
        color: theme.palette.grey[400]
    },
}));

export default useStyles;