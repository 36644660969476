import React from 'react';
import { List } from '@3tc/shared-components';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { AccessControl } from '../../../auth/components';
import { RoleNames } from '../../../auth/roles';
import { Comparer, compareTimestamps, applyComparers } from '../../../lib/comparers';
import { NodeID, parseNodeId } from '../../../lib/nodeIdentifier';
import { formatLastEvent } from '../../../lib/Utils';
import { Repair, isRepair } from '../../../store/types';
import RepairControl from '../repairControl';

interface IRepairPanelProps {
    readonly repairs: Repair[];
    readonly selectedRepair?: Repair;
    readonly onClearRepair?: () => void;
    readonly onRefreshRepair?: () => void;
    readonly onSelectRepair?: (id: NodeID) => void;
    readonly showCreateRepairDialog: (category: "external" | "internal") => void;
}

type ListItem<T> = T & {
    readonly lastEventDescription: string;
    readonly typeDescription: string;
};

type RepairItem = ListItem<Repair>;

interface ListConfiguration<T, K = keyof ListItem<T>> {
    readonly titleKey: K;
    readonly infoKey: K;
    readonly titleLeftKey: K;
    readonly titleAttrKeys: K[];
    readonly moreMenu?: {
        readonly title: string;
        readonly items: {
            readonly key: string;
            readonly title: string;
            readonly onClick?: (arg: any) => void;
        }[];
    };
}

const configureList = (): ListConfiguration<RepairItem> => ({
    titleKey: 'repairId',
    infoKey: 'lastEventDescription',
    titleLeftKey: 'typeDescription',
    titleAttrKeys: ['repairId', 'lastEventDescription', 'typeDescription']
});

const getRepairType = (repair: Repair): string => {
    const { repairCategory, repairType } = repair;
    const typeName = repairType ? repairType.displayText : "Unknown Type";
    return `${repairCategory.displayText.substring(0, 3)}., ${typeName}`;
};

export const buildListItems = (repairs: Repair[]): RepairItem[] => {
    return repairs
        .map(repair => {
            return {
                ...repair,
                lastEventDescription: formatLastEvent(repair.lastEvent),
                typeDescription: getRepairType(repair)
            }
        })
        .sort((a, b) => {
            const comparers: Comparer<RepairItem>[] = [
                (a, b): number => compareTimestamps(a, b, "created"),
                (a, b): number => a.repairId - b.repairId,
                (a, b): number => a.typeDescription.localeCompare(b.typeDescription) * -1
            ];
            const result = applyComparers(comparers, a, b);
            return result;
        })
        .reverse();
};

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: "white"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1)
    }
}));

const RepairList = (props: Pick<IRepairPanelProps, "repairs" | "onSelectRepair" | "showCreateRepairDialog">): JSX.Element => {
    const { repairs, onSelectRepair, showCreateRepairDialog } = props;
    const styles = useStyles();
    const handleRepairClick = (item: Record<string, unknown>): void => {
        if (isRepair(item)) {
            onSelectRepair?.(parseNodeId(item.repairNodeId));
        }
    };
    const handleAddExternalRepairClick = (): void => {
        showCreateRepairDialog("external");
    };
    const handleAddInternalRepairClick = (): void => {
        showCreateRepairDialog("internal");
    };
    const listConfig = configureList();
    return (
        <div className={styles.root}>
            <AccessControl role={RoleNames.REPAIRS_ALL}>
                <div className={styles.buttonContainer}>
                    <button className="action-button" onClick={handleAddExternalRepairClick}>
                        Add External Repair
                    </button>
                    <button className="action-button" onClick={handleAddInternalRepairClick}>
                        Add Internal Repair
                    </button>
                </div>
            </AccessControl>
            <List items={buildListItems(repairs)} config={listConfig} clickHandler={handleRepairClick} />
        </div>
    );
};

const RepairPanel = (props: IRepairPanelProps): JSX.Element => {
    const { repairs, selectedRepair, onClearRepair, onRefreshRepair, onSelectRepair, showCreateRepairDialog } = props;
    return (
        <div>
            {selectedRepair
                ? <RepairControl selectedRepair={selectedRepair} onClose={onClearRepair} onRefresh={onRefreshRepair} />
                : <RepairList repairs={repairs} showCreateRepairDialog={showCreateRepairDialog} onSelectRepair={onSelectRepair} />
            }
        </div>
    );
};

export type { IRepairPanelProps };
export default RepairPanel;