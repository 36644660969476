// app permissions
export const BASE_ACCESS = "BASE_ACCESS";
export const CONFIGURATION = "CONFIGURATION";

// file manager permissions
export const FILES_DELETE = "FILES_DELETE";
export const FILES_DOWNLOAD = "FILES_DOWNLOAD"; // not regonised by FM; requires FILES_VIEW
export const FILES_EDIT = "FILES_EDIT";
export const FILES_UPLOAD = "FILES_UPLOAD"; // not recognised by FM; requires FILES_EDIT
export const FILES_VIEW = "FILES_VIEW";
export const SECURE_FILES_DELETE = "SENSITIVE_FILES_DELETE";
export const SECURE_FILES_DOWNLOAD = "SENSITIVE_FILES_DOWNLOAD"; // not regonised by FM; requires SENSITIVE_FILES_VIEW
export const SECURE_FILES_EDIT = "SENSITIVE_FILES_EDIT";
export const SECURE_FILES_UPLOAD = "SENSITIVE_FILES_UPLOAD"; // not recognised by FM; requires SENSITIVE_FILES_EDIT
export const SECURE_FILES_VIEW = "SENSITIVE_FILES_VIEW";

// feature permissions
export const DEFECTS_ALL = "DEFECTS_ALL";
export const DEFECTS_CREATE = "DEFECTS_CREATE";
export const DEFECTS_CLOSE = "DEFECTS_CLOSE";

export const INSPECTIONS_ALL = "INSPECTIONS_ALL";
export const INSPECTIONS_COMPLETE = "INSPECTIONS_COMPLETE";

export const REPAIRS_ALL = "REPAIRS_ALL";
export const ROUTES_ALL = "ROUTES_ALL";
export const SCHEMES_ALL = "SCHEMES_ALL";

export const WS_ALL = "WATERSOURCES_ALL";
export const WS_EDIT_DETAILS = "WATERSOURCES_EDIT_DETAILS";
export const WS_EDIT_HAZARDS = WS_EDIT_DETAILS;
export const WS_EDIT_LOCATION = "WATERSOURCES_EDIT_LOCATION";
export const WS_EDIT_LOCATION_COORDS = "WATERSOURCES_EDIT_POSITION";

export const NOTES_EDIT = "NOTES_EDIT";
export const NOTES_DELETE = "NOTES_DELETE";

// admin permissions
export const ADMIN_USERS = "MANAGE_USERS";
export const ADMIN_ROLES = "MANAGE_PERMISSIONS_ROLES";
