import React from "react";

import CreateDefectDialog from "../../defect/createDefectDialog";
import CreateInspectionDialog from "../../inspection/createInspectionDialog"
import { CreateExternalRepairDialog, CreateInternalRepairDialog } from "../../repairs/createRepairDialog";
import CreateRouteDialog from "../../routes/createRouteDialog";
import CreateSchemeDialog from "../../schemes/createSchemeDialog";
import CreateWaterSourceDialog from "../../waterSource/createWaterSourceDialog";
import { IWaterSource } from "../../../store/types";

interface ICreateInspectionDialogOpenProps {
    readonly waterSourcesToAssign?: string[];
    readonly selectedWaterSource?: IWaterSource;
}

type DialogVariant =
    "createWaterSource" |
    "createInspection" |
    "createDefect" |
    "createExternalRepair" |
    "createInternalRepair" |
    "createRoute" |
    "createScheme";

interface IGlobalDialogProps {
    readonly variant: DialogVariant | undefined;
    readonly createInspectionProps?: ICreateInspectionDialogOpenProps
    readonly onClose?: () => void;
}

export const GlobalDialog = (props: IGlobalDialogProps): JSX.Element => {
    const { variant, createInspectionProps, onClose } = props;

    const handleClose = (): void => {
        onClose?.();
    };

    switch (variant) {
        case "createDefect":
            return <CreateDefectDialog isOpen={true} onClose={handleClose} />;
        case "createExternalRepair":
            return <CreateExternalRepairDialog isOpen={true} onClose={handleClose} />;
        case "createInspection":
            return <CreateInspectionDialog
                isOpen={true}
                onClose={handleClose}
                waterSourcesToAssign={createInspectionProps?.waterSourcesToAssign}
                selectedWaterSource={createInspectionProps?.selectedWaterSource}
            />;
        case "createInternalRepair":
            return <CreateInternalRepairDialog isOpen={true} onClose={handleClose} />;
        case "createRoute":
            return <CreateRouteDialog isOpen={true} onClose={handleClose} />;
        case "createScheme":
            return <CreateSchemeDialog isOpen={true} onClose={handleClose} />;
        case "createWaterSource":
            return <CreateWaterSourceDialog isOpen={true} onClose={handleClose} />;
        default:
            return <React.Fragment />;
    }
};

export type { DialogVariant, IGlobalDialogProps, ICreateInspectionDialogOpenProps };
export default GlobalDialog;
