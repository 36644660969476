import React from "react";

import useInspectionsSummary, { InspectionSummary, empty } from "../../api/hooks/useInspectionsSummary";
import getInspections from "../../api/getStationInspections";
import useTechnician from "../../auth/hooks/useTechnician";
import LoadingContainer from "../../components/shared/loadingContainer"
import UnregisteredTechnicianItem from "../../components/shared/unregisteredTechnicianItem";
import View from '../../components/shared/view';
import StationList, { StationItem } from '../../components/stationList';
import { ILookupItem } from "../../store/types";

interface IStationListViewProps {
    readonly stations?: ILookupItem[];
    readonly isLoading: boolean;
}

const reducer = (stations: Record<number, StationItem>, inspection: InspectionSummary): Record<number, StationItem> => {
    const { station } = inspection;
    const update = (count: number): Record<number, StationItem> => ({
        ...stations,
        [station.nodeId.id]: { ...station, inspectionCount: count + 1 }
    });
    return stations[station.nodeId.id] === undefined
        ? update(0)
        : update(stations[station.nodeId.id].inspectionCount);
};

const lessThan = -1;
const equalTo = 0;
const greaterThan = 1;

const compare = (a: StationItem, b: StationItem): number => {
    if (isNaN(a.nodeId.id)) {
        return greaterThan;
    }
    if (isNaN(b.nodeId.id)) {
        return lessThan;
    }
    if (a.nodeId.id === b.nodeId.id) {
        return equalTo;
    }
    return a.name.localeCompare(b.name);
};

const StationListView = (): JSX.Element => {
    const technician = useTechnician();
    const [status, inspections = empty, refresh] = useInspectionsSummary(getInspections, technician);
    const isLoading = status !== "complete";
    const collation = inspections.edges
        .map(edge => edge.node)
        .reduce(reducer, {});
    const stations = Object
        .values(collation)
        .sort(compare);

    const handleViewRefresh = (): void => {
        refresh();
    };

    return (
        <View title="Stations" backButton onRefreshClick={handleViewRefresh}>
            {technician
                ? <LoadingContainer isLoading={isLoading} component={<StationList stations={stations} />} />
                : <UnregisteredTechnicianItem />
            }
        </View>
    );
}

export type { IStationListViewProps };
export default StationListView;