import React from "react";
import { Spinner } from "@blueprintjs/core";
import cls from "classnames";

import styles from "./QuickSearchLoading.module.scss";

interface IQuickSearchLoadingProps {
    readonly show?: boolean;
    readonly inline?: boolean;
}

const QuickSearchLoading = (props: IQuickSearchLoadingProps): JSX.Element =>
    props.show ?? true
        ? (
            <div className={cls(styles.root, { [styles.inline]: props.inline })}>
                <Spinner size={Spinner.SIZE_SMALL} />
                <p>Loading...</p>
            </div>
        )
        : <React.Fragment />

export type { IQuickSearchLoadingProps };
export default QuickSearchLoading;