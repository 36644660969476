import React from "react";

import { styled } from "@material-ui/core/styles";
import Typography, { TypographyTypeMap } from "@material-ui/core/Typography";

import { formatText } from "../../../../lib/Utils";
import { PropsWithWaterSource } from "../types";

const WaterSourceCategory = styled((props: PropsWithWaterSource<Omit<TypographyTypeMap["props"], "variant">>): JSX.Element => {
    const { waterSource, ...rest } = props;
    return (
        <Typography variant="body2" {...rest}>
            {formatText(waterSource.category.displayText, true, "-")}
        </Typography>
    );
})(({ theme }) => ({
    color: theme.palette.secondary.main
}));

export default WaterSourceCategory;