import { useState, useEffect, useCallback } from "react";

import { IWaterSource } from "../../store/types";
import getWaterSource from "../getWaterSource";
import { FetchStatus, FetchSingle } from "./types";
import { bindIfMounted } from "./utils";

const useWaterSource = (waterSourceNodeId: string | undefined): FetchSingle<IWaterSource> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [waterSource, setWaterSource] = useState<IWaterSource | undefined>(undefined);

    const fetch = useCallback(() => {
        let isMounted = true;
        const ifMounted = bindIfMounted(isMounted);

        setStatus("loading");
        if (waterSourceNodeId) {
            getWaterSource(waterSourceNodeId)
                .then(waterSource => ifMounted(() => setWaterSource(waterSource)))
                .catch(() => ifMounted(() => setWaterSource(undefined)))
                .finally(() => ifMounted(() => setStatus("complete")));
        }
        else {
            ifMounted(() => setWaterSource(undefined));
            ifMounted(() => setStatus("complete"));
        }

        return (): void => {
            isMounted = false;
        };
    }, [waterSourceNodeId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [status, waterSource, fetch];
};

export default useWaterSource;