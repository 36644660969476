import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import DefectSearch from "../../components/filters/defectSearch";
import InspectionSearch from "../../components/filters/inspectionSearch";
import RepairSearch from "../../components/filters/repairSearch";
import SchemeSearch from "../../components/filters/schemeSearch";
import RouteSearch from "../../components/filters/routeSearch";
import WaterSourceSearch from "../../components/filters/waterSourceSearch";
import styles from "./searchRouter.module.scss";

type SearchOptions = "water-sources" | "inspections" | "defects" | "repairs" | "schemes" | "routes";

const SearchRouter = (): JSX.Element => {
    const { path } = useRouteMatch() ?? { path: "" };
    const searchPath = (search: SearchOptions): string => {
        return `${path}/${search}/:savedSearchId?`;
    };
    return (
        <div className={styles.searchPage}>
            <Switch >
                <Route path={searchPath("water-sources")}>
                    <WaterSourceSearch />
                </Route>
                <Route path={searchPath("inspections")}>
                    <InspectionSearch />
                </Route>
                <Route path={searchPath("defects")}>
                    <DefectSearch />
                </Route>
                <Route path={searchPath("repairs")}>
                    <RepairSearch />
                </Route>
                <Route path={searchPath("schemes")}>
                    <SchemeSearch />
                </Route>
                <Route path={searchPath("routes")}>
                    <RouteSearch />
                </Route>
            </Switch>
        </div>
    );
};

export default SearchRouter;