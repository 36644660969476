import Feature from "ol/Feature";
import Control from "ol/control/Control";
import { Coordinate } from "ol/coordinate";
import LineString from "ol/geom/LineString";
import ClusterSource from "ol/source/Cluster";
import VectorSource from "ol/source/Vector";
import { CursorOptions, LineNodeFeature, MapControlButton } from "./Map.types";


export const clusterSource = (points: VectorSource): ClusterSource => {
    return new ClusterSource({
        distance: 75,
        source: points
    });
};

export const renderCursorType = (position: Coordinate, lineLength: string, options: CursorOptions): string => {
    const { enableDelete, enableMouseCoordinates, showLineLength } = options;
    
    if (enableDelete) {
        return `crosshair`
    }

    const formatCoord = (n: number | undefined | null): string => {
        return n?.toFixed(1).padStart(8, "0") ?? "?";
    };

    const formattedX = formatCoord(position[0]);
    const formattedY = formatCoord(position[1]);
    if (enableMouseCoordinates && !showLineLength) {
        return `url('data:image/svg+xml;utf8,<svg width="128" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" d="m12.305343,9.644233l2.877946,4.958828l-5.755892,0l2.877946,-4.958828m0,-1.827447l-4.503828,7.755742l9.007656,0l-4.503828,-7.755742z"/><text x="20" y="9" fill="black" font-size="12px" font-weight="bold">x: ${formattedX}</text><text x="20" y="20" fill="black" font-size="12px" font-weight="bold">y: ${formattedY}</text></svg>'), auto`;
    }
    if (showLineLength && !enableMouseCoordinates) {
        return `url('data:image/svg+xml;utf8,<svg width="128" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" d="m12.305343,9.644233l2.877946,4.958828l-5.755892,0l2.877946,-4.958828m0,-1.827447l-4.503828,7.755742l9.007656,0l-4.503828,-7.755742z"/><text x="20" y="20" fill="black" font-size="12px" font-weight="bold">Length: ${lineLength}</text></svg>'), auto`;
    }
    if (showLineLength && enableMouseCoordinates) {
        return `url('data:image/svg+xml;utf8,<svg width="128" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" d="m12.305343,9.644233l2.877946,4.958828l-5.755892,0l2.877946,-4.958828m0,-1.827447l-4.503828,7.755742l9.007656,0l-4.503828,-7.755742z"/><text x="20" y="9" fill="black" font-size="12px" font-weight="bold">x: ${formattedX}</text><text x="20" y="20" fill="black" font-size="12px" font-weight="bold">y: ${formattedY}</text><text x="10" y="32" fill="red" font-size="12px" font-weight="bold">Length: ${lineLength}</text></svg>'), auto`;
    }
    return 'auto';
};

export const renderCustomControls = (buttons: MapControlButton[] | undefined): Control[] => {
    if (buttons) {
        const allControls = buttons.map(btn => {
            const newButton = document.createElement('BUTTON');
            newButton.innerHTML = btn.icon;
            newButton.setAttribute('title', btn.title);
            newButton.setAttribute('id', btn.id);
            newButton.addEventListener('click', btn.action, false);

            const newControl = new Control({
                element: newButton
            });
            return newControl;
        });
        return allControls;
    }
    return [];
};

export const renderLineFeatures = (vector: string, lines: LineNodeFeature[] | undefined, source: VectorSource): void => {
    source.clear();
    if (lines && lines.length > 0) {
        lines.forEach(({ nodeId, path}) => {
            for (let i = 0; i < path.length - 1; i++) {
                const start = path[i];
                const end = path[i + 1];
                source.addFeature(new Feature({
                    geometry: new LineString([
                        [start.x, start.y],
                        [end.x, end.y]
                    ]),
                    vector,
                    nodeId
                }));
            }
        });
    }
};
