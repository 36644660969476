import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, TextProps } from "../types";

const EwsAccessDescription = ({ editing, value, onChange }: PropsWithEditing<TextProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        id: "water-source-accessDescription",
        fieldType: "text",
        label: "Access Description",
        classes: { container: 'input__group', label: 'input__label' },
        value,
        editing: editing ?? false,
        multiline: true,
        onChange: value => onChange?.(value)
    };
    return <LabelledField {...props} />;
};

export default EwsAccessDescription;