import React from "react";

import useNavigation from "../hooks/useNavigation";
import AppBarMenuItem from "./AppBarMenuItem";

const AppBarNotificationsItem = (): JSX.Element => {
    const { gotoNotifications } = useNavigation();

    const handleClick = (): void => {
        gotoNotifications();
    };

    return <AppBarMenuItem id="notifications" label="Notifications" onClick={handleClick} />;
};

export default AppBarNotificationsItem;