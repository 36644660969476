import executeQuery from "../../../lib/executeQuery";
import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import { IConnectionQuery, IConnection, IWaterSource } from "../../../store/types";

const gql = `
    query getWaterSourceIds($filter: QueryFilter, $paging: QueryControlInput) {
        waterSources(queryFilter: $filter, queryControl: $paging) {
            items {
                waterSourceNodeId: id
            }
        }
    }`;

const getWaterSourceIds = async (filter: Record<string, unknown>, totalCount: number): Promise<NodeID[]> => {
    const variables: IConnectionQuery<Record<string, unknown>> = {
        filter,
        paging: {
            pageIndex: 1,
            pageSize: totalCount,
            sortBy: "id",
            sortDirection: "ASCENDING"
        }
    };

    return executeQuery<{ waterSources: IConnection<Pick<IWaterSource, "waterSourceNodeId">> }>(gql, variables)
        .then(response => response?.waterSources)
        .then(connection => connection?.items ?? [])
        .then(items => items.map(item => parseNodeId(item.waterSourceNodeId)))
        .catch(reason => {
            console.error("Error fetching water source node ids.", reason);
            return [];
        });
};

export default getWaterSourceIds;