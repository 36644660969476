import React from "react";

import { createStyles, withStyles, Theme, StyleRules, WithStyles } from "@material-ui/core/styles";
import MuiCloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiTypography from '@material-ui/core/Typography';

const styles = (theme: Theme): StyleRules<"root" | "closeButton", Record<string, unknown>> => createStyles({
    root: {
        alignItems: "center",
        color: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "row",
        margin: 0,
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingRight: 0
    },
    closeButton: {
        color: theme.palette.primary.light,
        marginLeft: "auto",
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
});

interface DialogTitleProps extends WithStyles<typeof styles> {
    readonly id?: string;
    readonly children?: React.ReactNode;
    readonly onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps): JSX.Element => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <MuiTypography variant="h6">{children}</MuiTypography>
            {onClose ? (
                <MuiIconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <MuiCloseIcon />
                </MuiIconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export type { DialogTitleProps };
export default DialogTitle;