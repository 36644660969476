import React from "react";

import ActionButton from "../../../components/shared/ActionButton";
import useNavigation from "../../MobileRouter/hooks/useNavigation";

const AppBarSignOut = (): JSX.Element => {
    const { signOut } = useNavigation();
    const handleLogOutClick = (): void => {
        signOut();
    };
    return (
        <ActionButton id="appBar-user-logout" onClick={handleLogOutClick}>
            Log out
        </ActionButton>
    );
};

export default AppBarSignOut;