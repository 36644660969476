import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";

import { AccessControl } from "../../../auth/components";
import { RoleNames } from "../../../auth/roles";
import RolesPermissionsPage from "../../../pages/rolesPermissionsPage";
import UserAdminPage from "../../../pages/userAdminPage";

const AdminRouter = (): JSX.Element => {
    const { path } = useRouteMatch() ?? {};
    return (
        <Switch>
            <Route path={`${path}/users`}>
                <AccessControl role={RoleNames.ADMIN_USERS}>
                    <UserAdminPage />
                </AccessControl>
            </Route>
            <Route path={`${path}/roles`}>
                <AccessControl role={RoleNames.ADMIN_ROLES}>
                    <RolesPermissionsPage />
                </AccessControl>
            </Route>
        </Switch>
    );
};

export default AdminRouter;