import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AddNoteRequest, AddNoteResponse, INote, EditNoteRequest, EditNoteResponse, DeleteNoteRequest, DeleteNoteResponse } from "../types/notes";
import executeQuery from "../../lib/executeQuery";
import { NodeID, encodeNodeId } from "../../lib/nodeIdentifier";
import { guid } from "../../lib/Utils";
import { setLoadingToast, setSuccessToast } from "./app"
import { setError } from "./errors";
import addNoteMutation from "./graphQL/addNote";
import editNoteMutation from "./graphQL/editNote";
import deleteNoteMutation from "./graphQL/deleteNote";

export const createNote = (objectId: NodeID, content: string) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<INote[]> => {
        try {
            dispatch(setLoadingToast("Saving note..."));

            const request: AddNoteRequest = {
                input: {
                    clientMutationId: guid(),
                    data: {
                        ownerId: encodeNodeId(objectId),
                        content
                    }
                }
            };
            const response = await executeQuery<AddNoteResponse>(addNoteMutation, request);
            const { clientMutationId, hasErrors, object: { notes = [] } = {} } = response?.addNote ?? {};

            if (clientMutationId !== request.input.clientMutationId) {
                throw new Error("Request/response mismatch");
            }
            if (hasErrors) {
                throw new Error("Unhandled API error");
            }

            dispatch(setSuccessToast("Note saved.", 5000));

            return notes;
        }
        catch (err) {
            dispatch(setError("Error creating note", err));
            return [];
        }
    };
};

export const editNote = (objectId: NodeID, ownerId: NodeID, content: string) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<INote[]> => {
        try {
            dispatch(setLoadingToast("Saving note..."));

            const request: EditNoteRequest = {
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(objectId),
                    data: {
                        ownerId: encodeNodeId(ownerId),
                        content
                    }
                }
            };

            const response = await executeQuery<EditNoteResponse>(editNoteMutation, request);
            const { clientMutationId, hasErrors, object: { notes = [] } = {} } = response?.editNote ?? {};

            if (clientMutationId !== request.input.clientMutationId) {
                throw new Error("Request/response mismatch");
            }
            if (hasErrors) {
                throw new Error("Unhandled API error");
            }

            dispatch(setSuccessToast("Note saved.", 5000));

            return notes;
        } catch (error) {
            dispatch(setError("Error editing note", error));
            return [];
        }
    }
};

export const deleteNote = (noteNodeId: NodeID) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<INote[]> => {
        try {
            dispatch(setLoadingToast("Deleting note..."));

            const request: DeleteNoteRequest = {
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(noteNodeId)
                }
            };

            const response = await executeQuery<DeleteNoteResponse>(deleteNoteMutation, request);
            const { clientMutationId, hasErrors, object: { notes = [] } = {} } = response?.deleteNote ?? {};

            if (clientMutationId !== request.input.clientMutationId) {
                throw new Error("Request/response mismatch");
            }
            if (hasErrors) {
                throw new Error("Unhandled API error");
            }

            dispatch(setSuccessToast("Note deleted.", 5000));

            return notes
        }
        catch (error) {
            dispatch(setError("Error deleting note", error));
            return [];
        }
    }
};