import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { IReduxState } from "../../../../store/types";
import PostCodeSearch, { IPostCodeSearchProps } from "./PostCodeSearch.view";
import { getPostCodeCoordinates } from "../../../../store/actions/map";

const mapProps = (state: IReduxState): Pick<IPostCodeSearchProps, "projection"> => ({
    projection: state.map.mapSettings?.projection?.code
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>): Pick<IPostCodeSearchProps, "showOnMap"> => ({
    showOnMap: async (postCode, projection): Promise<void> => dispatch(getPostCodeCoordinates(postCode, projection))
});

export default connect(mapProps, mapDispatch)(PostCodeSearch);