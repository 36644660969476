import React from "react";
import { connect } from 'react-redux';
import { setGlobalToast } from '../../../../store/actions/app';
import { IReduxState, IAppAction } from '../../../../store/types';
import GlobalToast, { IGlobalToastProps } from './globalToast.view';

type ConnectedProps = Pick<IGlobalToastProps, "globalToast">;
type ConnectedActions = Omit<IGlobalToastProps, keyof ConnectedProps>;

const mapState = (state: IReduxState): ConnectedProps => ({
    globalToast: state.app.globalToast
});

const mapDispatch = (dispatch: React.Dispatch<IAppAction>): ConnectedActions => ({
    onClose: (): void => dispatch(setGlobalToast())
});

export default connect(mapState, mapDispatch)(GlobalToast);