import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

import AppBarAdminMenu from '../AppBarAdminMenu';
import AppBarCreateMenu from '../AppBarCreateMenu';
import AppBarHome from "../AppBarHome";
import AppBarNotificationsItem from '../AppBarNotificationsItem';
import AppBarSearch from '../AppBarSearch';
import AppBarSearchMenu from '../AppBarSearchMenu';
import AppBarUser from '../AppBarUser';
import AppBarConfigurationItem from '../AppBarConfigurationItem';
import AppBarDashboard from '../AppBarDashboardItem';

const DesktopAppBar = (): JSX.Element => {
    return (
        <AppBar position="sticky">
            <Toolbar disableGutters>
                <AppBarHome />
                <AppBarCreateMenu />
                <AppBarDashboard />
                <AppBarSearchMenu />
                <AppBarNotificationsItem />
                <AppBarConfigurationItem />
                <AppBarAdminMenu />
                <Box ml="auto" display="inline-flex" flexDirection="row" alignItems="center">
                    <AppBarSearch />
                    <AppBarUser />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default DesktopAppBar;