import React from "react";

import useSelector from "../../../../lib/hooks/useSelector";
import bindLookup from "../../../../lib/lookups";
import { ITechnician } from "../../../../store/types";
import CreateInspection, { CreateInspectionProps } from "./CreateInspection.veiw";

type ConnectedProps = Omit<CreateInspectionProps, "isMobile" | "inspectionTypes" | "technicians" | "inspector" | "inspectionGroups">;

const ConnectedCreateInspection = (props: ConnectedProps): JSX.Element => {
    const connectedProps: CreateInspectionProps = {
        ...props,
        isMobile: useSelector(state => state.device.isMobile),
        inspectionTypes: useSelector(state => bindLookup(state.app.lookups?.inspectionTypes ?? [])),
        technicians: useSelector(state => bindLookup(state.app.lookups?.technicians ?? [])),
        userProfile: useSelector(state => state.app.user?.profile) as ITechnician,
        inspectionGroups: useSelector(state => bindLookup(state.app.lookups?.inspectionGroups ?? []))
    };
    return <CreateInspection {...connectedProps} />;
};

export default ConnectedCreateInspection;