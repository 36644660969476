import React from "react";

import { ILifecycleEvent } from "../../../../lib/Model";
import { timestampFormatter, formatText } from "../../../../lib/Utils";
import LabelledField, { LabelledFieldProps } from "../../../labelledField";

interface LifecycleProps {
    readonly events: ILifecycleEvent[];
}

const RepairLifecycle = ({ events }: LifecycleProps): JSX.Element => {
    if (events instanceof Array) {
        const children = events.map((event, index) => {
            const eventLabel = formatText(event.action, true);
            const props: LabelledFieldProps = {
                fieldType: "readonly",
                id: `water-source-event-${eventLabel}`,
                label: eventLabel,
                classes: { container: 'input__group', label: 'input__label' },
                value: event.timestamp.format(timestampFormatter)
            };
            return <LabelledField key={index} {...props} />;
        });
        return <React.Fragment>{children}</React.Fragment>
    }
    return <React.Fragment />;
};

export default RepairLifecycle;