import { ILookupItem, LookupOwnerType } from "./app";

interface LookupItemOptions {
    readonly enabled?: boolean;
    readonly filters?: LookupOwnerType[];
    readonly groupingId?: number;
    readonly symbolName?: string;
}

export const LookupItem = (value: number, displayText: string, sortOrder: number, options?: LookupItemOptions): ILookupItem => {
    const { enabled, filters, groupingId, symbolName } = options ?? {};
    return {
        value,
        displayText,
        sortOrder,
        enabled: enabled ?? true,
        filters: filters ?? [],
        groupingId,
        symbolName
    };
};