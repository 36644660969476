import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => createStyles({
    root: {
    },
    inputRoot: {
        backgroundColor: theme.palette.primary.main,
        textTransform: "uppercase",
        "&, & *": {
            color: "white",
        },
        "& .MuiFilledInput-input": {
            textTransform: "uppercase",
        }
    },
    helperText: {
        color: theme.palette.grey[300]
    }
}));

interface SearchInputProps {
    readonly helperText?: string;
    readonly id?: string;
    readonly placeholder?: string;
    readonly value?: unknown;
    readonly onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    readonly onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SearchInput = (props: SearchInputProps): JSX.Element => {
    const styles = useStyles();
    const { helperText, id, placeholder, value, onChange, onClear } = props;
    const showClear = onClear && value;
    return (
        <FormControl fullWidth hiddenLabel variant="filled" className={styles.root}>
            <FilledInput
                id={id} placeholder={placeholder}
                value={value}
                onChange={onChange}
                type="text"
                classes={{ root: styles.inputRoot }}
                endAdornment={showClear &&
                    <InputAdornment position="end">
                        <IconButton onClick={onClear}>
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText id={`${id}-helper-text`} className={styles.helperText}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

export type { SearchInputProps };
export default SearchInput;