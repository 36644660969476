import { useState } from "react";
import { IPageInfo, IQueryControl, SortDirection } from "../../store/types";

export const useTablePaging = (initialPaging: IQueryControl, pageInfo: IPageInfo): any[] => {
    const [paging, setPaging] = useState<IQueryControl | undefined>();

    const handleSearchClick = (): void => {
        setPaging({ ...initialPaging });
    };

    const handleFirstClick = (): void => {
        if (paging) {
            setPaging({
                ...paging,
                pageIndex: 1
            });
        }
    };
    const handlePreviousClick = (): void => {
        if (paging) {
            setPaging({
                ...paging,
                pageIndex: pageInfo.pageNumber - 1
            });
        }
    };
    const handleNextClick = (): void => {
        if (paging) {
            setPaging({
                ...paging,
                pageIndex: pageInfo.pageNumber + 1
            });
        }
    };
    const handleLastClick = (): void => {
        if (paging) {
            setPaging({
                ...paging,
                pageIndex: pageInfo.pageCount
            });
        }
    };
    const handleTableHeaderClick = (sortBy: string, sortDirection: SortDirection): void => {
        if (paging) {
            setPaging({
                ...paging,
                sortBy,
                sortDirection
            });
        }
    };

    return [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ];
}