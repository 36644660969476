import React from "react";

interface OptionalProps {
    readonly hidden?: boolean;
}

const Optional = (props: React.PropsWithChildren<OptionalProps>): JSX.Element | null => {
    const { children, hidden } = props;
    return hidden
        ? null
        : <React.Fragment>{children}</React.Fragment>;
};

export type { OptionalProps };
export default Optional;