import React from "react";

import { createStyles, Theme, StyleRules, withStyles, WithStyles } from "@material-ui/core/styles";
import MuiTab, { TabTypeMap } from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme): StyleRules => createStyles({
    root: {
        color: ({ selected }: Pick<TabTypeMap["props"], "selected">): string => selected
            ? theme.palette.secondary.main
            : theme.palette.common.white
        ,
        minWidth: "unset",
    }
});

type TabProps =
    Pick<TabTypeMap["props"], "label"> &
    Pick<React.HTMLAttributes<TabTypeMap["defaultComponent"]>, "id">;

const renderLabel = (label: React.ReactNode): React.ReactNode => {
    if (typeof label === "string" || typeof label === "number") {
        return <Typography variant="caption">{label}</Typography>;
    }
    return label;
}

const Tab = withStyles(styles)((props: WithStyles<typeof styles> & Omit<TabTypeMap["props"], keyof TabProps> & TabProps): JSX.Element => {
    const { classes, id, label, ...rest } = props;
    return <MuiTab label={renderLabel(label)} id={id} className={classes.root} {...rest} />;
});

export default Tab;