import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { formatCurrency } from "../../../../lib/Utils";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, TextProps } from "../types";

const RepairExpectedCost = ({ show, editing, data, onChange }: PropsWithEditing<PropsWithShow<TextProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "text",
        id: "repair-expected-cost",
        label: "Expected Cost",
        classes: { container: 'input__group', label: 'input__label' },
        value: formatCurrency(data.expectedCost, true, editing ? "" : "-") ?? "-",
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairExpectedCost;