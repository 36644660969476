import React from "react";
import { useDispatch } from 'react-redux';

import useSelector from '../../../lib/hooks/useSelector';
import { guid } from "../../../lib/Utils";
import { startCreateInspection } from '../../../store/actions/inspections';
import { getWaterSource } from '../../../store/actions/waterSources';
import { IAddInspectionVariables } from "../../../store/types";
import { useWaterSource } from "../../appWindowContext";
import CreateInspectionDialogBase, { ICreateInspectionDialogProps } from './createInspectionDialog';

const CreateInspectionDialog = (props: Pick<ICreateInspectionDialogProps, "isOpen" | "onClose" | "waterSourcesToAssign" | "selectedWaterSource">): JSX.Element => {
    const dispatch = useDispatch();
    const inspectionGroups = useSelector(state => state.app.lookups?.inspectionGroups ?? []);
    const inspectionTypes = useSelector(state => state.app.lookups?.inspectionTypes ?? []);
    const technicians = useSelector(state => state.app.lookups?.technicians ?? []);
    const { instance: waterSource } = useWaterSource();
    const combined: ICreateInspectionDialogProps = {
        ...props,
        inspectionGroups,
        inspectionTypes,
        technicians,
        selectedWaterSource: waterSource ?? props.selectedWaterSource,
        getWaterSource: async (variables): Promise<void> => {
            const action = getWaterSource(variables);
            await action(dispatch);
        },
        onCreateInspection: async data => {
            const variables: IAddInspectionVariables = {
                input: {
                    clientMutationId: guid(),
                    data
                }
            };
            const action = startCreateInspection(variables);
            await action(dispatch);
        }
    };
    return <CreateInspectionDialogBase {...combined} />;
}

export default CreateInspectionDialog;