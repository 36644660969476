import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

interface CategoryIconProps {
    readonly title?: string;
    readonly className?: string;
    readonly height?: number;
    readonly width?: number;
}

const useStyles = makeStyles(createStyles({
    root: {
        display: "inline-flex"
    }
}));

const CategoryIcon = (props: React.PropsWithChildren<CategoryIconProps>): JSX.Element => {
    const styles = useStyles();
    const { title, className, height, width } = props;
    return (
        <span className={cx(styles.root, className)} title={title ?? "CATEGORY"}>
            <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24">
                {props.children}
            </svg>
        </span>
    );
};

export type { CategoryIconProps };
export default CategoryIcon;