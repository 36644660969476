import { useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SearchEntity } from "../../../components/filters/searchSelector";
import { NodeID, encodeNodeId } from "../../../lib/nodeIdentifier";
import { toQueryString } from "./useAppWindowFeatures";
import useAppWindowParams, { AppWindowParams } from "./useAppWindowParams";

const PATH_APP_ROOT = "/"
const PATH_SEARCH_ROOT = "/search";
const PATH_ADMIN_ROOT = "/admin";
const PATH_ADMIN_ROLES = "/rolespermissions";
const PATH_ADMIN_USERS = "/users";

const PATH_NOTIFICATIONS = "/notifications";
const PATH_WATERSOURCE = "/water-source"
const PATH_CONFIGURATION = "/configuration";
const PATH_DASHBOARD = '/dashboard'

interface Navigation {
    readonly isHome: boolean;
    readonly goHome: () => void;
    readonly gotoAdminRoles: () => void;
    readonly gotoAdminUsers: () => void;
    readonly gotoMap: (params?: AppWindowParams) => void;
    readonly gotoNotifications: () => void;
    readonly gotoSearch: (path: SearchEntity, search?: NodeID) => void;
    readonly gotoWaterSource: (id: NodeID) => void;
    readonly gotoConfiguration: () => void;
    readonly goToDashboard: () => void;
    readonly signOut: () => void;
}

const useNavigation = (newWindow?: boolean): Navigation => {
    const history = useHistory();
    const homeMatch = useRouteMatch({ path: PATH_APP_ROOT, exact: true });
    const params = useAppWindowParams();

    const bindHistory = useCallback(() => {
        return (path: string): void => {
            history.push(path);
        };
    }, [history]);
    const bindWindow = useCallback(() => {
        return (path: string): void => {
            window.open(path, "_blank");
        };
    }, []);
    const goto = useCallback((newWindow ? bindWindow : bindHistory)(), [newWindow, bindHistory, bindWindow]);

    const isHome = homeMatch !== null;

    const navigation = useMemo((): Navigation => ({
        isHome,
        goHome: (): void => {
            return goto(PATH_APP_ROOT);
        },
        gotoAdminRoles: (): void => {
            return goto(`${PATH_ADMIN_ROOT}${PATH_ADMIN_ROLES}`);
        },
        gotoAdminUsers: (): void => {
            return goto(`${PATH_ADMIN_ROOT}${PATH_ADMIN_USERS}`);
        },
        gotoMap: (options): void => {
            if (options) {
                const qs = toQueryString({ ...params, ...options });
                const path = `${PATH_APP_ROOT}?${qs}`;
                return goto(path);
            }
            return goto(PATH_APP_ROOT);
        },
        gotoNotifications: (): void => {
            return goto(PATH_NOTIFICATIONS);
        },
        gotoSearch: (path: SearchEntity, search?: NodeID): void => {
            return goto(`${PATH_SEARCH_ROOT}/${path}/${search ? encodeNodeId(search) : ""}`)
        },
        gotoWaterSource: (id): void => {
            return goto(`${PATH_WATERSOURCE}/${encodeNodeId(id)}`)
        },
        gotoConfiguration: (): void => {
            return goto(PATH_CONFIGURATION);
        },
        goToDashboard: (): void => {
            return goto(PATH_DASHBOARD)
        },
        signOut: (): void => {
            document.location.pathname = "/logout";
        }
    }), [isHome, params, goto]);

    return navigation;
};

export type { Navigation };
export {
    PATH_ADMIN_ROLES,
    PATH_ADMIN_ROOT,
    PATH_ADMIN_USERS,
    PATH_APP_ROOT,
    PATH_NOTIFICATIONS,
    PATH_SEARCH_ROOT,
    PATH_WATERSOURCE,
    PATH_CONFIGURATION,
    PATH_DASHBOARD
};
export default useNavigation;