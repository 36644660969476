import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, SelectProps } from "../types";

const WaterSourceSurface = ({ editing, options, value, onClear, onSelect }: PropsWithEditing<SelectProps>): JSX.Element => {
    const surfaceProps: LabelledFieldProps = {
        fieldType: "select",
        id: "water-source-surface",
        label: "Surface",
        classes: { container: 'input__group', label: 'input__label' },
        value,
        selectOptions: options,
        editing: editing ?? false,
        onChange: value => onSelect?.(value),
        onClear
    };
    return <LabelledField {...surfaceProps} />;
};

export default WaterSourceSurface;