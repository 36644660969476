import React from 'react';
import './Login.scss';

const Login = () => (
    <div className="login__container">
        <img src="/img/3tc-logo.svg" alt="The 3tc logo"/>
        <div>
            <h1>Water Management</h1>
            <a href="/login" className="btn">Log In</a>
            <small>Clicking the log in button will take you to our secure login page</small>
        </div>
    </div>
)

export default Login;
