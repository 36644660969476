import React, { useState, useRef, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => createStyles({
    root: {

    },
    button: {
        color: theme.palette.primary.contrastText
    },
    menu: {
        minWidth: theme.spacing(25),
        zIndex: 10
    }
}));

interface WaterSourceMenuProps {
    readonly onEditWaterSource: () => void;
    readonly onChangeWaterSourceId: () => void;
    readonly onViewHistory: () => void;
}

const WaterSourceMenu = (props: WaterSourceMenuProps): JSX.Element => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement>(null);

    const handleToggle = (): void => {
        setOpen(previous => !previous);
    };

    const handleClose = (): void => {
        if (anchor.current?.contains(event?.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchor.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleEditWaterSource = (): void => {
        handleClose();
        props.onEditWaterSource();
    };
    const handleViewWaterSourceHistory = (): void => {
        handleClose();
        props.onViewHistory();
    };
    const handleChangeWaterSourceId = (): void => {
        handleClose();
        props.onChangeWaterSourceId();
    };

    return (
        <div className={styles.root}>
            <IconButton ref={anchor} className={styles.button} aria-controls={open ? "watersource-menu" : undefined} aria-haspopup="true" onClick={handleToggle}>
                <MenuIcon />
            </IconButton>
            <Popper className={styles.menu} open={open} anchorEl={anchor.current} role={undefined} transition disablePortal placement="bottom-start">
                {({ TransitionProps }): JSX.Element => (
                    <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="watersource-menu" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleEditWaterSource}>Edit</MenuItem>
                                    <MenuItem onClick={handleChangeWaterSourceId}>Change ID</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleViewWaterSourceHistory}>View History</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export type { WaterSourceMenuProps };
export default WaterSourceMenu;