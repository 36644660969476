import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiInputLabel from "@material-ui/core/InputLabel";
import MuiSelect from "@material-ui/core/Select";

import SelectItem from "./selectItem";
import InputLabel from "./inputLabel";

interface SelectProps {
    readonly value: any;
    readonly id?: string;
    readonly label?: string;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly native?: boolean;
    readonly children?: React.ReactNode;
    readonly withBlankOption?: boolean;
    readonly onChange?: (event: React.ChangeEvent<{ value: unknown; }>, child: React.ReactNode) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginBottom: theme.spacing(1.5),
        "& .MuiFilledInput-underline::after": {
            borderBottomColor: theme.palette.secondary.main
        },
    },
    inputRoot: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        color: "white",
        "&:focus": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },
    },
    input: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    select: {
        "&.MuiSelect-select:not([multiple]) option": {
            color: theme.palette.primary.main
        },
        "&.MuiSelect-select:not([multiple]) optgroup": {
            color: theme.palette.primary.main
        }
    },
    icon: {
        color: "rgba(255, 255, 255, 0.5)"
    },
    placeholder: {
        fontStyle: "italic"
    },
    label: {
        color: theme.palette.grey[400],
        position: "unset",
        top: "unset",
        bottom: "unset",
        "&.Mui-focused": {
            color: theme.palette.grey[400]
        }
    }
}));

const Select = (props: SelectProps): JSX.Element => {
    const styles = useStyles();
    const { value, id, label, placeholder, required, disabled, native, children, withBlankOption = true, onChange } = props;
    const selectProps = {
        id,
        value,
        required,
        disabled,
        native,
        onChange
    };
    return (
        <MuiFormControl className={styles.root} fullWidth variant="filled">
            <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
            <MuiSelect {...selectProps} classes={{ root: styles.inputRoot, filled: styles.input, select: styles.select, icon: styles.icon }}>
                {withBlankOption && <SelectItem value="" native={native} className={styles.placeholder} >{placeholder}</SelectItem>}
                {React.Children.map(children, child => {
                    return React.isValidElement(child)
                        ? React.cloneElement(child, { native })
                        : child;
                })}
            </MuiSelect>
        </MuiFormControl>
    );
}

export type { SelectProps };
export default Select;