import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import LockIcon from "@material-ui/icons/Lock";

import IconDecoration from "../../../components/icons/IconDecoration";
import Optional from "../../../components/shared/Optional";
import { Attachment, ThemedProps, isDark } from "./Attachment.types";
import AttachmentDescription, { AttachmentDescriptionChange } from "./AttachmentDescription";
import AttachmentDownloader from "./AttachmentDownloader";
import AttachmentIcon from "./AttachmentIcon";
import AttachmentName from "./AttachmentName";

interface AttachmentItemProps {
    readonly attachment: Attachment;
    readonly editing?: boolean;
    readonly onDescriptionChange?: AttachmentDescriptionChange;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        borderBottom: (props: ThemedProps): string => `1px solid ${isDark(props.theme)
            ? theme.palette.primary.dark
            : theme.palette.grey[400]}`
    },
    secure: {
        color: (props: ThemedProps): string =>
            isDark(props.theme) ? theme.palette.grey[200] : theme.palette.grey[600],
        border: (props: ThemedProps): string =>
            `2px solid ${isDark(props.theme) ? theme.palette.grey[200] : theme.palette.grey[600]}`,
        boxShadow: (props: ThemedProps): string =>
            `0 0 0 2px ${isDark(props.theme) ? theme.palette.primary.main : theme.palette.grey[300]}`,
        backgroundColor: (props: ThemedProps): string =>
            isDark(props.theme) ? theme.palette.primary.main : theme.palette.grey[300],
        borderRadius: "50%",
        padding: "2px"
    },
}));

const AttachmentItem = (props: ThemedProps<AttachmentItemProps>): JSX.Element => {
    const styles = useStyles(props);
    const { attachment, theme, editing, onDescriptionChange } = props;

    return (
        <ListItem divider className={styles.root}>
            <ListItemIcon>
                <IconDecoration decoration={attachment.sensitive ? <LockIcon className={styles.secure} /> : <React.Fragment />}>
                    <AttachmentIcon type={attachment.type} theme={theme} />
                </IconDecoration>
            </ListItemIcon>
            <ListItemText
                primary={<AttachmentName name={attachment.name} size={attachment.size} theme={theme} />}
                secondary={<AttachmentDescription tempId={attachment.tempId} description={attachment.description} editing={editing} onChange={onDescriptionChange} />}
                secondaryTypographyProps={{ component: editing ? "div" : "p" }}
            />
            <Optional hidden={editing ?? attachment.type === "link"}>
                <ListItemSecondaryAction>
                    <AttachmentDownloader attachment={attachment} />
                </ListItemSecondaryAction>
            </Optional>
        </ListItem>
    );
};

export type { Attachment, AttachmentItemProps };
export default AttachmentItem;