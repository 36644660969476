import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";

import { bindComparers } from '../../../../lib/comparers';
import { INote, ObjectType } from '../../../../store/types';
import NoteListItem from '../NoteListItem';
import { NoteStylesProps } from '../types';

const useStyles = makeStyles(createStyles({
    root: {
        flexGrow: 1
    }
}));

// order by timestamp date descending, then by id descending
// places newest first; oldest last
const orderBy = bindComparers<INote>([
    (a, b): number => b.timestamp.toLocalDate().compareTo(a.timestamp.toLocalDate()),
    (a, b): number => b.noteId - a.noteId
]);

interface INoteListProps extends NoteStylesProps {
    readonly notes: INote[];
    readonly canDeleteNotes: boolean;
    readonly canEditNotes: boolean;
    readonly hideActions?: boolean;
    readonly objectType: ObjectType;
    readonly onDeleteNote?: (parentType: ObjectType, noteNodeId: string) => void;
    readonly onEditNote?: (objectType: ObjectType, ownerId: string, content: string) => void;
}

export const NoteList = (props: INoteListProps): JSX.Element => {
    const { notes, ...rest } = props;
    const styles = useStyles();
    return (
        <List dense={false} classes={{ ...styles }}>
            {notes.sort(orderBy).map(note => <NoteListItem key={note.noteNodeId} note={note} {...rest} />)}
        </List>
    );
};

export type { INoteListProps };
export default NoteList;
