import JSPDF from 'jspdf';
import autoTable, { CellDef } from 'jspdf-autotable';
import { LocalDateTime, DateTimeFormatter } from '@js-joda/core';

import { shortDateFormatter } from '../../../lib/Utils';
import { IRoute } from "../../../store/types";

export const printRoute = (route: IRoute, imageUrl: string): void => {
    const formatCell = (value: string | number | undefined, rowSpan = 1, colSpan = 1): CellDef => {
        const content = value ?? "-";
        return { content, rowSpan, colSpan };
    };
    
    const formatNamePart = (s: string): string => {
        return s.toLowerCase().replaceAll(/[\s\\/:*?"<>|]/g, "");
    };

    const { routeId, name, station, waterSources } = route;

    const pdf = new JSPDF({ orientation: 'landscape', unit: 'px' });
    pdf.text(`Water Management Route: ${station.displayText}, ${name} (${routeId})`, 30, 30);
    pdf.text(`Water sources: ${waterSources.length}`, 30, 40)
    pdf.addImage(imageUrl, 30, 50, 0, 285);
    pdf.addPage();
    pdf.setPage(2);

    autoTable(pdf, {
        head: [["Route Order", "Water Source", "Next Inspection Date", "Risk Severity", "Surface", "Hydrant Location", "Location Description", "Street", "Easting", "Northing"]],
        body: waterSources.map(ws => {
            return [
                formatCell(ws.routeSortOrder),
                formatCell(ws.waterSourceId),
                formatCell(ws.nextInspectionDate?.format(shortDateFormatter) ?? ws.recommendedInspectionDate?.format(shortDateFormatter)),
                formatCell(ws.riskSeverity?.displayText),
                formatCell(ws.surface?.displayText),
                formatCell(ws.hydrantLocation?.displayText),
                formatCell(ws.location.description),
                formatCell(ws.location.address?.streetDescription),
                formatCell(Math.trunc(ws.location.coordinates.x)),
                formatCell(Math.trunc(ws.location.coordinates.y))
            ];
        })
    });

    const timestamp = LocalDateTime.now().format(DateTimeFormatter.ofPattern("uuuuMMddHHmmss"));
    const fileName = `${formatNamePart(route.station.displayText)}-${formatNamePart(route.name)}-${timestamp}.pdf`;

    pdf.save(fileName);
};
