interface Formatter {
    (value: number): string;
}

const makeCoordinateFormatter = (scale: ((value: number) => number), maxLength: number): Formatter => {
    return (value: number): string => {
        const a = scale(value);
        const b = Math.round(a);
        const c = b.toString();
        const d = c.padStart(maxLength, "0");
        return d;
    };
};

export default makeCoordinateFormatter;