import React from 'react'

import { AccessControl } from '../../../auth/components';
import { RoleNames } from '../../../auth/roles';
import { useSearchContext } from '../searchSelector/searchSelectorContext';
import ActionButton from './ActionButton';
import styles from './ActionButtons.module.scss';

interface IActionButtonProps {
    readonly totalCount: number;
    readonly onClear?: () => void;
    readonly onCreateInspections?: [() => void, boolean];
    readonly onExport?: () => void;
    readonly onSearch?: () => void;
    readonly onShowOnMap?: () => void;
}

const ActionButtons = (props: IActionButtonProps): JSX.Element => {
    const { totalCount, onClear, onExport, onSearch, onShowOnMap } = props;

    const [onCreateInspections, createInProgress] = props.onCreateInspections ?? [];

    const { clearSelection, remove, save, saveAs } = useSearchContext();

    const handleClearClick = (): void => {
        clearSelection?.execute();
        onClear?.();
    };

    const handleCreateInspectionsClick = (): void => {
        onCreateInspections?.();
    };

    const handleExportClick = (): void => {
        onExport?.();
    };

    const handleSearchClick = (): void => {
        onSearch?.();
    };

    const handleShowOnMapClick = (): void => {
        onShowOnMap?.();
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <ActionButton id="search-delete" disabled={remove?.disabled} onClick={remove?.execute}>
                    Delete Saved Search
                </ActionButton>
                <ActionButton id="search-saveAs" disabled={saveAs?.disabled} onClick={saveAs?.execute}>
                    Save Search As
                </ActionButton>
                <ActionButton id="search-save" disabled={save?.disabled} onClick={save?.execute}>
                    Save Search
                </ActionButton>
                <ActionButton id="search-clear" disabled={clearSelection?.disabled} variant="cancel" onClick={handleClearClick}>
                    Clear
                </ActionButton>
                <ActionButton id="search-export" disabled={totalCount === 0} onClick={handleExportClick}>
                    Export
                </ActionButton>
                <AccessControl role={RoleNames.INSPECTIONS_ALL}>
                    <ActionButton id="search-createInspections" inProgress={createInProgress} disabled={totalCount === 0} hidden={onCreateInspections === undefined} width={160} onClick={handleCreateInspectionsClick}>
                        Create Inspections
                    </ActionButton>
                </AccessControl>
            </div>
            <div className={styles.container}>
                <ActionButton id="search-showOnMap" disabled={totalCount === 0} hidden={onShowOnMap === undefined} onClick={handleShowOnMapClick}>
                    Show on Map
                </ActionButton>
                <ActionButton id="search-search" onClick={handleSearchClick}>
                    Search
                </ActionButton>
            </div>
        </div>
    );
};

export default ActionButtons;