import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

interface MultiSelectProps {
    readonly value: any;
    readonly id?: string;
    readonly label?: string;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly native?: boolean;
    readonly children?: React.ReactNode;
    readonly isWhiteLabel?: boolean;
    readonly onChange?: (value: number[]) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    input: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    label: {
        position: "unset",
        top: "unset",
        bottom: "unset",
        marginBottom: theme.spacing(2),
        color: ({ isWhiteLabel }: { isWhiteLabel?: boolean }): string => isWhiteLabel ? `${theme.palette.common.white}` : 'inherit',
        "&.MuiFormLabel-root.Mui-focused": {
            color: ({ isWhiteLabel }: { isWhiteLabel?: boolean }): string => isWhiteLabel ? `${theme.palette.common.white}` : 'inherit'
        },
        "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
            transform: "translate(0, 10px) scale(0.75)",
        }
    },
    select: {
        color: ({ isWhiteLabel }: { isWhiteLabel?: boolean }): string =>
            isWhiteLabel
                ? theme.palette.common.white
                : theme.palette.grey[900],
        textTransform: "uppercase",
        ["&:not([multiple]) option"]: {
            color: theme.palette.grey[900],
            textTransform: "uppercase",
        },
        ["&:not([multiple]) optgroup"]: {
            color: theme.palette.grey[900],
            textTransform: "uppercase",
        },
        "& > option": {
            color: ({ isWhiteLabel }: { isWhiteLabel?: boolean }): string =>
                isWhiteLabel
                    ? theme.palette.common.white
                    : theme.palette.grey[900],
            "&:checked": {
                color: ({ isWhiteLabel }: { isWhiteLabel?: boolean }): string =>
                    isWhiteLabel
                        ? theme.palette.common.white
                        : theme.palette.common.white,
                backgroundColor: ({ isWhiteLabel }: { isWhiteLabel?: boolean }): string =>
                    isWhiteLabel
                        ? theme.palette.primary.light
                        : theme.palette.grey[500],
            }
        },
    }
}));

const Field = (props: MultiSelectProps): JSX.Element => {
    const { value, id, label, required, disabled, native, children, isWhiteLabel, onChange } = props;
    const styles = useStyles({ isWhiteLabel });
    const labelId = `${id}-label`;

    const handleChange = (event: React.ChangeEvent<{ value: unknown; }>): void => {
        const { options } = event.target as HTMLSelectElement;
        const selectedValues: number[] = [];
        for (const o of options) {
            if (o.selected) {
                selectedValues.push(Number(o.value));
            }
        }
        onChange?.(selectedValues);
    };

    const multiSelectProps = {
        id,
        labelId,
        value,
        required,
        disabled,
        native,
        onChange: handleChange
    };
    return (
        <FormControl className={styles.root} fullWidth variant="filled">
            <InputLabel id={labelId} htmlFor={id} classes={{ formControl: styles.label }} variant="filled" shrink required={required}>{label}</InputLabel>
            <Select multiple {...multiSelectProps} classes={{ filled: styles.input, select: styles.select }}>
                {children}
            </Select>
        </FormControl>
    );
}

export type { MultiSelectProps };
export default Field;