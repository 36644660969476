export default `
fragment Point on GeoPoint {
    x
    y
}
fragment AonRange on AddressableObjectNameRange {
    number
    suffix
}
fragment Aon on AddressableObjectName {
    description
    startRange { ...AonRange }
    endRange { ...AonRange }
}
fragment Address on Address {
    addressNodeId: id
    addressId
    saon { ...Aon }
    paon { ...Aon }
    streetDescription
    usrn
    locality
    town
    administrativeArea
    postCode
    uprn
    coordinates { ...Point }
}
mutation CreateAddress($input: EditAddressInput!) {
    gis {
        address {
            update(input: $input) {
                clientMutationId
                address {
                    ...Address
                }
                hasErrors
                errors {
                    message
                    path
                }
            }
        }
    }
}`;
