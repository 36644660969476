import { IDefectSummary } from "../components/inspectionView";
import executeQuery from "../lib/executeQuery";
import { IInspection } from "../store/types";
import { NodeResponse, NodeRequest } from "./types";

const gql = `
fragment Defect on Defect {
    defectNodeId: id
    defectId
    type {
        displayText
        value
    }
    dateReported
    reportedBy
    isOpen
    isCancelled
    isResolved
    notes {
        noteNodeId: id
        noteId
        timestamp
        author
        content
      }
}
query getOpenDefects_Query($id: ID!) {
    node(id:$id) {
        ... on Inspection {
            isOpen
            waterSource {
                defects(includeClosed: false) {
                    ...Defect
                }
            }
            defectsReviewed {
                defect {
                    ...Defect
                }
                isDefectResolved
            }
        }
    }
}`;

type OpenDefectsResponse = Pick<IInspection, "isOpen" | "waterSource" | "defectsReviewed">;

const mapDefects = ({ isOpen, waterSource: { defects: openDefects }, defectsReviewed }: OpenDefectsResponse): IDefectSummary[] =>
    isOpen
        ? openDefects
        : defectsReviewed
            .filter(r => !r.isDefectResolved)
            .map(r => r.defect)
            .map(d => ({
                defectNodeId: d.defectNodeId,
                defectId: d.defectId,
                type: d.type,
                dateReported: d.dateReported,
                reportedBy: d.reportedBy,
                isOpen: d.isOpen,
                isCancelled: d.isCancelled,
                isResolved: d.isResolved,
                notes: []
            }));

const getOpenDefects = async (inspectionNodeId: string): Promise<IDefectSummary[]> => {
    const request: NodeRequest = {
        id: inspectionNodeId
    };
    return executeQuery<NodeResponse<OpenDefectsResponse>>(gql, request)
        .then(response => response?.node)
        .then(inspection => inspection ? mapDefects(inspection) : []);
};

export default getOpenDefects;