import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { IQuickSearchItem } from "../../store/types";
import QuickSearch, { waterSourceIdSearch } from "../quickSearch";

interface InlineQuickSearchProps {
    readonly id?: string;
    readonly result?: IQuickSearchItem;
    readonly invalid?: boolean;
    readonly onClear?: () => void;
    readonly onResult?: (result: IQuickSearchItem) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    inputContainer: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    searchContainer: {
        flexGrow: 1
    },
    label: {
        marginRight: theme.spacing(1),
        width: "128px"
    },
    input: {
        backgroundColor: "#273c4e",
        border: "3px solid #273c4e",
        color: "white",
        height: "44px",
        padding: "12px",
        textTransform: "uppercase",
        width: "100%",
    },
    closeButton: {
        color: theme.palette.primary.light,
        "&:hover": {
            color: theme.palette.secondary.main
        },
        margin: 0
    }
}));

const InlineQuickSearch = (props: InlineQuickSearchProps): JSX.Element => {
    const styles = useStyles();
    const { id, result, invalid, onClear, onResult } = props;

    const renderResult = (item: IQuickSearchItem): JSX.Element => (
        <React.Fragment>
            <input
                type="text"
                id={id ?? "inline-quickSearch"}
                className={styles.input}
                disabled
                value={item.waterSourceId}
            />
            <IconButton className={styles.closeButton} onClick={onClear}>
                <CloseIcon />
            </IconButton>
        </React.Fragment>
    );

    const renderSearch = (): JSX.Element => (
        <QuickSearch searchTypes={[waterSourceIdSearch]} isInline={true} invalid={invalid} onSearchResultClick={onResult} />
    );

    return (
        <div className={styles.root}>
            <label htmlFor={id ?? "inline-quickSearch"} className={styles.label}>Water Source *</label>
            <div className={styles.inputContainer}>
                {result
                    ? renderResult(result)
                    : renderSearch()
                }
            </div>
        </div>
    );
};

export type { InlineQuickSearchProps };
export default InlineQuickSearch;
