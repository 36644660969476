import React from "react";
import { useDispatch } from 'react-redux';
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import executeQuery from "../../../lib/executeQuery";

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { setRenderPoints } from "../../../store/actions/map";
import { createHydrant, createEmergencyWaterSupply, createRiser } from '../../../store/actions/waterSources';
import { isRoute, isScheme, WaterSourceCategory, IWaterSource, IConnection } from "../../../store/types";
import { useRoute, useScheme, useWaterSource } from "../../appWindowContext";
import CreateWaterSourceDialogBase, { ICreateWaterSourceDialogProps } from './createWaterSourceDialog';

const CreateWaterSourceDialog = (props: Pick<ICreateWaterSourceDialogProps, "isOpen" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const { change: selectWaterSource } = useWaterSource();
    const { instance: scheme, refresh: refreshScheme } = useScheme();
    const { instance: { route } = {}, refresh: refreshRoute } = useRoute();
    const context = scheme ?? route;

    // category 'Unknown' is not available for new water sources
    const categories = useSelector(state => state.app.lookups?.waterSourceCategories.filter((cat) => cat.value !== 0) ?? []);
    const classifications = useSelector(state => state.app.lookups?.waterSourceClasses ?? []);
    const statuses = useSelector(state => state.app.lookups?.waterSourceStatuses ?? []);
    const stations = useSelector(state => state.app.lookups?.stations ?? []);
    const createFromMapLocation = useSelector(state => state.map.createFromMapLocation?.slice(0, 2) as ([number, number] | undefined));

    const baseProps: ICreateWaterSourceDialogProps = {
        ...props,
        context,
        categories,
        classifications,
        statuses,
        stations,
        createFromMapLocation,
        onCreateWaterSourcePreValidation: async (data): Promise<boolean> => {
            const { externalId } = data;

            if (!externalId) {
                return true;
            }
            
            if (externalId) {
                const variables = {
                    filter: {
                        externalId
                    }
                };

                const query = `query QuickSearch_Query($filter: QueryFilter) {
                    waterSources(queryFilter: $filter) {
                        totalCount
                    }
                }`

                const res = await executeQuery<{ waterSources: IConnection<IWaterSource> }>(query, variables);
                const totalCount = res?.waterSources.totalCount;
                return !totalCount;
            }

            return false;
        },
        onCreateWaterSource: async (category, data) => {
            const getCreateAction = (): ((dispatch: ThunkDispatch<any, any, AnyAction>) => Promise<IWaterSource | undefined>) => {
                const schemeId = isScheme(context) ? context.schemeNodeId : undefined;
                const route = isRoute(context) ? context : undefined;
                const addToMap: boolean = window.location.pathname !== '/search'
                switch (category) {
                    case WaterSourceCategory.EWS:
                        return createEmergencyWaterSupply(data, schemeId, route, addToMap);
                    case WaterSourceCategory.RISER:
                        return createRiser(data, schemeId, route, addToMap);
                    default: // WaterSourceCategory.HYDRANT
                        return createHydrant(data, schemeId, route, addToMap);
                }
            };

            const createWaterSource = getCreateAction();
            const waterSource = await createWaterSource(dispatch);

            if (scheme) {
                await refreshScheme();
            }
            if (route) {
                await refreshRoute();
            }
            dispatch(setRenderPoints(true));

            if (waterSource) {
                selectWaterSource(parseNodeId(waterSource.waterSourceNodeId), { focusMap: true });
            }
        }
    };

    return <CreateWaterSourceDialogBase {...baseProps} />;
};

export default CreateWaterSourceDialog;