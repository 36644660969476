import React from "react";
import { FlexWrapProperty, MinHeightProperty } from "csstype";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

interface ViewToolbarProps {
    readonly extended?: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    toolbar: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        flexDirection: "row",
        flexWrap: ({ extended } : ViewToolbarProps): FlexWrapProperty => {
            return extended ? "wrap" : "initial";
        },
        minHeight: ({ extended }: ViewToolbarProps): MinHeightProperty<string | 0> => {
            return extended ? "128px" : "initial"
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
}));

const ViewToolbar = (props: React.PropsWithChildren<ViewToolbarProps>): JSX.Element => {
    const styles = useStyles(props);
    return <Toolbar className={styles.toolbar}>{props.children}</Toolbar>;
};

export type { ViewToolbarProps };
export default ViewToolbar;