import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
    return createStyles({
        root: {

        },
        hazard: {

        }
    })
});

export default useStyles;
