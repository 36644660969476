import { formatEastingsAndNorthings, formatGridReference } from "../../../lib/coordinates";
import { shortDateFormatter } from "../../../lib/Utils";
import { Repair } from "../../../store/types";

const newLine = '%0D%0A';

const formatField = (name: string, content: string | number | undefined): string => {
    return `${name}: ${escape(content?.toString() ?? "-")}${newLine}`;
}

const formatBody = (repair: Repair): string => {
    const {
        repairVendor,
        waterSource,
        dateCreated,
        repairId,
        repairType,
        defect,
        workOrderRef,
        purchaseOrderRef
    } = repair;
    return "" +
        formatField("Repair Vendor", repairVendor?.displayText) +
        newLine +
        formatField("Water Source ID", waterSource?.waterSourceId) +
        formatField("Defect ID", defect?.defectId) +
        formatField("Defect Type", defect?.type?.displayText) +
        newLine +
        formatField("Location Description", waterSource?.location?.description) +
        formatField("Hydrant Location", waterSource?.hydrantLocation?.displayText) +
        formatField("Street", waterSource?.location?.address?.streetDescription) +
        formatField("Locality", waterSource?.location?.address?.locality) +
        formatField("Town", waterSource?.location?.address?.town) +
        formatField("Easting, Northing", waterSource?.location?.coordinates ? formatEastingsAndNorthings(waterSource?.location?.coordinates) : undefined) +
        formatField("Grid Ref.", waterSource?.location?.coordinates ? formatGridReference(waterSource?.location?.coordinates) : undefined) +
        newLine +
        formatField("Repair Created", dateCreated?.format(shortDateFormatter)) +
        formatField("Repair ID", repairId) +
        formatField("Repair Type", repairType?.displayText) +
        newLine +
        formatField("Purchase Order Reference", purchaseOrderRef) +
        formatField("Work Order Reference", workOrderRef);
}

export const formatEmail = (repair: Repair): string => {
    const {
        repairVendor: {
            emailAddress,
        } = {}
    } = repair;
    return `mailto:${emailAddress}?subject=New Repair Request&body=${formatBody(repair)}`;
};
