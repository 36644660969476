export default `
    query getSavedSearches($searchPath:String!) {
        searches(searchPath:$searchPath) {
            id
            searchPath
            displayText
            queryControl {
                pageIndex
                pageSize
                sortBy
                sortDirection
            }
            queryFilter
            searchId
            sortOrder
        }
    }
`;