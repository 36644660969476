import React from "react";
import { useDispatch } from "react-redux";
import useSelector from "../../../lib/hooks/useSelector";
import { guid } from "../../../lib/Utils";
import { startCancelRepair } from "../../../store/actions/repairs";
import { useRepair } from "../../appWindowContext";
import { SelectOption } from "../../labelledField";

import CancelRepairDialogBase, { CancelRepairDialogProps } from "./CancelRepairDealog.view";

const CancelRepairDialog = (props: Pick<CancelRepairDialogProps, "isOpen" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const cancelReasons = useSelector(state => state.app.lookups?.repairCancelReasons ?? []);
    const { instance: repair, refresh: refreshRepair } = useRepair();
    if (repair) {
        const combined = {
            ...props,
            repair,
            cancelReasons,
            onCancelRepair: async (cancelReason: SelectOption): Promise<void> => {
                if (repair === undefined) {
                    return;
                }
                const action = startCancelRepair({
                    input: {
                        clientMutationId: guid(),
                        nodeId: repair.repairNodeId,
                        data: Number(cancelReason.value)
                    }
                });
                await action(dispatch);
                await refreshRepair();
            }
        }
        return <CancelRepairDialogBase {...combined} />;
    }
    return <React.Fragment />
};

export default CancelRepairDialog;
