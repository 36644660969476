import React from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import LocationSearchingIcon from "@material-ui/icons/LocationSearching";

import LabelledField from "../../../../../../components/labelledField";
import ElementContainer from "../../../../../../components/labelledField/components/elementContainer";
import LabelElement from "../../../../../../components/labelledField/components/labelElement";
import * as coords from "../../../../../../lib/coordinates";
import { IPoint } from "../../../../../../store/types";

import styles from "./mapReference.module.scss";

interface MapReferenceProps {
    readonly id: string;
    readonly coordinates: IPoint;
    readonly editing?: boolean;
    readonly hideSearch?: boolean;
    readonly onChange?: (coordinates: IPoint) => void;
    readonly onSearchClick?: () => void;
}

const formatEastingNorthing = (coordinates: IPoint | undefined): string => {
    return coordinates?.x && coordinates?.y
        ? coords.formatEastingsAndNorthings(coordinates)
        : "-";
};

const formatGridReference = (coordinates: IPoint | undefined): string => {
    return coordinates?.x && coordinates?.y
        ? coords.formatGridReference(coordinates)
        : "-";
};

const GridReference = (props: { id: string; coordinates: IPoint | undefined }): JSX.Element => {
    return <LabelledField fieldType="readonly" id={`${props.id}-grid-reference`} label="Grid Reference" value={formatGridReference(props.coordinates)} />
};

const MapReference = (props: MapReferenceProps): JSX.Element => {
    const { coordinates, editing, hideSearch, id, onChange, onSearchClick } = props;
    if (editing) {
        const containerId = `${id}-edit-container`;
        const x = Math.floor(coordinates?.x);
        const y = Math.floor(coordinates?.y);
        const handleXChange = (value: number | undefined): void => {
            if (value !== undefined) {
                onChange?.({ x: value, y });
            }
        };
        const handleYChange = (value: number | undefined): void => {
            if (value !== undefined) {
                onChange?.({ x, y: value });
            }
        };
        return (
            <Box>
                <ElementContainer noMargin>
                    <LabelElement ownerId={containerId} label="Easting, Northing" classes={styles.label} />
                    <Box id={containerId} display="flex" flexDirection="row">
                        <LabelledField fieldType="number" id={`${id}-coords-x`} label="X" editing minValue={undefined} value={x} onChange={handleXChange} />
                        <Box ml={1} />
                        <LabelledField fieldType="number" id={`${id}-coords-y`} label="Y" editing minValue={undefined} value={y} onChange={handleYChange} />
                        {!hideSearch &&
                            <IconButton onClick={onSearchClick} >
                                <LocationSearchingIcon className={styles.searchButton} />
                            </IconButton>
                        }
                    </Box>
                </ElementContainer>
                <GridReference id={id} coordinates={coordinates} />
            </Box>
        );
    }
    return (
        <Box>
            <LabelledField fieldType="readonly" id={`${id}-easting-northing`} label="Easting, Northing" value={formatEastingNorthing(coordinates)} />
            <GridReference id={id} coordinates={coordinates} />
        </Box>
    );
};

export type { MapReferenceProps };
export default MapReference;
