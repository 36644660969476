import React from "react";

import { IWaterSource } from "../../store/types";
import MobileSearchMatchResult from "./MobileSearchMatchResult";
import MobileSearchNoMatchResult from "./MobileSearchNoMatchResult";

interface MobileSearchResultProps {
    readonly show: boolean;
    readonly searchTerm: string;
    readonly result: IWaterSource | undefined;
    readonly onClose?: () => void;
}

const MobileSearchResult = ({ show, searchTerm, result, onClose }: MobileSearchResultProps): JSX.Element => {
    if (show) {
        return result
        ? <MobileSearchMatchResult show={show} result={result} onClose={onClose} />
        : <MobileSearchNoMatchResult searchTerm={searchTerm} />;
    }
    return <React.Fragment />;
};

export type { MobileSearchResultProps };
export default MobileSearchResult;