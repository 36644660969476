import executeQuery from "../../../lib/executeQuery";
import { IConnection, IConnectionQuery, IQueryControl } from "../../../store/types";
import gql from "./query.text";
import { RepairSearchItem } from "./types";

interface RepairQueryResponse {
    readonly repairs: IConnection<RepairSearchItem>;
}

const queryRepairsByFilter = async (filter: Record<string, unknown>, paging: IQueryControl): Promise<IConnection<RepairSearchItem>> => {
    const input: IConnectionQuery<Record<string, unknown>> = {
        filter,
        paging
    };
    const response = await executeQuery<RepairQueryResponse>(gql, input);
    return response
        ? response.repairs
        : Promise.reject(new Error("Missing query response."));
};

export type { RepairQueryResponse };
export default queryRepairsByFilter;