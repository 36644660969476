import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, NumberProps } from "../types";

const id = "water-source-inspection-frequency";

const Editor = ({ value, onChange }: NumberProps): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "number",
        id: id,
        label: "Inspection Frequency",
        classes: { container: "input__group", label: "input__label" },
        value: value,
        minValue: 1,
        editing: true,
        onChange: value => onChange?.(value),
    };
    return <LabelledField {...props} />;
}

const ReadOnly = ({ value }: NumberProps): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "readonly",
        id: "water-source-inspection-frequency",
        label: "Inspection Frequency",
        classes: { container: "input__group", label: "input__label" },
        value: value ? `${value} months` : undefined,
    };
    return <LabelledField {...props} />;
}

const WaterSourceInspectionFrequency = (props: PropsWithEditing<NumberProps>): JSX.Element => {
    const { editing, ...rest } = props;
    return editing
        ? <Editor {...rest} />
        : <ReadOnly {...rest} />
};

export default WaterSourceInspectionFrequency;