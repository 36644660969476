import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { FetchStatus } from "../../../api/hooks";
import { getRepairsForWaterSource } from "../../../store/actions/repairs";
import { Repair } from "../../../store/types";

const useRepairs = (waterSourceNodeId: string | undefined): [Repair[], () => void, FetchStatus] => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [repairs, setRepairs] = useState<Repair[]>([]);

    const dispatch = useDispatch();

    const fetch = useCallback(() => {
        if (waterSourceNodeId) {
            setStatus("loading");
            const action = getRepairsForWaterSource({ id: waterSourceNodeId });
            action(dispatch)
                .then(response => setRepairs(response))
                .catch(error => console.warn("Error getting repairs for water source", error))
                .finally(() => setStatus("complete"));
        }
        else {
            setRepairs([]);
            setStatus("complete");
        }
    }, [waterSourceNodeId]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [repairs, fetch, status]
};

export default useRepairs;