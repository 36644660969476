import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { NodeID, encodeNodeId } from '../../lib/nodeIdentifier';
import { useNavigation } from '../../router/MobileRouter/hooks';
import { EmptyItem } from '../inspectionList/components';
import useStyles from "./StationList.styles";

interface StationItem {
    readonly nodeId: NodeID;
    readonly name: string;
    readonly inspectionCount: number;
}

interface StationListProps {
    readonly stations: StationItem[]
}

const StationList = (props: StationListProps): JSX.Element => {
    const { gotoStation } = useNavigation();
    const handleStationClick = (station: StationItem): void => {
        gotoStation(station.nodeId, station.name);
    };
    const classes = useStyles();
    const { stations } = props;
    return (
        <div className={classes.container}>
            <List className={classes.list} disablePadding>
                {stations.length > 0
                    ? stations.map((station: StationItem) =>
                        <ListItem button key={encodeNodeId(station.nodeId)} className={classes.item} onClick={(): void => handleStationClick(station)}>
                            <ListItemText primary={station.name} />
                            <ListItemSecondaryAction>
                                {station.inspectionCount}
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                    : <EmptyItem message="No stations with open inspections." />
                }
            </List>
        </div>
    );
};

export type { StationListProps, StationItem };
export default StationList;