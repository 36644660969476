import { useState, useEffect, useMemo, useCallback } from "react";

import { IInspection, IConnectionQuery, IConnection, emptyPage, IEdge, FilteredObject, emptyConnection } from "../../store/types";
import { IQueryFilter, ITechnician } from "../../store/types/inspections";
import { NodeID, parseNodeId } from "../../lib/nodeIdentifier";
import { FetchStatus, FetchSingle } from "./types";
import { InspectionQuery } from ".";

interface InspectionSummary {
    readonly inspectionNodeId: string;
    readonly inspectionId: number;
    readonly station: {
        readonly nodeId: NodeID;
        readonly name: string;
    };
    readonly route: {
        readonly nodeId: NodeID;
        readonly name: string;
    }
}

const empty = emptyConnection<InspectionSummary>();

const mapSummary = (page: IConnection<IInspection>): IConnection<InspectionSummary> => {
    const map = (inspection: IInspection): InspectionSummary => {
        const { station, route } = inspection.waterSource;
        return {
            inspectionNodeId: inspection.inspectionNodeId,
            inspectionId: inspection.inspectionId,
            station: station
                ? { nodeId: NodeID("Station", station.value), name: station.displayText }
                : { nodeId: NodeID("Station", Number.NaN), name: "(No Station)" },
            route: route
                ? { nodeId: parseNodeId(route.routeNodeId), name: route.name }
                : { nodeId: NodeID("Route", Number.NaN), name: "(No Route)" }
        };
    };
    const mapEdge = (edge: IEdge<IInspection>): IEdge<InspectionSummary> => ({
        index: edge.index,
        node: map(edge.node)
    });
    const edges = page.edges.map(mapEdge);
    return {
        edges: edges,
        items: edges.map(edge => edge.node),
        pageInfo: page.pageInfo,
        totalCount: page.totalCount
    };
};

/**
 * @param {boolean} isPlanned true to only included planned/scheduled inspections, false to only include unplanned/ad-hoc inspections
 * @param {number} pageIndex the number of the query results page to return
 * @param {number} pageSize the number of query results in a page
 */
const useInspectionsSummary = (query: InspectionQuery, technician: ITechnician | undefined, station?: NodeID, pageIndex = 1, pageSize = 99999999): FetchSingle<IConnection<InspectionSummary>> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [page, setPage] = useState<IConnection<InspectionSummary>>(empty);

    const { technicianId, inspectionGroups } = technician ?? {};
    const groupIds = useMemo(() => inspectionGroups?.map(group => group.inspectionGroupId) ?? [], [inspectionGroups]);

    const { id: stationId } = station ?? {};
    const fetch = useCallback(() => {
        if (technicianId) {
            const filter: FilteredObject<IQueryFilter> = {
                isOpen: true,
                stationId,
                isPlanned: true,
                "$any": {
                    technicianId,
                    "$all": {
                        technicianId: null,
                        inspectionGroupId: {
                            "$in_list": groupIds
                        }
                    }
                }
            };
            setStatus("loading");
            const filters: IConnectionQuery<FilteredObject<IQueryFilter>> = {
                filter: filter ?? {},
                paging: {
                    pageIndex: pageIndex ?? 1,
                    pageSize: pageSize ?? 9999999,
                    sortBy: "inspectionDate",
                    sortDirection: "DESCENDING"
                }
            };
            query(filters)
                .then(page => mapSummary(page))
                .then(page => setPage(page))
                .catch(() => setPage(empty))
                .finally(() => setStatus("complete"));
        }
    }, [technicianId, stationId, groupIds, JSON.stringify(groupIds), pageIndex, pageSize]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [status, page, fetch];
};

export type { InspectionSummary };
export { empty };
export default useInspectionsSummary;