export default `mutation archiveThoroughfareMutation($input: EmptyEditInput!) {
    gis {
      thoroughfare {
        archive(input: $input) {
          hasErrors
          errors {
            message
            path
          }
          clientMutationId
          thoroughfare {
            thoroughfareNodeId: id
            thoroughfareId
            isActive
            name
            scheme {
              schemeId
            }
            path {
              coordinates {
                x
                y
              }
            }
          }
        }
      }
    }
  }`