import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { MenuItem } from "../types";

interface MainMenuProps {
    readonly items: MenuItem[];
}

const MainMenu = (props: MainMenuProps): JSX.Element => {
    return (
        <List>
            {props.items.map((item, index) => (
                <ListItem key={index} button onClick={item.onClick}>
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.label} />
                </ListItem>
            ))}
        </List>
    )
};

export type { MainMenuProps };
export default MainMenu;