export default `
query getInspectionForTechnician_Query($id: ID!) {
    node(id:$id) {
        ... on Inspection {
            inspectionNodeId: id
            inspectionId
            isWallPlateFitted
            isServiceRecordInDate
            waterSource {
                waterSourceNodeId: id
                waterSourceId: externalId
                category {
                    displayText
                    value
                }
                classification {
                    value
                    displayText
                }
                location {
                    address {
                        addressNodeId: id
                        addressId
                        paon {
                            description
                            startRange {
                                number
                                suffix
                            }
                            endRange {
                                number
                                suffix
                            }
                        }
                        streetDescription
                        locality
                        town
                        postCode
                    }
                    coordinates {
                        x
                        y
                    }
                    description
                }
                isActive
                isOperable {
                    value
                    isOverridden
                }
                isDefective {
                    value
                    isOverridden
                }
                status {
                    displayText
                    value
                }
                riskSeverity {
                    displayText
                    value
                }
                lastInspectionDate
                nextInspectionDate
                recommendedInspectionDate
                station {
                    displayText
                    value
                }
                additionalInfo
                ... on Hydrant {
                    flowRate
                    mainsSize {
                        value
                        unit {
                          value
                          displayText
                          symbolName
                        }
                      }
                    hydrantLocation {
                        displayText
                        value
                    }
                    pressure
                    surface {
                        value
                        displayText
                    }
                    plateLocation {
                        value
                        displayText
                    }
                    plateDistance
                }
                ... on ITagged {
                    tags {
                        value
                        displayText
                        enabled
                        sortOrder
                    }
                }
                defects(includeClosed:false) {
                    defectNodeId:id
                    defectId
                    type {
                        displayText
                        value
                    }
                    dateReported
                    reportedBy
                    isOpen
                    isCancelled
                    isResolved
                }
                controlMeasures {
                    value
                    displayText
                    enabled
                    sortOrder
                    filters
                }
                hazardSeverity {
                    value
                    displayText
                    enabled
                    sortOrder
                    filters
                }
                hazards {
                    hazardNodeId: id
                    hazardId
                    hazardType {
                        value
                        displayText
                    }
                }
                isSecondPersonRequired
                roadSpeed {
                    value
                    displayText
                    enabled
                    sortOrder
                    filters
                }
                hazardInformation
                hazardFlag {
                    value
                    displayText
                    enabled
                    sortOrder
                    filters
                }
                notes {
                    noteNodeId: id
                    noteId
                    timestamp
                    author
                    content
                }
            }
            type {
                displayText
                value
            }
            inspectionDate
            isOpen
            isCompleted
            isCancelled
            technician {
                technicianNodeId:id
                technicianId
                displayName
                userName
            }
            crew {
                crewNodeId:id
                crewId
                callSign
            }
            inspectionGroup {
                inspectionGroupNodeId: id
                inspectionGroupId
                name
                members {
                    ... on Technician {
                        technicianNodeId: id
                    }
                    ... on Crew {
                        crewNodeId: id
                    }
                }
            }
            test {
                displayText
                value
            }
            result {
                displayText
                value
            }
            defectsReviewed {
                defect {
                    defectNodeId:id
                    defectId
                    type {
                        displayText
                        value
                    }
                    dateReported
                    reportedBy
                }
                isDefectResolved
            }
            description
        }
    }
}`;