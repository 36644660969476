import React, { useState, useEffect } from "react";
import { Input, LoadingBar } from "@3tc/shared-components";

import { jsonToCsv, downloadCsvFile } from "../../../lib/csv";
import { formatText } from "../../../lib/Utils";
import { ILookups, IConnection, ISavedSearch, IInspection, ILookupItem, LookupItem, IQueryControl } from "../../../store/types";
import { ChoiceChips, IChip } from "../../shared/chips";
import ActionButtons from "../actionButtons";
import Filter from "../filter";
import FilterResultsTable from "../filterResultsTable";
import SearchSelectorContext from "../searchSelector/searchSelectorContext";
import { ITableHeader, ViewOptions } from "../types";
import { queryRoutesByFilter } from "./query";
import { RouteSearchItem, RouteSelectedFilters } from "./types";
import { buildQueryFilter, buildRouteFilters } from "./utils";
import styles from "./routeSearch.module.scss";
import { useTablePaging } from "../hooks";
import { SortDirections } from "../filterResultsTable/components/results";

interface RouteSearchProps {
    readonly lookups: ILookups;
    readonly hideToast: () => void;
    readonly showError: (message: string, error?: Error) => void;
    readonly showLoading: (message: string) => void;
    readonly showRouteOnMap: (route: RouteSearchItem) => void;
    readonly showSuccess: (message: string) => void;
}

const formatInspectionCount = (route: RouteSearchItem): string => {
    return route.waterSources
        .reduce((count, current): number => {
            return count + current.inspections.length;
        }, 0)
        .toString();
};
const formatAssignments = (selector: (i: Readonly<Pick<IInspection, "technician" | "crew" | "inspectionGroup">>) => (ILookupItem | undefined)) => {
    return (route: RouteSearchItem): string => {
        return route.waterSources
            .flatMap(ws => ws.inspections)
            .map(selector)
            .map(item => item?.displayText)
            .reduce<string[]>((names, current): string[] => {
                return current && !names.includes(current)
                    ? [...names, current]
                    : names;
            }, [])
            .join(", ");
    };
};
const formatTechnicians = formatAssignments(i => {
    const { technician } = i;
    return technician
        ? LookupItem(technician.technicianId, technician.displayName, technician.sortOrder ?? 0)
        : undefined;
});
const formatCrews = formatAssignments(i => {
    const { crew } = i;
    return crew
        ? LookupItem(crew.crewId, crew.callSign, crew.sortOrder ?? 0)
        : undefined;
});
const formatGroups = formatAssignments(i => {
    const { inspectionGroup } = i;
    return inspectionGroup
        ? LookupItem(inspectionGroup.inspectionGroupId, inspectionGroup.name, inspectionGroup.sortOrder ?? 0)
        : undefined;
});

const tableHeaders: ITableHeader[] = [
    {
        displayText: "Id",
        sortBy: "id"
    },
    {
        displayText: "Name",
        sortBy: "name"
    },
    {
        displayText: "Station",
        sortBy: "station"
    },
    {
        displayText: "Active",
        sortBy: "isActive"
    },
    {
        displayText: "Water Sources",
        sortBy: "waterSourceCount"
    },
    {
        displayText: "Open Inspections",
        sortBy: "openInspectionCount"
    },
    {
        displayText: "Technicians"
    },
    {
        displayText: "Crews"
    },
    {
        displayText: "Groups"
    }
];
const tableRows = (routes: RouteSearchItem[]): string[][] => {
    return routes.map(route => {
        return [
            route.routeId.toString(),
            route.name,
            route.station.displayText,
            route.isActive ? 'Yes' : 'No',
            route.waterSources.length.toString(),
            formatInspectionCount(route),
            formatTechnicians(route),
            formatCrews(route),
            formatGroups(route)
        ];
    });
};

const empty: IConnection<RouteSearchItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "id",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "id",
    sortDirection: "ASCENDING"
};

const viewOptions: IChip[] = [
    { label: "Active & Inactive", value: "all" },
    { label: "Active Only", value: "active" },
    { label: "Inactive Only", value: "archived" },
];

const queryRoutes = async (selectedFilters: RouteSelectedFilters, paging: IQueryControl): Promise<IConnection<RouteSearchItem>> => {
    const filter = buildQueryFilter(selectedFilters);
    return queryRoutesByFilter(filter, paging);
};

const RouteSearch = (props: RouteSearchProps): JSX.Element => {
    const { hideToast, showError, showLoading, showRouteOnMap } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<RouteSelectedFilters>({ isActive: ["true"] });
    const [page, setPage] = useState<IConnection<RouteSearchItem>>(empty);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();
    const [selectedView, setSelectedView] = useState<ViewOptions>("active");
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            showLoading("Getting routes...");
            queryRoutes(selectedFilters, paging)
                .then(routes => setPage(routes))
                .catch(() => showError("Error searching for routes"))
                .finally(() => {
                    setIsLoading(false);
                    hideToast();
                });
        }
    }, [paging]);

    const handleFilterChange = (value: string): void => {
        if (Object.keys(selectedFilters).indexOf(value) > -1) {
            const update = { ...selectedFilters };
            delete update[value];
            setSelectedFilters(update);
            return;
        }
        setSelectedFilters(current => ({ ...current, [value]: [] }));
    };

    const handleClearClick = (): void => {
        setIsLoading(true);
        setSelectedView("active");
        setSelectedFilters({ isActive: ["true"] });
        setPage(empty);
        setIsLoading(false);
    };

    const handleExportClick = (): void => {
        showLoading("Downloading CSV.");
        queryRoutes(selectedFilters, { pageIndex: 1, pageSize: totalCount, sortBy: "id", sortDirection: SortDirections.ASCENDING })
            .then(routes => {
                if (routes) {
                    const dataToExport = routes.items.map(route => ({
                        routeId: route.routeId,
                        name: route.name,
                        station: route.station,
                        waterSourcesCount: route.waterSources.length,
                        openInspectionsCount: formatInspectionCount(route),
                        technicians: formatTechnicians(route),
                        crews: formatCrews(route),
                        groups: formatGroups(route)
                    }));
                    const csvData = jsonToCsv(dataToExport);
                    downloadCsvFile(csvData);
                } else {
                    showError("Error exporting water sources to CSV.")
                }
            })
            .catch(error => showError("Error exporting water sources to CSV.", error))
            .finally(hideToast);
    };

    const handleRowClick = (id: string): void => {
        const route = items.find(item => item.routeId.toString() === id);
        if (route) {
            showRouteOnMap(route);
        }
    };

    const handleViewOptionsChange = (value: string): void => {
        const selected = value as ViewOptions;
        setSelectedView(selected);

        const getIsActive = (): string[] => {
            switch (selected) {
                case "all":
                    return [];
                case "active":
                    return ["true"];
                case "archived":
                    return ["false"]
            }
        };

        const isActive = getIsActive();

        setSelectedFilters(current => ({ ...current, isActive }));
    };

    const handleChangeSelection = (selected: ISavedSearch | undefined): void => {
        setSelectedSearch(selected);
    };

    useEffect(() => {
        if (selectedSearch) {
            setSelectedFilters(selectedSearch.queryFilter);
        }
    }, [selectedSearch, setSelectedFilters]);

    const routeFilters = buildRouteFilters(props.lookups);

    return (
        <SearchSelectorContext entity="routes" selectedFilters={selectedFilters} onChangeSelection={handleChangeSelection}>
            <div className={styles.allInputs}>
                <div className={styles.inputContainer}>
                    <Input
                        type="multilist"
                        id="selected-filters-list"
                        labelText="Search On"
                        isEditing
                        value={Object.keys(selectedFilters)}
                        responseOptions={Object.keys(routeFilters).map(item => ({
                            value: item,
                            text: formatText(item, true, undefined, true)
                        }))}
                        onChangeHandler={handleFilterChange}
                    />
                </div>
                {Object.keys(selectedFilters).map(filter => (
                    <Filter
                        key={filter}
                        name={filter}
                        options={routeFilters[filter]}
                        selected={selectedFilters}
                        onChangeSelection={setSelectedFilters}
                    />
                ))}
            </div>
            <ChoiceChips name="activeOrArchived" chips={viewOptions} styles={{ root: styles.viewOptions }} selected={selectedView} onChange={handleViewOptionsChange} />
            <ActionButtons
                totalCount={totalCount}
                onClear={handleClearClick}
                onExport={handleExportClick}
                onSearch={handleSearchClick}
            />
            {isLoading
                ? <LoadingBar loading />
                : <FilterResultsTable
                    headers={tableHeaders}
                    items={tableRows(items)}
                    pageInfo={pageInfo}
                    totalCount={totalCount}
                    onFirstClick={handleFirstClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onLastClick={handleLastClick}
                    onTableHeaderClick={handleTableHeaderClick}
                    onTableRowClick={handleRowClick}
                />
            }
        </SearchSelectorContext>
    );
}

export type { RouteSearchProps };
export default RouteSearch;