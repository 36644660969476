export default `mutation editRoute_Mutation($input: EditRouteInput!) {
    updateRoute(input: $input) {
        route {
            routeNodeId: id
            routeId
            name
            isActive
            station {
                value
                displayText
            }
            waterSources {
                waterSourceNodeId: id
                waterSourceId: externalId
                isActive
                location {
                    coordinates {
                        x
                        y
                    }
                }
                isOperable {
                    value
                    isOverridden
                }
                isDefective {
                    value
                    isOverridden
                }
                status {
                    value
                }
                riskSeverity {
                    value
                    displayText
                }
                nextInspectionDate
                recommendedInspectionDate
                routeSortOrder
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }  
            events {
                actor
                action
                timestamp
            }
            lastEvent {
                actor
                action
                timestamp
            }
    	} 
    }
}`