import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { FilterOption } from "../../types";

interface FilterListItemProps {
    readonly option: FilterOption;
}

const useStyles = makeStyles(theme => createStyles({
    root: (option: FilterOption) => ({
        color: option.enabled ? "inherit" : theme.palette.grey[500],
        fontStyle: option.enabled ? "normal" : "italic",
        textTransform: "capitalize",
    })
}));

const FilterOptionItem = (props: FilterListItemProps): JSX.Element => {
    const { option } = props;
    const styles = useStyles(option);
    return (
        <option className={styles.root} value={option.value.toString()}>
            {option.text}
        </option>
    );
};

export type { FilterListItemProps };
export default FilterOptionItem;