import React from "react";
import { OverflowYProperty } from "csstype";

import { createStyles, withStyles, Theme, StyleRules, WithStyles } from "@material-ui/core/styles";
import MuiDialog, { DialogProps as MuiDialogProps } from "@material-ui/core/Dialog";

const styles = (theme: Theme): StyleRules<"paper", Partial<DialogProps>> => createStyles({
    paper: {
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        overflowY: (props): OverflowYProperty | undefined => props.overflowY
    }
});

interface DialogProps extends WithStyles<typeof styles> {
    readonly overflowY?: OverflowYProperty;
    readonly onClose?: () => void;
}

const Dialog = withStyles(styles)((props: DialogProps & Omit<MuiDialogProps, keyof DialogProps>) => {
    const { children, classes, fullWidth, maxWidth, open, overflowY, onClose, ...other } = props;
    return (
        <MuiDialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} onClose={onClose} classes={classes} disableBackdropClick disableEscapeKeyDown {...other} >
            {children}
        </MuiDialog>
    );
});

export default Dialog;