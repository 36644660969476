import React from "react";
import { useDispatch } from 'react-redux';

import useSelector from '../../../lib/hooks/useSelector';
import { setGlobalToast } from "../../../store/actions/app";
import { setAssignInspectionGroupSuccess } from "../../../store/actions/inspections";
import { useInspection } from "../../appWindowContext";
import AssignInspectionGroupDialogBase, { IAssignInspectionGroupDialogProps } from './assignInspectionGroupDialog';

const AssignInspectionGroupDialog = (props: Pick<IAssignInspectionGroupDialogProps, "isOpen" | "onAssignInspectionGroup" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const assignInspectionGroupSuccess = useSelector(state => state.inspections.assignInspectionGroupSuccess);
    const groups = useSelector(state => state.app.lookups?.inspectionGroups ?? []);
    const { instance: inspection } = useInspection();
    const combined: IAssignInspectionGroupDialogProps = {
        ...props,
        assignInspectionGroupSuccess,
        groups,
        inspectionNodeId: inspection?.inspectionNodeId ?? "",
        setAssignInspectionGroupSuccess: (): void => {
            dispatch(setAssignInspectionGroupSuccess())
        },
        onClearError: (): void => {
            dispatch(setGlobalToast());
        },
        onShowError: (message): void => {
            dispatch(setGlobalToast({
                type: "ERROR",
                message,
                showToast: true
            }));
        }
    };
    return <AssignInspectionGroupDialogBase {...combined} />;
};

export default AssignInspectionGroupDialog;