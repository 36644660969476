import { StyleLike } from "ol/style/Style";

type Cache = Partial<Record<string, StyleLike>>;

export default class StyleCache {
    private styles: Cache;
    constructor(styles?: Cache) {
        this.styles = styles ?? {};
    }

    public addStyle(key: string, style: StyleLike): void {
        console.log(`adding map style to cache: ${key}.`);
        this.styles[key] = style;
    }

    public getStyle(key: string): StyleLike | undefined {
        const style = this.styles[key];
        return style;
    }

    public getStyles(): Readonly<Cache> {
        return this.styles;
    }
}