import React from "react";
import { useDispatch } from 'react-redux';

import { NodeID } from '../../../lib/nodeIdentifier';
import { setDialogOpen } from "../../../store/actions/app";
import { setMapClickAction, setRenderPoints } from '../../../store/actions/map';
import { startAddWaterSourceToScheme, startClearSchemeBoundary } from '../../../store/actions/schemes';
import { useScheme, useWaterSource } from "../../appWindowContext";
import SchemePanelBase, { ISchemePanelProps } from './schemePanel.view';

type ConnectedActions = Pick<ISchemePanelProps,
    "onAddBoundary" |
    "onAssignWaterSource" |
    "onCancelEdit" |
    "onRemoveBoundary" |
    "onEditBoundary" |
    "onCreateInspectionsClick"
>;

const SchemePanel = (props: Omit<ISchemePanelProps, keyof ConnectedActions>): JSX.Element => {
    const dispatch = useDispatch();
    const { refresh: refreshScheme } = useScheme();
    const { refresh: refreshWaterSource } = useWaterSource();

    const refreshMap = (): void => {
        dispatch(setRenderPoints(true));
    };

    const actions: ConnectedActions = {
        onAssignWaterSource: async (scheme: NodeID, waterSource: NodeID): Promise<void> => {
            const action = startAddWaterSourceToScheme(scheme, waterSource);
            await action(dispatch);
            await refreshScheme();
            await refreshWaterSource();
            refreshMap();
        },
        onAddBoundary: () => {
            dispatch(setMapClickAction("DRAW_POLYGON"));
        },
        onRemoveBoundary: async (scheme: NodeID) => {
            const action = startClearSchemeBoundary(scheme);
            await action(dispatch);
            await refreshScheme();
            refreshMap();
        },
        onCancelEdit: async () => {
            await refreshScheme();
        },
        onEditBoundary: () => {
            dispatch(setMapClickAction("EDIT_POLYGON"));
        },
        onCreateInspectionsClick: (waterSourceIds): void => {
            dispatch(setDialogOpen("createInspection", { waterSourcesToAssign: waterSourceIds }));
        }
    };

    return <SchemePanelBase {...props} {...actions} />;
};

export default SchemePanel;
