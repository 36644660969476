import React from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { useRoleChecks } from "../../../auth/hooks";
import useSelector from "../../../lib/hooks/useSelector";
import { setGlobalToast, setDialogOpen } from "../../../store/actions/app";
import { addHazards, removeHazards } from "../../../store/actions/hazards";
import { navigateToLocationOnMap, setMovingWaterSource, setRenderPoints } from "../../../store/actions/map";
import { editWaterSource as editMapWaterSource, setIsEditingWaterSourceAborted } from '../../../store/actions/waterSources';
import {
    editHydrant,
    editEmergencyWaterSupply,
    editRiser,
    editUnknownWaterSource,
    setEditWaterSourceSuccess,
    startUpdateWaterSourceActivation,
    setEditWaterSource,
    savePumpTypes
} from "../../../store/actions/waterSources"
import { defaultLookups } from "../../../store/reducers/app";
import { IGlobalToast, ILookupItem, IMapWaterSource } from "../../../store/types"
import { useRoute, useScheme } from "../../appWindowContext";
import { DialogVariant } from "../../utils/globalDialog";
import WaterSourceControlBase, { IWaterSourceControlProps } from "./waterSourceControl.view";

const resolveCoordinates = (source: number[] | undefined): [number, number] | undefined => {
    return source && source.length === 2
        ? [source[0], source[1]]
        : undefined;
};

const WaterSourceControl = ({ selectedWaterSource, onRefresh, changeWaterSourceCoordinates }: Partial<Pick<IWaterSourceControlProps, "selectedWaterSource" | "onRefresh" | "changeWaterSourceCoordinates">>): JSX.Element => {
    const dispatch = useDispatch();

    const { has: checkAccess } = useRoleChecks();

    const { change: showRoute } = useRoute();
    const { change: showScheme } = useScheme();

    const editing = useSelector(state => state.waterSources.editingSelectedWaterSource);
    const editWaterSourceSuccess = useSelector(state => state.waterSources.editWaterSourceSuccess ?? false);
    const lookups = useSelector(state => state.app.lookups ?? defaultLookups);
    const movingWaterSource = useSelector(state => state.map.movingWaterSource);
    const movingWaterSourceLocation = useSelector(state => resolveCoordinates(state.map.movingWaterSourceLocation));
    const fileStoreToken = useSelector(state => state.fileStore.token);
    const waterSources = useSelector(state => state.waterSources.waterSources ?? []);
    const isEditingWaterSourceAborted = useSelector(state => state.waterSources.isEditingWaterSourceAborted);

    const editWaterSource = async (action: (dispatch: ThunkDispatch<any, any, AnyAction>) => Promise<void>): Promise<void> => {
        await action(dispatch);
        onRefresh?.();
    };

    if (selectedWaterSource) {
        const props: IWaterSourceControlProps = {
            selectedWaterSource,
            editing,
            editWaterSourceSuccess,
            lookups,
            movingWaterSource,
            movingWaterSourceLocation,
            fileStoreToken,
            waterSources,
            isEditingWaterSourceAborted,
            editEmergencyWaterSupply: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                await editWaterSource(editEmergencyWaterSupply(variables, triggerMapReRender, tags));
            },
            editHydrant: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                await editWaterSource(editHydrant(variables, triggerMapReRender, tags));
            },
            editRiser: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                await editWaterSource(editRiser(variables, triggerMapReRender, tags));
            },
            editUnknownWaterSource: async (variables, triggerMapReRender, tags?: ILookupItem[]): Promise<void> => {
                await editWaterSource(editUnknownWaterSource(variables, triggerMapReRender, tags));
            },
            startUpdateWaterSourceActivation: async (variables): Promise<void> => {
                const action = startUpdateWaterSourceActivation(variables);
                await action(dispatch);
                onRefresh?.();
            },
            setDialogOpen: (dialog: DialogVariant): void => {
                dispatch(setDialogOpen(dialog))
            },
            setEditWaterSource: (editing): void => {
                dispatch(setEditWaterSource(editing))
            },
            setEditWaterSourceSuccess: (): void => {
                dispatch(setEditWaterSourceSuccess())
            },
            setGlobalToast: (globalToast?: IGlobalToast): void => {
                dispatch(setGlobalToast(globalToast))
            },
            setMovingWaterSource: (movingWaterSource: boolean): void => {
                dispatch(setMovingWaterSource(movingWaterSource))
            },
            onShowRoute: id => {
                showRoute(id);
            },
            onShowScheme: id => {
                showScheme(id);
            },
            onRefresh,
            onSaveHazards: async (added, removed): Promise<void> => {
                const { waterSourceNodeId } = selectedWaterSource;
                let refresh = false;
                if (added && added.length > 0) {
                    const data = added.map(h => ({
                        hazardTypeId: h.value,
                    }));
                    const action = addHazards(data, waterSourceNodeId);
                    await action(dispatch);
                    refresh = true;
                }
                if (removed && removed.length > 0) {
                    const data = removed.map(h => h.hazardNodeId);
                    const action = removeHazards(data, waterSourceNodeId);
                    await action(dispatch);
                    refresh = true;
                }
                if (refresh) {
                    onRefresh?.();
                }
            },
            onSavePumpTypes: async (values): Promise<void> => {
                const { waterSourceNodeId } = selectedWaterSource;
                if (values !== undefined) {
                    const action = savePumpTypes(waterSourceNodeId, values);
                    await action(dispatch);
                    onRefresh?.();
                }
            },
            setRenderPoints: (renderPoints): void => {
                dispatch(setRenderPoints(renderPoints));
            },
            editMapWaterSource: (mapWaterSource: IMapWaterSource): void => {
                dispatch(editMapWaterSource(mapWaterSource));
            },
            changeWaterSourceCoordinates,
            setIsEditingWaterSourceAborted: (isEditingWaterSourceAborted: boolean): void => {
                dispatch(setIsEditingWaterSourceAborted(isEditingWaterSourceAborted));
            },
            checkAccess,
            navigateToWaterSource: (): void => {
                const { location: { coordinates: { x, y } }, waterSourceNodeId } = selectedWaterSource;
                dispatch(navigateToLocationOnMap([x, y], false, waterSourceNodeId));
            }
        };

        return <WaterSourceControlBase {...props} />;
    }

    return <React.Fragment />;
};

export default WaterSourceControl;