import { IInspectionAction, IInspectionState, IInspection } from '../types/inspections';
import { isConnection } from '../types/helpers';
import { IConnection } from '../types/connections';


const defaultAction: IInspectionAction = {
    type: '@@INIT',
    inspections: [],
    inspectionsPageInfo: undefined
}

const defaultState = {
    inspections: [],
    selectedInspection: undefined,
    createInspectionSuccess: undefined,
    editInspectionSuccess: undefined,
    inspectionsPageInfo: undefined
}

export default (state: IInspectionState = defaultState, action: IInspectionAction = defaultAction): IInspectionState => {
    switch (action.type) {
        case "SET_REFRESH_INSPECTION":
            return {
                ...state,
                refresh: action.refresh
            };
        case 'SET_INSPECTIONS':
            return {
                ...state,
                inspections: action.inspections
            }

        case "ADD_INSPECTION_PAGE":
            if (action.inspectionPage) {
                const update: IConnection<IInspection>[] = [
                    ...(state.inspectionPages ?? []),
                    action.inspectionPage
                ];
                return {
                    ...state,
                    inspectionPages: update
                }
            }
            return state;

        case 'CLEAR_INSPECTIONS':
            return {
                ...state,
                inspections: []
            }

        case 'ADD_INSPECTIONS':
            if (action.newInspections && state.inspections) {
                const updatedInspections = [
                    ...action.newInspections,
                    ...(isConnection(state.inspections) ? state.inspections.items : state.inspections)
                ];

                return {
                    ...state,
                    inspections: updatedInspections
                }
            }

            return state;

        case 'EDIT_INSPECTION':
            if (action.editedInspection && state.inspections) {
                const existingInspectionNodeId = action.editedInspection.inspectionNodeId
                const inspections = isConnection(state.inspections) ? state.inspections.items : state.inspections;
                const updatedInspections: any = inspections.map((inspection) => inspection.inspectionNodeId === existingInspectionNodeId
                    ? action.editedInspection
                    : inspection
                );

                return {
                    ...state,
                    inspections: updatedInspections
                }
            }

            return state;

        case 'SET_SELECTED_INSPECTION':
            return {
                ...state,
                selectedInspection: action.selectedInspection
            }

        case "SET_ASSIGN_INSPECTOR_SUCCESS":
            return {
                ...state,
                assignInspectorSuccess: action.assignInspectorSuccess
            };

        case "SET_ASSIGN_INSPECTION_GROUP_SUCCESS":
            return {
                ...state,
                assignInspectionGroupSuccess: action.assignInspectionGroupSuccess
            };

        case 'SET_CREATE_INSPECTION_SUCCESS':
            return {
                ...state,
                createInspectionSuccess: action.createInspectionSuccess
            }

        case 'SET_EDIT_INSPECTION_SUCCESS':
            return {
                ...state,
                editInspectionSuccess: action.editInspectionSuccess
            }

        case "SET_REMOVE_INSPECTOR_SUCCESS":
            return {
                ...state,
                removeInspectorSuccess: action.removeInspectorSuccess
            }

        case "SET_REMOVE_INSPECTION_GROUP_SUCCESS":
            return {
                ...state,
                removeInspectionGroupSuccess: action.removeInspectionGroupSuccess
            };

        case 'ADD_PAGINATED_INSPECTIONS':
            return {
                ...state,
                inspections: action.inspections,
                inspectionsPageInfo: action.inspectionsPageInfo,
                inspectionsTotalCount: action.inspectionsTotalCount
            }
        default:
            return state;
    }
}
