import React from "react";
import cx from "classnames";

import { TransitionProps } from "@material-ui/core/transitions";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import CloseIcon from '@material-ui/icons/Close';

import useStyles from "./appDialog.styles";

interface AppDialogProps {
    readonly open: boolean;
    readonly appBar?: {
        readonly title?: string | React.ReactElement;
        readonly extended?: boolean;
    };
    readonly commit?: React.ReactElement;
    readonly disableGutters?: boolean;
    readonly onClose?: () => void;
}

const DialogTransition = React.forwardRef(function DialogTransition(props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>): JSX.Element {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TitleProps {
    readonly title?: string | React.ReactElement;
    readonly extended?: boolean;
    readonly styles: Record<"title", string>
}

const Title = ({ title, extended, styles }: TitleProps): JSX.Element => {
    if (title === undefined) {
        return <React.Fragment />;
    }
    return (
        <div className={cx(styles.title, { extended: extended })}>
            {typeof title === "string"
                ? <Typography variant="h6">{title}</Typography>
                : title
            }
        </div>
    )
};

const AppDialog = (props: React.PropsWithChildren<AppDialogProps>): JSX.Element => {
    const { open, appBar, commit, children, disableGutters, onClose } = props;

    const styles = useStyles();

    const handleClose = (): void => onClose?.();

    return (
        <Dialog fullScreen open={open} TransitionComponent={DialogTransition} onClose={handleClose}>
            <div className={styles.container}>
                <AppBar position="relative" className={styles.appBar}>
                    <Toolbar className={cx(styles.toolbar, { extended: appBar?.extended })}>
                        <IconButton edge="start" className={styles.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <Title title={appBar?.title} extended={appBar?.extended} styles={styles} />
                        <div className={cx(styles.actions, { extended: appBar?.extended })}>
                            {commit}
                        </div>
                    </Toolbar>
                </AppBar>
                <Container className={styles.content} disableGutters={disableGutters}>
                    {children ?? <React.Fragment />}
                </Container>
            </div>
        </Dialog>
    );
};

export type { AppDialogProps };
export default AppDialog;