export default `
fragment WaterSource on IWaterSource {
    waterSourceNodeId: id
    waterSourceId: externalId
    location {
        description
        coordinates {
            x
            y
        }
        address {
            streetDescription
            locality
            town
        }
    }
}
fragment Defect on Defect {
    defectNodeId: id
    defectId
    type {
        displayText
        value
    }
}
fragment Inspection on Inspection {
    inspectionNodeId: id
    inspectionId
    type { 
        displayText
        value
    }
}
query getRepairControl_Query($id: ID!) {
    node(id:$id) {
        ... on IWaterSource {
            repairs {
                repairNodeId: id          
                repairId
                waterSource {
                    ...WaterSource
                }
                defect {
                    ...Defect
                }
                inspection {
                    ...Inspection
                }
                repairCategory {
                    displayText
                    value
                }
                repairType {
                    displayText
                    value
                }
                repairVendor {
                    displayText
                    value
                }
                expectedCost
                actualCost
                costSaving
                isPaymentAuthorised
                workOrderRef
                purchaseOrderRef
                invoiceRef
                dateWorkCompleted
                dateInvoiced
                dateCreated
                datePaid
                cancelReason {
                    displayText
                    value
                }
                isOpen
                isWorkCompleted
                isInvoiced
                isPaymentAuthorised
                isPaid
                isCancelled
                lastEvent {
                    action
                    actor
                    timestamp
                }
                events {
                    action
                    actor
                    timestamp
                }
                notes {
                    noteNodeId: id
                    noteId
                    timestamp
                    author
                    content
                }
            }
        }
    }
}`;