import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { History, Location } from "history";
import { stringify } from "querystring";

import { isNumber } from "../../../lib/assertions";
import { NodeID, encodeNodeId } from "../../../lib/nodeIdentifier";
import { setRefreshInspection } from "../../../store/actions/inspections";
import { InspectionCategory } from "../../../store/types/inspections";
import { useRouterContext, RouterContextState } from "../components/RouterContext";
import { InspectionsParams } from "./useInspectionsParams";


const PATH_ROOT = "/";
const PATH_ADMIN_USERS = "/admin/users"
const PATH_ADMIN_ROLES = "/admin/roles"
const homeRoutes = new Set([PATH_ROOT, PATH_ADMIN_USERS, PATH_ADMIN_ROLES]);

interface RouteNavigation {
    readonly station: string | undefined;
    readonly route?: string;
}

interface CategoryNavigation<T extends InspectionCategory> {
    readonly category: T;
}

type InspectionNavigation<T extends InspectionCategory> = T extends "PLANNED"
    ? CategoryNavigation<T> & RouteNavigation
    : CategoryNavigation<T>;

interface AppBarState {
    readonly title?: string;
    readonly extended?: boolean
}

interface NavigationState {
    readonly appBar?: AppBarState;
}

interface Navigation {
    readonly appBar?: AppBarState;
    readonly goBack: () => void;
    readonly goHome: () => void;
    readonly gotoAdminRoles: () => void;
    readonly gotoAdminUsers: () => void;
    readonly gotoInspection: (nodeId: NodeID, tabIndex?: number) => void;
    readonly gotoPlannedTasks: (params: Pick<InspectionsParams, "route" | "station">, appBar: AppBarState) => void;
    readonly gotoSearch: () => void;
    readonly gotoStation: (id: NodeID, name: string) => void;
    readonly gotoStations: () => void;
    readonly gotoNearestWaterSources: () => void;
    readonly gotoNearestWaterSource: (waterSourceNodeId: string) => void;
    readonly gotoUnplannedTasks: (appBar: AppBarState) => void;
    readonly signOut: () => void;
}

const bindNavigation = (history: History, router: RouterContextState, location: Location) => {
    return (path: string, state?: NavigationState): void => {
        console.group("navigation");
        router.push(location);
        console.info("route(location)", location);
        history.push(path, state);
        console.info("history(path, state)", path, state);
        console.groupEnd();
    };
};

const useNavigation = (): Navigation => {
    const history = useHistory<NavigationState>();
    const location = useLocation<NavigationState>();
    const routerContext = useRouterContext();
    const dispatch = useDispatch();

    const goTo = useCallback(bindNavigation(history, routerContext, location), [history, routerContext, location]);
    const gotoInspections = useCallback(<T extends InspectionCategory>(params: InspectionNavigation<T>, appBar?: AppBarState): void => {
        const reducer = (current: Record<string, any>, entry: [string, any]): Record<string, any> => {
            const [key, value] = entry;
            return value
                ? { ...current, [key]: value }
                : current;
        };

        const query = Object.entries(params).reduce(reducer, {});
        const qs = stringify(query);
        goTo(`/inspections?${qs}`, { appBar });
    }, [goTo]);

    const { pathname, state } = location;
    const { appBar } = state ?? {};

    const isRoot = useMemo(() => pathname === PATH_ROOT, [pathname]);
    const isHome = useMemo(() => homeRoutes.has(pathname), [pathname]);
    const navigation = useMemo((): Navigation => ({
        appBar: isRoot ? { title: "Home " } : appBar,
        goBack: (): void => {
            if (isHome) {
                routerContext.clear();
            }
            else {
                const parent = routerContext.peek();
                if (parent) {
                    routerContext.pop();
                    history.push(parent);
                }
            }
        },
        goHome: (): void => {
            if (!isRoot) {
                goTo("/");
            }
        },
        gotoAdminRoles: (): void => {
            goTo("/admin/roles");
        },
        gotoAdminUsers: (): void => {
            goTo("/admin/users");
        },
        gotoInspection: (nodeId: NodeID, tabIndex?: number): void => {
            dispatch(setRefreshInspection(true));
            goTo(`/inspections/${encodeURIComponent(encodeNodeId(nodeId))}${tabIndex ? `?tab-index=${tabIndex}` : ""}`, { appBar: { title: "" } });
        },
        gotoPlannedTasks: (params, appBar): void => {
            const encode = (nodeId: NodeID | undefined): string | undefined => nodeId
                ? encodeNodeId(nodeId)
                : undefined;            
            gotoInspections({
                category: "PLANNED",
                route: encode(params.route),
                station: encode(params.station)
            }, appBar);
        },
        gotoSearch: (): void => {
            goTo("/search");
        },
        gotoStation: (id: NodeID, name: string): void => {
            const nodeId = encodeNodeId(id);
            if (isNumber(id.id)) {
                goTo(`/stations/${encodeURIComponent(nodeId)}`, { appBar: { title: name } });
                return;
            }
            gotoInspections({ category: "PLANNED", station: nodeId }, { title: name });
        },
        gotoStations: (): void => {
            goTo("/stations");
        },
        gotoNearestWaterSources: (): void => {
            goTo("/near-me");
        },
        gotoNearestWaterSource: (waterSourceNodeId: string): void => {
            goTo(`/near-me/water-source/${waterSourceNodeId}`, { appBar: { title: "" } });
        },
        gotoUnplannedTasks: (appBar): void => {
            gotoInspections({ category: "UNPLANNED" }, appBar);
        },
        signOut: (): void => {
            document.location.pathname = "/logout";
        }
    }), [appBar, isHome, isRoot, goTo, gotoInspections]);
    return navigation;
};

export type { InspectionsParams, Navigation, NavigationState };
export default useNavigation;