import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';

import { ColourOption, getRgb } from "../../../scss/colours";

interface DashboardCardProps {
    readonly color: ColourOption;
    readonly title: string;
    readonly description: string;
    readonly number?: number;
}

const getColour = (color: ColourOption, theme: Theme): string => {
    const rgb = getRgb(theme);
    switch (color) {
        case "SPARK":
        case "MIST":
            return rgb("DARKPETROL");
        case "FUSE":
            return theme.palette.common.white;
        default:
            return theme.palette.common.white;
    }
};

const useStyles = makeStyles(theme => {
    const rgb = getRgb(theme);
    return createStyles({
        card: (props: DashboardCardProps) => ({
            backgroundColor: rgb(props.color),
            borderRadius: "30px",
            boxShadow: "0 3px 10px black",
            display: "flex",
            height: "150px",
            width: "400px",
        }),
        leftPane: (props: DashboardCardProps) => ({
            borderBottomLeftRadius: "30px",
            borderTopLeftRadius: "30px",
            color: getColour(props.color, theme),
            padding: "20px",
            width: "60%"
        }),
        rightPane: (props: DashboardCardProps) => ({
            alignItems: "center",
            backgroundColor: theme.palette.common.white,
            borderBottomLeftRadius: "100%",
            borderBottomRightRadius: "30px",
            borderTopLeftRadius: "100%",
            borderTopRightRadius: "30px",
            color: rgb(props.color),
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            width: "40%"
        }),
        description: {

        },
        progress: {
            color: rgb("MIST")
        }
    });
});

const DashboardCard = (props: DashboardCardProps): JSX.Element => {
    const styles = useStyles(props);
    const { title, description, number } = props;
    return (
        <div className={styles.card}>
            <div className={styles.leftPane}>
                <Typography variant='h6' gutterBottom>{title}</Typography>
                <Typography variant='body1' className={styles.description}>{description}</Typography>
            </div>
            <div className={styles.rightPane}>
                <Typography variant="h2">{number ?? <CircularProgress className={styles.progress} />}</Typography>
            </div>
        </div>
    )
}

export type { DashboardCardProps };
export default DashboardCard;
