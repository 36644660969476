export default `mutation RemoveWaterPipesFromScheme($input: EditUpdateLinksInput!) {
  scheme {
      removeWaterPipes(input: $input) {
          clientMutationId
          hasErrors
          errors {
              message
              path
          }
          scheme {
              schemeNodeId: id
              events {
                action
                actor
                timestamp
              }
              waterPipes {
                  waterPipeNodeId: id
                  waterPipeId
                  isActive
                  path {
                      coordinates {
                          x
                          y
                      }
                  }
              }
          }
      }
  }
}`