import { equals } from "../../lib/nodeIdentifier";
import {
    Inspector,
    getInspectorNodeId,
    IInspection,
    ILookupItem,
    ITechnician,
    ICrew,
    getInspectorDisplayName
} from "../../store/types";
import { IDefectSummary, InspectionOutcome } from "./types";

export const canEnableCommand = (inspection: IInspection | undefined, inspector: Inspector | undefined): boolean => {
    if (inspection === undefined) {
        return false;
    }

    const isClosed = !inspection.isOpen;
    if (isClosed) {
        return false;
    }

    if (inspector === undefined) {
        return false;
    }

    const inspectorId = getInspectorNodeId(inspector);
    if (inspectorId === undefined) {
        return false;
    }

    const isOwnedByInspector = (): boolean => {
        const { technician: owner } = inspection;
        if (owner) {
            const ownerId = getInspectorNodeId(owner);
            if (ownerId) {
                return equals(ownerId, inspectorId);
            }
        }
        return false;
    };
    const isMemberOfOwningGroup = (): boolean => {
        const { inspectionGroup: group } = inspection;
        if (group) {
            const isMember = group.members.some(member => {
                const memberId = getInspectorNodeId(member);
                return memberId && equals(memberId, inspectorId);
            });
            return isMember;
        }
        return false;
    };

    const enabled = isOwnedByInspector() || isMemberOfOwningGroup();
    return enabled;
};

export const formatInspector = (inspector: ITechnician | ICrew | undefined): string | undefined => {
    return inspector
        ? getInspectorDisplayName(inspector)
        : undefined;
};

export const formatOutcome = (outcome: InspectionOutcome): string => {
    switch (outcome) {
        case InspectionOutcome.Fail:
            return "Fail";
        case InspectionOutcome.Pass:
            return "Pass";
    }
};

export const formatResult = (result: ILookupItem | undefined): string | undefined => {
    switch (result?.value) {
        case 1: return "Failed";
        case 2: return "Passed";
        default: return undefined;
    }
};

export const formatStatus = (isOpen: boolean, isCompleted: boolean, isCancelled: boolean): string => {
    if (isOpen) {
        return "Open";
    }
    if (isCompleted) {
        return "Completed";
    }
    if (isCancelled) {
        return "Cancelled";
    }
    return "Unknown";
};

export const formatText = (source: string | undefined): string => {
    const s = source?.trim() ?? "";
    return s.length > 0 ? s : "-";
};

export const formatValue = (source: string | number | ILookupItem | undefined): string | undefined => {
    if (typeof source === "undefined" || source === null) {
        return undefined;
    }
    if (typeof source === "string") {
        return source;
    }
    if (typeof source === "number") {
        return source.toString();
    }
    if ("displayText" in source) {
        return source.displayText;
    }
    return undefined;
};

export const formatMultiLineText = (string: string | undefined): string[] => {
    if (string) {
        const lines = string?.split('\n');
        return lines;
    }
    return ["-"];
};
