export default `
mutation createDefect_Mutation($defect: AddDefectInput!) {
    addDefect(input:$defect) {
        defect {
            defectNodeId: id
            defectId
            type {
                displayText
                value
            }
            cancelReason {
                displayText
                value
            }
            closeReason {
                displayText
                value
            }
            dateReported
            reportedBy
            isOpen
            isResolved
            lastEvent {
                timestamp
                action
                actor
            }
            parentInspection {
                id
            }
            waterSource {
                waterSourceNodeId: id
                isDefective {
                    value
                    isOverridden
                }
                isOperable {
                    value
                    isOverridden
                }
            }
            events {
                timestamp
                action
                actor
            }
        }
        hasErrors
        errors {
            message
            path
        }
    }
}
`;