import React from "react";

import useSelector from '../../../../../lib/hooks/useSelector';
import WaterSourceListTabBase, { WaterSourceListTabProps } from './waterSourceListTab';

type ConnectedProps = Pick<WaterSourceListTabProps, "mapClickAction">;

const WaterSourceListTab = (props: Omit<WaterSourceListTabProps, keyof ConnectedProps>): JSX.Element => {
    const mapClickAction = useSelector(state => state.map.mapClickAction);
    return <WaterSourceListTabBase {...props} mapClickAction={mapClickAction} />
};

export default WaterSourceListTab;
