import { useState, useEffect } from "react";

import olBaseLayer from "ol/layer/Base";
import olSource from "ol/source/Source";
import olTileLayer from "ol/layer/Tile";

import { tileServerUrl, PrimaryTileServer } from "../../store/types";
import { MapLayerFactory } from "./MapLayerFactory";
import { setMapFallbackWarning } from "../../store/actions/map";
import { useDispatch } from "react-redux";

interface FallbackSource {
    readonly source: olSource;
    readonly showWarning: true;
}
type MapSource = olSource | FallbackSource | undefined;
interface MapLayer {
    readonly tileLayer: olBaseLayer;
    readonly showWarning: boolean;
}

const createMapSource = async (tileServer: PrimaryTileServer): Promise<MapSource> => {
    const { type, params, ...address } = tileServer;
    const url = tileServerUrl(address);
    const source = await MapLayerFactory.CreateMapSource(type, url, params)
        .then((source) => {
            console.log("Create map source.");
            const { fallback } = tileServer;
            if (typeof fallback === "boolean" && fallback) {
                return {
                    source,
                    showWarning: fallback
                };
            }
            return source;
        })
        .catch(async (error): Promise<FallbackSource | undefined> => {
            console.error("Cannot create map source using primary map server configuration.", error, tileServer);
            const { fallback } = tileServer;
            if (fallback !== undefined && typeof fallback !== "boolean") {
                const source = await MapLayerFactory.CreateMapSource(fallback.type, fallback.url, fallback.params);
                return {
                    source,
                    showWarning: true
                };
            }
            return undefined;
        });
    return source;
};

const createMapLayer = async (tileServer: PrimaryTileServer): Promise<MapLayer> => {
    const { type } = tileServer;
    const source = await createMapSource(tileServer);
    if (source === undefined) {
        return {
            tileLayer: new olTileLayer({}),
            showWarning: true
        };
    }

    if (source instanceof olSource) {
        const layer = MapLayerFactory.CreateMapLayer(type, source);
        return {
            tileLayer: layer,
            showWarning: false
        };
    }

    const layer = MapLayerFactory.CreateMapLayer(type, source.source);
    return {
        tileLayer: layer,
        showWarning: source.showWarning
    };
};

const useTileLayer = (tileServer: PrimaryTileServer): MapLayer | undefined => {
    const [tileLayer, setTileLayer] = useState<MapLayer>();
    const dispatch = useDispatch();
    useEffect(() => {
        createMapLayer(tileServer)
            .then(layer => setTileLayer(layer))
            .catch(error => {
                console.error("Cannot create base map layer using primary map server configuration.", error);
                dispatch(setMapFallbackWarning(true));
                setTileLayer(undefined);
            });
    }, [tileServer]);
    return tileLayer;
};

export type { MapSource, MapLayer };
export { createMapLayer };
export default useTileLayer;