import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Lines from "../../../labelledField/components/Lines";

interface IFieldProps {
    readonly label: string;
    readonly text: string | string[];
    readonly className?: string;
    readonly renderStart?: () => JSX.Element | null;
    readonly renderEnd?: () => JSX.Element | null;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(1.5)
    },
    label: {
        color: theme.palette.grey[400],
    },
    value: {
        color: theme.palette.primary.contrastText
    }
}));

const Field = ({ label, text, className, renderStart, renderEnd }: IFieldProps): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={cx(styles.root, className)}>
            <Typography variant="caption" className={styles.label}>{label}</Typography>
            {renderStart?.()}
            <Typography variant="body2" className={styles.value}>
                <Lines>{text}</Lines>
            </Typography>
            {renderEnd?.()}
        </div>
    );
};

export type { IFieldProps };
export default Field;