import { IPoint } from "../../store/types";
import makeCoordinateFormatter from "./makeCoordinateFormatter";

const formatCoordinate = makeCoordinateFormatter(a => a, 6);

const format = (coordinates: IPoint): string => {
    const x = formatCoordinate(coordinates.x);
    const y = formatCoordinate(coordinates.y);
    return `${x}, ${y}`;
};

export default format;