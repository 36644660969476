import { v4 as guid } from "uuid";

import executeQuery from "../../../lib/executeQuery";
import removeSearchMutation from "../../../store/actions/graphQL/removeSavedSearch";
import { SearchEntity } from "../searchSelector";
import { SearchMutationResult, DeleteSearchVariables, SearchMutations } from "./types";
import { makeResolveMutation } from "./utils";

const resolveMutation = makeResolveMutation("remove");

const deleteSearches = async (searchPath: SearchEntity, nodeIds: string[]): Promise<SearchMutationResult> => {
    const variables: DeleteSearchVariables = {
        input: {
            clientMutationId: guid(),
            searchPath,
            data: nodeIds
        }
    };
    return executeQuery<SearchMutations>(removeSearchMutation, variables)
        .then(resolveMutation);
};

export default deleteSearches;