import React from "react";

import Box from "@material-ui/core/Box";

import NavBackButton from "./NavBackButton";

interface ViewPrimaryActionProps {
    readonly backButton?: boolean;
    readonly action?: React.ReactNode;
}

const Placeholder = (): JSX.Element => {
    return <Box position="relative" minHeight={48} minWidth={0} ml={-1.5} />
};

const ViewPrimaryAction = ({ backButton, action }: ViewPrimaryActionProps): JSX.Element => {
    if (React.isValidElement(action)) {
        return action;
    }
    if (action !== undefined && action !== null) {
        return typeof action === "boolean" && action
            ? <NavBackButton/> 
            : <React.Fragment>{action}</React.Fragment>;
    }
    if (backButton) {
        return <NavBackButton />;
    }
    return <Placeholder />;
};

export type { ViewPrimaryActionProps };
export default ViewPrimaryAction;