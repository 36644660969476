import React from "react";
import { Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import useAppRoutes from "./useAppRoutes";
import Projection from "../../components/map/Projection";
import LocaleProvider from "../../components/locale/LocaleProvider";

const AppRouter = (): JSX.Element => {
    const routes = useAppRoutes();
    return (
        <LocaleProvider>
            <Projection>
                <MuiPickersUtilsProvider utils={MomentUtils} >
                    <Router>
                        <Switch>
                            {routes}
                        </Switch>
                    </Router>
                </MuiPickersUtilsProvider>
            </Projection>
        </LocaleProvider>
    );
};

export default AppRouter;