import React from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames, IconName } from "@blueprintjs/icons";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Box, { BoxProps } from "@material-ui/core/Box";

import { Severity, Severities } from ".";

export interface IRiskSeverityProps {
    readonly severity: Severity;
}

const useStyles = makeStyles(createStyles({
    root: {
        display: "inline-flex",
        flexDirection: "row",
    },
    high: {
        color: red[600]
    },
    medium: {
        color: amber[500]
    },
    low: {
        color: green[600]
    },
}));

const getIcon = (severity: Severity): IconName => {
    switch (severity) {
        case Severities.HIGH:
            return IconNames.ARROW_UP;
        case Severities.MEDIUM:
            return IconNames.EQUALS;
        case Severities.LOW:
            return IconNames.ARROW_DOWN;
        default:
            return IconNames.BLANK;
    }
};

const getStyle = (severity: Severity, styles: Record<"medium" | "high" | "low", string>): string => {
    switch (severity) {
        case Severities.HIGH:
            return styles.high;
        case Severities.MEDIUM:
            return styles.medium;
        case Severities.LOW:
            return styles.low;
        default:
            return "";
    }
};

const RiskSeverity = (props: IRiskSeverityProps & BoxProps): JSX.Element => {
    const { severity, className, ...box } = props;
    const styles = useStyles();
    const iconName = getIcon(severity);
    const style = getStyle(severity, styles);
    return (
        <Box component="span" className={cx(styles.root, style, className)} {...box}>
            <Icon icon={iconName} htmlTitle={severity} />
        </Box>
    );
};

export default React.memo(RiskSeverity);