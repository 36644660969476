import React from "react";

import useDefects from "../../appWindowContext/hooks/useDefects";
import SelectDefectsDialogBase, { ISelectDefectsDialogProps } from './selectDefectsDialog';

const SelectDefectsDialog = (props: Pick<ISelectDefectsDialogProps, "isOpen" | "onClose" | "onSave"> & { readonly waterSourceNodeId?: string; }): JSX.Element => {
    const { waterSourceNodeId, ...otherProps } = props;
    const [defects] = useDefects(waterSourceNodeId)
    const combined: ISelectDefectsDialogProps = {
        ...otherProps,
        defects: props.isOpen
            ? defects.filter(defect => defect.isOpen)
            : []
    };
    return <SelectDefectsDialogBase {...combined} />;
};

export default SelectDefectsDialog;