import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AppDialogInput } from "../../../components/shared/appDialog";
import { guid } from "../../../lib/Utils";

interface AttachmentDescriptionChange {
    (tempId: string | undefined, description: string): void;
}

interface AttachmentDescriptionProps {
    readonly tempId?: string;
    readonly description?: string;
    readonly editing?: boolean;
    readonly onChange?: AttachmentDescriptionChange
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.grey[400],
        marginRight: theme.spacing(3),
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        whiteSpace: "nowrap",
    }
}));

const AttachmentDescription = (props: AttachmentDescriptionProps): JSX.Element => {
    const styles = useStyles();
    const { tempId, description, editing, onChange } = props;
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = event.target.value;
        onChange?.(tempId, value);
    };
    return editing
        ? <AppDialogInput value={description} id={`attachmentItem-description-${tempId ?? guid()}`} placeholder="Description" onChange={handleChange} />
        : <Typography variant="caption" className={styles.root}>{description}</Typography>;
};

export type { AttachmentDescriptionChange, AttachmentDescriptionProps };
export default AttachmentDescription;