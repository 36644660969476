import { IDefectAction, IDefectState } from '../types/defects';

const defaultAction: IDefectAction = {
    type: '@@INIT',
    defects: []
}

const defaultState = {
    defects: [],
    selectedDefect: undefined,
    createDefectSuccess: undefined,
    editDefectSuccess: undefined,
    reviewedDefects: {
        reviewedDefects: [],
        notReviewedDefects: []
    }
}

export default (state: IDefectState = defaultState, action: IDefectAction = defaultAction): IDefectState => {
    switch (action.type) {
        case 'SET_DEFECTS':
            return {
                ...state,
                defects: action.defects
            }

        case 'CLEAR_DEFECTS':
            return {
                ...state,
                defects: []
            }

        case 'ADD_DEFECT':
            if (action.newDefect && state.defects) {
                const updatedDefects = [
                    action.newDefect,
                    ...state.defects
                ];

                return {
                    ...state,
                    defects: updatedDefects
                }
            }
            return state;

        case "SET_PAGINATED_DEFECTS":
            return {
                ...state,
                currentPage: action.defectPage
            };

        case "CLEAR_PAGINATED_DEFECTS":
            return {
                ...state,
                currentPage: undefined
            };

        case 'EDIT_DEFECT':
            if (action.editedDefect && state.defects) {
                const existingDefectNodeId = action.editedDefect.defectNodeId
                const updatedDefects: any = state.defects.map((defect) => {
                    if (defect.defectNodeId === existingDefectNodeId) {
                        return action.editedDefect
                    }

                    return defect;
                });

                return {
                    ...state,
                    defects: updatedDefects
                }
            }

            return state;
        case 'SET_SELECTED_DEFECT':
            return {
                ...state,
                selectedDefect: action.selectedDefect
            }

        case 'SET_CREATE_DEFECT_SUCCESS':
            return {
                ...state,
                createDefectSuccess: action.createDefectSuccess
            }

        case 'SET_EDIT_DEFECT_SUCCESS':
            return {
                ...state,
                editDefectSuccess: action.editDefectSuccess
            }

        case 'SET_REVIEWED_DEFECTS':
            return {
                ...state,
                reviewedDefects: {
                    reviewedDefects: action.reviewedDefects ? action.reviewedDefects[0] : [],
                    notReviewedDefects: action.reviewedDefects ? action.reviewedDefects[1] : []
                }
            }

        default:
            return state;
    }
}

