import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Repair, IDefect } from '../../../store/types';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogContent from '../../dialog/dialogContent';
import DialogTitle from '../../dialog/dialogTitle';

interface CloseRepairDialogProps {
    readonly repair: Repair;
    readonly isOpen: boolean;
    readonly onClose?: () => void;
    readonly onCompleteRepair?: () => void;
}

const DefectText = ({ defect }: { readonly defect?: Pick<IDefect, "defectId" | "type"> }): JSX.Element => {
    if (defect) {
        return (
            <Typography variant="caption" component="p">
                Defect {defect.defectId}, {defect.type.displayText}
            </Typography>
        );
    }
    return <React.Fragment />;
};

const RepairText = ({ repair }: Pick<CloseRepairDialogProps, "repair">): JSX.Element => {
    const type = repair.repairType?.displayText;
    return (
        <Typography variant="caption" component="p">
            Repair {repair.repairId}{type ? ", " : ""}{type}
        </Typography>
    );
};

const CloseRepairDialog = (props: CloseRepairDialogProps): JSX.Element => {
    const { repair, isOpen, onClose, onCompleteRepair } = props;

    const handleClose = (): void => {
        onClose?.();
    };

    const handleCompleteRepair = (): void => {
        handleClose();
        onCompleteRepair?.();
    };

    return (
        <Dialog fullWidth maxWidth="xs" overflowY="unset" open={isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Close Repair?</DialogTitle>
            <DialogContent overflowY="unset">
                <Typography variant="body2" gutterBottom>
                    Are you sure you want to close the repair?
                </Typography>
                <Typography variant="caption" component="p">
                    Water source {repair.waterSource.waterSourceId}
                </Typography>
                <DefectText defect={repair.defect} />
                <RepairText repair={repair} />
            </DialogContent>
            <DialogActions>
                <button id="closeRepair-cancel-button" className="action-button action-button--cancel" onClick={handleClose}>No</button>
                <button id="closeRepair-save-button" className="action-button" onClick={handleCompleteRepair}>Yes</button>
            </DialogActions>
        </Dialog>
    );
};

export type { CloseRepairDialogProps };
export default CloseRepairDialog;