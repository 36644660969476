import { ILookupItem } from "../../store/types";

const prepareTagsUpdate = (updated: ILookupItem[], current: ILookupItem[]): [number[], number[]] => {
    const updatedIds = updated.map(t => t.value);
    const currentIds = current.map(t => t.value);
    const added = updatedIds.filter(u => currentIds.every(c => c !== u));
    const removed = currentIds.filter(c => updatedIds.every(u => u !== c));
    return [added, removed];
};

export default prepareTagsUpdate;