import React, { useState, useEffect } from 'react';
import { InputGroup, IInputGroupProps, HTMLInputProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import cls from "classnames";

import "./QuickSearchInput.scss";

interface IQuickSearchInputProps {
    readonly input: string;
    readonly isInline?: boolean;
    readonly valid: boolean;
    readonly inputRef: (ref: HTMLInputElement | null) => void;
    readonly onClear: () => void;
    readonly onFocus: () => void;
    readonly onSearch: (value: string) => void;
}

const QuickSearchInput = (props: IQuickSearchInputProps): JSX.Element => {
    const [input, setInput] = useState("");

    useEffect(() => {
        setInput(props.input);
    }, [props.input]);

    const handleInputRef = (value: HTMLInputElement | null): any => {
        props.inputRef(value);
    };

    const handleInputChange = (event: React.FormEvent<HTMLElement> & React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        if (input !== value) {
            setInput(value);
        }
        if (value === "") {
            props.onClear();
        }
    };
    const handleInputFocus = (): void => {
        props.onFocus();
    };
    const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const validSubmitKeys = ['Enter'];
        if (validSubmitKeys.findIndex((validKey) => validKey === event.key) > -1) {
            props.onSearch(input);
        }
    };

    const inputProps: IInputGroupProps & HTMLInputProps = {
        inputRef: handleInputRef,
        type: "search",
        className: cls("QuickSearchInput_root", {
            "QuickSearchInput_notInline": !props.isInline,
            "QuickSearchInput_invalid": !props.isInline && !props.valid,
            "QuickSearchInput_inline_invalid": props.isInline && !props.valid
        }),
        leftIcon: IconNames.SEARCH,
        placeholder: "Search",
        value: input,
        onChange: handleInputChange,
        onFocus: handleInputFocus,
        onKeyPress: handleInputKeyPress
    };
    return <InputGroup {...inputProps} />;
};

export type { IQuickSearchInputProps };
export default QuickSearchInput;
