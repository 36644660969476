import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AddressDetailsData } from "../components/address";
import { setError } from "../store/actions/errors";
import { AddressNode } from "../store/types";
import { setLoadingToast, setSuccessToast } from "../store/actions/app";
import executeQuery from "../lib/executeQuery";
import { guid } from "../lib/Utils";
import createAddress from "./graphql/createAddress";
import { AddAddressRequest, CreateAddressResponse } from "./types";

export const startCreateAddress = (data: AddressDetailsData) => {
    return async (dispatch: ThunkDispatch<any, unknown, AnyAction>): Promise<AddressNode | undefined> => {
        try {
            dispatch(setLoadingToast("Creating new address record..."));
            const variables: AddAddressRequest = {
                input: {
                    clientMutationId: guid(),
                    data: {
                        paon: {
                            description: data.description
                        },
                        streetDescription: data.streetDescription,
                        locality: data.locality,
                        town: data.town,
                        postCode: data.postCode
                    }
                }
            };
            const response = await executeQuery<CreateAddressResponse>(createAddress, variables);
            const { address } = response?.gis.address?.create ?? {};
            if (address) {
                dispatch(setSuccessToast("Address record created."));
                return address;
            }
            const errorMessages = response?.gis.address?.create.errors.map(e => e.message).join(" ")
            dispatch(setError("Error updating address record.", new Error(errorMessages)));
        } catch (error) {
            dispatch(setError("Error creating address.", error));
        }
        return undefined;
    };
};
