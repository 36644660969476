import React from "react";
import { isEmpty } from "lodash";

import Box from "@material-ui/core/Box";

import { Lookup } from "../../../../../../lib/lookups";
import { ILookupItem, IMeasurement, Owner } from "../../../../../../store/types";
import Select from "../../../../../forms/select";
import SelectItem from "../../../../../forms/selectItem";
import TextInput from "../../../../../forms/textInput";
import { hasValue, isHydrant } from "../../../utils";
import { HydrantDetails } from "../readonly";
import Optional from "../../../../../shared/Optional";
import { AppDialogMultiSelect } from "../../../../../shared/appDialog";
import MutliSelectOptions from "../../../MultiSelectOptions";

enum SelectField {
    CLASSIFICATION = "classification",
    STATION = "station",
    SURFACE = "surface",
    MAINS_SIZE = "mainsSizeValue",
    RISK_SEVERITY = "riskSeverity",
    PLATE_LOCATION = "plateLocation"
}

enum TextField {
    FLOW_RATE = "flowRate",
    PRESSURE = "pressure",
    MAINS_SIZE = "mainsSizeUnits",
    PLATE_DISTANCE = "plateDistance",
    ADDITIONAL_INFO = "additionalInfo"
}

type EditFields = HydrantDetails;
type EditKeys = keyof EditFields;

interface EditorProps extends HydrantDetails, Pick<Owner, "category"> {
    readonly classifications: Lookup;
    readonly mainsSizeUnits: Lookup;
    readonly stations: Lookup;
    readonly surfaces: Lookup;
    readonly riskSeverities: Lookup;
    readonly tagOptions: Lookup;
    readonly plateLocations: Lookup;
    readonly native?: boolean;
    readonly onChange?: (key: EditKeys, value: EditFields[EditKeys]) => void;
}

const makeMainsSize = (current: IMeasurement | undefined, key: keyof IMeasurement, value: IMeasurement[keyof IMeasurement]): IMeasurement | undefined => {
    if (current) {
        if (hasValue(value)) {
            return { ...current, [key]: value };
        }
        delete current[key];
        return isEmpty(current)
            ? undefined
            : current;
    }
    return hasValue(value)
        ? { [key]: value }
        : undefined;
};

const Editor = (props: EditorProps): JSX.Element => {
    const { category, classifications, mainsSizeUnits, stations, surfaces, plateLocations, native, onChange, riskSeverities, tagOptions, ...other } = props;

    const handleSelectChange = (field: SelectField) => {
        return (event: React.ChangeEvent<{ readonly value: unknown; }>): void => {
            const getItem = (lookup: Lookup): ILookupItem | undefined => {
                const { value } = event.target;
                if (typeof value === "string" && value) {
                    return lookup.getItem(Number(value));
                }
                if (typeof value === "number") {
                    return lookup.getItem(value);
                }
                return undefined;
            }
            switch (field) {
                case SelectField.CLASSIFICATION:
                    onChange?.("classification", getItem(classifications));
                    return;
                case SelectField.MAINS_SIZE:
                    onChange?.("mainsSize", makeMainsSize(other.mainsSize, "unit", getItem(mainsSizeUnits)))
                    return;
                case SelectField.PLATE_LOCATION:
                    onChange?.("plateLocation", getItem(plateLocations));
                    return;
                case SelectField.STATION:
                    onChange?.("station", getItem(stations));
                    return;
                case SelectField.SURFACE:
                    onChange?.("surface", getItem(surfaces));
                    return;
                case SelectField.RISK_SEVERITY:
                    onChange?.("riskSeverity", getItem(riskSeverities));
                    return;
            }
        };
    };

    const handleTagsChange = (values: number[]): void => {
        const selectedTags: ILookupItem[] = [];
        values.forEach(value => {
            const item = tagOptions.getItem(value);
            if (item) {
                selectedTags.push(item);
            }
        });
        onChange?.("tags", selectedTags);
    };

    const handleTextChange = (field: TextField) => {
        return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            const { value } = event.target;
            const getNumber = (): number | undefined => {
                if (value === undefined) {
                    return undefined;
                }
                if (typeof value === "string" && value != "") {
                    return Number(value);
                }
                if (typeof value === "number") {
                    return value;
                }
                return undefined;
            };
            const getString = (): string | undefined => {
                if (value === undefined) {
                    return undefined;
                }
                if (typeof value === "string") {
                    return value;
                }
                return undefined;
            };
            switch (field) {
                case TextField.FLOW_RATE:
                    onChange?.("flowRate", getNumber());
                    return;
                case TextField.PRESSURE:
                    onChange?.("pressure", getNumber());
                    return;
                case TextField.MAINS_SIZE:
                    onChange?.("mainsSize", makeMainsSize(other.mainsSize, "value", getNumber()));
                    return;
                case TextField.PLATE_DISTANCE:
                    onChange?.("plateDistance", getNumber());
                    return;
                case TextField.ADDITIONAL_INFO:
                    onChange?.("additionalInfo", getString())
            }
        };
    };

    const showField = isHydrant(category);
    return (
        <React.Fragment>
            <AppDialogMultiSelect
                value={other.tags?.map(tag => tag.value)}
                id="waterSource-tags"
                label="Tags"
                native={native}
                isWhiteLabel
                onChange={handleTagsChange}
            >
                <MutliSelectOptions items={tagOptions} isMobile={native} />
            </AppDialogMultiSelect>
            <Select value={other.classification?.value} id="waterSource-classification" label="Classification" placeholder="Please select..." native={native} onChange={handleSelectChange(SelectField.CLASSIFICATION)}>
                {classifications.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
            <Select value={other.station?.value} id="waterSource-station" label="Station" placeholder="Please select..." native={native} onChange={handleSelectChange(SelectField.STATION)}>
                {stations.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
            <Select value={other.riskSeverity?.value} id="waterSource-riskSeverity" label="Risk Severity" placeholder="Please select..." native={native} onChange={handleSelectChange(SelectField.RISK_SEVERITY)}>
                {riskSeverities.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
            <Optional hidden={!showField} >
                <Select value={other.surface?.value} id="waterSource-surface" label="Surface" placeholder="Please select..." native={native} onChange={handleSelectChange(SelectField.SURFACE)}>
                    {surfaces.map(item => {
                        return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                    })}
                </Select>
                <TextInput value={other.flowRate} id="waterSource-flowRate" type="number" label="Flow rate" onChange={handleTextChange(TextField.FLOW_RATE)} />
                <TextInput value={other.pressure} id="waterSource-pressure" type="number" label="Pressure" onChange={handleTextChange(TextField.PRESSURE)} />
                <Box display="flex" flexDirection="row">
                    <TextInput value={other.mainsSize?.value} id="waterSource-mainsSize" type="number" label="Mains size" onChange={handleTextChange(TextField.MAINS_SIZE)} />
                    <Box mr={2} />
                    <Select value={other.mainsSize?.unit?.value} id="waterSource-mainsSize-unit" label="Mains size units" placeholder="Please select..." native={native} onChange={handleSelectChange(SelectField.MAINS_SIZE)}>
                        {mainsSizeUnits.map(item => {
                            return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                        })}
                    </Select>
                </Box>
                <Select value={other.plateLocation?.value} id="waterSource-plateLocation" label="Plate Location" placeholder="Please select..." native={native} onChange={handleSelectChange(SelectField.PLATE_LOCATION)}>
                    {plateLocations.map(item => {
                        return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>
                    })}
                </Select>
                <TextInput value={other.plateDistance} id="waterSource-plateDistance" type="number" label="Plate Distance" onChange={handleTextChange(TextField.PLATE_DISTANCE)} />
            </Optional>
            <TextInput value={other.additionalInfo} id="waterSource-additionalInfo" label="Additional Information" multiline onChange={handleTextChange(TextField.ADDITIONAL_INFO)} />
        </React.Fragment>
    );
};

export type { EditorProps, EditKeys, EditFields };
export default Editor; 