import React from 'react'
import { fileManager } from "@3tc/shared-components-ts";
import cx from "classnames";

import { useRoleChecks } from '../../../auth/hooks';
import { IAccess, IGlobalToast, ToastType } from '../../../store/types/app';

import './FileUploader.scss';
import { RoleNames } from '../../../auth/roles';

interface IFileUploaderProps {
    readonly fileStoreToken: string;
    readonly entity: string;
    readonly entityId: string;
    readonly accessRoles: IAccess[];
    readonly setGlobalToast: (toast?: IGlobalToast) => void;
}

const FileUploader = (props: IFileUploaderProps): JSX.Element => {
    const { hasAny } = useRoleChecks();

    const setToast = (toastType?: ToastType, message?: string): void => {
        const globalToast: IGlobalToast = {
            type: toastType && toastType,
            message: message && message,
            showToast: toastType && message ? true : false,
            timeout: 3000
        }
        props.setGlobalToast(globalToast);
    };

    const fileAccess = hasAny([
        RoleNames.FILES_DELETE,
        RoleNames.FILES_EDIT,
        RoleNames.FILES_VIEW,
        RoleNames.SECURE_FILES_DELETE,
        RoleNames.SECURE_FILES_EDIT,
        RoleNames.SECURE_FILES_VIEW
    ]);
    return (
        <React.Fragment>
            {fileAccess &&
                <fileManager.v2.FileManager
                    fileStoreToken={props.fileStoreToken}
                    entity={props.entity}
                    entityId={Number(props.entityId)}
                    setToast={setToast}
                    accessRoles={props.accessRoles}
                    portalClassName={cx("wm-dialog-portal", "file-dialog")}
                />
            }
        </React.Fragment>
    )
};

export type { IFileUploaderProps };
export default FileUploader;
