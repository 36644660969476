import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "querystring";

const useQueryString = (): Record<string, string | string[]> => {
    const location = useLocation();
    const { search } = location;
    const qs = useMemo(() => {
        const params = search.startsWith("?")
            ? search.substring(1)
            : search;
        return parse(params)
    }, [search]);
    return qs;
};

export default useQueryString;