import React, { Component } from 'react';

import MapBrowserPointerEvent from 'ol/MapBrowserPointerEvent';

import { waterSourceStyleInfo, waterSourceFeature } from '../../lib/map/Map';
import { IMapWaterSource, LookupItem, ITileServer, ICoordinate } from '../../store/types';
import { SelectOption } from '../labelledField';
import CadMap from '../map/desktop';
import styles from "./locationMap.module.scss";

export interface ILocationMapProps {
    readonly mapServer: ITileServer;
    readonly mapCentre: [number, number];
    readonly waterSourceStatus: SelectOption | undefined;
    readonly selectedLocation: [number, number] | undefined;
    readonly clearCreateFromMapLocation: () => void;
    readonly onChange?: (coordinates: [number, number]) => void;
}

const createPlaceholder = (coordinates: ICoordinate, status: SelectOption | undefined): IMapWaterSource => ({
    waterSourceNodeId: "",
    waterSourceId: "",
    category: LookupItem(0, "UNKNOWN", 4),
    classification: undefined,
    isActive: true,
    isDefective: {
        value: false,
        isOverridden: false
    },
    isOperable: {
        value: true,
        isOverridden: false
    },
    location: {
        coordinates
    },
    status: status ? LookupItem(Number(status.value), status.displayText, status.sortOrder) : undefined,
    show: true,
});

const renderFeature = (mapCentre: [number, number]) =>
    (context: CadMap): void => {
        const waterSource = context.props.data.waterSource;
        if (waterSource?.location) {
            const coordinate = [waterSource.location.coordinates.x, waterSource.location.coordinates.y];
            // Only render the WS if its coordinates are not at the centre of the map (the user hasnt chosen a position)
            if (coordinate[0] !== mapCentre[0] && coordinate[1] !== mapCentre[1]) {
                const { category, status, inspectionCount, tags } = waterSource;
                const styles = waterSourceStyleInfo(true, true, false, false, category, status, inspectionCount, tags);
                const feature = waterSourceFeature(coordinate, waterSource, styles, context.styleCache);
                context.addFeature(feature);

                setTimeout(() => {
                    context.gotoPoint(coordinate)
                }, 10)
            }
        }
    };

class LocationMap extends Component<ILocationMapProps> {
    public render(): JSX.Element {
        const { mapServer, mapCentre, waterSourceStatus, selectedLocation, onChange } = this.props;

        const coordinates = selectedLocation
            ? { x: selectedLocation[0], y: selectedLocation[1] }
            : { x: mapCentre[0], y: mapCentre[1] };
        console.log("RENDER", coordinates);
        const placeholder = createPlaceholder(coordinates, waterSourceStatus);

        const handleMapClick = (evt: MapBrowserPointerEvent): void => {
            onChange?.([evt.coordinate[0], evt.coordinate[1]])
        };

        return (
            <div className={styles.locationMapContainer}>
                <CadMap
                    mapTileServer={mapServer}
                    mapCentre={coordinates}
                    data={{ waterSource: placeholder }}
                    animateTo={[coordinates.x, coordinates.y]}
                    pointRenderHandler={renderFeature(mapCentre)}
                    clickHandler={handleMapClick}
                />
            </div>
        );
    }

    public componentWillUnmount(): void {
        this.props.clearCreateFromMapLocation();
    }
}

export default LocationMap;
