import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { SelectProps, PropsWithEditing } from "../types";

const WaterSourceOrganisation = ({ editing, options, groups, value, onClear, onSelect }: PropsWithEditing<SelectProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "water-source-organisation",
        label: "Organisation",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value,
        selectOptions: options.filter(organisation => organisation.enabled),
        selectOptionGroups: groups,
        editing: editing ?? false,
        onChange: value => onSelect?.(value),
        onClear
    };
    return <LabelledField {...props} />;
};

export default WaterSourceOrganisation;