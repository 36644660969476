import React from 'react';
import { useParams, useLocation } from "react-router-dom";
import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";

interface IInspectionParams {
    readonly nodeId?: NodeID | undefined;
    readonly tabIndex?: number;
}

const useQuery = (): URLSearchParams => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useInspectionParams = (): IInspectionParams => {
    const query = useQuery();
    const tabIndex = query.get("tab-index");
    const { inspectionId } = useParams<{ inspectionId?: string }>();
    return {
        nodeId: inspectionId
        ? parseNodeId(decodeURIComponent(inspectionId))
        : undefined,
        tabIndex: tabIndex ? Number(tabIndex) : undefined
    }
};

export default useInspectionParams;