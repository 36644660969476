import React from 'react';
import ReactStreetView from 'react-streetview';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { IStreetView } from '../../store/types';
import Dialog from '../dialog/dialog';
import DialogTitle from '../dialog/dialogTitle';
import DialogContent from '../dialog/dialogContent';

interface IStreetViewProps {
    readonly isOpen: boolean;
    readonly config: IStreetView;
    readonly onClose?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    dialog: {
        height: theme.spacing(62),
        paddingBottom: theme.spacing(2)
    }
}));

const StreetView = (props: IStreetViewProps): JSX.Element => {
    const { isOpen, config, onClose } = props;
    const styles = useStyles();
    const handleClose = (): void => {
        onClose?.()
    };
    return (
        <Dialog fullWidth maxWidth="sm" classes={{ paper: styles.dialog }} open={isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Street View</DialogTitle>
            <DialogContent>
                <ReactStreetView
                    apiKey={config.apiKey}
                    streetViewPanoramaOptions={config.params}
                />
            </DialogContent>
        </Dialog>
    );
};

export type { IStreetViewProps };
export default StreetView;