import React from "react";
import { Pagination } from "@3tc/shared-components";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

import { IPageInfo, SortDirection } from "../../../../store/types";
import ResultRow from "./resultRow";
import { ITableHeader } from "../../types";

import css from './filterResultsTable.module.scss';

interface ResultsProps {
    readonly headers: ITableHeader[];
    readonly items: string[][];
    readonly pageInfo: IPageInfo;
    readonly totalCount: number;
    readonly lastFieldAsObjectNodeId?: boolean;
    readonly onFirstClick: () => void;
    readonly onPreviousClick: () => void;
    readonly onNextClick: () => void;
    readonly onLastClick: () => void;
    readonly onTableRowClick: (id: string) => void;
    readonly onTableHeaderClick: (sortBy: string, sortDirection: SortDirection) => void;
}

const useStyles = makeStyles(createStyles({
    table: {
        "& th": {
            fontWeight: "bold",
            cursor: "pointer",
            position: "relative"
        }
    },
    sortArrowContainer: {
        height: "24px",
        position: "absolute",
        left: "-5px",
        top: "50%",
        transform: "translateY(-50%)"
    }
}));

export enum SortDirections {
    ASCENDING = "ASCENDING",
    DESCENDING = "DESCENDING"
}

const Results = (props: ResultsProps): JSX.Element => {
    const styles = useStyles();
    const {
        headers,
        items,
        pageInfo,
        totalCount,
        lastFieldAsObjectNodeId,
        onFirstClick,
        onPreviousClick,
        onNextClick,
        onLastClick,
        onTableRowClick,
        onTableHeaderClick
    } = props;

    const PageControl = (): JSX.Element => (
        <Pagination
            tableContent={items}
            totalResults={totalCount}
            pageNumber={pageInfo.pageNumber}
            pageCount={pageInfo.pageCount}
            firstPageClickHandler={onFirstClick}
            lastPageClickHandler={onLastClick}
            nextPageClickHandler={onNextClick}
            previousPageClickHandler={onPreviousClick}
        />
    );

    const renderHeader = (header: ITableHeader, index: number): JSX.Element => {
        const { displayText, sortBy } = header;
        if (sortBy) {
            const sortingDirectionIcon = pageInfo.sortDirection === SortDirections.ASCENDING
                ? <ArrowDropUp color="secondary" />
                : <ArrowDropDown color="secondary" />;
            const handleClick = (): void => {
                if (pageInfo.sortBy === sortBy) {
                    const sortDirection = pageInfo.sortDirection === SortDirections.ASCENDING ? SortDirections.DESCENDING : SortDirections.ASCENDING;
                    return onTableHeaderClick?.(sortBy, sortDirection);
                }
                onTableHeaderClick?.(sortBy, pageInfo.sortDirection);
            };
            return (
                <th key={index} onClick={handleClick}>
                    <div className={styles.sortArrowContainer}>
                        {pageInfo.sortBy === sortBy && sortingDirectionIcon}
                    </div>
                    {displayText}
                </th>
            );
        }
        return <th key={index}>{displayText}</th>;
    };

    const renderRow = (row: string[], index: number): JSX.Element => (
        <ResultRow key={`row-${index}`} index={index} fields={row} lastFieldAsObjectNodeId={lastFieldAsObjectNodeId} onClick={onTableRowClick} />
    );

    return (
        <div>
            <PageControl />
            <table id='water-sources-table' className={cx(styles.table, "responsive-table")}>
                <thead>
                    <tr className={css.tableHeader}>
                        {headers.map(renderHeader)}
                    </tr>
                </thead>
                <tbody>
                    {items.map(renderRow)}
                </tbody>
            </table>
            <PageControl />
        </div>
    );
};

export type { ResultsProps };
export default Results;