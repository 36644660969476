import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../../lib/hooks/useSelector";
import { NodeID } from "../../../lib/nodeIdentifier";
import { setDialogOpen } from "../../../store/actions/app";
import { IWaterSource } from "../../../store/types";
import { useDefect, useInspection, useWaterSource } from "../../appWindowContext";
import useDefects from "../../appWindowContext/hooks/useDefects";
import DefectPanelBase, { IDefectPanelProps } from "./defectPanel.view";

const DefectPanel = (props: Pick<IDefectPanelProps, "hideNavButton"> & { readonly waterSource: IWaterSource; }): JSX.Element => {
    const { waterSource, ...otherProps } = props;
    const dispatch = useDispatch();
    const [defects, refreshDefects] = useDefects(waterSource.waterSourceNodeId);
    const { refresh: refreshWaterSource } = useWaterSource();
    const { instance: defect, change: changeDefect, clear: clearDefect, refresh: refreshDefect } = useDefect();
    const { instance: inspection } = useInspection();
    const createSuccess = useSelector(state => state.defects.createDefectSuccess);
    const prevCreateSuccess = useRef(createSuccess);
    useEffect(() => {
        if (prevCreateSuccess.current !== createSuccess && createSuccess) {
            refreshDefects();
            refreshWaterSource();
        }
    }, [createSuccess, refreshDefects]);
    const combined: IDefectPanelProps = {
        ...otherProps,
        selectedDefect: defect,
        selectedInspection: inspection,
        defects,
        onClearDefect: (): void => {
            clearDefect();
        },
        onRefreshDefect: async (): Promise<void> => {
            await refreshDefect();
            refreshDefects();
        },
        onSelectDefect: (id: NodeID): void => {
            changeDefect(id);
        },
        showCreateDialog: (): void => {
            dispatch(setDialogOpen("createDefect"));
        },
        onUpdateDefect: (): void => {
            refreshWaterSource();
        }
    };
    return <DefectPanelBase {...combined} />;
};

export default DefectPanel;