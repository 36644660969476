import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { NodeID } from "../../../../../lib/nodeIdentifier";
import { IScheme } from "../../../../../store/types";
import ElementContainer from "../../../../labelledField/components/elementContainer";
import LabelElement from "../../../../labelledField/components/labelElement";
import EmptyList from "./EmptyList";
import CollapsibleList from "./CollapsibleList";

interface SchemeLinkProps {
    readonly schemes: IScheme[];
    readonly onClick?: (id: NodeID) => void;
}

const useStyles = makeStyles(createStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start"
    }
}));

const WaterSourceSchemeLinks = ({ schemes, onClick }: SchemeLinkProps): JSX.Element => {
    const styles = useStyles();
    const id = "water-source-scheme";
    const label = `Schemes (${schemes.length})`;
    if (schemes.length > 0) {
        return (
            <React.Fragment>
                <ElementContainer classes={cx("input__group", "input__group_side-panel", styles.root)}>
                    <LabelElement ownerId={id} label={label} classes="input__label" />
                    <CollapsibleList id={id} schemes={schemes} onClick={onClick} />
                </ElementContainer>
            </React.Fragment>
        );
    }
    return <EmptyList id={id} label={label} />;
};

export default WaterSourceSchemeLinks;