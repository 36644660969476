import React from "react";

import Field from "../../../../card/components/field";
import TextInput from "../../../../forms/textInput";
import { formatText } from "../../../utils";

interface LocationDescriptionProps {
    readonly description: string | undefined;
    readonly editing?: boolean;
    readonly onChange?: (value: string | undefined) => void;
}

const LocationDescription = ({ description, editing, onChange }: LocationDescriptionProps): JSX.Element => {
    if (editing) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            const { value } = event.target;
            onChange?.(value);
        };
        return <TextInput value={description} id="location-description" label="Description" onChange={handleChange} />;
    }
    return <Field label="Description" text={formatText(description)} />
};

export type { LocationDescriptionProps };
export default LocationDescription;