import React from "react";

import DataTabs from "../../components/dataTabs";
import LeftDrawer from "../../components/drawer/LeftDrawer";
import MapPage from "../../components/mapPage";

import styles from "./AppWindow.module.scss";

const AppWindow = (): JSX.Element => {
    return (
        <div className={styles.root}>
            <LeftDrawer />
            <DataTabs />
            <MapPage />
        </div>
    );
};

export default AppWindow;
