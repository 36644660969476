import { connect } from "react-redux";

import bindLookup from "../../../../../../lib/lookups";
import { IReduxState } from "../../../../../../store/types";
import Editor, { EditorProps } from "./editor.view";

type ConnectedProps = Pick<EditorProps, "allHazardSeverity" | "allHazards" | "allControlMeasures" | "allRoadSpeed" | "allHazardFlags">;

const mapProps = (state: IReduxState): ConnectedProps => {
    const { riskSeverity, hazardTypes, controlMeasures, roadSpeed, hazardFlags } = state.app.lookups ?? {};

    return {
        allHazardSeverity: bindLookup(riskSeverity ?? []),
        allHazards: bindLookup(hazardTypes ?? []),
        allControlMeasures: bindLookup(controlMeasures ?? []),
        allRoadSpeed: bindLookup(roadSpeed ?? []),
        allHazardFlags: bindLookup(hazardFlags ?? [])
    };
};

export default connect(mapProps)(Editor);