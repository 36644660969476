import React, { useState } from "react";

import Box from "@material-ui/core/Box";

import AccessControl from "../../../../auth/components/AccessControl";
import { RoleNames } from "../../../../auth/roles";
import { OptionValue } from "../../../labelledField";
import EwsPumpTypesDialog, { EwsPumpTypesDialogProps } from "./EwsPumpTypesDialog";

type EwsManagePumpTypesProps = Pick<EwsPumpTypesDialogProps, "options" | "selected" | "onChange">;

const EwsManagePumpTypes = ({ options, selected, onChange }: EwsManagePumpTypesProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const closeDialog = (): void => setDialogOpen(false);

    const handleOpen = (): void => {
        setDialogOpen(true);
    };
    const handleClose = (): void => {
        closeDialog();
    };
    const handleChange = (selected: OptionValue[]): void => {
        closeDialog();
        onChange?.(selected);
    };

    return (
        <AccessControl role={RoleNames.WS_ALL}>
            <Box pl={16}>
                <button id="ews-pumpTypes-openDialog-button" className="action-button action-button--hazards" onClick={handleOpen}>
                    Manage Pump Types
                </button>
            </Box>
            <EwsPumpTypesDialog open={dialogOpen} options={options} selected={selected} onClose={handleClose} onChange={handleChange} />
        </AccessControl>
    );
};

export type { EwsManagePumpTypesProps };
export default EwsManagePumpTypes;