import React, { useState, useEffect, useRef } from "react";

import executeQuery from "../../../../lib/executeQuery";
import quickSearchQuery from "../../../../store/actions/graphQL/quickSearchQuery";
import { IConnection, IWaterSource, IQuickSearchItem, LikeFilter } from "../../../../store/types";
import QuickSearchLoading from "../quickSearchLoading";
import QuickSearchResult from "../quickSearchResult";
import QuickSearchEmptyResult from "../quickSearchEmptyResult";
import { isEmpty, isWhiteSpace, compose, and } from "../../../../lib/validation";

interface StreetSearchParams {
    readonly input: string;
    readonly pageNumber: number;
    readonly setIsLoading: (value: boolean) => void;
    readonly setSearchResults: (items: IQuickSearchItem[], totalCount: number) => void;
}

const streetPattern = /^[^,]{1,100}$/;
const townPattern = /^[^,]{1,200}$/;

const validateInput = (input: string): boolean => {
    const isEmptyOrWhiteSpace = compose(and, [isEmpty, isWhiteSpace]);

    if (input.trim() === "") {
        return false;
    }

    const fields = input.split(",");
    if (fields.length > 1) {
        const street = fields[0];
        const town = fields[1];
        if (isEmptyOrWhiteSpace(street) && isEmptyOrWhiteSpace(town)) {
            return false;
        }
        const isStreetValid = isEmptyOrWhiteSpace(street) ? true : streetPattern.test(street);
        const isTownValid = isEmptyOrWhiteSpace(town) ? true : townPattern.test(town);
        return isStreetValid && isTownValid;
    }
    if (fields.length > 0) {
        const isStreetValid = isEmptyOrWhiteSpace(fields[0]) ? true : streetPattern.test(fields[0]);
        return isStreetValid;
    }
    return false;
};

interface StreetFilter {
    readonly addressStreet?: LikeFilter;
}

interface TownFilter {
    readonly addressTown?: LikeFilter;
}

type AddressFilter = StreetFilter & TownFilter;

const likeFilter = (s: string): LikeFilter => {
    return { "$like": `%${s.trim()}%` };
};

const parseStreetFilter = (input: string): StreetFilter => {
    return input.trim() === ""
        ? {}
        : { addressStreet: likeFilter(input) };
};

const parseTownFilter = (input: string): TownFilter => {
    return input.trim() === ""
        ? {}
        : { addressTown: likeFilter(input) };
};

const parseFilter = (input: string): AddressFilter => {
    const fields = input.split(",");
    if (fields.length > 1) {
        return {
            ...parseStreetFilter(fields[0]),
            ...parseTownFilter(fields[1])
        };
    }
    if (fields.length > 0) {
        return {
            ...parseStreetFilter(fields[0])
        };
    }
    return {};
};

const streetSearch = (params: StreetSearchParams): void => {
    const input = params.input.replace(/[%_[]/, "[$&]");
    const filter = parseFilter(input);
    const variables = {
        filter,
        paging: {
            pageIndex: params.pageNumber,
            pageSize: 10,
            sortBy: "id",
            sortDirection: "ASCENDING"
        }
    };
    params.setIsLoading(true);
    executeQuery<{ waterSources: IConnection<IWaterSource> }>(quickSearchQuery, variables)
        .then(response => response?.waterSources)
        .then(waterSources => {
            const items = waterSources?.items ?? [];
            const totalCount = waterSources?.totalCount ?? 0;
            params.setSearchResults(items, totalCount);
        })
        .catch(() => {
            params.setSearchResults([], 0);
        })
        .finally(() => params.setIsLoading(false));

};

interface IStreetSearchProps {
    readonly input: string;
    readonly inline?: boolean;
    readonly onSearchResultClick: (result: IQuickSearchItem) => void;
    readonly onSearchInvalid: () => void;
    readonly onClose: () => void;
}

const StreetSearch = (props: IStreetSearchProps): JSX.Element => {
    const [isInputValid, setIsInputValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchComplete, setIsSearchComplete] = useState(false);
    const [searchResults, setSearchResults] = useState<IQuickSearchItem[]>([]);
    const [searchResultsTotal, setSearchResultsTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const { input, onSearchInvalid } = props;

    const prevInput = useRef(input);
    useEffect(() => {
        if (input !== prevInput.current) {
            setPageNumber(1);
        }
    }, [input, prevInput]);

    useEffect(() => {
        if (input.length > 0) {
            if (validateInput(input)) {
                setIsInputValid(true);
                streetSearch({
                    input,
                    pageNumber,
                    setIsLoading,
                    setSearchResults: (items, totalCount) => {
                        setIsSearchComplete(true);
                        setSearchResults(items);
                        setSearchResultsTotal(totalCount);
                        setTotalPages(Math.ceil(totalCount / 10))
                    }
                });
            }
            else {
                setIsInputValid(false);
            }
        }
    }, [input, pageNumber]);

    useEffect(() => {
        if (!isInputValid) {
            onSearchInvalid?.();
        }
    }, [isInputValid, onSearchInvalid]);

    const clearResults = (): void => {
        setIsLoading(false);
        setIsSearchComplete(false);
        setSearchResults([]);
        setSearchResultsTotal(0);
    };

    const handleCloseResults = (): void => {
        props.onClose();
        clearResults();
    };
    const handleSelectResult = (item: IQuickSearchItem): void => {
        props.onSearchResultClick(item);
        clearResults();
    };

    return isInputValid
        ? (
            <React.Fragment>
                <QuickSearchLoading show={isLoading} inline={props.inline ?? false} />
                <QuickSearchEmptyResult show={isSearchComplete && searchResultsTotal === 0} inline={props.inline} />
                {!isLoading && <QuickSearchResult
                    show={isSearchComplete && searchResults.length > 0}
                    items={searchResults}
                    pageSize={searchResults.length}
                    totalCount={searchResultsTotal}
                    totalPages={totalPages}
                    isInline={props.inline}
                    pageNumber={pageNumber}
                    onSelect={handleSelectResult}
                    onClose={handleCloseResults}
                    onGotoFirst={(): void => setPageNumber(1)}
                    onGotoLast={(): void => setPageNumber(totalPages)}
                    onGotoNext={(): void => setPageNumber(pageNumber + 1)}
                    onGotoPrevious={(): void => setPageNumber(pageNumber - 1)}
                />}
            </React.Fragment>
        )
        : <React.Fragment />;
};

export type { IStreetSearchProps };
export default StreetSearch;