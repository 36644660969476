import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { SelectProps, PropsWithEditing } from "../types";
import { formatText } from "../../../../lib/Utils";
import LabelledField, { LabelledFieldProps, asLookupItem } from "../../../labelledField";

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.secondary.main,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    editor: {
        marginBottom: "-0.35rem"
    }
}));

const Editor = ({ options, data, onClear, onSelect }: SelectProps): JSX.Element => {
    const styles = useStyles();
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "repair-repairType",
        label: "",
        classes: { container: cx("input__group", styles.editor), label: "input__label" },
        editing: true,
        value: asLookupItem(data.repairType),
        selectOptions: options,
        onChange: option => onSelect?.(option),
        onClear: () => onClear?.()
    };
    return <LabelledField {...props} />;
}

const ReadOnly = ({ data }: Pick<SelectProps, "data">): JSX.Element => {
    return (
        <Typography variant="body2">
            {formatText(data.repairType?.displayText, true, "-")}
        </Typography>
    );
};

const RepairType = ({ editing, data, ...rest }: PropsWithEditing<SelectProps>): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            {editing
                ? <Editor data={data} {...rest} />
                : <ReadOnly data={data} />
            }
        </div>
    );
};

export default RepairType;