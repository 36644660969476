import React from "react";
import classnames from "classnames";

import { EditElementProps } from "../../types";
import { inputClass } from "../../labelledField.utils";

import ReadOnlyElement from "../readOnlyElement";
import LabelledContainer from "../LabelledContainer";

interface NumberElementProps extends EditElementProps<number> {
    readonly minValue: number | undefined;
    readonly maxValue?: number;
}

const NumberElement = ({ editing, value, maxValue, minValue, onChange, ...others }: NumberElementProps): JSX.Element => {
    if (editing) {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            const value = e.target.value === ""
                ? undefined
                : Number(e.target.value);
            onChange(value);
        };
        return (
            <LabelledContainer {...others}>
                <input
                    type="number"
                    placeholder={others.label}
                    id={others.id}
                    name={others.id}
                    className={classnames("input", inputClass(others))}
                    value={value ?? ""}
                    min={minValue}
                    max={maxValue}
                    onChange={handleChange}
                />
            </LabelledContainer>
        );
    }
    return <ReadOnlyElement value={value} {...others} />;
};

export type { NumberElementProps };
export default NumberElement;