import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(createStyles({
    text: {
        fontSize: "20px",
        fontFamily: "Arial, Helvetica, sans-serif",
        stroke: "black",
        strokeWidth: "1px",
        ["&.washout"]: {
            fontSize: "16px"
        },
        ["&.operableDefective"]: {
            stroke: "#e73930",
            fill: "#e73930"
        }
    },
    hydrant: {
        fill: "#ffcf21",
        stroke: "black",
        strokeWidth: "3px",
        ["&.private"]: {
            fill: "#25e181"
        },
        ["&.defective"]: {
            fill: "#e73930"
        },
        ["&.inactive"]: {
            fill: "#b3b3b3"
        },
        ["&.proposed"]: {
            fill: "#00ffff"
        },
        ["&.awaitingAdoption"]: {
            fill: "#3250ef"
        }
    },
    abandoned: {
        stroke: "#e73930",
        strokeWidth: "2px"
    }
}));

export default useStyles;
