import { IDeviceState, IDeviceAction } from "../types/device";

const defaultAction: IDeviceAction = {
    type: "@@INIT"
};

const defaultState: IDeviceState = {
    isMobile: false
};

export default (state: IDeviceState = defaultState, action: IDeviceAction = defaultAction): IDeviceState => {
    switch (action.type) {
        case "SET_DEVICE_ISDESKTOP":
            return {
                isMobile: false
            };
        case "SET_DEVICE_ISMOBILE":
            return {
                isMobile: true
            };
        default:
            return state;
    }
}