import React, { useContext } from "react";

import useAppWindowParams, { WaterSourceTab } from "../../router/DesktopRouter/hooks/useAppWindowParams";
import useNavigation from "../../router/DesktopRouter/hooks/useNavigation";

interface DataTabsContextState {
    readonly tab: WaterSourceTab;
}

interface TabSelector {
    (tabIndex: WaterSourceTab): void;
}

const defaultTab = WaterSourceTab.WATER_SOURCE;

const Context = React.createContext<DataTabsContextState>({ tab: defaultTab });

const DataTabsContext = (props: React.PropsWithChildren<unknown>): JSX.Element => {
    const params = useAppWindowParams();
    return (
        <Context.Provider value={{ tab: params.tab ?? defaultTab }}>
            {props.children}
        </Context.Provider>
    );
};

const useTabs = (): [WaterSourceTab, TabSelector] => {
    const { tab } = useContext(Context);
    const { gotoMap } = useNavigation();
    const selectTab = (tabIndex: WaterSourceTab): void => {
        gotoMap({ tab: tabIndex });
    };
    return [tab, selectTab];
};

export type { TabSelector };
export { WaterSourceTab, useTabs };
export default DataTabsContext;