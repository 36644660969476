import executeQuery from "../../../lib/executeQuery";
import getSavedSearchesQuery from "../../../store/actions/graphQL/getSavedSearches";
import { ISavedSearch } from "../../../store/types";
import { SearchEntity } from "../searchSelector";
import { SearchQueryResponse } from "./types";
import { resolveQuery } from "./utils";

const getSavedSearches = async (searchPath: SearchEntity): Promise<ISavedSearch[]> =>
    executeQuery<SearchQueryResponse>(getSavedSearchesQuery, { searchPath })
        .then(resolveQuery);

export default getSavedSearches;
