import React, { useState, useEffect } from "react";

import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from "@material-ui/icons/Warning";

import * as assert from "../../../../lib/assertions";
import { NodeID, parseNodeId } from "../../../../lib/nodeIdentifier";
import { longDateFormatter } from "../../../../lib/Utils";
import IconDecoration from "../../../icons/IconDecoration";
import AppDialog, { AppDialogInput, AppDialogSelect } from "../../../shared/appDialog";
import { ILookupItem, IDefect } from "../../../../store/types";
import useStyles from "./CloseDefect.styles";

interface CloseDefectProps {
    readonly defect: Pick<IDefect, "defectNodeId" | "defectId" | "type" | "dateReported" | "reportedBy">;
    readonly closeReasons: ILookupItem[];
    readonly isMobile?: boolean;
    readonly onCommit?: (defectId: NodeID, closeReason: ILookupItem) => void;
}

const renderCloseReasons = (reasons: ILookupItem[], isMobile: boolean | undefined): JSX.Element[] => {
    return reasons
        .filter(type => type.enabled)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(reason => [reason.value, reason.displayText])
        .map(([value, displayText]) => isMobile
            ? <option key={value} value={value}>{displayText}</option>
            : <MenuItem key={value} value={value}>{displayText}</MenuItem>);
};

const CloseDefect = (props: CloseDefectProps): JSX.Element => {
    const { defect, closeReasons, isMobile, onCommit } = props;

    const styles = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [disableCommit, setDisableCommit] = useState(true);
    const [closeReason, setCloseReason] = useState<ILookupItem>();

    const closeDialog = (): void => {
        setShowDialog(false);
        setCloseReason(undefined);
    }
    const openDialog = (): void => setShowDialog(true);

    const handleCloseReasonChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        const { value } = event.target;
        const item = closeReasons.find(i => {
            return (typeof value === "string" || typeof value === "number")
                ? i.value.toString() === value.toString()
                : undefined;
        });
        setCloseReason(item);
    };

    const handleCommit = (): void => {
        closeDialog();
        if (onCommit) {
            assert.isDefined(closeReason);
            onCommit(parseNodeId(defect.defectNodeId), closeReason);
        }
    }

    useEffect(() => {
        const isValid = closeReason !== undefined;
        setDisableCommit(!isValid);
    }, [closeReason]);

    const commit = (
        <IconButton edge="end" onClick={handleCommit} disabled={disableCommit}>
            <DoneIcon />
        </IconButton>
    );

    return (
        <React.Fragment>
            <IconButton className={styles.button} onClick={openDialog}>
                <IconDecoration decoration={<CheckCircleOutlineIcon className={styles.icon} />}>
                    <WarningIcon />
                </IconDecoration>
            </IconButton>
            <AppDialog open={showDialog} appBar={{ title: "Resolve Defect" }} commit={commit} onClose={closeDialog}>
                <div className={styles.dialog}>
                    <AppDialogInput value={defect.defectId} id="resolveDefect-defect" label="Defect" disabled />
                    <AppDialogInput value={defect.type.displayText} id="resolveDefect-type" label="Type" disabled />
                    <AppDialogInput value={defect.dateReported.format(longDateFormatter)} id="resolveDefect-dateReported" label="Date reported" disabled />
                    <AppDialogInput value={defect.reportedBy} id="resolveDefect-reportedBy" label="Reported by" disabled />
                    <AppDialogSelect value={closeReason?.value} id="resolveDefect-reason" label="Reason" placeholder="Please select..." native={isMobile} required onChange={handleCloseReasonChange}>
                        {renderCloseReasons(closeReasons, isMobile)}
                    </AppDialogSelect>
                </div>
            </AppDialog>
        </React.Fragment>
    );
};

export type { CloseDefectProps };
export default CloseDefect;