import React from 'react';
import { Box } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { ILookupItem } from '../../../../../store/types';

interface HazardFlagProps {
    readonly hazardFlag?: ILookupItem;
}

enum HazardFlagValues {
    DO_NOT_TEST = 1,
    RISK_ASSESSMENT = 2,
    NOT_AVAILABLE = 3
}

const HazardFlag = ({ hazardFlag }: HazardFlagProps): JSX.Element => {
    if (!hazardFlag || hazardFlag.value === HazardFlagValues.NOT_AVAILABLE) {
        return <React.Fragment />;
    }

    const icon = hazardFlag.value === HazardFlagValues.DO_NOT_TEST ? faBan : faClipboardList;

    return <Box mr={1}><FontAwesomeIcon icon={icon} /></Box>;
}

export default HazardFlag;