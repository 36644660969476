import React from 'react';
import { LoadingBar } from '@3tc/shared-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import Snackbar, { SnackbarCloseReason } from "@material-ui/core/Snackbar";
import Typography from '@material-ui/core/Typography';

import CloseIcon from "@material-ui/icons/Close";

import { IGlobalToast } from '../../../../store/types';

interface IGlobalToastProps {
    readonly globalToast: IGlobalToast;
    readonly onClose?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        color: theme.palette.primary.dark,
        
        width: "60%",
        minWidth: "300px",
        minHeight: "50px",
        padding: theme.spacing(2),
        paddingBottom: ({ globalToast: { type } }: IGlobalToastProps): number => {
            return type === "LOADING" 
                ? 0 
                : theme.spacing(2.5)
        },
        borderRadius: "3px",
        border: ({ globalToast: { type }}: IGlobalToastProps): string => {
            switch (type) {
                case "ERROR":
                    return "6px solid red";
                case "SUCCESS":
                    return "6px solid green";
                default:
                    return "6px solid transparent";
            }
        }
    },
    message: {
        fontWeight: theme.typography.fontWeightBold
    },
    closeButton: {
        color: theme.palette.primary.dark,
        marginLeft: "auto",
        "&:hover": {
            color: theme.palette.primary.light
        }
    }
}));

const defaultTimeout = 3000;

const GlobalToast = (props: IGlobalToastProps): JSX.Element => {
    const styles = useStyles(props);
    const isPrint = useMediaQuery("print");

    if (isPrint) {
        return <React.Fragment />;
    }
    
    const { onClose } = props;
    const { showToast, type, message, timeout } = props.globalToast;

    const handleClose = (_: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason): void => {
        if (reason === "clickaway") {
            return;
        }
        onClose?.();
    };

    return (
        <Snackbar open={showToast} autoHideDuration={timeout ?? defaultTimeout} onClose={handleClose} classes={{ root: styles.root }}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="h6" component="p" classes={{ root: styles.message }}>{message}</Typography>
                    <IconButton size="small" edge="end" classes={{ root: styles.closeButton }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                {type === "LOADING" && <LoadingBar loading={true} />}
            </Box>
        </Snackbar>
    );
};

export type { IGlobalToastProps };
export default GlobalToast;