import React from "react";
import { Spinner } from '@blueprintjs/core';
import cx from "classnames";

import styles from "./ActionButton.module.scss";

interface ActionButtonProps {
    readonly id?: string;
    readonly children?: React.ReactNode;
    readonly inProgress?: boolean;
    readonly disabled?: boolean;
    readonly hidden?: boolean;
    readonly variant?: "normal" | "cancel";
    readonly width?: number;
    readonly onClick?: () => void;
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
    const { id, children, inProgress, disabled, hidden, variant, width, onClick } = props;

    const handleClick = (): void => {
        onClick?.();
    };

    const className = cx("action-button", { ["action-button--cancel"]: variant === "cancel", [styles.inProgress]: inProgress });

    return hidden
        ? <React.Fragment />
        : (
            <button id={id} className={className} disabled={disabled ?? inProgress} style={{ width }} onClick={handleClick}>
                {inProgress ? <Spinner size={Spinner.SIZE_SMALL} /> : children}
            </button>
        );
};

export type { ActionButtonProps };
export default ActionButton;