import axios from "axios";

interface ChangePasswordResult {
    readonly error: boolean;
    readonly message: string;
}

const ok = (): ChangePasswordResult => ({
    message: "Change password e-mail sent. Please check your inbox.",
    error: false
});

const error = (): ChangePasswordResult => ({
    message: "Unable to send change password e-mail. Please try again.",
    error: true
});

const changePassword = async (): Promise<ChangePasswordResult> => {
    return axios.post("/api/user/changePassword")
        .then(ok)
        .catch(error);
}

export type { ChangePasswordResult };
export default changePassword;