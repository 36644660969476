import React from "react";
import { styled } from "@material-ui/core/styles";

interface PanelProps {
    readonly edge?: "left" | "right" | false;
}

type DivProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

const Panel = styled(({ edge, ...rest }: PanelProps & Omit<DivProps, keyof PanelProps>) => (
    <div {...rest} />
))({
    borderRight: (props: PanelProps) => props.edge === "left"
        ? "1px solid white"
        : undefined,
    height: (props: PanelProps) => props.edge === "left"
        ? "auto"
        : undefined,
    msOverflowStyle: (props: PanelProps) => props.edge === "left"
        ? "none"
        : undefined,
    overflowY: (props: PanelProps) => props.edge === "left"
        ? "auto"
        : undefined,
    paddingLeft: (props: PanelProps) => props.edge === "left"
        ? 0
        : 16,
    paddingRight: (props: PanelProps) => props.edge === "right"
        ? 0
        : 16,
    scrollbarWidth: (props: PanelProps) => props.edge === "left"
        ? "none"
        : undefined,
    width: "48%"
});

export default Panel;