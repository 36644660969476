import React from "react";
import cx from "classnames";

import { EditElementProps } from "../../types";
import { containerClass, inputClass, labelClass } from "../../labelledField.utils";

import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";
import ReadOnlyElement from "../readOnlyElement";
import styles from "./checkboxElement.module.scss";

type CheckboxElementProps = EditElementProps<boolean>;

const CheckboxElement = (props: CheckboxElementProps): JSX.Element => {
    if (props.editing) {
        const value = props.value ?? false;
        return (
            <ElementContainer noMargin={props.noMargin} classes={cx(styles.root, containerClass(props))}>
                <LabelElement ownerId={props.id} label={props.label} classes={cx("input__label", styles.input__label, labelClass(props))} />
                <div className={styles.container}>
                    <input
                        type="checkbox"
                        id={props.id}
                        name={props.id}
                        title={props.label}
                        className={cx(styles.input, inputClass(props))}
                        checked={value}
                        onChange={(): void => props.onChange(!value)}
                    />
                    <span className={styles.check} />
                </div>
            </ElementContainer>
        );
    }
    return <ReadOnlyElement id={props.id} label={props.label} noMargin={props.noMargin} classes={props.classes} value={props.value} />;
};

export type { CheckboxElementProps };
export default CheckboxElement;