import React, { useMemo } from "react";
import cx from "classnames";

import { findIconDefinition, IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useStyles from "../hazards.styles";

interface HazardItemProps {
    readonly symbolName?: IconName;
    readonly title?: string;
}

const HazardItem = (props: HazardItemProps): JSX.Element => {
    const { symbolName, title } = props;
    const styles = useStyles();
    const icon = useMemo(() => symbolName
        ? findIconDefinition({ prefix: "fas", iconName: symbolName })
        : undefined
        , [symbolName]);
    return icon
        ? <FontAwesomeIcon className={cx(styles.hazard, "hazard")} icon={icon} title={title} />
        : <React.Fragment />;
};

export type { HazardItemProps };
export default React.memo(HazardItem);
