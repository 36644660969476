export type SortDirection = "ASCENDING" | "DESCENDING";

export interface IEdge<TNode> {
    readonly index: number;
    readonly node: TNode;
}

export interface IPageInfo {
    readonly hasNextPage: boolean;
    readonly hasPreviousPage: boolean;
    readonly isFirstPage: boolean;
    readonly isLastPage: boolean;
    readonly pageCount: number;
    readonly pageNumber: number;
    readonly pageSize: number;
    readonly sortBy: string;
    readonly sortDirection: SortDirection;
}

export const emptyPage: IPageInfo = {
    hasNextPage: false,
    hasPreviousPage: false,
    isFirstPage: false,
    isLastPage: false,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 50,
    sortBy: "",
    sortDirection: "ASCENDING"
};

export interface IConnection<TNode> {
    readonly edges: IEdge<TNode>[];
    readonly items: TNode[];
    readonly pageInfo: IPageInfo;
    readonly totalCount: number;
}

export const emptyConnection = <TNode>(): IConnection<TNode> => ({
    edges: [],
    items: [],
    pageInfo: emptyPage,
    totalCount: 0
});

export interface IQueryControl {
    readonly pageSize: number;
    readonly pageIndex: number;
    readonly sortBy: string;
    readonly sortDirection: SortDirection;
}

export interface IConnectionQuery<TQueryFilter> {
    filter?: TQueryFilter;
    paging?: IQueryControl;
}