import React from "react";

import ClearIcon from '@material-ui/icons/Clear';

import AddressActionButton from "../AddressActionButton";

interface ClearAddressButtonProps {
    readonly edge?: 'start' | 'end' | false;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ClearAddressButton = ({ edge, onClick }: ClearAddressButtonProps): JSX.Element => {
    return (
        <AddressActionButton title="Clear Address" size="small" edge={edge} onClick={onClick}>
            <ClearIcon />
        </AddressActionButton>
    );
};

export type { ClearAddressButtonProps };
export default ClearAddressButton;