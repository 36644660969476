import React from "react";
import { useDispatch } from 'react-redux';

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { guid } from '../../../lib/Utils';
import { setGlobalToast, setDialogOpen } from '../../../store/actions/app';
import { setError } from '../../../store/actions/errors';
import { beginUpdateRoute, beginUpdateRouteWaterSources } from '../../../store/actions/routes';
import { setRenderPoints, startSetMapClickAction } from '../../../store/actions/map';
import { IEditRouteInput, IMapAction } from '../../../store/types';
import { useRoute, useWaterSource } from "../../appWindowContext";
import { printRoute } from "./RoutePanel.pdf";
import { compareWaterSources } from './RoutePanel.utils';
import RoutesPanelBase, { IRoutesPanelProps } from './RoutesPanel.view';

const RoutesPanel = ({ route, showOnMap, onClose, onUpdateRoute }: Pick<IRoutesPanelProps, "route" | "showOnMap" | "onClose" | "onUpdateRoute">): JSX.Element => {
    const dispatch = useDispatch();
    const { change: selectWaterSource, refresh: refreshWatersource } = useWaterSource();
    const { setShowOnMap } = useRoute();

    const stations = useSelector(state => state.app.lookups?.stations ?? []);

    const props: IRoutesPanelProps = {
        stations,
        route,
        showOnMap,
        mapSettings: useSelector(state => state.map.mapSettings ?? {
            projection: { code: "", definition: "" },
            tileServer: { apiKey: "", type: "", url: "", params: undefined },
            view: {
                centre: { x: 0, y: 0 },
                extent: { max: { x: 0, y: 0 }, min: { x: 0, y: 0 } }
            }
        }),
        onClose,
        onCreateInspectionsClick: (waterSourceIds): void => {
            dispatch(setDialogOpen("createInspection", { waterSourcesToAssign: waterSourceIds }));
        },
        onCreateWaterSourceClick: (): void => {
            dispatch(startSetMapClickAction("CREATE_WATER_SOURCE", true));
        },
        onError: (error, errorObject): void => {
            dispatch(setError(error, errorObject));
        },
        onInfo: (message): void => {
            dispatch(setGlobalToast({
                type: "INFO",
                message: message,
                showToast: true
            }));
        },
        onPrint: (route, imageUrl): void => {
            printRoute(route, imageUrl);
        },
        onUpdateRoute: async (routeNodeId, name, station): Promise<void> => {
            const variables: IEditRouteInput = {
                input: {
                    clientMutationId: guid(),
                    nodeId: routeNodeId,
                    data: {
                        name: name,
                        stationId: station.value
                    }
                }
            };
            const action = beginUpdateRoute(variables);
            return action(dispatch)
                .then(async () => onUpdateRoute?.(routeNodeId, name, station))
                .then(() => { dispatch(setRenderPoints(true)); });
        },
        onUpdateRouteWaterSources: async (routeNodeId, waterSources): Promise<void> => {
            const waterSourceIds = waterSources
                .sort(compareWaterSources)
                .map(ws => ws.waterSourceNodeId);
            const action = beginUpdateRouteWaterSources(routeNodeId, waterSourceIds);
            return action(dispatch).then(refreshWatersource);
        },
        onWaterSourceSelect: (waterSourceNodeId): void => {
            selectWaterSource(parseNodeId(waterSourceNodeId));
        },
        setShowOnMap
    };

    return <RoutesPanelBase {...props} />;
}

export default RoutesPanel;
