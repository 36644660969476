import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, SwitchProps } from "../types";

const WaterSourceSecondTechnicianRequired = ({ editing, checked, onChange }: PropsWithEditing<SwitchProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "checkbox",
        id: "water-source-second-technician-required",
        label: "Second Technician Required?",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value: checked,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return <LabelledField {...props} />;
};

export default WaterSourceSecondTechnicianRequired;