import React from "react";

import AppBarUser from "./AppBarUser.view";
import { useAccount, useVersion } from "../../../../auth/hooks";

const ConnectedAppBarUser = (): JSX.Element => {
    const account = useAccount();
    const version = useVersion();
    return <AppBarUser account={account} version={version} />;
};

export default ConnectedAppBarUser;