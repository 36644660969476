import { IPoint } from "../../store/types";
import makeCoordinateFormatter from "./makeCoordinateFormatter";

const gridLetters: string[][] = [
    ["V", "W", "X", "Y", "Z"],
    ["Q", "R", "S", "T", "U"],
    ["L", "M", "N", "O", "P"],
    ["F", "G", "H", "J", "K"],
    ["A", "B", "C", "D", "E"],
];

const _500kmGridOffset: [number, number] = [2, 1];

const _500km = 500000;
const _100km = 100000;

interface Offset {
    (index: number): number;
}

interface Indexer {
    (value: number): number;
}

interface LetterResolver {
    (coordinates: IPoint): string;
}

const makeIndexer = (scale: number, offset: Offset): Indexer => {
    return (n: number): number => {
        const a = n - (n % scale);
        const b = a / scale;
        const c = offset(b);
        return c;
    };
};

const resolveLetter = (scale: number, xOffset: Offset, yOffset?: Offset): LetterResolver => {
    const xIndex = makeIndexer(scale, xOffset);
    const yIndex = makeIndexer(scale, yOffset ?? xOffset);
    return (coordinates: IPoint): string => {
        const x = xIndex(coordinates.x);
        const y = yIndex(coordinates.y);
        const letter = gridLetters[y][x];
        return letter;
    };
};

const resolveFirstLetter = resolveLetter(_500km, a => a + _500kmGridOffset[0], a => a + _500kmGridOffset[1]);
const resolveSecondLetter = resolveLetter(_100km, a => a % 5);
const formatCoordinate = makeCoordinateFormatter(a => a % _100km, 5);

const format = (coordinates: IPoint): string => {
    const a = resolveFirstLetter(coordinates);
    const b = resolveSecondLetter(coordinates);

    const x = formatCoordinate(coordinates.x);
    const y = formatCoordinate(coordinates.y);

    return `${a}${b} ${x} ${y}`;
};

export { resolveFirstLetter, resolveSecondLetter };
export default format;