import React from "react";
import { Box } from "@material-ui/core";

import ReadOnly, { ReadOnlyProps } from "./components/readonly";
import Editor from "./components/editor";
import { EditFields, EditKeys } from "../waterSourceDetails/components/editor";

interface HazardsDetailsProps extends ReadOnlyProps {
    readonly editing?: boolean;
    readonly isMobile?: boolean;
    readonly onChange?: (key: EditKeys, value: EditFields[EditKeys]) => void;
}

const HazardsDetails = (props: HazardsDetailsProps): JSX.Element => {
    const { editing, isMobile, onChange, ...other } = props;

    const handleChange = (key: EditKeys, value: EditFields[EditKeys]): void => {
        onChange?.(key, value);
    };

    return (
        <Box p={2} overflow="auto" height="100%">
            {editing
                ? <Editor {...other} native={isMobile} onChange={handleChange} />
                : <ReadOnly {...props} />
            }
        </Box>
    );
};

export type { HazardsDetailsProps };
export default HazardsDetails;