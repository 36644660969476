import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    root: {},
    viewButton: {
        color: theme.palette.primary.light,
        "&.Mui-selected": {
            color: theme.palette.secondary.main
        },
        "&:last-child": {
            marginRight: theme.spacing(-1.5)
        }
    }
}));

export default useStyles;
