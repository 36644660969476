import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";

import Dialog from "../../dialog/dialog";
import DialogTitle from "../../dialog/dialogTitle";
import DialogContent from "../../dialog/dialogContent";
import DialogActions from "../../dialog/dialogActions";
import { AddressOwner } from "../types";
import ClearAddressButton from "./ClearAddressButton";

interface ClearAddressProps {
    readonly owner: AddressOwner;
    readonly hidden?: boolean;
    readonly onConfirm?: () => void;
}

const useStyles = makeStyles(createStyles({
    note: {
        fontStyle: "italic"
    }
}));

const ClearAddress = ({ owner, hidden, onConfirm }: ClearAddressProps): JSX.Element => {
    const styles = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    if (hidden) {
        return <React.Fragment />;
    }

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const handleCancelClick = (): void => {
        setDialogOpen(false);
    };
    const handleConfirmClick = (): void => {
        setDialogOpen(false);
        onConfirm?.();
    };

    return (
        <React.Fragment>
            <ClearAddressButton onClick={handleActionClick} />
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle onClose={handleCloseDialog}>
                    Clear Address?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" paragraph>
                        {`Are you sure you want to clear this address from the ${owner}? Remember, the change is not permanent until the ${owner} is saved.`}
                    </Typography>
                    <Typography variant="body2" className={styles.note}>
                        {"The address record is not deleted."}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button id="cancelEdit-yes-button" className="action-button action-button--cancel" onClick={handleConfirmClick}>Yes</button>
                    <button id="cancelEdit-no-button" className="action-button" onClick={handleCancelClick}>No</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export type { ClearAddressProps };
export default ClearAddress;