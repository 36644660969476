import { useState, useEffect, useCallback } from "react";
import { Coordinate } from "ol/coordinate";
import proj4 from "proj4";

import useSelector from "../../lib/hooks/useSelector";
import { defaultProjection } from "../../lib/map/Map";
import { IMapProjection } from "../../store/types";

type DeviceLocation = Coordinate | Error;

const useDeviceLocation = (): DeviceLocation | undefined => {
    const [projectedLocation, setProjectedLocation] = useState<DeviceLocation>();
    const projection = useSelector<IMapProjection>(state => state.map.mapSettings?.projection ?? defaultProjection)

    const { geolocation, permissions } = navigator;
    const { code } = projection;

    const getDeviceLocation = useCallback((): void => {
        geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            const projectedCoords = proj4(code, [longitude, latitude]);
            setProjectedLocation(projectedCoords);

        }, error => {
            setProjectedLocation(new Error(error.message));
        });
    }, [geolocation, code]);

    useEffect(() => {
        if (permissions?.query) {
            permissions
                .query({ name: "geolocation" })
                .then(result => {
                    if (result.state !== "denied") {
                        getDeviceLocation();
                    } else {
                        console.warn("Geolocation service: permission denied.");
                        setProjectedLocation(new Error("Geolocation service: permission denied."));
                    }
                })
                .catch(err => {
                    console.error("Error getting Navigator permission.", err);
                    setProjectedLocation(new Error("Error getting geolocation service permissions."));
                });
        } else {
            getDeviceLocation();
        }
    }, [permissions, getDeviceLocation]);

    return projectedLocation;
};

export type { DeviceLocation };
export default useDeviceLocation;