import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setMapClickAction, startSetMapClickAction } from '../../../../../store/actions/map';
import SchemeMenu, { SchemeMenuProps } from './schemeMenu';

type ConnectedActions = Pick<SchemeMenuProps, "onCreateWaterSource" | "onEditThoroughfares" | "onEditWaterPipes">;

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>): ConnectedActions => ({
    onCreateWaterSource: (): void => {
        dispatch(startSetMapClickAction("CREATE_WATER_SOURCE", true));
    },
    onEditThoroughfares: (action: "DRAW_THOROUGHFARE" | "DELETE_THOROUGHFARE"): void => {
        dispatch(setMapClickAction(action));
    },
    onEditWaterPipes: (action: "DRAW_WATER_PIPE" | "DELETE_WATER_PIPE"): void => {
        dispatch(setMapClickAction(action));
    }
});

export default connect(undefined, mapDispatch)(SchemeMenu);