import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Dialog from '../../dialog/dialog';
import DialogTitle from '../../dialog/dialogTitle';
import DialogActions from '../../dialog/dialogActions';
import LabelledField, { SelectOption } from '../../labelledField';
import DialogContent from '../../dialog/dialogContent';
import { ILookupItem, Repair, IDefect } from '../../../store/types';
interface CancelRepairDialogProps {
    readonly repair: Repair;
    readonly cancelReasons: ILookupItem[];
    readonly isOpen: boolean;
    readonly onClose?: () => void;
    readonly onCancelRepair: (cancelReason: SelectOption) => void;
}

const DefectText = ({ defect }: { readonly defect?: Pick<IDefect, "defectId" | "type"> }): JSX.Element => {
    if (defect) {
        return (
            <Typography variant="caption" component="p">
                Defect {defect.defectId}, {defect.type.displayText}
            </Typography>
        );
    }
    return <React.Fragment />;
};

const RepairText = ({ repair }: Pick<CancelRepairDialogProps, "repair">): JSX.Element => {
    const type = repair.repairType?.displayText;
    return (
        <Typography variant="caption" component="p">
            Repair {repair.repairId}{type ? ", " : ""}{type}
        </Typography>
    );
};


const CancelRepairDialog = (props: CancelRepairDialogProps): JSX.Element => {
    const { repair, cancelReasons, isOpen, onClose, onCancelRepair } = props;
    const [cancelReason, setCancelReason] = useState<SelectOption | undefined>();

    const handleCancelReasonChange = (value: SelectOption | undefined): void => {
        setCancelReason(value);
    };

    const handleClose = (): void => {
        onClose?.();
    };

    const handleCancelRepair = (): void => {
        if (cancelReason) {
            handleClose();
            onCancelRepair(cancelReason);
        }
    };

    return (
        <Dialog fullWidth maxWidth="xs" overflowY="unset" open={isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Cancelling Repair</DialogTitle>
            <DialogContent overflowY="unset">
                <Box marginBottom={2}>
                    <Typography variant="body2" gutterBottom>
                        Are you sure you want to cancel the repair?
                    </Typography>
                    <Typography variant="caption" component="p">
                        Water source {repair.waterSource.waterSourceId}
                    </Typography>
                    <DefectText defect={repair.defect} />
                    <RepairText repair={repair} />
                </Box>
                <LabelledField
                    fieldType="select"
                    id="repair-cancel-reason"
                    label="Cancel Reason"
                    classes={{ container: 'input__group', label: 'input__label' }}
                    value={cancelReason}
                    selectOptions={cancelReasons}
                    editing
                    onChange={handleCancelReasonChange}
                />
            </DialogContent>
            <DialogActions>
                <button id="cancelRepair-cancel-button" className="action-button action-button--cancel" onClick={handleClose}>No</button>
                <button id="cancelRepair-save-button" className="action-button" disabled={!cancelReason} onClick={handleCancelRepair}>Yes</button>
            </DialogActions>
        </Dialog>
    )
}

export type { CancelRepairDialogProps };
export default CancelRepairDialog;