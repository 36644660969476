import React from "react";

import { AccessControl } from "../../../auth/components";
import { RoleNames } from "../../../auth/roles";
import useNavigation from "../hooks/useNavigation";
import AppBarMenu from "./AppBarMenu";
import SecureAppBarMenuItem from "./SecureAppBarMenuItem";

const AppBarAdminMenu = (): JSX.Element => {
    const { gotoAdminRoles, gotoAdminUsers } = useNavigation();

    const handleRolesClick = (): void => {
        gotoAdminRoles();
    };

    const handleUsersClick = (): void => {
        gotoAdminUsers();
    };

    return (
        <AccessControl any={[RoleNames.ADMIN_ROLES, RoleNames.ADMIN_USERS]}>
            <AppBarMenu id="admin" title="Admin">
                <SecureAppBarMenuItem role={RoleNames.ADMIN_USERS} id="adminUsers" label="Users" onClick={handleUsersClick} />
                <SecureAppBarMenuItem role={RoleNames.ADMIN_ROLES} id="adminRoles" label="Roles &amp; Permissions" onClick={handleRolesClick} />
            </AppBarMenu>
        </AccessControl>
    );
};

export default AppBarAdminMenu;