import { ILookupItem } from "../store/types";

interface Lookup {
    readonly getItem: (value: number) => ILookupItem | undefined;
    readonly map: <T>(callback: (value: ILookupItem, index: number, array: ILookupItem[]) => T, thisArg?: any) => T[];
}

const getItem = (lookup: ILookupItem[], value: number): ILookupItem | undefined => {
    return lookup.find(item => item.value === value);
};

const bind = (lookup: ILookupItem[]): Lookup => ({
    getItem: (value: number): ILookupItem | undefined => {
        return getItem(lookup, value);
    },
    map: <T>(callback: (value: ILookupItem, index: number, array: ILookupItem[]) => T, thisArg?: any): T[] => {
        return lookup
            .filter(item => item.enabled)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(callback, thisArg);
    }
});

export type { Lookup };
export default bind;