import React, { Component } from 'react';

import { StateValidators, valid, validateState, requiredArray, requiredObject } from '../../../lib/validation';
import { ILookupItem, ICreateWaterSourceInput, WaterSourceCategory, isScheme } from '../../../store/types';
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import { SelectOption } from '../../labelledField';
import CreateWaterSourceDetails from './createWaterSourceDetails';
import { CreateWaterSourceContext, defaultStatus, matchCategory } from './utils';
import LoadingContainer from '../../shared/loadingContainer';

import './createWaterSourceDialog.scss';

interface ICreateWaterSourceDialogProps {
    readonly isOpen: boolean;
    readonly categories: ILookupItem[];
    readonly classifications: ILookupItem[];
    readonly statuses: ILookupItem[];
    readonly stations: ILookupItem[];
    readonly context?: CreateWaterSourceContext;
    readonly createFromMapLocation: [number, number] | undefined;
    readonly onClose?: () => void;
    readonly onCreateWaterSource?: (category: WaterSourceCategory, data: ICreateWaterSourceInput) => void;
    readonly onCreateWaterSourcePreValidation?: (data: ICreateWaterSourceInput) => Promise<boolean>;
}

interface ICreateWaterSourceDialogState {
    readonly data: ICreateWaterSourceDialogStateData;
    readonly isLoadingValidation: boolean;
    readonly externalIdCustomValidation: string | undefined;
}

interface ICreateWaterSourceDialogStateData {
    readonly externalId: string | undefined;
    readonly location: [number, number] | undefined;
    readonly category: SelectOption | undefined;
    readonly classification: SelectOption | undefined;
    readonly status: SelectOption | undefined;
    readonly station: SelectOption | undefined;
    readonly locationAddressId: number | undefined;
}

type CreateWaterSourceStateDataKeys = keyof ICreateWaterSourceDialogStateData;
type CreateWaterSourcePropsTypes = ICreateWaterSourceDialogStateData[CreateWaterSourceStateDataKeys];

const validators: StateValidators<ICreateWaterSourceDialogStateData> = {
    externalId: valid,
    location: requiredArray,
    category: requiredObject,
    classification: requiredObject,
    status: requiredObject,
    station: requiredObject,
    locationAddressId: valid
};

const validateForm = validateState(validators);

class CreateWaterSourceDialog extends Component<ICreateWaterSourceDialogProps, ICreateWaterSourceDialogState> {
    constructor(props: ICreateWaterSourceDialogProps) {
        super(props);

        const category = props.categories.find(category => category.value === 1);
        this.state = {
            data: {
                externalId: undefined,
                category,
                classification: undefined,
                status: isScheme(this.props.context)
                    ? defaultStatus(this.props.statuses, category)
                    : undefined,
                station: this.props.context?.station,
                location: this.props.createFromMapLocation,
                locationAddressId: isScheme(this.props.context)
                    ? this.props.context.address?.addressId
                    : undefined
            },
            isLoadingValidation: false,
            externalIdCustomValidation: undefined
        };
    }

    public render(): JSX.Element {
        const handleClose = (): void => this.props.onClose?.();

        const handleChange = (key: CreateWaterSourceStateDataKeys, data: CreateWaterSourcePropsTypes): void => {
            this.setState(current => ({
                data: {
                    ...current.data,
                    [key]: data
                }
            }))
        };

        const handleSave = async (): Promise<void> => {
            const { externalId, location, category, classification, status, station, locationAddressId } = this.state.data;
            if (location && category && classification && status && station) {
                const getExternalId = (): string | undefined => {
                    const candidate = externalId?.trim();
                    return candidate ? candidate : undefined;
                };
                const data: ICreateWaterSourceInput = {
                    externalId: getExternalId(),
                    classificationId: Number(classification.value),
                    statusId: Number(status.value),
                    locationCoordinates: {
                        x: location[0],
                        y: location[1]
                    },
                    stationId: Number(station.value),
                    locationAddressId
                };

                this.setState(current => ({
                    ...current,
                    isLoadingValidation: true
                }));
                const validationSuccessful = await this.props.onCreateWaterSourcePreValidation?.(data);
                this.setState(current => ({
                    ...current,
                    isLoadingValidation: false
                }));

                if (validationSuccessful) {
                    this.props.onClose?.();
                    this.props.onCreateWaterSource?.(Number(category.value), data);
                } else {
                    this.setState(current => ({
                        ...current,
                        externalIdCustomValidation: 'Cannot create the Water Source; the ID is already in use. Please chose another ID.'
                    }))
                }
            }
            else {
                console.warn("CreateWaterSource", "Missing required field values.", this.state);
            }
        };

        const resetExternalIdValidation = (): void => this.setState(current => ({
            ...current,
            externalIdCustomValidation: undefined
        }));

        const { category } = this.state.data;
        const disableSave = !validateForm(this.state.data);
        return (
            <Dialog fullWidth maxWidth="sm" scroll="paper" open={this.props.isOpen} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>New Water Source</DialogTitle>
                <LoadingContainer isLoading={this.state.isLoadingValidation} />
                <DialogContent>
                    <CreateWaterSourceDetails
                        categories={this.props.categories}
                        classifications={this.props.classifications.filter(matchCategory(category))}
                        statuses={this.props.statuses.filter(matchCategory(category))}
                        stations={this.props.stations}
                        context={this.props.context}
                        data={{
                            externalId: this.state.data.externalId,
                            category: this.state.data.category,
                            classification: this.state.data.classification,
                            status: this.state.data.status,
                            station: this.state.data.station,
                            location: this.state.data.location,
                            locationAddressId: this.state.data.locationAddressId
                        }}
                        validators={validators}
                        onChange={handleChange}
                        externalIdCustomValidation={this.state.externalIdCustomValidation}
                        resetExternalIdValidation={resetExternalIdValidation}
                    />
                </DialogContent>
                <DialogActions>
                    <button className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button className="action-button" disabled={disableSave} onClick={handleSave}>Save</button>
                </DialogActions>
            </Dialog>
        );
    }
}

export type { ICreateWaterSourceDialogProps, ICreateWaterSourceDialogStateData, CreateWaterSourceStateDataKeys, CreateWaterSourcePropsTypes };
export default CreateWaterSourceDialog;