import React from "react";
import cls from "classnames";

import styles from "./QuickSearchEmptyResult.module.scss";

interface IQuickSearchEmptyResultProps {
    readonly show?: boolean;
    readonly inline?: boolean;
}

const QuickSearchEmptyResult = (props: IQuickSearchEmptyResultProps): JSX.Element =>
    props.show ?? true
        ? (
            <div className={cls(styles.root, { [styles.inline]: props.inline ?? false })}>
                <p>0 results to display</p>
            </div>
        )
        : <React.Fragment />;

export type { IQuickSearchEmptyResultProps };
export default QuickSearchEmptyResult;