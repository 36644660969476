import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import FilterOptionItem from "./FilterOptionItem";
import { FilterOption } from "../../types";

interface FilterGroupItemProps {
    readonly label: string;
    readonly options: FilterOption[]
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        textTransform: "capitalize",
        paddingBottom: theme.spacing(1),
        "& > option:first-child": {
            marginTop: theme.spacing(0.5)
        }
    },
}));

const FilterGroupItem = (props: FilterGroupItemProps): JSX.Element => {
    const { label, options } = props;
    const styles = useStyles();
    return (
        <optgroup label={label} className={styles.root}>
            {options.map(option => <FilterOptionItem key={`${option.value}`} option={option} />)}
        </optgroup>
    );
};

export type { FilterGroupItemProps };
export default FilterGroupItem;