import React, { useState } from "react";

import AddressDetailsDialog from "../AddressDetailsDialog";
import { AddressDetailsData } from "../types";

interface AddAddressProps {
    readonly show: boolean;
    readonly onConfirm?: (address: AddressDetailsData) => void;
}

const AddAddress = ({ show, onConfirm }: AddAddressProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const handleConfirm = (address: AddressDetailsData): void => {
        setDialogOpen(false);
        onConfirm?.(address);
    };

    const renderAction = (enabled: boolean, getAddress?: () => AddressDetailsData): JSX.Element => {
        const handleClick = (): void => {
            const address = getAddress?.();
            if (address) {
                handleConfirm(address);
            }
        };
        return <button type="button" id="addressSearch-add-button" className="action-button" disabled={!enabled} onClick={handleClick}>Create & Select Address</button>;
    };

    if (show) {
        return (
            <React.Fragment>
                <button type="button" id="addressSearch-add-address" className="action-button" onClick={handleActionClick}>Add Address</button>
                <AddressDetailsDialog
                    title="Add Address"
                    open={dialogOpen}
                    initialAddress={{}}
                    renderAction={renderAction}
                    onCancel={handleCloseDialog}
                />
            </React.Fragment>
        );
    }

    return <React.Fragment />;
};

export type { AddAddressProps };
export default AddAddress;