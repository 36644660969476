export default `
fragment WaterSource on IWaterSource {
    waterSourceNodeId: id
    waterSourceId: externalId
}
fragment Defect on Defect {
    defectNodeId: id
    defectId
    type {
        displayText
        value
    }
}
fragment Inspection on Inspection {
    inspectionNodeId: id
    inspectionId
    type {
        displayText
        value
    }
}
mutation updateRepair_Mutation($repair: EditRepairInput!) {
    updateRepair(input:$repair) {
        clientMutationId
        repair {
            repairNodeId: id          
            repairId
            waterSource {
                ...WaterSource
            }
            defect {
                ...Defect
            }
            inspection {
                ...Inspection
            }
            repairCategory {
                displayText
                value
            }
            repairType {
                displayText
                value
            }
            repairVendor {
                displayText
                value
            }
            expectedCost
            actualCost
            costSaving
            isPaymentAuthorised
            workOrderRef
            purchaseOrderRef
            invoiceRef
            dateWorkCompleted
            dateInvoiced
            datePaid
            cancelReason {
                displayText
                value
            }
            
            isWorkCompleted
            isInvoiced
            isPaymentAuthorised
            isPaid
            isCancelled
            lastEvent {
                action
                actor
                timestamp
            }
            events {
                action
                actor
                timestamp
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
        }
        hasErrors
        errors {
            message
            path
        }
    }
}`