import React, { Component, Fragment } from "react";
import { Switch } from "@blueprintjs/core";
import { LocalDate } from "@js-joda/core";

import { AccessControl } from "../../../../auth/components";
import { RoleNames } from "../../../../auth/roles";
import ControlHeader from "../../../../components/controlHeader/controlHeader";
import FileUploader from "../../../../components/files/fileUploader";
import LabelledField, { SelectOption, LabelledFieldProps, asLookupItem, asOption } from "../../../../components/labelledField";
import Tags from "../../../../components/tags";
import { DialogVariant } from "../../../../components/utils/globalDialog";
import { HazardItem } from "../../../../components/waterSource/waterSourceControl/types";
import { parseNodeId, NodeID } from "../../../../lib/nodeIdentifier";
import { guid, handleValidation } from "../../../../lib/Utils";
import {
    IPoint,
    ILookupItem,
    LookupOwnerType,
    IRoute,
    IEditHydrantInput,
    IEditEmergencyWaterSupplyInput,
    IEditRiserOrUnknownInput,
    IUpdateWaterSourceActivationInput,
    ILookups,
    IWaterSource,
    IGlobalToast,
    IScheme,
    IHazard,
    AddressNode
} from "../../../../store/types";
import MapReference, { MapReferenceProps } from "./components/mapReference";
import {
    getClassificationId,
    getLocationCoordinates,
    getIsDefective,
    getIsOperable,
    getPumpTypes,
    resetEditData,
    getStatus,
    getHydrantLocation,
    getSurface,
    getPressure,
    getFlowRate,
    getMainsSizeUnit,
    getMainsSize,
    getAssetReference,
    getOrganisation,
    getStation,
    getInspectionFrequency,
    getRiskSeverity,
    getHazardSeverity,
    getLocationDescription,
    getLocationAddressId,
    operationalStatuses,
    getOperationalStatus,
    getOverrideState,
    getNextInspectionDate,
    IWaterSourceEditData,
    getAdditionalInfo,
    getHazardTypes,
    prepareHazardsToSave,
    getPlateLocation,
    getPlateDistance,
} from "./waterSourceControl.utils";

import "./waterSourceControl.scss";
import { formatNextInspectionValue, formatNextInspectionTag } from "../../../../lib/formatters";
import { IPrepareHazardsToSaveData } from "../../../../components/waterSource/waterSourceControl/types";
import Address, { AddressDetailsData, toAddressDetails, toAddressNode } from "../../../../components/address";
import WaterSourceSchemeLinks from "../../../../components/waterSource/waterSourceControl/components/WaterSourceSchemeLinks";

interface IWaterSourceControlProps {
    readonly editing: boolean;
    readonly editWaterSourceSuccess: boolean;
    readonly lookups: ILookups;
    readonly movingWaterSource?: boolean;
    readonly movingWaterSourceLocation?: [number, number];
    readonly selectedWaterSource: IWaterSource;
    readonly fileStoreToken: string;
    readonly fullPageLayout?: true;
    readonly editEmergencyWaterSupply: (variables: IEditEmergencyWaterSupplyInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly editHydrant: (variables: IEditHydrantInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly editRiser: (variables: IEditRiserOrUnknownInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly editUnknownWaterSource: (variables: IEditRiserOrUnknownInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly onRefresh?: () => void;
    readonly onSaveHazards?: (added: HazardItem[], removed: IHazard[]) => void;
    readonly onShowRoute?: (id: NodeID) => void;
    readonly onShowScheme?: (id: NodeID) => void;
    readonly startUpdateWaterSourceActivation: (variables: IUpdateWaterSourceActivationInput) => void;
    readonly setDialogOpen: (dialog: DialogVariant) => void;
    readonly setEditWaterSource: (editing: boolean) => void;
    readonly setEditWaterSourceSuccess: () => void;
    readonly setGlobalToast: (toastProps?: IGlobalToast) => void;
    readonly setMovingWaterSource: (movingWaterSource: boolean) => void;
}

interface IWaterSourceControlState {
    readonly shouldReRenderMap: boolean;
    readonly isActive: boolean;
    readonly shouldValidate: boolean;
    readonly locationContainerHover: boolean;
    readonly initialLocation: IPoint;
    readonly initialHazards: SelectOption[];
    readonly data: IWaterSourceEditData;
}

class WaterSourceControl extends Component<IWaterSourceControlProps, IWaterSourceControlState> {
    constructor(props: IWaterSourceControlProps) {
        super(props);

        this.state = {
            shouldReRenderMap: false,
            shouldValidate: false,
            isActive: props.selectedWaterSource.isActive,
            locationContainerHover: false,
            initialLocation: props.selectedWaterSource.location.coordinates,
            initialHazards: getHazardTypes(props.selectedWaterSource.hazards),
            data: {
                ...resetEditData(props.selectedWaterSource)
            }
        };
    }

    public componentDidUpdate(prevProps: IWaterSourceControlProps): void {
        if (prevProps.selectedWaterSource.waterSourceNodeId !== this.props.selectedWaterSource.waterSourceNodeId) {
            this.props.setEditWaterSource(false);
            this.setState({ isActive: this.props.selectedWaterSource.isActive });
            this.resetData();
        }

        const isEqual = (a: IPoint, b: IPoint): boolean => {
            return a?.x === b?.x && a?.y === b?.y;
        };

        if (this.props.editWaterSourceSuccess && isEqual(prevProps.selectedWaterSource.location.coordinates, this.props.selectedWaterSource.location.coordinates)) {
            this.props.setEditWaterSource(false);
            this.props.setEditWaterSourceSuccess();
        }

        if (!isEqual(prevProps.selectedWaterSource.location.coordinates, this.props.selectedWaterSource.location.coordinates)) {
            this.setState(state => ({
                data: {
                    ...state.data,
                    locationCoordinates: this.props.selectedWaterSource.location.coordinates
                }
            }));
        }

        if (!this.props.movingWaterSource && this.props.movingWaterSourceLocation) {
            this.setState({ shouldReRenderMap: true }, () => {
                if (this.props.movingWaterSourceLocation) {
                    this.saveWaterSource();
                    this.props.setMovingWaterSource(false);
                }
            });
        }

        if (prevProps.selectedWaterSource.hazards !== this.props.selectedWaterSource.hazards) {
            this.setState({ initialHazards: getHazardTypes(this.props.selectedWaterSource.hazards) });
        }
    }

    public componentWillUnmount(): void {
        this.props.setGlobalToast()
        this.props.setEditWaterSource(false)
    }

    public render(): JSX.Element {
        const { selectedWaterSource } = this.props;
        const { schemes, route } = selectedWaterSource;
        const { operationalStatusOverride } = this.state.data;

        const operationalStatus = getOperationalStatus(selectedWaterSource.isOperable, selectedWaterSource.isDefective, operationalStatusOverride);
        const tagOwner = (categoryId: number): LookupOwnerType | undefined => {
            switch (categoryId) {
                case 0:
                    return "UnknownWaterSource";
                case 1:
                    return "Hydrant";
                case 2:
                    return "EmergencyWaterSupply";
                case 3:
                    return "Riser";
            }
        };

        const waterSourceNodeId = parseNodeId(selectedWaterSource.waterSourceNodeId);
        return (
            <div className="waterSourceControl">
                <div className="waterSource">
                    <div className="wm-actions">
                        {this.renderActions()}
                    </div>
                    <ControlHeader
                        editing={this.props.editing}
                        primary={this.props.selectedWaterSource.waterSourceId.toString()}
                        primaryKey={"waterSourceId"}
                        hidePrimary={this.props.fullPageLayout}
                        renderPrimaryAsLink={!this.props.fullPageLayout}
                        secondaryKey="operationalStatusOverride"
                        secondaryValue={asLookupItem(operationalStatusOverride)}
                        secondaryReadOnlyValue={asLookupItem(operationalStatus)}
                        secondaryOptions={operationalStatuses}
                        secondaryClass={handleValidation(this.state.shouldValidate, operationalStatus)}
                        secondaryFooter={getOverrideState(operationalStatusOverride)}
                        onSecondaryChange={(key, value): void => this.updateState(key as keyof IWaterSourceEditData, value, true)}
                        tertiaryKey="category"
                        tertiaryReadOnlyValue={this.props.selectedWaterSource.category}
                        waterSourceNodeId={this.props.selectedWaterSource.waterSourceNodeId}
                    />
                    <div className="waterSourceControl--fullPage">
                        <div className="waterSourceControl__meta_one">
                            {this.renderActive()}
                            <Tags
                                id="waterSource-tags"
                                label="Tags"
                                tags={this.props.selectedWaterSource.tags ?? []}
                                editing={this.props.editing}
                                ownerNodeId={parseNodeId(this.props.selectedWaterSource.waterSourceNodeId)}
                                onChange={(): void => this.setState({ shouldReRenderMap: true })}
                            />
                            {this.renderOrganisation()}
                            {this.props.selectedWaterSource.category.value > 0 && this.renderClassification()}
                            {this.renderStatus()}
                            {this.renderStation()}
                            {this.renderRoute(route)}
                            {this.renderLastInspectionDate()}
                            {this.renderNextInspectionDate()}
                            {this.renderInspectionFrequency()}
                            {this.props.selectedWaterSource.category.value === 1 && (
                                <Fragment>
                                    {this.renderAssetReference()}
                                    {this.renderSurface()}
                                </Fragment>
                            )}
                            {this.props.selectedWaterSource.category.value === 1 && (
                                <Fragment>
                                    {this.renderFlowRate()}
                                    {this.renderPressure()}
                                    {this.renderMainsSize()}
                                    {this.renderPlateLocation()}
                                    {this.renderPlateDistance()}
                                </Fragment>
                            )}
                            {this.renderRiskSeverity()}
                            {this.renderHazardSeverity()}
                            {this.renderHazardsList()}
                            {this.renderSchemeLink(schemes)}
                            {this.renderAdditionalInfo()}
                        </div>
                        <div className="waterSourceControl__meta_two">
                            {this.renderLocation()}
                            {this.renderLocationDescription()}
                            {this.renderAddress()}
                            {this.props.selectedWaterSource.category.value === 1 && this.renderHydrantLocation()}
                            <FileUploader entity={waterSourceNodeId.typeName.toUpperCase()} entityId={waterSourceNodeId.id.toString()} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderActive(): JSX.Element {
        const { isActive } = this.state;
        const { selectedWaterSource } = this.props;
        const toggleLabel = isActive ? "ACTIVE" : "INACTIVE";
        const handleIsActiveChange = (): void => {
            const value = !isActive;
            this.setState({ isActive: value, shouldReRenderMap: value !== selectedWaterSource.isActive });
        }

        if (this.props.editing) {
            return (
                <div>
                    <div className="switchContainer">
                        <Switch
                            checked={isActive}
                            labelElement={<label className="switchLabel" htmlFor="water-source-active">{toggleLabel}</label>}
                            onChange={handleIsActiveChange}
                            id="water-source-active"
                            large
                        />
                    </div>
                </div>
            );
        }
        return <LabelledField label="" id="water-source-active" value={toggleLabel} editing={this.props.editing} fieldType="text" onChange={(): void => undefined} />;
    }

    private renderOrganisation(): JSX.Element {
        const { organisations } = this.props.lookups;
        const options: SelectOption[] = organisations
            ? this.props.lookups.organisations
                .filter((organisation) => organisation.enabled)
                .map(asOption)
            : [];

        const props: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-organisation",
            label: "Organisation",
            value: this.state.data.organisation,
            selectOptions: options,
            selectOptionGroups: this.props.lookups.organisationTypes,
            editing: this.props.editing,
            onChange: (value): void => this.updateState("organisation", value),
            onClear: (): void => this.updateState("organisation", undefined)
        };
        return <LabelledField {...props} />
    }

    private renderClassification(): JSX.Element {
        const classificationProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-classification",
            label: "Classification",
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.classification) },
            value: this.state.data.classification,
            selectOptions: this.props.lookups.waterSourceClasses.filter((classification: ILookupItem) => classification.groupingId === this.props.selectedWaterSource.category.value),
            editing: this.props.editing,
            onChange: (value): void => this.updateState("classification", value),
            onClear: (): void => this.updateState("classification", undefined)
        };
        return <LabelledField {...classificationProps} />;
    }

    private renderStatus(): JSX.Element {
        const statusProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-status",
            label: "Status",
            value: this.state.data.status,
            selectOptions: this.props.lookups.waterSourceStatuses.filter((status: ILookupItem) => status.groupingId === this.props.selectedWaterSource.category.value),
            editing: this.props.editing,
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.status) },
            onChange: (value): void => this.updateState("status", value, true),
            onClear: () => this.updateState("status", undefined)
        };
        return <LabelledField {...statusProps} />;
    }

    private renderLocation(): JSX.Element {
        const { editing, fullPageLayout, selectedWaterSource, setMovingWaterSource } = this.props;
        const props: MapReferenceProps = {
            id: "water-source-coordinates",
            coordinates: this.state.data.locationCoordinates ?? selectedWaterSource.location.coordinates,
            editing,
            hideSearch: fullPageLayout,
            onChange: (value): void => {
                this.updateState("locationCoordinates", value, true);
            },
            onSearchClick: (): void => {
                setMovingWaterSource(true);
                this.setState({
                    shouldReRenderMap: true
                });
            }
        };
        return <MapReference {...props} />;
    }
    private renderLocationDescription(): JSX.Element {
        const locationDescriptionProps: LabelledFieldProps = {
            id: "water-source-location-description",
            fieldType: "text",
            label: "Location Description",
            value: this.state.data.locationDescription,
            editing: this.props.editing,
            onChange: (value) => this.updateState("locationDescription", value)
        };
        return <LabelledField {...locationDescriptionProps} fieldType="text" />;
    }

    private renderHydrantLocation(): JSX.Element {
        const hydrantLocationProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-hydrant-location",
            label: "Hydrant Location",
            value: this.state.data.hydrantLocation,
            selectOptions: this.props.lookups.hydrantLocations,
            editing: this.props.editing,
            onChange: (value) => this.updateState("hydrantLocation", value),
            onClear: () => this.updateState("hydrantLocation", undefined)
        };
        return <LabelledField {...hydrantLocationProps} fieldType="select" />
    }

    private renderAddress(): JSX.Element {
        const handleAddressClear = (): void => {
            this.updateState("address", undefined);
        };
        const handleAddressSelect = (address: AddressDetailsData): void => {
            this.updateState("address", toAddressNode(address));
        };
        const handleAddressUpdate = (address: AddressDetailsData): void => {
            this.updateState("address", toAddressNode(address));
            this.props.onRefresh?.();
        };

        return (
            <div className="addressContainer">
                <label className="addressLabel">Address</label>
                <Address
                    id="water-source"
                    address={toAddressDetails(this.state.data.address ?? {})}
                    owner="water source"
                    editing={this.props.editing}
                    onClear={handleAddressClear}
                    onSelect={handleAddressSelect}
                    onUpdate={handleAddressUpdate}
                />
            </div>
        );
    }

    private renderAssetReference(): JSX.Element {
        const assetReferenceProps: LabelledFieldProps = {
            fieldType: "text",
            label: "Asset Reference",
            value: this.state.data.assetReference,
            editing: this.props.editing,
            onChange: (value) => this.updateState("assetReference", value),
            id: "water-source-assetRef"
        };
        return <LabelledField {...assetReferenceProps} />;
    }

    private renderRiskSeverity(): JSX.Element {
        const riskSeverityProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-riskSeverity",
            label: "Risk Severity",
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.riskSeverity) },
            value: this.state.data.riskSeverity,
            selectOptions: this.props.lookups.riskSeverity,
            editing: this.props.editing,
            onChange: (value) => this.updateState("riskSeverity", value),
            onClear: () => this.updateState("riskSeverity", undefined)
        };
        return <LabelledField {...riskSeverityProps} fieldType="select" />;
    }

    private renderHazardsList(): JSX.Element {
        const hazardsProps: LabelledFieldProps = {
            fieldType: "multiselect",
            id: "water-source-hazards-list",
            label: "Hazards",
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.station) },
            options: this.props.lookups.hazardTypes,
            selectedOptions: this.state.data.hazards,
            editing: this.props.editing,
            onChange: (value) => this.updateState("hazards", value),
            onClear: undefined
        };
        return <LabelledField {...hazardsProps} />;
    }

    private renderHazardSeverity(): JSX.Element {
        const hazardSeverityProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-hazardSeverity",
            label: "Hazard Severity",
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.hazardSeverity) },
            value: this.state.data.hazardSeverity,
            selectOptions: this.props.lookups.riskSeverity,
            editing: this.props.editing,
            onChange: (value) => this.updateState("hazardSeverity", value),
            onClear: () => this.updateState("hazardSeverity", undefined)
        };
        return <LabelledField {...hazardSeverityProps} />;
    }

    private renderFlowRate(): JSX.Element {
        const flowRateProps: LabelledFieldProps = {
            fieldType: "number",
            id: "water-source-flow-rate",
            label: "Flow Rate",
            value: this.state.data.flowRate,
            minValue: undefined,
            editing: this.props.editing,
            onChange: (value) => this.updateState("flowRate", value),
        };
        return <LabelledField {...flowRateProps} />;
    }

    private renderPressure(): JSX.Element {
        const pressureProps: LabelledFieldProps = {
            fieldType: "number",
            id: "water-source-pressure",
            label: "Pressure",
            value: this.state.data.pressure,
            minValue: undefined,
            editing: this.props.editing,
            onChange: (value) => this.updateState("pressure", value),
        };
        return <LabelledField {...pressureProps} />;
    }

    private renderMainsSize(): JSX.Element {
        const mainsSizeProps: LabelledFieldProps = {
            fieldType: "number",
            id: "water-source-mains-size",
            label: "Mains Size",
            value: this.state.data.mainsSize,
            minValue: undefined,
            editing: this.props.editing,
            onChange: (value) => this.updateState("mainsSize", value),
        };
        const mainsSizeUnitsProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-mains-size-units",
            label: "",
            value: this.state.data.mainsSizeUnit,
            selectOptions: this.props.lookups.mainsSizeUnits,
            editing: this.props.editing,
            onChange: (value) => this.updateState("mainsSizeUnit", value),
            onClear: () => this.updateState("mainsSizeUnit", undefined)
        };
        return (
            <div className="mains-size-fields">
                <LabelledField {...mainsSizeProps} />
                <LabelledField {...mainsSizeUnitsProps} />
            </div>
        );
    }

    private renderPlateLocation(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-plateLocation",
            label: "Plate Location",
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.plateLocation) },
            value: this.state.data.plateLocation,
            selectOptions: this.props.lookups.plateLocations,
            editing: this.props.editing,
            onChange: (value) => this.updateState("plateLocation", value),
            onClear: () => this.updateState("plateLocation", undefined)
        };
        return <LabelledField {...props} />;
    }

    private renderPlateDistance(): JSX.Element {
        const pressureProps: LabelledFieldProps = {
            fieldType: "number",
            id: "water-source-plateDistance",
            label: "Plate Distance",
            value: this.state.data.plateDistance,
            minValue: undefined,
            editing: this.props.editing,
            onChange: (value) => this.updateState("plateDistance", value),
        };
        return <LabelledField {...pressureProps} />;
    }

    private renderLastInspectionDate(): JSX.Element {
        const lastInspectionDateProps: LabelledFieldProps = {
            fieldType: "date",
            id: "water-source-last-inspection-date",
            label: "Last Inspection Date",
            value: this.props.selectedWaterSource.lastInspectionDate,
            editing: false,
            onChange: () => undefined,
        };
        return <LabelledField {...lastInspectionDateProps} />;
    }

    private renderNextInspectionDate(): JSX.Element {
        if (this.props.editing) {
            const editorProps: LabelledFieldProps = {
                fieldType: "date",
                id: "water-source-next-inspection-date",
                label: "Next Inspection Date",
                value: this.state.data.nextInspectionDate,
                editing: true,
                onChange: (value) => this.updateState("nextInspectionDate", value),
            };
            return <LabelledField {...editorProps} />;
        }

        const { nextInspectionDate, recommendedInspectionDate } = this.props.selectedWaterSource;

        const readOnlyProps: LabelledFieldProps = {
            fieldType: "readonly",
            id: "water-source-next-inspection-date",
            label: "Next Inspection Date",
            value: `${formatNextInspectionValue(nextInspectionDate, recommendedInspectionDate)} ${formatNextInspectionTag(nextInspectionDate, recommendedInspectionDate)}`
        };
        return <LabelledField {...readOnlyProps} />;
    }

    private renderInspectionFrequency(): JSX.Element {
        const { inspectionFrequency } = this.state.data;

        const inspectionFrequencyEditingProps: LabelledFieldProps = {
            fieldType: "number",
            id: "water-source-inspection-frequency",
            label: "Inspection Frequency",
            value: inspectionFrequency,
            minValue: 1,
            editing: this.props.editing,
            onChange: (value) => this.updateState("inspectionFrequency", value),
        };

        if (this.props.editing) {
            return <LabelledField {...inspectionFrequencyEditingProps} />;
        }

        const inspectionFrequencyReadOnlyProps: LabelledFieldProps = {
            fieldType: "text",
            id: "water-source-inspection-frequency",
            label: "Inspection Frequency",
            value: inspectionFrequency ? `${inspectionFrequency} months` : undefined,
            editing: this.props.editing,
            onChange: (value) => this.updateState("inspectionFrequency", value),
        };

        return <LabelledField {...inspectionFrequencyReadOnlyProps} />;
    }

    private renderStation(): JSX.Element {
        const stationProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-station",
            label: "Station *",
            value: this.state.data.station,
            selectOptions: this.props.lookups.stations,
            editing: this.props.editing,
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.station) },
            onChange: (value) => this.updateState("station", value),
            onClear: () => this.updateState("station", undefined)
        };
        return <LabelledField {...stationProps} />;
    }

    private renderSurface(): JSX.Element {
        const surfaceProps: LabelledFieldProps = {
            fieldType: "select",
            id: "water-source-surface",
            label: "Surface",
            value: this.state.data.surface,
            selectOptions: this.props.lookups.surfaces,
            editing: this.props.editing,
            onChange: (value) => this.updateState("surface", value),
            onClear: () => this.updateState("surface", undefined)
        };
        return <LabelledField {...surfaceProps} />;
    }

    private renderActions(): JSX.Element {
        return this.props.editing
            ? (
                <Fragment>
                    <button id="save-button" className="action-button" onClick={(): void => this.handleSaveClick()}>Save</button>
                    <button id="cancel-button" className="action-button action-button--cancel" onClick={(): void => this.handleEditCancelClick()}>Cancel</button>
                </Fragment>
            )
            : (
                <AccessControl role={RoleNames.WS_ALL}>
                    <button id="edit-button" className="action-button" onClick={(): void => this.props.setEditWaterSource(true)}>Edit</button>
                </AccessControl>
            );
    }

    private renderRoute(route: IRoute | undefined): JSX.Element {
        const handleShowRoute = (): void => {
            if (route) {
                this.props.onShowRoute?.(parseNodeId(route?.routeNodeId))
            }
        };
        const id = "water-source-route";
        const label = "Route";
        if (route) {
            return (
                <LabelledField
                    fieldType="link"
                    id={id}
                    label={label}
                    linkText={`${route.routeId} - ${route.name}`}
                    preventDefault
                    onClick={handleShowRoute}
                />
            );
        }
        return <LabelledField fieldType="readonly" id={id} label={label} value="-" />;
    }

    private renderSchemeLink(schemes: IScheme[] | undefined): JSX.Element | undefined {
        const handleClick = (id: NodeID): void => {
            this.props.onShowScheme?.(id);
        };
        return <WaterSourceSchemeLinks schemes={schemes ?? []} onClick={handleClick} />
    }

    private renderAdditionalInfo(): JSX.Element {
        const props: LabelledFieldProps = {
            id: "water-source-additionalInfo",
            fieldType: "text",
            label: "Additional Information",
            value: this.state.data.additionalInfo,
            editing: this.props.editing,
            multiline: true,
            onChange: (value) => this.updateState("additionalInfo", value)
        };
        return <LabelledField {...props} />;
    }

    private handleEditCancelClick(): void {
        this.props.setMovingWaterSource(false);
        this.props.setEditWaterSource(false);
        this.resetData();

        this.setState({
            shouldValidate: false
        });
    }

    private handleSaveClick(): void {
        this.saveWaterSource();
    }

    private resetData(): void {
        this.setState((prevState: IWaterSourceControlState, props: IWaterSourceControlProps) => {
            const state: IWaterSourceControlState = {
                ...prevState,
                data: {
                    ...resetEditData(props.selectedWaterSource)
                }
            };
            return state;
        });
    }

    private saveHazards(): void {
        const hazardSeverityValue = this.state.data.hazardSeverity?.value;
        const hazardsValues = this.state.data.hazards.map(hazard => hazard.value);
        const prepareHazardsToSaveData: IPrepareHazardsToSaveData = {
            hazardsValues,
            hazardSeverityValue,
            allHazardsTypes: this.props.lookups.hazardTypes,
            initialHazardsValues: this.state.initialHazards.map(initialHazard => initialHazard.value),
            allHazards: this.props.selectedWaterSource.hazards
        };

        const { addedHazards, deletedHazards } = prepareHazardsToSave(prepareHazardsToSaveData);

        if (!hazardsValues.length) {
            this.setState(state => ({
                data: {
                    ...state.data,
                    hazardSeverity: undefined
                }
            }));
        }

        this.props.onSaveHazards?.(addedHazards, deletedHazards);
    }

    private saveWaterSource(): void {
        this.setState({ shouldValidate: true });

        this.props.setGlobalToast()

        const { selectedWaterSource } = this.props;
        const { data, shouldReRenderMap } = this.state;

        const categoryId = selectedWaterSource.category.value;
        const variables: IEditHydrantInput | IEditEmergencyWaterSupplyInput | IEditRiserOrUnknownInput = {
            input: {
                clientMutationId: guid(),
                nodeId: selectedWaterSource.waterSourceNodeId,
                data: {
                    // all water sources
                    classificationId: getClassificationId(data, categoryId),
                    isDefective: getIsDefective(data),
                    isOperable: getIsOperable(data),
                    statusId: getStatus(data),
                    locationAddressId: getLocationAddressId(data),
                    locationCoordinates: getLocationCoordinates(data, selectedWaterSource.location.coordinates, this.props.movingWaterSourceLocation),
                    locationDescription: getLocationDescription(data),
                    riskSeverityId: getRiskSeverity(data),
                    hazardSeverityId: getHazardSeverity(data),
                    inspectionFrequency: getInspectionFrequency(data),
                    nextInspectionDate: getNextInspectionDate(data),
                    stationId: getStation(data),
                    organisationId: getOrganisation(data),
                    additionalInfo: getAdditionalInfo(data),
                    controlMeasures: [],
                    // hydrants
                    assetReference: getAssetReference(data, categoryId),
                    mainsSize: getMainsSize(data, categoryId),
                    mainsSizeUnitId: getMainsSizeUnit(data, categoryId),
                    flowRate: getFlowRate(data, categoryId),
                    pressure: getPressure(data, categoryId),
                    surfaceId: getSurface(data, categoryId),
                    hydrantLocationId: getHydrantLocation(data, categoryId),
                    plateLocationId: getPlateLocation(data, categoryId),
                    plateDistance: getPlateDistance(data, categoryId),
                    // ews
                    pumpTypes: getPumpTypes(categoryId), // non null field for ews only
                }
            }
        };

        switch (selectedWaterSource.category.value) {
            case 1:
                this.props.editHydrant(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
            case 2:
                this.props.editEmergencyWaterSupply(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
            case 3:
                this.props.editRiser(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
            default:
                this.props.editUnknownWaterSource(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
        }

        if (this.props.selectedWaterSource.isActive !== this.state.isActive) {
            this.updateActiveStatus();
        }

        this.props.setEditWaterSource(false);

        this.saveHazards();

        this.setState({ shouldReRenderMap: false });
    }

    private updateActiveStatus(): void {
        const variables: IUpdateWaterSourceActivationInput = {
            waterSource: {
                clientMutationId: guid(),
                nodeId: this.props.selectedWaterSource.waterSourceNodeId,
                data: this.state.isActive ? "ACTIVE" : "INACTIVE"
            }
        };
        this.props.startUpdateWaterSourceActivation(variables);
    }

    private updateState(key: keyof IWaterSourceEditData, value: IWaterSourceEditData[keyof IWaterSourceEditData], shouldReRenderMap?: boolean): void {
        this.setState(current => ({
            ...current,
            shouldReRenderMap: shouldReRenderMap ?? false,
            initialLocation: {
                ...current.initialLocation
            },
            data: {
                ...current.data,
                [key]: value
            }
        }));
    }
}

export type { IWaterSourceControlProps };
export default WaterSourceControl;