import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Spinner } from "@blueprintjs/core";

import { red } from "@material-ui/core/colors";
import PlaceIcon from "@material-ui/icons/Place";

import { Coordinate as olCoordinate } from "ol/coordinate";
import OverlayPositioning from "ol/OverlayPositioning";
import olFeature from "ol/Feature";
import olSelect from "ol/interaction/Select"
import olLayer from "ol/layer/Layer";
import olVectorLayer from "ol/layer/Vector";
import olMap from "ol/Map";
import olMapBrowserEvent from "ol/MapBrowserEvent";
import olOverlay from "ol/Overlay";
import olVectorSource from "ol/source/Vector";
import { Style as olStyle, Fill as olFill, Stroke as olStroke, Circle as olCircle } from "ol/style";
import olView from "ol/View";

import { vectorSource } from "../../../lib/map/Map";
import useTileLayer from "../useTileLayer";
import MapWarning from "../MapWarning";
import { Projection, TileServer, View, MapDataLike, MapDataAsync, MapData } from "./types";
import css from "./map.module.scss";
import "./map.scss";
import { MapSupplier } from "../../../store/types";

const defaultZoom = 18;

interface MapParams {
    readonly zoom?: number;
}

const createPointLayer = (features: olFeature[] = []): olVectorLayer => {
    const source = new olVectorSource({
        features: features
    });
    const layer = new olVectorLayer({
        source: source,
        maxResolution: undefined,
        style: new olStyle({
            fill: new olFill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new olStroke({
                color: '#ffcc33',
                width: 2
            }),
            image: new olCircle({
                radius: 7,
                fill: new olFill({
                    color: '#ffcc33'
                })
            })
        })
    });
    return layer;
};

interface MapSelectHandler {
    (data: unknown): void;
}

const createSelect = (layers: olLayer[], handleSelect: MapSelectHandler | undefined): olSelect => {
    const select = new olSelect({
        layers
    });
    select.on("select", e => {
        if (e.selected.length > 0) {
            const feature = e.selected[0];
            const data = feature.getProperties();
            handleSelect?.(data);
            select.getFeatures().clear();
        }
    });
    return select;
};

const createView = (props: View, projection: Projection, params?: MapParams): olView => {
    const { code } = projection;
    const { centre, extent } = props;
    const view = new olView({
        extent: [extent.min.x, extent.min.y, extent.max.x, extent.max.y],
        projection: code,
        center: [centre.x, centre.y],
        zoom: params?.zoom ?? defaultZoom
    });
    return view;
};

interface MapProps {
    readonly className?: string;
    readonly data: MapDataLike;
    readonly params?: MapParams;
    readonly settings: {
        readonly projection: Projection;
        readonly tileServer: TileServer;
        readonly view: View;
        readonly supplier?: MapSupplier;
    };
    readonly deviceLocation?: olFeature | undefined;
    readonly editing?: boolean;
    readonly onSelect?: MapSelectHandler;
    readonly onMoveLocation?: (coordinates: olCoordinate) => void;
}

const MapViewer = (props: MapProps): JSX.Element => {
    const { data, params, settings, deviceLocation, editing, onSelect, onMoveLocation } = props;
    const { projection, tileServer, view, supplier } = settings;

    const containerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<olMap>();
    const pointLayerRef = useRef<olVectorLayer>();
    const locationLayerRef = useRef<olVectorLayer>();
    const markerRef = useRef<olOverlay>();
    const [markerAnchor, setMarkerAnchor] = useState<HTMLElement | null>(null);
    const [centre, setCentre] = useState<olCoordinate>();
    const [coordinates, setCoordinates] = useState<olCoordinate>();
    const [isLoading, setIsLoading] = useState(false);
    const [isMapInitialised, setIsMapInitialised] = useState(false);

    const handleMapClick = (event: olMapBrowserEvent): void => {
        setCoordinates(event.coordinate);
    };

    const { tileLayer, showWarning } = useTileLayer(tileServer) ?? {};

    // ensure OL map object is re-initialised whenever parameters change
    useEffect(() => {
        if (containerRef.current && tileLayer) {
            const pointLayer = createPointLayer();
            const locationLayer = createPointLayer();
            const select = createSelect([pointLayer], onSelect);
            const marker = new olOverlay({
                offset: [0, -10],
                positioning: OverlayPositioning.CENTER_CENTER,
                stopEvent: false
            });
            const map = new olMap({
                target: containerRef.current,
                layers: [
                    tileLayer,
                    pointLayer,
                    locationLayer
                ],
                controls: [],
                view: createView(view, projection, params)
            });
            map.addInteraction(select);
            map.addOverlay(marker);
            map.on("click", handleMapClick);
            pointLayerRef.current = pointLayer;
            locationLayerRef.current = locationLayer;
            markerRef.current = marker;
            mapRef.current = map;

            setIsMapInitialised(true);
        }

        return (): void => {
            markerRef.current?.dispose();
            markerRef.current = undefined;
            locationLayerRef.current?.dispose();
            locationLayerRef.current = undefined;
            pointLayerRef.current?.dispose();
            pointLayerRef.current = undefined;
            // calling olMap.dispose() somehow breaks the map rendering
            mapRef.current = undefined;

            setIsMapInitialised(false);
        };
    }, [params, projection, tileLayer, view, onSelect]);

    // ensure features are re-drawn whenever features change
    useEffect(() => {
        let isMounted = true;

        const { current: map } = mapRef;
        const { current: pointLayer } = pointLayerRef;
        if (isMapInitialised && map && pointLayer) {
            const getMapData = typeof data === "function"
                ? data
                : async (): MapDataAsync => data;

            const setMapData = (data: MapData): olCoordinate => {
                const [center, features] = data;
                const source = vectorSource(features);
                pointLayer.setSource(source);
                map.getView().animate({ center });

                return center;
            };

            setIsLoading(true);
            getMapData()
                .then(setMapData)
                .then(centre => isMounted ? setCentre(centre) : undefined)
                .catch(error => console.warn(error))
                .finally(() => isMounted ? setIsLoading(false) : undefined);
        }

        return (): void => {
            isMounted = false;
        };
    }, [isMapInitialised, data, setIsLoading]);

    // render device location
    useEffect(() => {
        const { current: map } = mapRef;
        const { current: locationLayer } = locationLayerRef;
        if (map && locationLayer && deviceLocation) {
            const source = vectorSource([deviceLocation]);
            locationLayer.setSource(source);
        }
    }, [mapRef.current, locationLayerRef.current, deviceLocation]);

    // reset coordinates state on start edit
    const prevEditing = useRef(editing);
    useEffect(() => {
        if (!prevEditing.current && editing) {
            setCoordinates(undefined);
        }
        prevEditing.current = editing;
    }, [markerRef, prevEditing, editing, setCoordinates]);

    // show and move the 'edit location' marker
    useEffect(() => {
        const { current: marker } = markerRef;
        marker?.setElement(editing ? (markerAnchor ?? undefined) : undefined);
    }, [markerRef, editing, markerAnchor]);
    useEffect(() => {
        const { current: marker } = markerRef;
        marker?.setPosition(editing ? (coordinates ?? centre) : undefined);
    }, [markerRef, editing, coordinates, centre]);

    // when editing, raise the onMoveLocation 'event'
    useEffect(() => {
        if (editing && coordinates) {
            const x = Math.round(coordinates[0]);
            const y = Math.round(coordinates[1]);
            onMoveLocation?.([x, y]);
        }
    }, [editing, coordinates]);

    return (
        <div className={css.root}>
            {isLoading &&
                <div className={css.loadingOverlay}>
                    <Spinner size={Spinner.SIZE_STANDARD} className={"mobile-map"} />
                </div>
            }
            <div ref={containerRef} className={cx(css.map, props.className, { [css.cadcorp]: supplier === "cadcorp" })} />
            <div className={css.overlayContainer}>
                <div className={css.marker} ref={setMarkerAnchor}>
                    <PlaceIcon height={32} width={32} style={{ color: red[500], fontSize: "32px" }} />
                </div>
            </div>
            <MapWarning open={showWarning ?? false} />
        </div>
    );
};

export type { MapParams, MapProps, MapSelectHandler };
export default MapViewer;