import React, { useState, useRef } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import useMobileViewOption from "../../../lib/hooks/useMobileViewOption";
import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import { ViewOptions, IInspection } from "../../../store/types";
import InspectionListView from '../../../views/inspectionListView';
import InspectionListContext, { InspectionListContextItem, InspectionListContextProps } from "../../../views/inspectionListView/listNavigationContext";
import InspectionView from '../../../views/inspectionView';

const buildNavigationList = (inspections: IInspection[]): InspectionListContextItem[] => {
    const reduce = (list: InspectionListContextItem[], current: NodeID, index: number, source: NodeID[]): InspectionListContextItem[] => {
        const getPrev = (): NodeID | undefined => {
            const isFirst = index === 0;
            return isFirst
                ? undefined
                : source[index - 1];
        };
        const getNext = (): NodeID | undefined => {
            const isLast = index + 1 === source.length;
            return isLast
                ? undefined
                : source[index + 1];
        };

        const prev = getPrev();
        const next = getNext();
        return [...list, { inspection: current, prev, next }];
    };

    const list = inspections
        .map(item => item.inspectionNodeId)
        .map(nodeId => parseNodeId(nodeId))
        .reduce(reduce, []);
    return list;
};

const useViewOption = (): [ViewOptions, (value: ViewOptions | null) => void] => {
    const defaultOption = useMobileViewOption();
    const [view, setView] = useState<ViewOptions>(defaultOption);
    const handleSelectView = (value: ViewOptions | null): void => {
        if (value) {
            setView(value);
        }
    };
    return [view, handleSelectView];
};

const InspectionsRouter = (): JSX.Element => {
    const { path } = useRouteMatch() ?? {};

    const [view, handleSelectView] = useViewOption();

    const contextProps = useRef<InspectionListContextProps>({ list: [] });
    const handleInspectionsLoaded = (inspections: IInspection[]): void => {
        contextProps.current = {
            list: buildNavigationList(inspections)
        };
    };

    return (
        <Switch>
            <InspectionListContext {...contextProps.current}>
                <Route path={`${path}/:inspectionId`}>
                    <InspectionView />
                </Route>
                <Route path={`${path}`} exact>
                    <InspectionListView view={view} onInspectionsLoaded={handleInspectionsLoaded} onSelectView={handleSelectView} />
                </Route>
            </InspectionListContext>
        </Switch>
    );
};

export default InspectionsRouter;