import React from "react";

import ListSubheader from "@material-ui/core/ListSubheader";

import useStyles from "./emptyItem.styles";

const EmptyItem = (props: { message?: string }): JSX.Element => {
    const classes = useStyles();
    return (
        <ListSubheader className={classes.root}>
            {props.message ?? "The list is empty."}
        </ListSubheader>
    );
};

export default EmptyItem;