import React from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";

interface WaterSourceActionItemProps extends
    Pick<MenuItemProps, "id" | "onClick">,
    Pick<ListItemTextProps, "primary"> {
    readonly icon: React.ReactNode;
}

const WaterSourceActionItem = React.forwardRef(function WaterSourceActionItem({ id, primary, icon, onClick }: WaterSourceActionItemProps, ref: React.Ref<HTMLLIElement>): JSX.Element {
    return (
        <MenuItem button dense id={id} onClick={onClick} ref={ref}>
            <ListItemIcon>
                {React.isValidElement(icon)
                    ? React.cloneElement(icon, { fontSize: "small" })
                    : icon
                }
            </ListItemIcon>
            <ListItemText primary={primary} />
        </MenuItem>
    );
});

export type { WaterSourceActionItemProps };
export default WaterSourceActionItem;