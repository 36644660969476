import React from "react";
import { Switch } from "@blueprintjs/core";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ElementContainer from "../../../labelledField/components/elementContainer";
import LabelElement from "../../../labelledField/components/labelElement";
import { containerClass, labelClass, inputClass } from "../../../labelledField/labelledField.utils";
import { EditElementProps } from "../../../labelledField/types";
import ReadOnlyElement from "../readOnlyElement";
import Box from "@material-ui/core/Box";

interface SwitchElementProps extends EditElementProps<boolean> {
    readonly options: {
        readonly checked: string;
        readonly unchecked: string;
    };
    readonly onClear?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    switch: {
        marginBottom: "0 !important"
    },
    stateLabel: {
        color: theme.palette.primary.light,
        "&.on": {
            marginLeft: theme.spacing(1)
        },
        "&.off": {

        },
        "&.checked": {
            color: theme.palette.secondary.main
        }
    },
    clearButton: {
        color: theme.palette.secondary.main,
        cursor: "pointer",
        fontSize: "0.65rem",
        marginLeft: "auto",
        //textAlign: "right",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));

const SwitchElement = (props: SwitchElementProps): JSX.Element => {
    const { editing, id, label, value, options, noMargin, onChange, onClear, ...rest } = props;
    const styles = useStyles();
    const labelPosition = rest.labelPosition ?? "TOP";
    if (editing) {
        const handleChange = (): void => {
            onChange?.(!value);
        };
        const handleClear = (): void => {
            onClear?.();
        };
        return (
            <ElementContainer noMargin={noMargin} classes={containerClass(rest)}>
                {labelPosition === "TOP" && <LabelElement ownerId={id} label={label} classes={labelClass(rest)} />}
                <div className={inputClass(rest)}>
                    <Typography component="div">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Grid component="label" container alignItems="center" spacing={0}>
                                <Grid item component="span" className={cx(styles.stateLabel, { ["checked"]: value !== undefined && !value }, "off")}>
                                    {options.unchecked}
                                </Grid>
                                <Grid item component="span">
                                    <Switch id={id} checked={value} onChange={handleChange} alignIndicator="right" large className={styles.switch} />
                                </Grid>
                                <Grid item component="span" className={cx(styles.stateLabel, { ["checked"]: value }, "on")}>
                                    {options.checked}
                                </Grid>
                            </Grid>
                            {onClear && <span className={styles.clearButton} onClick={handleClear}>clear</span>}
                        </Box>
                    </Typography>
                </div>
                {labelPosition === "BOTTOM" && <LabelElement ownerId={id} label={label} classes={labelClass(rest)} />}
            </ElementContainer>
        );
    }

    const text = value === undefined
        ? "-"
        : value ? options.checked : options.unchecked;
    return <ReadOnlyElement id={id} label={label} noMargin={noMargin} classes={rest.classes} value={text} />;
};

export type { SwitchElementProps };
export default SwitchElement;