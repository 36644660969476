import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from '@blueprintjs/core';

import { handleValidation } from '../../lib/Utils'
import LabelledField from '../labelledField';

interface ISaveFilterDialog {
    readonly isOpen: boolean;
    readonly existingSearchNames: string[];
    readonly onCancel?: () => void;
    readonly onError?: (message: string) => void;
    readonly onSave?: (searchName: string) => void;
}

const SaveFilterDialog = (props: ISaveFilterDialog): JSX.Element => {
    const { isOpen, existingSearchNames, onCancel, onSave, onError } = props;

    const [searchName, setSearchName] = useState<string>();
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [validate, setValidate] = useState(false);

    const toggle = useRef(isOpen);
    useEffect(() => {
        if (isOpen !== toggle.current && isOpen) {
            setSearchName(undefined);
        }
        toggle.current = isOpen;
    }, [isOpen]);

    const isUniqueName = (name: string): boolean => {
        return !existingSearchNames
            .map(n => n.toUpperCase())
            .includes(name.toUpperCase());
    };

    const handleCancelClick = (): void => {
        onCancel?.();
    };

    const handleInputChange = (value: string | undefined): void => {
        const isValid = (value !== undefined) && (value !== null) && (value.length > 0);
        setSearchName(value);
        setSaveDisabled(!isValid);
        setValidate(isValid)
    };

    const handleSaveClick = (): void => {
        if (searchName && isUniqueName(searchName)) {
            onSave?.(searchName);
        }
        else {
            onError?.("Please enter a unique name.");
        }
    };

    return (
        <Dialog title="Save Filter" portalClassName="wm-dialog-portal" isOpen={isOpen} onClose={handleCancelClick}>
            <div className="wm-dialog-body">
                <LabelledField
                    classes={{ container: 'input__group', label: 'input__label', input: handleValidation(validate, searchName) }}
                    label="Name"
                    id="savedSearchName"
                    value={searchName}
                    editing
                    fieldType="text"
                    onChange={handleInputChange}
                />
            </div>
            <div className="wm-dialog-buttonContainer">
                <button className="action-button action-button--cancel" onClick={handleCancelClick}>Cancel</button>
                <button className="action-button" onClick={handleSaveClick} disabled={saveDisabled}>Save</button>
            </div>
        </Dialog>
    );
};

export default SaveFilterDialog;