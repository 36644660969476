import React, { useState, useEffect } from "react";
import { Input, LoadingBar } from "@3tc/shared-components";


import { NodeID } from "../../../lib/nodeIdentifier";
import { formatText, longDateFormatter } from "../../../lib/Utils";
import { IConnection, ISavedSearch, ILookups, IQueryControl } from "../../../store/types";
import { IChip, ChoiceChips } from "../../shared/chips";
import ActionButtons from "../actionButtons";
import * as api from "../api";
import downloadCsv from "../api/export/downloadWaterSources";
import FilterItem from "../filter";
import FilterResultsTable from "../filterResultsTable";
import SearchSelectorContext from "../searchSelector/searchSelectorContext";
import { ITableHeader, ViewOptions } from "../types";
import { formatYesNo } from "../utils";
import { queryWaterSourcesByFilter } from "./query";
import { IWaterSourceSelectedFilters, WaterSourceSearchItem } from "./types";
import { buildQueryFilter, buildWaterSourceFilters } from "./utils";
import styles from "./waterSourceSearch.module.scss";
import { useTablePaging } from "../hooks";
import { formatNextInspectionValue, formatNextInspectionTag } from "../../../lib/formatters";

interface WaterSourceSearchProps {
    readonly lookups: ILookups;
    readonly hideToast: () => void;
    readonly showCreateInspectionsDialog: (waterSourceIds: NodeID[]) => void;
    readonly showError: (message: string, error?: Error) => void;
    readonly showLoading: (message: string) => void;
    readonly showWaterSourceOnMap: (waterSource: WaterSourceSearchItem) => void;
    readonly showWaterSourcesOnMap: (filters: IWaterSourceSelectedFilters) => void;
    readonly showSuccess: (message: string) => void;
}

const tableHeaders: ITableHeader[] = [
    {
        displayText: "Id",
        sortBy: "id"
    },
    {
        displayText: "Category",
        sortBy: "category"
    },
    {
        displayText: "Classification",
        sortBy: "classification"
    },
    {
        displayText: "Status",
        sortBy: "status"
    },
    {
        displayText: "Active",
        sortBy: "isActive"
    },
    {
        displayText: "Operable",
        sortBy: "isOperable"
    },
    {
        displayText: "Defective",
        sortBy: "isDefective"
    },
    {
        displayText: "Last Inspection Date",
        sortBy: "lastInspectionDate"
    },
    {
        displayText: "Next Inspection Date",
        sortBy: "nextInspectionDate"
    }
];

const tableRows = (waterSources: WaterSourceSearchItem[]): string[][] => {
    return waterSources.map(waterSource => {
        const {
            waterSourceId,
            category,
            classification,
            status,
            isActive,
            isOperable,
            isDefective,
            lastInspectionDate,
            nextInspectionDate,
            recommendedInspectionDate
        } = waterSource;
        return [
            waterSourceId.toString(),
            category.displayText,
            classification?.displayText ?? "",
            status?.displayText ?? "",
            formatYesNo(isActive),
            formatYesNo(isOperable.value),
            formatYesNo(isDefective.value),
            lastInspectionDate?.format(longDateFormatter) ?? "",
            `${formatNextInspectionValue(nextInspectionDate, recommendedInspectionDate)} ${formatNextInspectionTag(nextInspectionDate, recommendedInspectionDate)}`
        ];
    });
};

const empty: IConnection<WaterSourceSearchItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "id",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "id",
    sortDirection: "ASCENDING"
};

const viewOptions: IChip[] = [
    { label: "Active & Inactive", value: "all" },
    { label: "Active Only", value: "active" },
    { label: "Inactive Only", value: "archived" },
];

const queryWaterSources = async (selectedFilters: IWaterSourceSelectedFilters, paging: IQueryControl): Promise<IConnection<WaterSourceSearchItem>> => {
    const filter = buildQueryFilter(selectedFilters);
    return queryWaterSourcesByFilter(filter, paging);
};

const WaterSourceSearch = (props: WaterSourceSearchProps): JSX.Element => {
    const {
        hideToast,
        showCreateInspectionsDialog,
        showError,
        showLoading,
        showWaterSourceOnMap,
        showWaterSourcesOnMap
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<IWaterSourceSelectedFilters>({ isActive: ["true"] });
    const [page, setPage] = useState<IConnection<WaterSourceSearchItem>>(empty);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();
    const [selectedView, setSelectedView] = useState<ViewOptions>("active");
    const [creatingInspections, setCreatingInspections] = useState(false);
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            showLoading("Getting water sources...");
            queryWaterSources(selectedFilters, paging)
                .then(waterSources => setPage(waterSources))
                .catch(() => showError("Error searching for water sources."))
                .finally(() => {
                    setIsLoading(false);
                    hideToast();
                });
        }
    }, [paging]);

    const handleFilterChange = (value: string): void => {
        if (Object.keys(selectedFilters).indexOf(value) > -1) {
            const update = { ...selectedFilters };
            delete update[value];
            setSelectedFilters(update);
            return;
        }
        setSelectedFilters({ ...selectedFilters, [value]: [] });
    };

    const handleClearClick = (): void => {
        setIsLoading(true);
        setSelectedView("active");
        setSelectedFilters({ isActive: ["true"] });
        setPage(empty);
        setIsLoading(false);
    };

    const handleExportClick = (): void => {
        showLoading("Downloading CSV.");
        const filter = buildQueryFilter(selectedFilters);
        downloadCsv("water-sources", filter)
            .catch(error => showError("Error exporting water sources to CSV.", error))
            .finally(hideToast);
    };

    const handleRowClick = (id: string): void => {
        const waterSource = items.find(item => item.waterSourceId.toString() === id);
        if (waterSource) {
            showWaterSourceOnMap(waterSource);
        }
    };

    const handleViewOptionsChange = (value: string): void => {
        const selected = value as ViewOptions;
        setSelectedView(selected);

        const getIsActive = (): string[] => {
            switch (selected) {
                case "all":
                    return [];
                case "active":
                    return ["true"];
                case "archived":
                    return ["false"]
            }
        };

        const isActive = getIsActive();

        setSelectedFilters(current => ({ ...current, isActive }));
    };

    const handleChangeSelection = (selected: ISavedSearch | undefined): void => {
        setSelectedSearch(selected);
    };

    useEffect(() => {
        if (selectedSearch) {
            setSelectedFilters(selectedSearch.queryFilter);
        }
    }, [selectedSearch, setSelectedFilters]);

    const handleCreateInspections = (): void => {
        setCreatingInspections(true);
        api.getWaterSourceIds(buildQueryFilter(selectedFilters), totalCount)
            .then(nodeIds => showCreateInspectionsDialog(nodeIds))
            .catch(error => showError("Error getting water source details for Create Inspections.", error))
            .finally(() => setCreatingInspections(false));
    };

    const handleApplyFiltersToMap = (): void => {
        showWaterSourcesOnMap(selectedFilters);
    };

    const waterSourceFilters = buildWaterSourceFilters(props.lookups);

    return (
        <SearchSelectorContext entity="water-sources" selectedFilters={selectedFilters} onChangeSelection={handleChangeSelection}>
            <div className={styles.allInputs}>
                <div className={styles.inputContainer}>
                    <Input
                        type="multilist"
                        id="selected-filters-list"
                        labelText="Search On"
                        isEditing
                        value={Object.keys(selectedFilters)}
                        responseOptions={Object.keys(waterSourceFilters).map((item: string) => ({
                            value: item,
                            text: formatText(item, true, undefined, true)
                        }))}
                        onChangeHandler={handleFilterChange}
                    />
                </div>
                {Object.keys(selectedFilters).map(filter => (
                    <FilterItem
                        key={filter}
                        name={filter}
                        options={waterSourceFilters[filter]}
                        selected={selectedFilters}
                        onChangeSelection={setSelectedFilters}
                    />
                ))}
            </div>
            <ChoiceChips name="activeOrArchived" chips={viewOptions} styles={{ root: styles.viewOptions }} selected={selectedView} onChange={handleViewOptionsChange} />
            <ActionButtons
                totalCount={totalCount}
                onClear={handleClearClick}
                onCreateInspections={[handleCreateInspections, creatingInspections && totalCount > 0]}
                onExport={handleExportClick}
                onSearch={handleSearchClick}
                onShowOnMap={handleApplyFiltersToMap}
            />
            {isLoading
                ? <LoadingBar loading />
                : <FilterResultsTable
                    headers={tableHeaders}
                    items={tableRows(items)}
                    pageInfo={pageInfo}
                    totalCount={totalCount}
                    onFirstClick={handleFirstClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onLastClick={handleLastClick}
                    onTableHeaderClick={handleTableHeaderClick}
                    onTableRowClick={handleRowClick}
                />
            }
        </SearchSelectorContext>
    );
};

export type { WaterSourceSearchProps };
export default WaterSourceSearch;