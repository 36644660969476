import React from "react";

import { useScheme, useRoute } from "../../appWindowContext";
import LeftDrawer from "./LeftDrawer";

const ContextLeftDrawer = (): JSX.Element => {
    const { instance: scheme, clear: clearScheme, refresh: refreshScheme } = useScheme();
    const { instance: route, clear: clearRoute, refresh: refreshRoute } = useRoute();
    const isOpen = scheme !== undefined || route?.route !== undefined;

    const handleClose = (entity: "route" | "scheme"): void => {
        switch (entity) {
            case "route":
                clearRoute();
                break;
            case "scheme":
                clearScheme();
                break;
        }
    };

    const handleRefresh = async (entity: "route" | "scheme"): Promise<void> => {
        switch (entity) {
            case "route":
                return refreshRoute();
            case "scheme":
                return refreshScheme();
        }
    };

    return <LeftDrawer open={isOpen} entity={scheme ?? route} onClose={handleClose} onRefresh={handleRefresh} />;
};

export default ContextLeftDrawer;