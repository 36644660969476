import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    root: {
        height: "100%",
        overflowY: "scroll"
    },
    list: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[200],
    },
    subheader: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.grey[200],
    },
}));

export default useStyles;
