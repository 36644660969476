import React from "react";
import { Box } from "@material-ui/core";

import { Owner } from "../../../../store/types";
import Editor, { EditKeys, EditFields } from "./components/editor";
import ReadOnly, { ReadOnlyProps } from "./components/readonly";

interface WaterSourceDetailsProps extends ReadOnlyProps, Pick<Owner, "tags" | "category"> {
    readonly editing?: boolean;
    readonly isMobile?: boolean;
    readonly onChange?: (key: EditKeys, value: EditFields[EditKeys]) => void;
}

const WaterSourceDetails = (props: WaterSourceDetailsProps): JSX.Element => {
    const { editing, isMobile, onChange, ...other } = props;

    const handleChange = (key: EditKeys, value: EditFields[EditKeys]): void => {
        onChange?.(key, value);
    };

    return (
        <Box p={2} overflow="auto" height="100%">
            {editing
                ? <Editor {...other} native={isMobile} onChange={handleChange} />
                : <ReadOnly {...other} />
            }
        </Box>
    );
};

export type { WaterSourceDetailsProps };
export default WaterSourceDetails;