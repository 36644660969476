import React from "react";
import { useDispatch } from 'react-redux';

import useSelector from '../../../lib/hooks/useSelector';
import { startAddDefect } from '../../../store/actions/defects';
import { getWaterSource } from '../../../store/actions/waterSources';
import { IAddDefectVariables } from '../../../store/types';
import { useWaterSource, useInspection } from '../../appWindowContext';
import CreateDefectDialogBase, { ICreateDefectDialogProps } from './createDefectDialog';
import { guid } from "../../../lib/Utils";
import { getInspectionsForWaterSource } from "../../../store/actions/inspections";

const CreateDefectDialog = (props: Pick<ICreateDefectDialogProps, "isOpen" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const defectTypes = useSelector(state => state.app.lookups?.defectTypes ?? []);
    const { instance: waterSource } = useWaterSource();
    const { instance: inspection } = useInspection();
    const combined: ICreateDefectDialogProps = {
        ...props,
        defectTypes,
        selectedInspection: inspection,
        selectedWaterSource: waterSource,
        getWaterSource: async (variables): Promise<void> => {
            const action = getWaterSource(variables);
            await action(dispatch);
        },
        getInspections: async waterSourceNodeId => {
            const action = getInspectionsForWaterSource({ id: waterSourceNodeId }, true);
            const result = action(dispatch);
            return result;
        },
        onCreateDefect: async data => {
            const variables: IAddDefectVariables = {
                defect: {
                    clientMutationId: guid(),
                    data
                }
            };
            const action = startAddDefect(variables);
            await action(dispatch);
        }
    }
    return <CreateDefectDialogBase {...combined} />;
};

export default CreateDefectDialog;