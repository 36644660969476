export default `
fragment Point on GeoPoint {
    x
    y
}
fragment AonRange on AddressableObjectNameRange {
    number
    suffix
}
fragment Aon on AddressableObjectName {
    description
    startRange { ...AonRange }
    endRange { ...AonRange }
}
fragment Address on Address {
    addressNodeId: id
    addressId
    saon { ...Aon }
    paon { ...Aon }
    streetDescription
    usrn
    locality
    town
    administrativeArea
    postCode
    uprn
    coordinates { ...Point }
}
query addressSearch($filter: QueryFilter, $paging: QueryControlInput) {
    addresses(queryFilter: $filter, queryControl: $paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        items {
            ...Address
        }
    }
}`;