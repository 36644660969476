import React, { useState, useRef, useEffect } from "react";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import { IAccount, IVersion } from "../../../../store/types";
import AppBarChangePassword from "../AppBarChangePassword";
import AppBarSignOut from "../AppBarSignOut";

interface AppBarUserProps {
    readonly account?: IAccount;
    readonly version: IVersion;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
        justifyContent: "flex-end"
    },
    userButton: {
        color: theme.palette.primary.contrastText,
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    popper: {
        zIndex: 5
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderBottomColor: theme.palette.secondary.main,
        borderBottomWidth: "2px",
        borderBottomStyle: "solid"
    },
    menu: {
        paddingTop: theme.spacing(2)
    },
    headerItem: {
        justifyContent: "flex-end"
    },
    infoItem: {
        color: theme.palette.grey[400],
        justifyContent: "flex-end",
    },
    actionItem: {
        justifyContent: "space-between"
    }
}));

const AppBarUser = (props: AppBarUserProps): JSX.Element => {
    const styles = useStyles();
    const { account, version } = props;
    const { customerName, customerShortName, name, subscriptionName, versionNumber } = account ?? {};

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = (): void => {
        setOpen(current => !current);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>): void => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div className={styles.root}>
            <IconButton id="appBar-user" title={name} ref={anchorRef} className={styles.userButton} onClick={handleToggle}>
                <AccountCircleIcon fontSize="large" />
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={styles.popper} transition placement="bottom-end">
                {({ TransitionProps }): JSX.Element => (
                    <Slide {...TransitionProps}>
                        <Paper square className={styles.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="appBar-user-menu" className={styles.menu}>
                                    <MenuItem className={styles.headerItem}>
                                        <Typography variant="body2">
                                            {name}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem className={styles.infoItem}>
                                        <Typography variant="caption">
                                            {`${customerShortName} - ${customerName}`}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem className={styles.infoItem}>
                                        <Typography variant="caption">
                                            {subscriptionName}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem className={styles.infoItem}>
                                        <Typography variant="caption">
                                            {`UI v${version.uiVersion ?? "(unknown)"}`}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem className={styles.infoItem}>
                                        <Typography variant="caption">
                                            {`API v${version.apiVersion ?? "(unknown)"}`}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem className={styles.actionItem}>
                                        <AppBarChangePassword />
                                        <Box mr={2} />
                                        <AppBarSignOut />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Slide>
                )}
            </Popper>
        </div>
    );
};

export type { AppBarUserProps };
export default AppBarUser;
