import React from "react";

import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

interface MapWarningProps {
    readonly open: boolean;
    readonly message?: string;
}

const MapWarning = ({ open, message }: MapWarningProps): JSX.Element => {
    return (
        <Snackbar open={open} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <Alert severity="warning">
                {message ?? "Using fall-back map server."}
            </Alert>
        </Snackbar>
    );
};

export type { MapWarningProps };
export default MapWarning;