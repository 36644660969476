import React from "react";

import CheckboxElement, { CheckboxElementProps } from "./components/checkboxElement";
import DateElement, { DateElementProps } from "./components/dateElement";
import LinkElement, { LinkElementProps } from "./components/linkElement";
import NumberElement, { NumberElementProps } from "./components/numberElement";
import ReadOnlyElement, { ReadOnlyElementProps } from "./components/readOnlyElement";
import SelectElement, { SelectElementProps } from "./components/selectElement";
import SwitchElement, { SwitchElementProps } from "./components/switchElement";
import TextElement, { TextElementProps } from "./components/textElement";
import MultiSelectElement, { MultiSelectElementProps } from "./components/multiSelectElement";

const isCheckboxField = (props: LabelledFieldProps): props is CheckboxElementProps & FieldType<"checkbox"> => {
    return (props as FieldType<"checkbox">).fieldType === "checkbox";
};

const isDateField = (props: LabelledFieldProps): props is DateElementProps & FieldType<"date"> => {
    return (props as FieldType<"date">).fieldType === "date";
};

const isLinkField = (props: LabelledFieldProps): props is LinkElementProps & FieldType<"link"> => {
    return (props as FieldType<"link">).fieldType === "link";
};

const isNumberField = (props: LabelledFieldProps): props is NumberElementProps & FieldType<"number"> => {
    return (props as FieldType<"number">).fieldType === "number";
};

const isReadOnlyField = (props: LabelledFieldProps): props is ReadOnlyElementProps & FieldType<"readonly"> => {
    return (props as FieldType<"readonly">).fieldType === "readonly";
};

const isSelectField = (props: LabelledFieldProps): props is SelectElementProps & FieldType<"select"> => {
    return (props as FieldType<"select">).fieldType === "select";
};

const isSwitchField = (props: LabelledFieldProps): props is SwitchElementProps & FieldType<"switch"> => {
    return (props as FieldType<"switch">).fieldType === "switch";
};

const isTextField = (props: LabelledFieldProps): props is TextElementProps & FieldType<"text"> => {
    return (props as FieldType<"text">).fieldType === "text";
};

const isMultiSelectField = (props: LabelledFieldProps): props is MultiSelectElementProps & FieldType<"multiselect"> => {
    return (props as FieldType<"multiselect">).fieldType === "multiselect";
};

type FieldTypes = "checkbox" | "date" | "link" | "number" | "readonly" | "select" | "switch" | "text" | "multiselect";

interface FieldType<T extends FieldTypes> {
    readonly fieldType: T;
}

type LabelledFieldProps =
    CheckboxElementProps & FieldType<"checkbox"> |
    DateElementProps & FieldType<"date"> |
    LinkElementProps & FieldType<"link"> |
    NumberElementProps & FieldType<"number"> |
    ReadOnlyElementProps & FieldType<"readonly"> |
    SelectElementProps & FieldType<"select"> |
    SwitchElementProps & FieldType<"switch"> |
    TextElementProps & FieldType<"text"> |
    MultiSelectElementProps & FieldType<"multiselect">;

const LabelledField = (props: LabelledFieldProps): JSX.Element => {
    if (isReadOnlyField(props)) {
        return <ReadOnlyElement {...props} />;
    }
    if (isSelectField(props)) {
        return <SelectElement {...props} />;
    }
    if (isTextField(props)) {
        return <TextElement {...props} />;
    }
    if (isNumberField(props)) {
        return <NumberElement {...props} />;
    }
    if (isDateField(props)) {
        return <DateElement {...props} />;
    }
    if (isSwitchField(props)) {
        return <SwitchElement {...props} />;
    }
    if (isCheckboxField(props)) {
        return <CheckboxElement {...props} />;
    }
    if (isLinkField(props)) {
        return <LinkElement {...props} />;
    }
    if (isMultiSelectField(props)) {
        return <MultiSelectElement {...props} />;
    }
    return <React.Fragment />;
};

export type { LabelledFieldProps };
export default LabelledField;