export type MobileActionType =
    "@@INIT" |
    "SET_VIEW_LIST" |
    "SET_VIEW_MAP";

export interface IMobileAction {
    readonly type: MobileActionType;
}

export interface IMobileState {
    readonly viewOption: ViewOptions;
}

export enum ViewOptions {
    LIST = "list",
    MAP = "map"
}
