import React, { useState } from "react";
import { useDispatch } from "react-redux";

import useDefects from "../../../../components/appWindowContext/hooks/useDefects";
import useGetDefect from "../../../../components/appWindowContext/hooks/useGetDefect";
import useGetInspection from "../../../../components/appWindowContext/hooks/useGetInspection";
import useGetRepair from "../../../../components/appWindowContext/hooks/useGetRepair";
import useInspections from "../../../../components/appWindowContext/hooks/useInspections";
import useRepairs from "../../../../components/appWindowContext/hooks/useRepairs";
import useSelector from "../../../../lib/hooks/useSelector";
import { NodeID, parseNodeId } from "../../../../lib/nodeIdentifier";
import { setGlobalToast } from "../../../../store/actions/app";
import { IMapSettings } from "../../../../store/types";
import ViewWaterSourceBase, { IViewWaterSourceProps } from "./ViewWaterSource";

const ViewWaterSource = (props: Pick<IViewWaterSourceProps, "selectedWaterSource">): JSX.Element => {
    const dispatch = useDispatch();

    const { selectedWaterSource } = props;

    const statuses = useSelector(state => state.app.lookups?.waterSourceStatuses ?? []);
    const mapSettings = useSelector<IMapSettings>(state => state.map.mapSettings ?? {
        projection: { code: "", definition: "" },
        tileServer: { apiKey: "", type: "", url: "", params: undefined },
        view: {
            centre: { x: 0, y: 0 },
            extent: {
                min: { x: 0, y: 0 },
                max: { x: 0, y: 0 }
            }
        }
    });

    const [defects, refreshDefects] = useDefects(selectedWaterSource.waterSourceNodeId);
    const [defectId, setDefectId] = useState<NodeID | undefined>(undefined);
    const [, defect, refreshDefect] = useGetDefect(defectId);

    const [inspections, refreshInspections] = useInspections(selectedWaterSource.waterSourceNodeId);
    const [inspectionId, setInspectionId] = useState<NodeID | undefined>(undefined);
    const [, inspection, refreshInspection] = useGetInspection(inspectionId)

    const [repairs, refreshRepairs] = useRepairs(selectedWaterSource.waterSourceNodeId);
    const [repairId, setRepairId] = useState<NodeID | undefined>(undefined);
    const [, repair, refreshRepair] = useGetRepair(repairId);

    const combined: IViewWaterSourceProps = {
        selectedWaterSource,
        statuses,
        mapSettings,
        defects,
        selectedDefect: defect,
        inspections,
        selectedInspection: inspection,
        repairs,
        selectedRepair: repair,
        clearGlobalToast: (): void => {
            dispatch(setGlobalToast());
        },
        onRefreshDefects: async (): Promise<void> => {
            await refreshDefect();
            refreshDefects();
        },
        onRefreshInspections: async (): Promise<void> => {
            await refreshInspection();
            refreshInspections();
        },
        onRefreshRepairs: async (): Promise<void> => {
            await refreshRepair();
            refreshRepairs();
        },
        setSelectedDefect: (defect): void => {
            const nodeId = defect
                ? parseNodeId(defect.defectNodeId)
                : undefined;
            setDefectId(nodeId);
        },
        setSelectedInspection: (inspection): void => {
            const nodeId = inspection
                ? parseNodeId(inspection.inspectionNodeId)
                : undefined;
            setInspectionId(nodeId);
        },
        setSelectedRepair: (repair): void => {
            const nodeId = repair
                ? parseNodeId(repair.repairNodeId)
                : undefined;
            setRepairId(nodeId);
        },
    };
    return <ViewWaterSourceBase {...combined} />;
};

export default ViewWaterSource;
