import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AddressNode } from "../../store/types";
import Box from "@material-ui/core/Box";

interface RenderAction {
    (address: AddressNode): JSX.Element;
}

interface AddressSearchItemProps {
    readonly address: AddressNode;
    readonly renderEdit?: RenderAction;
    readonly onSelect?: (address: AddressNode) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            cursor: "pointer"
        }
    }
}));

const formatAddress = (address: AddressNode): string => {
    const { paon, streetDescription, locality, town, postCode } = address;
    const fields = [
        paon?.description,
        streetDescription,
        locality,
        town,
        postCode
    ];
    const result = fields
        .map(s => s?.trim())
        .filter(s => s !== undefined && s !== null && s !== "")
        .join(", ");
    return result;
};

const AddressSearchItem = ({ address, renderEdit, onSelect }: AddressSearchItemProps): JSX.Element => {
    const styles = useStyles();
    const handleItemClick = (): void => {
        if (address.addressNodeId) {
            onSelect?.(address);
        }
    };

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" py={0.5}>
            <Typography variant="body2" classes={{ ...styles }} onClick={handleItemClick}>{formatAddress(address)}</Typography>
            {renderEdit?.(address)}
        </Box>
    );
};

export type { AddressSearchItemProps, RenderAction };
export default AddressSearchItem;