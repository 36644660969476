import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

interface DecorationProps {
    readonly color?: string;
    readonly children: React.ReactChild;
    readonly decoration: React.ReactChild;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "inline-block",
        position: "relative"
    },
    decoration: {
        "& > svg": {
            display: "inline-block",
            position: "absolute",
            bottom: 0,
            right: "-6px",
            fontSize: "18px",
            borderRadius: "9px",
        }
    },
}));

const IconDecoration = (props: DecorationProps): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            {props.children}
            <div className={styles.decoration}>
                {props.decoration}
            </div>
        </div>
    )
};

export type { DecorationProps };
export default IconDecoration;
