import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import WaterSourceActionItem from "./WaterSourceActionItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { setDialogOpen } from "../../../../../../store/actions/app";
import { WaterSourceActionProps } from "./types";
import useGetWaterSource from "../../../../../appWindowContext/hooks/useGetWaterSource";
import { NodeID } from "../../../../../../lib/nodeIdentifier";

const CreateInspectionAction = ({ waterSourceNodeId }: WaterSourceActionProps): JSX.Element => {
    const dispatch = useDispatch();
    const [waterSourceNodeIdToRequest, setWaterSourceNodeIdToRequest] = useState<NodeID>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, waterSource] = useGetWaterSource(waterSourceNodeIdToRequest);

    const handleClick = (): void => {
        if (waterSource) {
            dispatch(setDialogOpen("createInspection", { selectedWaterSource: waterSource }));

            return;
        }
        setWaterSourceNodeIdToRequest(waterSourceNodeId);
    };

    useEffect(() => {
        if (waterSource) {
            dispatch(setDialogOpen("createInspection", { selectedWaterSource: waterSource }));
        }
    }, [waterSource]);

    return <WaterSourceActionItem
        icon={<FontAwesomeIcon
            icon={faClipboardList}
            style={{ marginLeft: "4px" }}
        />}
        primary="Create Inspection"
        onClick={handleClick}
    />;
};

export default CreateInspectionAction;