export default `
mutation addHazards_Mutation($hazards: EditAddHazardInputCollection!) {
    addHazards(input:$hazards) {
        clientMutationId
        waterSource {
            waterSourceNodeId: id
            waterSourceId: externalId
            hazards {
                hazardNodeId: id
                hazardId
                hazardType {
                    value
                    displayText
                }
            }        
        }
        hasErrors
        errors {
          message
          path
        }
    }
}
`;