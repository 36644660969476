import { connect } from "react-redux";

import updateWaterSourceLocation, { LocationData } from "../../../../api/waterSources/updateLocation";
import { NodeID } from "../../../../lib/nodeIdentifier";
import { IPoint, IReduxState } from "../../../../store/types";
import { IWaterSourceSummary } from "../../types";
import ShowMap, { ShowMapProps } from "./showMap.view";

type ConnectedProps = Pick<ShowMapProps, "fence">;

type ConnectedActions = Pick<ShowMapProps, "onMoveWaterSource">;

const mapState = (state: IReduxState): ConnectedProps => {
    const defaultRadius = state.map.mapSettings?.search?.defaultRadius;
    const props: ConnectedProps = {
        fence: Number(defaultRadius)
    };
    return props;
};

const mapDispatch = (): ConnectedActions => ({
    onMoveWaterSource: async (nodeId: NodeID, point: IPoint): Promise<IWaterSourceSummary | undefined> => {
        const data: LocationData = {
            locationCoordinates: point,
            controlMeasures: []
        };
        return updateWaterSourceLocation(nodeId, data);
    }
});

export default connect(mapState, mapDispatch)(ShowMap);