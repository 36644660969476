import React from "react";

import List from "@material-ui/core/List";

import { GroupItem } from "../../lib/collections";
import { IInspection } from "../../store/types";
import EmptyItem from "./components/emptyItem";
import InspectionItemGroup from "./components/inspectionItemGroup";
import useStyles from "./InspectionList.styles";

interface IInspectionListProps {
    readonly groups: GroupItem<IInspection>[];
    readonly totalCount: number;
    readonly onScroll?: (event: React.UIEvent<HTMLUListElement>) => void;
}

const InspectionList = (props: IInspectionListProps): JSX.Element => {
    const styles = useStyles();
    const { groups, onScroll } = props;
    return (
        <div className={styles.root} >
            <List className={styles.list} disablePadding onScroll={onScroll}>
                {groups.length > 0
                    ? groups.map(group => <InspectionItemGroup key={group.label} groupItem={group} />)
                    : <EmptyItem message="No open inspections." />
                }
            </List>
        </div>
    );
}

export type { IInspectionListProps };
export default InspectionList;