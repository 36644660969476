import React from "react";
import { AccessControl } from "../../../auth/components";
import { RoleNames } from "../../../auth/roles";

import useNavigation from "../hooks/useNavigation";
import AppBarMenuItem from "./AppBarMenuItem";

const AppBarConfigurationItem = (): JSX.Element => {
    const { gotoConfiguration } = useNavigation();

    const handleClick = (): void => {
        gotoConfiguration();
    };

    return (
        <AccessControl any={[RoleNames.CONFIGURATION]}>
            <AppBarMenuItem id="configuration" label="Configuration" onClick={handleClick} />
        </AccessControl>
    );
};

export default AppBarConfigurationItem;