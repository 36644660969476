export default `
    mutation archiveLookup($input:LookupMutationLookupValuesInput!) {
        lookups {
            archiveItems(input: $input) {
                clientMutationId
                errors {
                    message
                    path
                }
                hasErrors
                lookup {
                    name
                    items {
                        value
                        displayText
                        filters
                        sortOrder
                        enabled
                        isOperable
                        isPlanned
                        symbolName
                        groupingId
                    }
                }
            }
        }
    }
`