import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import FileIcon from "@material-ui/icons/InsertDriveFile";
import LinkIcon from "@material-ui/icons/Link";
import { ThemedProps } from "./Attachment.types";

interface AttachmentIconProps {
    readonly type: string;
}

const isDark = (theme: "light" | "dark" | undefined): boolean => {
    return theme === undefined || theme === "dark";
};

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: (props: ThemedProps): string =>
            isDark(props.theme) ? theme.palette.grey[200] : theme.palette.grey[600]
    }
}));

const AttachmentIcon = (props: ThemedProps<AttachmentIconProps>): JSX.Element => {
    const styles = useStyles(props);
    return props.type === "link"
        ? <LinkIcon className={styles.root} />
        : <FileIcon className={styles.root} />
};

export default AttachmentIcon;