import React, { useState, useRef, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Slide from "@material-ui/core/Slide";
import AppBarMenuLabel from "../AppBarMenuLabel";
import AppBarMenuItem, { AppBarMenuItemProps } from "../AppBarMenuItem";

interface AppBarMenuProps {
    readonly id?: string;
    readonly title: string;
    readonly icon?: React.ReactNode;
    readonly children?: React.ReactElement<AppBarMenuItemProps>[]
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
    },
    userButton: {
        color: theme.palette.primary.contrastText,
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    popper: {
        zIndex: 5
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderBottomColor: theme.palette.secondary.main,
        borderBottomWidth: "2px",
        borderBottomStyle: "solid"
    },
    menu: {
        minWidth: "200px",
        paddingTop: theme.spacing(2)
    },
}));

const AppBarMenu = (props: AppBarMenuProps): JSX.Element => {
    const styles = useStyles();
    const { id, title, icon, children } = props;

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = (): void => {
        setOpen(current => !current);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>): void => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div className={styles.root}>
            <AppBarMenuLabel id={id} ref={anchorRef} label={title} icon={icon} open={open} showArrow onClick={handleToggle} />
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={styles.popper} transition placement="bottom-start">
                {({ TransitionProps }): JSX.Element => (
                    <Slide {...TransitionProps}>
                        <Paper square className={styles.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id={`appBar-menu-${id}`} className={styles.menu}>
                                    {React.Children.map(children, child => {
                                        if (React.isValidElement(child)) {
                                            return React.cloneElement(child, {
                                                onClick: (): void => {
                                                    setOpen(false);
                                                    child.props.onClick?.();
                                                }
                                            });
                                        }
                                        return child;
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Slide>
                )}
            </Popper>
        </div>
    );
};

export type { AppBarMenuProps };
export default AppBarMenu;
