import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingBar } from "@3tc/shared-components";

import Dialog from '../../../../dialog/dialog';
import DialogTitle from '../../../../dialog/dialogTitle';
import DialogContent from '../../../../dialog/dialogContent';
import DialogActions from '../../../../dialog/dialogActions';
import { emptyPage, IConnection, IQueryControl } from '../../../../../store/types';
import FilterResultsTable from '../../../../filters/filterResultsTable';
import useNavigation from '../../../../../router/DesktopRouter/hooks/useNavigation';
import { NodeID, parseNodeId } from '../../../../../lib/nodeIdentifier';
import { longDateFormatter } from '../../../../../lib/Utils';
import { ITableHeader } from '../../../../filters/types';
import { useTablePaging } from '../../../../filters/hooks';
import { setGlobalToast } from '../../../../../store/actions/app';
import { queryWaterSourceHistory } from './query';
import { WaterSourceHistoryItem } from './types';
import { formatOutcome, getObjectCategory, getTabIndex } from './utils';

interface WaterSourceHistoryDialogProps {
    readonly isOpen: boolean;
    readonly waterSourceNodeId: NodeID;
    readonly onClose: () => void;
}

const tableHeaders: ITableHeader[] = [
    { displayText: "Created Date" },
    { displayText: "Item" },
    { displayText: "Type" },
    { displayText: "Outcome" },
    { displayText: "Closed Date" }
];

const tableRows = (waterSourceHistoryItems: WaterSourceHistoryItem[]): string[][] => {
    return waterSourceHistoryItems.map(waterSourceHistoryItem => {
        return [
            waterSourceHistoryItem.dateCreated?.format(longDateFormatter) ?? "",
            waterSourceHistoryItem.objectCategory,
            waterSourceHistoryItem.objectType,
            formatOutcome(waterSourceHistoryItem),
            waterSourceHistoryItem.dateClosed?.format(longDateFormatter) ?? "",
            waterSourceHistoryItem.objectNodeId
        ];
    });
};

const empty: IConnection<WaterSourceHistoryItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "dateCreated",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "dateCreated",
    sortDirection: "ASCENDING"
};

const WaterSourceHistoryDialog = (props: WaterSourceHistoryDialogProps): JSX.Element => {
    const { isOpen, waterSourceNodeId, onClose } = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = (): void => onClose?.();
    const [page, setPage] = useState<IConnection<WaterSourceHistoryItem>>(empty);
    const { gotoMap } = useNavigation();
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            dispatch(setGlobalToast({
                showToast: true,
                type: "LOADING",
                message: "Getting water source history..."
            }));
            queryWaterSourceHistory(waterSourceNodeId, paging)
                .then(history => setPage(history))
                .catch(() => {
                    dispatch(setGlobalToast({
                        showToast: true,
                        type: "ERROR",
                        message: `Error getting water source history for '${props.waterSourceNodeId}'`
                    }))
                })
                .finally(() => {
                    setIsLoading(false);
                    dispatch(setGlobalToast());
                });
        }
    }, [paging]);

    useEffect(() => {
        if (isOpen) {
            handleSearchClick();
        }
    }, [isOpen]);

    const handleTableRowClick = (objectNodeId: string): void => {
        const nodeId = parseNodeId(objectNodeId);
        const options = {
            [getObjectCategory(nodeId)]: nodeId,
            tab: getTabIndex(nodeId)
        };
        gotoMap(options);
    };

    return (
        <Dialog fullWidth maxWidth="lg" overflowY="unset" open={isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Water Source History</DialogTitle>
            <DialogContent>
                {isLoading
                    ? <LoadingBar loading />
                    : <FilterResultsTable
                        headers={tableHeaders}
                        items={tableRows(items)}
                        pageInfo={pageInfo ?? emptyPage}
                        totalCount={totalCount}
                        lastFieldAsObjectNodeId
                        onFirstClick={handleFirstClick}
                        onPreviousClick={handlePreviousClick}
                        onNextClick={handleNextClick}
                        onLastClick={handleLastClick}
                        onTableHeaderClick={handleTableHeaderClick}
                        onTableRowClick={handleTableRowClick}
                    />
                }
            </DialogContent>
            <DialogActions>
                <button id="cancel_button" className="action-button action-button--cancel" onClick={handleClose}>Close</button>
            </DialogActions>
        </Dialog>
    );
};

export type { WaterSourceHistoryDialogProps };
export default WaterSourceHistoryDialog;