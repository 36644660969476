import React, { useState, useEffect } from "react";
import { LocalDate } from "@js-joda/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import MenuItem from "@material-ui/core/MenuItem";

import * as assert from "../../../../lib/assertions";
import { Lookup } from "../../../../lib/lookups";
import { shortDateFormatter } from "../../../../lib/Utils";
import { isNumber } from "../../../../lib/validation";
import { IInspection, ILookupItem, Inspector } from "../../../../store/types";
import AppDialog, { AppDialogInput, AppDialogMultiSelect, AppDialogSelect } from "../../../shared/appDialog";
import { InspectionOutcome } from "../../types";
import { formatInspector, formatOutcome } from "../../utils";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface CompleteInspectionProps {
    readonly inspection: IInspection;
    readonly inspectionTests: Lookup;
    readonly internalRepairs: Lookup;
    readonly isMobile: boolean;
    readonly inspector?: Inspector;
    readonly outcome: InspectionOutcome;
    readonly waterSourceCategory: ILookupItem;
    readonly onCommit?: (test: ILookupItem, note: string, completedInternalRepairs: ILookupItem[], wallPlateFitted?: boolean, serviceRecordsInDate?: boolean) => void;
}

const useStyles = makeStyles(theme => createStyles({
    commitButton: {
        width: "100%"
    },
    checkBoxLabel: {
        marginLeft: "0px"
    },
    checkbox: {
        color: theme.palette.primary.light,
        marginRight: "32px"
    }
}));

const renderTasks = (tasks: Lookup, isMobile: boolean | undefined): JSX.Element[] => {
    return tasks
        .map(type => [type.value, type.displayText])
        .map(([value, displayText]) => isMobile
            ? <option key={value} value={value}>{displayText}</option>
            : <MenuItem key={value} value={value}>{displayText}</MenuItem>);
};

const renderTests = (tests: Lookup, isMobile: boolean | undefined): JSX.Element[] => {
    return tests
        .map(({ value, displayText }) => isMobile
            ? <option key={value} value={value}>{displayText}</option>
            : <MenuItem key={value} value={value} >{displayText}</MenuItem>);
};

const CompleteInspection = (props: CompleteInspectionProps): JSX.Element => {
    const styles = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [disableCommit, setDisableCommit] = useState(true);
    const [test, setTest] = useState<ILookupItem>();
    const [note, setNote] = useState<string>("");
    const [wallPlateFitted, setWallPlateFitted] = useState<boolean | undefined>(props.inspection.isWallPlateFitted);
    const [serviceRecordsInDate, setServiceRecordsInDate] = useState<boolean | undefined>(props.inspection.isServiceRecordInDate);
    const [selectedRepairs, setSelectedRepairs] = useState<ILookupItem[]>([]);

    const closeDialog = (): void => {
        setShowDialog(false);
        setTest(undefined);
        setNote("");
        setSelectedRepairs([]);
    };
    const openDialog = (): void => setShowDialog(true);
    const isRiser = props.waterSourceCategory.value === 3;
    const handleWallPlateFittedChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => setWallPlateFitted(checked);
    const handleServiceRecordsInDateChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => setServiceRecordsInDate(checked);

    const handleTestChange = (event: React.ChangeEvent<{ value: unknown; }>): void => {
        const { value } = event.target;
        const item = isNumber(value)
            ? props.inspectionTests.getItem(Number(value))
            : undefined;
        setTest(item);
    };
    const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = event.target.value;
        setNote(value);
    };
    const handleTasksChange = (values: number[]): void => {
        const items = values
            .map(value => props.internalRepairs.getItem(Number(value)))
            .reduce((items, current) => {
                if (current) {
                    items.push(current);
                }
                return items;
            }, [] as ILookupItem[]);
        setSelectedRepairs(items);
    };

    const handleCommit = (): void => {
        closeDialog();
        if (props.onCommit) {
            assert.isDefined(test);
            const wallPlateFittedValue = isRiser ? wallPlateFitted : undefined;
            const serviceRecordsInDateValue = isRiser ? serviceRecordsInDate : undefined;
            props.onCommit(test, note, selectedRepairs, wallPlateFittedValue, serviceRecordsInDateValue);
        }
    };

    useEffect(() => {
        setDisableCommit(test === undefined);
    }, [test, test?.value]);

    const commitButton = <Button onClick={handleCommit} disabled={disableCommit}>{formatOutcome(props.outcome)}</Button>

    return (
        <React.Fragment>
            <Fab variant="extended" color="secondary" className={styles.commitButton} onClick={openDialog}>
                Complete
            </Fab>
            <AppDialog open={showDialog} appBar={{ title: "Inspection" }} commit={commitButton} onClose={closeDialog}>
                <Box mt={1}>
                    <AppDialogInput value={props.inspection.type.displayText} id="inspection-type" label="Type" disabled />
                    <AppDialogInput value={props.inspection.inspectionDate?.format(shortDateFormatter)} id="inspection-date" label="Due date" disabled />
                    <AppDialogInput value={formatInspector(props.inspector)} id="inspection-inspector" label="Inspector" disabled />
                    <AppDialogSelect
                        id="inspection-test"
                        label="Test"
                        native={props.isMobile}
                        placeholder="Please select..."
                        value={test?.value}
                        onChange={handleTestChange}
                    >
                        {renderTests(props.inspectionTests, props.isMobile)}
                    </AppDialogSelect>
                    <AppDialogMultiSelect
                        value={selectedRepairs.map(r => r.value)}
                        id="inspection-internalRepairs"
                        label="Internal Tasks Completed"
                        native={props.isMobile}
                        onChange={handleTasksChange}
                    >
                        {renderTasks(props.internalRepairs, props.isMobile)}
                    </AppDialogMultiSelect>
                    {isRiser && <FormControlLabel
                        label="Wall Plate Fitted"
                        labelPlacement="start"
                        className={styles.checkBoxLabel}
                        control={
                            <Checkbox
                                checked={wallPlateFitted}
                                onChange={handleWallPlateFittedChange}
                                className={styles.checkbox}
                                color="primary"
                            />
                        }
                    />}
                    {isRiser && <FormControlLabel
                        label="Service Records in Date"
                        labelPlacement="start"
                        className={styles.checkBoxLabel}
                        control={
                            <Checkbox
                                checked={serviceRecordsInDate}
                                onChange={handleServiceRecordsInDateChange}
                                className={styles.checkbox}
                                color="primary"
                            />
                        }
                    />}
                    <AppDialogInput value={note} id="inspection-note" label="Notes" multiline rows={3} onChange={handleNoteChange} />
                </Box>
            </AppDialog>
        </React.Fragment>
    );
};

export type { CompleteInspectionProps, InspectionOutcome };
export default CompleteInspection;