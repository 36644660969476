import React from 'react';
import { Popover, Icon } from "@blueprintjs/core";

import EWS from '../../images/WaterSourceIcons/Standard/icon-ews.png';
import HydrantAbandoned from '../../images/WaterSourceIcons/Standard/icon-hydrant-abandoned.png';
import HydrantArchived from '../../images/WaterSourceIcons/Standard/icon-hydrant-inactive.png';
import HydrantDefective from '../../images/WaterSourceIcons/Standard/icon-hydrant-defective.png';
import HydrantOperational from '../../images/WaterSourceIcons/Standard/icon-hydrant-operational.png';
import HydrantOperableDefective from '../../images/WaterSourceIcons/Standard/icon-hydrant-operable-defective.png';
import HydrantProposed from '../../images/WaterSourceIcons/Standard/icon-hydrant-proposed.png';
import HydrantAwaitingAdoption from '../../images/WaterSourceIcons/Standard/icon-hydrant-awaitingAdoption.png';
import Private from '../../images/WaterSourceIcons/Standard/icon-private-operational.png';
import Riser from "../../images/WaterSourceIcons/Standard/icon-riser-operational.png";
import Washout from '../../images/WaterSourceIcons/Standard/icon-washout-operational.png';
import KeyItem, { IItem } from './KeyItem';
import './mapKey.scss';
import styles from "./MapKey.module.scss";

const Items: IItem[] = [
    { status: "Operational", icon: HydrantOperational },
    { status: "Defective", icon: HydrantDefective },
    { status: "Operable Defective", icon: HydrantOperableDefective },
    { status: "Proposed", icon: HydrantProposed },
    { status: "Awaiting Adoption", icon: HydrantAwaitingAdoption },
    { status: "Abandoned", icon: HydrantAbandoned },
    { status: "Inactive", icon: HydrantArchived },
    { status: "Emergency Water Supply", icon: EWS },
    { status: "Riser", icon: Riser },
    { status: "Washout", icon: Washout },
    { status: "Private", icon: Private }
];

const KeyContent = (): JSX.Element => (
    <div className={styles.keyContainer}>
        <ul className={styles.key}>
            {Items.map((item, index) => <KeyItem key={index} item={item} />)}
        </ul>
    </div>
);

const MapKey = (): JSX.Element => (
    <div className={styles.root}>
        <Popover interactionKind="click" minimal content={<KeyContent />} position="bottom">
            <button className={styles.openKeyButton} title="Key" >
                <Icon icon="info-sign" iconSize={16} style={{ verticalAlign: "middle" }} />
            </button>
        </Popover>
    </div>
);

export default MapKey;