import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import { parseNodeId } from "../../../../../../lib/nodeIdentifier";
import { IWaterSource, IScheme } from "../../../../../../store/types";
import { useWaterSource } from "../../../../../appWindowContext";
import { WaterSourceActionProps } from "./types";
import WaterSourceActionMenu from "./WaterSourceActionMenu";

interface WaterSourceItemProps {
    readonly scheme: IScheme;
    readonly waterSource: IWaterSource;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
}));

const WaterSourceItem = ({ scheme, waterSource }: WaterSourceItemProps): JSX.Element => {
    const styles = useStyles();
    const { change: selectWaterSource } = useWaterSource();
    const handleClick = (): void => {
        selectWaterSource(parseNodeId(waterSource.waterSourceNodeId));
    };
    const menuProps: WaterSourceActionProps = {
        schemeNodeId: parseNodeId(scheme.schemeNodeId),
        waterSourceNodeId: parseNodeId(waterSource.waterSourceNodeId)
    };
    return (
        <ListItem className={styles.root} disableGutters button onClick={handleClick}>
            <ListItemText primary={waterSource.waterSourceId} primaryTypographyProps={{ variant: "body1" }} />
            <ListItemSecondaryAction>
                <WaterSourceActionMenu {...menuProps} />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export type { WaterSourceItemProps };
export default WaterSourceItem;