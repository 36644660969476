export default `mutation createThoroughfareMutation($input: AddThoroughfareInput!) {
    gis {
      thoroughfare {
        create(input: $input) {
          clientMutationId
          thoroughfare {
            thoroughfareNodeId: id
            thoroughfareId
            isActive
            name
            scheme {
              schemeId
            }
            path {
              coordinates {
                x
                y
              }
            }
          }
          hasErrors
          errors{
            message
            path
          }
        }
      }
    }
  }`