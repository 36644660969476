export default `
query getBasicWaterSourceDetailsWithFilterQuery($filter:QueryFilter, $paging: QueryControlInput) {
    waterSources(queryFilter: $filter, queryControl: $paging) {
        items {
            waterSourceNodeId: id
            waterSourceId: externalId
            category {
                value
                displayText
            }
            classification {
                value
                displayText
            }
            location {
                coordinates {
                    x
                    y
                }
            }
            isActive
            isOperable {
                value
                isOverridden
            }
            isDefective {
                value
                isOverridden
            }
            status {
                value
                displayText
            }
            riskSeverity {
                displayText
                value
            }
            lastInspectionDate
            nextInspectionDate
            recommendedInspectionDate
            ... on ITagged {
                tags {
                    value
                    displayText
                    enabled
                }
            }
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        totalCount
    }
}`;