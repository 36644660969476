import React, { useState, useRef } from "react";
import cls from "classnames";

import { IQuickSearchItem } from "../../store/types";

import CoordinatesSearch from "./components/coordinatesSearch";
import IdSearch from "./components/idSearch";
import PostCodeSearch from "./components/postCodeSearch";
import StreetSearch from "./components/streetSearch";
import QuickSearchInput from "./components/quickSearchInput";
import QuickSearchSelector from "./components/quickSearchSelector";
import { IQuickSearchType } from "./QuickSearch.d";
import * as SearchTypes from "./QuickSearchType";
import styles from "./QuickSearch.module.scss";
import "./QuickSearch.bp3.scss";

const isNotWhitespace = /\S/;

interface IQuickSearchProps {
    readonly isInline?: boolean;
    readonly searchTypes: IQuickSearchType[];
    readonly projectionCode?: string;
    readonly className?: string;
    readonly invalid?: boolean;
    readonly onSearchResultClick?: (result: IQuickSearchItem) => void;
}

const QuickSearch = (props: IQuickSearchProps): JSX.Element => {
    const [searchInput, setSearchInput] = useState("");
    const [validSearch, setValidSearch] = useState(true);
    const [showSelector, setShowSelector] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState(props.searchTypes[0].value);
    const inputRef = useRef<HTMLInputElement>();

    const focus = (): void => {
        inputRef.current?.focus();
    };

    const setInputRef = (ref: HTMLInputElement | null): void => {
        if (!inputRef.current) {
            inputRef.current = ref ?? undefined;
        }
    };

    const resetState = (): void => {
        setSearchInput("");
        setValidSearch(true);
    };

    const handleInputClear = (): void => {
        setShowSelector(true);
        resetState();
    };
    const handleInputFocus = (): void => {
        setShowSelector(searchInput === "");
    };
    const handleInputSearch = (value: string): void => {
        setShowSelector(false);
        if (value === "") {
            resetState();
        }
        setSearchInput(value);
    };

    const handleSelectorChange = (selectedType: string): void => {
        setSelectedSearch(selectedType);
        focus();
    };
    const handleSelectorClose = (): void => {
        setShowSelector(false);
    };

    const handleSearchResultClick = (result: IQuickSearchItem): void => {
        resetState();
        props.onSearchResultClick?.(result);
    }
    const handleSearchInvalid = (): void => {
        setValidSearch(false);
    };
    const handleSearchClose = (): void => {
        setShowSelector(true);
        resetState();
        focus();
    };

    const renderSearch = (inline: boolean | undefined): JSX.Element => {
        if (isNotWhitespace.test(searchInput)) {
            switch (selectedSearch) {
                case SearchTypes.waterSourceIdSearch.value:
                    return <IdSearch input={searchInput} inline={inline} onSearchResultClick={handleSearchResultClick} onSearchInvalid={handleSearchInvalid} onClose={handleSearchClose} />;
                case SearchTypes.streetSearch.value:
                    return <StreetSearch input={searchInput} inline={inline} onSearchResultClick={handleSearchResultClick} onSearchInvalid={handleSearchInvalid} onClose={handleSearchClose} />;
                case SearchTypes.postCodeSearch.value:
                    return <PostCodeSearch input={searchInput} onSearchInvalid={handleSearchInvalid} />;
                case SearchTypes.gridRefSearch.value:
                    return <CoordinatesSearch input={searchInput} onSearchInvalid={handleSearchInvalid} />;
                default:
                    return <React.Fragment />;
            }
        }
        return <React.Fragment />;
    };

    return (
        <div className={cls(styles.root, props.className)}>
            <QuickSearchInput
                input={searchInput}
                valid={validSearch && !(props.invalid ?? false)}
                isInline={props.isInline}
                inputRef={setInputRef}
                onClear={handleInputClear}
                onFocus={handleInputFocus}
                onSearch={handleInputSearch}
            />
            <QuickSearchSelector
                show={showSelector}
                types={props.searchTypes}
                currentSearch={selectedSearch}
                onChange={handleSelectorChange}
                onClose={handleSelectorClose}
            />
            {renderSearch(props.isInline)}
        </div>
    );
};

export type { IQuickSearchProps };
export default QuickSearch;