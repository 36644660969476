import React from "react";

import Box from "@material-ui/core/Box";

import ClearAddress from "./ClearAddress";
import EditAddress from "./EditAddress";
import FindAddress from "./FindAddress";
import { AddressDetailsData, AddressField } from "./types";
import { isSavedAddress } from "./utils";

interface AddressEditorProps extends AddressField {
    readonly initialAddress?: AddressDetailsData;
}

const AddressEditor = ({ owner, initialAddress, editing, onClear, onSelect, onUpdate }: AddressEditorProps): JSX.Element => {
    const saved = isSavedAddress(initialAddress);
    return editing
        ? (
            <Box display="flex" flexDirection="row" flexGrow={1} my={0.5}>
                <EditAddress context="editor" onUpdate={onUpdate} hidden={!saved} initialAddress={initialAddress ?? {}} owner={owner} />
                <Box display="flex" flexDirection="row" ml="auto">
                    <FindAddress onSelect={onSelect} onUpdate={onUpdate} owner={owner} />
                    <ClearAddress onConfirm={onClear} hidden={!saved} owner={owner} />
                </Box>
            </Box>
        )
        : <React.Fragment />;
};

export type { AddressEditorProps };
export default AddressEditor;