import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import updateDetails, { DetailsData } from "../../api/waterSources/updateDetails";
import updateLocation, { LocationData } from "../../api/waterSources/updateLocation";
import { isHydrant } from "../../components/inspectionView/components/utils";
import useSelector from "../../lib/hooks/useSelector";
import { NodeID, parseNodeId } from "../../lib/nodeIdentifier";
import { CreateInspectionData, ILookupItem } from "../../store/types";
import { addHazards, removeHazards } from "../../store/actions/hazards";
import { prepareHazardsToSave } from "../../components/waterSource/waterSourceControl/waterSourceControl.utils";
import { IPrepareHazardsToSaveData } from "../../components/waterSource/waterSourceControl/types";
import { useWaterSource } from "../../api/hooks";
import { EditState } from "../inspectionView/InspectionView.view";
import NearMeWaterSourceView from "./NearMeWaterSourceView.view";
import { formatNote } from "../inspectionView/InspectionView.redux";
import { startCreateInspection } from "../../store/actions/inspections";
import { guid } from "../../lib/Utils";
import { NavigationState, useNavigation } from "../../router/MobileRouter/hooks";
import { useRouterContext } from "../../router/MobileRouter/components/RouterContext";

const inspectionTabIndex = 3;

const ConnectedNearMeWaterSourceView = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory<NavigationState>();
    const routerContext = useRouterContext();
    const { gotoInspection } = useNavigation();
    const { waterSourceNodeId = "" } = useParams<{ waterSourceNodeId?: string }>();
    const [waterSourceStatus, waterSource, fetchWaterSource] = useWaterSource(waterSourceNodeId);
    const [loadingWaterSource, setLoadingWaterSource] = useState(false);
    const allHazardsTypes = useSelector(state => state.app.lookups?.hazardTypes);

    useEffect(() => {
        setLoadingWaterSource(waterSourceStatus !== "complete");
    }, [waterSourceStatus]);

    useEffect(() => {
        if (waterSource?.inspectionCount && waterSource?.inspections?.length) {
            const [inspection] = waterSource.inspections;
            history.replace("/near-me");
            gotoInspection(parseNodeId(inspection.inspectionNodeId), inspectionTabIndex);
            routerContext.pop();
        }
    }, [waterSource?.inspectionCount]);

    const handleRefresh = (): void => {
        fetchWaterSource();
    };

    const handleSaveLocation = async (nodeId: NodeID, update: EditState, category: ILookupItem): Promise<void> => {
        const data: LocationData = isHydrant(category)
            ? {
                hydrantLocationId: update.hydrantLocation?.value ?? null,
                locationAddressId: update.address?.addressId ?? null,
                locationCoordinates: update.coordinates ?? null,
                locationDescription: update.description ?? null,
                controlMeasures: []
            }
            : {
                locationAddressId: update.address?.addressId ?? null,
                locationCoordinates: update.coordinates ?? null,
                locationDescription: update.description ?? null,
                controlMeasures: []
            };
        await updateLocation(nodeId, data);
        fetchWaterSource();
    };

    const handleSaveWaterSource = async (nodeId: NodeID, update: EditState, category: ILookupItem): Promise<void> => {
        const data: DetailsData = isHydrant(category)
            ? {
                classificationId: update.waterSource.classification?.value ?? null,
                flowRate: update.waterSource.flowRate ?? null,
                mainsSize: update.waterSource.mainsSize?.value ?? null,
                mainsSizeUnitId: update.waterSource.mainsSize?.unit?.value ?? null,
                pressure: update.waterSource.pressure ?? null,
                stationId: update.waterSource.station?.value ?? null,
                surfaceId: update.waterSource.surface?.value ?? null,
                additionalInfo: formatNote(update.waterSource.additionalInfo) ?? null,
                controlMeasures: update.waterSource.controlMeasures.map(controlMeasure => controlMeasure.value),
                plateLocationId: update.waterSource.plateLocation?.value ?? null,
                plateDistance: update.waterSource.plateDistance ?? null
            }
            : {
                classificationId: update.waterSource.classification?.value ?? null,
                stationId: update.waterSource.station?.value ?? null,
                additionalInfo: formatNote(update.waterSource.additionalInfo) ?? null,
                controlMeasures: update.waterSource.controlMeasures.map(controlMeasure => controlMeasure.value)
            };
        await updateDetails(nodeId, data);
        fetchWaterSource();
    };

    const handleSaveHazards = async (nodeId: NodeID, update: EditState): Promise<void> => {
        const hazardSeverityValue = update.waterSource.hazardSeverity?.value;
        const prepareHazardsToSaveData: IPrepareHazardsToSaveData = {
            hazardSeverityValue,
            allHazardsTypes,
            hazardsValues: update.waterSource.hazardsValues,
            initialHazardsValues: waterSource?.hazards?.map(hazard => hazard.hazardType.value),
            allHazards: waterSource?.hazards
        };

        const { addedHazards, deletedHazards } = prepareHazardsToSave(prepareHazardsToSaveData);

        const data: DetailsData = {
            controlMeasures: update.waterSource.controlMeasures.map(controlMeasure => controlMeasure.value),
            hazardInformation: formatNote(update.waterSource.hazardInformation) ?? null,
            roadSpeedId: update.waterSource.roadSpeed?.value,
            hazardFlagId: update.waterSource.hazardFlag?.value,
            isSecondPersonRequired: update.waterSource.isSecondPersonRequired
        };
        await updateDetails(nodeId, data);

        if (addedHazards && addedHazards.length > 0) {
            const data = addedHazards.map(h => ({
                hazardTypeId: h.value
            }));
            const action = addHazards(data, waterSourceNodeId);
            await action(dispatch);
        }
        if (deletedHazards && deletedHazards.length > 0) {
            const action = removeHazards(deletedHazards.map(h => h.hazardNodeId), waterSourceNodeId);
            await action(dispatch);
        }

        fetchWaterSource();
    };

    const createInspection = async (data: CreateInspectionData): Promise<void> => {
        const action = startCreateInspection({
            input: {
                clientMutationId: guid(),
                data
            }
        });
        return action(dispatch);
    };

    const handleCreateInspection = async (data: CreateInspectionData): Promise<void> => {
        setLoadingWaterSource(true);
        await createInspection(data)
            .then(fetchWaterSource)
            .catch(error => console.warn("Cannot create inspection; an error occurred.", error))
            .finally(() => setLoadingWaterSource(false));
    };

    return (
        <NearMeWaterSourceView
            waterSource={{ item: waterSource, loading: loadingWaterSource }}
            actionsEnabled={false}
            onRefresh={handleRefresh}
            onSaveLocation={handleSaveLocation}
            onSaveWaterSource={handleSaveWaterSource}
            onSaveHazards={handleSaveHazards}
            onCreateInspection={handleCreateInspection}
        />
    );
};

export default ConnectedNearMeWaterSourceView;