export default `
query getDefect_Query($id: ID!) {
    node(id:$id) {
        ... on Defect {
            defectNodeId: id
            defectId
            waterSource {
                waterSourceNodeId: id
            }
            parentInspection {
                inspectionNodeId: id
                inspectionId
                type {
                    displayText
                    value
                }
                inspectionDate
                description
            }
            type {
                displayText
                value
            }
            dateReported
            reportedBy
            isOpen
            isResolved
            tags {
                displayText
                value
                enabled
            }
            cancelReason {
                displayText
                value
            }
            closeReason {
                displayText
                value
            }
            lastEvent {
                timestamp
                action
                actor
            }
            events {
                timestamp
                action
                actor
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
        }        
    }
}`;