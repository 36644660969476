import React, { useCallback, useMemo, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";

import { ClearAddressProps as PropsBase } from "../../../../address/ClearAddress";
import AppDialog, { AppDialogText } from "../../../../shared/appDialog";

type ClearAddressProps = Omit<PropsBase, "owner">

const useStyles = makeStyles(createStyles({
    button: {
        marginRight: "-5px"
    }
}));

const ClearAddress = ({ hidden, onConfirm }: ClearAddressProps): JSX.Element => {
    const styles = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const handleConfirmClick = useCallback((): void => {
        setDialogOpen(false);
        onConfirm?.();
    }, [onConfirm]);

    const commitButton = useMemo(() => (
        <IconButton edge="end" onClick={handleConfirmClick}>
            <DoneIcon />
        </IconButton>
    ), [handleConfirmClick]);

    if (hidden) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <Button size="small" color="secondary" className={styles.button} onClick={handleActionClick}>Clear</Button>
            <AppDialog open={dialogOpen} appBar={{ title: "Clear Address?" }} commit={commitButton} onClose={handleCloseDialog}>
                <Box mt={1.5}>
                    <AppDialogText text="Are you sure you want to clear this address from the water source? Remember, the change is not permanent until the water source is saved." />
                    <AppDialogText text="The address record is not deleted." />
                </Box>
            </AppDialog>
        </React.Fragment>
    );
};

export type { ClearAddressProps };
export default ClearAddress;