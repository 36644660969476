import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppAction, IGlobalToast, ILookups, IDataTabsSelectedTab, IAccount, IConfigurableLookups, ILookupItem } from '../types/app';
import { Inspector, ITechnician } from '../types/inspections';

import { DialogVariant, ICreateInspectionDialogOpenProps } from '../../components/utils/globalDialog';
import executeQuery from '../../lib/executeQuery';
import { defaultConfigurableLookups, defaultLookups } from "../reducers/app";
import getLookupsQuery from './graphQL/getLookups';
import getConfigurableLookupsQuery from './graphQL/getConfigurableLookups';
import getProfileQuery from "./graphQL/getProfile";

export const setDataTabSelectedTab = (dataTabsSelectedTab: IDataTabsSelectedTab): IAppAction => ({
    type: 'SET_DATA_TAB_SELECTED_TAB',
    dataTabsSelectedTab
});

export const setDialogOpen = (variant?: DialogVariant, createInspectionProps?: ICreateInspectionDialogOpenProps): IAppAction => ({
    type: 'SET_DIALOG_OPEN',
    dialog: variant
        ? createInspectionProps ? { variant, createInspectionProps } : { variant }
        : undefined
});

export const setIsLoading = (isLoading: boolean): IAppAction => ({
    type: "SET_IS_LOADING",
    isLoading
});

export const setLookups = (lookups: ILookups): IAppAction => ({
    type: 'SET_LOOKUPS',
    lookups
});

export const setConfigurableLookups = (configurableLookups: IConfigurableLookups): IAppAction => ({
    type: 'SET_CONFIGURABLE_LOOKUPS',
    configurableLookups
});

export const setGlobalToast = (globalToast?: IGlobalToast): IAppAction => ({
    type: 'SET_GLOBAL_TOAST',
    globalToast
});

export const setTokenExpiry = (tokenExpiry: number): IAppAction => ({
    type: 'SET_TOKEN_EXPIRY',
    tokenExpiry
});

export const clearGlobalToast = (): IAppAction => setGlobalToast();

export const setErrorToast = (message: string, error: string | Error, timeout = 5000): IAppAction => setGlobalToast({
    type: "ERROR",
    showToast: true,
    message,
    timeout,
    error
});

export const setInfoToast = (message: string, timeout = 3000): IAppAction => setGlobalToast({
    type: "INFO",
    showToast: true,
    message,
    timeout
});

export const setLoadingToast = (message: string, timeout: number | undefined = undefined): IAppAction => setGlobalToast({
    type: 'LOADING',
    showToast: true,
    message,
    timeout
});

export const setSuccessToast = (message: string, timeout = 3000): IAppAction => setGlobalToast({
    type: "SUCCESS",
    showToast: true,
    message,
    timeout
});

export const setAccount = (account: IAccount): IAppAction => ({
    type: 'SET_ACCOUNT',
    account
});

export const setVersion = (uiVersion: string | undefined, apiVersion: string | undefined): IAppAction => ({
    type: "SET_VERSION",
    version: {
        uiVersion,
        apiVersion
    }
});

export const setProfile = (profile?: Inspector): IAppAction => ({
    type: "SET_PROFILE",
    profile
});

export const shouldReloadWaterSources = (shouldReload: boolean): IAppAction => ({
    type: 'SHOULD_RELOAD_WATER_SOURCES',
    shouldReload
});

const getLookupsByQuery = <T>(query: string, action: ((configurableLookups: T) => IAppAction), defaultValue: T) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        dispatch(setIsLoading(true));
        const data = await executeQuery<{ lookups: T }>(query, {});
        dispatch(action(data?.lookups ?? defaultValue));
        dispatch(setIsLoading(false));
    };
};

export const getLookups = (): ((dispatch: ThunkDispatch<any, any, AnyAction>) => Promise<void>) => {
    return getLookupsByQuery(getLookupsQuery, setLookups, defaultLookups);
};

export const getConfigurableLookups = (): ((dispatch: ThunkDispatch<any, any, AnyAction>) => Promise<void>) => {
    return getLookupsByQuery(getConfigurableLookupsQuery, setConfigurableLookups, defaultConfigurableLookups);
};

export const updateLookups = (lookup: keyof ILookups, updatedLookups: ILookupItem[]): IAppAction => ({
    type: 'UPDATE_LOOKUPS',
    lookup,
    updatedLookups
});

export const getProfile = (variables: { userName: string }) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        const data = await executeQuery<{ technician: ITechnician }>(getProfileQuery, variables);
        dispatch(setProfile(data?.technician));
    };
};
