import Feature, { FeatureLike } from 'ol/Feature';
import { Size } from 'ol/size';
import { Style, RegularShape, Fill, Stroke, Text, Icon } from 'ol/style';
import { Options as StyleOptions, StyleLike } from 'ol/style/Style';
import { Geometry, Polygon, Point } from 'ol/geom';
import { getLength } from 'ol/sphere';
import { Coordinate } from 'ol/coordinate';
import StyleCache from '../../components/map/StyleCache';
import ImageStyle from 'ol/style/Image';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import VectorSource from 'ol/source/Vector';
import Color from 'color';

import { ILookupItem, IWaterSource, IMapWaterSource, IMapProjection } from '../../store/types';
import { DeviceLocation } from '../../api/hooks';
import CadMap from '../../components/map/desktop';
import { serialize } from '../checksum';

export const defaultProjection: IMapProjection = {
    code: 'EPSG:27700',
    definition: '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs'
};

export interface IMapSelection {
    readonly focusMap: boolean;
    readonly waterSourceId: string;
    readonly waterSourceReference: string;
    readonly waterSourceNodeId: string;
}

export interface ClusterStyleInfo {
    readonly fillColor?: string;
    readonly imageRadius?: number;
}

export interface IconInfo {
    readonly anchor?: number[];
    readonly opacity?: number;
    readonly scale?: number;
    readonly src?: string;
    readonly size?: Size;
}

export interface LabelInfo {
    readonly backgroundColor?: string;
    readonly color?: string;
    readonly fontSize?: string;
}

export interface WaterSourceStyleInfo {
    readonly key: string;
    readonly icon: IconInfo;
    readonly label: LabelInfo;
}

export type WaterSourceFeatureData = IMapWaterSource & {
    readonly reference: string;
    readonly hasFocus?: boolean;
};

export interface WaterSourceFeatureInfo {
    readonly coordinates: number[];
    readonly data: WaterSourceFeatureData;
    readonly styles: WaterSourceStyleInfo;
}

export const formatLength = (line: Geometry): string => {
    const length = getLength(line);
    const output = length > 1000
        ? `${(Math.round(length / 1000 * 100) / 100)} km`
        : `${(Math.round(length * 100) / 100)} m`;
    return output;
};

const isEws = (category: ILookupItem): boolean => {
    return category.value === 2;
};
const isRiser = (category: ILookupItem): boolean => {
    return category.value === 3;
};

const getActivenessLabel = (isActive: boolean): string => isActive
    ? ''
    : '-inactive';
const getCategoryLabel = (category: ILookupItem): string => category && category.value === 2
    ? '-ews'
    : '';
const getOperationalStatusLabel = (isDefective: boolean, isOperable: boolean): string => isDefective && isOperable
    ? '-operable-defective'
    : isDefective
        ? '-defective'
        : '-operational';
const getLifecycleStatusLabel = (status?: ILookupItem): string => {
    switch (status?.value) {
        case 4: // hydrant
            return "-proposed";
        case 5: // hydrant
            return "-awaitingAdoption"
        case 3:  // hydrant
        case 12: // ews
        case 15: // unknown ws
            return "-abandoned";
        default:
            return "";
    }
};
const getTagLabel = (category: ILookupItem, tags?: ILookupItem[]): string => {
    const isMatch = (tag: ILookupItem, text: string): boolean => {
        return tag.displayText.toUpperCase() === text.toUpperCase();
    };
    const isPrivate = (tag: ILookupItem): boolean => isMatch(tag, "PRIVATE");
    const isWashout = (tag: ILookupItem): boolean => isMatch(tag, "WASHOUT");

    if (isRiser(category)) {
        return "-riser";
    }
    if (tags) {
        if (tags.some(isPrivate)) {
            return "-private";
        }
        if (tags.some(isWashout)) {
            return "-washout";
        }
    }
    return "-hydrant";
};

const getIconName = (isActive: boolean, isOperable: boolean, isDefective: boolean, category: ILookupItem, status?: ILookupItem, tags?: ILookupItem[]): string => {
    if (isEws(category)) {
        return isActive ? "icon-ews" : "icon-ews-inactive";
    }

    const waterSourceTag = getTagLabel(category, tags);
    const activeness = getActivenessLabel(isActive);
    if (!isActive) {
        return `icon${waterSourceTag}${activeness}`;
    }

    const waterSourceStatus = getLifecycleStatusLabel(status);
    if (waterSourceStatus) {
        return `icon${waterSourceTag}${waterSourceStatus}`;
    }

    const waterSourceOperationalStatus = getOperationalStatusLabel(isDefective, isOperable);
    return `icon${waterSourceTag}${waterSourceOperationalStatus}`;
};

const getImageRadius = (clusterSize: number): number => {
    if (clusterSize < 10) return 14;
    if (clusterSize >= 10 && clusterSize < 100) return 19;
    return 26;
}

export const iconInfo = (styleKey: string, scale: number, opacity: number): IconInfo => {
    const src = `/img/ws-icons-v2/${styleKey}.png`;
    return {
        src,
        scale,
        size: [48, 48],
        opacity: opacity,
        anchor: [24, 24]
    };
};

export const labelInfo = (selected: boolean, inspectionCount: number | undefined, opacity = 1): LabelInfo => {
    if (selected) {
        return { backgroundColor: "#ff810c", color: "#273c4e", fontSize: "21px" };
    }
    if (inspectionCount) {
        return { backgroundColor: "#0000ff", color: "#ffffff" };
    }
    return { backgroundColor: "rgba(242,239,233,0.5)", color: `rgba(22,34,44,${opacity})` };
};

const getOpacity = (selected: boolean, hasFocus: boolean | undefined): 0.3 | 1 => {
    if (selected) {
        return 1;
    }
    return hasFocus === undefined
        ? 1
        : hasFocus ? 1 : 0.3
};

export const waterSourceStyleInfo = (
    isActive: boolean,
    isOperable: boolean,
    isDefective: boolean,
    selected: boolean,
    category: ILookupItem,
    status: ILookupItem | undefined,
    inspectionCount: number | undefined,
    tags: ILookupItem[] | undefined,
    hasFocus?: boolean): WaterSourceStyleInfo => {
    const scale = 0.5;
    const opacity = getOpacity(selected, hasFocus);

    const iconName = getIconName(isActive, isOperable, isDefective, category, status, tags);
    const icon = iconInfo(iconName, scale, opacity);
    const label = labelInfo(selected, inspectionCount, opacity);

    return {
        key: `${iconName}${inspectionCount ? "-inspections" : ""}${selected ? "-selected" : ""}-${opacity}`,
        icon,
        label
    };
};

export const clusterStyle = (clusterProps: ClusterStyleInfo | undefined): StyleLike => {
    return (feature: FeatureLike): Style => {
        const { fillColor } = clusterProps ?? {};
        const features = feature.get("features") as any[];
        const size = features.length;
        const imageRadius = getImageRadius(size);
        const imageRadiusMultiplier = 10;
        const clusterStyle: StyleOptions = {
            image: new RegularShape({
                fill: new Fill({
                    color: fillColor ?? '#e73930'
                }),
                stroke: new Stroke({
                    color: '#273c4e',
                    width: 2
                }),
                points: 50,
                radius: imageRadius ?? (imageRadiusMultiplier * Number(size)),
                angle: Math.PI / 4
            }),
            text: new Text({
                text: String(size),
                font: "21px Montserrat,'Helvetica Neue',Helvetica,Arial,sans-serif",
                fill: new Fill({
                    color: 'black'
                })
            }),
            ...clusterProps
        }
        return new Style(clusterStyle);
    };
};

const pointStyleCore = (styles: WaterSourceStyleInfo): StyleLike => {
    const fallbackIcon: ImageStyle = new RegularShape({
        fill: new Fill({ color: "#ffcf21" }),
        stroke: new Stroke({ color: "#273c4e", width: 2 }),
        points: 4,
        radius: 16,
        angle: Math.PI / 4
    });

    const { icon, label } = styles ?? {};

    const iconImage: ImageStyle = icon?.src !== undefined
        ? new Icon({
            src: icon.src,
            scale: icon.scale ?? 0.5,
            size: icon.size ?? [48, 48],
            opacity: icon.opacity ?? 1,
            anchor: icon.anchor,
            anchorXUnits: IconAnchorUnits.PIXELS,
            anchorYUnits: IconAnchorUnits.PIXELS,
            rotation: 0,
            crossOrigin: 'anonymous'
        })
        : fallbackIcon;

    const backgroundColor = label.backgroundColor
        ? label.backgroundColor
        : 'rgba(255, 255, 255, 0.5)';

    const iconStyle = new Style({
        image: iconImage,
        text: new Text({
            font: `${label.fontSize ?? "12px"} Montseratt,sans-serif`,
            text: "(ref)",
            fill: new Fill({
                color: label.color,
            }),
            stroke: new Stroke({
                color: label.color,
                width: 0.70
            }),
            backgroundFill: new Fill({
                color: new Color(backgroundColor).string(),
            }),
            padding: [4, 8, 4, 8],
            offsetX: 0,
            offsetY: 20,
            textAlign: "center",
            textBaseline: "top"
        }),
    });

    return (feature: FeatureLike): Style => {
        iconStyle.getText().setText(feature.get('reference'));
        return iconStyle;
    }
};

export const pointStyle = (styles: WaterSourceStyleInfo, styleCache: StyleCache, selected = false): StyleLike => {
    if (!selected && styles?.key) {
        let iconStyle = styleCache.getStyle(styles.key);
        if (!iconStyle) {
            iconStyle = pointStyleCore(styles);
            styleCache.addStyle(styles.key, iconStyle);
        }
        return iconStyle;
    }
    return pointStyleCore(styles);
};

export const vectorSource = (features?: Feature[]): VectorSource => {
    return new VectorSource({
        features: features ?? []
    });
};

export const pointFeature = (coordinates: Coordinate, data: Record<string, any>): Feature => {
    const checksum = serialize(data);
    const feature = new Feature({
        geometry: new Point(coordinates),
        ...data,
        checksum
    });
    return feature;
};

export const polygonFeature = (polygon: Polygon, data: Record<string, unknown>): Feature => {
    const feature = new Feature({
        geometry: polygon,
        ...data
    });
    return feature;
};

export const waterSourceFeature = (coordinates: Coordinate, data: Record<string, any>, styleInfo: WaterSourceStyleInfo, styleCache: StyleCache): Feature => {
    const feature = pointFeature(coordinates, data);
    if (styleInfo) {
        const style = pointStyle(styleInfo, styleCache);
        feature.setStyle(style);
    }
    return feature;
};

export const renderWaterSources = (context: CadMap, selectedWaterSource: IWaterSource | undefined, focusedWaterSources?: IWaterSource[], fitWaterSourcesToMap?: boolean): void => {
    // remove features from selection (this is essentially a seperate source to context.pointSource)
    context.clearSelectedFeatures();

    const waterSources = context.props.data.waterSources ?? [];

    const isSelected = (nodeId: string): boolean => selectedWaterSource?.waterSourceNodeId === nodeId;
    const hasFocus = (nodeId: string): boolean | undefined => {
        return focusedWaterSources?.some(ws => ws.waterSourceNodeId === nodeId);
    };

    const start: any = new Date();
    const info: string[] = [];
    const features = waterSources
        .map((waterSource): Feature | undefined => {
            if (waterSource?.location?.coordinates) {
                const data: WaterSourceFeatureData = {
                    ...waterSource,
                    reference: waterSource.waterSourceId.toString(),
                    hasFocus: hasFocus(waterSource.waterSourceNodeId)
                };
                const isWaterSourceSelected = isSelected(waterSource.waterSourceNodeId);

                const styles = waterSourceStyleInfo(
                    data.isActive,
                    data.isOperable.value,
                    data.isDefective.value,
                    isWaterSourceSelected,
                    data.category,
                    data.status,
                    data.inspectionCount,
                    data.tags,
                    data.hasFocus);

                const coordinates = [waterSource.location.coordinates.x, waterSource.location.coordinates.y];

                return waterSourceFeature(coordinates, data, styles, context.styleCache);
            }
            return undefined;
        })
        .reduce((current: Feature[], candidate: Feature | undefined): Feature[] => {
            // note: this is not the 'correct' way to write a collection reducer function, but is the most performant
            if (candidate) {
                current.push(candidate);
            }
            return current;
        }, []);

    console.log("focused ws:", info);

    context.addFeatures(features);
    context.setSelectedFeature(feature => {
        const nodeId = feature.get("waterSourceNodeId");
        return nodeId
            ? isSelected(nodeId)
            : false;
    });

    if (fitWaterSourcesToMap) {
        context.fitFeatures();
    }

    const end: any = new Date();
    const duration = end - start; // milliseconds

    console.log(`map: DONE rendering ${features.length} points in ${duration} ms`);
};


export const toLocationFeature = (deviceLocation: DeviceLocation | undefined): Feature | undefined => {
    if (deviceLocation) {
        if (deviceLocation instanceof Error) {
            return undefined;
        }

        const iconImage: ImageStyle = new Icon({
            src: `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/></svg>`,
            scale: 1,
            size: [60, 60],
            opacity: 1
        });

        if (deviceLocation[0] > 0 && deviceLocation[1] > 0) {
            const feature = new Feature({
                geometry: new Point(deviceLocation),
            });
            const style = new Style({
                image: iconImage,
            });
            feature.setStyle(style);
            return feature;
        }
    }
    return undefined;
};
