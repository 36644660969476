import React, { useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import Dialog from "../../../../dialog/dialog";
import DialogActions from "../../../../dialog/dialogActions";
import DialogContent from "../../../../dialog/dialogContent";
import DialogTitle from "../../../../dialog/dialogTitle";

interface RemoveSchemeBoundaryProps {
    readonly onRemoveBoundary?: () => void;
}

const RemoveSchemeBoundary = ({ onRemoveBoundary }: RemoveSchemeBoundaryProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleCloseClick = (): void => {
        setDialogOpen(false);
    };
    const handleConfirmClick = (): void => {
        setDialogOpen(false);
        onRemoveBoundary?.();
    }
    const handleItemClick = (): void => {
        setDialogOpen(true);
    };

    return (
        <React.Fragment>
            <MenuItem onClick={handleItemClick}>Remove Boundary</MenuItem>
            <Dialog open={dialogOpen} onClose={handleCloseClick}>
                <DialogTitle onClose={handleCloseClick} >
                    Remove Scheme Boundary?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" paragraph>
                        Are you sure you want to remove the boundary from this scheme?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button id="cancelEdit-yes-button" className="action-button action-button--cancel" onClick={handleConfirmClick}>Yes</button>
                    <button id="cancelEdit-no-button" className="action-button" onClick={handleCloseClick}>No</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export type { RemoveSchemeBoundaryProps };
export default RemoveSchemeBoundary;