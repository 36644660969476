export default `

fragment Defect on Defect {
    defectNodeId: id
    defectId
    waterSource {
        waterSourceNodeId: id
        waterSourceId: externalId
    }
    type {
        displayText
        value
    }
    dateReported
    dateClosed
    closedBy
    reportedBy
    isOperable
    isOpen
}

query getDefectsByFilterQuery($filter:QueryFilter, $paging:QueryControlInput) {
    defects(queryFilter:$filter, queryControl:$paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        items {
            ... Defect
        }
    }
}`;