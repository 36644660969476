import React from "react";

import bindLookup from "../../../../lib/lookups";
import useSelector from "../../../../lib/hooks/useSelector";
import { Inspector } from "../../../../store/types";
import CompleteInspection, { CompleteInspectionProps } from "./CompleteInspection.view";

type ConnectedProps = Omit<CompleteInspectionProps, "inspector" | "inspectionTests" | "internalRepairs" | "isMobile">;

const ConnectedCompleteInspection = (props: ConnectedProps): JSX.Element => {
    const connectedProps: CompleteInspectionProps = {
        ...props,
        inspector: useSelector<Inspector | undefined>(state => state.app.user?.profile),
        inspectionTests: useSelector(state => bindLookup(state.app.lookups?.inspectionTests ?? [])),
        internalRepairs: useSelector(state => bindLookup(state.app.lookups?.repairTypes.filter(item => item.groupingId === 2) ?? [])),
        isMobile: useSelector(state => state.device.isMobile),
    };
    return <CompleteInspection {...connectedProps} />
};

export default ConnectedCompleteInspection;