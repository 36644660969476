import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

interface FilterLabelProps {
    readonly htmlFor?: string;
    readonly children?: React.ReactChild;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
    },
    label: {
        color: theme.palette.secondary.main,
        marginBottom: "4px",
        display: "block",
        alignSelf: "center"
    }
}));

const FilterLabel = (props: FilterLabelProps): JSX.Element => {
    const { htmlFor, children } = props;
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <label htmlFor={htmlFor} className={styles.label}>{children}</label>
        </div>
    );
};

export type { FilterLabelProps };
export default FilterLabel;