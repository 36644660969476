import React from "react";
import { useDispatch } from "react-redux";

import CloseIcon from '@material-ui/icons/Close';

import { startRemoveWaterSourceFromScheme } from "../../../../../../store/actions/schemes";
import { useScheme, useWaterSource } from "../../../../../appWindowContext";
import { WaterSourceActionProps } from "./types";
import WaterSourceActionItem from "./WaterSourceActionItem";
import { setRenderPoints } from "../../../../../../store/actions/map";

const RemoveWaterSourceAction = ({ schemeNodeId, waterSourceNodeId }: WaterSourceActionProps): JSX.Element => {
    const dispatch = useDispatch();
    const { refresh: refreshScheme } = useScheme();
    const { refresh: refreshWaterSource } = useWaterSource();
    const handleClick = async (): Promise<void> => {
        const action = startRemoveWaterSourceFromScheme(schemeNodeId, waterSourceNodeId);
        await action(dispatch);
        await refreshScheme();
        await refreshWaterSource();
        dispatch(setRenderPoints(true));
    };
    return <WaterSourceActionItem icon={<CloseIcon />} primary="Remove" onClick={handleClick} />;
};

export type { WaterSourceActionProps };
export default RemoveWaterSourceAction;