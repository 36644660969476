import React from "react";

import Typography from "@material-ui/core/Typography";

import LabelledField from "../../../components/labelledField";
import { ILookupItem } from "../../../store/types";
import { ELEVATION_0 } from "@blueprintjs/core/lib/esm/common/classes";

interface StationInfoProps {
    readonly station: ILookupItem;
    readonly onMapXChange?: (value: number | null) => void;
    readonly onMapYChange?: (value: number | null) => void;
}

const handleChange = (value: unknown, onChange: ((value: number | null) => void) | undefined): void => {
    if (value === undefined || value === null) {
        onChange?.(null);
    }
    if (typeof value === "string") {
        onChange?.(parseInt(value));
    }
    if (typeof value === "number") {
        onChange?.(value);
    }
};

const StationInfo = ({ station, onMapXChange, onMapYChange }: StationInfoProps): JSX.Element => {
    const handleChangeEasting = (value: unknown): void => {
        handleChange(value, onMapXChange);
    };
    const handleChangeNorthing = (value: unknown): void => {
        handleChange(value, onMapYChange);
    };
    return (
        <div>
            <Typography variant="body1">Easting</Typography>
            <LabelledField
                editing
                fieldType="number"
                id="station-mapX"
                value={station.mapX}
                minValue={0}
                maxValue={999999}
                label="Easting"
                labelPosition="HIDDEN"
                onChange={handleChangeEasting}
            />
            <Typography variant="body1">Northing</Typography>
            <LabelledField
                editing
                fieldType="number"
                id="station-mapY"
                value={station.mapY}
                minValue={0}
                maxValue={999999}
                label="Northing"
                labelPosition="HIDDEN"
                onChange={handleChangeNorthing}
            />
        </div>
    );
};

export type { StationInfoProps };
export default StationInfo;