import { IAccess } from "../store/types";
import { RoleName } from "./roles";

interface RoleChecks {
    readonly has: (role: RoleName) => boolean;
    readonly hasAny: (roles: RoleName[]) => boolean;
    readonly hasAll: (roles: RoleName[]) => boolean;
}

const checkRoles = (accessControl: IAccess[]): RoleChecks => {
    return {
        has: (role: RoleName): boolean => {
            const result = accessControl.some(current => current.permissionname === role)
            return result;
        },
        hasAll: (roles: RoleName[]): boolean => {
            const result = accessControl.every(current => roles.includes(current.permissionname as RoleName));
            return result;
        },
        hasAny: (roles: RoleName[]): boolean => {
            const result = accessControl.some(current => roles.includes(current.permissionname as RoleName));
            return result;
        }
    };
};

export type { RoleChecks };
export default checkRoles;