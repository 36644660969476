import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { formatBytes } from "../../../lib/Utils";
import { ThemedProps, isDark } from "./Attachment.types";

const useStyles = makeStyles(theme => createStyles({
    root: {
        alignItems: "baseline",
        display: "flex",
        flexDirection: "column",
    },
    name: {
        display: "inline",
        color: (props: ThemedProps): string =>
            isDark(props.theme) ? theme.palette.secondary.main : theme.palette.text.primary,
        marginRight: theme.spacing(3),
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        whiteSpace: "nowrap",
    },
    size: {
        color: (props: ThemedProps): string =>
            isDark(props.theme) ? theme.palette.grey[200] : theme.palette.grey[600],
        fontStyle: "italic",
    },
}));

interface AttachmentNameProps {
    readonly name: string;
    readonly size: number;
}

const AttachmentName = (props: ThemedProps<AttachmentNameProps>): JSX.Element => {
    const styles = useStyles(props);
    return (
        <div className={styles.root}>
            <Typography variant="body2" className={styles.name}>{props.name}</Typography>
            <Typography variant="caption" className={styles.size}>{formatBytes(props.size)}</Typography>
        </div>
    );
};

export type { AttachmentNameProps };
export default AttachmentName;