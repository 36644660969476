import executeQuery from "../../../../../lib/executeQuery";

import { NodeID, encodeNodeId } from "../../../../../lib/nodeIdentifier";
import { IQueryControl, IConnection } from "../../../../../store/types";
import { WaterSourceHistoryItem } from "./types";

const waterSourceHistoryQuery = `query waterSourceControl_Query($id: ID!, $historyQueryControl: QueryControlInput) {
    node(id:$id) {
        ... on IWaterSource {
            waterSourceNodeId: id
            waterSourceId: externalId
            history(queryControl:$historyQueryControl) {
                totalCount
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    isFirstPage
                    isLastPage
                    pageCount
                    pageNumber
                    pageSize
                    sortBy
                    sortDirection
                }
                items {
                    objectNodeId
                    objectId
                    objectCategory
                    objectType
                    dateCreated
                    dateClosed
                    activity
                    outcome
                    cancelReason
                }
            }
        }
    }
}`;

export const queryWaterSourceHistory = async (id: NodeID, historyQueryControl: IQueryControl): Promise<IConnection<WaterSourceHistoryItem>> => {
    const variables = { id: encodeNodeId(id), historyQueryControl }
    const response = await executeQuery<{ node: { history: IConnection<WaterSourceHistoryItem> } }>(waterSourceHistoryQuery, variables);
    return response
        ? response.node.history
        : Promise.reject(new Error("Missing query response."));
};
