export default `mutation cancelDefect_Mutation($defect: EditCancelDefectInput!) {
    cancelDefect(input:$defect) {
      clientMutationId
      defect {
        defectNodeId: id
        defectId
        type {
            displayText
            value
        }
        cancelReason {
            displayText
            value
        }
        dateReported
        reportedBy
        isOpen
        isResolved
        lastEvent {
            timestamp
            action
            actor
        }
        parentInspection {
            id
        }
        waterSource {
            waterSourceNodeId: id
            isDefective {
                value
                isOverridden
            }
            isOperable {
                value
                isOverridden
            }
        }
        events {
            timestamp
            action
            actor
        }
    }
      hasErrors
      errors {
          message
          path
      }
    }
  }`;