import React from 'react';
import { getUser } from '../../lib/getUser';
import { ICustomerSubscriptionUser } from '../../store/types/app';
import { UserSubscription } from '@3tc/shared-components';

interface IUserSubscriptionsPage {
    userSubscriptions: ICustomerSubscriptionUser[];
}

const UserSubscriptionsPage = ({ userSubscriptions }: IUserSubscriptionsPage): JSX.Element => <UserSubscription userSubscriptions={userSubscriptions} nextFunction={getUser} />;

export default UserSubscriptionsPage;