import { createStore, combineReducers, applyMiddleware, compose, Store, AnyAction } from 'redux';
import thunk from 'redux-thunk';

import defectsReducer from './reducers/defects';
import deviceReducer from "./reducers/device";
import inspectionsReducer from './reducers/inspections';
import waterSourcesReducer from './reducers/waterSources';
import mapReducer from './reducers/map';
import appReducer from './reducers/app';
import featuresReducer from "../store/reducers/features";
import fileStoreReducer from "../store/reducers/fileStore";
import repairsReducer from './reducers/repairs';
import schemesReducer from './reducers/schemes';
import organisationsReducer from './reducers/organisations';
import mobileReducer from "./reducers/mobile";

import { IReduxState } from './types/store';

declare global {
    interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any }
}

const enableDevTools = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const composeEnhancers = enableDevTools
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const configureStore = (): Store<IReduxState, AnyAction> & { dispatch: unknown } => {
    return createStore(
        combineReducers<IReduxState>({
            app: appReducer,
            device: deviceReducer,
            waterSources: waterSourcesReducer,
            map: mapReducer,
            defects: defectsReducer,
            fileStore: fileStoreReducer,
            inspections: inspectionsReducer,
            repairs: repairsReducer,
            schemes: schemesReducer,
            mobile: mobileReducer,
            features: featuresReducer,
            organisations: organisationsReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
};

export default configureStore;
