import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import executeQuery from '../../lib/executeQuery';

import { setGlobalToast, setLoadingToast, setSuccessToast } from './app';
import { setError } from './errors';
import { setReviewedDefects } from './defects';
import { setSelectedWaterSourceLastInspectionDate } from './waterSources';

import assignInspectorMutation from "./graphQL/assignInspector";
import assignInspectionGroupMutation from "./graphQL/assignInspectionGroup";
import cancelInspectionMutation from './graphQL/cancelInspection';
import createInspectionsMutation from './graphQL/createInspections';
import editInspectionMutation from './graphQL/editInspection';
import completeInspectionQuery from './graphQL/completeInspection';
import getInspectionQuery from './graphQL/getInspection';
import getInspectionsForWaterSourceQuery from './graphQL/getInspectionsForWaterSource';
import removeInspectionGroupMutation from "./graphQL/removeInspectionGroup";
import removeInspectorMutation from "./graphQL/removeInspector";

import {
    IGlobalToast,
    IInspectionAction,
    IInspection,
    IAddInspectionVariables,
    IUpdateInspectionVariables,
    ICompleteInspectionVariables,
    ICancelInspectionVariables,
    IAssignInspectorInput,
    IAssignInspectionGroupInput,
    IRemoveInspectorInput,
    IRemoveInspectionGroupInput,
    IConnection,
    IPageInfo
} from '../types';

export const clearInspections = (): IInspectionAction => ({
    type: 'CLEAR_INSPECTIONS'
})

export const editInspection = (editedInspection: IInspection): IInspectionAction => ({
    type: 'EDIT_INSPECTION',
    editedInspection
})

export const addInspectionPage = (page: IConnection<IInspection>): IInspectionAction => ({
    type: "ADD_INSPECTION_PAGE",
    inspectionPage: page
});

export const addInspections = (newInspections: IInspection[]): IInspectionAction => ({
    type: 'ADD_INSPECTIONS',
    newInspections
});

export const addPaginatedInspections = (inspections: IInspection[], inspectionsPageInfo: IPageInfo, totalCount: number): IInspectionAction => ({
    type: 'ADD_PAGINATED_INSPECTIONS',
    inspections,
    inspectionsPageInfo,
    inspectionsTotalCount: totalCount
})

export const setAssignInspectorSuccess = (assignInspectorSuccess?: boolean): IInspectionAction => ({
    type: "SET_ASSIGN_INSPECTOR_SUCCESS",
    assignInspectorSuccess
})

export const setAssignInspectionGroupSuccess = (assignInspectionGroupSuccess?: boolean): IInspectionAction => ({
    type: "SET_ASSIGN_INSPECTION_GROUP_SUCCESS",
    assignInspectionGroupSuccess
})

export const setCreateInspectionSuccess = (createInspectionSuccess?: boolean): IInspectionAction => ({
    type: 'SET_CREATE_INSPECTION_SUCCESS',
    createInspectionSuccess
})

export const setEditInspectionSuccess = (editInspectionSuccess?: boolean): IInspectionAction => ({
    type: 'SET_EDIT_INSPECTION_SUCCESS',
    editInspectionSuccess
})

export const setInspections = (inspections: IInspection[]): IInspectionAction => ({
    type: 'SET_INSPECTIONS',
    inspections
})

export const setRemoveInspectionGroupSuccess = (removeInspectionGroupSuccess?: boolean): IInspectionAction => ({
    type: "SET_REMOVE_INSPECTION_GROUP_SUCCESS",
    removeInspectionGroupSuccess
})

export const setRemoveInspectorSuccess = (removeInspectorSuccess?: boolean): IInspectionAction => ({
    type: "SET_REMOVE_INSPECTOR_SUCCESS",
    removeInspectorSuccess
})

export const setSelectedInspection = (selectedInspection?: IInspection): IInspectionAction => ({
    type: 'SET_SELECTED_INSPECTION',
    selectedInspection
})

export const setRefreshInspection = (refresh: boolean): IInspectionAction => ({
    type: "SET_REFRESH_INSPECTION",
    refresh
});

export const getInspection = (variables: { id: string }, setLoading = true) =>
    async (dispatch: Function): Promise<void> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast('Loading inspection data...'));
            }

            const data = await executeQuery<{ node: IInspection | undefined }>(getInspectionQuery, variables);
            dispatch(setSelectedInspection(data?.node));

            if (setLoading) {
                dispatch(setGlobalToast());
            }
        } catch (err) {
            dispatch(setError('Error loading inspection', err));
        }
    }

export const getInspectionsForWaterSource = (variables?: { id: string }, setLoading = true) =>
    async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<IInspection[]> => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast("Getting inspections for water source..."));
            }

            const response = await executeQuery<{ node: { inspections: IInspection[] } }>(getInspectionsForWaterSourceQuery, variables);
            const inspections = response?.node.inspections ?? [];

            dispatch(setInspections(inspections));
            dispatch(setCreateInspectionSuccess(false));

            if (setLoading) {
                dispatch(setGlobalToast());
            }

            return inspections;
        } catch (err) {
            dispatch(setError("Error getting inspections for water source", err));
            return [];
        }
    };

export const startAssignInspector = (input: IAssignInspectorInput) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast("Assigning inspector to inspection..."));
            const response = await executeQuery<{ inspection: { assignInspector: { inspection: IInspection } } }>(assignInspectorMutation, input);
            const { inspection } = response?.inspection.assignInspector ?? {};
            if (inspection) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Inspector assigned to inspection ${inspection.inspectionId}`,
                    showToast: true,
                    timeout: 5000
                };
                dispatch(editInspection(inspection));
                dispatch(setSelectedInspection(inspection));
                dispatch(setAssignInspectorSuccess(true))
                dispatch(setGlobalToast(globalToast));
            }
        }
        catch (err) {
            dispatch(setError("Error assigning inspector", err));
        }
    }

export const startRemoveInspector = (input: IRemoveInspectorInput) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast("Removing inspector from inspection..."));
            const response = await executeQuery<{ inspection: { removeInspector: { inspection: IInspection } } }>(removeInspectorMutation, input);
            const { inspection } = response?.inspection.removeInspector ?? {};
            if (inspection) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Inspector removed from inspection ${inspection.inspectionId}`,
                    showToast: true,
                    timeout: 5000
                };
                dispatch(editInspection(inspection));
                dispatch(setSelectedInspection(inspection));
                dispatch(setRemoveInspectorSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        }
        catch (err) {
            dispatch(setError("Error removing inspector", err));
        }
    }

export const startAssignInspectionGroup = (input: IAssignInspectionGroupInput) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast("Assigning inspection group to inspection..."));
            const response = await executeQuery<{ inspection: { assignInspectionGroup: { inspection: IInspection } } }>(assignInspectionGroupMutation, input);
            const { inspection } = response?.inspection.assignInspectionGroup ?? {};
            if (inspection) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Inspection group assigned to inspection ${inspection.inspectionId}`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(editInspection(inspection));
                dispatch(setSelectedInspection(inspection));
                dispatch(setAssignInspectionGroupSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        }
        catch (err) {
            dispatch(setError("Error assigning inspection group", err));
        }
    }

export const startRemoveInspectionGroup = (input: IRemoveInspectionGroupInput) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast("Removing inspection group from inspection..."));
            const response = await executeQuery<{ inspection: { removeInspectionGroup: { inspection: IInspection } } }>(removeInspectionGroupMutation, input);
            const { inspection } = response?.inspection.removeInspectionGroup ?? {};
            if (inspection) {
                const globalToast: IGlobalToast = {
                    type: "SUCCESS",
                    message: `Inspection group removed from inspection ${inspection.inspectionId}`,
                    showToast: true,
                    timeout: 5000
                };
                dispatch(editInspection(inspection));
                dispatch(setSelectedInspection(inspection));
                dispatch(setRemoveInspectionGroupSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        }
        catch (err) {
            dispatch(setError("Error removing inspection group", err));
        }
    }

export const startCancelInspection = (variables: ICancelInspectionVariables) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast('Cancelling inspection...'));
            const res = await executeQuery<{ inspection: { cancel: { inspection: IInspection } } }>(cancelInspectionMutation, variables);
            const { inspection } = res?.inspection.cancel ?? {};
            if (inspection) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Changes saved to inspection ${inspection.inspectionId}`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(editInspection(inspection));
                dispatch(setSelectedInspection(inspection));
                dispatch(setEditInspectionSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        } catch (err) {
            dispatch(setError('Error cancelling inspection', err))
        }
    }

export const startCompleteInspection = (variables: ICompleteInspectionVariables) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to inspection'));
            const res = await executeQuery<{ inspection: { complete: { inspection: IInspection } } }>(completeInspectionQuery, variables);
            const { inspection } = res?.inspection.complete ?? {};
            if (inspection) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Changes saved to inspection ${inspection.inspectionId}`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(editInspection(inspection));
                dispatch(setSelectedWaterSourceLastInspectionDate(inspection.waterSource.lastInspectionDate))
                dispatch(setSelectedInspection(inspection));
                dispatch(setReviewedDefects([], []));
                dispatch(setEditInspectionSuccess(true));
                dispatch(setGlobalToast(globalToast));
                dispatch(setRefreshInspection(true));
            }
        } catch (err) {
            dispatch(setError('Error completing inspection', err))
        }
    }

export const startCreateInspection = (variables: IAddInspectionVariables) =>
    async (dispatch: Function): Promise<void> => {
        try {
            dispatch(setLoadingToast('Creating inspections...'));
            const res = await executeQuery<{ waterSource: { createInspections: { inspections: IInspection[] } } }>(createInspectionsMutation, variables);
            const { inspections } = res?.waterSource.createInspections ?? {};
            if (inspections) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `${inspections.length} inspection${inspections.length === 1 ? '' : 's'} created`,
                    showToast: true,
                    timeout: 5000
                }

                dispatch(addInspections(inspections));
                dispatch(setCreateInspectionSuccess(true));
                dispatch(setGlobalToast(globalToast));
            }
        } catch (err) {
            dispatch(setError('Error creating inspection', err))
        }
    }

export const startEditInspection = (variables: IUpdateInspectionVariables) =>
    async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Saving changes to inspection...'));
            const res = await executeQuery<{ inspection: { update: { inspection: IInspection } } }>(editInspectionMutation, variables);
            const { inspection } = res?.inspection.update ?? {};
            if (inspection) {
                dispatch(editInspection(inspection));
                dispatch(setSelectedInspection(inspection));
                dispatch(setEditInspectionSuccess(true));
                dispatch(setSuccessToast(`Changes saved to inspection ${inspection.inspectionId}`, 5000));
            }
        } catch (err) {
            dispatch(setError('Error editing inspection', err))
        }
    }
