import React, { useCallback, useState } from "react";

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AddIcon from '@material-ui/icons/Add';

import AddressDialog from "../AddressDialog";
import { AddressDetailsData } from "../../../../../address";

interface AddAddressProps {
    readonly active?: boolean;
    readonly onConfirm?: (address: AddressDetailsData) => void;
}

const useStyles = makeStyles(theme => createStyles({
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

const AddAddress = ({ active, onConfirm }: AddAddressProps): JSX.Element => {
    const styles = useStyles();
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleActionClick = (): void => {
        setDialogOpen(true);
    };
    const handleCloseDialog = (): void => {
        setDialogOpen(false);
    };

    const handleConfirm = useCallback((address: AddressDetailsData): void => {
        setDialogOpen(false);
        onConfirm?.(address);
    }, [onConfirm])

    const bindHandleConfirm = useCallback((address: AddressDetailsData | undefined): React.MouseEventHandler<HTMLButtonElement> | undefined => {
        return address
            ? (): void => handleConfirm(address)
            : (): void => { return; };
    }, [onConfirm]);

    const transitionDelay = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen
    };

    const renderActions = useCallback((enabled?: boolean, getAddress?: () => AddressDetailsData) => {
        const address = getAddress?.();
        return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Button size="small" variant="contained" color="secondary" disabled={!enabled} onClick={bindHandleConfirm(address)}>Add & Select</Button>
            <Typography variant="caption" color="textSecondary">Add the address record, and then select it as the address for the water source.</Typography>
        </Box>
    )}, [bindHandleConfirm]);

    return (
        <React.Fragment>
            <Zoom in={active} timeout={transitionDelay} style={{ transitionDelay: `${active ? transitionDelay.exit : 0}ms` }}>
                <Fab className={styles.fab} color="secondary" onClick={handleActionClick}>
                    <AddIcon />
                </Fab>
            </Zoom>
            <AddressDialog
                title="Add"
                open={dialogOpen}
                initialAddress={{}}
                renderActions={renderActions}
                onCancel={handleCloseDialog}
            />
        </React.Fragment>
    );
};

export type { AddAddressProps };
export default AddAddress;