import { useState, useEffect, useCallback } from "react";

import { IDefectSummary } from "../../components/inspectionView";
import { NodeID, encodeNodeId } from "../../lib/nodeIdentifier";
import getOpenDefects from "../getOpenDefects";
import { FetchStatus, FetchCollection } from "./types";
import { bindIfMounted } from "./utils";

const useOpenDefects = (inspectionId: NodeID | undefined): FetchCollection<IDefectSummary> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [defects, setDefects] = useState<IDefectSummary[]>([]);

    const nodeId = inspectionId ? encodeNodeId(inspectionId) : undefined;
    const fetch = useCallback(() => {
        let isMounted = true;
        const ifMounted = bindIfMounted(isMounted);

        setStatus("loading");
        if (nodeId) {
            getOpenDefects(nodeId)
                .then(result => ifMounted(() => setDefects(result)))
                .catch(() => ifMounted(() => setDefects([])))
                .finally(() => ifMounted(() => setStatus("complete")));
        }
        else {
            ifMounted(() => setDefects([]));
            ifMounted(() => setStatus("complete"));
        }

        return (): void => {
            isMounted = false;
        };
    }, [nodeId]);

    useEffect(() => {
        fetch();
    }, [fetch])

    return [status, defects, fetch];
};

export default useOpenDefects;