import { Comparer } from "./comparers";

interface Groups<T> {
    readonly [label: string]: T[];
}

interface GroupBy<T> {
    (item: T): string;
}

interface GroupItem<T> {
    readonly label: string;
    readonly items: T[];
}

const bindGroupBy = <T>(groupBy: GroupBy<T>, orderBy: Comparer<T> | undefined) => {
    return (groups: Groups<T>, currentItem: T): Groups<T> => {
        const label = groupBy?.(currentItem) ?? "";
        const currentGroup = groups[label] ?? [];
        const updatedGroup = [...currentGroup, currentItem];
        const sortedGroup = orderBy ? updatedGroup.sort(orderBy) : updatedGroup;
        return {
            ...groups,
            [label]: [...sortedGroup]
        }
    };
};

const group = <T>(items: T[], groupBy: GroupBy<T>, orderBy?: Comparer<T>): Groups<T> => {
    const makeGroups = bindGroupBy(groupBy, orderBy);
    const groups = items.reduce(makeGroups, {});
    return groups;
};

const toGroupItems = <T>(groups: Groups<T>): GroupItem<T>[] => {
    const items = Object
        .entries(groups)
        .map<GroupItem<T>>(([label, items]) => ({ label, items }));
    return items;
};

interface HashFunction<T> {
    (collection: T[]): string;
}

const makeHash = <T>(f: (item: T) => string): HashFunction<T> => {
    return (collection): string => {
        if (collection !== undefined && collection.length > 0) {
            const x = collection.map(item => item ? f(item) : "").join("");
            const y = btoa(x);
            return y;
        }
        return "";
    };
};

export type { GroupBy, Groups, GroupItem };
export { group, toGroupItems, makeHash };