export default `
fragment WaterSourceFragment on IWaterSource {
    waterSourceNodeId: id
    waterSourceId: externalId
    category {
        value
        displayText
    }
    classification {
        value
        displayText
    }
    location {
        coordinates {
            x
            y
        }
    }
    isActive
    isOperable {
        value
        isOverridden
    }
    isDefective {
        value
        isOverridden
    }
    status {
        value
        displayText
    }
    riskSeverity {
        displayText
        value
    }
    hazardSeverity {
        displayText
        value
    }
    lastInspectionDate
    nextInspectionDate
    recommendedInspectionDate
    additionalInfo
    routeSortOrder
    inspectionCount(includeClosed:false)
    ... on ITagged {
        tags {
            value
            displayText
            enabled
        }
    }
}
mutation updateWaterSourceLocation($input: EditEmergencyWaterSupplyInput!) {
    waterSource {
        update {
            emergencyWaterSupply(input: $input) {
                clientMutationId
                waterSource {
                    ...WaterSourceFragment
                }
                hasErrors
                errors {
                    path
                    message
                }
            }
        }
    }
}`;