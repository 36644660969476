import { formatYesNo } from "../components/filters/utils";
import { shortDateFormatter } from "./Utils";
import { isBoolean, isLocalDate, isObject, isUndefined, isLocalDateTime } from "./validation";

const empty = "";

const resolveObject = (obj: Record<string, unknown>): unknown => {
    if (isLocalDate(obj)) {
        return obj.format(shortDateFormatter);
    }
    if (isLocalDateTime(obj)) {
        return obj.format(shortDateFormatter);
    }
    if ("displayText" in obj) {
        return obj.displayText;
    }
    if ("waterSourceId" in obj) {
        return obj.waterSourceId;
    }
    if ("value" in obj) {
        return obj.value;
    }
    return obj[0];
};

const resolveValue = (item: unknown): unknown => {
    if (isUndefined(item)) {
        return empty;
    }
    if (isBoolean(item)) {
        return formatYesNo(item);
    }
    return isObject(item)
        ? resolveObject(item)
        : item;
};

const appendValue = (line: string, val: unknown, index: number, length: number): string =>
    `${line}"${val ?? ""}"${index !== length - 1 ? "," : empty}`;

const formatHeader = (keys: string[], seed: string): string =>
    keys.reduce((acc, val, idx) => appendValue(acc, val, idx, keys.length), seed);

const formatRow = <R = Record<string, unknown>>(row: R, length: number): string =>
    Object.keys(row).reduce((acc, val, idx) => {
        const item = resolveValue(row[val]);
        return appendValue(acc, item, idx, length);
    }, empty);

export const jsonToCsv = <R = Record<string, unknown>>(json: R[]): string => {
    if (json.length === 0) {
        return empty;
    }

    const keys = Object.keys(json[0])
    const header = formatHeader(keys, empty);
    const csv = json.reduce((acc, val) => {
        const line = formatRow(val, keys.length);
        return `${acc}\n${line}`;
    }, header);
    return csv;
};

export const downloadCsvFile = (csvData: string): void => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const filename = 'export.csv';
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};