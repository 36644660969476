import { useEffect, useCallback, useReducer } from "react";
import { useDispatch } from "react-redux";

import { FetchStatus } from "../../../api/hooks";
import { NodeResponse } from "../../../api/types";
import executeQuery from "../../../lib/executeQuery";
import { NodeID, encodeNodeId } from "../../../lib/nodeIdentifier";
import { setLoadingToast, setGlobalToast } from "../../../store/actions/app";
import { setError } from "../../../store/actions/errors";

interface RefetchEntity {
    (): Promise<void>;
}
type FetchEntityResponse<T> = [FetchStatus, T | undefined, RefetchEntity];
interface FetchEntity<T> {
    (id: NodeID | undefined): FetchEntityResponse<T>;
}

interface FetchState<T> {
    readonly status: FetchStatus;
    readonly entity: T | undefined;
}

interface FetchAction<T> {
    readonly type: "READY" | "LOADING" | "COMPLETE";
    readonly entity?: T;
}

const reducer = <T>(state: FetchState<T>, action: FetchAction<T>): FetchState<T> => {
    switch (action.type) {
        case "READY":
            return { status: "ready", entity: undefined };
        case "LOADING":
            return { ...state, status: "loading" };
        case "COMPLETE":
            return { status: "complete", entity: action.entity };
    }
};

const useGetEntity = <T>(displayText: string, query: string): FetchEntity<T> => {
    const getNode = async (id: string): Promise<T | undefined> =>
        executeQuery<NodeResponse<T>>(query, { id })
            .then(response => response?.node);

    return (id): FetchEntityResponse<T> => {
        const dispatch = useDispatch();
        const [state, setState] = useReducer<React.Reducer<FetchState<T>, FetchAction<T>>>(reducer, { status: "ready" } as FetchState<T>);

        const nodeId = id ? encodeNodeId(id) : undefined;
        const fetch = useCallback(async (): Promise<void> => {
            if (nodeId) {
                dispatch(setLoadingToast(`Loading ${displayText}...`));
                setState({ type: "LOADING" });
                return getNode(nodeId)
                    .then(entity => setState({ type: "COMPLETE", entity }))
                    .then(() => {
                        dispatch(setGlobalToast());
                    })
                    .catch(error => {
                        setState({ type: "COMPLETE" });
                        dispatch(setError(`Error loading ${displayText}`, error));
                    });
            }
            else {
                setState({ type: "READY" });
            }
        }, [nodeId]);

        useEffect(() => {
            void fetch();
        }, [fetch]);

        const { status, entity } = state;
        return [status, entity, fetch];
    };
};

export type { FetchEntity, FetchEntityResponse, FetchStatus, RefetchEntity }
export default useGetEntity;