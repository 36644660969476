import React, { useState, useEffect } from "react";
import { Input, LoadingBar } from "@3tc/shared-components";

import { ILookups, IConnection, ISavedSearch, IQueryControl } from "../../../store/types";

import { formatText, longDateFormatter } from "../../../lib/Utils";
import ActionButtons from "../actionButtons";
import downloadCsv from "../api/export/downloadWaterSources";
import Filter from "../filter";
import FilterResultsTable from "../filterResultsTable";
import SearchSelectorContext from "../searchSelector/searchSelectorContext";
import { formatYesNo } from "../utils";
import { queryInspectionsByFilter } from "./query";
import { InspectionSearchFilters, InspectionSelectedFilters, InspectionSearchItem } from "./types";
import { buildQueryFilter, buildInspectionFilters } from "./utils";
import styles from "./inspectionSearch.module.scss";
import { ITableHeader } from "../types";
import { useTablePaging } from "../hooks";

interface IInspectionSearchProps {
    readonly lookups: ILookups;
    readonly hideToast: () => void;
    readonly showError: (message: string, error?: Error) => void;
    readonly showLoading: (message: string) => void;
    readonly showInspectionOnMap: (inspection: InspectionSearchItem) => void;
    readonly showSuccess: (message: string) => void;
}

const tableHeaders: ITableHeader[] = [
    {
        displayText: "Id",
        sortBy: "id"
    },
    {
        displayText: "Date",
        sortBy: "inspectionDate"
    },
    {
        displayText: "Type",
        sortBy: "type"
    },
    {
        displayText: "Water Source",
        sortBy: "waterSource"
    },
    {
        displayText: "Open",
        sortBy: "isOpen"
    },
    {
        displayText: "Inspection Group",
        sortBy: "inspectionGroup"
    },
    {
        displayText: "Inspector",
        sortBy: "technician"
    }
];
const tableRows = (inspections: InspectionSearchItem[]): string[][] => {
    return inspections.map(inspection => {
        return [
            inspection.inspectionId.toString(),
            inspection.inspectionDate?.format(longDateFormatter) ?? "",
            inspection.type.displayText,
            inspection.waterSource.waterSourceId.toString(),
            formatYesNo(inspection.isOpen),
            inspection.inspectionGroup?.name ?? "",
            inspection.technician?.displayName ?? inspection.crew?.callSign ?? ""
        ];
    });
};

const empty: IConnection<InspectionSearchItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "id",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "id",
    sortDirection: "ASCENDING"
};

const queryInspections = async (selectedFilters: InspectionSelectedFilters, paging: IQueryControl): Promise<IConnection<InspectionSearchItem>> => {
    const filter = buildQueryFilter(selectedFilters);
    return queryInspectionsByFilter(filter, paging);
};

const InspectionSearch = (props: IInspectionSearchProps): JSX.Element => {
    const { lookups, hideToast, showError, showInspectionOnMap, showLoading } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<InspectionSelectedFilters>({});
    const [page, setPage] = useState<IConnection<InspectionSearchItem>>(empty);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            showLoading("Getting inspections...");
            queryInspections(selectedFilters, paging)
                .then(inspections => setPage(inspections))
                .catch(() => showError("Error searching for inspections."))
                .finally(() => {
                    setIsLoading(false);
                    hideToast();
                });
        }
    }, [paging]);

    const handleFilterChange = (value: string): void => {
        if (Object.keys(selectedFilters).indexOf(value) > -1) {
            const update = { ...selectedFilters };
            delete update[value];
            setSelectedFilters(update);
            return;
        }
        setSelectedFilters({ ...selectedFilters, [value]: [] });
    };

    const handleClearClick = (): void => {
        setIsLoading(true);
        setSelectedFilters({});
        setPage(empty);
        setIsLoading(false);
    };

    const handleExportClick = (): void => {
        showLoading("Downloading CSV.");
        const filter = buildQueryFilter(selectedFilters);
        downloadCsv("inspections", filter)
            .catch(error => showError("Error exporting inspections to CSV.", error))
            .finally(hideToast);
    };

    const handleRowClick = (id: string): void => {
        const inspection = items.find(item => item.inspectionId.toString() === id);
        if (inspection) {
            showInspectionOnMap(inspection);
        }
    };

    const handleChangeSelection = (selected: ISavedSearch | undefined): void => {
        setSelectedSearch(selected);
    };

    useEffect(() => {
        if (selectedSearch) {
            setSelectedFilters(selectedSearch.queryFilter);
        }
    }, [selectedSearch, setSelectedFilters]);

    const inspectionFilters: InspectionSearchFilters = buildInspectionFilters(lookups);

    return (
        <SearchSelectorContext entity="inspections" selectedFilters={selectedFilters} onChangeSelection={handleChangeSelection}>
            <div className={styles.allInputs}>
                <div className={styles.inputContainer}>
                    <Input
                        type="multilist"
                        id="selected-filters-list"
                        labelText="Search On"
                        isEditing
                        value={Object.keys(selectedFilters)}
                        responseOptions={Object.keys(inspectionFilters).map((item: string) => ({
                            value: item,
                            text: formatText(item, true, undefined, true)
                        }))}
                        onChangeHandler={handleFilterChange}
                    />
                </div>
                {Object.keys(selectedFilters).map(filter => (
                    <Filter
                        key={filter}
                        name={filter}
                        options={inspectionFilters[filter]}
                        selected={selectedFilters}
                        onChangeSelection={setSelectedFilters}
                    />
                ))}
            </div>
            <ActionButtons
                totalCount={totalCount}
                onClear={handleClearClick}
                onExport={handleExportClick}
                onSearch={handleSearchClick}
            />
            {isLoading
                ? <LoadingBar loading={isLoading} />
                : <FilterResultsTable
                    headers={tableHeaders}
                    items={tableRows(items)}
                    pageInfo={pageInfo}
                    totalCount={totalCount}
                    onFirstClick={handleFirstClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onLastClick={handleLastClick}
                    onTableHeaderClick={handleTableHeaderClick}
                    onTableRowClick={handleRowClick}
                />
            }
        </SearchSelectorContext>
    );
};

export type { IInspectionSearchProps };
export default InspectionSearch;