export default `
    mutation createRoute_Mutation($route: AddRouteInput!) {
        createRoute(input: $route) {
            route {
                routeNodeId: id
                routeId
                name
                isActive
                station {
                    value
                    displayText
                }
                events {
                    actor
                    action
                    timestamp
                }
                lastEvent {
                    actor
                    action
                    timestamp
                }
                waterSources {
                    waterSourceNodeId: id
                    waterSourceId: externalId
                    isActive
                    nextInspectionDate
                    recommendedInspectionDate
                    isDefective {
                        value
                        isOverridden
                      }
                    isOperable {
                        value
                        isOverridden
                      }
                    location {
                        coordinates {
                            x
                            y
                        }
                    }
                    status {
                        value
                    }
                    riskSeverity {
                        value
                        displayText
                    }
                }
                notes {
                    noteNodeId: id
                    noteId
                    timestamp
                    author
                    content
                }
            }
            hasErrors
            errors {
                message
                path
            }
        }
    }
`