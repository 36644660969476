import React from "react";

import { Owner, ILookupItem, IHazard } from "../../../../../store/types";
import Field from "../../../../card/components/field";
import { formatMultiLineText, formatText } from "../../../utils";

type ReadOnlyProps = Pick<Owner,
    "controlMeasures" |
    "hazardSeverity" |
    "hazards" |
    "isSecondPersonRequired" |
    "roadSpeed" |
    "hazardInformation" |
    "hazardFlag"
>;

const formatHazards = (hazards: IHazard[] | undefined = []): string =>
    hazards.length === 0
        ? "-"
        : hazards.map(hazard => hazard.hazardType.displayText).join(", ");

const formatIsSecondPersonRequired = (isSecondPersonRequired?: boolean): string =>
    isSecondPersonRequired
        ? "Yes"
        : "No"

const formatControlMeasures = (controlMeasures?: ILookupItem[]): string => {
    if (controlMeasures?.length) {
        return controlMeasures
            .sort((a, b) => {
                if (a.enabled && !b.enabled) {
                    return -1;
                }
                if (!a.enabled && b.enabled) {
                    return 1;
                }
                return a.sortOrder - b.sortOrder;
            })
            .map(controlMeasure => controlMeasure.enabled ? controlMeasure.displayText : controlMeasure.displayText.toLowerCase())
            .join(", ");
    }
    return "-";
};

const ReadOnly = (props: ReadOnlyProps): JSX.Element => {
    const { controlMeasures, hazardSeverity, hazards, isSecondPersonRequired, roadSpeed, hazardInformation, hazardFlag } = props;

    return (
        <React.Fragment>
            <Field label="Hazard Severity" text={formatText(hazardSeverity?.displayText)} />
            <Field label="Hazards" text={formatHazards(hazards)} />
            <Field label="Control Measures" text={formatControlMeasures(controlMeasures)} />
            <Field label="Second Technician Required" text={formatIsSecondPersonRequired(isSecondPersonRequired)} />
            <Field label="Road Speed" text={formatText(roadSpeed?.displayText)} />
            <Field label="Hazard Information" text={formatMultiLineText(hazardInformation)} />
            <Field label="Hazard Flag" text={formatText(hazardFlag?.displayText)} />
        </React.Fragment>
    );
};

export type { ReadOnlyProps };
export default ReadOnly;