import { ILookups } from "../../../store/types";
import { buildListFilterObject } from "../filter/utils";
import {
    buildLookupFilterItems,
    buildYesNoFilterItems,
    formatFilterDate,
    formatFilterText,
    resolveBoolean,
    resolveRange,
    resolveMatchString
} from "../utils";
import { IDefectSelectedFilters, IDefectSearchFilters } from "./types";

export const buildDefectFilters = (lookups: ILookups): IDefectSearchFilters => ({
    id: "TEXTRANGE",
    waterSource: "TEXT",
    parentInspection: "TEXTRANGE",
    type: buildLookupFilterItems(lookups.defectTypes),
    dateReported: "DATERANGE",
    reportedBy: "TEXT",
    isOperable: buildYesNoFilterItems(),
    isOpen: buildYesNoFilterItems(),
    isResolved: buildYesNoFilterItems(),
    isCancelled: buildYesNoFilterItems(),
    closeReason: buildLookupFilterItems(lookups.defectCloseReasons, undefined, true),
    cancelReason: buildLookupFilterItems(lookups.defectCancelReasons, undefined, true),
    description: "TEXT"
});

export const buildQueryFilter = (filters: IDefectSelectedFilters): Record<string, unknown> => {
    return {
        "id": resolveRange(filters.id, formatFilterText),
        "$all": [
            buildListFilterObject(filters.type, "type"),
            buildListFilterObject(filters.cancelReason, "cancelReason"),
            buildListFilterObject(filters.closeReason, "closeReason")
        ].filter(item => Object.keys(item).length),
        "waterSource": {
            "$like": resolveMatchString(filters.waterSource)
        },
        "inspection": resolveRange(filters.parentInspection, formatFilterText),
        "isOperable": {
            "$eq": resolveBoolean(filters.isOperable)
        },
        "dateReported": resolveRange(filters.dateReported, formatFilterDate),
        "reportedBy": {
            "$like": resolveMatchString(filters.reportedBy)
        },
        "description": {
            "$like": resolveMatchString(filters.description)
        },
        "isOpen": {
            "$eq": resolveBoolean(filters.isOpen)
        },
        "isCancelled": {
            "$eq": resolveBoolean(filters.isCancelled)
        },
        "isResolved": {
            "$eq": resolveBoolean(filters.isResolved)
        }
    };
};