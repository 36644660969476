import React from "react";

import { MultiSelectWithoutCheckboxesProps, PropsWithEditing } from "../types";
import LabelledField, { LabelledFieldProps } from "../../../labelledField";

const WaterSourceHazardList = ({ options, selectedOptions, editing, onChange, onClear }: PropsWithEditing<MultiSelectWithoutCheckboxesProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        id: "water-source-hazard-list",
        fieldType: "multiselect",
        label: "Hazards",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        options,
        selectedOptions,
        editing: editing ?? false,
        onChange: (value) => onChange?.(value),
        onClear
    };

    return <LabelledField {...props} />
};

export default WaterSourceHazardList;