import { useState, useCallback } from "react";

import { FetchStatus, FetchSingle } from "../../../api/hooks/types";
import useIsMounted from "../../../api/hooks/useIsMounted";
import useMountedEffect from "../../../api/hooks/useMountedEffect";
import executeQuery from "../../../lib/executeQuery";
import { AddressNode, IConnection, emptyPage, IConnectionQuery, FilteredObject, LikeFilter, IPageInfo } from "../../../store/types";
import searchQuery from "./addressSearchQuery";

const empty: IConnection<AddressNode> = {
    edges: [],
    items: [],
    pageInfo: emptyPage,
    totalCount: 0
};

interface AddressSearchFilter {
    readonly id?: number;
    readonly description?: string;
    readonly streetName?: string;
    readonly locality?: string;
    readonly town?: string;
    readonly postCode?: string;
    readonly coordinateX?: number;
    readonly coordinateY?: number;
}

type AddressQuery = IConnectionQuery<FilteredObject<AddressSearchFilter>>;

const getAddresses = async (variables: AddressQuery): Promise<IConnection<AddressNode>> => {
    const response = await executeQuery<{ addresses: IConnection<AddressNode> }>(searchQuery, variables);
    return response?.addresses ?? empty;
};

interface AddressSearchParams {
    readonly searchTerm: string;
    readonly pageIndex: number;
    readonly pageSize: number;
}

interface AddressSearchResult {
    readonly addresses: AddressNode[];
    readonly pageInfo: IPageInfo;
    readonly totalCount: number;
}

const emptyResult: AddressSearchResult = {
    addresses: [],
    pageInfo: emptyPage,
    totalCount: 0
};

const buildQuery = (parameters: AddressSearchParams): AddressQuery => {
    const { searchTerm, pageIndex, pageSize } = parameters;
    // attempt to match against any of the fields below; trailing wild-card match
    // database is case-insensitive
    const filterTerm: LikeFilter = {
        "$like": `${searchTerm}%`
    };
    const query: AddressQuery = {
        filter: {
            "$any": {
                description: filterTerm,
                street: filterTerm,
                locality: filterTerm,
                town: filterTerm,
                postCode: filterTerm,
            }
        },
        paging: {
            pageIndex,
            pageSize,
            sortBy: "@address",
            sortDirection: "ASCENDING"
        }
    };
    return query;
};

const useAddressSearch = (parameters: AddressSearchParams): FetchSingle<AddressSearchResult, AddressSearchParams> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [result, setResult] = useState<AddressSearchResult>(emptyResult);

    const [isMounted, ifMounted] = useIsMounted();

    const clearResults = useCallback((): void => {
        ifMounted(() => setResult(emptyResult));
    }, [isMounted]);

    const fetch = useCallback((parameters?: AddressSearchParams) => {
        ifMounted(() => setStatus("ready"));
        if (parameters?.searchTerm) {
            clearResults();
            setStatus("loading");
            const variables = buildQuery(parameters);
            getAddresses(variables)
                .then(page => {
                    ifMounted(() => setResult({
                        addresses: page.items,
                        pageInfo: page.pageInfo,
                        totalCount: page.totalCount
                    }));
                })
                .catch(() => {
                    ifMounted(() => clearResults());
                })
                .finally(() => {
                    ifMounted(() => setStatus("complete"));
                });
        }
    }, [isMounted]);

    const { searchTerm, pageIndex, pageSize } = parameters;
    useMountedEffect(ifMounted => {
        ifMounted(() => fetch({ searchTerm, pageIndex, pageSize }));
    }, [searchTerm, pageIndex, pageSize]);

    return [status, result, fetch, clearResults];
};

export type { AddressSearchParams, AddressSearchResult };
export default useAddressSearch;