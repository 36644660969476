import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";

import EventIcon from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/Room";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from '@material-ui/icons/Search';
import WorkIcon from "@material-ui/icons/Work";

import useAccount from "../../../auth/hooks/useAccount";
import AppDrawer, { MenuItem } from "../appDrawer";
import useNavigation from "../../../router/MobileRouter/hooks/useNavigation";

const MainMenu = (): JSX.Element => {
    const { goHome, gotoStations, gotoSearch, gotoNearestWaterSources, gotoUnplannedTasks } = useNavigation();
    const menu: MenuItem[] = [
        { label: "Home", icon: <HomeIcon />, onClick: goHome },
        { label: "My Work", icon: <WorkIcon />, onClick: (): void => gotoUnplannedTasks({ title: "My Work" }) },
        { label: "Routine", icon: <EventIcon />, onClick: gotoStations },
        { label: "Search", icon: <SearchIcon />, onClick: gotoSearch },
        { label: "Near Me", icon: <LocationOnIcon />, onClick: gotoNearestWaterSources }
    ];
    const account = useAccount();
    const [open, setOpen] = useState(false); 
    
    const closeMenu = (): void => setOpen(false);
    const openMenu = (): void => setOpen(true);

    const handleCloseClick = (): void => {
        closeMenu();
    };
    const handleMenuClick = (): void => {
        openMenu();
    };

    return (
        <React.Fragment>
            <IconButton edge="start" color="inherit" onClick={handleMenuClick}>
                <MenuIcon />
            </IconButton>
            <AppDrawer account={account} menuItems={menu} open={open} onClose={handleCloseClick} />
        </React.Fragment>
    );
};

export default MainMenu;