export default `
mutation Add_Tags($input:EditInt32CollectionInput!) {
    tags {
        add(input:$input) {
            clientMutationId
            hasErrors
            object {
                id
                __typename
                ... on ITagged {
                    tags {
                        value
                        displayText
                        enabled
                    }
                }
            }
        }
    }
}`