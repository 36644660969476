import React, { useReducer, useEffect, useState } from "react";

import { handleValidation } from "../../../lib/Utils";
import { ILookupItem } from "../../../store/types";
import Dialog from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import LabelledField, { LabelledFieldProps, SelectOption, asLookupItem } from "../../labelledField";
import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";

export interface IAssignInspectorsDialogProps {
    readonly assignInspectorSuccess?: boolean;
    readonly crews: ILookupItem[];
    readonly inspectionNodeId: string;
    readonly selectedCrew?: ILookupItem;
    readonly selectedTechnician?: ILookupItem;
    readonly technicians: ILookupItem[];
    readonly isOpen: boolean;
    readonly setAssignInspectorSuccess: () => void;
    readonly onClearError?: () => void;
    readonly onClose?: () => void;
    readonly onAssignInspector?: (inspectionId: NodeID, inspectorId: NodeID) => void;
    readonly onShowError?: (message: string) => void;
}

interface IAssignInspectorsDialogState {
    readonly crew: ILookupItem | undefined;
    readonly technician: ILookupItem | undefined;
}

const initialState: IAssignInspectorsDialogState = {
    crew: undefined,
    technician: undefined
}

const stateReducer = (state: IAssignInspectorsDialogState, action: { type: "SELECT_CREW" | "SELECT_TECH" | "RESET_CREW" | "RESET_TECH"; item?: ILookupItem }): IAssignInspectorsDialogState => {
    switch (action.type) {
        case "SELECT_CREW":
            return { crew: action.item, technician: undefined };
        case "SELECT_TECH":
            return { crew: undefined, technician: action.item };
        case "RESET_CREW":
            return { crew: undefined, technician: undefined };
        case "RESET_TECH":
            return { crew: undefined, technician: undefined };
    }
}

const formatData = (state: IAssignInspectorsDialogState): NodeID | undefined =>
    state.technician
        ? NodeID("Technician", state.technician.value)
        : state.crew
            ? NodeID("Crew", state.crew.value)
            : undefined;

const saveData = (props: IAssignInspectorsDialogProps, state: IAssignInspectorsDialogState, setShouldValidate: React.Dispatch<React.SetStateAction<boolean>>): void => {
    setShouldValidate(true);
    const inspectorId = formatData(state);
    if (inspectorId) {
        props.onClearError?.();
        props.onAssignInspector?.(parseNodeId(props.inspectionNodeId), inspectorId);
    }
    else {
        props.onShowError?.("Please select a Technician or a Crew.");
    }
};

const AssignInspectorsDialog = (props: IAssignInspectorsDialogProps): JSX.Element => {
    const [state, dispatch] = useReducer(stateReducer, initialState);
    const [shouldValidate, setShouldValidate] = useState(false);

    useEffect(() => {
        if (props.assignInspectorSuccess) {
            props.onClose?.();
            props.setAssignInspectorSuccess();
        }
        // eslint-disable-next-line 
    }, [props.assignInspectorSuccess]);

    const handleClose = (): void => props.onClose?.();
    const handleSave = (): void => saveData(props, state, setShouldValidate);
    const invalidClass: string = handleValidation(shouldValidate, state.technician) === 'invalid'
        && handleValidation(shouldValidate, state.crew) === 'invalid'
        ? 'invalid' : 'valid';

    const techProps: LabelledFieldProps = {
        fieldType: "select",
        id: "technicians",
        label: "Select a Technician",
        value: state.technician,
        selectOptions: props.technicians,
        editing: true,
        classes: { container: 'input__group', label: 'input__label', input: invalidClass },
        onClear: (): void => dispatch({ type: "RESET_TECH" }),
        onChange: (value: SelectOption | undefined) => dispatch({ type: "SELECT_TECH", item: asLookupItem(value) })
    };

    const crewProps: LabelledFieldProps = {
        fieldType: "select",
        id: "crews",
        label: "or a Crew",
        value: state.crew,
        selectOptions: props.crews,
        editing: true,
        classes: { container: 'input__group', label: 'input__label', input: invalidClass },
        onClear: () => dispatch({ type: "RESET_CREW" }),
        onChange: (value: SelectOption | undefined) => dispatch({ type: "SELECT_CREW", item: asLookupItem(value) })
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={props.isOpen} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Assign Inspector</DialogTitle>
            <DialogContent>
                <form>
                    <LabelledField {...techProps} />
                    <LabelledField {...crewProps} />
                </form>
            </DialogContent>
            <DialogActions>
                <button id="assignInspector-cancel" className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                <button id="assignInspector-save" className="action-button" onClick={handleSave}>Save</button>
            </DialogActions>
        </Dialog>
    );
}

export default AssignInspectorsDialog;