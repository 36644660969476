import olFeature from "ol/Feature";

import StyleCache from "../../components/map/StyleCache";
import { IWaterSourceSummary } from "../../components/inspectionView";
import { WaterSourceFeatureData, waterSourceStyleInfo, waterSourceFeature } from "./Map";


const createFeature = (styleCache: StyleCache, waterSource: IWaterSourceSummary, selectedNodeId?: string | number): olFeature => {
    const { waterSourceNodeId, waterSourceId, status, location } = waterSource;

    const isSelected = selectedNodeId !== undefined && waterSourceNodeId === selectedNodeId;
    if (isSelected) {
        console.log("selected feature:", waterSourceNodeId, waterSourceId);
    }

    const coordinates = [location.coordinates?.x ?? 0, location.coordinates?.y ?? 0];

    const data: WaterSourceFeatureData = {
        ...waterSource,
        show: true,
        reference: waterSourceId,
        status: status
    };

    const style = waterSourceStyleInfo(
        data.isActive,
        data.isOperable?.value ?? false,
        data.isDefective?.value ?? true,
        isSelected,
        data.category ?? { value: 0, displayText: "Unknown" },
        data.status,
        data.inspectionCount,
        data.tags
    );

    const feature = waterSourceFeature(coordinates, data, style, styleCache);
    return feature;
};

export default createFeature;
