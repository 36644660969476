import React, { useState } from "react";

import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";

import { IAccount } from "../../../store/types";
import AppDialog, { AppDialogProps } from "../appDialog";
import AdminMenu from "./components/adminMenu";
import MainMenu from "./components/mainMenu";
import useStyles from "./appDrawer.styles";
import { MenuItem } from "./types";

interface AppDrawerProps {
    readonly menuItems: MenuItem[];
    readonly account?: IAccount;
    readonly open?: boolean;
    readonly onClose?: () => void;
}

const AppDrawer = (props: AppDrawerProps): JSX.Element => {
    const styles = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [dialogParams, setDialogParams] = useState<{ title: string; children?: React.ReactNode }>();

    const closeDialog = (): void => setShowDialog(false);
    const openDialog = (title: string, children: React.ReactNode): void => {
        setDialogParams({ title, children });
        setShowDialog(true);
        closeDrawer();
    };
    const closeDrawer = (): void => props.onClose?.();

    const { account } = props;
    return (
        <React.Fragment>
            <Drawer anchor="left" open={props.open ?? false} onClose={closeDrawer}>
                <div className={styles.container}>
                    <Container fixed className={styles.account}>
                        <Typography variant="body2">{account?.name ?? "Unknown account"}</Typography>
                        <Typography variant="caption" color="textSecondary">{account?.customerName ?? "Unknown organisation"}</Typography>
                        <Typography variant="caption" color="textSecondary">{account?.subscriptionName ?? "Unknown subscription"}</Typography>
                    </Container>
                    <Divider />
                    <MainMenu items={props.menuItems} />
                    <AdminMenu className={styles.adminMenu} divider="top" onOpenDialog={openDialog} />
                </div>
            </Drawer>
            <AppDialog appBar={{ title: dialogParams?.title }} open={showDialog} onClose={closeDialog}>
                {dialogParams?.children}
            </AppDialog>
        </React.Fragment>
    );
};

export type { AppDrawerProps };
export default AppDrawer;