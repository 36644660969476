import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import RoomIcon from "@material-ui/icons/Room";

import { IAddress } from "../../../../../store/types";
import useStyles from "../InspectionItem.styles"

interface AddressProps {
    readonly address: IAddress | undefined;
}

const formatAddress = (address: IAddress | undefined): string => {
    if (address) {
        const fields = [address.streetDescription, address.town, address.postCode];
        const result = fields
            .reduce((current: string[], field) => {
                if (field) {
                    current.push(field);
                }
                return current;
            }, [])
            .join(", ");
        return result;
    }
    return "-";
};

const stringEquals = (a: string | undefined, b: string | undefined): boolean => {
    const isString = (s: string | null | undefined): s is string => {
        return s !== undefined && s !== null && typeof s === "string";
    };

    if (isString(a) && isString(b)) {
        return a.localeCompare(b) === 0;
    }
    if (isString(a)) {
        return false;
    }
    if (isString(b)) {
        return false;
    }

    return true;
};

const Address = (props: AddressProps): JSX.Element => {
    const styles = useStyles();
    const { address } = props;
    return (
        <React.Fragment>
            <Box mr={1}>
                <RoomIcon className={styles.icon} />
            </Box>
            <Typography variant="caption">{formatAddress(address)}</Typography>
        </React.Fragment>
    );
};

export type { AddressProps };
export default React.memo(Address, (prev, next): boolean => {
    const { address: prevAddress } = prev;
    const { address: nextAddress } = next;
    if (prevAddress === undefined && nextAddress === undefined) {
        return true;
    }
    if (prevAddress && nextAddress) {
        return stringEquals(prevAddress?.streetDescription, nextAddress?.streetDescription) &&
            stringEquals(prevAddress?.town, prevAddress?.town) &&
            stringEquals(prevAddress?.postCode, nextAddress?.postCode);
    }
    return false;
});