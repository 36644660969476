import React from "react";

import { NodeID, parseNodeId } from "../../../../lib/nodeIdentifier";
import { IRoute } from "../../../../store/types";
import LabelledField from "../../../labelledField";

interface RouteLinkProps {
    readonly route: IRoute | undefined;
    readonly onClick?: (id: NodeID) => void;
}

const WaterSourceRouteLink = ({ route, onClick }: RouteLinkProps): JSX.Element => {
    const id = "water-source-route";
    const label = "Route";
    if (route) {
        const handleClick = (): void => {
            onClick?.(parseNodeId(route.routeNodeId))
        };
        return (
            <LabelledField
                fieldType="link"
                id={id}
                label={label}
                classes={{ container: 'input__group input__group_side-panel', label: 'input__label' }}
                linkText={`${route.routeId} - ${route.name}`}
                preventDefault
                onClick={handleClick}
            />
        );
    }
    return <LabelledField fieldType="readonly" id={id} label={label} value="-" classes={{ container: 'input__group input__group_side-panel', label: 'input__label' }} />;
};

export default WaterSourceRouteLink;