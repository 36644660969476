import executeQuery from "../lib/executeQuery";
import gql from "../store/actions/graphQL/getInspectionForTechnician";
import { IInspection } from "../store/types";

const getInspection = async (id: string): Promise<IInspection | undefined> => {
    const variables = {
        id
    };
    const response = await executeQuery<{ node: IInspection | undefined }>(gql, variables);
    return response?.node;
};

export default getInspection;