export default `
query mobileSearch($filter: QueryFilter, $paging: QueryControlInput) {
    waterSources(queryFilter: $filter, queryControl: $paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        items {
            waterSourceNodeId: id
            waterSourceId: externalId
            category  {
                value
                displayText
            }
            riskSeverity {
                value
                displayText
            }
            isActive
            isOperable {
                value
                isOverridden
            }
            isDefective {
                value
                isOverridden
            }
            status {
                value
                displayText
            }
            ... on ITagged {
                tags {
                    value
                    displayText
                    enabled
                }
            }
            location {
                coordinates {
                    x
                    y
                }
            }
            inspectionCount(includeClosed: false)
            inspections(includeClosed: false) {
                inspectionNodeId: id
                inspectionId
                type {
                    value
                    displayText
                }
                inspectionDate
            }
        }
    }
}`;