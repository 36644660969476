import React, { useState, useEffect, useCallback } from 'react';
import cx from "classnames";

import { IConnection } from '../../../../store/types';
import Typography, { ITypographyProps } from '../../../shared/typography';
import { RepairType, queryRepairsByIds } from '../notificationItem/queryInternalRepairs';
import styles from './NotificationDetails.module.scss';

const DefectsItem = (props: { hasOpenDefects: boolean; onClick: () => void }): JSX.Element => {
    return props.hasOpenDefects
        ? (
            <div className={styles.root}>
                <button type="button" className={styles.button} onClick={props.onClick}>
                    <Typography scale="caption">
                        View open defects.
                    </Typography>
                </button>
            </div>
        )
        : <Typography className={styles.disabled} scale="caption">There are no open defects.</Typography>;
};

const FilesItem = (props: { hasAttachments: boolean; onClick: () => void }): JSX.Element => {
    return props.hasAttachments
        ? (
            <div className={cx(styles.root, { [styles.disabled]: !props.hasAttachments })}>
                <button type="button" className={styles.button} onClick={props.onClick}>
                    <Typography scale="caption">
                        View attachments.
                    </Typography>
                </button>
            </div>
        )
        : <Typography className={styles.disabled} scale="caption">There are no attachments.</Typography>;
};

const NotesItem = (props: { show: boolean }): JSX.Element => {
    return props.show
        ? (
            <div className={cx(styles.root)}>
                <Typography scale="caption">A note was added to the inspection.</Typography>
            </div>
        )
        : <React.Fragment />;
};


interface FieldComponentStyles {
    readonly label?: ITypographyProps;
    readonly value?: ITypographyProps;
}

interface FieldProps {
    readonly label: string;
    readonly value: string;
    readonly style?: FieldComponentStyles;
}

const Field = (props: FieldProps): JSX.Element => {
    const { label, value, style } = props;
    return (
        <div className={styles.field}>
            <Typography className={cx(styles.label, style?.label?.className)} scale={style?.label?.scale ?? "body2"} transform={style?.label?.transform}>
                {label}
            </Typography>
            <Typography className={cx(styles.value, style?.value?.className)} scale={style?.value?.scale ?? "body2"} transform={style?.value?.transform}>
                {value}
            </Typography>
        </div>
    );
};

const WaterSourceLink = (props: { id: string | number; onClick: () => void }): JSX.Element => {
    return (
        <div className={cx(styles.root, styles.highlight)}>
            <button type="button" className={styles.button} onClick={props.onClick}>
                <Typography scale="body2">Water Source {props.id}</Typography>
            </button>
        </div>
    );
};

interface InternalTasksProps {
    readonly repairIds: number[];
}

const InternalTasks = (props: InternalTasksProps): JSX.Element => {
    const { repairIds } = props;

    const [repairs, setRepairs] = useState<RepairType[]>([]);

    const queryRepairs = useCallback(async (): Promise<IConnection<RepairType>> => {
        return queryRepairsByIds(repairIds);
    }, [repairIds]);

    const formatLabel = (): string => {
        return `Internal Tasks (${repairIds.length}):`;
    };

    const formatInternalTasks = (): string => {
        if (repairs.length) {
            const labels = repairs.map(item => item.repairType?.displayText);
            const text = labels.join(", ");
            return text;
        }
        return "-";
    };

    useEffect(() => {
        queryRepairs()
            .then(response => response.items)
            .then(repairs => setRepairs(repairs))
            .catch(console.error);
    }, [queryRepairs]);

    const fieldStyle: FieldComponentStyles = {
        label: {
            scale: "caption",
            className: styles.tasks
        },
        value: {
            scale: "caption",
            className: styles.tasks
        }
    };
    return props.repairIds.length === 0
        ? <React.Fragment />
        : <Field label={formatLabel()} value={formatInternalTasks()} style={fieldStyle} />;
};

export type { FieldProps, FieldComponentStyles };
export { DefectsItem, FilesItem, NotesItem, Field, WaterSourceLink, InternalTasks };
