import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";

import { useNavigation } from "../../../router/MobileRouter/hooks";
import Portal from "../portal";
import NavigationBar from "./NavigationBar";
import ViewPrimaryAction from "./ViewPrimaryAction";
import ViewSecondaryAction from "./ViewSecondaryAction";
import ViewTitle from "./ViewTitle";
import ViewToolbar from "./ViewToolbar";

interface ViewProps {
    readonly backButton?: boolean;
    readonly disableRefresh?: boolean;
    readonly extendedAppBar?: boolean;
    readonly primary?: React.ReactNode;
    readonly secondary?: React.ReactNode;
    readonly title?: React.ReactNode;
    readonly onRefreshClick?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: theme.palette.primary.light,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
}));

const View = (props: React.PropsWithChildren<ViewProps>): JSX.Element => {
    const { backButton, disableRefresh, extendedAppBar, primary, secondary, title, children, onRefreshClick } = props;
    const { appBar } = useNavigation();
    const extended = extendedAppBar ?? appBar?.extended;
    const styles = useStyles({ extended });

    return (
        <div className={styles.root}>
            <Portal>
                <AppBar position="sticky">
                    <ViewToolbar extended={extended}>
                        <ViewPrimaryAction action={primary} backButton={backButton} />
                        <ViewTitle title={title ?? appBar?.title} extended={extended} />
                        <ViewSecondaryAction action={secondary} extended={extended} />
                    </ViewToolbar>
                </AppBar>
                <Box component="section" display="flex" flexDirection="column" overflow="hidden" height="100%">
                    {children}
                </Box>
                <NavigationBar disableRefresh={disableRefresh} onRefreshClick={onRefreshClick} />
            </Portal>
        </div>
    );
};

export type { ViewProps };
export default View;