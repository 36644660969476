import React, { useState, useEffect, useRef } from "react";

import executeQuery from "../../../../lib/executeQuery";
import quickSearchQuery from "../../../../store/actions/graphQL/quickSearchQuery";
import { IConnection, IWaterSource, IQuickSearchItem } from "../../../../store/types";
import QuickSearchLoading from "../quickSearchLoading";
import QuickSearchResult from "../quickSearchResult";
import QuickSearchEmptyResult from "../quickSearchEmptyResult";

interface WaterSourceSearchParams {
    readonly input: string;
    readonly pageNumber: number;
    readonly setIsLoading: (value: boolean) => void;
    readonly setSearchResults: (items: IQuickSearchItem[], totalCount: number) => void;
}

const idPattern = /^.+$/;

const pageSize = 5;

const waterSourceSearch = (params: WaterSourceSearchParams): void => {
    const variables = {
        filter: {
            externalId: {
                '$like': `${params.input}%`
            }
        },
        paging: {
            pageIndex: params.pageNumber,
            pageSize: pageSize,
            sortBy: "id",
            sortDirection: "ASCENDING"
        }
    };
    params.setIsLoading(true);
    executeQuery<{ waterSources: IConnection<IWaterSource> }>(quickSearchQuery, variables)
        .then(response => response?.waterSources)
        .then(waterSources => {
            const items = waterSources?.items ?? [];
            const totalCount = waterSources?.totalCount ?? 0;
            params.setSearchResults(items, totalCount);
        })
        .catch(() => {
            params.setSearchResults([], 0);
        })
        .finally(() => params.setIsLoading(false));
};

interface IIdSearchProps {
    readonly input: string;
    readonly inline?: boolean;
    readonly onSearchResultClick: (result: IQuickSearchItem) => void;
    readonly onSearchInvalid: () => void;
    readonly onClose: () => void;
}

const IdSearch = (props: IIdSearchProps): JSX.Element => {
    const [isInputValid, setIsInputValid] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchComplete, setIsSearchComplete] = useState(false);
    const [searchResults, setSearchResults] = useState<IQuickSearchItem[]>([]);
    const [searchResultsTotal, setSearchResultsTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const { input, onSearchInvalid } = props;

    const prevInput = useRef(input);
    useEffect(() => {
        if (input !== prevInput.current) {
            setPageNumber(1);
        }
    }, [input, prevInput]);

    useEffect(() => {
        if (idPattern.test(input)) {
            setIsInputValid(true);
            waterSourceSearch({
                input,
                pageNumber,
                setIsLoading,
                setSearchResults: (items, totalCount) => {
                    setIsSearchComplete(true);
                    setSearchResults(items);
                    setSearchResultsTotal(totalCount);
                    setTotalPages(Math.ceil(totalCount / pageSize));
                }
            });
        }
        else {
            setIsInputValid(false);
        }
    }, [input, pageNumber]);

    useEffect(() => {
        if (!isInputValid) {
            onSearchInvalid?.();
        }
    }, [isInputValid, onSearchInvalid])

    const clearResults = (): void => {
        setIsLoading(false);
        setIsSearchComplete(false);
        setSearchResults([]);
        setSearchResultsTotal(0);
    };

    const handleCloseResults = (): void => {
        props.onClose();
        clearResults();
    };
    const handleSelectResult = (item: IQuickSearchItem): void => {
        props.onSearchResultClick(item);
        clearResults();
    };

    return isInputValid
        ? (
            <React.Fragment>
                <QuickSearchLoading show={isLoading} inline={props.inline ?? false} />
                <QuickSearchEmptyResult show={isSearchComplete && searchResultsTotal === 0} inline={props.inline} />
                {!isLoading && <QuickSearchResult
                    show={isSearchComplete && searchResults.length > 0}
                    items={searchResults}
                    pageSize={searchResults.length}
                    totalCount={searchResultsTotal}
                    totalPages={totalPages}
                    isInline={props.inline}
                    pageNumber={pageNumber}
                    onSelect={handleSelectResult}
                    onClose={handleCloseResults}
                    onGotoFirst={(): void => setPageNumber(1)}
                    onGotoLast={(): void => setPageNumber(totalPages)}
                    onGotoNext={(): void => setPageNumber(pageNumber + 1)}
                    onGotoPrevious={(): void => setPageNumber(pageNumber - 1)}
                />}
            </React.Fragment>
        )
        : <React.Fragment />;
};

export type { IIdSearchProps };
export default IdSearch;