export default `
fragment LookupItem on LookupItem {
    displayText
    value
}

query findRepairs($filter: QueryFilter, $paging: QueryControlInput) {
    repairs(queryFilter: $filter, queryControl: $paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        items {
            repairNodeId: id
            repairId
            repairCategory {
                ...LookupItem
            }
            repairType {
                ...LookupItem
            }
            waterSource {
                waterSourceNodeId: id
                waterSourceId: externalId
            }
            defect {
                defectNodeId: id
                defectId
            }
            inspection {
                inspectionNode: id
                inspectionId
            }
            actualCost
            repairVendor {
                ...LookupItem
            }
            dateCreated
            datePaid
            dateClosed
            isOpen
        }
    }
}`;
