import executeQuery from "../lib/executeQuery";
import gql from "../store/actions/graphQL/getWaterSource";
import { IWaterSource } from "../store/types";

const getWaterSource = async (id: string): Promise<IWaterSource | undefined> => {
    const variables = {
        id
    };
    const response = await executeQuery<{ node: IWaterSource | undefined }>(gql, variables);
    return response?.node;
};

export default getWaterSource;