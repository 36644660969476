import React from "react";

import { containerClass, labelClass } from "../labelledField.utils";
import { ElementProps } from "../types";
import ElementContainer from "./elementContainer";
import LabelElement from "./labelElement";


interface LabelledContainerProps extends ElementProps {
    readonly children?: React.ReactNode;
}

const LabelledContainer = (props: LabelledContainerProps): JSX.Element => {
    const labelPosition = props.labelPosition ?? "TOP";
    return (
        <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
            {labelPosition === "TOP" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
            {props.children}
            {labelPosition === "BOTTOM" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
        </ElementContainer>
    );
};

export type { LabelledContainerProps };
export default LabelledContainer;