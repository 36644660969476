import React from "react";

import useSelector from "../../../../lib/hooks/useSelector";
import CloseDefectBase, { CloseDefectProps } from "./CloseDefect.view";

type ConnectedProps = Pick<CloseDefectProps, "defect" | "onCommit">;

const CloseDefect = (props: ConnectedProps): JSX.Element => {
    const closeReasons = useSelector(state => state.app.lookups?.defectCloseReasons ?? []);
    const isMobile = useSelector(state => state.device.isMobile);
    const connectedProps: CloseDefectProps = {
        ...props,
        closeReasons,
        isMobile
    };
    return <CloseDefectBase {...connectedProps} />
};

export default CloseDefect;