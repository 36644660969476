import React from "react";

import { createStyles, withStyles, WithStyles, Theme, StyleRules } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme): StyleRules => createStyles({
    root: {
        display: "flex",
        alignItems: "center"
    },
    header: {
        color: theme.palette.primary.contrastText
    },
    button: {
        color: theme.palette.primary.contrastText
    }
});

interface DrawerToolbarProps {
    readonly menu?: React.ReactNode;
    readonly title?: React.ReactNode;
    readonly onClose?: () => void;
}

const renderTitle = (title: React.ReactNode, className: string): React.ReactNode => {
    return typeof title === "string" || typeof title === "number"
        ? <Typography variant="caption" className={className} noWrap>{title}</Typography>
        : title;
};

const MenuPlaceholder = (): JSX.Element => {
    return <Box minHeight={48} minWidth={48} height={48} width={48} />
};

const DrawerToolbar = withStyles(styles)((props: WithStyles<typeof styles> & DrawerToolbarProps): JSX.Element => {
    const { classes, menu, title, onClose } = props;
    const handleCloseClick = (): void => {
        onClose?.();
    };
    return (
        <Toolbar variant="dense" disableGutters className={classes.root}>
            {menu ?? <MenuPlaceholder />}
            <Box overflow="hidden" flexGrow={1} display="flex" flexDirection="row" justifyContent="center">
                {renderTitle(title, classes.header)}
            </Box>
            <Box ml="auto">
                <IconButton className={classes.button} onClick={handleCloseClick}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Toolbar>
    );
});

export default DrawerToolbar;