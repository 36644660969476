import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { navigateToLocationOnMap } from "../../../../store/actions/map";
import CoordinatesSearch, { ICoordinatesSearchProps } from "./CoordinatesSearch.view";

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>): Pick<ICoordinatesSearchProps, "showOnMap"> => ({
    showOnMap: (coordinates): void => dispatch(navigateToLocationOnMap(coordinates))
});

export default connect(undefined, mapDispatch)(CoordinatesSearch);