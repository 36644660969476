export default `
query getScheme_Query($id: ID!) {
    node(id: $id) {
        ... on Scheme {
            schemeNodeId: id
            schemeId
            type {
                displayText
                value
            }
            reference
            title
            description
            station {
                displayText
                value
            }
            address {
                addressNodeId: id
                addressId
                saon {
                    description
                }
                paon {
                    description
                }
                usrn
                streetDescription
                locality
                town
                postCode
                administrativeArea
                uprn
                coordinates {
                    x
                    y
                }
            }
            stage {
                displayText
                value
                isOpen
            }
            dateReceived
            dateReturned
            dateOrdered
            orderReference
            invoiceReference
            invoiceDueDate
            cost
            dateClosedExternally
            dateClosed
            lastEvent {
                action
                actor
                timestamp
            }
            events {
                action
                actor
                timestamp
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
            waterSources {
                waterSourceNodeId: id
                waterSourceId: externalId
                isActive
                isDefective {
                    value
                    isOverridden
                }
                isOperable {
                    value
                    isOverridden
                }
                nextInspectionDate
                recommendedInspectionDate
                riskSeverity {
                    value
                    displayText
                }
                location {
                    coordinates {
                        x
                        y
                    }
                }
                status {
                    value
                }
            }
            waterPipes {
                waterPipeNodeId: id
                waterPipeId
                isActive
                path {
                    coordinates {
                        x
                        y
                    }
                }
            }
            thoroughfares {
                thoroughfareNodeId: id
                thoroughfareId
                isActive
                name
                path {
                    coordinates {
                        x
                        y
                    }
                }
            }
            boundary {
                exteriorRing {
                    coordinates {
                        x
                        y
                    }
                }
            }
        }
    }
}`;