import { IWaterSourceSummary } from "../../components/inspectionView";
import executeQuery from "../../lib/executeQuery";
import { NodeID, encodeNodeId } from "../../lib/nodeIdentifier";
import { guid } from "../../lib/Utils";
import { IEditMutationVariables, IWaterSourceInput, IHydrantInput } from "../../store/types";
import updateEwsLocationQuery from "../graphql/updateEwsLocation";
import updateHydrantLocationQuery from "../graphql/updateHydrantLocation";
import updateRiserLocationQuery from "../graphql/updateRiserLocation";
import updateUwsLocationQuery from "../graphql/updateUwsLocation";
import { UpdateResponse, UpdateHydrantResponse, UpdateEmergencyWaterSupplyResponse, UpdateRiserResponse, UpdateUnknownWaterSupplyResponse } from "./types";

type LocationData = Pick<IWaterSourceInput,
    "locationAddressId" |
    "locationCoordinates" |
    "locationDescription" |
    "controlMeasures"
> & Pick<IHydrantInput, "hydrantLocationId">;

interface UpdateWaterSourceLocationRequest {
    readonly input: IEditMutationVariables<LocationData>;
}

interface UpdateLocationResponse<T = UpdateResponse> {
    readonly waterSource: {
        readonly update: T;
    };
}

const updateHydrantLocation = async (request: UpdateWaterSourceLocationRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateLocationResponse<UpdateHydrantResponse>>(updateHydrantLocationQuery, request)
    return response?.waterSource.update.hydrant.waterSource;
};

const updateEmergencyWaterSupplyLocation = async (request: UpdateWaterSourceLocationRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateLocationResponse<UpdateEmergencyWaterSupplyResponse>>(updateEwsLocationQuery, request);
    return response?.waterSource.update.emergencyWaterSupply.waterSource;
};

const updateRiserLocation = async (request: UpdateWaterSourceLocationRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateLocationResponse<UpdateRiserResponse>>(updateRiserLocationQuery, request);
    return response?.waterSource.update.riser.waterSource;
};

const updateUnknownWaterSourceLocation = async (request: UpdateWaterSourceLocationRequest): Promise<IWaterSourceSummary | undefined> => {
    const response = await executeQuery<UpdateLocationResponse<UpdateUnknownWaterSupplyResponse>>(updateUwsLocationQuery, request);
    return response?.waterSource.update.unknown.waterSource;
};

const updateWaterSourceLocation = async (nodeId: NodeID, data: LocationData): Promise<IWaterSourceSummary | undefined> => {
    const request: UpdateWaterSourceLocationRequest = {
        input: {
            clientMutationId: guid(),
            nodeId: encodeNodeId(nodeId),
            data,
        }
    };
    const executeQuery = async (): Promise<IWaterSourceSummary | undefined> => {
        switch (nodeId.typeName) {
            case "Hydrant":
                return updateHydrantLocation(request);
            case "EmergencyWaterSupply":
                return updateEmergencyWaterSupplyLocation(request);
            case "Riser":
                return updateRiserLocation(request);
            case "UnknownWaterSource":
                return updateUnknownWaterSourceLocation(request);
            default:
                throw new Error(`Unknown node type '${nodeId.typeName}'.`)
        }
    };

    const waterSource = executeQuery();
    return waterSource;
};

export type { LocationData };
export default updateWaterSourceLocation;