import React from "react";

import useNavigation from "../hooks/useNavigation";
import AppBarMenuItem from "./AppBarMenuItem";

const AppBarDashboardItem = (): JSX.Element => {
    const { goToDashboard } = useNavigation();

    const handleClick = (): void => {
        goToDashboard();
    };

    return (
        <AppBarMenuItem id="dashboard" label="Dashboard" onClick={handleClick} />
    );
};

export default AppBarDashboardItem;