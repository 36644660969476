export default `
fragment NoteBody on Note {
    noteNodeId: id
    noteId
    timestamp
    author
    content
}
mutation deleteNote_Mutation($input: EmptyEditInput!) {
    deleteNote(input:$input) {
        clientMutationId
        object {
            ownerNodeId: id
            ... on Defect {
                notes {
                    ...NoteBody
                }
            }
            ... on Repair {
                notes {
                    ...NoteBody
                }
            }
            ...on Inspection {
                notes {
                    ...NoteBody
                }
            }
            ...on Route {
                notes {
                    ...NoteBody
                }
            }
            ... on Scheme {
                notes {
                    ...NoteBody
                }
            }          
            ... on IWaterSource {
                notes {
                    ...NoteBody
                }
            }
        }
        hasErrors
        errors {
            message
            path
        }
    }
}`;