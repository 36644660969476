import React from "react";

import Box from "@material-ui/core/Box";

import { AddressNode } from "../../../../../store/types";
import ClearAddress from "./ClearAddress";
import FindAddress from "./FindAddress";
import { AddressChanged, AddressSelected } from "./types";

interface ActionOptions {
    readonly clear?: boolean;
    readonly find?: boolean;
}

interface SelectorProps {
    readonly show?: boolean;
    readonly actions?: ActionOptions;
    readonly onChange?: AddressChanged;
    readonly onSelect?: AddressSelected;
}

const Selector = ({ show, actions, onChange, onSelect }: SelectorProps): JSX.Element | null => {
    if (!show) {
        return null;
    }
    
    const { clear, find } = actions ?? {};
   
    const handleAddressClear = (): void => {
        onSelect?.(undefined);
    };
    const handleAddressChange = (value: AddressNode): void => {
        onChange?.(value);
    };
    const handleAddressSelect = (value: AddressNode | undefined): void => {
        onSelect?.(value);
    };
    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
            {find && <FindAddress onChange={handleAddressChange} onSelect={handleAddressSelect} />}
            {clear && <ClearAddress onConfirm={handleAddressClear} />}
        </Box>
    );
};

export type { SelectorProps };
export default Selector;