
import { useMemo } from "react";

import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";
import useQueryString from "../../hooks/useQueryString";
import { useDispatch } from "react-redux";
import { setMapClickAction } from "../../../store/actions/map";

enum WaterSourceTab {
    WATER_SOURCE = 0,
    INSPECTIONS = 1,
    DEFECTS = 2,
    REPAIRS = 3
}

type AppWindowFilters = Record<string, unknown>;

interface AppWindowParams {
    readonly filters?: AppWindowFilters;
    readonly scheme?: NodeID;
    readonly route?: NodeID;
    readonly waterSource?: NodeID;
    readonly tab?: WaterSourceTab;
    readonly defect?: NodeID;
    readonly inspection?: NodeID;
    readonly repair?: NodeID;
    readonly focusMap?: boolean;
}

interface SchemeQueryParams extends Partial<WaterSourceQueryParams> {
    readonly scheme: string;
}
interface RouteQueryParams extends Partial<WaterSourceQueryParams> {
    readonly route: string;
}
type WaterSourceTabLabel = "WATER_SOURCE" | "INSPECTIONS" | "DEFECTS" | "REPAIRS";
interface WaterSourceQueryParams {
    readonly ws: string;
    readonly tab?: WaterSourceTabLabel;
    readonly defect?: string;
    readonly inspection?: string;
    readonly repair?: string;
    readonly focusMap?: string;
}
interface FilterQueryParams {
    readonly filters?: string;
}

type QueryParams = FilterQueryParams & (
    SchemeQueryParams |
    RouteQueryParams |
    WaterSourceQueryParams
);

type QueryString<T> = {
    readonly [K in keyof T]?: string;
};

type ParseResult<T> = T extends string ? NodeID : undefined;
type FilterParseResult = Pick<AppWindowParams, "filters">;
type WaterSourceParseResult = Pick<AppWindowParams,
    "waterSource" |
    "tab" |
    "defect" |
    "inspection" |
    "repair" |
    "focusMap"
>;
const parse = <T extends string | undefined>(s: T): ParseResult<T> => {
    return s
        ? parseNodeId(decodeURIComponent(s as string)) as ParseResult<T>
        : undefined as ParseResult<T>;
};
const parseBoolean = (value: string | undefined): boolean | undefined => {
    return value === undefined
        ? undefined
        : Boolean(value);
};
const parseFilters = (params: QueryString<QueryParams>): FilterParseResult | undefined => {
    const { filters } = params;
    if (filters) {
        const decodeFilters = (): Record<string, unknown> => {
            const a = decodeURIComponent(filters);
            const qs = atob(a);
            const query = JSON.parse(qs) as Record<string, unknown>;
            return query;
        };
        return { filters: decodeFilters() };
    }
    return undefined;
};
const parseTab = (tabLabel: WaterSourceTabLabel | undefined): WaterSourceTab => {
    switch (tabLabel) {
        case "INSPECTIONS":
            return WaterSourceTab.INSPECTIONS;
        case "DEFECTS":
            return WaterSourceTab.DEFECTS;
        case "REPAIRS":
            return WaterSourceTab.REPAIRS;
        default:
            return WaterSourceTab.WATER_SOURCE;
    }
};
const parseWaterSource = (params: QueryString<QueryParams>): WaterSourceParseResult | undefined => {
    const { ws, tab, defect, inspection, repair, focusMap } = params;
    return ws
        ? {
            waterSource: parse(ws),
            tab: parseTab(tab as (WaterSourceTabLabel | undefined)),
            defect: parse(defect),
            inspection: parse(inspection),
            repair: parse(repair),
            focusMap: parseBoolean(focusMap)
        }
        : focusMap !== undefined
            ? { focusMap: parseBoolean(focusMap) }
            : undefined;
};

const useAppWindowParams = (): AppWindowParams => {
    const qs = useQueryString() as QueryString<QueryParams>;
    const params = useMemo(() => {
        const getParams = (): AppWindowParams => {
            if ("scheme" in qs) {
                return {
                    ...parseFilters(qs),
                    scheme: parse(qs.scheme),
                    ...parseWaterSource(qs)
                };
            }
            if ("route" in qs) {
                return {
                    ...parseFilters(qs),
                    route: parse(qs.route),
                    ...parseWaterSource(qs)
                };
            }
            return {
                ...parseFilters(qs),
                ...parseWaterSource(qs)
            };
        };
        const result = getParams();
        return result;
    }, [qs]);
    return params;
};

export type { AppWindowParams, AppWindowFilters, QueryString, QueryParams };
export { WaterSourceTab };
export default useAppWindowParams;