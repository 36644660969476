export default `
query getMapWaterSources_Query($filter:Filter) {
    waterSources(filter:$filter) {
        waterSourceNodeId: id
        waterSourceId: externalId
        category {
            value
        }
        isActive
        isDefective {
            value
            isOverridden
        }
        isOperable {
            value
            isOverridden
        }
        location {
            coordinates {
                x
                y
            }
        }
        status {
            value
        }
        tags {
            value
            displayText
            enabled
        }
    }
}
`;