import React, { useCallback } from "react";
import classnames from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { ILookupItem, IWaterSource } from "../../../store/types";
import { OperationalStatus } from "../../inspectionView/types";
import { Category } from "./category.d";
import * as categories from "./categories.d";
import { CategoryIconProps } from "./categoryIcon";
import Ews from "./ews";
import Hydrant, { HydrantIconType, HydrantIconStatus } from "./hydrant";
import Riser from "./riser";
import Unknown from "./unknown";
import Box, { BoxProps } from "@material-ui/core/Box";

const useStyles = makeStyles(createStyles({
    root: {
        display: "inline-flex"
    }
}));

type WaterSourceState = Pick<IWaterSource, "isActive" | "status" | "isOperable" | "isDefective" | "tags">;

interface IWaterSourceCategoryProps extends CategoryIconProps, WaterSourceState {
    readonly category: Category
}

const itemIsMatch = (item: ILookupItem | undefined, text: string): boolean => {
    return item ? item.displayText.toUpperCase() === text.toUpperCase() : false;
};

const getHydrantType = (tags: ILookupItem[] | undefined): HydrantIconType => {
    const isPrivate = (tag: ILookupItem): boolean => itemIsMatch(tag, "PRIVATE");
    const isWashout = (tag: ILookupItem): boolean => itemIsMatch(tag, "WASHOUT");

    if (tags) {
        if (tags.some(isPrivate)) {
            return "private";
        }
        if (tags.some(isWashout)) {
            return "washout";
        }
    }
    return "standard";
};

const getHydrantStatus = (
    isActive: boolean | undefined,
    status: ILookupItem | undefined,
    isOperable: OperationalStatus | undefined,
    isDefective: OperationalStatus | undefined): HydrantIconStatus => {
    if (isActive !== undefined && !isActive) {
        return "inactive";
    }
    if (status) {
        const isMatch = (text: string): boolean => itemIsMatch(status, text);
        if (isMatch("PROPOSED")) {
            return "proposed";
        }
        if (isMatch("AWAITING ADOPTION")) {
            return "awaitingAdoption";
        }
    }
    if (isOperable?.value && isDefective?.value) {
        return "operableDefective";
    }
    if (isDefective?.value) {
        return "defective";
    }
    if (isOperable?.value) {
        return "operable";
    }
    return "operable";
};

const renderCategory = (
    category: Category,
    status: HydrantIconStatus,
    isAbandoned: boolean,
    iconType: HydrantIconType,
    height: number | undefined,
    width: number | undefined): JSX.Element => {
    switch (category) {
        case categories.HYDRANT:
            return <Hydrant height={height} width={width} status={status} abandoned={isAbandoned} type={iconType} />;
        case categories.EWS:
            return <Ews height={height} width={width} />;
        case categories.RISER:
            return <Riser height={height} width={width} status={status} abandoned={isAbandoned} />;
        default:
            return <Unknown height={height} width={width} />
    }
};

const WaterSourceCategory = (props: IWaterSourceCategoryProps & BoxProps): JSX.Element => {
    const styles = useStyles();
    const { height, width, category, isActive, status, isOperable, isDefective, tags, className, ...box } = props;

    const hydrantStatus = getHydrantStatus(isActive, status, isOperable, isDefective);
    const isAbandoned = itemIsMatch(status, "ABANDONED");
    const hydrantType = getHydrantType(tags);

    const render = useCallback(() => {
        return renderCategory(category, hydrantStatus, isAbandoned, hydrantType, height, width)
    }, [category, hydrantStatus, isAbandoned, hydrantType, height, width]);

    return (
        <Box component="span" className={classnames(styles.root, className)} {...box}>
            {render()}
        </Box>
    );
};

export type { WaterSourceState, IWaterSourceCategoryProps };
export default React.memo(WaterSourceCategory, (prev, next): boolean => {
    return prev.category === next.category &&
        prev.className === next.className &&
        prev.height === next.height &&
        prev.isActive === next.isActive &&
        prev.isDefective.value === next.isDefective.value &&
        prev.isOperable.value === next.isOperable.value &&
        prev.status?.value === next.status?.value &&
        prev.tags?.sort((a, b) => a.sortOrder - b.sortOrder).map(t => t.value).join(",") === next.tags?.sort((a, b) => a.sortOrder - b.sortOrder).map(t => t.value).join(",") &&
        prev.title === next.title &&
        prev.width === next.width;
});