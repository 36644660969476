import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { formatText } from "../../../../lib/Utils";
import LabelledField, { SelectOption, LabelledFieldProps, asLookupItem } from "../../../labelledField";
import { WaterSourceEditor, SelectProps } from "../types";
import { getOperationalStatus, getOverrideState } from "../waterSourceControl.utils";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.secondary.main
    },
    editor: {
        marginBottom: "0rem"
    },
    readOnly: {
        display: "flex",
        alignItems: "baseline"
    },
    override: {
        marginLeft: theme.spacing(1)
    }
}));

const Editor = ({ options, value, onClear, onSelect }: Pick<WaterSourceEditor<SelectProps>, "options" | "value" | "onClear" | "onSelect">): JSX.Element => {
    const styles = useStyles();
    const handleChange = (option: SelectOption | undefined): void => {
        onSelect?.(option);
    };
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "waterSource-operationalStatus",
        label: "",
        classes: { container: cx("input__group", styles.editor), label: "input__label" },
        editing: true,
        value: asLookupItem(value),
        selectOptions: options,
        blankOptionText: "Automatic Status",
        onChange: handleChange,
        onClear
    };
    return <LabelledField {...props} />;
};

const ReadOnly = ({ waterSource, value }: Pick<WaterSourceEditor<SelectProps>, "waterSource" | "value">): JSX.Element => {
    const styles = useStyles();
    const operationalStatus = getOperationalStatus(waterSource.isOperable, waterSource.isDefective, value);
    const displayText = formatText(operationalStatus?.displayText, true, "-");
    const override = getOverrideState(value);
    return (
        <div className={styles.readOnly}>
            <Typography variant="body2">{displayText}</Typography>
            <Typography variant="caption" className={styles.override}>{override}</Typography>
        </div>
    );
};

const WaterSourceOperationalStatus = (props: WaterSourceEditor<SelectProps>): JSX.Element => {
    const { editing, waterSource, options, value, onClear, onSelect } = props;
    const styles = useStyles();
    return (
        <div className={styles.root}>
            {editing
                ? <Editor options={options} value={value} onClear={onClear} onSelect={onSelect} />
                : <ReadOnly waterSource={waterSource} value={value} />
            }
        </div>
    );
};

export default WaterSourceOperationalStatus;