import WMTSCapabilities from "ol/format/WMTSCapabilities";
import BaseLayer from "ol/layer/Base";
import ImageLayer from "ol/layer/Image";
import TileLayer from "ol/layer/Tile";
import ImageSource from "ol/source/Image";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Source, { AttributionLike } from "ol/source/Source";
import WMTS, { optionsFromCapabilities, Options } from "ol/source/WMTS";
import XYZ from 'ol/source/XYZ';

import { ITileServerParams } from "../../store/types/map";
import { TileServerType } from "./TileServerType";

// base options for WMTS layers
interface BaseWmtsOptions {
    readonly attributions?: AttributionLike;
    readonly layer: string;
    readonly style: string;
    readonly format?: string;
    readonly matrixSet: string;
    readonly url?: string;
    readonly crossOrigin?: string;
}

export class MapLayerFactory {
    // create a layer of the specified type, for the given source
    // no checks are made to ensure the source is appropriate for the type of layer
    public static CreateMapLayer(tileServerType: TileServerType, source: Source | XYZ | ImageSource): BaseLayer {
        console.log("create map layer", tileServerType);
        switch (tileServerType.trim().toLowerCase()) {
            case "wms":
                return new ImageLayer({ source: source as ImageSource });
            case "wmts":
            case "osm":
            case "osm-public":
            case "xyz":
            default:
                return new TileLayer({ source: source as XYZ });
        }
    }

    // create the map source
    // tileServerParams are required for WMS, see https://openlayers.org/en/latest/apidoc/module-ol_source_ImageWMS-ImageWMS.html LAYERS is required, STYLES can be set to override default style. set as JSON object
    public static async CreateMapSource(tileServerType: TileServerType, mapUrl: string, tileServerParams?: ITileServerParams): Promise<Source> {
        console.log("create map source", tileServerType);
        switch (tileServerType.trim().toLowerCase()) {
            case "wms":
                return MapLayerFactory.CreateWmsMapSource(mapUrl, tileServerParams);
            case "wmts":
                return MapLayerFactory.CreateWmtsMapSource(mapUrl, tileServerParams);
            case "osm":
                return new OSM({ url: mapUrl, crossOrigin: 'anonymous' });
            case "osm-public":
                return new OSM({ crossOrigin: 'anonymous' });
            case "xyz":
            default:
                return new XYZ({ url: mapUrl, crossOrigin: 'anonymous' });
        }
    }

    private static CreateWmsMapSource(mapUrl: string, tileServerParams?: ITileServerParams): Source {
        if (!tileServerParams) {
            throw new Error("Params are required for WMTS layers");
        }
        if (!tileServerParams.layer) {
            throw new Error("Params.layer is required for WMS layers");
        }
        return new ImageWMS({
            url: mapUrl,
            ratio: 1.5,
            params: { LAYERS: tileServerParams.layer, STYLES: tileServerParams.style, ...tileServerParams.additionalParams },
            attributions: tileServerParams.attributions,
            crossOrigin: 'anonymous'
        });
    }

    private static async CreateWmtsMapSource(mapUrl: string, tileServerParams?: ITileServerParams): Promise<Source> {
        if (!tileServerParams) {
            throw new Error("Params are required for WMTS layers");
        }
        if (!tileServerParams.matrixSet) {
            throw new Error("Params.matrixSet is required for WMTS layers");
        }
        const capabilitiesUrl = MapLayerFactory.GetWmtsCapabilitiesUrl(mapUrl, tileServerParams.getCapabilitiesUrl);
        const baseOptions: BaseWmtsOptions = {
            url: mapUrl,
            format: tileServerParams.format ?? "image/jpeg",
            style: tileServerParams.style ?? '',
            matrixSet: tileServerParams.matrixSet,
            layer: tileServerParams.layer ?? '',
            attributions: tileServerParams.attributions,
            crossOrigin: 'anonymous'
        };
        const capabilitiesOptions = await MapLayerFactory.GetWmtsCapabilities(capabilitiesUrl, baseOptions);
        // @ts-ignore (will complain because WMTS object has mandatory properties that the spec says aren't mandatory, ts-expect-error still errors for some reason at runtime)
        return new WMTS(capabilitiesOptions);
    }

    private static async GetWmtsCapabilities(capabilitiesUrl: string, baseOptions: BaseWmtsOptions): Promise<Options> {
        return fetch(capabilitiesUrl, { method: "GET" })
            .then(async response => {
                if (response.status === 200) {
                    return response.text()
                }
                throw new Error(`Could not get capabilities for WMTS service from ${capabilitiesUrl}; HTTP ${response.status} (${response.statusText})`);
            })
            .then(text => {
                const parser = new WMTSCapabilities();
                const parsedCapabilities = parser.read(text)
                const options = optionsFromCapabilities(parsedCapabilities, baseOptions);
                return options;
            })
            .catch(error => {
                throw new Error(`Could not get capabilities for WMTS service from ${capabilitiesUrl}; ${error}`);
            });
    }

    private static GetWmtsCapabilitiesUrl(mapUrl: string, capabilitiesUrl: string | undefined): string {
        if (capabilitiesUrl) {
            return capabilitiesUrl;
        }
        // try to guess it
        return `${mapUrl}${mapUrl.includes('?') ? "&" : "?"}REQUEST=GetCapabilities`;
    }
}
