import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, NumberProps } from "../types";

const EwsCapacity = ({ editing, value, onChange }: PropsWithEditing<NumberProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "number",
        id: "water-source-capacity",
        label: "Capacity (litres)",
        classes: { container: 'input__group', label: 'input__label' },
        value,
        minValue: 0,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return (
        <LabelledField {...props} />
    );
};

export default EwsCapacity;