import React, { Component, Fragment } from 'react';
import { Radio, RadioGroup } from "@blueprintjs/core";
import { LocalDate } from '@js-joda/core';

import Box from '@material-ui/core/Box';

import { NodeResponse } from '../../../../api/types';
import { AccessControl } from '../../../../auth/components';
import { RoleNames } from '../../../../auth/roles';
import executeQuery from '../../../../lib/executeQuery';
import { formatText, handleValidation, timestampFormatter } from '../../../../lib/Utils';
import { parseNodeId, NodeID } from '../../../../lib/nodeIdentifier';
import { ILookupItem } from '../../../../store/types/app';
import getInspectionsForLookupQuery from "../../../../store/actions/graphQL/getInspectionsForLookup";
import { IDefect, ParentInspection } from '../../../../store/types';
import ControlHeader from '../../../../components/controlHeader/controlHeader'
import LabelledField, { LabelledFieldProps, SelectOption, asLookupItem } from '../../../../components/labelledField';
import NavButton from '../../../../components/navButton/navButton';
import FileUploader from '../../../../components/files/fileUploader';
import Tags from '../../../../components/tags';
import NotesTab from '../../../../components/notes/NotesTab';

import './defectControl.scss';

type InspectionItem = ParentInspection;

interface IDefectControlProps {
    readonly defectCancelReasons: ILookupItem[];
    readonly defectCloseReasons: ILookupItem[];
    readonly defectTypes: ILookupItem[];
    readonly selectedDefect: IDefect | undefined;
    readonly fileStoreToken: string;
    readonly hideNavButton?: boolean;
    readonly onCancelDefect?: (defectId: NodeID, cancelReason: SelectOption) => void;
    readonly onClearError?: () => void;
    readonly onClose?: () => void;
    readonly onCompleteDefect?: (defectId: NodeID, result: SelectOption, inspectionId?: NodeID, isResolved?: boolean) => void;
    readonly onCreateRepair?: () => void;
    readonly onShowError?: (message: string) => void;
    readonly onUpdateDefect?: (defectId: NodeID, dateReported: LocalDate, reportedBy: string, typeId: SelectOption, waterSourceId: NodeID, inspectionId?: SelectOption) => void;
}

interface IDefectContralStateData {
    readonly type: SelectOption | undefined;
    readonly reportedDate: LocalDate | undefined;
    readonly reportedBy: string | undefined;
    readonly inspection: SelectOption | undefined;
    readonly closeReason: SelectOption | undefined;
    readonly cancelReason: SelectOption | undefined;
    readonly tags: ILookupItem[];
}

interface IDefectControlState {
    readonly editing: boolean;
    readonly closeType: number;
    readonly shouldValidate: boolean;
    readonly inspections: InspectionItem[];
    readonly data: IDefectContralStateData;
}

const asOption = (inspection: InspectionItem, index: number): SelectOption => {
    const { inspectionNodeId, inspectionId, type } = inspection;
    return {
        value: inspectionNodeId,
        displayText: `${inspectionId}, ${type.displayText}`,
        enabled: true,
        sortOrder: index
    };
};

const defectCloseTypes: ILookupItem[] = [
    { value: 1, displayText: "Deferred", filters: [], sortOrder: 1, enabled: true },
    { value: 2, displayText: "Completed", filters: [], sortOrder: 2, enabled: true },
    { value: 3, displayText: "Cancelled", filters: [], sortOrder: 3, enabled: true }
];

const initState = (selectedDefect: IDefect | undefined): IDefectControlState => ({
    shouldValidate: false,
    editing: false,
    closeType: selectedDefect ? (selectedDefect.cancelReason ? 3 : selectedDefect.result ? 2 : 1) : 1,
    inspections: [],
    data: {
        type: selectedDefect ? selectedDefect.type : undefined,
        reportedDate: selectedDefect ? selectedDefect.dateReported : undefined,
        reportedBy: selectedDefect ? selectedDefect.reportedBy : "",
        inspection: selectedDefect?.parentInspection ? asOption(selectedDefect.parentInspection, 0) : undefined,
        closeReason: selectedDefect?.result,
        cancelReason: selectedDefect?.cancelReason,
        tags: selectedDefect?.tags && selectedDefect.tags.length > 0 ? selectedDefect.tags : [],
    }
})

class DefectControl extends Component<IDefectControlProps, IDefectControlState> {
    constructor(props: IDefectControlProps) {
        super(props);

        this.state = initState(props.selectedDefect);
    }

    public componentDidMount(): void {
        this.getInspections();
    }

    public componentDidUpdate(prevProps: IDefectControlProps): void {
        if (prevProps.selectedDefect?.defectNodeId !== this.props.selectedDefect?.defectNodeId) {
            this.setState(initState(this.props.selectedDefect));
        }
    }

    public componentWillUnmount(): void {
        this.props.onClearError?.();
    }

    public render(): JSX.Element {
        const handleNavClick = (): void => {
            this.props.onClose?.();
        };
        return (
            <div className="defectControlContainer">
                {!this.props.hideNavButton && <NavButton onClick={handleNavClick} text="Back: Defects" chevronDir="left" />}
                {this.renderDefectData()}
                <Box px="20px">
                    {this.renderNotes()}
                </Box>
            </div>
        );
    }

    private renderNotes(): JSX.Element {
        const { selectedDefect } = this.props;
        return selectedDefect
            ? <NotesTab objectType="DEFECT" objectNodeId={selectedDefect.defectNodeId} notes={selectedDefect.notes} />
            : <React.Fragment />;
    }

    private renderDefectData(): JSX.Element {
        const { selectedDefect, defectTypes } = this.props;
        if (selectedDefect) {
            const defectType = asLookupItem(this.state.data.type);
            return (
                <div className="defectControl">
                    <div className="action-buttons-container">
                        {this.renderActions()}
                    </div>
                    <ControlHeader
                        editing={this.state.editing}
                        primaryKey="defectId"
                        primary={selectedDefect.defectId.toString()}
                        editPrimary={false}
                        secondaryKey="type"
                        secondaryValue={defectType}
                        secondaryReadOnlyValue={defectType}
                        secondaryClass={handleValidation(this.state.shouldValidate, this.state.data.type)}
                        secondaryOptions={defectTypes}
                        onSecondaryChange={(key, value): void => this.updateState(key, value)}
                        tertiaryKey=""
                        tertiaryValue={undefined}
                    />
                    <Tags
                        id="defect-tags"
                        label="Tags"
                        tags={this.state.data.tags}
                        editing={this.state.editing}
                        ownerNodeId={parseNodeId(selectedDefect.defectNodeId)}
                    />
                    {this.renderLinkedInspection()}
                    {this.renderReportedBy()}
                    {this.renderReportedDate()}
                    {this.renderDefectLifecycleEvents()}
                    {this.renderDefectCloseResult()}
                    <FileUploader entity='DEFECT' entityId={selectedDefect.defectId.toString()} />
                </div>
            );
        }
        return <Fragment />;
    }

    private renderReportedBy(): JSX.Element {
        const reportedByProps: LabelledFieldProps = {
            fieldType: "text",
            id: "defect-reported-by",
            label: "Reported By *",
            value: this.state.data.reportedBy,
            editing: this.state.editing,
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.reportedBy) },
            onChange: (value): void => this.updateState("reportedBy", value)
        };
        return <LabelledField {...reportedByProps} />;
    }

    private renderReportedDate(): JSX.Element {
        const reportedByProps: LabelledFieldProps = {
            fieldType: "date",
            id: "defect-reported-date",
            label: "Reported On *",
            classes: { input: handleValidation(this.state.shouldValidate, this.state.data.reportedDate) },
            value: this.state.data.reportedDate,
            editing: this.state.editing,
            onChange: (value): void => this.updateState("reportedDate", value)
        };
        return <LabelledField {...reportedByProps} />;
    }

    private renderDefectLifecycleEvents(): JSX.Element[] | JSX.Element {
        const { selectedDefect } = this.props;
        if (selectedDefect?.events) {
            return selectedDefect.events.map((event, index) => {
                const eventLabel = formatText(event.action, true);
                const props: LabelledFieldProps = {
                    fieldType: "readonly",
                    id: `${selectedDefect.defectId}-${event.action}`,
                    label: eventLabel,
                    value: event.timestamp.format(timestampFormatter)
                };
                return <LabelledField key={index} {...props} />
            });
        }
        return <Fragment />;
    }

    private renderLinkedInspection(): JSX.Element {
        const { inspections } = this.state;
        if (inspections.length) {
            const props: LabelledFieldProps = {
                fieldType: "select",
                id: "inspection",
                label: "Inspection",
                editing: this.state.editing,
                selectOptions: inspections.map(asOption),
                value: this.state.data.inspection,
                onChange: item => this.updateState("inspection", item),
                onClear: () => this.updateState("inspection", undefined)
            }
            return <LabelledField {...props} />;
        }
        return <Fragment />;
    }

    private renderDefectCloseResult(): JSX.Element {
        const { selectedDefect } = this.props;
        const { editing } = this.state;
        if ((selectedDefect && !editing && !selectedDefect.isOpen) || (selectedDefect && editing && selectedDefect.isOpen)) {
            return (
                <Fragment>
                    {this.renderCloseType()}
                    {this.renderDefectResult()}
                    {this.renderDefectCancelReason()}
                </Fragment>
            );
        }
        return <Fragment />;
    }

    private renderCloseType(): JSX.Element {
        if (this.state.editing) {
            const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
                const closeType = Number(e.currentTarget.value);
                this.setState((prevState: IDefectControlState) => ({
                    closeType: closeType,
                    data: {
                        ...prevState.data,
                        cancelReason: undefined,
                        closeReason: undefined
                    }
                }));
            };
            return (
                <RadioGroup inline onChange={handleChange} selectedValue={this.state.closeType}>
                    {defectCloseTypes.map((item, index) => <Radio key={index} label={item.displayText} value={item.value} />)}
                </RadioGroup>
            );
        }
        return <Fragment />;
    }

    private renderDefectResult(): JSX.Element {
        const { selectedDefect } = this.props;
        if (selectedDefect && this.state.closeType === 2) {
            const defectResultProps: LabelledFieldProps = {
                fieldType: "select",
                id: "defect-result",
                label: "Defect Result",
                value: this.state.data.closeReason,
                selectOptions: this.props.defectCloseReasons,
                editing: this.state.editing,
                onChange: (value: SelectOption | undefined) => {
                    this.updateState("closeReason", value);
                },
                onClear: () => this.updateState("closeReason", undefined)
            }
            return <LabelledField {...defectResultProps} />;
        }
        return <Fragment />;
    }

    private renderDefectCancelReason(): JSX.Element {
        const { selectedDefect } = this.props;
        if (selectedDefect && this.state.closeType === 3) {
            const defectCancelReasonProps: LabelledFieldProps = {
                fieldType: "select",
                id: "defect-cancel-reason",
                label: "Defect Cancel Reason",
                value: this.state.data.cancelReason,
                selectOptions: this.props.defectCancelReasons,
                editing: this.state.editing,
                onChange: (value: SelectOption | undefined) => {
                    this.updateState("cancelReason", value)
                },
                onClear: () => this.updateState("cancelReason", undefined)
            }
            return <LabelledField {...defectCancelReasonProps} />;
        }
        return <Fragment />;
    }

    private renderActions(): JSX.Element {
        const { selectedDefect } = this.props
        if (this.state.editing) {
            const handleCancel = (): void => this.setState({
                editing: false,
                shouldValidate: false,
                closeType: 0
            });
            return (
                <React.Fragment>
                    <button id="save-button" className="action-button" onClick={this.handleSave}>
                        Save
                    </button>
                    <button id="cancel-button" className="action-button action-button--cancel" onClick={handleCancel}>
                        Cancel
                    </button>
                </React.Fragment>
            );
        }

        const handleEditClick: (() => void) = () => {
            this.setState({ editing: true });
        };
        const handleRepairClick: (() => void) = () => {
            this.props.onCreateRepair?.();
        };
        return (
            <React.Fragment>
                <AccessControl role={RoleNames.DEFECTS_ALL}>
                    <button id="edit-button" className="action-button" disabled={selectedDefect ? !selectedDefect.isOpen : false} onClick={handleEditClick}>
                        Edit
                    </button>
                </AccessControl>
                <AccessControl role={RoleNames.REPAIRS_ALL}>
                    <button className="action-button" disabled={selectedDefect ? !selectedDefect.isOpen : false} onClick={handleRepairClick}>
                        Create Repair
                    </button>
                </AccessControl>
            </React.Fragment>
        );
    }

    private updateState = (key: string, value: string | number | SelectOption | LocalDate | boolean | undefined): void => {
        const newState: IDefectControlState = {
            ...this.state,
            data: {
                ...this.state.data
            }
        };
        newState.data[key] = value;
        this.setState(newState);
    };

    private getInspections = (): void => {
        if (this.props.selectedDefect?.waterSource) {
            const { waterSource } = this.props.selectedDefect;
            const variables = { id: waterSource.waterSourceNodeId };
            type Response = NodeResponse<{
                readonly inspections: InspectionItem[]
            }>;
            executeQuery<Response>(getInspectionsForLookupQuery, variables)
                .then(response => response?.node)
                .then(node => node?.inspections)
                .then(inspections => {
                    this.setState({
                        inspections: inspections ?? []
                    });
                })
                .catch(reason => console.warn(reason));
        }
    };

    private handleSave = (): void => {
        const { reportedDate, reportedBy, type } = this.state.data;
        if (reportedDate && reportedBy && type) {
            this.props.onClearError?.();

            if (this.props.selectedDefect) {
                const defectId = parseNodeId(this.props.selectedDefect.defectNodeId);

                this.setState({ shouldValidate: true })
                this.props.onUpdateDefect?.(
                    defectId,
                    reportedDate,
                    reportedBy,
                    type,
                    parseNodeId(this.props.selectedDefect.waterSource.waterSourceNodeId),
                    this.state.data.inspection
                );

                const { closeType } = this.state;
                if (closeType === 2 && this.state.data.closeReason) {
                    this.props.onCompleteDefect?.(defectId, this.state.data.closeReason);
                }

                if (closeType === 3 && this.state.data.cancelReason) {
                    this.props.onCancelDefect?.(defectId, this.state.data.cancelReason);
                }

                this.setState({ editing: false });
            }
        } else {
            this.props.onShowError?.("Please complete all required fields.");
        }
    }
}

export type { IDefectControlProps };
export default DefectControl;