export default `
mutation createEWS_Mutation($input: AddCreateWaterSourceInput!) {
    waterSource {
        create {
            emergencyWaterSupply(input: $input) {
                clientMutationId
                hasErrors
                errors {
                    path
                    message
                }
                waterSource {
                    ... on EmergencyWaterSupply {
                        waterSourceNodeId: id
                        waterSourceId: externalId
                        category {
                            value
                            displayText
                        }
                        classification {
                            value
                            displayText
                        }
                        status {
                            displayText
                            value
                        }
                        isActive
                        isDefective {
                            value
                            isOverridden
                        }
                        isOperable {
                            value
                            isOverridden
                        }
                        station {
                            displayText
                            value
                        }
                        location {
                            address {
                                addressNodeId: id
                                addressId
                                saon {
                                    description
                                    startRange {
                                        number
                                        suffix
                                    }
                                    endRange {
                                        number
                                        suffix
                                    }
                                }
                                paon {
                                    description
                                    startRange {
                                        number
                                        suffix
                                    }
                                    endRange {
                                        number
                                        suffix
                                    }
                                }
                                streetDescription
                                locality
                                town
                                postCode
                                administrativeArea
                                usrn
                                uprn
                                coordinates {
                                    x
                                    y
                                }
                            }
                            coordinates {
                                x
                                y
                            }
                            description
                        }
                        inspectionFrequency
                        lastInspectionDate
                        nextInspectionDate           
                        recommendedInspectionDate
                        riskSeverity {
                            displayText
                            value                
                        }
                        hazardSeverity {
                            displayText
                            value
                        }
                        hazards {
                            hazardNodeId: id
                            hazardId
                            hazardType {
                                value
                                displayText
                            }
                        }
                        notes {
                            noteNodeId: id
                            noteId
                            timestamp
                            author
                            content
                        }
                        route {
                            routeNodeId: id
                            routeId
                            name
                            isActive
                        }
                        schemes {
                            schemeNodeId: id
                            schemeId
                            reference
                            title
                            description
                            station {
                                displayText
                                value
                            }
                            stage {
                                displayText
                                value
                                isOpen
                            }
                        }
                        accessDescription
                        capacity
                        isHardstanding
                        isSeasonal
                        lift
                        owner
                        pumpCarryDistances
                        pumpCount
                        pumpTypes {
                            value
                            displayText
                            sortOrder
                            enabled
                        }
                    }
                }
            }
        }
    }
}`