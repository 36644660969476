export default `mutation closeDefect_Mutation($defect: EditCloseDefectInput!) {
    closeDefect(input:$defect) {
        clientMutationId
        defect {
            defectNodeId: id
            defectId
            type {
                displayText
                value
            }
            cancelReason {
                displayText
                value
            }
            closeReason {
                displayText
                value
            }
            dateReported
            reportedBy
            isOpen
            isResolved
            lastEvent {
                timestamp
                action
                actor
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
            parentInspection {
                id
            }
            waterSource {
                waterSourceNodeId: id
                isDefective {
                    value
                    isOverridden
                }
                isOperable {
                    value
                    isOverridden
                }
            }
            events {
                timestamp
                action
                actor
            }
        }
        hasErrors
        errors {
            message
            path
        }
    }
}`;