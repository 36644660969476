import React from "react";
import { useDispatch } from "react-redux";
import { useRoleChecks } from "../../../auth/hooks";
import { parseNodeId } from "../../../lib/nodeIdentifier";

import { createNote, editNote, deleteNote } from "../../../store/actions/notes";
import { ObjectType } from "../../../store/types";
import { useRefresh, RefreshTarget } from "../../appWindowContext";
import NotesTabBase, { INotesTabProps } from "./NotesTab";

type SaveNoteHandler = Pick<INotesTabProps, "onSaveNote">["onSaveNote"];

const toRefreshTarget = (objectType: ObjectType): RefreshTarget => {
    switch (objectType) {
        case "WATER_SOURCE":
            return "waterSource"
        case "INSPECTION":
            return "inspection"
        case "DEFECT":
            return "defect"
        case "REPAIR":
            return "repair";
        case "SCHEME":
            return "scheme"
        case "ROUTE":
            return "route";
        default:
            throw new Error(`Unknown object type '${objectType}'.`);
    }
};

const NotesTab = (props: Omit<INotesTabProps, "onSaveNote" | "onEditNote" | "checkAccess">): JSX.Element => {
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const { has: checkAccess } = useRoleChecks();

    const handleSaveNote: SaveNoteHandler = async (objectType, objectId, content): Promise<void> => {
        const action = createNote(objectId, content);
        const target = toRefreshTarget(objectType);
        await action(dispatch);
        await refresh(target)();
    };

    const handleDeleteNote = async (parentType: ObjectType, noteNodeId: string): Promise<void> => {
        const action = deleteNote(parseNodeId(noteNodeId));
        const target = toRefreshTarget(parentType);
        await action(dispatch);
        await refresh(target)();
    };

    const handleEditNote = async (objectType: ObjectType, objectId: string, content: string): Promise<void> => {
        const action = editNote(parseNodeId(objectId), parseNodeId(props.objectNodeId), content);
        const target = toRefreshTarget(objectType);
        await action(dispatch);
        await refresh(target)();
    };

    return <NotesTabBase onSaveNote={handleSaveNote} checkAccess={checkAccess} onDeleteNote={handleDeleteNote} onEditNote={handleEditNote} {...props} />;
};

export default NotesTab;