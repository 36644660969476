import React from 'react';

import { Radio, RadioGroup } from '@blueprintjs/core';
import Typography from '@material-ui/core/Typography';

import LabelledField from '../../../components/labelledField';
import { ILookupItem } from '../../../store/types';

interface IOrganisationInfoProps {
    readonly organisation: ILookupItem;
    readonly organisationTypes: ILookupItem[];
    readonly onShortNameChange?: (value: string) => void;
    readonly onTypeChange?: (lookupValue: number) => void;
}

const OrganisationInfo = ({ organisation, organisationTypes, onShortNameChange, onTypeChange }: IOrganisationInfoProps): JSX.Element => {
    const handleRadioChange = (e: React.FormEvent<HTMLInputElement>): void => onTypeChange?.(parseInt(e.currentTarget.value))

    return (
        <div>
            <Typography variant="body1">Organisation Short Name</Typography>
            <LabelledField
                editing
                fieldType="text"
                value={organisation.symbolName}
                id="organisationShortText"
                label="Organisation Short Name"
                labelPosition="HIDDEN"
                onChange={(value): void => onShortNameChange?.(value ?? '')}
            />
            <RadioGroup inline onChange={handleRadioChange} selectedValue={organisation.groupingId}>
                {organisationTypes.filter(org => org.enabled).map(org => <Radio key={org.value} label={org.displayText} value={org.value} />)}
            </RadioGroup>
        </div>
    );
};

export default OrganisationInfo;
