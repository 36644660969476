export default `
fragment WaterSourceFragment on IWaterSource {
    waterSourceNodeId: id
    waterSourceId: externalId
    category {
        value
        displayText
    }
    classification {
      value
      displayText
    }
    location {
      coordinates {
          x
          y
      }
    }
    isActive
    isOperable {
      value
      isOverridden
    }
    isDefective {
      value
      isOverridden
    }
    status {
      value
      displayText
    }
    riskSeverity {
      displayText
      value
    }
    lastInspectionDate
    nextInspectionDate
    recommendedInspectionDate
    routeSortOrder
    inspectionCount(includeClosed:false)
    inspections {
      inspectionNodeId: id
      inspectionId
      type {
        displayText
        value
      }
      isOpen
      events {
        action
        actor
        timestamp
      }
      lastEvent {
        timestamp
        action
        actor
    }
  }
    ... on ITagged {
        tags {
            value
            displayText
            enabled
        }
    }
}
query getWaterSourcesByFilterQuery($filter:QueryFilter, $paging: QueryControlInput) {
    waterSources(queryFilter: $filter, queryControl: $paging) {
      edges {
        index
        node {
            ...WaterSourceFragment
        }        
      }
      items {
          ...WaterSourceFragment
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        isFirstPage
        isLastPage
        pageCount
        pageNumber
        pageSize
        sortBy
        sortDirection
      }
      totalCount
    }
}`;