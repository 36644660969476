import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import NearMeView from "../../../views/nearMeView";
import NearMeWaterSourceView from "../../../views/nearMeWaterSourceView";

const NearMeRouter = (): JSX.Element => {
    const { path } = useRouteMatch() ?? {};

    return (
        <Switch>
            <Route path={`${path}/water-source/:waterSourceNodeId`}>
                <NearMeWaterSourceView />
            </Route>
            <Route path={`${path}`} exact>
                <NearMeView />
            </Route>
        </Switch>
    );
};

export default NearMeRouter;