import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setGlobalToast } from '../../../store/actions/app';
import { IReduxState } from '../../../store/types';
import AssignWaterSourceToSchemeDialog, { IAssignWaterSourceToSchemeDialogProps } from './AssignWaterSourceToSchemeDialog';


type ConnectedProps = Pick<IAssignWaterSourceToSchemeDialogProps, "selectedWaterSource">;

const mapStateToProps = (state: IReduxState): ConnectedProps => ({
    selectedWaterSource: state.waterSources.selectedWaterSource
});

type ConnectedActions = Pick<IAssignWaterSourceToSchemeDialogProps, "onValidationError">;

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): ConnectedActions => ({
    onValidationError: (message): void => {
        dispatch(setGlobalToast({
            type: 'ERROR',
            message,
            showToast: true
        }));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignWaterSourceToSchemeDialog);

