import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { RolesPermissionsAdmin, UserAdmin } from '@3tc/shared-components';

import { AccessControl } from "../../../auth/components";
import { RoleNames } from "../../../auth/roles";

const AdminRouter = (): JSX.Element => {
    const { path } = useRouteMatch() ?? {};
    return (
        <Switch>
            <Route path={`${path}/users`}>
                <AccessControl role={RoleNames.ADMIN_USERS}>
                    <UserAdmin appName="Water Management" />
                </AccessControl>
            </Route>
            <Route path={`${path}/rolespermissions`}>
                <AccessControl role={RoleNames.ADMIN_ROLES}>
                    <RolesPermissionsAdmin />
                </AccessControl>
            </Route>
        </Switch>
    );
};

export default AdminRouter;