import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, TextProps } from "../types";

const WaterSourceLocationDescription = ({ editing, value, onChange }: PropsWithEditing<TextProps>): JSX.Element => {
    const props: LabelledFieldProps = {
        id: "water-source-location-description",
        fieldType: "text",
        label: "Location Description",
        classes: { container: 'input__group input__group_side-panel', label: 'input__label' },
        value,
        editing: editing ?? false,
        onChange: value => onChange?.(value)
    };
    return <LabelledField {...props} />;
};

export default WaterSourceLocationDescription;