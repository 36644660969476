import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";

import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

import { SearchInputProps } from "./types";

const useStyles = makeStyles(theme => createStyles({
    root: {
        padding: 0,
        display: "flex",
        alignItems: "center",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        border: "3px dashed transparent",
        color: theme.palette.common.white,
        minHeight: theme.spacing(6),
    },
    inputInput: {
        padding: theme.spacing(1.5)
    },
    iconButton: {
        padding: 10,
    },
    search: {
        color: theme.palette.primary.contrastText,
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    clear: {
        color: theme.palette.primary.dark,
        "&:hover": {
            color: theme.palette.grey[500]
        }
    },
    primaryButton: {
        marginRight: 0
    }
}));

const DestkopSearchInput = ({ searchText, enabled, placeholder, onClear, onSearch, onTextChange }: SearchInputProps): JSX.Element => {
    const styles = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onTextChange?.(event.target.value);
    };
    
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === "Enter") {
            onSearch?.();
        }
    };

    const actionEnabled = enabled && (searchText.trim() !== "");
    return (
        <Box display="flex" flexDirection="row">
            <Paper className={styles.root} elevation={0} variant="outlined" square onKeyPress={handleKeyPress}>
                <IconButton disabled={!actionEnabled} className={cx(styles.iconButton, styles.search)} onClick={onSearch} title="Search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    disabled={!enabled}
                    value={searchText}
                    classes={{ root: styles.input, input: styles.inputInput }}
                    fullWidth
                    placeholder={placeholder}
                    onChange={handleChange}
                />
                <IconButton disabled={!enabled} className={cx(styles.iconButton, styles.clear)} onClick={onClear} title="Clear">
                    <ClearIcon />
                </IconButton>
            </Paper>
            <button type="button" disabled={!actionEnabled} className={cx("action-button", styles.primaryButton)} title="Search for an address." onClick={onSearch}>Search</button>
        </Box>
    );
};

export type { SearchInputProps }
export default DestkopSearchInput;