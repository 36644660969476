import React from "react";
import { styled } from "@material-ui/core/styles";
import Typography, { TypographyTypeMap } from "@material-ui/core/Typography";

type TypographyProps = TypographyTypeMap["props"];

type SectionHeaderProps = Omit<TypographyProps, "variant">

const SectionHeaderContent = styled((props: SectionHeaderProps): JSX.Element => (
    <Typography variant="subtitle2" {...props} />
))(({ theme }) => ({
    color: theme.palette.grey[400]
}));

const SectionHeader = styled((props: SectionHeaderProps): JSX.Element => (
    <SectionHeaderContent {...props} />
))(({ theme }) => ({
    marginBottom: theme.spacing(1)
}));

export type { SectionHeaderProps };
export { SectionHeaderContent };
export default SectionHeader;