import React from "react";

import CategoryIcon, { CategoryIconProps } from "./categoryIcon";

const Ews = (props: CategoryIconProps): JSX.Element => (
    <CategoryIcon title="EMERGENCY WATER SUPPLY" className={props.className} width={props.width ?? 24} height={props.height ?? 24}>
        <g id="background">
            <rect
                id="rect828"
                x="0"
                y="0"
                width="24"
                height="24"
                fill="#ffcf21"
                fillOpacity="1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="4"
                strokeDasharray="none"
                strokeOpacity="1"
                paintOrder="normal"
            />
        </g>
        <g id="border">
            <rect
                id="rect4538"
                x="0"
                y="0"
                width="24"
                height="24"
                fill="none"
                fillOpacity="1"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="4"
                strokeDasharray="none"
                strokeOpacity="1"
            />
        </g>
        <g id="text">
            <text
                x="4"
                y="11"
                id="text4555"
                fontWeight="bold"
                fontSize="7pt"
                fontFamily="Arial">E</text >
            <text
                x="12"
                y="11"
                id="text4547"
                fontWeight="bold"
                fontSize="7pt"
                fontFamily="Arial">S</text>
            <text
                x="7"
                y="20"
                id="text4559"
                fontWeight="bold"
                fontSize="7pt"
                fontFamily="Arial">W</text >
        </g >
    </CategoryIcon >
);

export default React.memo(Ews);