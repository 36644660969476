import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { SectionHeaderContent } from "../SectionHeader";
import AddressDetails, { AddressDetailsOptions } from "./AddressDetails";
import AddressEditor from "./AddressEditor";
import { AddressDetailsData, AddressField } from "./types";

interface AddressProps extends AddressField {
    readonly id?: string;
    readonly address: AddressDetailsData | undefined;
    readonly hidden?: AddressDetailsOptions;
    readonly showHeader?: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    header: {
        marginRight: theme.spacing(1)
    }
}));

const Address = (props: AddressProps): JSX.Element => {
    const styles = useStyles();
    const { address, owner, editing, hidden, showHeader, onClear, onSelect, onUpdate } = props;
    return (
        <Box>
            {showHeader && <SectionHeaderContent className={styles.header}>Address</SectionHeaderContent>}
            <AddressEditor initialAddress={address} owner={owner} editing={editing} onClear={onClear} onSelect={onSelect} onUpdate={onUpdate} />
            <AddressDetails address={address} hidden={hidden} />
        </Box>
    );
};

const KeyedAddress = (props: AddressProps): JSX.Element => {
    // setting the key prop forces the Address component and all children to be re-rendered when the address id changes
    return <Address key={props.address?.addressNodeId} {...props} />;
};

export type { AddressProps, AddressDetailsData, AddressDetailsOptions };
export type { AddressDetailsKeys, AddressDetailsTypes } from "./types";
export { toAddressDetails, toAddressNode } from "./types";
export { isSavedAddress } from "./utils";
export default KeyedAddress;