import React from "react";
import { useDispatch } from "react-redux";

import { guid } from "../../../lib/Utils";
import { startCompleteRepair } from "../../../store/actions/repairs";
import { ICompleteRepairInput } from "../../../store/types";
import { useRepair } from "../../appWindowContext";
import CloseRepairDialogBase, { CloseRepairDialogProps } from "./CloseRepairDialog.view";

const CloseRepairDialog = (props: Pick<CloseRepairDialogProps, "isOpen" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const { instance: repair, refresh: refreshRepair } = useRepair();
    if (repair) {
        const combined: CloseRepairDialogProps = {
            ...props,
            repair,
            onCompleteRepair: async (): Promise<void> => {
                if (repair === undefined) {
                    return;
                }

                const variables: ICompleteRepairInput = {
                    input: {
                        clientMutationId: guid(),
                        nodeId: repair.repairNodeId
                    }
                };
                const action = startCompleteRepair(variables);
                await action(dispatch);
                await refreshRepair();
            }
        };
        return <CloseRepairDialogBase {...combined} />;
    }
    return <React.Fragment />;
};

export default CloseRepairDialog;
