import React from 'react';
import { LocalDate } from '@js-joda/core';

import Box from '@material-ui/core/Box';

import { AccessControl } from '../../../../auth/components';
import { RoleNames } from '../../../../auth/roles';
import { NodeID, encodeNodeId, parseNodeId } from '../../../../lib/nodeIdentifier';
import { formatCurrency, formatText, parseCurrency, timestampFormatter } from '../../../../lib/Utils';
import { Repair, ILookupItem, IDefect, IInspection, RepairMutationData, isExternalRepair } from '../../../../store/types';
import ControlHeader from '../../../../components/controlHeader/controlHeader';
import FileUploader from '../../../../components/files/fileUploader';
import LabelledField, { LabelledFieldProps, SelectOption } from '../../../../components/labelledField';
import NavButton from '../../../../components/navButton/navButton';
import NotesTab from '../../../../components/notes/NotesTab';
import styles from './repairControl.module.scss';

interface IRepairControlProps {
    readonly repairTypes: ILookupItem[];
    readonly repairVendors: ILookupItem[];
    readonly openDefects: IDefect[];
    readonly openInspections: IInspection[];
    readonly selectedRepair?: Repair;
    readonly fileStoreToken: string;
    readonly hideNavButton?: boolean;
    readonly onClose?: () => void;
    readonly onUpdateRepair?: (repairId: NodeID, update: RepairMutationData) => void;
}

interface RepairControlStateData {
    readonly selectedDefect: SelectOption | undefined;
    readonly selectedInspection: SelectOption | undefined;
    readonly repairType: SelectOption | undefined;
    readonly workOrderRef: string | undefined;
    readonly expectedCost: number | undefined;
    readonly actualCost: number | undefined;
    readonly costSaving: number | undefined;
    readonly repairVendor: SelectOption | undefined;
    readonly isPaymentAuthorised: boolean;
    readonly dateWorkCompleted: LocalDate | undefined;
    readonly invoiceRef: string | undefined;
    readonly dateInvoiced: LocalDate | undefined;
    readonly purchaseOrderRef: string | undefined;
    readonly datePaid: LocalDate | undefined;
    readonly dateGuaranteeExpires: LocalDate | undefined;
}

interface IRepairControlState {
    readonly editing: boolean;
    readonly data: RepairControlStateData;
}

const toDefectOption = (source: Pick<IDefect, "defectId" | "type">, index: number): SelectOption => ({
    value: source.defectId,
    displayText: `${source.defectId} (${source.type.displayText})`,
    enabled: true,
    sortOrder: index
});

const toInspectionOption = (source: Pick<IInspection, "inspectionId" | "type">, index: number): SelectOption => ({
    value: source.inspectionId,
    displayText: `${source.inspectionId} (${source.type.displayText})`,
    enabled: true,
    sortOrder: index
});


const initData = (selectedRepair: Repair | undefined, openDefects: IDefect[], openInspections: IInspection[]): RepairControlStateData => ({
    selectedDefect: selectedRepair?.defect
        ? toDefectOption(selectedRepair.defect, openDefects.findIndex(d => d.defectNodeId === selectedRepair.defect?.defectNodeId))
        : undefined,
    selectedInspection: selectedRepair?.inspection
        ? toInspectionOption(selectedRepair.inspection, openInspections.findIndex(i => i.inspectionNodeId === selectedRepair.inspection?.inspectionNodeId))
        : undefined,
    repairType: selectedRepair?.repairType,
    repairVendor: selectedRepair?.repairVendor,
    expectedCost: selectedRepair?.expectedCost,
    actualCost: selectedRepair?.actualCost,
    costSaving: selectedRepair?.costSaving,
    isPaymentAuthorised: selectedRepair?.isPaymentAuthorised ?? false,
    workOrderRef: selectedRepair?.workOrderRef,
    purchaseOrderRef: selectedRepair?.purchaseOrderRef,
    invoiceRef: selectedRepair?.invoiceRef,
    dateWorkCompleted: selectedRepair?.dateWorkCompleted,
    dateInvoiced: selectedRepair?.dateInvoiced,
    datePaid: selectedRepair?.datePaid,
    dateGuaranteeExpires: selectedRepair?.dateGuaranteeExpires
});

const initState = (props: IRepairControlProps): IRepairControlState => ({
    editing: false,
    data: initData(props.selectedRepair, props.openDefects, props.openInspections)
});
export class RepairControl extends React.Component<IRepairControlProps, IRepairControlState> {
    constructor(props: IRepairControlProps) {
        super(props)

        this.state = initState(props);

        this.updateState = this.updateState.bind(this);
        this.resetData = this.resetData.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    public componentDidUpdate(prevProps: IRepairControlProps): void {
        if (prevProps.selectedRepair?.repairNodeId !== this.props.selectedRepair?.repairNodeId) {
            this.setState(initState(this.props));
        }
    }

    public render(): JSX.Element {
        const handleNavClick = (): void => {
            this.props.onClose?.();
        };
        return (
            <div className={styles.repairControlContainer}>
                {!this.props.hideNavButton && <NavButton onClick={handleNavClick} text="Back: Repairs" chevronDir="left" />}
                <div className={styles.repairControl}>
                    <div className={styles.actionsContainer}>
                        {this.renderActions()}
                    </div>
                    {this.renderRepairData()}
                    <Box px="20px">
                        {this.renderNotes()}
                    </Box>
                </div>
            </div>
        );
    }

    private renderNotes(): JSX.Element {
        const { selectedRepair } = this.props;
        return selectedRepair
            ? <NotesTab objectType="REPAIR" objectNodeId={selectedRepair.repairNodeId} notes={selectedRepair.notes} />
            : <React.Fragment />;
    }

    private renderRepairData(): JSX.Element {
        const { selectedRepair } = this.props;
        if (selectedRepair) {
            const isExternal = isExternalRepair(selectedRepair);
            return (
                <div className={styles.repairData}>
                    <ControlHeader
                        editing={this.state.editing}
                        primary={selectedRepair.repairId.toString()}
                        primaryKey="repairId"
                        editPrimary={false}
                        secondaryKey="repairCategory"
                        secondaryReadOnlyValue={selectedRepair.repairCategory}
                        tertiaryKey=""
                    />
                    {this.renderDefect()}
                    {this.renderInspection()}
                    {this.renderRepairType()}
                    {/* external repairs only */}
                    {isExternal && this.renderWorkOrderRef()}
                    {isExternal && this.renderExpectedCost()}
                    {isExternal && this.renderActualCost()}
                    {isExternal && this.renderCostSaving()}
                    {isExternal && this.renderRepairVendor()}
                    {isExternal && this.renderAgreeToPay()}
                    {isExternal && this.renderDateWorkCompleted()}
                    {isExternal && this.renderInvoiceRef()}
                    {isExternal && this.renderDateInvoiced()}
                    {isExternal && this.renderPurchaseOrderRef()}
                    {isExternal && this.renderDatePaid()}
                    {isExternal && this.renderDateGuaranteeExpires()}
                    {/* end */}
                    {this.renderLifecycleEvents()}
                    <FileUploader entity='REPAIR' entityId={selectedRepair.repairId.toString()} />
                </div>
            );
        }
        return <React.Fragment />;
    }

    private renderDefect(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "select",
            id: "repair-defect-id",
            label: "Defect",
            value: this.state.data.selectedDefect,
            selectOptions: this.props.openDefects.map(toDefectOption),
            editing: this.state.editing,
            onChange: value => this.updateState("selectedDefect", value),
            onClear: () => this.updateState("selectedDefect", undefined)
        };
        return <LabelledField {...props} />;
    }

    private renderInspection(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "select",
            id: "repair-inspection-id",
            label: "Inspection",
            value: this.state.data.selectedInspection,
            selectOptions: this.props.openInspections.map(toInspectionOption),
            editing: this.state.editing,
            onChange: value => this.updateState("selectedInspection", value),
            onClear: () => this.updateState("selectedInspection", undefined)
        };
        return <LabelledField {...props} />;
    }

    private renderRepairType(): JSX.Element {
        const filterType = (type: ILookupItem): boolean => {
            return type.groupingId === this.props.selectedRepair?.repairCategory.value;
        };
        const props: LabelledFieldProps = {
            fieldType: "select",
            id: "repair-type-id",
            label: "Repair Type",
            value: this.state.data.repairType,
            selectOptions: this.props.repairTypes.filter(filterType),
            editing: this.state.editing,
            onChange: (value) => this.updateState("repairType", value),
            onClear: () => this.updateState("repairType", undefined)
        };
        return <LabelledField {...props} />;
    }

    private renderWorkOrderRef(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "text",
            id: "repair-workOrder-ref",
            label: "Work Order Reference",
            value: this.state.data.workOrderRef,
            editing: this.state.editing,
            onChange: (value) => this.updateState("workOrderRef", value)
        };
        return <LabelledField {...props} />
    }

    private renderExpectedCost(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "text",
            id: "repair-expected-cost",
            label: "Expected Cost",
            value: this.state.data.expectedCost ? formatCurrency(this.state.data.expectedCost) : "",
            editing: this.state.editing,
            onChange: (value) => this.updateState("expectedCost", value ? parseCurrency(value) : undefined)
        };
        return <LabelledField {...props} />;
    }

    private renderActualCost(): JSX.Element {
        const invoicedCostProps: LabelledFieldProps = {
            fieldType: "text",
            id: "repair-actual-cost",
            label: "Actual Cost",
            value: this.state.data.actualCost ? formatCurrency(this.state.data.actualCost) : "",
            editing: this.state.editing,
            onChange: (value) => this.updateState("actualCost", value ? parseCurrency(value) : undefined)
        }
        return <LabelledField {...invoicedCostProps} />;
    }

    private renderCostSaving(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "text",
            id: "repair-costSaving",
            label: "Cost Savings",
            value: this.state.data.costSaving ? formatCurrency(this.state.data.costSaving) : "",
            editing: this.state.editing,
            onChange: (value) => this.updateState("costSaving", value ? parseCurrency(value) : undefined)
        }
        return <LabelledField {...props} />;
    }

    private renderRepairVendor(): JSX.Element {
        const vendorProps: LabelledFieldProps = {
            fieldType: "select",
            id: "repair-vendor-id",
            label: "Repair Vendor",
            value: this.state.data.repairVendor,
            selectOptions: this.props.repairVendors,
            editing: this.state.editing,
            onChange: (value) => this.updateState("repairVendor", value),
            onClear: () => this.updateState("repairVendor", undefined)
        };
        return <LabelledField {...vendorProps} />;
    }

    private renderAgreeToPay(): JSX.Element {
        const isPaymentAuthorisedProps: LabelledFieldProps = {
            fieldType: "checkbox",
            id: "repair-payment-authorised",
            label: "Payment Authorised?",
            value: this.state.data.isPaymentAuthorised,
            editing: this.state.editing,
            onChange: (value) => this.updateState("isPaymentAuthorised", value)
        };
        return <LabelledField {...isPaymentAuthorisedProps} />;
    }

    private renderDateWorkCompleted(): JSX.Element {
        const dateWorkCompletedProps: LabelledFieldProps = {
            fieldType: "date",
            id: "repair-date-work-completed",
            label: "Date Work Completed",
            value: this.state.data.dateWorkCompleted,
            editing: this.state.editing,
            onChange: (value) => this.updateState("dateWorkCompleted", value),
        };
        return <LabelledField {...dateWorkCompletedProps} fieldType="date" />;
    }

    private renderInvoiceRef(): JSX.Element {
        const invoiceReferenceProps: LabelledFieldProps = {
            fieldType: "text",
            id: "repair-invoice-ref",
            label: "Invoice Reference",
            value: this.state.data.invoiceRef,
            editing: this.state.editing,
            onChange: (value) => this.updateState("invoiceRef", value),
        }
        return <LabelledField {...invoiceReferenceProps} />;
    }

    private renderDateInvoiced(): JSX.Element {
        const dateInvoicedProps: LabelledFieldProps = {
            fieldType: "date",
            id: "repair-date-invoiced",
            label: "Date Invoiced",
            value: this.state.data.dateInvoiced,
            editing: this.state.editing,
            onChange: (value) => this.updateState("dateInvoiced", value),
        };
        return <LabelledField {...dateInvoicedProps} />;
    }

    private renderPurchaseOrderRef(): JSX.Element {
        const purchaseOrderRefProps: LabelledFieldProps = {
            fieldType: "text",
            id: "repair-purchase-order-ref",
            label: "Purchase Order Reference",
            value: this.state.data.purchaseOrderRef,
            editing: this.state.editing,
            onChange: (value) => this.updateState("purchaseOrderRef", value),
        };
        return <LabelledField {...purchaseOrderRefProps} />;
    }

    private renderDatePaid(): JSX.Element {
        const datePaidProps: LabelledFieldProps = {
            fieldType: "date",
            id: "repair-date-paid",
            label: "Date Paid",
            value: this.state.data.datePaid,
            editing: this.state.editing,
            onChange: (value) => this.updateState("datePaid", value)
        };
        return <LabelledField {...datePaidProps} />;
    }

    private renderDateGuaranteeExpires(): JSX.Element {
        const props: LabelledFieldProps = {
            fieldType: "date",
            id: "repair-dateGuaranteeExpires",
            label: "Date Guaranteed Until",
            value: this.state.data.dateGuaranteeExpires,
            editing: this.state.editing,
            onChange: (value) => this.updateState("dateGuaranteeExpires", value)
        };
        return <LabelledField {...props} />;
    }

    private renderLifecycleEvents(): JSX.Element[] | JSX.Element {
        const { selectedRepair } = this.props;
        if (selectedRepair?.events && selectedRepair.events instanceof Array) {
            return selectedRepair.events.map((event, index) => {
                const eventLabel = formatText(event.action, true);
                const props: LabelledFieldProps = {
                    fieldType: "readonly",
                    id: `${selectedRepair.repairId}-${event.timestamp}`,
                    label: eventLabel,
                    value: event.timestamp.format(timestampFormatter)
                };
                return <LabelledField key={index} {...props} />;
            });
        }
        return <React.Fragment />;
    }

    private renderActions(): JSX.Element {
        if (this.state.editing) {
            const handleCancel = (): void => {
                this.setState({ editing: false })
                this.resetData()
            };
            return (
                <React.Fragment>
                    <button id="save-button" className="action-button" onClick={(): void => this.handleSave()}>Save</button>
                    <button id="cancel-button" className="action-button action-button--cancel" onClick={handleCancel}>Cancel</button>
                </React.Fragment>
            );
        }

        const handleEdit = (): void => {
            this.setState({ editing: true });
        };
        return (
            <AccessControl role={RoleNames.REPAIRS_ALL}>
                <button id="edit-button" className="action-button" onClick={handleEdit}>
                    Edit
                </button>
            </AccessControl>
        );
    }

    private updateState(key: keyof RepairControlStateData, value: string | number | SelectOption | LocalDate | boolean | undefined): void {
        this.setState(state => ({
            ...state,
            data: {
                ...state.data,
                [key]: value
            }
        }));
    }

    private resetData(): void {
        this.setState((state, props) => ({
            ...state,
            data: initData(props.selectedRepair, props.openDefects, props.openInspections)
        }));
    }

    private handleSave(): void {
        function valueOrNull<T>(value: T | undefined): T | null {
            return value ? value : null;
        }

        const { selectedRepair } = this.props;
        if (selectedRepair) {
            const { data } = this.state;
            const defectId = data.selectedDefect?.value;
            const inspectionId = data.selectedInspection?.value;
            const variables: RepairMutationData = {
                waterSourceId: selectedRepair.waterSource.waterSourceNodeId,
                defectId: defectId ? encodeNodeId(NodeID("Defect", defectId)) : undefined,
                inspectionId: inspectionId ? encodeNodeId(NodeID("Inspection", inspectionId)) : undefined,
                repairTypeId: data.repairType ? Number(data.repairType.value) : null,
                expectedCost: valueOrNull(data.expectedCost),
                actualCost: valueOrNull(data.actualCost),
                costSaving: valueOrNull(data.costSaving),
                repairVendorId: data.repairVendor ? Number(data.repairVendor.value) : null,
                workOrderRef: valueOrNull(data.workOrderRef),
                isPaymentAuthorised: data.isPaymentAuthorised,
                dateWorkCompleted: valueOrNull(data.dateWorkCompleted),
                invoiceRef: valueOrNull(data.invoiceRef),
                dateInvoiced: valueOrNull(data.dateInvoiced),
                purchaseOrderRef: valueOrNull(data.purchaseOrderRef),
                datePaid: valueOrNull(data.datePaid),
                dateGuaranteeExpires: valueOrNull(data.dateGuaranteeExpires)
            };
            this.props.onUpdateRepair?.(parseNodeId(selectedRepair.repairNodeId), variables);
        }

        this.setState({ editing: false });
    }
}

export type { IRepairControlProps };
export default RepairControl;    