import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, TextProps } from "../types";

const RepairInvoiceRef = ({ show, editing, data, onChange }: PropsWithEditing<PropsWithShow<TextProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "text",
        id: "repair-invoice-ref",
        label: "Invoice Reference",
        classes: { container: 'input__group', label: 'input__label' },
        value: data.invoiceRef,
        editing: editing ?? false,
        onChange: value => onChange?.(value),
    }
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairInvoiceRef;