import React from "react";
import { useDispatch } from "react-redux";
import useSelector from "../../lib/hooks/useSelector";

import { parseNodeId } from "../../lib/nodeIdentifier";
import useNavigation from "../../router/DesktopRouter/hooks/useNavigation";
import { getDefectsForWaterSource } from "../../store/actions/defects";
import { getInspectionsForWaterSource } from "../../store/actions/inspections";
import { getRepairsForWaterSource } from "../../store/actions/repairs";
import { setIsEditingWaterSourceAborted, updateWaterSource } from "../../store/actions/waterSources";
import { useWaterSource } from "../appWindowContext";
import DataTabsBase, { IDataTabsProps } from "./dataTabs";
import DataTabsContext from "./DataTabsContext";
import { setRenderPoints } from "../../store/actions/map";
import { IWaterSourceSummary } from "../inspectionView";

const DataTabs = (): JSX.Element => {
    const dispatch = useDispatch();
    const { instance: waterSource, clear: clearWaterSource, refresh: refreshWaterSource, changeCoordinates: changeWaterSourceCoordinates } = useWaterSource();
    const { gotoWaterSource } = useNavigation(true);
    const editingSelectedWaterSource = useSelector(state => state.waterSources.editingSelectedWaterSource);
    if (waterSource) {
        const props: IDataTabsProps = {
            open: waterSource !== undefined,
            selectedWaterSource: waterSource,
            editingSelectedWaterSource,
            getDefectsForWaterSource: async (waterSourceNodeId, setLoading): Promise<void> => {
                const action = getDefectsForWaterSource(waterSourceNodeId, setLoading);
                await action(dispatch);
            },
            getInspectionsForWaterSource: async (waterSourceNodeId, setLoading): Promise<void> => {
                const action = getInspectionsForWaterSource(waterSourceNodeId, setLoading);
                await action(dispatch);
            },
            getRepairsForWaterSource: async (waterSourceNodeId, setLoading): Promise<void> => {
                const action = getRepairsForWaterSource(waterSourceNodeId, setLoading);
                await action(dispatch);
            },
            onCloseWaterSource: (): void => {
                clearWaterSource();
            },
            onOpenWaterSource: (): void => {
                gotoWaterSource(parseNodeId(waterSource.waterSourceNodeId));
            },
            onRefreshWaterSource: async (waterSource?: IWaterSourceSummary): Promise<void> => {
                await refreshWaterSource();
                if (waterSource) {
                    // merge updated water source into map?
                    dispatch(updateWaterSource(waterSource));
                    // update map
                    dispatch(setRenderPoints(true));
                }
            },
            changeWaterSourceCoordinates,
            setEditingWaterSourceAborted: (isEditingWaterSourceAborted: boolean): void => {
                dispatch(setIsEditingWaterSourceAborted(isEditingWaterSourceAborted));
            }
        };
        return (
            <DataTabsContext>
                <DataTabsBase key={waterSource?.waterSourceNodeId} {...props} />
            </DataTabsContext>
        );
    }
    return <React.Fragment />;
};

export default DataTabs;