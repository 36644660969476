import React from "react";
import { useDispatch } from 'react-redux';

import { parseNodeId } from "../../../lib/nodeIdentifier";
import useSelector from '../../../lib/hooks/useSelector';
import { clearGlobalToast } from '../../../store/actions/app';
import { beginCreateRoute } from '../../../store/actions/routes';
import { INewRouteVariables } from '../../../store/types';
import CreateRouteDialogBase, { ICreateRouteProps } from './CreateRouteDialog.view';
import { guid } from "../../../lib/Utils";
import { useRoute } from "../../appWindowContext";

const CreateRouteDialog = (props: Pick<ICreateRouteProps, "isOpen" | "onClose">): JSX.Element => {
    const dispatch = useDispatch();
    const { change: showRoute } = useRoute();
    const stations = useSelector(state => state.app.lookups?.stations ?? []);
    const combined: ICreateRouteProps = {
        ...props,
        stations,
        onCreateRoute: async (data) => {
            dispatch(clearGlobalToast());
            const variables: INewRouteVariables = {
                route: {
                    clientMutationId: guid(),
                    data
                }
            };
            const action = beginCreateRoute(variables);
            const route = await action(dispatch);
            if (route) {
                showRoute(parseNodeId(route.routeNodeId));
            }
        }
    };
    return <CreateRouteDialogBase {...combined} />;
};

export default CreateRouteDialog;
