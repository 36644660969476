import React from "react";

import Typography from "@material-ui/core/Typography";

import { ILookupItem } from "../../../store/types";
import LabelledField from "../../../components/labelledField";

interface RepairVendorEmailAddressProps {
    readonly repairVendor: ILookupItem;
    readonly onChange?: (value: string) => void;
}

const RepairVendorEmailAddress = ({ repairVendor, onChange }: RepairVendorEmailAddressProps): JSX.Element => {
    const handleChange = (value: string | undefined): void => {
        onChange?.(value ?? "");
    };

    return (
        <div>
            <Typography variant="body1">Repair Vendor Email Address</Typography>
            <LabelledField
                editing
                fieldType="text"
                value={repairVendor.emailAddress}
                id="repairVendorEmailAddress"
                label="Repair Vendor Email Address"
                labelPosition="HIDDEN"
                onChange={handleChange}
            />
        </div>
    );
};

export type { RepairVendorEmailAddressProps };
export default RepairVendorEmailAddress;