import executeQuery from "../../../lib/executeQuery";
import getWaterSourceByFiltersQuery from "../../../store/actions/graphQL/getWaterSourcesByFilter";
import { IConnection, IConnectionQuery, IQueryControl } from "../../../store/types";
import { WaterSourceSearchItem } from "./types";

export interface WaterSourceQueryResponse {
    readonly waterSources: IConnection<WaterSourceSearchItem>;
}

export const queryWaterSourcesByFilter = async (filter: Record<string, unknown>, paging: IQueryControl): Promise<IConnection<WaterSourceSearchItem>> => {
    const input: IConnectionQuery<Record<string, unknown>> = {
        filter, 
        paging
    };
    const response = await executeQuery<WaterSourceQueryResponse>(getWaterSourceByFiltersQuery, input);
    return response
        ? response.waterSources
        : Promise.reject(new Error("Missing query response."));
};
