import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { NodeID, encodeNodeId } from '../../lib/nodeIdentifier';
import { useNavigation } from '../../router/MobileRouter/hooks';
import useStyles from "./RoutesList.styles";
import EmptyItem from '../inspectionList/components/emptyItem';

interface RouteItem {
    readonly nodeId: NodeID;
    readonly name: string;
    readonly inspectionCount: number;
}

interface RoutesListProps {
    readonly stationId?: NodeID;
    readonly routes: RouteItem[];
}

const RoutesList = (props: RoutesListProps): JSX.Element => {
    const { gotoPlannedTasks } = useNavigation();
    const classes = useStyles();
    const handleRouteClick = (route: RouteItem): void => {
        gotoPlannedTasks({
            station: props.stationId,
            route: route.nodeId
        }, { title: route.name, extended: true });
    };
    const { routes } = props;
    return (
        <div className={classes.container}>
            <List className={classes.list}>
                {routes.length
                    ? routes.map(route =>
                        <ListItem button key={encodeNodeId(route.nodeId)} className={classes.item} onClick={(): void => handleRouteClick(route)}>
                            <ListItemText primary={route.name} />
                            <ListItemSecondaryAction>
                                {route.inspectionCount}
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                    : <EmptyItem message="No routes with open inspections." />
                }
            </List>
        </div>
    );
};

export type { RoutesListProps, RouteItem };
export default RoutesList;