import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(createStyles({
    portal: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: 1000000
    }
}));

const PortalContext = React.createContext<React.RefObject<HTMLDivElement> | undefined>(undefined);

const Portal = (props: React.PropsWithChildren<unknown>): JSX.Element => {
    const styles = useStyles();
    const portal = React.useRef<HTMLDivElement>(null);
    return (
        <PortalContext.Provider value={portal}>
            {props.children}
            <div ref={portal} className={styles.portal} />
        </PortalContext.Provider>
    );
};

const usePortalRef = (): Element | null => {
    const context = React.useContext(PortalContext);
    if (context === undefined) {
        throw new Error("Missing context; `usePortalRef` must be used within a `Portal`.");
    }
    const ref = context.current ?? null;
    return ref;
};

export { usePortalRef };
export default Portal;