import React, { useCallback, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core";
import { Coordinate as olCoordinate } from "ol/coordinate";

import { bindFeatureFactory, toLocationFeature, WaterSourceFeatureData } from "../../lib/map";
import { parseNodeId } from "../../lib/nodeIdentifier";
import { useNavigation } from "../../router/MobileRouter/hooks";
import { IInspection } from "../../store/types";
import Map, { FeatureArray, MapDataAsync } from "../map/mobile";
import StyleCache from "../map/StyleCache";
import { SelectorDialog } from "../inspectionMap";
import { isFeatureData } from "../inspectionMap/InspectionMap.view";
import { IWaterSourceSummary } from "../inspectionView";

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "flex",
        height: "100%"
    }
}));

interface NearMeMapProps {
    readonly nearestWaterSources: IWaterSourceSummary[];
    readonly deviceLocation: olCoordinate;
}

const NearMeMap = (props: NearMeMapProps): JSX.Element => {
    const { nearestWaterSources, deviceLocation } = props;
    const styles = useStyles();
    const locationFeature = toLocationFeature(deviceLocation);
    const { gotoInspection, gotoNearestWaterSource } = useNavigation();
    const [waterSource, setWaterSource] = useState<WaterSourceFeatureData>();
    const [inspections, setInspections] = useState<IInspection[]>([]);
    const [showSelector, setShowSelector] = useState(false);

    const closeSelector = useCallback((): void => setShowSelector(false), []);

    const openSelector = useCallback((): void => setShowSelector(true), []);

    const makeData = useCallback(async (): MapDataAsync => {
        const styleCache = new StyleCache();
        const createFeature = bindFeatureFactory(styleCache);
        const features: FeatureArray = nearestWaterSources.map(createFeature);

        return Promise.resolve([deviceLocation, features]);
    }, [nearestWaterSources]);

    const handleMapSelect = useCallback((data: unknown): void => {
        if (isFeatureData(data)) {
            const { inspectionCount, waterSourceNodeId, inspections } = data;
            if (!inspectionCount) {
                gotoNearestWaterSource(waterSourceNodeId);
            }
            if (inspectionCount && inspections) {
                if (inspectionCount === 1) {
                    gotoInspection(parseNodeId(inspections[0].inspectionNodeId));
                }
                if (inspectionCount > 1) {
                    setWaterSource(data);
                    setInspections(inspections);
                    openSelector();
                }
            }
        }
    }, [openSelector, gotoInspection]);

    return (
        <div className={styles.root}>
            <Map data={makeData} deviceLocation={locationFeature} onSelect={handleMapSelect} />
            <SelectorDialog open={showSelector} waterSource={waterSource} inspections={inspections} onClose={closeSelector} />
        </div>
    );
};

export type { NearMeMapProps };
export default NearMeMap;