import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import LabelledField, { LabelledFieldProps, SelectOption } from "../../../labelledField";
import ElementContainer from "../../../labelledField/components/elementContainer";
import { containerClass } from "../../../labelledField/labelledField.utils";
import Optional from "../../../shared/Optional";
import { PropsWithEditing } from "../types";

interface MainsSizeProps {
    readonly value: number | undefined;
    readonly units: SelectOption[];
    readonly selectedUnit: SelectOption | undefined;
    readonly onClear?: () => void;
    readonly onChange?: (value: number | undefined) => void;
    readonly onSelectUnit?: (value: SelectOption | undefined) => void;
}

const id = "water-source-mains-size";

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginBottom: theme.spacing(1)
    },
    value: {
        alignSelf: "flex-start",
        marginBottom: 0
    },
    units: {
        marginBottom: 0
    }
}));

const Editor = ({ value, units, selectedUnit, onClear, onChange, onSelectUnit }: MainsSizeProps): JSX.Element => {
    const styles = useStyles();
    const mainsSizeProps: LabelledFieldProps = {
        fieldType: "number",
        id,
        label: "Mains Size",
        classes: { container: cx("input__group", styles.value), label: "input__label" },
        value,
        minValue: undefined,
        editing: true,
        onChange: value => onChange?.(value),
    };
    const mainsSizeUnitsProps: LabelledFieldProps = {
        fieldType: "select",
        id: `${id}-units`,
        label: "",
        classes: { container: cx("input__group", styles.units), label: "input__label" },
        value: selectedUnit,
        selectOptions: units,
        editing: true,
        onChange: value => onSelectUnit?.(value),
        onClear
    };

    const containerId = `${id}-edit-container`;
    return (
        <ElementContainer classes={cx(containerClass({ classes: { container: 'input__group', label: 'input__label' } }), styles.root)}>
            <Box id={containerId} display="flex" flexDirection="row">
                <LabelledField {...mainsSizeProps} />
                <LabelledField {...mainsSizeUnitsProps} />
            </Box>
        </ElementContainer>
    );
};

const formatText = (value: number | undefined, selectedUnit: SelectOption | undefined): string => {
    if (value === undefined) {
        return "-";
    }
    if (selectedUnit) {
        return `${value} ${selectedUnit?.displayText}`;
    }
    return `${value}`;
};

const ReadOnly = ({ value, selectedUnit }: Pick<MainsSizeProps, "value" | "selectedUnit">): JSX.Element => (
    <LabelledField
        fieldType="readonly"
        id={id}
        label="Mains Size"
        classes={{ container: 'input__group', label: 'input__label' }}
        value={formatText(value, selectedUnit)}
    />
);

const WaterSourceMainsSize = (props: PropsWithEditing<MainsSizeProps>): JSX.Element => {
    const { editing, ...rest } = props;
    return editing
        ? <Editor {...rest} />
        : <ReadOnly {...rest} />

};

export type { MainsSizeProps };
export default WaterSourceMainsSize;