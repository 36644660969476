import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

interface AppBarMenuItemProps {
    readonly id?: string;
    readonly label?: string;
    readonly onClick?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
}));

const AppBarMenuItem = (props: AppBarMenuItemProps): JSX.Element => {
    const { id, label, onClick } = props;
    const styles = useStyles();
    const handleClick = (): void => {
        onClick?.();
    };
    return (
        <MenuItem id={`appBar-menuItem-${id}`} className={styles.root} onClick={handleClick}>
            <Typography variant="body2">
                {label}
            </Typography>
        </MenuItem>
    );
};

export type { AppBarMenuItemProps };
export default AppBarMenuItem;