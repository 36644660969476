import React, { Component } from 'react';

import { NodeID, parseNodeId } from '../../../lib/nodeIdentifier';
import { IQuickSearchItem, IWaterSource } from '../../../store/types';
import Dialog2 from '../../dialog/dialog';
import DialogActions from '../../dialog/dialogActions';
import DialogTitle from '../../dialog/dialogTitle';
import DialogContent from '../../dialog/dialogContent';
import InlineQuickSearch from '../../inlineQuickSearch';

interface IAssignWaterSourceToSchemeDialogProps {
    readonly isOpen: boolean;
    readonly selectedWaterSource?: IWaterSource;
    readonly onClose?: () => void;
    readonly onSelect?: (waterSource: NodeID) => void;
    readonly onValidationError?: (message: string) => void;
}

interface IAssignWaterSourceToSchemeDialogState {
    readonly shouldValidate: boolean;
    readonly waterSource: IQuickSearchItem | undefined;
}

const getIdentity = (source: IWaterSource | undefined): IQuickSearchItem | undefined => {
    return source
        ? { ...source }
        : undefined;
};

class AssignWaterSourceToSchemeDialog extends Component<IAssignWaterSourceToSchemeDialogProps, IAssignWaterSourceToSchemeDialogState> {
    constructor(props: IAssignWaterSourceToSchemeDialogProps) {
        super(props);
        this.state = {
            waterSource: getIdentity(props.selectedWaterSource),
            shouldValidate: false
        }
    }

    public componentDidUpdate(prevProps: IAssignWaterSourceToSchemeDialogProps): void {
        const { waterSourceNodeId: selectedNodeId } = this.props.selectedWaterSource ?? {};
        const { waterSourceNodeId: previousNodeId } = prevProps.selectedWaterSource ?? {};
        if (selectedNodeId && (previousNodeId !== selectedNodeId)) {
            this.setState({
                waterSource: getIdentity(this.props.selectedWaterSource)
            });
        }
    }

    public render(): JSX.Element {
        const handleClose = (): void => {
            this.props.onClose?.();
        };
        const handleSaveClick = (): void => {
            this.save();
        };
        const handleSearchClear = (): void => {
            this.setState({
                waterSource: undefined
            });
        };
        const handleSearchResult = (result: IQuickSearchItem): void => {
            this.setState({
                waterSource: { ...result }
            });
        };

        return (
            <Dialog2 fullWidth maxWidth="xs" overflowY="unset" open={this.props.isOpen} onClose={handleClose} >
                <DialogTitle onClose={handleClose}>Assign Water Source to Scheme</DialogTitle>
                <DialogContent overflowY="unset">
                    <InlineQuickSearch id="hidden_waterSource_input" result={this.state.waterSource} onClear={handleSearchClear} onResult={handleSearchResult} />
                </DialogContent>
                <DialogActions>
                    <button id="assignWaterSource-cancelButton" className="action-button action-button--cancel" onClick={handleClose}>Cancel</button>
                    <button id="assignWaterSource-saveButton" className="action-button action-button--save" onClick={handleSaveClick}>Save</button>
                </DialogActions>
            </Dialog2>
        );
    }

    private save = (): void => {
        this.setState({ shouldValidate: true }, () => {
            const { waterSourceNodeId } = this.state.waterSource ?? {};
            if (waterSourceNodeId) {
                this.props.onSelect?.(parseNodeId(waterSourceNodeId));
            } else {
                this.props.onValidationError?.("Please complete all required fields.");
            }
        });
    }
}

export type { IAssignWaterSourceToSchemeDialogProps };
export default AssignWaterSourceToSchemeDialog;