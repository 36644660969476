import React, { Children } from "react";

import { useRoleChecks } from "../hooks";
import { RoleName } from "../roles";

interface MatchRoleRule {
    readonly role: RoleName;
}

interface IncludeAllRule {
    readonly all: RoleName[];
}

interface IncludeAnyRule {
    readonly any: RoleName[];
}

interface ExcludeAllRule {
    readonly excludeAll: RoleName[];
}

interface ExcludeAnyRule {
    readonly excludeAny: RoleName[];
}

type AccessControlRule =
    MatchRoleRule |
    IncludeAllRule |
    IncludeAnyRule |
    ExcludeAllRule |
    ExcludeAnyRule;

const isMatchRoleRule = (rule: AccessControlRule): rule is MatchRoleRule => {
    return ("role" in rule);
};

const isIncludeAllRule = (rule: AccessControlRule): rule is IncludeAllRule => {
    return ("all" in rule);
};

const isIncludeAnyRule = (rule: AccessControlRule): rule is IncludeAnyRule => {
    return ("any" in rule);
};

const isExcludeAllRule = (rule: AccessControlRule): rule is ExcludeAllRule => {
    return ("excludeAll" in rule);
};

const isExcludeAnyRule = (rule: AccessControlRule): rule is ExcludeAnyRule => {
    return ("excludeAny" in rule);
};

const isAccessControlRule = (rule: unknown): rule is AccessControlRule => {
    const isMatch =
        isMatchRoleRule(rule as AccessControlRule) ||
        isIncludeAllRule(rule as AccessControlRule) ||
        isIncludeAnyRule(rule as AccessControlRule) ||
        isExcludeAllRule(rule as AccessControlRule) ||
        isExcludeAnyRule(rule as AccessControlRule);
    return isMatch;
};

interface AccessControlProps {
    readonly alt?: React.ReactNode;
}

const AccessControl = <P extends Record<string, unknown>>(props: React.PropsWithChildren<AccessControlProps & AccessControlRule & P>): JSX.Element => {
    const { alt, children, ...rest } = props;
    const { has, hasAll, hasAny } = useRoleChecks();

    const AccessDenied = (): JSX.Element => (
        <React.Fragment>
            {React.Children.map(alt, child =>
                React.isValidElement(child)
                    ? React.cloneElement(child)
                    : child
            )}
        </React.Fragment>
    );

    const AccessGranted = (): JSX.Element => (
        <React.Fragment>
            {React.Children.map(children, child => {
                return React.isValidElement(child)
                    ? React.cloneElement(child, rest)
                    : Children
            })}
        </React.Fragment>
    );

    if (isMatchRoleRule(props)) {
        return has(props.role)
            ? <AccessGranted />
            : <AccessDenied />;
    }
    if (isIncludeAnyRule(props)) {
        return hasAny(props.any)
            ? <AccessGranted />
            : <AccessDenied />;
    }
    if (isIncludeAllRule(props)) {
        return hasAll(props.all)
            ? <AccessGranted />
            : <AccessDenied />;
    }
    if (isExcludeAnyRule(props)) {
        return hasAny(props.excludeAny)
            ? <AccessDenied />
            : <AccessGranted />
    }
    if (isExcludeAllRule(props)) {
        return hasAll(props.excludeAll)
            ? <AccessDenied />
            : <AccessGranted />
    }
    return <AccessDenied />;
};

export type { AccessControlProps, AccessControlRule, MatchRoleRule, IncludeAllRule, IncludeAnyRule, ExcludeAllRule, ExcludeAnyRule };
export { isAccessControlRule, isExcludeAllRule, isExcludeAnyRule, isIncludeAllRule, isIncludeAnyRule, isMatchRoleRule };
export default AccessControl;