import React from "react";
import { useParams } from "react-router-dom";
import { LoadingBar } from "@3tc/shared-components";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import useGetWaterSource from "../../components/appWindowContext/hooks/useGetWaterSource";
import { parseNodeId } from "../../lib/nodeIdentifier";
import ViewWaterSource from "./components/ViewWaterSource";

export const WaterSourcePage = (): JSX.Element => {
    const { waterSourceNodeId } = useParams<{ readonly waterSourceNodeId: string }>();
    const [status, waterSource] = useGetWaterSource(parseNodeId(waterSourceNodeId));

    if (status === "complete") {
        return waterSource
            ? <ViewWaterSource selectedWaterSource={waterSource} />
            : <Typography variant="subtitle1">Cannot load water source; please refresh the page to try again.</Typography>
    }
    return (
        <Box marginTop={2}>
            <Typography variant="subtitle1">Loading water source...</Typography>
            <LoadingBar loading={true} />
        </Box>
    )
};

export default WaterSourcePage;
