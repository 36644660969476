import React from 'react';

import Typography from "@material-ui/core/Typography";

import styles from "./KeyItem.module.scss";

interface IItem {
    readonly status: string,
    readonly icon: string
}

interface IKeyItemProps {
    readonly item: IItem,
}

const KeyItem = ({ item }: IKeyItemProps): JSX.Element => (
    <li className={styles.root}>
        <div className={styles.iconContainer}>
            <img src={item.icon} alt={item.status} className={styles.icon} />
        </div>
        <Typography className={styles.label} variant="subtitle2" >{item.status}</Typography>
    </li>
)

export type { IItem };
export default KeyItem;