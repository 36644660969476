import React from "react";

import { Radio, RadioGroup } from '@blueprintjs/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { ILookupItem } from '../../../store/types';

interface WaterSourceCategorySelectorProps {
    readonly categories: ILookupItem[];
    readonly item: ILookupItem;
    readonly onChange?: (value: number) => void;
}

const useStyles = makeStyles(theme => createStyles({
    header: {
        marginBottom: theme.spacing(0.5)
    }
}));

const WaterSourceCategorySelector = ({ categories, item, onChange }: WaterSourceCategorySelectorProps): JSX.Element => {
    const styles = useStyles();
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        onChange?.(parseInt(event.currentTarget.value));
    };
    const buttons = categories
        .filter(cat => cat.enabled)
        .map(cat => <Radio key={cat.value} label={cat.displayText} value={cat.value} />);
    return (
        <div>
            <Typography variant="body1" className={styles.header}>Water Source Category</Typography>
            <RadioGroup onChange={handleChange} selectedValue={item.groupingId}>
                {buttons}
            </RadioGroup>
        </div>
    );
};

export type { WaterSourceCategorySelectorProps };
export default WaterSourceCategorySelector;