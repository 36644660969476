import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { LocalDate } from "@js-joda/core";

import useAttachments from "../../api/hooks/useAttachments";
import useInspection from "../../api/hooks/useInspection";
import useOpenDefects from "../../api/hooks/useOpenDefects";
import updateDetails, { DetailsData } from "../../api/waterSources/updateDetails";
import updateLocation, { LocationData } from "../../api/waterSources/updateLocation";
import { isHydrant } from "../../components/inspectionView/components/utils";
import { InspectionOutcome, IDefectSummary } from "../../components/inspectionView/types";
import { canEnableCommand } from "../../components/inspectionView/utils";
import { IFileList } from "../../components/shared/fileManager";
import useSelector from "../../lib/hooks/useSelector";
import { NodeID, encodeNodeId, parseNodeId } from "../../lib/nodeIdentifier";
import { guid } from "../../lib/Utils";
import useInspectionParams from "../../router/MobileRouter/hooks/useInspectionNodeIdParam";
import {
    Inspector, ILookupItem, Repair,
    ICompleteInspectionVariables, ICompleteRepairInput, ICreateRepairVariables, IAddDefectVariables, ICloseDefectVariables, getInspectorNodeId, INote, IDefect,
} from "../../store/types";
import { startAddDefect, startCompleteDefect } from "../../store/actions/defects";
import { startCompleteInspection, startAssignInspector, startEditInspection } from "../../store/actions/inspections";
import { startAddInternalRepair, startCompleteRepair } from "../../store/actions/repairs";
import InspectionView, { EditState } from "./InspectionView.view";
import { createNote } from "../../store/actions/notes";
import { addHazards, removeHazards } from "../../store/actions/hazards";
import { prepareHazardsToSave } from "../../components/waterSource/waterSourceControl/waterSourceControl.utils";
import { IPrepareHazardsToSaveData } from "../../components/waterSource/waterSourceControl/types";
import prepareTagsUpdate from "./prepareTagsUpdate";
import { addTag, removeTag } from "../../store/actions/tags";

export const formatNote = (note: string | undefined): string | undefined => {
    const normalised = note?.trim() ?? "";
    return normalised.length > 0
        ? normalised
        : undefined;
};

const getOutcomeValue = (outcome: InspectionOutcome): number => {
    switch (outcome) {
        case InspectionOutcome.Fail:
            return 1;
        case InspectionOutcome.Pass:
            return 2;
    }
};

const ConnectedInspectionView = (): JSX.Element => {
    const dispatch = useDispatch();
    const { nodeId, tabIndex } = useInspectionParams();
    const inspector = useSelector<Inspector | undefined>(state => state.app.user?.profile);
    const [inspectionStatus, inspection, fetchInspection] = useInspection(nodeId);
    const [loadingInspection, setLoadingInspection] = useState(false);
    const [defectsStatus, defects, fetchDefects] = useOpenDefects(nodeId);
    const [filesStatus, files, fetchFiles] = useAttachments(inspection?.inspectionId);
    const allHazardsTypes = useSelector(state => state.app.lookups?.hazardTypes);

    useEffect(() => {
        setLoadingInspection(inspectionStatus !== "complete");
    }, [inspectionStatus])

    const actionsEnabled = canEnableCommand(inspection, inspector);

    const addRepairs = (waterSourceNodeId: NodeID, inspectionNodeId: NodeID, selectedRepairs: ILookupItem[]) => {
        return async (): Promise<Repair[]> => {
            const closedRepairs: Repair[] = [];

            const addInternalRepair = async (internalRepair: number): Promise<Repair | undefined> => {
                const variables: ICreateRepairVariables = {
                    repair: {
                        clientMutationId: guid(),
                        data: {
                            waterSourceId: encodeNodeId(waterSourceNodeId),
                            inspectionId: encodeNodeId(inspectionNodeId),
                            repairTypeId: internalRepair,
                            isPaymentAuthorised: false
                        }
                    }
                };
                const action = startAddInternalRepair(variables);
                return action(dispatch);
            };
            const completeRepair = async (repair: Repair | undefined): Promise<Repair | undefined> => {
                if (repair === undefined) {
                    return;
                }

                const variables: ICompleteRepairInput = {
                    input: {
                        clientMutationId: guid(),
                        nodeId: repair.repairNodeId
                    }
                };
                const action = startCompleteRepair(variables);
                return action(dispatch);
            };
            const pushClosedRepair = (repair: Repair | undefined): void => {
                if (repair) {
                    closedRepairs.push(repair);
                }
            };

            if (selectedRepairs.length) {
                for (const internalRepair of selectedRepairs) {
                    await addInternalRepair(internalRepair.value)
                        .then(async repair => completeRepair(repair))
                        .then(repair => pushClosedRepair(repair));

                }
            }
            return closedRepairs;
        };
    };

    const editInspection = async (inspectionNodeId: NodeID, isWallPlateFitted?: boolean, isServiceRecordInDate?: boolean): Promise<void> => {
        const action = startEditInspection({
            inspection: {
                clientMutationId: guid(),
                nodeId: encodeNodeId(inspectionNodeId),
                data: {
                    isWallPlateFitted,
                    isServiceRecordInDate
                }
            }
        });
        return action(dispatch);
    };

    const assignInspector = (inspectionNodeId: NodeID, inspectorNodeId: NodeID) => {
        return async (): Promise<void> => {
            const action = startAssignInspector({
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(inspectionNodeId),
                    data: encodeNodeId(inspectorNodeId)
                }
            });
            return action(dispatch);
        }
    };

    const completeInspection = (getVariables: (closedRepairs: Repair[]) => ICompleteInspectionVariables) => {
        return async (closedRepairs: Repair[]): Promise<void> => {
            const variables = getVariables(closedRepairs);
            const action = startCompleteInspection(variables);
            return action(dispatch);
        };
    };

    const handleRefresh = (): void => {
        fetchInspection();
    };

    const handleSaveLocation = async (nodeId: NodeID, update: EditState, category: ILookupItem): Promise<void> => {
        const data: LocationData = isHydrant(category)
            ? {
                hydrantLocationId: update.hydrantLocation?.value ?? null,
                locationAddressId: update.address?.addressId ?? null,
                locationCoordinates: update.coordinates ?? null,
                locationDescription: update.description ?? null,
                controlMeasures: []
            }
            : {
                locationAddressId: update.address?.addressId ?? null,
                locationCoordinates: update.coordinates ?? null,
                locationDescription: update.description ?? null,
                controlMeasures: []
            };
        await updateLocation(nodeId, data);
        fetchInspection();
    };

    const updateTags = async (nodeId: NodeID, tags: ILookupItem[]): Promise<void> => {
        const [added, removed] = prepareTagsUpdate(tags, inspection?.waterSource?.tags ?? []);
        await addTag(nodeId, added)(dispatch);
        await removeTag(nodeId, removed)(dispatch);
    };

    const handleSaveWaterSource = async (nodeId: NodeID, update: EditState, category: ILookupItem): Promise<void> => {
        const data: DetailsData = isHydrant(category)
            ? {
                classificationId: update.waterSource.classification?.value ?? null,
                flowRate: update.waterSource.flowRate ?? null,
                mainsSize: update.waterSource.mainsSize?.value ?? null,
                mainsSizeUnitId: update.waterSource.mainsSize?.unit?.value ?? null,
                pressure: update.waterSource.pressure ?? null,
                stationId: update.waterSource.station?.value ?? null,
                surfaceId: update.waterSource.surface?.value ?? null,
                additionalInfo: formatNote(update.waterSource.additionalInfo) ?? null,
                controlMeasures: update.waterSource.controlMeasures.map(controlMeasure => controlMeasure.value),
                riskSeverityId: update.waterSource.riskSeverity?.value ?? null,
                plateLocationId: update.waterSource.plateLocation?.value ?? null,
                plateDistance: update.waterSource.plateDistance ?? null
            }
            : {
                classificationId: update.waterSource.classification?.value ?? null,
                stationId: update.waterSource.station?.value ?? null,
                additionalInfo: formatNote(update.waterSource.additionalInfo) ?? null,
                controlMeasures: update.waterSource.controlMeasures.map(controlMeasure => controlMeasure.value),
                riskSeverityId: update.waterSource.riskSeverity?.value ?? null
            };
        await updateDetails(nodeId, data);
        await updateTags(nodeId, update.waterSource.tags ?? []);
        fetchInspection();
    };

    const handleSaveHazards = async (nodeId: NodeID, update: EditState): Promise<void> => {
        const hazardSeverityValue = update.waterSource.hazardSeverity?.value;
        const waterSourceNodeId = inspection?.waterSource.waterSourceNodeId ?? "";
        const prepareHazardsToSaveData: IPrepareHazardsToSaveData = {
            hazardSeverityValue,
            allHazardsTypes,
            hazardsValues: update.waterSource.hazardsValues,
            initialHazardsValues: inspection?.waterSource?.hazards?.map(hazard => hazard.hazardType.value),
            allHazards: inspection?.waterSource?.hazards
        };

        const { addedHazards, deletedHazards } = prepareHazardsToSave(prepareHazardsToSaveData);

        const data: DetailsData = {
            controlMeasures: update.waterSource.controlMeasures.map(controlMeasure => controlMeasure.value),
            hazardInformation: formatNote(update.waterSource.hazardInformation) ?? null,
            roadSpeedId: update.waterSource.roadSpeed?.value ?? null,
            hazardSeverityId: update.waterSource.hazardSeverity?.value ?? null,
            hazardFlagId: update.waterSource.hazardFlag?.value ?? null,
            isSecondPersonRequired: update.waterSource.isSecondPersonRequired ?? null
        };
        await updateDetails(nodeId, data);

        if (addedHazards && addedHazards.length > 0) {
            const data = addedHazards.map(h => ({
                hazardTypeId: h.value
            }));
            const action = addHazards(data, waterSourceNodeId);
            await action(dispatch);
        }
        if (deletedHazards && deletedHazards.length > 0) {
            const action = removeHazards(deletedHazards.map(h => h.hazardNodeId), waterSourceNodeId);
            await action(dispatch);
        }

        fetchInspection();
    };

    const handleCompleteInspection = async (
        outcome: InspectionOutcome,
        note: string,
        unresolvedDefects: IDefectSummary[],
        selectedTest: ILookupItem,
        selectedRepairs: ILookupItem[],
        attachments: IFileList,
        wallPlateFitted?: boolean,
        serviceRecordsInDate?: boolean
    ): Promise<void> => {
        if (inspection === undefined) {
            console.warn("Cannot complete inspection; missing inspection.");
            return;
        }
        if (inspector === undefined) {
            console.warn("Cannot complete inspection; missing `inspector`.");
            return;
        }

        const inspectorNodeId = getInspectorNodeId(inspector);
        const { waterSource } = inspection;
        const waterSourceNodeId = parseNodeId(waterSource.waterSourceNodeId);
        const inspectionNodeId = parseNodeId(inspection.inspectionNodeId);

        setLoadingInspection(true);
        await editInspection(inspectionNodeId, wallPlateFitted, serviceRecordsInDate)
            .then(assignInspector(inspectionNodeId, inspectorNodeId))
            .then(addRepairs(waterSourceNodeId, inspectionNodeId, selectedRepairs))
            .then(completeInspection(closedRepairs => ({
                inspection: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(inspectionNodeId),
                    data: {
                        testId: selectedTest.value,
                        resultId: getOutcomeValue(outcome),
                        note: formatNote(note),
                        resolvedDefects: [],
                        unresolvedDefects: unresolvedDefects.map(d => d.defectNodeId),
                        internalRepairs: closedRepairs.map(r => r.repairNodeId),
                        attachments: Object.values(attachments).map(f => Number(f.metaData.Id))
                    }
                }
            })))
            .then(fetchInspection)
            .catch(error => console.warn("Cannot complete inspection; an error occurred.", error))
            .finally(() => setLoadingInspection(false));
    };

    const handleAddDefect = (defectType: ILookupItem, dateReported: LocalDate, reportedBy: string, note: string): void => {
        const addNote = async (defect: IDefect | undefined): Promise<INote[]> => {
            if (defect && note) {
                const action = createNote(parseNodeId(defect.defectNodeId), note);
                const notes = await action(dispatch);
                return notes;
            }
            return [];
        };

        if (nodeId === undefined) {
            return;
        }

        const variables: IAddDefectVariables = {
            defect: {
                clientMutationId: guid(),
                data: {
                    parentId: encodeNodeId(nodeId),
                    typeId: defectType.value,
                    dateReported,
                    reportedBy,
                }
            }
        };
        const action = startAddDefect(variables);
        action(dispatch)
            .then(addNote)
            .then(() => fetchDefects())
            .catch(error => console.warn("Error adding defect.", error));
    };
    const handleCloseDefect = (defectId: NodeID, closeReason: ILookupItem): void => {
        if (nodeId === undefined) {
            return;
        }

        const variables: ICloseDefectVariables = {
            defect: {
                clientMutationId: guid(),
                nodeId: encodeNodeId(defectId),
                data: {
                    inspectionId: encodeNodeId(nodeId),
                    closeReasonId: closeReason.value,
                    isResolved: true
                }
            }
        };
        const action = startCompleteDefect(variables);
        action(dispatch)
            .then(() => fetchDefects())
            .catch(error => console.warn("Error completing defect.", error));
    };

    const handleUploadComplete = (): void => {
        fetchFiles();
    };

    return (
        <InspectionView
            inspection={{ item: inspection, loading: loadingInspection }}
            defects={{ items: defects, loading: defectsStatus !== "complete" }}
            attachments={{ files: files, loading: filesStatus !== "complete" }}
            actionsEnabled={actionsEnabled}
            onAddDefect={handleAddDefect}
            onCloseDefect={handleCloseDefect}
            onCompleteInspection={handleCompleteInspection}
            onRefresh={handleRefresh}
            onSaveLocation={handleSaveLocation}
            onSaveWaterSource={handleSaveWaterSource}
            onUploadComplete={handleUploadComplete}
            onSaveHazards={handleSaveHazards}
            tabIndex={tabIndex}
        />
    );
};

export default ConnectedInspectionView;