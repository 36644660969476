import { IAppAction } from '../types';
import { clearGlobalToast, setErrorToast } from './app';

export const setError = (errorMessage: string, errorObject: Error): IAppAction => {
    return setErrorToast(errorMessage, errorObject);
};

export const clearError = (): IAppAction => {
    return clearGlobalToast();
};
