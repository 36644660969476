import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { setGlobalToast } from "../../../../store/actions/app";
import { setError } from "../../../../store/actions/errors";
import { IReduxState, IAppAction } from "../../../../store/types";
import AppBarChangePassword, { AppBarChangePasswordProps } from "./AppBarChangePassword";

type ConnectedActions = Pick<AppBarChangePasswordProps, "onSuccess" | "onError">;

const mapDispatch = (dispatch: ThunkDispatch<IReduxState, never, IAppAction>): ConnectedActions => {
    return ({
        onError: (message, error): void => {
            if (error) {
                console.warn("Change Password:", message, error);
            }
            dispatch(setError(message, error ?? new Error(message)));
        },
        onSuccess: (message): void => {
            dispatch(setGlobalToast({
                showToast: true,
                type: "SUCCESS",
                message
            }));
        }
    });
}

export default connect(undefined, mapDispatch)(AppBarChangePassword);