import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

interface SelectProps {
    readonly value: any;
    readonly id?: string;
    readonly label?: string;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly native?: boolean;
    readonly children?: React.ReactNode;
    readonly onChange?: (event: React.ChangeEvent<{ value: unknown; }>, child: React.ReactNode) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    input: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    label: {
        position: "unset",
        top: "unset",
        bottom: "unset",
        marginBottom: theme.spacing(2),
        "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
            transform: "translate(0, 10px) scale(0.75)",
        }
    },
    select: {
        color: `${theme.palette.grey[900]} !important`,
        textTransform: "uppercase",
        ["&:not([multiple]) option"]: {
            color: theme.palette.grey[900],
            textTransform: "uppercase",
        },
        ["&:not([multiple]) optgroup"]: {
            color: theme.palette.grey[900],
            textTransform: "uppercase",
        },
    }
}));

const Field = (props: SelectProps): JSX.Element => {
    const styles = useStyles();
    const { value, id, label, placeholder, required, disabled, native, children, onChange } = props;
    const labelId = `${id}-label`;
    const selectProps = {
        id,
        labelId,
        value,
        required,
        disabled,
        native,
        onChange
    };
    return (
        <FormControl className={styles.root} fullWidth variant="filled">
            <InputLabel id={labelId} htmlFor={id} classes={{ formControl: styles.label }} variant="filled" shrink required={required}>{label}</InputLabel>
            <Select {...selectProps} classes={{ filled: styles.input, select: styles.select }}>
                {native ? <option value="">{placeholder}</option> : <MenuItem value="">{placeholder}</MenuItem>}
                {children}
            </Select>
        </FormControl>
    );
}

export type { SelectProps };
export default Field;