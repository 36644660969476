import React from 'react';
import useSelector from '../../lib/hooks/useSelector';
import ConfigurationPageBase, { IConfigurationPageProps } from "./ConfigurationPage.view";

const ConfigurationPage = (props: Omit<IConfigurationPageProps, "configurableLookups" | "lookups">): JSX.Element => {
    const configurableLookups = useSelector(state => state.app.configurableLookups);
    const lookups = useSelector(state => state.app.lookups);
    

    if (configurableLookups && lookups) {
        const combined: IConfigurationPageProps = {
            ...props,
            configurableLookups,
            lookups
        };
        return <ConfigurationPageBase {...combined} />;
    }

    return <React.Fragment />;
};

export default ConfigurationPage;