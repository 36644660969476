import React from 'react';
import { LocalDate } from '@js-joda/core';

import Box from "@material-ui/core/Box";

import { ILookupItem } from '../../../../../../store/types';
import LabelledField, { SelectOption } from '../../../../../labelledField'
import { SchemeAddressData } from "./schemeAddress";

interface ISchemeDetailsData {
    readonly type: ILookupItem | undefined;
    readonly title: string;
    readonly reference: string;
    readonly description: string | undefined;
    readonly station: ILookupItem | undefined;
}

interface ISchemeDetailsProps {
    readonly data: ISchemeDetailsData;
    readonly stations: ILookupItem[];
    readonly types: ILookupItem[];
    readonly editing: boolean;
    readonly onChange: (details: ISchemeDetailsData) => void;
}

const SchemeDetails = (props: ISchemeDetailsProps): JSX.Element => {
    const { data, stations, editing, types, onChange } = props;

    const handleChange = (key: keyof ISchemeDetailsData, value: string | SelectOption | LocalDate | SchemeAddressData | undefined): void => {
        onChange?.({
            ...data,
            [key]: value
        });
    };

    return (
        <Box>
            <LabelledField
                fieldType="select"
                id="scheme-type"
                label="Type"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.type}
                selectOptions={types}
                editing={editing}
                onChange={(value: SelectOption | undefined): void => handleChange("type", value)}
                onClear={(): void => handleChange("type", undefined)}
            />
            <LabelledField
                fieldType="text"
                id="scheme-title"
                label="Title"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.title}
                editing={editing}
                onChange={(value: string | undefined): void => handleChange("title", value)}
            />
            <LabelledField
                fieldType="text"
                id="scheme-reference"
                label="Reference"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.reference}
                editing={editing}
                onChange={(value: string | undefined): void => handleChange("reference", value)}
            />
            <LabelledField
                fieldType="text"
                id="scheme-description"
                label="Description"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.description}
                editing={editing}
                onChange={(value: string | undefined): void => handleChange("description", value)}
            />
            <LabelledField
                fieldType="select"
                id="scheme-station"
                label="Station"
                classes={{ container: 'input__group', label: 'input__label' }}
                value={data.station}
                selectOptions={stations}
                editing={editing}
                onChange={(value: SelectOption | undefined): void => handleChange("station", value)}
                onClear={(): void => handleChange("station", undefined)}
            />
        </Box>
    );
};

export type { ISchemeDetailsProps, ISchemeDetailsData };
export default SchemeDetails;
