import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    container: {
        height: "100%",
        overflowY: "scroll"
    },
    list: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[200]
    },
    item: {
        textTransform: "capitalize"
    }
}));

export default useStyles;
