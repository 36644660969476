import React from "react";
import { Link } from "react-router-dom";

import { ElementProps } from "../../types";
import { containerClass, labelClass } from "../../labelledField.utils";

import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";

import styles from "./linkElement.module.scss";

interface LinkElementProps extends ElementProps {
    readonly linkText: string;
    readonly preventDefault?: boolean;
    readonly onClick?: () => void;
}

const LinkElement = (props: LinkElementProps): JSX.Element => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        if (props.preventDefault) {
            event.preventDefault();
        }
        props.onClick?.();
    };
    return (
        <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
            <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />
            <Link className={styles.link} to={'#'} onClick={handleClick}>{props.linkText}</Link>
        </ElementContainer>
    );
};

export type { LinkElementProps };
export default LinkElement;
