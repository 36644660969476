import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "flex-start",
        marginTop: theme.spacing(2)
    },
    request: {
        position: "relative"
    },
    button: {
        width: "100%"
    },
    spinner: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
        zIndex: 1
    },
    response: {
        alignItems: "center",
        color: theme.palette.grey[500],
        display: "inline-flex",
        flexDirection: "row",
        marginTop: theme.spacing(1),
    },
    warning: {
        marginRight: theme.spacing(1)
    },
    text: {
        fontStyle: "italic"
    }
}));

export default useStyles;
