import React from "react";
import { Icon, Radio, RadioGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { IQuickSearchType } from "../../QuickSearch.d";
import styles from "./QuickSearchSelector.module.scss";

interface IQuickSearchSelectorProps {
    readonly show: boolean;
    readonly types: IQuickSearchType[];
    readonly currentSearch: string;
    readonly onChange: (selectedSearch: string) => void;
    readonly onClose: () => void;
}

const QuickSearchSelector = (props: IQuickSearchSelectorProps): JSX.Element => {
    if (props.show && props.types.length > 1) {
        const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
            props.onChange(event.currentTarget.value);
        };
        const handleClose = (): void => {
            props.onClose();
        };
        return (
            <div className={styles.root}>
                <Icon icon={IconNames.SMALL_CROSS} className={styles.closeButton} onClick={handleClose} />
                <RadioGroup inline onChange={handleChange} selectedValue={props.currentSearch}>
                    {props.types.map((searchType, index) => <Radio key={index} label={searchType.description} value={searchType.value} />)}
                </RadioGroup>
            </div>
        );
    }
    return <React.Fragment />;
}

export type { IQuickSearchSelectorProps };
export default QuickSearchSelector;
