import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
    },
    appBarButton: {
        color: theme.palette.grey[200],
        "&.Mui-disabled": {
            color: theme.palette.primary.main
        }
    },
    edgeEnd: {
        marginLeft: "auto"
    },
    tabBar: {
        backgroundColor: theme.palette.primary.light,
        paddingTop: theme.spacing(1),
    },
    tabContainer: {
        height: "100%",
        overflow: "hidden",
        position: "relative",
    },
    fabContainer: {

    },
    listItemPrimary: {
        display: "flex",
        flexDirection: "row",
    },
    listItemId: {
        display: "inline",
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1),
    },
    listItemSecondary: {
        color: theme.palette.grey[400]
    },
    outcomeReason: {
        color: theme.palette.grey[400],
        fontStyle: "italic"
    },
    waterSourceIcon: {
        "& rect": {
            fill: "transparent",
            stroke: "white"
        },
        "& text": {
            fill: "white",
            stroke: "white"
        }
    }
}));

export default useStyles;
