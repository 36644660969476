import { connect } from "react-redux";

import { IReduxState } from "../../store/types";
import InspectionMap, { InspectionMapProps } from "./InspectionMap.view";

type ConnectedProps = Pick<InspectionMapProps, "mapCentre">;

const mapProps = (state: IReduxState): ConnectedProps => {
    const { view } = state.map.mapSettings ?? {}
    return {
        mapCentre: view
            ? [view.centre.x, view.centre.y]
            : [0, 0]
    };
};

export default connect(mapProps)(InspectionMap);