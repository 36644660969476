import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import executeQuery from '../../lib/executeQuery';
import { NodeID, encodeNodeId } from '../../lib/nodeIdentifier';
import { guid } from '../../lib/Utils';
import { ILookupItem, IEditMutation } from '../types';
import { setGlobalToast, setLoadingToast } from './app';
import { setError } from './errors';
import addTagsMutation from './graphQL/addTags';
import removeTagsMutation from './graphQL/removeTags';
import { setRenderPoints } from './map';
import { addTagsToSelectedWaterSource, removeTagsFromSelectedWaterSource } from './waterSources';

interface TagData {
    readonly items: number[];
}

export const addTag = (objectNodeId: NodeID, tagIds: number[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Adding tag...'));

            const newTagsInput: IEditMutation<TagData> = {
                input: {
                    nodeId: encodeNodeId(objectNodeId),
                    clientMutationId: guid(),
                    data: {
                        items: tagIds
                    }
                }
            };
            const updatedObject = await executeQuery<{ tags: { add: { object: { tags: ILookupItem[] } } } }>(addTagsMutation, newTagsInput)

            dispatch(setGlobalToast({
                type: 'SUCCESS',
                message: `Tag added.`,
                showToast: true,
                timeout: 5000
            }));
            dispatch(setRenderPoints(true));
            dispatch(addTagsToSelectedWaterSource(updatedObject?.tags.add.object.tags ?? []));
        }
        catch (err) {
            dispatch(setError('Error adding tag', err));
        }
    }
}

export const removeTag = (objectNodeId: NodeID, tagId: number[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Removing tag...'));

            const removeTagsInput: IEditMutation<TagData> = {
                input: {
                    nodeId: encodeNodeId(objectNodeId),
                    clientMutationId: guid(),
                    data: {
                        items: tagId
                    }
                }
            }
            const updatedObject = await executeQuery<{ tags: { remove: { object: { tags: ILookupItem[] } } } }>(removeTagsMutation, removeTagsInput);

            dispatch(setGlobalToast({
                type: 'SUCCESS',
                message: `Tag removed.`,
                showToast: true,
                timeout: 5000
            }));
            dispatch(setRenderPoints(true));
            dispatch(removeTagsFromSelectedWaterSource(updatedObject?.tags.remove.object.tags ?? []));
        }
        catch (err) {
            dispatch(setError('Error removing tag', err));
        }
    }
}