import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";

import { MapClickAction, IScheme } from "../../../../../store/types";
import WaterSourceItem from "./components/WaterSourceItem";

const useStyles = makeStyles(theme => createStyles({
    root: {
        padding: theme.spacing(2),
        color: "white"
    }
}));

interface WaterSourceListTabProps {
    readonly scheme: IScheme;
    readonly mapClickAction: MapClickAction;
}

const WaterSourceListTab = ({ scheme }: WaterSourceListTabProps): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <List disablePadding>
                {scheme.waterSources.map(waterSource =>
                    <WaterSourceItem key={waterSource.waterSourceNodeId} scheme={scheme} waterSource={waterSource} />
                )}
            </List>
        </div>
    );
};

export type { WaterSourceListTabProps };
export default WaterSourceListTab;