import React from "react";
import cx from "classnames";
import { LocalDate } from "@js-joda/core";

import { longDateFormatter } from "../../../../lib/Utils";
import { containerClass, inputClass, labelClass } from "../../labelledField.utils";
import { ElementProps } from "../../types";
import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";
import { SelectOption } from "../selectElement";
import Lines from "../Lines";
import styles from "./readOnlyElement.module.scss";

interface ReadOnlyElementProps extends ElementProps {
    readonly normalcase?: boolean;
    readonly value: string | number | SelectOption | LocalDate | boolean | undefined;
}

const asString = (source: string | number | SelectOption | LocalDate | boolean | undefined): string | string[] => {
    if (typeof source === "undefined" || source === null) {
        return "-";
    }
    if (typeof source === "string") {
        return source.split('\n');
    }
    if (typeof source === "number") {
        return source.toString();
    }
    if (typeof source === "boolean") {
        return source ? "Yes" : "No";
    }
    if (source instanceof LocalDate) {
        return source.format(longDateFormatter);
    }
    if ("displayText" in source) {
        return source.displayText;
    }
    console.error("Unexpected type for parameter `source`.", source);
    return "";
};

const ReadOnlyElement = (props: ReadOnlyElementProps): JSX.Element => {
    const value = asString(props.value);
    return (
        <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
            <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />
            <span className={cx(styles.value, inputClass(props), { [styles.normalCase]: props.normalcase })} id={props.id}>
                <Lines>{value}</Lines>
            </span>
        </ElementContainer>
    );
};

export type { ReadOnlyElementProps };
export default ReadOnlyElement;