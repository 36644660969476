import React from "react";
import Dialog from "../../../components/dialog/dialog";
import DialogActions from "../../../components/dialog/dialogActions";
import DialogContent from "../../../components/dialog/dialogContent";
import DialogTitle from "../../../components/dialog/dialogTitle";
import executeQuery from "../../../lib/executeQuery";
import { guid } from "../../../lib/Utils";
import { IConfigurableLookups, ILookupItem } from "../../../store/types";
import { convertLookupKeyToLookupType, GraphQlLookupType } from "../utils";
import archiveLookupMutation from '../../../store/actions/graphQL/archiveLookup';

export interface IArchiveLookupModalProps {
    readonly isOpen: boolean;
    readonly lookupItem?: ILookupItem;
    readonly lookupType: keyof IConfigurableLookups;
    readonly handleClose: (updatedLookups?: ILookupItem[], updatedLookupType?: keyof IConfigurableLookups, updatedLookupId?: number) => void;
}

interface IArchiveLookupMutationInput {
    readonly clientMutationId: string;
    readonly lookup: GraphQlLookupType;
    readonly data: {
        readonly values: number[];
    };
}

interface IArchiveLookupMutationResponse {
    readonly archiveItems: {
        readonly clientMutationId: string;
        readonly errors: string[];
        readonly hasErrors: boolean;
        readonly lookup: {
            readonly name: GraphQlLookupType;
            readonly items: ILookupItem[];
        }
    }
}

const AssignInspectorsDialog = (props: IArchiveLookupModalProps): JSX.Element => {
    const handleSave = async (): Promise<void> => {
        const lookup = convertLookupKeyToLookupType(props.lookupType);
        if (lookup && props.lookupItem) {
            const input: IArchiveLookupMutationInput = {
                clientMutationId: guid(),
                lookup,
                data: {
                    values: [props.lookupItem.value]
                }
            }
            const res = await executeQuery<{ lookups: IArchiveLookupMutationResponse }>(archiveLookupMutation, {input});
            props.handleClose(res?.lookups.archiveItems.lookup.items, props.lookupType, props.lookupItem.value);
        }
    }
    return (
        <Dialog fullWidth maxWidth="sm" open={props.isOpen} onClose={props.handleClose}>
            <DialogTitle onClose={props.handleClose}>Archive Lookup Item</DialogTitle>
            <DialogContent>
                {`Are you sure you want to archive "${props.lookupItem?.displayText}"?`}
            </DialogContent>
            <DialogActions>
                <button id="archiveLookup-save" className="action-button" onClick={handleSave}>Confirm</button>
                <button id="archiveLookup-cancel" className="action-button action-button--cancel" onClick={(): void => props.handleClose()}>Cancel</button>
            </DialogActions>
        </Dialog>
    );
}

export default AssignInspectorsDialog;