import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    form: {

    },
    actionsContainer: {
        marginTop: theme.spacing(2)
    },
    button: {
        width: "100%"
    }
}));

export default useStyles;