export default `
query inspectionList_Query($id: ID!) {
    node(id:$id) {
        id
        ... on IWaterSource {
            inspections {
                inspectionNodeId: id
                inspectionId
                type {
                    value
                    displayText
                }
                inspectionDate
                description
            }
        }
    }
}`;