import React from 'react';

import { formatLastEvent } from '../../../lib/Utils';
import { IRoute, ILookupItem } from '../../../store/types';
import LabelledField, { SelectOption } from '../../labelledField';
import Typography from '../../shared/typography';
import styles from "./RouteDetails.module.scss";

interface IRouteDetailsProps {
    readonly routeData: Pick<IRoute, "name" | "station" | "lastEvent">;
    readonly stations: ILookupItem[];
    readonly editing: boolean;
    readonly onNameChange: (name: string | undefined) => void;
    readonly onStationChange: (station: SelectOption | undefined) => void;
}

export const RouteDetails = (props: IRouteDetailsProps): JSX.Element => {
    const { routeData, stations, onNameChange, onStationChange, editing } = props;
    return (
        <div className={styles.root}>
            <LabelledField
                fieldType="text"
                id="route-name"
                label="Name"
                classes={{ container: styles.input }}
                value={routeData.name}
                editing={editing}
                onChange={(value: string | undefined): void => onNameChange(value)}
            />
            <LabelledField
                fieldType="select"
                id="route-station"
                label="Station"
                classes={{ container: styles.input }}
                value={routeData.station}
                selectOptions={stations}
                editing={editing}
                onChange={(value): void => onStationChange(value)}
                onClear={(): void => onStationChange(undefined)}
            />
            <Typography scale="caption">{formatLastEvent(routeData.lastEvent)}</Typography>
        </div>
    );
}

export type { IRouteDetailsProps };
export default RouteDetails;
