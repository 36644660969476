import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { IDefect, IInspection, Repair, isDefect, isInspection, isRepair } from "../../../../store/types";
import DefectControl from "../DefectControl";
import InspectionControl from "../InspectionControl";
import RepairControl from "../RepairControl";

type ContentType = "INSPECTION" | "DEFECT" | "REPAIR";

interface ViewSelectedChildProps {
    readonly child?: IDefect | IInspection | Repair;
    readonly onRefresh?: () => void;
}

const ViewSelectedChild = (props: ViewSelectedChildProps): JSX.Element => {
    const { child, onRefresh } = props;
    if (isDefect(child)) {
        return <DefectControl selectedDefect={child} hideNavButton onRefresh={onRefresh} />;
    }
    if (isInspection(child)) {
        return <InspectionControl selectedInspection={child} hideNavButton onRefresh={onRefresh} />;
    }
    if (isRepair(child)) {
        return <RepairControl selectedRepair={child} hideNavButton onRefresh={onRefresh} />;
    }
    return (
        <Box display="flex" justifyContent="center" paddingTop={1}>
            <Typography variant="body2">
                Select an item from the panel on the left and it will load here.
            </Typography>
        </Box>
    );
};

export type { ContentType, ViewSelectedChildProps };
export default ViewSelectedChild;
