import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import { PropsWithEditing, SelectProps, PropsWithShow } from "../types";
import Optional from "../../../shared/Optional";

const WaterSourcePlateLocation = ({ editing, show, options, value, onClear, onSelect }: PropsWithEditing<PropsWithShow<SelectProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "select",
        id: "water-source-plateLocation",
        label: "Plate Location",
        classes: { container: "input__group", label: "input__label" },
        value,
        selectOptions: options,
        editing: editing ?? false,
        onChange: value => onSelect?.(value),
        onClear
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default WaterSourcePlateLocation;
