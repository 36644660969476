import React from "react";
import classnames from "classnames";

import { EditElementProps } from "../../types";
import { containerClass, inputClass, labelClass } from "../../labelledField.utils";

import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";
import ReadOnlyElement from "../readOnlyElement";

import SelectOptionGroups from "./components/SelectOptionGroups";
import SelectOptions from "./components/SelectOptions";
import { SelectOption } from "./types";
import styles from "./selectElement.module.scss";

interface SelectElementProps extends EditElementProps<SelectOption> {
    readonly selectOptions?: SelectOption[];
    readonly selectOptionGroups?: SelectOption[];
    readonly noOptionsMessage?: string;
    readonly onClear?: () => void;
    readonly disabled?: boolean;
    readonly withBlankOption?: boolean;
    readonly blankOptionText?: string;
}


const getItem = (collection: SelectOption[] | undefined, value: string | number): SelectOption | undefined => {
    return collection
        ? collection.find(option => option.value.toString() === value.toString())
        : undefined;
};

const buildOptions = (selectOptions: SelectOption[] | undefined, currentItem: SelectOption | undefined): SelectOption[] => {
    if (selectOptions === undefined && currentItem === undefined) {
        return [];
    }

    if (selectOptions === undefined) {
        return currentItem
            ? currentItem.disabled
                ? []
                : [currentItem]
            : [];
    }

    if (currentItem) {
        return currentItem.disabled || selectOptions.find(item => item.value === currentItem.value)
            ? selectOptions
            : [...selectOptions, currentItem];
    }

    return selectOptions;
};

const SelectElement = (props: SelectElementProps): JSX.Element => {
    const { value: currentItem, withBlankOption = true, blankOptionText } = props;
    if (props.editing) {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
            const value = e.target.value;
            const result = getItem(props.selectOptions, value);
            props.onChange(result);
        };
        const handleClear = (): void => {
            props.onClear?.();
        }

        const labelPosition = props.labelPosition ?? "TOP";
        const css = classnames("input", inputClass(props));
        const options = buildOptions(props.selectOptions, currentItem);
        const canShowInput = options.length > 0;
        const canShowClear = Boolean(props.onClear) && canShowInput;
        const canShowFooter = labelPosition === "BOTTOM" || canShowClear;

        return (
            <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
                {labelPosition === "TOP" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
                <div className={styles.inputGroup}>
                    {canShowInput
                        ? (
                            <select id={props.id} name={props.id} title={props.label} className={css} value={currentItem?.value ?? ""} onChange={handleChange} disabled={props.disabled}>
                                {withBlankOption && <option value="">{blankOptionText ?? "Please select..."}</option>}
                                {props.selectOptionGroups
                                    ? <SelectOptionGroups selectOptionGroups={props.selectOptionGroups} selectOptions={options} />
                                    : <SelectOptions selectOptions={options} />
                                }
                            </select>
                        )
                        : <span className={styles.noOptionsMessage}>{props.noOptionsMessage ? props.noOptionsMessage : "No items"}</span>
                    }
                    {canShowFooter && (
                        <div className={styles.footer}>
                            {labelPosition === "BOTTOM" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
                            {canShowClear && <span className={styles.clearButton} onClick={handleClear}>clear</span>}
                        </div>
                    )}
                </div>
            </ElementContainer>
        );
    }

    return <ReadOnlyElement id={props.id} label={props.label} noMargin={props.noMargin} classes={props.classes} value={currentItem?.displayText} />;
};

export type { SelectElementProps };
export default SelectElement;