import React from "react";

import type { CreateRepairDialogProps as BaseProps } from "./CreateRepairDialog";
import CreateRepairDialog from "./CreateRepairDialog.redux";

type CreateRepairDialogProps = Pick<BaseProps, "isOpen" | "onClose">

const CreateExternalRepairDialog = (props: Pick<CreateRepairDialogProps, "isOpen" | "onClose">): JSX.Element => {
    return <CreateRepairDialog repairCategory="external" {...props} />;
};

const CreateInternalRepairDialog = (props: Pick<CreateRepairDialogProps, "isOpen" | "onClose">): JSX.Element => {
    return <CreateRepairDialog repairCategory="internal" {...props} />;
};

export type { CreateRepairDialogProps };
export { CreateExternalRepairDialog, CreateInternalRepairDialog };
export default CreateRepairDialog;