import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import changePassword from "../../../../api/changePassword";

interface AppBarChangePasswordProps {
    readonly onSuccess?: (message: string) => void;
    readonly onError?: (message: string, error?: Error) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
}));

const AppBarChangePassword = (props: AppBarChangePasswordProps): JSX.Element => {
    const styles = useStyles();
    const { onError, onSuccess } = props;

    const handleChangePasswordClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
        event.preventDefault();
        changePassword()
            .then(() => onSuccess?.("Change Password request sent"))
            .catch(error => onError?.("Error sending Change Password request", error))
    };

    return (
        <Link id="appBar-user-changePassword" className={styles.root} href="#" variant="body2" onClick={handleChangePasswordClick}>
            Change Password
        </Link>
    );
};

export type { AppBarChangePasswordProps };
export default AppBarChangePassword;