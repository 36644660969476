import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import executeQuery from '../../lib/executeQuery';
import { guid } from '../../lib/Utils';
import { IHazard } from '../types';
import { setLoadingToast, setSuccessToast } from './app';
import { setError } from './errors';
import addHazardsMutation from './graphQL/addHazards';
import removeHazardsMutation from './graphQL/removeHazards';

export const addHazards = (hazards: { hazardTypeId: number }[], nodeId: string) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Updating hazards...'));

            const variables = {
                hazards: {
                    clientMutationId: guid(),
                    nodeId: nodeId,
                    data: hazards
                }
            };
            await executeQuery<{ addHazards: { waterSource: { hazards: IHazard[] } } }>(addHazardsMutation, variables);

            dispatch(setSuccessToast("Updated water source hazards", 5000));
        }
        catch (error) {
            dispatch(setError('Error creating hazards', error));
        }
    }
};

export const removeHazards = (hazardNodeIds: string[], nodeId: string) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Updating hazards...'));

            const variables = {
                input: {
                    clientMutationId: guid(),
                    nodeId: nodeId,
                    data: {
                        items: hazardNodeIds
                    }
                }
            };
            await executeQuery<{ removeHazards: { waterSource: { hazards: IHazard[] } } }>(removeHazardsMutation, variables);

            dispatch(setSuccessToast("Updated water source hazards", 5000));
        }
        catch (error) {
            dispatch(setError('Error removing hazards', error));
        }
    }
};
