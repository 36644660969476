import { useEffect } from "react";

import { IAddress } from "../../../store/types";

interface EditState {
    readonly address: IAddress | undefined;
}

const useAddressUpdate = (address: IAddress | undefined, state: EditState, dispatch: (address: IAddress | undefined) => void): void => {
    const { addressId, paon: { description = undefined } = {}, streetDescription, locality, town, postCode } = address ?? {};
    useEffect(() => {
        if (state.address?.addressId === addressId) {
            dispatch(address);
        }
    }, [address, addressId, description, streetDescription, locality, town, postCode]);
};

export default useAddressUpdate;