import React from "react";

import { ISavedSearch } from "../../../store/types";
import styles from "./searchSelector.module.scss";
import LabelledField, { SelectOption } from "../../labelledField";
import { useHistory } from "react-router-dom";
import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";

type SearchEntity = "water-sources" | "inspections" | "defects" | "repairs" | "schemes" | "routes";

interface ISearchSelectorProps {
    readonly entity: SearchEntity;
    readonly savedSearches: ISavedSearch[];
    readonly selected?: ISavedSearch;
    readonly label?: string;
    readonly noOptionsLabel?: string;
    readonly onChange?: (searchNodeId: NodeID | undefined) => void;
    readonly onClear?: () => void;
}

const getSearch = (options: SelectOption[], selected: ISavedSearch | undefined): SelectOption | undefined => {
    return selected
        ? options.find(option => option.value === selected.id)
        : undefined;
};

const SearchSelector = (props: ISearchSelectorProps): JSX.Element => {
    const { entity, savedSearches, selected, label, noOptionsLabel, onChange, onClear } = props;

    const handleChange = (value: SelectOption | undefined): void => {
        const searchId = value && typeof value.value === "string"
            ? parseNodeId(value.value)
            : undefined;
        onChange?.(searchId);
    };
    const handleClear = (): void => {
        onClear?.();
    };

    const options: SelectOption[] = savedSearches.map((search, index) => ({
        value: search.id,
        displayText: search.displayText,
        enabled: true,
        sortOrder: index
    }));

    return props.savedSearches?.length > 0
        ? (
            <div className={styles.root}>
                <LabelledField
                    fieldType="select"
                    id={`saved-searches-${entity}`}
                    label={label ?? "Saved Searches"}
                    noOptionsMessage={noOptionsLabel ?? "No Saved Searches"}
                    classes={{ container: styles.inputContainer, label: styles.inputLabel }}
                    editing
                    value={getSearch(options, selected)}
                    selectOptions={options}
                    onChange={handleChange}
                    onClear={handleClear}
                />
            </div>
        )
        : <React.Fragment />;
};

export type { ISearchSelectorProps, SearchEntity };
export default SearchSelector;