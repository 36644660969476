import React, { useCallback, useMemo } from "react";

import { Box } from "@material-ui/core";

import * as coords from "../../../../lib/coordinates";
import { IPoint, ILookupItem, Owner, AddressNode } from "../../../../store/types";
import Field from "../../../card/components/field";
import Optional from "../../../shared/Optional";
import { isHydrant } from "../utils";
import Editor from "./components/Editor";
import HydrantLocation from "./components/HydrantLocation";
import LocationDescription from "./components/LocationDescription";
import Selector from "./components/Selector";
import { toAddressDetails } from "../../../address/types";
import { isSavedAddress } from "../../../address/utils";
import { AddressChanged } from "./components/types";

type LocationChangeKeys = keyof Pick<WaterSourceLocationProps, "address" | "description" | "hydrantLocation">;
type LocationChangeValues = WaterSourceLocationProps[LocationChangeKeys];

interface WaterSourceLocationChange {
    (key: LocationChangeKeys, value: LocationChangeValues): void;
}

interface WaterSourceLocationProps extends Pick<Owner, "category"> {
    readonly address: AddressNode | undefined;
    readonly description: string | undefined;
    readonly coordinates: IPoint | undefined;
    readonly hydrantLocation: ILookupItem | undefined;
    readonly editing?: boolean;
    readonly isMobile?: boolean;
    readonly onAddressUpdated?: AddressChanged;
    readonly onChange?: WaterSourceLocationChange;
}

const formatCoordinates = (coordinates: IPoint | undefined): string => {
    return coordinates
        ? coords.formatEastingsAndNorthings(coordinates)
        : "-";
};

const formatGridReference = (coordinates: IPoint | undefined): string => {
    return coordinates
        ? coords.formatGridReference(coordinates)
        : "-";
};

const formatAddressFields = (address: AddressNode | undefined): string[] => {
    const format = (s: string | undefined): string => s?.trim() ?? "";
    if (address) {
        const { paon, streetDescription, locality, town, postCode } = address;
        const fields = [paon?.description, streetDescription, locality, town, postCode];
        const result = fields.map(format).filter(s => s);
        return result;
    }
    return ["-"];
};
const WaterSourceLocation = (props: WaterSourceLocationProps): JSX.Element => {
    const { category, editing, hydrantLocation, isMobile, onAddressUpdated, onChange } = props;
    const { address, coordinates, description } = props;

    const handleHydrantLocationChange = (item: ILookupItem | undefined): void => {
        onChange?.("hydrantLocation", item);
    };

    const handleLocationDescriptionChange = (value: string | undefined): void => {
        onChange?.("description", value);
    };

    const handleAddressChange = (address: AddressNode): void => {
        onAddressUpdated?.(address);
    };
    const handleAddressSelect = (address: AddressNode | undefined): void => {
        onChange?.("address", address);
    };

    const showField = isHydrant(category);
    const addressFields = useMemo(() => formatAddressFields(address), [address, address?.addressId]);
    const initialAddress = useMemo(() => toAddressDetails(address ?? {}), [address, address?.addressId]);
    const saved = useMemo(() => isSavedAddress(initialAddress), [initialAddress]);
    
    const renderEditor = useCallback(() => {
        return <Editor show={editing} addressSaved={saved} initialAddress={initialAddress} onChange={handleAddressChange} />;
    }, [editing, address, onChange]);

    const renderSelector = useCallback(() => {
        return <Selector show={editing} actions={{ find: true, clear: saved }} onChange={handleAddressChange} onSelect={handleAddressSelect} />;
    }, [editing, onChange]);

    return (
        <Box p={2} overflow="auto" height="100%">
            <Field label="Address" text={addressFields} renderStart={renderSelector} renderEnd={renderEditor} />
            <LocationDescription description={description} editing={editing} onChange={handleLocationDescriptionChange} />
            <Optional hidden={!showField} >
                <HydrantLocation selected={hydrantLocation} editing={editing} native={isMobile} onChange={handleHydrantLocationChange} />
            </Optional>
            <Field label="Easting, Northing" text={formatCoordinates(coordinates)} />
            <Field label="Grid reference" text={formatGridReference(coordinates)} />
        </Box>
    );
};

export type { WaterSourceLocationProps, WaterSourceLocationChange, LocationChangeKeys, LocationChangeValues };
export default WaterSourceLocation;