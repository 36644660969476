import React, { useState } from "react";
import ReactStreetView from 'react-streetview';

import { makeStyles, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { StreetViewIcon } from "./StreetViewIcon";
import AppDialog from "../../../shared/appDialog";
import { IStreetView } from "../../../../store/types";

const useStyles = makeStyles(() => createStyles({
    streetViewIconContainer: {
        height: "24px",
        width: "24px"
    },
    reactStreetViewContainer: {
        height: "100%",
        width: "100%"
    }
}));

interface StreetViewMobileProps {
    readonly config: IStreetView;
    readonly onClose?: () => void;
}

const StreetViewMobile = (props: StreetViewMobileProps): JSX.Element => {
    const styles = useStyles();
    const { config } = props;
    const [showDialog, setShowDialog] = useState(false);

    const openDialog = (): void => setShowDialog(true);
    const closeDialog = (): void => setShowDialog(false);

    const handleClose = (): void => {
        props.onClose?.();
        closeDialog();
    };

    return (
        <React.Fragment>
            <ListItem button onClick={openDialog}>
                <ListItemIcon>
                    <div className={styles.streetViewIconContainer}>
                        <StreetViewIcon />
                    </div>
                </ListItemIcon>
                <ListItemText primary="Street view" />
            </ListItem>
            <AppDialog appBar={{ title: "Street View" }} open={showDialog} disableGutters onClose={handleClose}>
                <div className={styles.reactStreetViewContainer}>
                    <ReactStreetView
                        apiKey={config.apiKey}
                        streetViewPanoramaOptions={config.params}
                    />
                </div>
            </AppDialog>
        </React.Fragment>
    );
};

export type { StreetViewMobileProps };
export default StreetViewMobile;