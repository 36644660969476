import { IConnectionQuery, IConnection, IInspection, emptyPage, FilteredObject } from "../store/types";
import { IQueryFilter as IInspectionQueryFilter } from "../store/types/inspections";
import executeQuery from "../lib/executeQuery";

const gql = `
fragment LookupItem on LookupItem {
    displayText
    value
}
fragment Inspection on Inspection {
    inspectionNodeId: id
    inspectionId
    type {
        displayText
        value
    }
    inspectionDate
    waterSource {
        waterSourceNodeId: id
        waterSourceId: externalId
        category {
            ...LookupItem
        }
        classification {
            value
            displayText
        }
        location {
            address {
                paon {
                    description
                }
                streetDescription
                locality
                town
                postCode
            }
            coordinates {
                x
                y
            }
            description
        }
        isActive
        isOperable {
            value
            isOverridden
        }
        isDefective {
            value
            isOverridden
        }
        status {
            ...LookupItem
        }
        riskSeverity {
            ...LookupItem
            symbolName
        }
        hazardSeverity {
            ...LookupItem
            symbolName
        }
        lastInspectionDate
        nextInspectionDate
        recommendedInspectionDate
        routeSortOrder
        inspectionCount(includeClosed:false)
        ... on ITagged {
            tags {
                ...LookupItem
                enabled
            }
        }
        isSecondPersonRequired
        roadSpeed {
            ...LookupItem
        }
        hazardFlag {
            ...LookupItem
        }
    }
}
query getInspections($filter:QueryFilter, $paging:QueryControlInput) {
    inspections(queryFilter:$filter, queryControl:$paging) {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        edges {
            index
            node {
                ...Inspection
            }
        }
    }
}`;

const getInspections = async (filters: IConnectionQuery<FilteredObject<IInspectionQueryFilter>>): Promise<IConnection<IInspection>> => {
    const response = await executeQuery<{ inspections: IConnection<IInspection> }>(gql, filters);
    return response?.inspections ?? {
        edges: [],
        items: [],
        pageInfo: emptyPage,
        totalCount: 0
    };
};

export default getInspections;