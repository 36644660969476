import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    tile: {
        backgroundColor: theme.palette.primary.main,
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
    }
}));

export default useStyles;
