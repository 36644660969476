import React from "react";

import SearchIcon from '@material-ui/icons/Search';

import AddressActionButton from "../AddressActionButton";

interface FindAddressButtonProps {
    readonly edge?: 'start' | 'end' | false;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const FindAddressButton = ({ edge, onClick }: FindAddressButtonProps): JSX.Element => {
    return (
        <AddressActionButton title="Find Address" size="small" edge={edge} onClick={onClick}>
            <SearchIcon />
        </AddressActionButton>
    );
};

export type { FindAddressButtonProps };
export default FindAddressButton;