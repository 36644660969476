import React from 'react';
import cls from "classnames";
import { Pagination } from '@3tc/shared-components';

import { IQuickSearchItem } from '../../../../store/types';
import { IQuickSearchSelectHandler } from "../../QuickSearch.d";
import QuickSearchResultItem from '../quickSearchResultItem';
import styles from "./QuickSearchResult.module.scss";

interface IQuickSearchResultProps {
    readonly show?: boolean;
    readonly items: ReadonlyArray<IQuickSearchItem>;
    readonly pageSize: number;
    readonly pageNumber: number;
    readonly totalPages: number;
    readonly totalCount: number;
    readonly isInline?: boolean;
    readonly onSelect: IQuickSearchSelectHandler;
    readonly onGotoFirst: () => void;
    readonly onGotoLast: () => void;
    readonly onGotoNext: () => void;
    readonly onGotoPrevious: () => void;
    readonly onClose: () => void;
}

const QuickSearchResult = (props: IQuickSearchResultProps): JSX.Element => {
    const handleItemSelect = (item: IQuickSearchItem): void => {
        props.onSelect(item);
    };

    return props.show ?? true
        ? (
            <div className={cls(styles.root, { [styles.inline]: props.isInline })}>
                <ul className={styles.results}>
                    {props.items.map((item, index) => <QuickSearchResultItem key={index} item={item} isInline={props.isInline} onSelect={handleItemSelect} />)}
                </ul>
                <div className={styles.pagination}>
                    <Pagination
                        tableContent={props.items}
                        totalResults={props.totalCount}
                        pageNumber={props.pageNumber}
                        pageCount={props.totalPages}
                        firstPageClickHandler={props.onGotoFirst}
                        lastPageClickHandler={props.onGotoLast}
                        nextPageClickHandler={props.onGotoNext}
                        previousPageClickHandler={props.onGotoPrevious}
                    />
                </div>
            </div>
        )
        : <React.Fragment />;
};

export type { IQuickSearchResultProps };
export default QuickSearchResult;
