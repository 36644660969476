import { useState, useCallback } from "react";

import { FetchStatus } from "../../api/hooks";
import { FetchSingle } from "../../api/hooks/types";
import useIsMounted from "../../api/hooks/useIsMounted";
import executeQuery from "../../lib/executeQuery";
import { IConnectionQuery, IWaterSource, emptyPage, FilteredObject, IConnection } from "../../store/types";
import { IWaterSourceQueryFilter as IQueryFilter } from "../../store/types/waterSources";
import searchQuery from "./waterSourceSearchQuery";
import useMountedEffect from "../../api/hooks/useMountedEffect";

const empty: IConnection<IWaterSource> = {
    edges: [],
    items: [],
    pageInfo: emptyPage,
    totalCount: 0
};

const getWaterSource = async (variables: IConnectionQuery<FilteredObject<IQueryFilter>>): Promise<IConnection<IWaterSource>> => {
    const response = await executeQuery<{ waterSources: IConnection<IWaterSource> }>(searchQuery, variables);
    return response?.waterSources ?? empty;
};


const useMobileSearch = (searchTerm: string): FetchSingle<IWaterSource, string> => {
    const [status, setStatus] = useState<FetchStatus>("ready");
    const [result, setResult] = useState<IWaterSource>();

    const [isMounted, ifMounted] = useIsMounted();

    const fetch = useCallback((externalId?: string) => {
        if (externalId) {
            // search is deliberately for exact matches only, and returns the first water source if there is more than one
            const variables: IConnectionQuery<FilteredObject<IQueryFilter>> = {
                filter: {
                    externalId: {
                        "$like": externalId
                    }
                },
                paging: {
                    pageIndex: 1,
                    pageSize: 1,
                    sortBy: "externalId",
                    sortDirection: "ASCENDING"
                }
            };
            setStatus("loading");
            getWaterSource(variables)
                .then(page => {
                    if (page.items.length > 0) {
                        ifMounted(() => setResult(page.items[0]));
                    }
                    else {
                        ifMounted(() => setResult(undefined));
                    }
                })
                .catch(() => {
                    ifMounted(() => setResult(undefined))
                })
                .finally(() => {
                    ifMounted(() => setStatus("complete"))
                });
        }
    }, [isMounted]);

    useMountedEffect(ifMounted => {
        ifMounted(() => fetch(searchTerm));
    }, [searchTerm]);

    return [status, result, fetch];
};

export default useMobileSearch;
