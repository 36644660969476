import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { AccessControl } from '../../../../../auth/components';
import { RoleNames } from '../../../../../auth/roles';
import {
    MapClickAction,
    IScheme,
    ILookupItem,
    IPolygon,
    AddressNode
} from '../../../../../store/types';
import Address, { AddressDetailsData, toAddressNode } from '../../../../address';
import Divider from '../../../../Divider';
import FileUploader from '../../../../files/fileUploader';
import NotesTab from '../../../../notes/NotesTab';
import GlobalNotice, { GlobalNoticeProps } from '../../../../shared/globalNotice';
import SchemeButtons from './components/schemeButtons';
import SchemeDetails, { ISchemeDetailsData } from './components/schemeDetails';
import SchemeWorfkflow, { SchemeWorkflowData } from './components/schemeWorkflow';
import useStyles from './schemeDetailsTab.styles';

interface SchemeDetailsTabProps {
    readonly scheme: IScheme;
    readonly schemeStages: ILookupItem[];
    readonly schemeTypes: ILookupItem[];
    readonly stations: ILookupItem[];
    readonly mapClickAction: MapClickAction;
    readonly editing?: boolean;
    readonly hasBoundary?: boolean;
    readonly onClearMapAction?: () => void;
    readonly onEditing?: (editing: boolean) => void;
    readonly onError?: (message: string) => void;
    readonly onUpdateBoundary?: (schemeNodeId: string, boundary: IPolygon | undefined) => void;
    readonly onUpdateScheme?: (schemeNodeId: string, details: ISchemeDetailsData | undefined, address: AddressDetailsData | undefined, workflow: SchemeWorkflowData | undefined) => Promise<void>;
}

const formatSchemeStatus = (scheme: IScheme): string => {
    return scheme.stage.isOpen ? "Open" : "Closed";
};

const initDetails = (scheme: IScheme): ISchemeDetailsData => ({
    type: scheme.type,
    title: scheme.title,
    reference: scheme.reference,
    description: scheme.description,
    station: scheme.station,
});

const initAddress = (scheme: IScheme): AddressDetailsData | undefined => {
    const { address } = scheme;
    if (address) {
        return {
            addressNodeId: address.addressNodeId ?? undefined,
            addressId: address.addressId ?? undefined,
            description: address.paon?.description ?? undefined,
            locality: address.locality ?? undefined,
            town: address.town ?? undefined,
            streetDescription: address.streetDescription ?? undefined,
            postCode: address.postCode ?? undefined,
        };
    }
    return undefined;
};

const initWorkflow = (scheme: IScheme): SchemeWorkflowData => ({
    stage: scheme.stage,
    dateReceived: scheme.dateReceived,
    dateReturned: scheme.dateReturned,
    dateOrdered: scheme.dateOrdered,
    orderReference: scheme.orderReference,
    invoiceDueDate: scheme.invoiceDueDate,
    invoiceReference: scheme.invoiceReference,
    cost: scheme.cost,
    dateClosedExternally: scheme.dateClosedExternally,
    dateClosed: scheme.dateClosed
});

const editBoundaryNoticeMessage = 'Press the ESC key to save the boundary and exit drawing mode';

const SchemeDetailsTab = (props: SchemeDetailsTabProps): JSX.Element => {
    const styles = useStyles();

    const { scheme, mapClickAction, editing, hasBoundary } = props;

    const [address, setAddress] = useState(initAddress(scheme));
    const [details, setDetails] = useState(initDetails(scheme));
    const [workflow, setWorkflow] = useState(initWorkflow(scheme));
    const [shouldValidate, setShouldValidate] = useState(false);
    const prevMapClickAction = useRef(mapClickAction);

    const refreshState = useCallback((): void => {
        setAddress(initAddress(scheme));
        setDetails(initDetails(scheme));
        setWorkflow(initWorkflow(scheme));
        setShouldValidate(false);
    }, [scheme]);

    const { schemeNodeId, boundary } = scheme;

    const updateAddress = (updated: AddressNode): void => {
        setAddress({
            addressNodeId: updated.addressNodeId,
            addressId: updated.addressId,
            description: updated.paon?.description,
            streetDescription: updated.streetDescription,
            locality: updated.locality,
            town: updated.town,
            postCode: updated.postCode
        });
    };

    const handleCancelEdit = (): void => {
        refreshState();
        props.onClearMapAction?.();
        props.onEditing?.(false);
    };

    const handleEdit = (): void => {
        props.onEditing?.(true);
    };

    const handleSave = async (): Promise<void> => {
        setShouldValidate(true);
        if (details.reference && details.title && workflow.stage) {
            handleCancelEdit();
            await props.onUpdateScheme?.(schemeNodeId, details, address, workflow);
        } else {
            props.onError?.("Please complete all required fields.");
        }
    };

    const handleAddressClear = (): void => {
        setAddress(undefined);
    };
    const handleAddressSelect = (selected: AddressDetailsData): void => {
        updateAddress(toAddressNode(selected));
    };
    const handleAddressUpdate = (updated: AddressDetailsData): void => {
        if (updated.addressNodeId === address?.addressNodeId) {
            updateAddress(toAddressNode(updated));
        }
    };

    const handleDetailsChange = (details: ISchemeDetailsData): void => {
        setDetails(details);
    };

    const handleWorfklowChange = (workflow: SchemeWorkflowData): void => {
        setWorkflow(workflow);
    };

    useEffect(() => {
        refreshState();
    }, [refreshState])

    useEffect(() => {
        if (prevMapClickAction.current === "DRAW_POLYGON" && hasBoundary) {
            props.onUpdateBoundary?.(schemeNodeId, boundary);
        }

        if (mapClickAction !== prevMapClickAction.current) {
            prevMapClickAction.current = mapClickAction;
        }
    }, [mapClickAction, schemeNodeId, boundary, prevMapClickAction.current, hasBoundary]);

    const notice: GlobalNoticeProps = useMemo<GlobalNoticeProps>(() => {
        const show = mapClickAction === "EDIT_POLYGON";
        return {
            message: editBoundaryNoticeMessage,
            show,
            onKeyDown: (): void => {
                props.onUpdateBoundary?.(schemeNodeId, boundary);
                props.onClearMapAction?.();
            }
        };
    }, [mapClickAction, schemeNodeId, boundary]);

    return (
        <div>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1" className={styles.status}>{formatSchemeStatus(scheme)}</Typography>
                <AccessControl role={RoleNames.SCHEMES_ALL}>
                    <SchemeButtons editing={editing} onCancel={handleCancelEdit} onEditing={handleEdit} onSave={handleSave} />
                </AccessControl>
            </Box>
            <Divider />
            <SchemeDetails
                data={details}
                stations={props.stations}
                types={props.schemeTypes}
                editing={editing ?? false}
                onChange={handleDetailsChange}
            />
            <Divider />
            <Address
                id="scheme"
                address={address}
                owner="scheme"
                editing={editing}
                showHeader
                onClear={handleAddressClear}
                onSelect={handleAddressSelect}
                onUpdate={handleAddressUpdate}
            />
            <Divider />
            <SchemeWorfkflow
                data={workflow}
                events={scheme.events}
                schemeStages={props.schemeStages}
                editing={editing}
                onChange={handleWorfklowChange}
            />
            <Divider />
            <FileUploader entity="SCHEME" entityId={scheme.schemeId.toString()} />
            <NotesTab objectType="SCHEME" objectNodeId={schemeNodeId} notes={scheme.notes} />
            <GlobalNotice {...notice} />
        </div>
    );
}

export type { SchemeDetailsTabProps };
export default SchemeDetailsTab;