import React from "react";
import cx from "classnames";

import styles from "./typography.module.scss";

type TypographyScale = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "button" | "caption" | "overline";
type TextTransform = "capitalize" | "lowercase" | "uppercase" | "none";

interface ITypographyProps {
    readonly scale?: TypographyScale;
    readonly transform?: TextTransform;
    readonly className?: string;
    readonly element?: (keyof React.ReactHTML) | React.FunctionComponent<unknown> | React.ComponentClass<unknown>;
}

const Typography = (props: React.PropsWithChildren<ITypographyProps>): JSX.Element => {
    const className = cx(
        styles.typography,
        styles[props.scale ?? "body1"] ?? styles.body1,
        styles[props.transform ?? "none"],
        props.className
    );
    const element = React.createElement<{ className?: string }>(props.element ?? "div", { className }, props.children);
    return element;
};

export type { TypographyScale, TextTransform, ITypographyProps };
export default Typography;