import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';

import EventIcon from "@material-ui/icons/Event";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from '@material-ui/icons/Search';
import WorkIcon from "@material-ui/icons/Work";

import View from '../../components/shared/view';
import useNavigation from '../../router/MobileRouter/hooks/useNavigation';
import useStyles from "./MobileStartView.styles";

const MobileStartView = (): JSX.Element => {
    const { gotoStations, gotoSearch, gotoNearestWaterSources, gotoUnplannedTasks } = useNavigation();
    const classes = useStyles();
    return (
        <View title="Home" disableRefresh>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                    <Card className={classes.tile}>
                        <CardActionArea onClick={(): void => gotoUnplannedTasks({ title: "My Work" })}>
                            <CardHeader avatar={<Avatar className={classes.avatar}><WorkIcon /></Avatar>} title="My Work" titleTypographyProps={{ color: "secondary" }} />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.tile}>
                        <CardActionArea onClick={(): void => gotoStations()}>
                            <CardHeader avatar={<Avatar className={classes.avatar}><EventIcon /></Avatar>} title="Routine" titleTypographyProps={{ color: "secondary" }} />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.tile}>
                        <CardActionArea onClick={(): void => gotoSearch()}>
                            <CardHeader avatar={<Avatar className={classes.avatar}><SearchIcon /></Avatar>} title="Search" titleTypographyProps={{ color: "secondary" }} />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.tile}>
                        <CardActionArea onClick={(): void => gotoNearestWaterSources()}>
                            <CardHeader avatar={<Avatar className={classes.avatar}><LocationOnIcon /></Avatar>} title="Near Me" titleTypographyProps={{ color: "secondary" }} />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </View>
    );
};

export default MobileStartView;