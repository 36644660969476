import { Coordinate as olCoordinate } from "ol/coordinate";

import { IConnection, IConnectionQuery } from "../store/types";
import { IWaterSourceSummary } from "../components/inspectionView";

import gqlQuery from '../store/actions/graphQL/getWaterSourcesByFilter';
import executeQuery from "../lib/executeQuery";

type Variables = IConnectionQuery<{
    readonly isActive: boolean;
    readonly coordinates: {
        "$in_circle": {
            "$centre": {
                "$x": number;
                "$y": number;
            };
            "$radius": number;
        };
    };
}>;

const getWaterSourcesInRadius = async (centre: olCoordinate, radius: number): Promise<IWaterSourceSummary[]> => {
    const variables: Variables = {
        filter: {
            isActive: true,
            coordinates: {
                "$in_circle": {
                    "$centre": {
                        "$x": centre[0],
                        "$y": centre[1]
                    },
                    "$radius": radius
                }
            }
        },
        paging: {
            pageIndex: 1,
            pageSize: 9999999,
            sortBy: "id",
            sortDirection: "ASCENDING"
        }
    };
    return executeQuery<{ waterSources: IConnection<IWaterSourceSummary> }>(gqlQuery, variables)
        .then(response => response?.waterSources)
        .then(waterSources => waterSources?.items)
        .then(items => items ?? []);
};

export default getWaterSourcesInRadius;