export enum NodeType {
    ADDRESS = "Address",
    CONTACT = "Contact",
    CREW = "Crew",
    DEFECT = "Defect",
    EWS = "EmergencyWaterSupply",
    HAZARD = "Hazard",
    HYDRANT = "Hydrant",
    INSPECTION = "Inspection",
    INSPECTION_GROUP = "InspectionGroup",
    NOTE = "Note",
    REPAIR = "Repair",
    RISER = "Riser",
    ROUTE = "Route",
    SCHEME = "Scheme",
    TECHNICIAN = "Technician",
    ORGANISATION = "Organisation",
    THOROUGHFARE = "Thoroughfare",
    UNKNOWN_WATERSOURCE = "UnknownWaterSource",
    WATER_PIPE = "WaterPipe"
}

export interface NodeID {
    readonly typeName: string;
    readonly id: number;
}

export const empty: NodeID = {
    typeName: "",
    id: 0
};

export const formatNodeId = (nodeId: NodeID): string => {
    const raw = `${nodeId.typeName}:${nodeId.id}`;
    return raw;
};

export const encodeNodeId = (nodeId: NodeID): string => {
    const raw = formatNodeId(nodeId);
    const encoded = btoa(raw);
    return encoded;
};

export const equals = (a: NodeID, b: NodeID): boolean => {
    const equals = (x: number, y: number): boolean => {
        if (isNaN(x) && isNaN(y)) {
            return true;
        }
        return x === y;
    }

    return (a.typeName === b.typeName) && equals(a.id, b.id);
};

export const parseNodeId = (nodeId: string): NodeID => {
    const raw = atob(nodeId);
    const fields = raw.split(":");
    const result = {
        typeName: fields[0],
        id: Number(fields[1])
    };
    return result;
};

export const NodeID = (typeName: string, id: number | string): NodeID => ({
    typeName,
    id: Number(id)
});
