import React from "react";

import { SelectOption } from "../types";
import { compareSelectOptions } from "../utils";

interface SelectOptionsProps {
    readonly selectOptions: SelectOption[];
}

const SelectOptions = (props: SelectOptionsProps): JSX.Element => {
    const options = props.selectOptions
        .filter(item => item.enabled)
        .sort(compareSelectOptions)
        .map(item => <option key={item.value} value={item.value}>{item.displayText}</option>);
    return (
        <React.Fragment>
            {options}
        </React.Fragment>
    );
};

export type { SelectOptionsProps };
export default SelectOptions;
