export default `
query getRoute_Query($id: ID!) {
    node(id:$id) {
        ... on Route {
            routeNodeId: id
            routeId
            name
            isActive
            station {
                value
                displayText
            }
            waterSources {
                waterSourceNodeId: id
                waterSourceId: externalId
                isActive
                location {
                    coordinates {
                        x
                        y
                    }
                    description
                    address {
                        addressNodeId: id
                        addressId
                        streetDescription
                    }
                }
                category {
                    value
                    displayText
                }
                isOperable {
                    value
                    isOverridden
                }
                isDefective {
                    value
                    isOverridden
                }
                status {
                    value
                }
                riskSeverity {
                    value
                    displayText
                }
                nextInspectionDate
                recommendedInspectionDate
                routeSortOrder
                ... on Hydrant {
                    surface {
                        value
                        displayText
                    }
                    hydrantLocation {
                        displayText
                        value
                    }
                }
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
            events {
                actor
                action
                timestamp
            }
            lastEvent {
                actor
                action
                timestamp
            }
        }        
    }
}`;