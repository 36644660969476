import { IFileStoreAction, IFileStore } from "../types/fileStore";

const defaultAction: IFileStoreAction = {
    type: "@@INIT"
};

const defaultState: IFileStore = {
    token: "00000000-0000-0000-0000-00000000000"
};

export default (state: IFileStore = defaultState, action: IFileStoreAction = defaultAction): IFileStore => {
    switch (action.type) {
        case "SET_FILESTORE":
            return action.fileStore
                ? { ...action.fileStore }
                : state;
        default:
            return state;
    }
};