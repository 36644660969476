import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from 'react-redux';

import { setGlobalToast } from '../../../store/actions/app';
import { IGlobalToast } from '../../../store/types/app';
import { IReduxState } from '../../../store/types/store';
import FileUploader, { IFileUploaderProps } from "./FileUploader";

type ConnectedProps = Pick<IFileUploaderProps, "accessRoles" | "fileStoreToken">;

const mapState = (state: IReduxState): ConnectedProps => {
    const { user } = state.app;
    const props: ConnectedProps = {
        accessRoles: user?.account?.access ?? [],
        fileStoreToken: state.fileStore.token
    };
    return props;
};

type ConnectedActions = Pick<IFileUploaderProps, "setGlobalToast">;

const mapDispatch = (dispatch: ThunkDispatch<any, any, AnyAction>): ConnectedActions => ({
    setGlobalToast: (toast?: IGlobalToast): void => {
        dispatch(setGlobalToast(toast));
    },
});

export default connect(mapState, mapDispatch)(FileUploader);
