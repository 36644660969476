import { IRepairsState, IRepairsAction, Repair } from '../types/repairs';

const defaultState: IRepairsState = {
    repairs: []
};

const defaultAction: IRepairsAction = {
    type: '@@INIT'
};

export default (state: IRepairsState = defaultState, action: IRepairsAction = defaultAction): IRepairsState => {
    switch (action.type) {
        case 'ADD_REPAIR': {
            return action.newRepair
                ? {
                    ...state,
                    repairs: [
                        action.newRepair,
                        ...(state.repairs ?? [])
                    ]
                }
                : state;
        }
        case 'CLEAR_REPAIRS':
            return {
                ...state,
                repairs: []
            };
        case 'EDIT_REPAIR': {
            const { editedRepair } = action;
            if (editedRepair) {
                const selectRepair = (repair: Repair): Repair => {
                    const { repairNodeId } = editedRepair ?? {};
                    return repairNodeId && repair.repairNodeId === repairNodeId
                        ? editedRepair
                        : repair;
                };

                return {
                    ...state,
                    repairs: state.repairs?.map<Repair>(selectRepair)
                };
            }
            return state;
        }
        case 'SET_REPAIRS':
            return {
                ...state,
                repairs: action.repairs ?? []
            };
        case 'SET_CREATE_REPAIR_SUCCESS':
            return {
                ...state,
                createRepairSuccess: action.createRepairSuccess
            };
        case 'SET_SELECTED_REPAIR':
            return {
                ...state,
                selectedRepair: action.selectedRepair
            };
        case 'SET_EDIT_REPAIR_SUCCESS':
            return {
                ...state,
                editRepairSuccess: action.editRepairSuccess
            };
        default:
            return state;
    }
};
