export default `
query QuickSearch_Query($filter: QueryFilter, $paging: QueryControlInput) {
    waterSources(queryFilter: $filter, queryControl: $paging) {
        items {
            waterSourceNodeId: id
            waterSourceId: externalId
            location {
                address {
                    addressNodeId: id
                    addressId
                    streetDescription
                    town
                    locality
                }
                coordinates {
                    x
                    y
                }
                description
            }
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
            isFirstPage
            isLastPage
            pageCount
            pageNumber
            pageSize
            sortBy
            sortDirection
        }
        totalCount
    }
}`