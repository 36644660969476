import React from "react";

import LabelledField, { LabelledFieldProps } from "../../../labelledField";
import Optional from "../../../shared/Optional";
import { PropsWithEditing, PropsWithShow, TextProps } from "../types";
import { formatCurrency } from "../../../../lib/Utils";

const RepairCostSavings = ({ show, editing, data, onChange }: PropsWithEditing<PropsWithShow<TextProps>>): JSX.Element => {
    const props: LabelledFieldProps = {
        fieldType: "text",
        id: "repair-costSaving",
        label: "Cost Savings",
        classes: { container: 'input__group', label: 'input__label' },
        value: formatCurrency(data.costSaving, true, editing ? "" : "-") ?? "-",
        editing: editing ?? false,
        onChange: (value) => onChange?.(value),
    };
    return (
        <Optional hidden={!show}>
            <LabelledField {...props} />
        </Optional>
    );
};

export default RepairCostSavings;