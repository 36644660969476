import React from 'react';

import { ILookupItem } from '../../../../../store/types';

import styles from "./RoadSpeed.module.scss";

interface RoadSpeedProps {
    readonly roadSpeed?: ILookupItem;
}

const notAvailableValue = 1;

const RoadSpeed = ({ roadSpeed }: RoadSpeedProps): JSX.Element => {
    if (!roadSpeed || roadSpeed.value === notAvailableValue) {
        return <React.Fragment />
    }

    return <div className={styles.roadSpeed}>{roadSpeed.displayText}</div>
}

export default RoadSpeed;