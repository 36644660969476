import { withStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const AddressActionButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.primary.contrastText
        }
    }
}))(IconButton);

export default AddressActionButton;