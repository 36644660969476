import { IWaterSourceSearchFilters } from "../../components/filters/waterSourceSearch/types"
import { IPoint } from "./geometry";
import { IWaterSource, IMapWaterSource } from "./waterSources";

export interface IMapData {
    readonly waterSource?: IWaterSource;
    readonly waterSources?: (IMapWaterSource | IWaterSource | undefined)[]
}

export interface IMapAction {
    readonly type: '@@INIT'
    | 'SET_SETTINGS'
    | 'SET_RENDER_POINTS'
    | 'SET_CREATE_FROM_MAP_LOCATION'
    | 'SET_MOVING_WATER_SOURCE'
    | 'SET_SHOW_MAP_MENU'
    | 'SET_RENDER_ANIMATE_TO'
    | 'SET_MAP_CLICK_ACTION'
    | 'SET_STREET_VIEW_SETTINGS'
    | 'SET_MAP_FILTERS'
    | 'SET_MAP_CENTER_COORDS'
    | 'SET_MAP_FALLBACK_WARNING';
    readonly mapSettings?: IMapSettings;
    readonly streetViewSettings?: IStreetViewSettings;
    readonly renderPoints?: boolean;
    readonly createFromMapLocation?: number[];
    readonly movingWaterSource?: boolean;
    readonly movingWaterSourceLocation?: number[];
    readonly renderAnimateTo?: number[];
    readonly mapClickAction?: MapClickAction;
    readonly filters?: IWaterSourceSearchFilters;
    readonly mapCenterCoords?: number[];
    readonly selectWaterSourceNodeId?: string;
    readonly fallbackWarning?: boolean;
}

export type MapClickAction = 'CREATE_WATER_SOURCE' | 'MEASURE_DISTANCE' | 'STREET_VIEW' | 'DRAW_POLYGON' | 'EDIT_POLYGON' | 'DRAW_WATER_PIPE' | 'DELETE_WATER_PIPE' | 'DRAW_THOROUGHFARE' | 'DELETE_THOROUGHFARE' | undefined;

export interface IMapState {
    readonly mapSettings?: IMapSettings;
    readonly streetViewSettings?: IStreetViewSettings;
    readonly mapCenterCoords?: number[];
    readonly renderPoints: boolean;
    readonly renderAnimateTo?: number[];
    readonly selectWaterSourceNodeId?: string;
    readonly createFromMapLocation?: number[];
    readonly movingWaterSource: boolean;
    readonly movingWaterSourceLocation?: number[];
    readonly mapClickAction: MapClickAction;
    readonly streetView: IStreetView;
    readonly filters?: IWaterSourceSearchFilters;
    readonly fallbackWarning?: boolean;
}

export type MapSupplier = "default" | "cadcorp";

export interface IStreetView {
    readonly apiKey?: string;
    readonly params: {
        readonly position: {
            readonly lat?: number;
            readonly lng?: number;
        };
        readonly pov: {
            readonly heading: number;
            readonly pitch: number;
        };
        readonly zoom: number;
    };
}

export interface IMapProjection {
    readonly code: string;
    readonly definition: string;
}

export interface IMapView {
    readonly centre: IPoint;
    readonly extent: {
        readonly max: IPoint;
        readonly min: IPoint;
    };
}

export interface IMapSettings {
    readonly projection: IMapProjection;
    readonly tileServer: PrimaryTileServer;
    readonly view: IMapView;
    readonly search?: IMapSearch;
    readonly supplier?: MapSupplier;
}

export interface IMapSearch {
    readonly defaultRadius: string;
}

export interface IStreetViewSettings {
    apiKey: string;
}

export interface IStreetViewParams {
    lat: number;
    lng: number;
}

export type MapSelection = Pick<IWaterSource, "waterSourceNodeId" | "waterSourceId">;

// specification of a tileserver
export interface ITileServer {
    readonly type: TileServerType;
    readonly url: string;     // for azure: url is optional, specify subscription key in apiKey
    readonly apiKey: string;
    readonly params?: ITileServerParams; // passed to the map source either as url params or properties, see readme for details
}

export interface PrimaryTileServer extends ITileServer {
    readonly fallback?: ITileServer | true;
}

export const tileServerUrl = (address: Pick<ITileServer, "url" | "apiKey">): string => {
    const { url, apiKey } = address;
    const result = "".concat(url ?? "", apiKey ?? "");
    return result;
};

// type of map server osm,xyz,azure, wms (default=xyz when empty). duplicated here because it is a separate source tree than the server code
export type TileServerType = '' | 'osm' | 'osm-public' | 'xyz' | 'azure' | 'wms' | 'wmts'


// tiles server parameters, duplicated here because it is a separate source tree than the server code
export interface IWmsTileServerParams {
    layer: string; // required
    style?: string;
    additionalParams?: ITileServerParams; // additional params passed to the query string
}

export interface IWmtsTileServerParams {
    format?: TileImageFormat;
    layer: string; // Layer name as advertised in the WMTS capabilities.
    style?: string; // Style name as advertised in the WMTS capabilities.
    matrixSet: string; // matrix set name as configured in geoserver gridsets
    getCapabilitiesUrl?: string; // url to get WMTS capabilities (if not supplied, the system will try to guess it)
}

// tile server params are parameters passed to the maps source to alter behaviour
// for some sources (such as WMS) they are added as parameters to the URL, for others they are set on the object
export interface ITileServerParams extends IWmsTileServerParams, IWmtsTileServerParams {
    attributions: string;   // attributions / copyright for the map layer
}

export type TileImageFormat = 'image/png' | 'image/jpeg'
