import React from "react";
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import useSelector from "../../../lib/hooks/useSelector";
import { guid } from "../../../lib/Utils";
import { getDefectsForWaterSource } from "../../../store/actions/defects";
import { setError } from "../../../store/actions/errors";
import { getInspectionsForWaterSource } from "../../../store/actions/inspections";
import { startAddExternalRepair, startAddInternalRepair } from '../../../store/actions/repairs';
import { ILookupItem, IRepairsState, Repair, ICreateRepairVariables } from '../../../store/types';
import { useWaterSource } from "../../appWindowContext";
import CreateRepairDialogBase, { CreateRepairDialogProps as BaseProps } from './CreateRepairDialog';
import { SelectOption } from "../../labelledField";
import { toDefectOption, toInspectionOption } from "./utils";

type CreateRepairDialogProps = Pick<BaseProps, "repairCategory" | "isOpen" | "onClose">;

const CreateRepairDialog = ({ repairCategory, ...rest }: CreateRepairDialogProps): JSX.Element => {
    const dispatch = useDispatch();
    const filterType = (type: ILookupItem): boolean => {
        switch (repairCategory) {
            case "external": return type.groupingId === 1;
            case "internal": return type.groupingId === 2;
        }
    };
    const repairTypes = useSelector(state => state.app.lookups?.repairTypes.filter(filterType) ?? []);
    const repairVendors = useSelector(state => state.app.lookups?.repairVendors ?? []);
    const { instance: waterSource } = useWaterSource();
    const props: BaseProps = {
        ...rest,
        repairCategory,
        repairTypes,
        repairVendors,
        selectedWaterSource: waterSource,
        getDefectsAndInspections: async (waterSourceId): Promise<[SelectOption[], SelectOption[]]> => {
            if (waterSourceId) {
                const getDefects = getDefectsForWaterSource({ id: waterSourceId });
                const getInspections = getInspectionsForWaterSource({ id: waterSourceId });

                const defects = (await getDefects(dispatch)).filter(d => d.isOpen).map(toDefectOption);
                const inspections = (await getInspections(dispatch)).filter(i => i.isOpen).map(toInspectionOption);

                return [defects, inspections];
            }

            return [[], []];
        },
        onCreateRepair: async (category, data) => {
            const variables: ICreateRepairVariables = {
                repair: {
                    clientMutationId: guid(),
                    data
                }
            };
            const getAction = (): ((dispatch: ThunkDispatch<IRepairsState, any, AnyAction>) => Promise<Repair | undefined>) => {
                switch (category) {
                    case "external":
                        return startAddExternalRepair(variables);
                    case "internal":
                        return startAddInternalRepair(variables);
                }
            };
            const action = getAction();
            await action(dispatch);
        },
        onError: message => {
            dispatch(setError(message, new Error(message)));
        }
    };
    return <CreateRepairDialogBase {...props} />;
};

export default CreateRepairDialog;
