import { useEffect, useRef } from "react";
import { bindIfMounted } from "./utils";

const useIsMounted = (): [boolean, (f: () => void) => void] => {
    const isMounted = useRef<boolean>();
    useEffect(() => {
        isMounted.current = true;
        return (): void => {
            isMounted.current = false;
        }
    }, []);
    return [isMounted.current ?? false, bindIfMounted(isMounted.current ?? false)];
};

export default useIsMounted;