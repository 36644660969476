import React, { useState, useEffect } from "react";
import { LocalDate } from "@js-joda/core";

import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DoneIcon from "@material-ui/icons/Done";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import * as assert from "../../../../lib/assertions";
import { ILookupItem } from "../../../../store/types";
import IconDecoration from "../../../icons/IconDecoration";
import AppDialog, { AppDialogInput, AppDialogSelect } from "../../../shared/appDialog";
import Optional from "../../../shared/Optional";
import useStyles from "./AddDefect.styles";

interface AddDefectProps {
    readonly waterSourceId: string;
    readonly defectTypes: ILookupItem[];
    readonly defaultReportDate?: LocalDate;
    readonly defaultReporter?: string;
    readonly isMobile?: boolean;
    readonly disabled?: boolean;
    readonly onClose?: () => void;
    readonly onCommit?: (defectType: ILookupItem, dateReported: LocalDate, reportedBy: string, note: string) => void;
}

const renderDefectTypes = (defectTypes: ILookupItem[], isMobile: boolean | undefined): JSX.Element[] => {
    return defectTypes
        .filter(type => type.enabled)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(type => [type.value, type.displayText])
        .map(([value, displayText]) => isMobile
            ? <option key={value} value={value}>{displayText}</option>
            : <MenuItem key={value} value={value}>{displayText}</MenuItem>);
};

const AddDefect = (props: AddDefectProps): JSX.Element => {
    const { waterSourceId, defectTypes, defaultReportDate, defaultReporter, isMobile, disabled, onClose, onCommit } = props;

    const styles = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [disableCommit, setDisableCommit] = useState(true);
    const [defectType, setDefectType] = useState<ILookupItem>();
    const [dateReported, setDateReported] = useState(defaultReportDate);
    const [reportedBy, setReportedBy] = useState(defaultReporter);
    const [note, setNote] = useState<string>("");

    const closeDialog = (): void => {
        setShowDialog(false);
        setDefectType(undefined);
        setDateReported(defaultReportDate);
        setReportedBy(defaultReporter);
        onClose?.();
    };
    const openDialog = (): void => setShowDialog(true);

    const handleDefectTypeChange = (event: React.ChangeEvent<{ value: unknown; }>): void => {
        const { value } = event.target;
        const item = defectTypes.find(i => {
            return (typeof value === "string" || typeof value === "number")
                ? i.value.toString() === value.toString()
                : undefined;
        });
        setDefectType(item);
    };
    const handleDateReportedChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;
        const dateValue = LocalDate.parse(value);
        setDateReported(dateValue);
    };
    const handleReportedByChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;
        setReportedBy(value);
    };
    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;
        setNote(value);
    };

    const handleCommit = (): void => {
        closeDialog();
        if (onCommit) {
            assert.isDefined(defectType);
            assert.isDefined(dateReported);
            assert.isDefined(reportedBy);
            onCommit(defectType, dateReported, reportedBy, note.trim());
        }
    };

    useEffect(() => {
        const isValid =
            defectType !== undefined &&
            dateReported !== undefined &&
            reportedBy !== undefined;
        setDisableCommit(!isValid);
    }, [defectType, dateReported, reportedBy]);

    const commit = (
        <IconButton edge="end" onClick={handleCommit} disabled={disableCommit}>
            <DoneIcon />
        </IconButton>
    );

    return (
        <Optional hidden={disabled}>
            <ListItem button onClick={openDialog}>
                <ListItemIcon>
                    <IconDecoration decoration={<AddCircleOutlineIcon className={styles.icon} />}>
                        <ReportProblemIcon />
                    </IconDecoration>
                </ListItemIcon>
                <ListItemText primary="Create defect" />
            </ListItem>
            <AppDialog open={showDialog} appBar={{ title: "Add Defect" }} commit={commit} onClose={closeDialog}>
                <div className={styles.dialog}>
                    <AppDialogInput value={waterSourceId} id="addDefect-waterSource" label="Water source" disabled />
                    <AppDialogSelect value={defectType?.value} id="addDefect-type" label="Defect type" placeholder="Please select..." native={isMobile} required onChange={handleDefectTypeChange}>
                        {renderDefectTypes(defectTypes, isMobile)}
                    </AppDialogSelect>
                    <AppDialogInput value={dateReported} id="addDefect-dateReported" label="Date reported" placeholder="DD/MM/YYYY" type="date" required onChange={handleDateReportedChange} />
                    <AppDialogInput value={reportedBy} id="addDefect-reportedBy" label="Reported by" placeholder="Reported by" required onChange={handleReportedByChange} />
                    <AppDialogInput value={note} id="addDefect-note" label="Notes" multiline rows={3} onChange={handleNoteChange} />
                </div>
            </AppDialog>
        </Optional>
    );
}

export type { AddDefectProps };
export default AddDefect;