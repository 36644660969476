import { LocalDate, LocalDateTime } from "@js-joda/core";

export interface Validator {
    (value: unknown): boolean;
}
export type ValidatorArray = Validator[];

export interface ValidatorComparison {
    (value: unknown): (state: boolean, test: Validator) => boolean;
    readonly initialValue: boolean;
}

export const and: ValidatorComparison = Object.assign((value: unknown) =>
    (state: boolean, current: Validator): boolean =>
        state ? current(value) : state, { initialValue: true });

export const or: ValidatorComparison = Object.assign((value: unknown) =>
    (state: boolean, current: Validator): boolean =>
        state ? state : current(value), { initialValue: false });

export const compose = (comparison: ValidatorComparison, validators: ValidatorArray): Validator =>
    (value: unknown): boolean =>
        validators.reduce(comparison(value), comparison.initialValue);
export { compose as composeValidator };

export const isArray =
    Array.isArray;
export const isBigInt = (value: unknown): value is bigint =>
    typeof value === "bigint";
export const isBoolean = (value: unknown): value is boolean =>
    typeof value === "boolean";
export const isFunction = (value: unknown): boolean =>
    typeof value === "function";
export const isLocalDate = (value: unknown): value is LocalDate =>
    value instanceof LocalDate;
export const isLocalDateTime = (value: unknown): value is LocalDateTime =>
    value instanceof LocalDateTime;
export const isNull = (value: unknown): boolean =>
    value === null;
export const isNumber = (value: unknown): value is number =>
    typeof value === "number" || !Number.isNaN(value);
export const isObject = (value: unknown): value is Record<string, unknown> =>
    typeof value === "object";
export const isString = (value: unknown): value is string =>
    typeof value === "string"
export const isUndefined = (value: unknown): boolean =>
    value === undefined || isNull(value);

export const not = (validator: Validator) =>
    (value: unknown): boolean =>
        !validator(value);

export const isEmpty = (value: unknown): boolean =>
    (isString(value) && value.length === 0) ||
    (isArray(value) && value.length === 0);

export const isWhiteSpace = (value: unknown): boolean =>
    (isString(value) && value.trim().length === 0);

export const minLength = (minLength: number) =>
    (value: unknown): boolean =>
        (isArray(value) && value.length >= minLength) ||
        (isString(value) && value.length >= minLength);

export const maxLength = (maxLength: number) =>
    (value: unknown): boolean =>
        (isArray(value) && value.length <= maxLength) ||
        (isString(value) && value.length <= maxLength);

export const match = (pattern: string | RegExp) =>
    (value: unknown): boolean =>
        isString(value)
            ? (isString(pattern) ? new RegExp(pattern) : pattern).test(value)
            : false;

export const valid: Validator = (): boolean => true;
export const invalid: Validator = (): boolean => false;

export const requiredObject = not(isUndefined);
export const requiredArray = compose(and, [requiredObject, isArray, not(isEmpty)]);
export const requiredLocalDate = compose(and, [requiredObject, isLocalDate]);
export const requiredNumber = compose(and, [requiredObject, isNumber])
export const requiredString = compose(and, [requiredObject, isString, not(isEmpty)]);

export type StateValidators<T> = {
    readonly [K in keyof T]: Validator;
};
export interface StateValidator<S> {
    (state: S): boolean;
}

export const validateState = <S>(tests: StateValidators<S>): StateValidator<S> =>
    (state: S): boolean => {
        const fields = Object.keys(tests);
        const isValid = fields.reduce((valid, key) => {
            const test = tests[key];
            const value = state[key];
            return test(value) ? valid : false;
        }, true);
        return isValid;
    };

export const validationStyle = (test: Validator) =>
    (value: unknown): string =>
        test(value) ? "valid" : "invalid";
