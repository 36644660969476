import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => createStyles({
    container: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "300px"
    },
    account: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    adminMenu: {
        marginTop: "auto"
    },
    dialogAppBar: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText
    },
    dialogContainer: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    }
}));

export default useStyles;
