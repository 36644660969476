import { v4 as guid } from "uuid";

import executeQuery from "../../../lib/executeQuery";
import saveNewSearchMutation from "../../../store/actions/graphQL/saveNewSearch";
import { IAddMutation } from "../../../store/types";
import { SearchEntity } from "../searchSelector";
import { SelectedFilters } from "../types";
import { SaveSearchInput, SearchMutationResult, SearchMutations } from "./types";
import { makeResolveMutation } from "./utils";

const resolveMutation = makeResolveMutation("saveNew");

const saveNewSearch = async (searchPath: SearchEntity, name: string, filters: SelectedFilters): Promise<SearchMutationResult> => {
    const variables: IAddMutation<SaveSearchInput> = {
        input: {
            clientMutationId: guid(),
            data: {
                searchPath,
                displayText: name,
                queryFilter: filters
            }
        }
    };
    return executeQuery<SearchMutations>(saveNewSearchMutation, variables)
        .then(resolveMutation);
};

export default saveNewSearch;