export default `
mutation removeHazards_Mutation($input: EditUpdateLinksInput!) {
    removeHazards(input:$input) {
        clientMutationId
        waterSource {
            waterSourceNodeId: id
            waterSourceId: externalId
            hazards {
              hazardNodeId: id
              hazardId
              hazardType {
                value
                displayText
              }
            }        
        }
        hasErrors
        errors {
          message
          path
        }
    }
}
`;