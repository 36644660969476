import React from 'react';
import { RolesPermissionsAdmin } from '@3tc/shared-components';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import View from '../../components/shared/view';

const useStyles = makeStyles(createStyles({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowX: "auto"
    }
}));

const RolesPermissionsPage = (): JSX.Element => {
    const styles = useStyles();
    return (
        <View title="Roles & Permissions" disableRefresh>
            <div className={styles.root}>
                <RolesPermissionsAdmin />
            </div>
        </View>
    );
};

export default RolesPermissionsPage;
