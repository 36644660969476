import { useParams } from "react-router-dom";
import { NodeID, parseNodeId } from "../../../lib/nodeIdentifier";

const useStationNodeIdParam = (): NodeID | undefined => {
    const params = useParams<{ stationId?: string; }>();
    const { stationId } = params;
    return stationId
        ? parseNodeId(decodeURIComponent(stationId))
        : undefined;
};

export default useStationNodeIdParam;